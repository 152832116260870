/* global Backbone:false */

/**
 * Model object to back the view object.
 * 
 */

var config = require('../../../config/config');
var utils = require('../../../utils/utils');

var InforceSRViewModel = Backbone.Model.extend({
    defaults : {
        col             : 0,
        dir             : 'asc',
        length          : 25,
        start           : 0,
        orgView         : false
    },

    /**
     * Build url for datatable to fetch data
     * 
     * @return {string} url
     */
    urlRoot: function () {
        let hierarchyValue = 'producer';

        if (this.get('orgView')){
            hierarchyValue = 'org';
        }
        return config.apiUrlRoot + 'policies/servicerequests/inforce?hierarchy=' + hierarchyValue;
    },

    /**
     * Life cycle function
     * Ensure that numeric attrs are set as Number.
     * 
     * @param {Object} attrs attributes initial values
     * @param {Object} options no used
     * 
     */
    initialize : function initialize (attrs, options) {
        if (attrs) {
            utils.setNumber(this, 'col', attrs.col);
            utils.setNumber(this, 'dir', attrs.dir);
            utils.setNumber(this, 'length', attrs.length);
            utils.setNumber(this, 'start', attrs.start);
            this.set('orgView', attrs.orgView);
        }
    },

});

module.exports = InforceSRViewModel;
