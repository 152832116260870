// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <form id=\"ipipeline-credential-form\" action=\"\" method=\"post\" target=\"formspipe\">\r\n        <input title=\"ApplicationDataXML\" name=\"ApplicationDataXML\" class=\"hidden\">\r\n    </form>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"5":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.showDisabled : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"center"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDisabled : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n    <i class=\"ace-icon fa fa-file-o oa-bark fa-3x\"></i>\r\n    <h4>\r\n        <a id=\"form-link\" href=\"#\">Find a Form</a>\r\n        <i class=\"ace-icon fa fa-external-link oa-banner-blue\"></i>\r\n    </h4>\r\n    <div class=\"alert alert-warning alert-message"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.formsPipeErrorMessage : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n        <button aria-label=\"close\" type=\"button\" class=\"close\" data-dismiss=\"alert\">\r\n            <i class=\"ace-icon fa fa-times\"></i>\r\n        </button>\r\n        <p>"
    + ((stack1 = ((helper = (helper = helpers.formsPipeErrorMessage || (depth0 != null ? depth0.formsPipeErrorMessage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"formsPipeErrorMessage","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\r\n    </div>\r\n    <p>Access new business, policy<br class=\"visible-lg\">service, and claims forms at<br class=\"visible-lg\">FormsPipe</p>\r\n</div>";
},"useData":true});
