/* global Backbone */

// The base model for an item in the sidebar navigation
var SidebarMenuItemModel = Backbone.Model.extend({

    defaults: function(){
        return {
            icon        : '',
            displayText : '',
            link        : '',
            subItems    : null,
            active      : false,
            open        : false
        };
    },

    initialize: function (options) {

        var SidebarMenuCollection = require('../collections/sidebar-menu-c');

        var subItems = this.get('subItems');
        if (subItems) {
            this.childItems = new SidebarMenuCollection(subItems);
        }
    },

    validate: function(attrs, options){

        if (typeof attrs.icon !== 'string') {
            return 'icon must be a string ';
        }

        if (typeof attrs.displayText !== 'string') {
            return 'displayText must be a string ';
        }

        if (typeof attrs.link !== 'string') {
            return 'link must be a string ';
        }

        if (attrs.subItems && !Array.isArray(attrs.subItems)) {
            return 'subItems must be an array';
        }

    },

    containsAnActiveItem: function () {

        var i;

        // Bail if this item isn't a parent
        if (!this.childItems) {
            return false;
        }

        // Are any child items active?
        if (this.childItems.findWhere({active: true})) {
            return true;
        }

        // Recursively check the child items that have subItems
        for (i=0; i<this.childItems.length; i++) {
            if (this.childItems.at(i).containsAnActiveItem()) {
                return true;
            }
        }

        return false;
        
    }

});

module.exports = SidebarMenuItemModel;
