/* global Backbone */

var config = require('../../../config/config');

var PerformanceCenterModel = Backbone.Model.extend({
    url: config.apiUrlRoot + 'performanceCenter',

    //Adding new objects to peform view rendering for each section
    //This one will not make any changes to response objects 
    //unless adding new/formatted objects.
    parse: function (response) {
        var performanceCenterData = {};
        var cyp = response.calendarYearProduction;
        var ct = response.chairmansTrip;
        var lc = response.leadersConference;
        var apr = response.annualProductionRequirements;

        if (cyp) {

            if (cyp.commission) {

                // section FYC (retail and ib)
                cyp.commission.section = 'fyc';
                cyp.commission.textOne = 'Year To Date';
                cyp.commission.viewType = 'text';
                cyp.commission.textTwo = 'First Year Commission (FYC)';
                performanceCenterData[cyp.commission.section] = cyp.commission;

                // section qualifyingFyc - only for retail
                if (cyp.type === 'retail') {
                    var qualifyingFyc = {
                        section: 'qualifyingFyc',
                        textOne: 'Year To Date',
                        textTwo: 'Qualifying FYC',
                        viewType: 'text',
                        qualifyingYearToDate: cyp.commission.qualifyingYearToDate
                    };
                    performanceCenterData[qualifyingFyc.section] = qualifyingFyc;
                }
            }

            // As its required to render two seperate views splitting up
            // Only for IB
            if (cyp.weightedPremium && cyp.type === 'ib') {

                var wgtprem = {
                    section: 'wgtprem',
                    textOne: 'Year To Date',
                    textTwo: 'Weighted Premium',
                    viewType: 'text',
                    totalYearToDate: cyp.weightedPremium.totalYearToDate
                };
                performanceCenterData[wgtprem.section] = wgtprem;

            }

            if (cyp.lifeLives) {
                cyp.lifeLives.section = 'lifeLives';
                cyp.lifeLives.asOfDate = cyp.asOfDate;
                performanceCenterData[cyp.lifeLives.section] = cyp.lifeLives;
            }

            // performanceCenter refresh date
            performanceCenterData.refreshDate = cyp.asOfDate;
        }

        if (ct) {
            if (ct.type === 'retail') {
                ct.measureLabel = 'Qualifying FYC';
                ct.measureTotalLabel = 'Total FYC';
            } else {
                ct.measureLabel = 'Weighted Premium';
                ct.measureTotalLabel = 'Total Weighted Premium';
            }
            ct.section = 'chairmansTrip';
            performanceCenterData[ct.section] = ct;
        }

        if (lc) {
            if (lc.type === 'retail') {
                lc.toggleChart = true;
                lc.measureLabel = 'Qualifying FYC';
                lc.measureTotalLabel = 'Total FYC';
            } else {
                lc.measureLabel = 'Weighted Premium';
                lc.measureTotalLabel = 'Total Weighted Premium';
            }
            lc.section = 'leadersConference';
            performanceCenterData[lc.section] = lc;
        }

        if (apr) {
            apr.section = 'annualProductionRequirements';
            performanceCenterData[apr.section] = apr;
        }

        return performanceCenterData;
    }
});

module.exports = PerformanceCenterModel;
