var rewriteDeprecatedURL = {
    deprecatedURLMap : {

        // old                        new
        '#pending-policy-detail'    : '#policy-detail',
        '#pending-policy-manager'   : '#policy-manager',
        '#org-pending-policies'     : '#org-policies',
        '#pending-policies'         : '#policies',
        '#producer-delegate-list'    : '#delegate-producer-list'
    },

    /**
     * Rewrite depreacted URL using Array of deprecatedURLMap
     */
    rewrite : function () {
        
        // rewrite deprecated URLs to new one
        var hash = location.hash;
        var newURL;
        for ( var oldURL in this.deprecatedURLMap) {
            if (hash.indexOf(oldURL) > -1) {
                newURL = this.deprecatedURLMap[oldURL];
                newURL =  hash.replace(new RegExp(oldURL, 'g'), newURL);
            }
        }

        if (newURL && newURL.substr(0,1) === '#') {
            location.hash = newURL;
        }
    }
};

module.exports = rewriteDeprecatedURL;