// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.flatExtraAmount : depth0),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<tr>\r\n"
    + ((stack1 = container.invokePartial(partials["rating-information-col-insured-rateclass"],depth0,{"name":"rating-information-col-insured-rateclass","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <td>"
    + alias4(((helper = (helper = helpers.coveragePlanName || (depth0 != null ? depth0.coveragePlanName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"coveragePlanName","hash":{},"data":data}) : helper)))
    + "</td>\r\n    <td>"
    + alias4(((helper = (helper = helpers.ratingType || (depth0 != null ? depth0.ratingType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ratingType","hash":{},"data":data}) : helper)))
    + "</td>\r\n    <td class=\"text-right\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.flatExtraAmount : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </td>\r\n    <td>"
    + alias4((helpers.isoDurationToTimeUnit || (depth0 && depth0.isoDurationToTimeUnit) || alias2).call(alias1,(depth0 != null ? depth0.duration : depth0),"year",{"name":"isoDurationToTimeUnit","hash":{},"data":data}))
    + "</td>\r\n    <td>"
    + alias4(((helper = (helper = helpers.tableName || (depth0 != null ? depth0.tableName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tableName","hash":{},"data":data}) : helper)))
    + "</td>\r\n</tr>";
},"usePartial":true,"useData":true});
