// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"panel panel-default"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.key),"neededForUnderwriting",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                <div class=\"panel-heading\">\r\n                    <h4 class=\"panel-title\">\r\n                        <a class=\"accordion-toggle pointer\" data-toggle=\"collapse\" href=\"#requirement-collapse-"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" >\r\n                        \r\n                            <i class=\"ace-icon fa fa-angle-down bigger-110\" data-icon-hide=\"ace-icon fa fa-angle-down\" data-icon-show=\"ace-icon fa fa-angle-right\"></i>\r\n\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.key),"neededForUnderwriting",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.key),"obtainAtDelivery",{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.key),"received",{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "&nbsp;("
    + alias4(container.lambda(((stack1 = blockParams[1][0]) != null ? stack1.length : stack1), depth0))
    + ")\r\n                        </a>\r\n                    </h4>\r\n                    <!-- /.panel-title -->\r\n                </div>\r\n                <!-- /.panel-heading -->\r\n\r\n                <div class=\"collapse in\" id=\"requirement-collapse-"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "\" aria-expanded=\"false\">\r\n                    <div class=\"dataTables_wrapper panel-body\">\r\n                        <div class=\"table-responsive\">\r\n                            <table role=\"grid\" class=\"table table-striped table-bordered table-hover table-sortable\" id=\""
    + alias4(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "\" data-instruction-container=\"#"
    + alias4(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "_instruction\" width=\"100%\" ></table>\r\n                            <!-- /.data-table -->\r\n                        </div>\r\n                        <!-- /.table-responsive -->\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","hash":{"id":(helpers.concat || (depth0 && depth0.concat) || alias2).call(alias1,(data && data.key),"_instruction",{"name":"concat","hash":{},"data":data,"blockParams":blockParams})},"data":data,"blockParams":blockParams,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\r\n                    <!-- /.panel-body-->\r\n                </div>\r\n                <!-- /.collapse -->\r\n            </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " panel-warning";
},"5":function(container,depth0,helpers,partials,data) {
    return "                                Needed to Underwrite / Issue / Mail Policy";
},"7":function(container,depth0,helpers,partials,data) {
    return "                                May Obtain at Delivery";
},"9":function(container,depth0,helpers,partials,data) {
    return "                                Received Requirements";
},"11":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "        <div class=\"panel panel-default\">\r\n            <div class=\"panel-heading\">\r\n                <h4 class=\"panel-title\">\r\n                    <a class=\"accordion-toggle pointer collapsed\" data-toggle=\"collapse\" href=\"#requirement-submission-log-collapse\" >\r\n                        <i class=\"ace-icon fa fa-angle-right bigger-110\" data-icon-hide=\"ace-icon fa fa-angle-down\" data-icon-show=\"ace-icon fa fa-angle-right\"></i>\r\n                        Requirement Submission Log&nbsp;("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.requirementSubmissionLog)) && stack1.length), depth0))
    + ")\r\n                    </a>\r\n                </h4>\r\n                <!-- /.panel-title -->\r\n            </div>\r\n            <!-- /.panel-heading -->\r\n            <div class=\"collapse\" id=\"requirement-submission-log-collapse\" aria-expanded=\"false\">\r\n                <div class=\"panel-body\">\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.requirementSubmissionLog)) && stack1.length),3,{"name":"compare","hash":{"operator":"<="},"fn":container.program(12, data, 0, blockParams),"inverse":container.program(15, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                </div> \r\n                <!-- /.panel-body -->\r\n            </div>\r\n            <!-- /.collapse -->\r\n\r\n        </div> \r\n        <!-- /.panel-default -->\r\n";
},"12":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "                        <dl>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.requirementSubmissionLog),{"name":"each","hash":{},"fn":container.program(13, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                        </dl> \r\n";
},"13":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["policy-requirement-submission-log-items"],blockParams[0][0],{"name":"policy-requirement-submission-log-items","data":data,"blockParams":blockParams,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        <div class=\"row\">\r\n                            <div class=\"col-md-6\">\r\n                                <dl>\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.requirementSubmissionLog),{"name":"each","hash":{},"fn":container.program(16, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                                </dl>\r\n                            </div> <!-- /.col-md-6-->\r\n\r\n                            <div class=\"col-md-6\">\r\n                                <dl>\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.requirementSubmissionLog),{"name":"each","hash":{},"fn":container.program(19, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                                </dl> \r\n                            </div> <!-- /.col-md-6-->\r\n                        </div><!-- /.row -->        \r\n";
},"16":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.index),(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.requirementSubmissionLog)) && stack1.length),"/",2,{"name":"math","hash":{},"data":data,"blockParams":blockParams}),{"name":"compare","hash":{"operator":"<"},"fn":container.program(17, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["policy-requirement-submission-log-items"],blockParams[1][0],{"name":"policy-requirement-submission-log-items","data":data,"blockParams":blockParams,"indent":"                                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.index),(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.requirementSubmissionLog)) && stack1.length),"/",2,{"name":"math","hash":{},"data":data,"blockParams":blockParams}),{"name":"compare","hash":{"operator":">="},"fn":container.program(17, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<h2 id=\"requirements\" class=\"header\">Requirements & U/W Decisions</h2>\r\n<a class=\"btn btn-info margin-btm-20 oa-js-nav\" href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(alias1,"#requirement-submission",{"name":"buildHrefURL","hash":{"policyId":((stack1 = (data && data.root)) && stack1.policyId)},"data":data,"blockParams":blockParams}))
    + "\">\r\n    <i class=\"ace-icon fa fa-file-text inline bigger-125\"></i>\r\n    Submit Requirements\r\n</a>\r\n<div id=\"requirementsAccordion\" class=\"accordion-style1 panel-group accordion-style2\">\r\n"
    + ((stack1 = helpers.each.call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    <!-- /.panel-default -->\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.requirementSubmissionLog),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\r\n</div>\r\n<!-- /.accordion -->\r\n<!-- end of Requirements -->";
},"usePartial":true,"useData":true,"useBlockParams":true});
