// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <p class=\"response-message bg-success\" >"
    + container.escapeExpression(((helper = (helper = helpers.successMessage || (depth0 != null ? depth0.successMessage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"successMessage","hash":{},"data":data}) : helper)))
    + "</p>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <p class=\"response-message bg-danger\" >"
    + container.escapeExpression(((helper = (helper = helpers.errorMessage || (depth0 != null ? depth0.errorMessage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorMessage","hash":{},"data":data}) : helper)))
    + "</p>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"page-content\">\r\n\r\n    <h2>Web Push Test</h2>\r\n    \r\n    <h4>Enable push</h4>\r\n    <div class=\"row margin-btm-40\">\r\n        <div class=\"col-md-8\" >\r\n            <button disabled id=\"btn-enable-push\" class=\"btn btn-primary btn-lg\">\r\n                Enable Push Messaging\r\n            </button>\r\n        </div>\r\n    </div><!-- /.row-->\r\n\r\n    <h4>Send Push notification</h4>\r\n    <div class=\"row\" >\r\n        <div class=\"col-md-8\" >\r\n	        <form id=\"web-push-form\" >\r\n                <div class=\"form-group\">\r\n                    <label for=\"message\">Subscription Data</label>\r\n                    <textarea type=\"text\" rows=\"5\" disabled id=\"subscription-data\" class=\"form-control\" >"
    + alias4(((helper = (helper = helpers.subscriptionData || (depth0 != null ? depth0.subscriptionData : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subscriptionData","hash":{},"data":data}) : helper)))
    + "</textarea>\r\n                    <p>Data will be saved in Server to send push notification</p>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label for=\"message\">Text to send</label>\r\n                    <textarea type=\"text\" id=\"message\" class=\"form-control\" >"
    + alias4(((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"msg","hash":{},"data":data}) : helper)))
    + "</textarea>\r\n                </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.successMessage : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.errorMessage : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <button id=\"btn-send-push\" disabled type=\"submit\" class=\"btn btn-success btn-lg\">Send Notification</button>\r\n            </form>\r\n        </div>\r\n    </div><!-- /.row-->\r\n\r\n</div>";
},"useData":true});
