/* global Marionette, _ */

import ToutTemplate from '../templates/tout-t.hbs';
import ToutModel from '../models/tout-m';

// Load handlebars helper method to avoid unit test failure
import '../../../utils/hb-helpers';

const ToutView = Marionette.ItemView.extend({

    template: ToutTemplate,

    errors : {
        objectRequired : 'ToutView requires options passed to constructor to be an object'
    },

    initialize(options) {
        
        if (_.isEmpty(options) || !_.isObject(options)) {
            throw new Error(this.errors.objectRequired);
        } 
        
        this.model = new ToutModel(options);
    }

});

module.exports = ToutView;

