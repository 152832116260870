/* global require:false, Backbone:false */

var template    = require('../templates/view-as-producer-test-t.hbs');
var userChannel = Backbone.Radio.channel('user');

var ViewAsProducerTestView = Backbone.Marionette.ItemView.extend({

    template : template,

    events : {
        'click a' : '_viewAsProducer'
    },

    _viewAsProducer : function _viewAsProducer(event) {
        event.preventDefault();
        var anchor = Backbone.$(event.currentTarget);
        userChannel.trigger(
            'viewAsProducer',
            {
                webId    : anchor.data('webid'),
                fullName : anchor.data('name')
            }
        );
    }
});

module.exports = ViewAsProducerTestView;
