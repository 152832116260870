// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <th>Coverage Description</th>\r\n                <th>Mortality Table</th>\r\n                <th>Morbidity Table</th>\r\n                <th>Rated Age</th>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ratingInformationData)) && stack1.hasNonAssetCareTableRatingData),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <th>Coverage Description</th>\r\n                    <th>Rating Type</th>\r\n                    <th class=\"text-right\">Flat Extra / $1,000</th>\r\n                    <th>Duration (yrs)</th>\r\n                    <th>Table Name</th>\r\n";
},"6":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["rating-information-table-row"],depth0,{"name":"rating-information-table-row","hash":{"insured":blockParams[0][0]},"data":data,"blockParams":blockParams,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n\r\n<h3 id=\"rating-information\">Rating Information as Applied</h3>\r\n<div class=\"table-responsive\">\r\n    <table id=\"ratingsTable\" class=\"table table-striped table-bordered table-hover\">\r\n        <thead>\r\n        <tr>\r\n            <th>Insured</th>\r\n            <th>Rate Class</th>\r\n\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ratingInformationData)) && stack1.isProductAssetCare),((stack1 = ((stack1 = (data && data.root)) && stack1.ratingInformationData)) && stack1.hasAssetCareTableRatingData),{"name":"compare","hash":{"operator":"&&"},"fn":container.program(1, data, 0, blockParams),"inverse":container.program(3, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\r\n        </tr>\r\n        </thead>\r\n        <tbody>\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.ratingInformationData),{"name":"each","hash":{},"fn":container.program(6, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "        </tbody>\r\n    </table>\r\n    <!-- end of table -->\r\n</div>\r\n<!-- /.table responsive -->\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useBlockParams":true});
