/* global Marionette:false, Backbone:false, _:false  */

var template = require('../templates/resource-center-layout-t.hbs');
var ResourceCenterFormView = require('../views/resource-center-form-v');
var ResourceCenterFormViewModel = require('../viewModels/resource-center-search-form-vm');
var checkpoint = require('../../../modules/checkpoint/checkpointModule');
const ResourceCenterItemView = require('./resource-center-item-v');
var ResourceCenterFilterModel = require('../viewModels/resource-center-filter-vm');
var ResourceCenterFilterView = require('../views/resource-center-filter-v');
var config = require('../../../config/config');
import WcmToutView from '../../home/views/wcm-tout-v';
var userChannel = Backbone.Radio.channel('user');


var ResourceCenterLayoutView = Marionette.LayoutView.extend({
    template: template,

    regions: {
        resourceCenterRegion: '#resource-table-region',
        resourceSearchRegion: '#resource-table-search-region',
        resourceFilterRegion: '#resource-table-filter-region',
        resourceCenterNote  : '#resource-center-note-ho-user',
    },
    errors: {
        resourceItemError   : 'No resource found that match "<%= searchTerm %>."',
    },
    initialize: function initialize(options) {
        this.resourceState = {};
        this.checkpoint = checkpoint;
        var searchFormModel;
        var searchTerm   = null;
        var filterFormModel = null;
        var filterTerm = null;
        var filterType = null;
        //Set up the search form and set up listeners
        if (options) {
            searchTerm = options.searchTerm;
        }

        searchFormModel = new ResourceCenterFormViewModel({
            searchTerm   : searchTerm
        });
        this.searchFormView = new ResourceCenterFormView({
            model: searchFormModel
        });
        this.listenTo(this.searchFormView, '_searchResource', this._searchResource);
        filterFormModel = new ResourceCenterFilterModel({
            filterType: filterType,
            filterTerm   : filterTerm
        });
        this.filterFormView = new ResourceCenterFilterView({
            model: filterFormModel
        });
        //this.listenTo(this.searchFormView, '_searchResource', this._searchResource);
    },

  /**
   * Lifecycle function.
   */
    onBeforeShow: function onBeforeShow(options) {
        this._showResources();
        this._showHoUserNote();
    },

    _showHoUserNote: function _showHoUserNote() {
        var hasHomeOfficeCapability = userChannel.request('hasCapability', 'Home_Office');
        if (hasHomeOfficeCapability) {
            var homeOfficeUserNote = 'resource-center/note-home-office-users';
            this.showChildView('resourceCenterNote', new WcmToutView({
                wcmPath : homeOfficeUserNote,
            }));
        }
    },

    _showResources: function _showResources() {
        this.showChildView('resourceSearchRegion', this.searchFormView);
        if (config.fflag.resourceCenterfilterGroup) {
            this.showChildView('resourceFilterRegion', this.filterFormView);
        }
        var resourceView = new ResourceCenterItemView({
            state: this.resourceState
        });
        this.showChildView('resourceCenterRegion', resourceView);
    },

    _searchResource: function(searchItem, options) {
        var state = {
            searchItem : searchItem
        };
        this.trigger('_stateChange', state, true);
        this._clearCurrentResults();
        var resultsView = new ResourceCenterItemView(
            _.extend({searchItem : searchItem}, options)
        );
        this.listenTo(resultsView , 'showServerMessage', this._showServerMessage);
        this.showChildView('resourceCenterRegion', resultsView);
    },

    /**
     * Destroy the current search results view, if it exists.
     * @private
    */
    _clearCurrentResults : function _clearCurrentResults () {
        //this.ui.searchResultsDiv.addClass('hidden');
        var currentResults = this.getChildView('resourceCenterRegion');
        if (currentResults) {
            currentResults.destroy();
        }
    },

    /**
     * Display a message from the server
     * @param message the message to display
     * @param type the type of the message (info, warn, etc)
     * @private
     */
    _showServerMessage : function _showServerMessage (message, type) {
        this.searchFormView.showServerMessage(message, type);
    },

    /**
     * Replace the hash when the user changes the state (paging/sorting) of the result table.
     * @param {object} [changedState] The properties that have changed
     * @private
    */
    _stateChange: function _stateChange(changedState) {
        var state;
        this.resourceCenterState = changedState;
        if (_.isObject(changedState)) {
            state = changedState;
        } else {
            state = {};
        }

        _.extend(state, changedState);
        this.checkpoint.writeCheckpoint(state, true);
    }

});

module.exports = ResourceCenterLayoutView;