/* global Backbone:false */
var config = require('../../../config/config');
var DeepLinkModel = Backbone.Model.extend({
    defaults: {
        deepLinkPath: null,
        dataUrl: null
    },
  /**
   * Build url for datatable to fetch data
   *
   * @return {string} url
   */
    url: function () {
       
        // URL is built by stripping off the protocol and appending endpoints on vintage project.
        var partUrl = config.vintageURL;
        partUrl = partUrl.substring(6);
        // partUrl + this.get('deepLinkPath') + ?spoofId=aul01ho;
        return partUrl + this.get('deepLinkPath');
    },

  
  /**
   * Life cycle function
   * Ensure that numeric attrs are set as Number.
   */
    initialize: function initialize(attrs, options) {
      
    },
  
});
module.exports = DeepLinkModel;