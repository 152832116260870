/* global WebTrends */

/**
*  Provider class for WebTrend analytics
*  
*/

require('../../../../vendor/webtrends');

var config = require('../../../config/config');
var BaseAnalyticsProviders = require('./baseAnalyticsProvider');
var debugModule = require('../../debug/debugModule').init();

var WebTrendsAnalyticsProvider  = BaseAnalyticsProviders.extend({
    
    id: 'WT',
    errors: {
        missingConfig   : 'WebTrends configurations are missing'
    },

    /**
     * @param options
     * parameter object which contain options to override 
     * parameters (dcsid, onsitedoms, fpcdoms) used in webtrends initialization
     * 
     */
    initialize: function(options) {

        //set webtrends parameters from 'options' or 'config' based on availability
        if (options) {
            this.dcsid      = options.dcsid;
            this.onsitedoms = options.onsitedoms;
            this.fpcdom     = options.fpcdom;
        } else if (config && config.webTrends) {
            this.dcsid      = config.webTrends.dcsid;
            this.onsitedoms = config.webTrends.onsitedoms;
            this.fpcdom     = config.webTrends.fpcdom;
        }

        // Initialize and configure the analytics utility (if necessary)
        // Configure for things it can do automatically even in SPA (if possible)
        // Disable automatic things that we'll need to do programatically in SPA (if necessary)
        // Store a ref to the main analytics object in this scope, to be used by the callbacks
        // in the 'comply' sections below (if necessary)
        if (this.dcsid && this.onsitedoms && this.fpcdom) {

            var dcs = new WebTrends.dcs();
            dcs.init({
                //Webtrends data collection server ID (DCSID)
                dcsid: this.dcsid,  
                
                //default for Webtrends On Demand accounts
                domain:'statse.webtrendslive.com', 
                
                //time zone (EST) for Webtrends data source
                timezone:-5, 
                i18n:false,
                
                //types of on-page click activities to track 
                //(offsite, download,anchor, javascript)  
                offsite:true,                         
                download:true, 
                downloadtypes:'xls,doc,pdf,txt,csv,zip,docx,xlsx,rar,gzip',
                anchor:true,
                javascript: true,
                
                //primary domain of the site you want to track
                onsitedoms: this.onsitedoms,
                
                //to track visits as completely separate visits, exculde subdomains
                fpcdom: this.fpcdom,  
                plugins:{
                }
            });

            this.configReady = true;
        } else {
            debugModule.error(this.errors.missingConfig, options);
            this.configReady = false;
        }
    },

    /**
     * Set webId of current impersonated user
     * @param {string} id webId received from 'user' service response
     */
    setViewingAs : function (webId) {
        this.impersonatedWebId = webId;
    },

    /**
     * Track that a user viewed a page
     * @param options
     *  
     * 'uri' and title are object available to with options 
     * to build analytics arguments
     * 
     */
    trackView: function(options) {

        if (!this.configReady) {
            debugModule.error(this.errors.missingConfig);
            return false;
        }

        // set up the args object
        var args = {
            'DCS.dcsuri': options.uri,

            //page view refernce :-
            //http://kb.webtrends.com/articles/Information/WT-dl-Values-and-Functionality-1365447872904
            'WT.dl'     : 0,

            //title of the page
            'WT.ti'     : options.title || 'OneSourceOnline',

            //scenario name
            'WT.si_n'   : '',

            //Identifies the step by name for scenario
            'WT.si_p'   : '',

            //home page or not
            'WT.hp'     : 0
        };

        var _this = this;

        // Add the DCS.dcsaut property
        if (this.webId) {
            args['DCS.dcsaut'] = this.webId;
        }

        // Set DCSext.acting_as property with webId of impersonated user
        if (this.impersonatedWebId) {
            args['DCSext.acting_as'] = this.impersonatedWebId;
        }

        // Call WebTrends
        WebTrends.multiTrack({
            args: args,
            callback: function (a) {
                _this._logResponse(options, a);
            }
        });

        debugModule.log('(' + this.id + ') viewing ' + options.uri);

    },

    /**
    * track all actions (events / user interactions) on application
    * Method can handle click events and other user actions such as search, filter
    *
    * @param options {object} 
    *  
    **/
    trackAction: function(eventDetails) {

        if (!this.configReady) {
            debugModule.error(this.errors.missingConfig);
            return false;
        }

        var _this = this;

        var eventId;
        var referenceURI;
        var title;
        var uri;

        //if event object exist parse details from it
        if (eventDetails) {
            
            //get eventId specific to WebTrends
            eventId      = _this._getWebTrendsEventId(eventDetails.eventType);
            referenceURI = eventDetails.referenceURI;
            title        = eventDetails.title;
            uri          = eventDetails.uri;
        }
        
        // Considering going with custom params, but those need special reports
        //'DCSext.action': options.actionName
        var args = {
            'DCS.dcsuri': uri,
            'WT.ti': title,
            'WT.hp': 0,
            'WT.dl': eventId
        };

        //refer this URL for WT.dl 
        //http://kb.webtrends.com/articles/Information/WT-dl-Values-and-Functionality-1365447872904
        if(eventId === 20 || eventId === 24){
            args['DCS.dcsref'] = referenceURI;
        }

         // Add the DCS.dcsaut property
        if (this.webId) {
            args['DCS.dcsaut'] = this.webId;
        }

        // Set DCSext.acting_as property with webId of impersonated user
        if (this.impersonatedWebId) {
            args['DCSext.acting_as'] = this.impersonatedWebId;
        }

        WebTrends.multiTrack({
            args: args,
            callback: function (a) {
                _this._logResponse(eventDetails, a);
            }
        });
    },

    /**
     * This is place holder method to track exception for WT.
     * Currently WebTrend doesn't have a specific API for logging error / exceptions
     * 
     * @return {[type]} [description]
     */
    trackException: function (options) {

    },

    /**
     * Abstract the WebTrends-specific event IDs and allow the app to use descriptive,
     * non-vendor-specific strings when calling the analytics module. The method accepts
     * a string and returns the equivalent WebTrends ID, defaulting to 99 if WebTrends
     * does not have a specific item.
     *
     * @param eventType A string representing the type of event
     * @returns {number} The WebTrends numeric ID for an event type
     */
    _getWebTrendsEventId : function(eventType){
        var eventIds = {
            pageView: 0,
            download: 20, 
            anchor: 21, 
            javascript: 22, 
            mailto: 23, 
            external: 24, 
            rightClick: 25, 
            
            // Generated when a form button is clicked. 
            // Button is enclosed inside a form, and the method is GET.
            formGet: 26,

            // Generated when a form button is clicked. 
            // Button is enclosed inside a form, and the method is POST.
            formPost: 27,

            // Generated when a form button is clicked. 
            // Button is not enclosed inside a form, but is enclosed in an <input> tag.
            formInput: 28,

            // Generated when a form button is clicked. 
            // Button is not enclosed inside a form, but is enclosed in a <button> tag
            formButton: 29,
            imageMap: 30,
            youTubeImpression: 40,
            videoEvent: 41,
            onSiteAd: 50,
            mobileEvent: 60,
            mobileStateEvent: 61,
            linkClick: 99,
            facebook: 111,
            heatmap: 125
        };

        // 99 is a default 'unknown' event id (conclusion from AS2 code)
        var id = 99;
        if (eventIds.hasOwnProperty(eventType)) {
            id = eventIds[eventType];
        }

        return id;
    },

    /**
     * Log the response to a webtrends call.
     * @param options The object passed into the appropriate analyticsModule comply function
     * @param response The response from WebTrends
     */
    _logResponse: function(options, response) {
        var action = 'view of';
        var element;

        //let's check that the event was collected.
        if (response.errors && response.errors.length) {
            debugModule.log('(' + this.id + ') Errors logging analytics data', response.errors);
        } else {
            if (options.eventType || options.actionName) {
                action = options.eventType + ':' + options.actionName + ' on';
            }

            if (options.uri) {
                element = ' uri "' + options.uri + '" ';
            } else {
                element = ' title "' + options.title + '" ';
            }

            debugModule.log('(' + this.id + ') ' + action + element + 'logged successfully');
        }
    }

});

// export a singleton instance of the module
module.exports = WebTrendsAnalyticsProvider;
