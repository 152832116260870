/* global Backbone:false*/

/**
 * A utility module which fetch all other sub untility modules to make single interface call.
 */

var utilsData = require('./utils-data');
var utilsDatatable = require('./utils-datatables');
var utilsFormatting = require('./utils-formatting');
var utilsUI = require('./utils-ui');
var utilsEmailValidation = require('./utils-emailValidation');

var utils = {

    // identify the global object in the environment we are running
    // inside. For browsers, this should return the window object
    global: function () {
        return this;
    }
};

// Extend utils object with sub utility module objects
Backbone.$.extend(true, utils, utilsData, utilsDatatable, utilsFormatting, utilsUI,
    utilsEmailValidation);

module.exports = utils;