/* global _, Backbone, Marionette */
var _global = (function() { return window; })();

var config                = require('./config');
var SidebarView           = require('./views/sidebar-v');
var SEL                   = require('./selectors');
var SidebarMenuCollection = require('./collections/sidebar-menu-c');
var SidebarMenuView       = require('./views/sidebar-menu-v');

require('./sidebar-helpers.js');

var Sidebar = Marionette.Application.extend({

    /**
    * options:
    *   container - selector or jQuery object of DOM element that will contain the sidebar
    *   menu - array of objects representing the structure of the menu (see below)
    *   namespace - (optional) string name of global namespace object
    *
    * Example of menu structure:
    *
        [
            {
                icon        : 'desktop',
                displayText : 'Contact Us',
                link        : 'contact-us'
            },
            {
                icon        : 'info-circle',
                displayText : 'FAQs & Resources',
                link        : 'resources',
                subItems: [
                    {
                        displayText: 'Billing and Payments',
                        link: 'billing-payments'
                    },
                    {
                        displayText: 'Tools',
                        link: 'tools'
                    },
                    {
                        displayText: 'Training',
                        link: 'training'
                    }
                ]
            }
        ]
    */
    initialize: function (options) {

        if (options.namespace && typeof options.namespace !== 'string') {
            throw new Error('Sidebar initialize: \'namespace\' option must be a string');
        }
        if (options.menu && !Array.isArray(this.menu)) {
            throw new Error('Sidebar initialize: \'menu\' option must be an array');
        }
        if (options.container && typeof options.container !== 'string') {
            throw new Error('Sidebar initialize: \'container\' option must be a string');
        } 

        if (
            options.namespace &&
            typeof options.namespace === 'string' &&
            typeof _global[options.namespace] === 'object'
        ) {
            // add our namespace under the passed one
            _global[options.namespace][config.namespace] = {};
        } else {
            // Add to the top level object
            _global[config.namespace] = {};
        }

        // Allow listening to events on passed in arguments
        _.extend(this, Backbone.Events);

        this.sidebarView = new SidebarView();
        // Must render the root view so that the elements for the regions exist
        // before we can show the regions
        this.sidebarView.render();
        this.menuRegion = this.sidebarView.addRegion('menuRegion', SEL.REGIONS.MENU);

        // Menu setup
        this.menuCollection = new SidebarMenuCollection(options.menu);
        this.menuView = new SidebarMenuView({
            collection  : this.menuCollection
        });

        this.menuRegion.show(this.menuView);

        this._configureEventListeners();

        //prepend SidebarView into .main-container (options.container)
        Backbone.$(options.container).prepend(this.sidebarView.$el);
    },

    /*
    * Method to set `active' flag with `true` to highlight 
    * the menu item selected from sidebar, same time it set `active` flag to false
    * for previously selected item
    *
    * Mostly this method will be invoked from _showPage method defined on osoApp
    *
    * @param pageId - string - eg: #products or product
    *
    */
    setActiveItem: function (pageId) {
        var newActiveItemRef;
        var oldActiveItemRef;

        if (!pageId && !_.isString(pageId)) {
            throw new Error('Sidebar setActiveItem: \'pageId\' parameter must be a string');
        } else if (pageId.charAt(0) === '#') {
            pageId = pageId.slice(1);
        }

        //recursive method to iterate model items stored in menu collection
        var iterateModel = function (model) {
            
            // A property from AppStructure.js to check whether current menuItem 
            // should active for given pageId (link)
            var activeFor = model.get('activeFor');

            //check whether it have childsItems set
            if (model.childItems && model.childItems.length) {

                model.childItems.each (function (nextModel) {
                    iterateModel(nextModel);
                });

            } else if (model.get('link') && model.get('link') === pageId) {
               
                //found new active item
                newActiveItemRef = model;

            } else if (activeFor && _.indexOf(activeFor, pageId) !== -1) {
                
                //set new active item
                if(pageId.indexOf('c/news')<0)
                {
                    newActiveItemRef = model;
                }
                else{
                    oldActiveItemRef = model;
                }
            } else if (model.get('active')) {
                
                //found old active item
                oldActiveItemRef = model;
            }
        };

        //start iterating menu collection 
        this.menuRegion.currentView.collection.each( function (model) {
            iterateModel(model);
        });

        //re-render menu based on new active model found
        if (newActiveItemRef) {

            if (oldActiveItemRef) {
                oldActiveItemRef.set('active', false);
            }

            newActiveItemRef.set('active', true);
            this.menuRegion.currentView.render();

        }
        else if(oldActiveItemRef) {
            oldActiveItemRef.set('active', false);
            this.menuRegion.currentView.render();
        }
        else{
            throw new Error('Sidebar setActiveItem: Could not find a menu item '+
                'with pageId "' + pageId + '"');
        }

    },

    /**
     * Reinitialize the sidebar's menu with a new structure.
     * @param menu an appStructure, filtered by capabilities, to use for the new menu
     */
    reinitializeMenu : function reinitializeMenu(menu) {
        this.menuCollection = new SidebarMenuCollection(menu);
        this.menuView = new SidebarMenuView({
            collection  : this.menuCollection
        });

        this.menuRegion.show(this.menuView);
        this._configureEventListeners();
    },

    _configureEventListeners: function () {
        // "nav" events from the menu view are passed up to the parent
        // of the sidebar app
        this.listenTo(this.menuView, 'nav', function (title) {
            this.trigger('nav', title);
        });
    }

});

module.exports = Sidebar;
