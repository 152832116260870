/* global Marionette:false */
/**
 * Footer Module. Creates the footer view with the region by the options.region property.
 * Content for the footer is loaded from WCM.
 */

import FooterView from './views/footer-v';

var Footer = Marionette.Object.extend({
    
    errors : {
        parentRegion : 'Footer requires an options object with a region property!'
    },

    initialize (options) {

        if (!options || !options.region) {
            throw new Error(this.errors.parentRegion);
        }

        this.footerView = new FooterView({
            wcmPath : 'misc/footer'
        });

        options.region.show(this.footerView);
    }
});

module.exports = Footer;
