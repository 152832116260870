/* global Marionette */
var SEL                 = require('../selectors');
var sidebarViewTemplate = require('../templates/sidebar-t.hbs');
var utils               = require('../../../utils/utils');

var SidebarView = Marionette.LayoutView.extend({

    template: sidebarViewTemplate,

    templateHelpers: function(){
        return {
            MENU: SEL.REGIONS.MENU.substr(1)
        };
    },
    onRender: function () {
        // remove blank wrapping-div which will again break layout.
        utils.unwrapView(this);

        //enable sidebar
        //to get all ACE plugin list - please type '$.fn.ace_' in console
        //otherwise we need to modify the ACE script in order to support to
        //single page. Similar approach done at EBEN. any that one also require
        // declaration like this : _global.ace.initialize(_global.$); 
        this.$el.ace_sidebar();
    }

});

module.exports = SidebarView;
