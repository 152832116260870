/* global Backbone:false, _:false */

/**
 * Model object to back the pending-policy-datatable-info-v object.
 * 
 */

import {apiUrlRoot} from '../../../config/config';

const PendingPolicyListCountsModel = Backbone.Model.extend({
    defaults : {
        ALL       : 0,
        PENDING   : 0,
        PAID      : 0,
        INACTIVE  : 0
    },

    /**
     * Build url for datatable to fetch data
     * 
     * By default query param 'org' will be set as producer.
     * If model has 'producerOrgId' item 'org' will set as 'org'
     * 
     * @return {string} url
     */
    urlRoot () {
        let hierarchyValue = this.get('hierarchy') ? this.get('hierarchy') : 'producer';
        let producerIdNameValue = '';

        if (this.get('producerOrgId')) {
            if (hierarchyValue !== 'org') {
                hierarchyValue = 'org';
            }
            producerIdNameValue = '&producerId=' + this.get('producerOrgId');
        } else if (this.get('producerId')) {
            producerIdNameValue = '&producerId=' + this.get('producerId');
        }

        return apiUrlRoot + 'policies/summaries/counts?' +
            'hierarchy=' + hierarchyValue +
            '&statusView=pending' +
            producerIdNameValue;
    },

    /**
    * Life cycle method to parse model response objects
    */
    parse (response) {
        const reportingGroups = response.reportingGroups;
        if (reportingGroups) {
            _.each(reportingGroups, function (groups) {
                if (groups.group === 'ALL') {
                    response.ALL = groups.count;
                } else if (groups.group === 'PENDING') {
                    response.PENDING = groups.count;
                } else if (groups.group === 'PAID') {
                    response.PAID = groups.count;
                } else if (groups.group === 'INACTIVE') {
                    response.INACTIVE = groups.count;
                }
            });
        }
        return response;
    }

});

module.exports = PendingPolicyListCountsModel;
