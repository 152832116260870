/* global Backbone:false, _:false */

var checkpoint                   = require('../../../modules/checkpoint/checkpointModule');
var OrgsView                     = require('./orgs-v');
var PolicyListProducerInfoView   = require('./policy-list-producer-info-v');
var PendingPolicyListView        = require('./policy-list-v');
var policySearchOrgViewTemplate = require('../templates/policy-search-org-v-t.hbs');
var PendingSearchView            = require('./policy-search-wrapper-v');
var InforceSRView                = require('./inforce-sr-wrapper-v');
var template                     = require('../templates/policy-manager-t.hbs');
var utils                        = require('../../../utils/utils');
var validTabs                    = ['org', 'orgPolicies' ,'policies', 'search', 
    'inforce-service-request'];

const userChannel = Backbone.Radio.channel('user');

/**
 * Layout view for view of a Manager.
 * This view handles the initialization of the manager view page, and all of the submodules
 * displayed on the page. As a page, it should handle all interaction with the checkpoint module -
 * reading and writing the URL stateObj.
 */
var PolicyManagerView = Backbone.Marionette.LayoutView.extend({

    model: new Backbone.Model(),

    template : template,

    regions : {
        organizationRegion      : '#my-organization-region',
        orgPolicyListRegion     : '#org-pending-policies-region',
        orgProducerInfoRegion   : '#org-producer-info-region',
        policyListRegion        : '#my-pending-policies-region',
        producerInfoRegion      : '#producer-info-region',
        pendingSearchRegion     : '#pending-search-region',
        inforceSROrgRegion      : '#inforce-sr-org-region'
    },

    ui : {
        orgTab          : '#org-tab',
        orgPoliciesTab  : '#org-policies-tab',
        policiesTab     : '#policies-tab',
        searchTab       : '#search-tab',
        inforceSROrgTab : '#inforce-sr-org-tab',
        tabLinks        : '#orgTabs .oa-js-tab'
    },

    events : {
        'click @ui.tabLinks' : '_switchTab'
    },

    /**
     * Backbone/Marionette lifecycle function, called during the construction of the view.
     * This function will read the stateObj and build the initial state of the page, including the
     * various tabs and subviews.
     *
     * @param options
     */
    initialize : function initialize (options) {

        var newState      = {
            subpages : []
        };
        var orgPoliciesSubTabState  = {};
        var orgPolicyTab            = 'pending';
        var policyTab               = 'pending';
        var producerId              = null;
        var producerOrgId           = null;
        var searchOptions           = {};
        var subTabState             = {};
        var subpages;
        var inforceServiceRequestEnabled = true;

        // By default, show the orgTab.
        this.currentTab = 'search';

        // set the checkpoint module on the view, to make testing easier
        this.checkpoint = checkpoint;

        // Set up vars based on stateObj to pass to policy list view
        if (options && options.stateObj) {

            /* Hold on there, chum! What's up with these two? Well, "producerId" is the ID of the
               producer whose full page we're looking at - that producer's orgs in the org tab, that
               same producer's policies in the policies tab, etc. "producerOrgId" is the ID of the
               producer with an organization currently being displayed within the org tab.
             */
            producerId    = options.stateObj.producerId;
            producerOrgId = options.stateObj.producerOrgId;

            if (options.stateObj.subpages) {
                subpages = options.stateObj.subpages;

                //first level - sub page
                if (subpages[0] && validTabs.indexOf(subpages[0]) > -1) {

                    // URL has a subpage, that overrides 'org' default
                    this.currentTab = subpages[0];

                    newState.subpages.push(subpages[0]);
                }

                if (this.currentTab === 'org') {
                    this.orgsState = {};
                    searchOptions = utils.parseSearchParams(options.stateObj);
                    if (searchOptions && searchOptions.searchState) {
                        this.orgsState = searchOptions.searchState;
                    }

                    if (producerId) {
                        this.orgsState.producerId = producerId;
                    }
                    if (producerOrgId) {
                        this.orgsState.producerOrgId = producerOrgId;
                    }

                } else if (this.currentTab === 'search') {
                    searchOptions = utils.parseSearchParams(options.stateObj);

                    //clone search option and flatten
                    //this wll be used to restore query string while nav change
                    this.searchState =
                        Backbone.$.extend(true, {}, searchOptions, searchOptions.searchState);
                    delete this.searchState.searchState;
                }

                //second level - sub page - currently only applicable for Policies tab
                if (subpages[1] && this.currentTab === 'policies') {
                    policyTab = subpages[1];

                    if (policyTab) {
                        subTabState = utils.parseSearchParams(options.stateObj);
                    }

                    newState.subpages.push(subpages[1]);
                } 
                
                // Org Policies tab
                if (subpages[1] && this.currentTab === 'orgPolicies') {
                    orgPolicyTab = subpages[1];

                    if (orgPolicyTab) {
                        orgPoliciesSubTabState = utils.parseSearchParams(options.stateObj);
                    }

                    newState.subpages.push(subpages[1]);
                }

                delete options.stateObj.subpages;
                _.extend(newState, options.stateObj);

            } else {
                // no options.stateObj.subpages
                newState.subpages.push(this.currentTab);
                _.extend(newState, options.stateObj);
            }

        } else {
            // no options or options.stateObj
            newState.subpages.push(this.currentTab);
        }

        const hasHOCapability = userChannel.request('hasCapability', 'Home_Office');
        // don't display if HO
        if (hasHOCapability) {
            inforceServiceRequestEnabled = false;
        }
        this.model.set('inforceServiceRequestEnabled', inforceServiceRequestEnabled);

        // rewrite the checkpoint, making sure we have proper subpages and stuff.
        this.checkpoint.writeCheckpoint(newState, true);

        //create organization view
        this.orgView = new OrgsView({
            orgsState     : this.orgsState,
            producerId    : producerId,
            producerOrgId : producerOrgId
        });

        this.listenTo(this.orgView, 'stateChange', this._setCheckpoint);
        this.listenTo(this.orgView, 'hierarchyChange', this._addCheckpoint);
        this.listenTo(this.orgView, 'showProducerPolicyList',this._showProducerPolicyList);

        // Create the Policy List panel
        this.orgPendingPolicyListView = new PendingPolicyListView({
            currentTab      : orgPolicyTab,
            hierarchy       : 'org',
            hashPrefix      : '#policy-manager/orgPolicies',
            searchEnabled   : false,
            inforceServiceRequestEnabled : false,
            showInfoBox     : false,
            tabState        : orgPoliciesSubTabState
        });

        this.listenTo(this.orgPendingPolicyListView, 'stateChange', this._setCheckpoint);
        this.listenTo(this.orgPendingPolicyListView, 'showPolicy', this._showPolicy);
        this.listenTo(this.orgPendingPolicyListView, 'setProducerInfo', this._setOrgProducerInfo);

        // Create the Policy List panel
        this.pendingPolicyListView = new PendingPolicyListView({
            currentTab      : policyTab,
            hashPrefix      : '#policy-manager/policies',
            producerId      : producerId,
            searchEnabled   : false,
            inforceServiceRequestEnabled : false,
            showInfoBox     : false,
            tabState        : subTabState
        });

        this.listenTo(this.pendingPolicyListView, 'stateChange', this._setCheckpoint);
        this.listenTo(this.pendingPolicyListView, 'showPolicy', this._showPolicy);
        this.listenTo(this.pendingPolicyListView, 'setProducerInfo', this._setProducerInfo);

        this.listenTo(
            this.pendingPolicyListView,
            'producerIdRetrieved',
            this._setMyPoliciesProducerId
        );

        // Create the Search panel (override the default template)
        searchOptions.template = policySearchOrgViewTemplate;
        this.pendingSearchView = new PendingSearchView(searchOptions);
        this.listenTo(this.pendingSearchView, 'stateChange', this._setCheckpoint);
        this.listenTo(this.pendingSearchView, 'showPolicy', this._showPolicy);

        if (inforceServiceRequestEnabled) {
            this.inforceSROrgView = new InforceSRView({orgView: true});
        }
    },


    /**
     * Backbone/Marionette Lifecycle function. Called before showing the view.
     * 
     */
    onBeforeShow : function onBeforeShow () {
        
        // Call switchTab method to load activeTab content
        var activeTab = this._getTabCurrent();
        this._switchTab(activeTab.find('a'));
    },


    /**
     * Backbone/Marionette Lifecycle function. Called when rendering the view.
     * Remove Backbone's wrapper div and handle active tab style.
     */
    onRender : function onRender () {
        utils.unwrapView(this);
        this._setTabActive();

        // Pre-load organization data when default tab opened as Search
        if (this.currentTab !== 'pending' && this.currentTab === 'search') {
            this.showChildView('organizationRegion', this.orgView);
        }
    },


    /**
     * Handler called when the user clicks on a tab. Switch the current tab 
     * and reset the checkpoint.
     * 
     * This will load view based up on currentTab and frequent click 
     * never it create new instance,  instead it will show existing instance of view.
     * This change will fix an issue with defining correct checkpoint (paging and sorting)
     * while refreshing or navigating application using browser back button.
     * 
     * @param e the jQuery event
     * @private
     */
    _switchTab : function _switchTab (e) {
        var tabLink = e;
        var changedState = {};
        if (e.currentTarget) {
            e.preventDefault();
            tabLink      = Backbone.$(e.currentTarget);
        } 
        var tabName      = tabLink.data('tabname');
        this.currentTab  = tabName;

        // need to get subtab state...
        if (tabName === 'org') {
            this.showChildView('organizationRegion', this.orgView);
            changedState = this.orgsState;
        } else if (tabName === 'policies') {
            this.showChildView('policyListRegion', this.pendingPolicyListView);
            changedState = this.pendingPolicyListView.getCurrentTabState();
        } else if (tabName === 'orgPolicies') {
            this.showChildView('orgPolicyListRegion', this.orgPendingPolicyListView);
            changedState = this.orgPendingPolicyListView.getCurrentTabState();

        } else if (tabName === 'search') {
            this.showChildView('pendingSearchRegion', this.pendingSearchView);
            changedState = this.searchState;
        } else if (tabName === 'inforce-service-request') {
            this.showChildView('inforceSROrgRegion', this.inforceSROrgView);
        }

        this._setCheckpoint(changedState);
    },

    /**
     * Determines whether or not the id passed in data object is the
     * same producerId used in the "My Pending Policies" tab. If it is,
     * the "My Pending Policies" tab is viewed. Otherwise, the user will
     * be sent to the #producer-policy-list view.
     *
     * @param {object} data
     * @private
     */
    _showProducerPolicyList : function _showProducerPolicyList(data) {

        if (data) {

            // Determine whether or not the producer's policies
            // are currently displayed in the "My Pending Policies" tab
            if (data.producerId === this.myPoliciesProducerId) {
                // show the "My Pending Policies" tab
                this.ui.policiesTab.find('a').click();

                // move to the top of the page
                utils.scrollTo(Backbone.$('body'));

            } else {
                this.trigger('nav', '#producer-policy-list?producerId=' + data.producerId);
            }
        }
    },


    /**
     * Handle setting the 'active' class on the proper tab
     * @private
     */
    _setTabActive : function _setTabActive () {

        var activeTab = this._getTabCurrent();
        var activeTabPaneId = activeTab.find('a').data('target');

        activeTab.addClass('active');
        this.$el.find(activeTabPaneId).addClass('active'); 
    },

    /**
     * Gets the currently selected tab or the pending tab if none selected. 
     * 
     * @private
     */
    _getTabCurrent : function _getTabCurrent () {
        var defaultTab = this.ui.orgTab;

        // determine tab element name
        if (this.currentTab && this.currentTab === 'inforce-service-request') {
            return this.ui.inforceSROrgTab;
        } else if (this.currentTab && this.ui.hasOwnProperty(this.currentTab + 'Tab')) {
            return this.ui[this.currentTab + 'Tab'];
        } else {
            return defaultTab;
        }
    },

    /**
     * Navigate to the policy detail page
     * @param policyId the ID of the policy to display
     * @private
     */
    _showPolicy : function _showPolicy(policyId) {
        this.trigger('nav', '#policy-detail?policyId=' + policyId);
    },

    /**
     * Sets the policyId of the producer being viewed in "My Pending Policies"
     * so that we can determine whether or not the user is wanting to see this
     * tab's content when clicking on a "Policies" link.
     * @param info
     * @private
     */
    _setMyPoliciesProducerId : function _setMyPoliciesProducerId(info) {
        this.myPoliciesProducerId = info.producerId;
    },


    /**
     * Set the producer info in the producerInfoRegion. Currently limited to just the producer's
     * name, but is likely to include more summary info in the future.
     * @param info - the info to display in the view
     * @private
     */
    _setProducerInfo : function _setProducerName(info) {
        var producerInfoView = new PolicyListProducerInfoView({
            model : new Backbone.Model()
        });
        this.showChildView('producerInfoRegion', producerInfoView);
        producerInfoView.showName(info.fullName);
        this.$el.find('#producer-info-region').removeClass('hidden');
    },

    /**
     * Set the Org producer info in the orgProducerInfoRegion.
     * @param info - the info to display in the view
     * @private
     */
    _setOrgProducerInfo : function _setProducerName(info) {
        var producerInfoView = new PolicyListProducerInfoView({
            model : new Backbone.Model()
        });
        this.showChildView('orgProducerInfoRegion', producerInfoView);
        producerInfoView.showName(info.fullName, true);
        this.$el.find('#org-producer-info-region').removeClass('hidden');
    },

    /**
     * Set a checkpoint when the subpage(s) state changes.
     * @param changedState
     * @private
     */
    _setCheckpoint : function _setCheckpoint(changedState) {
        var newState = this._mergeState(changedState);

        // If there is 'targetuser' in the stateObj please add it back
        // Delegate and VaP access 
        if (this.options.stateObj && this.options.stateObj.targetuser) {
            newState.targetuser = this.options.stateObj.targetuser;
        }

        this.checkpoint.writeCheckpoint(newState, true);
    },

    /**
     * Add a checkpoint (do not replace current)
     * @param changedState
     * @private
     */
    _addCheckpoint : function _addCheckpoint(changedState) {
        var newState = this._mergeState(changedState);
        this.checkpoint.writeCheckpoint(newState, false);
    },

    /**
     * Util function used by _addCheckpoint and _setCheckpoint to merge the changedState param
     * with the existing state so that all state parameters are saved.
     * @param changedState
     * @returns {{subpages: [*]}}
     * @private
     */
    _mergeState : function _mergeState(changedState) {
        var newState = {subpages: [this.currentTab]};
        var subTab;

        if (this.currentTab === 'org') {
            this.orgsState = changedState;

        } else if (this.currentTab === 'policies') {

            // if switching to policy list, need to query for current sub tab
            subTab = this.pendingPolicyListView.getCurrentTab();

            newState.subpages.push(subTab);

        } else if (this.currentTab === 'orgPolicies') {

            // if switching to org policy list, need to query for current sub tab
            subTab      = this.orgPendingPolicyListView.getCurrentTab();
            newState.subpages.push(subTab);

        } else if (this.currentTab === 'search') {
            this.searchState = changedState;
        }

        newState = _.extend(newState, changedState);

        return newState;
    }
});

module.exports = PolicyManagerView;