/**
 * Main entry point to the Backbone app.
 *
 */

// Temporary fix to Siteminder decoding issue with URL hash
if (location.hash) {
    location.hash = decodeURIComponent(location.hash.replace(/--/g,'-'));
}

let OSOapp = require('./osoApp.js');
let app    = new OSOapp();

app.start();