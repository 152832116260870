// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "\r\n\r\n                <div id=\"warningMsg\" class=\"alert alert-warning\">\r\n                    <div class=\"alert-icon\"> <i class=\"ace-icon fa fa-bullhorn\"></i></div>\r\n                    <!-- /.alert-icon -->\r\n                    <p><strong>Warning!</strong> No delegates found for Producer.</p>\r\n                </div>\r\n\r\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.delegates : depth0)) != null ? stack1.length : stack1),5,{"name":"compare","hash":{"operator":"<="},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n                <ul id=\"delegatesList_OneCol\" class=\"list-vertical\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.delegates : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </ul>\r\n\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n                    <li>\r\n                        <h5>"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h5>\r\n                        <ul>\r\n                            <li>Login ID: <span>"
    + alias4(((helper = (helper = helpers.webLogonId || (depth0 != null ? depth0.webLogonId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"webLogonId","hash":{},"data":data}) : helper)))
    + "</span></li>\r\n                        </ul>\r\n                    </li>\r\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div id=\"delegatesList_TwoCols\" class=\"row\">\r\n                    <div class=\"col-md-6\">\r\n\r\n                        <ul class=\"list-vertical\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.delegates : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </ul>\r\n\r\n                    </div> <!-- /.col-md-6-->\r\n\r\n                    <div class=\"col-md-6\">\r\n                        <ul class=\"list-vertical\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.delegates : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 1, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </ul>\r\n                    </div> <!-- /.col-md-6-->\r\n                </div><!-- /.row -->\r\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.index),(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,((stack1 = (depths[1] != null ? depths[1].delegates : depths[1])) != null ? stack1.length : stack1),"/",2,{"name":"math","hash":{},"data":data}),{"name":"compare","hash":{"operator":"<"},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <li>\r\n                                <h5>"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h5>\r\n                                <ul>\r\n                                    <li>Login ID: <span>"
    + alias4(((helper = (helper = helpers.webLogonId || (depth0 != null ? depth0.webLogonId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"webLogonId","hash":{},"data":data}) : helper)))
    + "</span></li>\r\n                                </ul>\r\n\r\n                            </li>\r\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.index),(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,((stack1 = (depths[1] != null ? depths[1].delegates : depths[1])) != null ? stack1.length : stack1),"/",2,{"name":"math","hash":{},"data":data}),{"name":"compare","hash":{"operator":">="},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <li>\r\n                                <h5>"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h5>\r\n                                <ul>\r\n                                    <li>Login ID: <span>"
    + alias4(((helper = (helper = helpers.webLogonId || (depth0 != null ? depth0.webLogonId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"webLogonId","hash":{},"data":data}) : helper)))
    + "</span></li>\r\n                                </ul>\r\n                            </li>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<!-- Modal -->\r\n<div class=\"modal fade\" id=\"modal-delegates\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"modal-delegate-label\">\r\n    <div class=\"modal-dialog\" role=\"document\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span\r\n                        aria-hidden=\"true\">&times;</span></button>\r\n                <h4 class=\"modal-title\" id=\"modal-delegate-label\">View Delegates</h4>\r\n            </div>\r\n            <!-- /.modal-header -->\r\n            <div class=\"modal-body\">\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.delegates : depth0)) != null ? stack1.length : stack1),0,{"name":"compare","hash":{"operator":"=="},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\r\n            </div>\r\n            <!-- /.modal-body -->\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\">OK</button>\r\n            </div>\r\n            <!-- /.modal-footer -->\r\n        </div>\r\n        <!-- /.modal-content -->\r\n    </div>\r\n    <!-- /.modal-dialog -->\r\n</div>\r\n<!-- /.modal -->";
},"useData":true,"useDepths":true});
