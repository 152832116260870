/* global Backbone:false, _:false */

/**
 * A utility sub module with all Datatable utility methods.
 *
 */
 var config           = require('../config/config');
 var moment           = require('moment-timezone');
 var utilsUI          = require('./utils-ui');
 var utilsFormatting  = require('./utils-formatting');
 
 // dataAvailability parser class from policy model parsers
 var policyDataAvailability = require('../pages/policy/models/parsers/policy-data-availability');
 
 var utilsDatatable = {
 
     errors: {
         dataTableObject           : 'dtObject should be dataTable object',
         dataTableOptionInvalidURL : 'url parameter should be in string format'
     },
 
     /**
      * Mapping of "status description" values to the tab / coloring groups
      * Also used as ordering by concatenating pending, paid, inactive lists
      */
     policyStatusReportingGroup: {
         pending: [
             'Application Received',
             'In Process',
             'In Underwriting',
             'Requirements Outstanding, Underwriting Complete',
             'Requirements Outstanding, Not Issued',
             'Ready for Issue',
             'Issued Not Paid',
             'Ready for Payment'
         ],
         paid: [
             'Paid'
         ],
         inactive: [
             'Declined',
             'Postponed',
             'Withdrawn',
             'Incomplete',
             'Terminated',
             'Expired',
             'Surrendered',
             'Lapse',
             'Not Taken'
         ]
     },
 
     /**
      * NOT USING ANYWHERE NOW. Can be removed?
      * Return an array for the ordering of status description
      * @returns {Array.<*>}
      */
     getPolicyStatusOrder: function() {
         return this.policyStatusReportingGroup.pending.concat(
             this.policyStatusReportingGroup.paid,
             this.policyStatusReportingGroup.inactive
         );
     },
 
     /**
      * Format a DataTable.
      * The Design for results requires some extra elements for styling the markup that DataTables
      * creates. This function will find and wrap the DataTables elements in divs with the correct
      * bootstrap-matching classes for styling.
      *
      * This function should only be called on the initial draw, otherwise it will keep adding
      * elements during sorting and paging.
      *
      * @param $dataTableWrapper the jQuerified div element wrapping the target dataTable
      */
     formatDataTable : function formatDataTable($dataTableWrapper) {
         var paginateWrapper = '<div class="row"><div class="col-xs-12"></div></div>';
         var rowWrapper      = '<div class="row"></div>';
         var columnWrapper   = '<div class="col-md-6"></div>';
         var tableWrapper    = '<div class="table-responsive"></div>';
 
         // Wrap the info and length elements in a row
         $dataTableWrapper.find('.dataTables_info').each(function () {
             Backbone.$(this).next('.dataTables_length').addBack().wrapAll(rowWrapper);
         });
 
         // Wrap the table
         $dataTableWrapper.find('table').wrap(tableWrapper);
 
         // Wrap the pagination controls
         $dataTableWrapper.find('.dataTables_paginate').wrap(paginateWrapper);
 
         // wrap each info and length element
         $dataTableWrapper.find('.dataTables_info, .dataTables_length').wrap(columnWrapper);
     },
 
     /**
      * Returns the CSS style to depict the status of a policy.
      * Special "progress" styles are applied to pending policies (OOSO-3259).
      * @param {string} statusDescription lexical status (policyStatus.description)
      * @param {string} status The actual "policyStatus.status" text
      * @param {string} acordHoldingStatus The actual "policyStatus.acordHoldingStatus" text
      * @return {string}
      */
     getCssClassForStatus : function getCssClassForStatus(statusDescription, status,
                                                           acordHoldingStatus) {
         var cellClass = '';
         var type;
 
         if (_.contains(['Active', 'Unknown', 'Dormant', 'Inactive'], acordHoldingStatus)) {
             cellClass = 'label-transparent';
         } else if (acordHoldingStatus === 'Proposed') {
             if (status === 'Issued' && statusDescription === 'Paid') {
                 type = 'paid';
             } else if ( 
                 _.contains(['Declined', 'Postponed', 'Withdrawn', 'Incomplete'], statusDescription)
             ) {
                 type = 'inactive';
             } else {
                 type = 'pending';
             }
         }
 
         if (type === 'pending') {
             cellClass = 'label-yellow';
 
             // Determine CSS class to display "progress bar"
             if (_.contains(['Application Received', 'In Process'], statusDescription)) {
                 cellClass = cellClass + '-0';
 
             } else if (statusDescription === 'In Underwriting') {
                 cellClass = cellClass + '-25';
 
             } else if (statusDescription === 'Requirements Outstanding, Underwriting Complete') {
                 cellClass = cellClass + '-50';
 
             } else if (statusDescription === 'Requirements Outstanding, Not Issued') {
 
                 cellClass = cellClass + '-75';
 
             } else if (_.contains(['Issued Not Paid', 'Ready for Payment'], statusDescription)) {
                 
                 // These two are 50% green
                 cellClass = 'label-success-50';
             }
 
         } else if (type === 'paid') {
             cellClass = 'label-success';
         } else if (type === 'inactive') {
             cellClass = 'label-grey';
         }
 
         return cellClass;
     },
 
     /**
      * Method set value as null if 'dataAvailability' property contain 'notAvailable'
      * value.
      * 
      * @param {object} data data which have dataAvailability properties
      * @return {object} policyStatus updated version  of policyStatus
      */
     nullifyPolicyStatusNotAvailableProps : function nullifyPolicyStatusNotAvailableProps(data) {
         var dataCopy;
         if (data.policyStatus && _.isObject(data.policyStatus)) {
             dataCopy = Backbone.$.extend(true, {}, data);
             if (_.contains(['Dormant','Unknown'], dataCopy.policyStatus.acordHoldingStatus)) {
                 dataCopy = policyDataAvailability.nullifyNotAvailableChildProperties(dataCopy);
             } 
         }
 
         return dataCopy;
     },
 
     /**
      * Sets the defaultContent property on column objects in the "columns" array.
      * If the defaultContent property already exists on any column object, this
      * value is not overwritten.
      * @param {Array|Object} columns - An array of column objects
      * @param {string} [value=""] - value to attach to the defaultContent property
      * @returns {*}
      */
     setDatatablesDefaultContent : function setDatatablesDefaultContent(columns, value) {
         var defaultValue = '';
 
         if (!_.isArray(columns)) {
             throw new Error('Columns param must be an array');
         }
 
         if (!_.isUndefined(value)) {
             defaultValue = value;
         }
 
         _.each(columns, function (column) {
             // if the defaultContent property
             // doesn't exist on the column, set it
             if (_.isObject(column) && !_.has(column, 'defaultContent')) {
                 column.defaultContent = defaultValue;
             }
         });
 
         return columns;
     },
 
     /**
      * Build the link for policy number. If the cellData parameter is defined, then the link will
      * use that value as the text, and will have a querystring param of policyId with a value of
      * rowData.policyId. Otherwise, the text will be "Recently Received" with a querystring param
      * of caseId with a value given by rowData.caseId
      *
      * @param cellData The value of the policyNumber property
      * @param rowData The hash of values for the entire row.
      * @returns {string} a string representation of the link.
      */
     buildPolicyNumberLink: function(cellData, rowData) {
 
         var hashURL = '#policy-detail?<%= param %>=<%= paramVal %>';
         
         // Add Impersonated webId in 'targetuser' query param if user is in impersonate state
         hashURL = utilsUI.addTargetUserQueryParamToURL(hashURL);
 
         var linkTemplate = _.template(
             '<a href="'+hashURL+'" class="oa-js-nav">'+
             '<%= label %></a>'
         );
 
         var displayLabel = cellData;
         var name         = 'policyId';
         var value        = rowData.policyId;
 
         if (! cellData) {
             displayLabel = 'Recently Received';
             name         = 'caseId';
             value        = rowData.caseId;
         }
 
         return linkTemplate({
             param    : name,
             paramVal : value,
             label    : displayLabel
         });
     },
 
     buildPendingCountLink: function buildPendingCountLink(data) {
         var badgeType = 'info';
         var retVal    = '';
         
         var hashURL = '#policy-detail/requirements/?'+
             '<%= param %>=<%= paramVal %>';
         
         // Impersonated webId in 'targetuser' query param
         hashURL = utilsUI.addTargetUserQueryParamToURL(hashURL);
 
         var linkTemplate = _.template('<a href="'+hashURL+'">'+
             '<span class="badge badge-<%= badgeType %>" title="<%=reqTitle %>">' +
             '<%= count %></span></a>');
 
         var paramName = 'policyId';
         var paramVal  = data.policyId;
         var reqTitle  = 'Requirement';
 
         // Just in case it's possible to have an AWD-only policy with outstanding requirements
         if (!data.policyId) {
             paramName = 'caseId';
             paramVal  = data.caseId;
         }
 
         if (data && data.pendingRequirementCount) {
             if (data.pendingRequirementCount > 1) {
                 reqTitle = reqTitle + 's';
             }
 
             // If changing the threshold from 9 to another number, be sure
             // to to also update `pages/pending/partials/jump-links.hbs`.
             if (data.pendingRequirementCount > 9) {
                 badgeType = 'important';
             }
             reqTitle = data.pendingRequirementCount + ' ' + reqTitle + ' Outstanding';
             retVal = linkTemplate({
                 badgeType : badgeType,
                 count     : data.pendingRequirementCount,
                 param     : paramName,
                 paramVal  : paramVal,
                 reqTitle  : reqTitle
             });            
         }
 
         return retVal;
     },
 
     /**
      * Render the Policy number value in the table. Uses the columns.render function from
      * DataTables: https://datatables.net/reference/option/columns.render
      *
      * @param data the data for this cell in the DataTable
      * @param type one of: filter, display type, or sort
      * @param row the full data source for the row
      * @param meta additional info abou the cell (row, col, settings)
      * @returns {String} HTML to be rendered in the table cell
      * @private
      */
     renderDataTablePolicyNumber: function renderDataTablePolicyNumber(data, type, row, meta) {
         var linkHtml = utilsDatatable.buildPolicyNumberLink(data, row);
         return linkHtml;
     },
 
     /**
      * After rendering the TD, set the proper link for the policy number column. Called via the
      * columns.createdCell callback in DataTables. Using this, as opposed to columns.render, allows
      * DataTables to naturally sort on the column value rather than the resulting HTML of the table
      * cell.
      *
      * https://datatables.net/reference/option/columns.createdCell
      *
      * @param {DOM} td       TD element
      * @param {string} cellData value of cell
      * @param {[type]} rowData  Data source object / array for the whole row
      * @param {number} row      DataTables' internal index for the row
      * @param {number} col      DataTables' internal index for the column
      * @private
      */
     setDataTablePolicyNumberLink: function (td, cellData, rowData, row, col) {
         var policyLinkHtml  = utilsDatatable.buildPolicyNumberLink(cellData, rowData);
         var pendingLinkHtml = utilsDatatable.buildPendingCountLink(rowData);
         var linkHtml        = policyLinkHtml;
 
         if (pendingLinkHtml) {
             linkHtml += '&nbsp;' + pendingLinkHtml;
         }
 
         Backbone.$(td).html(linkHtml);
     },
 
     /**
      * Render a date value in the table, calls the formatDate function in utils. Uses the
      * columns.render function from DataTables:
      * https://datatables.net/reference/option/columns.render
      *
      * @param data the data for this cell in the DataTable
      * @param type one of: filter, display type, or sort
      * @param row the full data source for the row
      * @param meta additional info abou the cell (row, col, settings)
      * @returns {String} HTML to be rendered in the table cell
      * @private
      */
     dataTableFormatDate: function (data, type, row, meta) {
         return utilsFormatting.formatDate(data);
     },

    /**
     * Render a currency value in the table, calls the formatAsCurrency function in utils. Uses the
     * columns.render function from DataTables:
     * https://datatables.net/reference/option/columns.render
     *
     * @param data the data for this cell in the DataTable
     * @param type one of: filter, display type, or sort
     * @param row the full data source for the row
     * @param meta additional info abou the cell (row, col, settings)
     * @returns {String} HTML to be rendered in the table cell
     * @private
     */
     dataTableFormatCurrency: function (data, type, row, meta) {
         if(!data){
             return '';
         }
         return utilsFormatting.formatAsCurrency(data);
     },
     /**
      * Datatable - 'render' callback method 
      * Mainly used in producer name/number result view or producer delegate list view
      *
      * Filters an array of role codes and returns the ID's for all active
      * (role.statusCode === "A") roles.
      *
      * @param {*} data the data for this cell in the DataTable
      * @param {string} type one of: filter, display type, or sort
      * @param {*} row the full data source for the row
      * @param {object} meta additional info about the cell (row, col, settings)
      * @returns {string} HTML to be rendered in the table cell
      */
     getActiveProducerRoleCodesAsString : function(data, type, row, meta) {
         var activeRoleCodes = [];
         var i;
 
         for (i = 0; i < data.length; i++) {
             var role = data[i];
             if (role.statusCode === 'A') {
                 activeRoleCodes.push(role.roleCode);
             }
         }
 
         return activeRoleCodes.sort().join(', ');
     },
 
     /**
      * NOT USING ANY WHERE, CAN BE REMOVED?
      * Sort on the receivedDateAndPolicyNumber field.
      *
      * @param x the first value
      * @param y the second value
      * @returns {number} 1 if the first value should be first, -1 if second should be first, or
      *                   zero if the values are equal
      */
     receivedDateAndPolicyNumberSort: function(x, y) {
         var result  = 0;
         var xDate   = null;
         var xPolicy = '';
         var yDate   = null;
         var yPolicy = '';
 
         if (x) {
             if (x.receivedDate) {
                 xDate = x.receivedDate;
             }
             if (x.policyNumber) {
                 xPolicy = x.policyNumber;
             }
         }
         if (y) {
             if (y.receivedDate) {
                 yDate = y.receivedDate;
             }
             if (y.policyNumber) {
                 yPolicy = y.policyNumber;
             }
         }
 
         if ((xDate && !yDate) || moment(xDate).isAfter(yDate)) {
             result = 1;
         } else if ((!xDate && yDate) || moment(yDate).isAfter(xDate)) {
             result = -1;
         }
 
         if (result === 0) {
             result = yPolicy.localeCompare(xPolicy);
         }
 
         return result;
     },
 
     /**
      * NOT USING ANY WHERE CAN BE REMOVED - Its part of above method
      * Sort on receivedDateAndPolicyNumber column. Delegates to the receivedDateAndPolicyNumberSort
      * and the reverseSort functions.
      *
      * @param x the first value
      * @param y the second value
      * @returns {number} 1 if the first value should be first, -1 if second should be first, or
      *                   zero if the values are equal
      */
     receivedDateAndPolicyNumberSortDesc: function(x, y) {
         return utilsDatatable.reverseSort(utilsDatatable.receivedDateAndPolicyNumberSort(x, y));
     },
 
     /**
      * NOT USING ANY WHERE CAN BE REMOVED - Its part of above method
      * Simply reverse the result of a sort function call. 
      * This allows us to create one sort function
      * (usually asc) and simply call this in the opposite function.
      *
      * @param value the value from the original sort call
      * @returns {number} the opposite of the input (or zero).
      */
     reverseSort: function(value) {
         if (value < 0) {
             return 1;
         } else if (value > 0) {
             return -1;
         } else {
             return 0;
         }
     },
 
     /**
      * NOT USING ANY WHERE CAN BE REMOVED - Its part of above method 
      * Add custom sorts to DataTables. To use these sorts, set the column's 'type' property to
      * one of the following:
      *
      * - 'policy-status'             - to sort based on the order given by the
      *                                 `getPolicyStatusOrder` property.
      *
      * - receivedDateAndPolicyNumber - to sort based on an object that contains receivedDate and
      *                                 policyNumber properties
      */
     addDataTableCustomSorts : function() {
 
         // Create the ascending sort for policy status
         Backbone.$.fn.dataTableExt.oSort['policy-status-asc'] = this._oSortPolicyStatusAsc;
 
         // Create the descending sort for policy status
         Backbone.$.fn.dataTableExt.oSort['policy-status-desc'] = this._oSortPolicyStatusDesc;
 
         // Create a sort for appReceived (ascending) and policyNumber (descending)
         Backbone.$.fn.dataTableExt.oSort['receivedDateAndPolicyNumber-asc'] =
             utilsDatatable.receivedDateAndPolicyNumberSort;
 
         // Create a sort for appReceived (descending) and policyNumber (ascending)
         Backbone.$.fn.dataTableExt.oSort['receivedDateAndPolicyNumber-desc'] =
             utilsDatatable.receivedDateAndPolicyNumberSortDesc;
     },
 
     /**
      * Method to bind 'policy-status-asc' event to datatables.oSort
      * 
      * @private
      */
     _oSortPolicyStatusAsc : function(x, y) {
         
         // Get the ordering to use as a lookup the custom sorting.
         var order = this.getPolicyStatusOrder();
         var xOrder = order.indexOf(x);
         var yOrder = order.indexOf(y);
 
         return ((xOrder < yOrder) ? -1 : ((xOrder > yOrder) ? 1 : 0));
     },
 
     /**
      * Method to bind 'policy-status-desc' event to datatables.oSort
      * 
      * @private
      */
     _oSortPolicyStatusDesc : function(x, y) {
 
         // Get the ordering to use as a lookup the custom sorting.
         var order = this.getPolicyStatusOrder();
         var xOrder = order.indexOf(x);
         var yOrder = order.indexOf(y);
 
         return ((xOrder < yOrder) ? 1 : ((xOrder > yOrder) ? -1 : 0));
     },
 
     /**
      * Bind common events to datatable object
      * @param  {object} dtObject Group object require to bind event on DataTable
      *
      * Expected object and purpose:-
      *      dtObject            : table                 // instance of datatable
      *      viewScope           : this                  // `this` scope of view
      *      viewName            : 'org-table-v: '       // file name of view
      *      spinnerChannel      : spinnerChannel        // radio channel for spinner
      *      debugModule         : debugModule,          // debug Module object
      *      addOrderListener    : true                  // Added sort 'order' event 
      */
     bindDataTableEvents : function (param) {
 
         if (!param || (param.dtObject 
                 && !(param.dtObject instanceof Backbone.$.fn.dataTable.Api))){
             throw new Error(this.errors.dataTableObject);
         } else {
             
             //bind common event used
             param.dtObject
             .on('init', 
                 _.bind(this._dataTableEventInitCallback, param)
             ).on('error', 
                 _.bind(this._dataTableEventErrorCallback, param)
             ).on('length', 
                 _.bind(this._dataTableEventLengthCallback, param)
             ).on('page', 
                 _.bind(this._dataTableEventPageCallback, param)
             ).on('processing', 
                 _.bind(this._dataTableEventProcessingCallback, param)
             ).on('column-sizing draw', 
                 _.bind(this._dataTableEventColumnSizingCallback, param)
             );
 
             // Add 'order' event listener
             if (param.addOrderListener) {
                 param.dtObject.on('order', _.bind(this._dataTableEventOrderCallback, param));
             }
         }
     },
 
     _dataTableEventInitCallback : function($event, dataTablesSettings) {
         
         // add the responsive table instruction (swipe/scroll message)
         // just above the bottom dataTables_info node.
         this.viewScope.$el.find('#' + dataTablesSettings.nTableWrapper.id + ' .dataTables_info')
             .eq(1)
             .before(
                 Backbone.$(this.viewScope.ui.responsiveTableInstruction).append('<br/>')
             );
     },
 
     _dataTableEventErrorCallback : function($event, dataTablesSettings, techNoteNumber, message) {
         if (techNoteNumber === 4) {
             
             // A row has a missing property. DT renders an empty cell and the message refers
             // to this condition as a 'warning'. Let's just log that it's happening, but not
             // show an error to the user.
             this.debugModule.warn(this.viewName + message); 
             
         } else {
             this.spinnerChannel.trigger('hide');
             this.viewScope.trigger('error');
         }
     },
 
     _dataTableEventLengthCallback : function($event, dataTableSettings, length) {
         var page = this.dtObject.page.info();
         this.viewScope.trigger('tableStateChange', {length: length, start: page.start});
     },
 
     _dataTableEventPageCallback : function($event, dataTablesSettings) {
         var page = this.dtObject.page.info();
         this.viewScope.isPaging = true;
         this.viewScope.trigger('tableStateChange', {start: page.start});
     },
 
     _dataTableEventProcessingCallback : function($event, datatablesSettings, processing) {
         
         //As per current implementation we are only need this event to show 
         //wait indicator before starting any process like sorting, pagination
 
         //This will be fired after every fetch completed (Initial and concurrent),
         //but currently we are using "XHR" event to hide the wait indicator 
         //there are some reason to use "XHR" event, 
         //like if any error (abort, timeout etc..) occured 
         //while "processing" event never get called, 
         //but XHR will be called (success / error)with statusText.
         if (processing) {
             this.spinnerChannel.trigger('show', {
                 viewScope : this.viewScope,
                 position  : 'fixed'
             });
         } 
     },
 
     _dataTableEventColumnSizingCallback : function($event, dataTablesSettings) {
 
         // Check to see if the ".table-responsive-instruction" should be shown
         var tableContainer = Backbone.$(dataTablesSettings.nTable)
             .closest('.table-responsive');
         var instructionDiv = Backbone.$(
             Backbone.$(dataTablesSettings.nTable).data('instruction-container'));
         utilsUI.setVisibilityOnSmartScrollMessage(tableContainer, instructionDiv);
     },
  
     _dataTableEventOrderCallback : function($event, dataTablesSettings) {
         var order = this.dtObject.order();
 
         // 'order' event gets fired on initial draw and on paging. 
         // We don't really want to trigger a state change from 'order' 
         // during those times.
         if (!this.viewScope.isPaging && this.viewScope.isLoaded) {
             this.viewScope.trigger('tableStateChange', {
                 start   : 0, 
                 col     : order[0][0], 
                 dir     : order[0][1]
             });
         }
 
         this.viewScope.isLoaded = true;
         this.viewScope.isPaging = false;
 
     },
 
     /**
      * Abort all pending ajax request initiated by DataTable
      * @param {string} tableId ID of dataTable 
      * 
      */
     abortAllDataTableAjaxRequest : function abortAllDataTableAjaxRequest (tableId) {
         var allDTSettings;
         var table;
         if (Backbone.$.fn.dataTable && tableId) {
             table = Backbone.$(Backbone.$.fn.dataTable.tables()).filter(tableId);
             if (table.length) {
                 allDTSettings = table.DataTable().settings();
                 _.each(allDTSettings, function (settings) {
                     if (settings.jqXHR && settings.jqXHR.readyState < 4) {
                         settings.jqXHR.abort();
                     }
                 });  
             }
             
         }
     },
 
     /**
      * Create and share common Datatable config options 
      * 
      * @param {object}  viewScope   Scope ('this') of the view
      * @param {string}  url         Rest URL
      * @param {boolean} cache       To enable caching feature.
      * 
      * @return {DataTableObject}              dataTable object
      */
     getDatatableOptions : function getDatatableOptions (viewScope, url, cache) {
 
         var options = {
 
             // page length options
             lengthMenu : [25, 50, 100],
 
             // disable multiple-column ordering
             orderMulti : false,
 
             // show a 'processing' indicator when the widget is waiting/working
             processing : false,
 
             // don't allow the built-in search input
             searching : false,
 
             // Use server-side processing for sorting and paging
             serverSide : true
         };
         // Cache storage
         var cacheStore = require('../modules/localStorageMemory/localStorageMemory');
 
         var ttl;
 
         if (url && _.isString(url)) {
             options.ajax = {
                 url : url
             };
         } else {
             throw new Error(this.errors.dataTableOptionInvalidURL);
         }
 
         if (cache && options.ajax) {
 
             // Cache expiry time
             ttl = (config && config.cache && config.cache.ttl) ? config.cache.ttl : 0.0;
 
             options.ajax.cache      = true;
             options.ajax.cacheTTL   = ttl;
 
             
             options.ajax.localCache = cacheStore;
                         
             // dataSrc can be used to alter data retrieved from ajax
             // but prior to being displayed. In this case, we'll handle
             // showing/hiding the results. Errors will still be handled in
             // the `xhr` event for datatables.
             options.ajax.dataSrc = _.bind(this._dataSourceCallback, viewScope);
         }
 
         return options;
     },

    /**
     * POST call configuration for Resource Center page
     * 
     * @param {object}  viewScope   Scope ('this') of the view
     * @param {string}  url         Rest URL
     * @param {boolean} cache       To enable caching feature.
     * 
     * @return {DataTableObject}              dataTable object
     */
     postDatatableOptions : function postDatatableOptions (viewScope, url, cache) {

         var options = {
             // page length options
             lengthMenu : [25, 50, 100],
             // disable multiple-column ordering
             orderMulti : false,

             // show a 'processing' indicator when the widget is waiting/working
             processing : false,

             // don't allow the built-in search input
             searching : false,

             // Use server-side processing for sorting and paging
             serverSide : true
         };
         // Cache storage
         var cacheStore = require('../modules/localStorageMemory/localStorageMemory');

         var ttl;
         var _viewdata = viewScope;
         var _this = this;
         if (url && _.isString(url)) {
             options.ajax = {
                 url : url,
                 method: 'POST',
                 dataType: 'JSON',
                 contentType: 'application/json',
                 data: function(d) {
                     d =JSON.parse(_this._getRequestParams(d));
                     if (_viewdata.searchItem) {
                         d.search.value = _viewdata.searchItem;
                     }
                     return JSON.stringify(d);
                 }
             };
         } else {
             throw new Error(this.errors.dataTableOptionInvalidURL);
         }
         
         if (cache && options.ajax) {

             // Cache expiry time
             ttl = (config && config.cache && config.cache.ttl) ? config.cache.ttl : 0.0;

             options.ajax.cache      = true;
             options.ajax.cacheTTL   = ttl;

            
             options.ajax.localCache = cacheStore;
                        
            // dataSrc can be used to alter data retrieved from ajax
            // but prior to being displayed. In this case, we'll handle
            // showing/hiding the results. Errors will still be handled in
            // the `xhr` event for datatables.
             options.ajax.dataSrc = _.bind(this._dataSourceCallback, viewScope);
         }

         return options;
     },

     /**
      *  
      * This function is used to add POST request paramters in body
      * called from postDatatableOptions() function.
      * @param {Object} d post request parameters in body
      * @returns {Object} Array of Categories object
      */
     _getRequestParams: function _getRequestParams(d) {
         let categories = [];
         let reqObj =  {};
         let reqParam = ['meta_ROLE'];
         for (let i = 0; i < reqParam.length; i++) {
             let name = reqParam[i];
             if (name === 'meta_ROLE') {
                 reqObj.name = name;
                 reqObj.value = utilsUI._getTAXRolesWithGroups(true);
             }
             categories.push(reqObj);

         }
         d.categories = categories;
         return JSON.stringify(d);
     },
    
 
     /**
      * Datatable 'dataSrc' callback method
      *
      * This will be configured in getDatatableOptions method if caching is enabled
      * Scope of this method will be bind with current view scope
      * So all the object (view) access should exist that scope
      * 
      * @param  {object} jsonResponse default callback parameter
      * @return {object} data data object reside in jsonResponse.
      */
     _dataSourceCallback : function _dataSourceCallback(jsonResponse) {
 
         if (this.spinnerChannel && this.spinnerChannel instanceof Backbone.Radio.Channel) {
             this.spinnerChannel.trigger('hide', this);
         }
 
         if (jsonResponse && _.has(jsonResponse, 'recordsTotal')) {
             
             // Handle no results being returned
             if (jsonResponse.recordsTotal === 0) {
                 this.trigger('noResults');
             } else {
 
                 if (this.ui && this.ui.clientResultsWrapper 
                     && this.ui.clientResultsWrapper instanceof Backbone.$) {
                     
                     // display the results
                     this.ui.clientResultsWrapper.removeClass('hidden');
 
                     // scroll to results section
                     utilsUI.scrollTo(this.ui.clientResultsWrapper);
                 }
             }
         }
 
         return jsonResponse.data;
     },
 
     /**
      * Some attributes need to be Number, or odd things tend to happen.
      * 
      * @param model the model or viewModel
      * @param attr the name of the attribute
      * @param value the value of the attribute
      * @private
      */
     setNumber : function setNumber(model, attr, value) {
         var numberValue = model.defaults[attr];
         if (value && !isNaN(value)) {
             numberValue = Number(value);
         }
         model.set(attr, numberValue);
     },
 
     /**
      * Formats the date in the field if data is truthy.
      *
      * @param {DOM} td          TD element
      * @param {string} cellData value of cell
      * @param {[type]} rowData  data source object / array for the whole row
      * @param {number} row      DataTables' internal index for the row
      * @param {number} col      DataTables' internal index for the column
      *
      * @private
      */
     formatDateCell: function formatDateCell(td, cellData, rowData, row, col) {
         var dateData = '';
         if (cellData) {
             dateData = utilsFormatting.formatDate(cellData);
         }
         Backbone.$(td).html(dateData);
     },
 
     /**
      * Formats the ISO Days in the field if data is truthy.
      *
      * @param {DOM} td          TD element
      * @param {string} cellData value of cell
      * @param {[type]} rowData  data source object / array for the whole row
      * @param {number} row      DataTables' internal index for the row
      * @param {number} col      DataTables' internal index for the column
      *
      * @private
      */
     formatDaysCell: function formatDaysCell(td, cellData, rowData, row, col) {
         var daysData = '';
         if (cellData) {
             daysData = utilsFormatting.isoDurationToTimeUnit(cellData, 'day');
         }
         Backbone.$(td).html(daysData);
     },
 
     /**
      * Determine the ordering to use for the table.
      *
      * @param {object} the default ordering
      * @param {object} the state object, which may be null
      *
      * @returns {Array} an Array in the form of:
      * [
      *     [col, dir]
      * ]
      *
      * @private
      */
     getTableOrdering: function getTableOrdering(defaultOrder, state) {
         var ordering = [[]];
         ordering[0][0] = defaultOrder[0][0];
         ordering[0][1] = defaultOrder[0][1];
     
         if (state) {
 
             // Need to explicitly check for col === 0, because it's valid even though it's falsy
             if (state.col || state.col === 0) {
                 ordering[0][0] = Number(state.col);
             }
 
             if (state.dir) {
                 ordering[0][1] = state.dir;
             }
         }
 
         return ordering;
     },
 
 
     renderCommissionAccess: function (data, type, row, meta) {
         if (data) {
             return 'Full Access';
         } else {
             return 'Limited Access';
         }
     },
 
     renderRegisteredStatus: function (data, type, row, meta) {
         // check webId / webLogonId
         if (data) {
             return 'Yes';
         } else {
             return 'No';
         }
     } 
 };
 
 module.exports = utilsDatatable;
 