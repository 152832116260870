// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "            <div class=\"panel panel-default\">\r\n                <div class=\"panel-heading\">\r\n                    <h4 class=\"panel-title\">\r\n                        <a class=\"accordion-toggle "
    + ((stack1 = helpers.unless.call(alias1,(data && data.first),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + " pointer\" data-toggle=\"collapse\" href=\"#collapse"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "\">\r\n                            <i class=\"ace-icon fa fa-angle-"
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams),"inverse":container.program(7, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + " bigger-110\" data-icon-hide=\"ace-icon fa fa-angle-down\" data-icon-show=\"ace-icon fa fa-angle-right\"></i>\r\n\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.key),"primaries",{"name":"compare","hash":{},"fn":container.program(9, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.key),"beneficiaries",{"name":"compare","hash":{},"fn":container.program(14, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.key),"others",{"name":"compare","hash":{},"fn":container.program(16, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "&nbsp;("
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.length : stack1), depth0))
    + ")\r\n                        </a>\r\n                        <!-- end of a.accordion -->\r\n                    </h4>\r\n                    <!-- /.panel-title -->\r\n                </div>\r\n                <!-- /.panel-heading -->\r\n                <div class=\"collapse "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\" id=\"collapse"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "\" aria-expanded=\"false\">\r\n                    <div class=\"panel-body\">\r\n                        <div class=\"table-responsive\">\r\n                            <table class=\"table table-striped table-bordered table-hover\">\r\n                                <thead>\r\n                                <tr role=\"row\">\r\n                                    <th>Name</th>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(data && data.key),"primaries",{"name":"compare","hash":{},"fn":container.program(20, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                                    <th>Relationship</th>\r\n                                    <th>"
    + alias4(alias5((depth0 != null ? depth0.ssnColumnLabel : depth0), depth0))
    + "</th>\r\n                                    <th>Address</th>\r\n                                    <th>Contact Info</th>\r\n                                </tr>\r\n                                </thead>\r\n                                <!-- end thead -->\r\n                                <tbody>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.data : depth0),{"name":"each","hash":{},"fn":container.program(22, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                                </tbody>\r\n                            </table>\r\n                        </div>\r\n                        <!-- /.table-responsive -->\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","data":data,"blockParams":blockParams,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\r\n                    <!-- /.panel-body -->\r\n                </div>\r\n                <!-- /.collapse -->\r\n            </div>\r\n            <!-- /.panel -->\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " collapsed ";
},"5":function(container,depth0,helpers,partials,data) {
    return "down ";
},"7":function(container,depth0,helpers,partials,data) {
    return "right";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.isAnnuity),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "                                        Annuitant";
},"12":function(container,depth0,helpers,partials,data) {
    return "Insured";
},"14":function(container,depth0,helpers,partials,data) {
    return "Beneficiary";
},"16":function(container,depth0,helpers,partials,data) {
    return "Other";
},"18":function(container,depth0,helpers,partials,data) {
    return " in ";
},"20":function(container,depth0,helpers,partials,data) {
    return "                                        <th>Birthdate</th>\r\n                                        <th>Sex</th>\r\n";
},"22":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["policy-relationships-table-row"],blockParams[0][0],{"name":"policy-relationships-table-row","data":data,"blockParams":blockParams,"indent":"                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "<h2 class=\"header\">Policy Relationships</h2>\r\n<div id=\"accordion\"  class=\"policy-relationships-accordion accordion-style1 panel-group accordion-style2\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\r\n</div>\r\n<!-- /#accordion -->\r\n<!-- end of policy-relationships-info -->";
},"usePartial":true,"useData":true,"useBlockParams":true});
