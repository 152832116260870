/* global Backbone:false, _:false */
/**
 * A model to load data for Requirement Submission
 */

import {apiUrlRoot} from '../../../config/config';
import debugModule from '../../../modules/debug/debugModule';
import utils       from '../../../utils/utils';

const dbg = debugModule.init();

const analyticsChannel = Backbone.Radio.channel('analytics');

const RequirementSubmissionModel = Backbone.Model.extend({

    errors : {
        attachmentRequired : 'Please attach a file.',
        commentsLength     : 'Comment cannot exceed 500 characters.',
        creditCardNoFiles  : 'This selection requires an attachment.',
        duplicateFile      : 'No file chosen. File has already been added.',
        emptyFile          : 'No file chosen. This file appears to be empty.',
        emptyForm          : 'Please attach a file or enter a comment.',
        invalidFileType    : 'No file chosen. The file you selected is not in the correct ' +
                             'format. Please submit a file as .TIF or .PDF format.',
        missingPolicyId    : 'RequirementSubmissionModel.urlRoot: Missing \'policyId\'',
        serviceValidation   : {
            invalidFileType : 'File(s) must be .TIF or .PDF format.',
            totalFileSize   : 'The total file size must be under 25 MB.'
        },
        totalFileSize      : 'No file chosen. File addition exceeds maximum of 25 MB. Please ' +
                             'reduce the size of your file, or create another submission.',
        unknownError       : 'There was a problem with the submission.'
    },

    // Used to send to utils.sendFormErrorsToAnalytics for mapping error messages to form fields
    errorKeysToFormFieldMap : {
        commentsErrorText    : 'comments',
        creditCardErrorText  : 'creditCard',
        fileErrorText        : 'attachments',
        formErrorText        : 'form',
        serviceFileErrorText : 'attachments'
    },

    formName : 'Requirement Submission',

    /**
     * Initializes the model.
     * @param {Object} options
     */
    initialize (options) {
        // Handle validation errors from the service
        // which are sent using an HTTP 400 response code.
        this.listenTo(this, 'error', this._setErrorParameters);

        // attach utils for testing
        this.utils = utils;
    },

    /**
     * Validate a file prior to being added to the model. Checks
     * to see if the file type is valid and whether or not it is
     * a duplicate file.
     *
     * Clears out "creditCardErrorText" and "formErrorText" if both tests pass.
     *
     * @param {Array.<Object>} files A collection of files
     * @param {Object} fileToBeAdded The file to validate
     * @returns {boolean}
     */
    preValidateFile (files, fileToBeAdded) {
        let error;
        const totalMegabytes = this._getTotalFileSizeInMegabytes(files.concat(fileToBeAdded));

        // check for a valid type
        if (!this._isValidFileType(fileToBeAdded)) {
            error = this.errors.invalidFileType;

        } else if (this._isDuplicateFile(files, fileToBeAdded)) {
            // check for a duplicate
            error = this.errors.duplicateFile;

        } else if (!this._isValidTotalFileSize(totalMegabytes)) {
            // check if this will put us over the file size limit
            error = this.errors.totalFileSize;

        } else if (fileToBeAdded.size === 0) {
            error = this.errors.emptyFile;
        }

        if (_.isUndefined(error)) {
            this.unset('creditCardErrorText');
            this.unset('fileErrorText');
            this.unset('formErrorText');

            // Clear out any "empty form" messages since we have a file in the form now.
            if (!this.has('commentsErrorText')) {
                this.unset('commentsErrorStyle');
            }

            return true;

        } else {
            this.set('fileErrorText', error);
            this.utils.sendFormErrorsToAnalytics({ fileErrorText : error },
                this.errorKeysToFormFieldMap, this.formName);
            return false;
        }

    },

    /**
     * Overriding the sync method on the model to create items as a FormData
     * object to post.
     *
     * @see {@link http://backbonejs.org/#Sync}
     * @param {string} method The Backbone method which maps to handlers
     * @param {Object} model The model to be saved
     * @param {Object} options Options object
     */
    sync (method, model, options) {
        let formData;
        let xRequestedWithHeader;

        if (method === 'create') {

            formData = this._getFormData();

            // Update the X-Requested-With header
            xRequestedWithHeader = {
                'X-Requested-With' : 'XMLHttpRequest'
            };

            _.defaults(options || (options = {}), {
                data        : formData,
                // since the service is not sending anything in the response body,
                // we need to set the dataType to "text" in order for jQuery to
                // fire success.
                dataType    : 'text',
                headers     : xRequestedWithHeader,
                processData : false,
                contentType : false
            });
        }

        return Backbone.sync(method, model, options);
    },

    /**
     * Return the URL root based on the values in the model.
     * @returns {string}
     */
    urlRoot () {
        const policyId = this.get('policyId');

        if (policyId) {
            return apiUrlRoot + 'policies/' + policyId + '/requirements';
        } else {
            throw new Error(this.errors.missingPolicyId);
        }
    },

    /**
     * Validation for the model.
     *
     * @returns {{}}
     */
    validate (attrs) {
        let comments              = attrs.comments || '';
        let creditCard            = attrs.creditCard || false;
        let errors                = {};
        let files                 = attrs.files || [];
        const maxCommentsLength   = this.get('maxCommentsLength');
        const totalFileSize       = this._getTotalFileSizeInMegabytes(files);
        let setCommentsErrorStyle;

        if (!creditCard && files.length === 0 && comments.length === 0) {
            errors.formErrorText     = this.errors.emptyForm;

            // the comments and file group should be highlighted
            errors.fileErrorText     = this.errors.emptyForm;
            setCommentsErrorStyle    = true;
        }

        if (creditCard && files.length === 0) {
            errors.creditCardErrorText = this.errors.creditCardNoFiles;
            errors.formErrorText       = this.errors.attachmentRequired;
            errors.fileErrorText       = this.errors.attachmentRequired;
        }

        if (comments.length > maxCommentsLength) {
            errors.commentsErrorText = this.errors.commentsLength;
        }

        // validate the files individually
        _.each(files, function(file) {
            if (!this._isValidFileType(file)) {
                errors.fileErrorText = this.errors.invalidFileType;
            }
        }, this);

        if (!errors.fileErrorText && !this._isValidTotalFileSize(totalFileSize)) {
            errors.fileErrorText = this.errors.totalFileSize;
        }

        this.set({
            // set commentsErrorStyle to TRUE if there is an issue found with the comments
            commentsErrorStyle  : setCommentsErrorStyle || !_.isUndefined(errors.commentsErrorText),
            commentsErrorText   : errors.commentsErrorText,
            creditCardErrorText : errors.creditCardErrorText,
            fileErrorText       : errors.fileErrorText,
            formErrorText       : errors.formErrorText
        });

        if (!_.isEmpty(errors)) {
            this.utils.sendFormErrorsToAnalytics(errors, this.errorKeysToFormFieldMap,
                this.formName);
            return errors;
        }
    },

    /**
     * Wrapper for the internal validation methods for comments.
     * The "commentsErrorText" is set if an error is found.
     *
     * @param {Object} model
     */
    validateComments (model) {
        const comments  = model.get('comments');
        const maxLength = this.get('maxCommentsLength');

        let errorMsg;
        let showAsError = false;

        if (comments.length >= maxLength) {
            errorMsg = this.errors.commentsLength;
        }

        // Clear out any emtpyForm messages and highlighting since we have something on the form
        if (comments.length && this.get('formErrorText') === this.errors.emptyForm
                && this.get('fileErrorText') === this.errors.emptyForm) {
            this.unset('formErrorText');
            this.unset('fileErrorText');
        }

        this.set('commentsErrorText', errorMsg);
        this.set('commentsErrorStyle', showAsError);
    },

    /**
     * Calculates the file size in MB of a collection of files.
     *
     * @param {Array.<File>} files
     * @returns {*|string}
     * @private
     */
    _getTotalFileSizeInMegabytes (files) {
        let totalBytes = 0;

        if (files && files.length) {
            // if there is a collection of files, calculate their size in bytes
            totalBytes = _.reduce(files, function (memo, file) {
                return Number(memo + file.size);
            }, 0);
        }

        return this.utils.bytesToMegabytesForDisplay(totalBytes);
    },

    /**
     * Loads data from the model into a FormData object.
     *
     * @returns {FormData}
     * @private
     */
    _getFormData () {
        let metaDataBlob;
        let metaDataObject;

        let files    = this.get('files');
        let formData = new FormData();

        // Map the comments to a key named "answer"
        // and credit card to a key named "payment".
        // This object should be passed in a FormData
        // entry named "metadata". See OOSO-3002.
        metaDataObject = {
            answer  : this.get('comments'),
            payment : this.get('creditCard')
        };

        metaDataBlob = new Blob([JSON.stringify(metaDataObject)], { type : 'application/json' });

        formData.append('metadata', metaDataBlob, 'metadata');

        for(let i = 0; i<files.length; i++) {
            formData.append('file', files[i]);
        }

        return formData;
    },

    /**
     * Checks to see if the file to be added has the same file size
     * and name as a file that already exists in the files list.
     * @param {Array.<Object>} files
     * @param {Object} fileToBeAdded
     * @returns {boolean}
     * @private
     */
    _isDuplicateFile (files, fileToBeAdded) {

        const duplicateFile = _.findWhere(files, {
            name : fileToBeAdded.name,
            size : fileToBeAdded.size
        });

        return !_.isUndefined(duplicateFile);
    },

    /**
     * Checks to see if the file passed in to the method
     * has a file type which matches one of the "validFileTypes".
     *
     * @param {Object} file
     * @returns {boolean}
     * @private
     */
    _isValidFileType (file) {

        return _.contains(this.get('validFileTypes'), file.type);
    },

    /**
     * Checks to see if the file size passed in to the method is
     * less than "maxFileSizeInMegabytes".
     *
     * @param {Number} totalFileSizeInMegabytes
     * @returns {boolean}
     * @private
     */
    _isValidTotalFileSize (totalFileSizeInMegabytes) {
        return totalFileSizeInMegabytes < this.get('maxFileSizeInMegabytes');
    },

    /**
     * Error messages returned by the service when a status of 400 is
     * returned appear as follows:
     *
     * {
     *     "items" : null,
     *     "errors" : [ {
     *       "className" : "java.lang.IllegalArgumentException",
     *       "message" : "Both text answer and files are empty."
     *     } ],
     *     "count" : 0,
     *     "errorCount" : 1
     * }
     *
     * This method parses this error and returns a collection of
     * messages.
     *
     * @param {string} text
     * @returns {Array.<string>}
     * @private
     */
    _parseServerErrorMessages (text) {
        let errorObj;
        let messages = [];

        if (text) {
            // parse the text and retrieve the errors
            try {
                errorObj = JSON.parse(text);
            } catch(error) {
                const errorMessage = '"' + error.message +
                    '" error when parsing service response: "' + text + '"';

                analyticsChannel.trigger('trackException', {
                    fatal   : false,
                    message : errorMessage
                });

                dbg.error(errorMessage);
            }

            if (_.isObject(errorObj)) {
                // loop through and get all of the error messages
                _.each(errorObj.errors, function(error) {
                    messages.push(error.message);
                });

            } else {
                // General error message since the errorObj couldn't be parsed.
                messages.push(this.errors.unknownError);
            }
        }

        return messages;
    },

    /**
     * Handle the 400 responses from the service and translate those
     * error messages into the messages specified in the requirements.
     *
     * The corresponding error parameters are then set on the model
     * and concatenated if multiple errors are returned related to the
     * same field (unlikely, but could happen).
     *
     * @param {Object} model
     * @param {Object} response The response object from the service.
     * @private
     */
    _setErrorParameters (model, response) {
        let serviceMessages;
        let errors = {
            formErrorText        : [],
            creditCardErrorText  : [],
            serviceFileErrorText : []
        };

        if (response && response.status === 400) {
            serviceMessages = this._parseServerErrorMessages(response.responseText);

            // loop through the messages and set the corresponding error for the view
            _.each(serviceMessages, function(message) {

                if (message.indexOf('Both text answer and files are empty.') > -1) {
                    errors.formErrorText.push(this.errors.emptyForm);
                }

                if (message === 'Payments require at least one file to be submitted.') {
                    errors.creditCardErrorText.push(this.errors.creditCardNoFiles);
                    errors.formErrorText.push(this.errors.attachmentRequired);
                }

                if (message.indexOf('Uploaded file extension is invalid.') > -1) {
                    errors.serviceFileErrorText.push(this.errors.serviceValidation.invalidFileType);
                }

                if (message.indexOf('Maximum upload size') > -1) {
                    errors.serviceFileErrorText.push(this.errors.serviceValidation.totalFileSize);
                }

                if (message === 'File submitted is null or empty') {
                    errors.serviceFileErrorText.push(this.errors.emptyFile);
                }

            }, this);

            // set the errors in the model and concatenate
            _.each(_.keys(errors), function(key) {
                const messages = errors[key];

                if (messages.length) {
                    this.set(key, messages.join(' '));
                } else {
                    this.unset(key);
                }
            }, this);

            this.utils.sendFormErrorsToAnalytics(errors, this.errorKeysToFormFieldMap,
                this.formName);
        }
    }

});

module.exports = RequirementSubmissionModel;