/* global Backbone:false; _:false */

var debugModule          = require('../../../modules/debug/debugModule').init();
var HierarchyPathsModel  = require('../models/hierarchy-paths-m');
var HierarchyPathsView   = require('./hierarchy-paths-v');
var hierarchyTemplate    = require('../templates/hierarchy-paths-org-policy-list-t.hbs');
var nameTemplate         = require('../templates/general-info-producer-name-t.hbs');
var template             = require('../templates/policy-list-producer-info-t.hbs');
var userChannel          = Backbone.Radio.channel('user');
var utils                = require('../../../utils/utils');
var spinnerChannel       = Backbone.Radio.channel('spinner');

// Load handlebars helper method to avoid unit test failure
require('../../../utils/hb-helpers');

/**
 * Organization Pending Policy List Producer Info View
 *
 */
var PendingPolicyListProducerInfoView = Backbone.Marionette.LayoutView.extend({

    template : template,

    regions : {
        hierarchyRegion       : '#hierarchy-paths-region',
        producerNameRegion    : '#general-info-producer-name-region'
    },

    onBeforeShow : function onBeforeShow () {
        var canViewHierarchy = userChannel.request('hasCapability', 'Producer_Role_View');
        var id               = this.model.get('id');

        if (canViewHierarchy && id) {
            this.model.set('paths', new HierarchyPathsModel({
                id : id
            }));

            this.listenTo(this.model.get('paths'), 'sync', this._showHierarchyPaths);
            this.listenTo(this.model.get('paths'), 'error', this._handlePathError);

            spinnerChannel.trigger('show', {
                viewScope    : this,
                regionName   : 'hierarchyRegion',
                size         : 'medium',
                position     : 'static'
            });

            this.model.get('paths').fetch();
        }
    },

    /**
     * Create and show the hierarchy path subview
     * @private
     */
    _showHierarchyPaths : function _showPaths () {

        var hierarchyModel = this.model.get('paths');
        var paths          = hierarchyModel.get('paths');
        var link;

        // Just in case there are no paths, or the producer is the root
        if (paths && !hierarchyModel.producerIsRoot()) {
            this.hierarchyView = new HierarchyPathsView({
                model    : hierarchyModel,
                template : hierarchyTemplate
            });

            this.showChildView('hierarchyRegion', this.hierarchyView);

        } else {

             // Its to add query param 'targetuser' if impersonated webID exist
            link = utils.addTargetUserQueryParamToURL(
                '#policy-manager'
            );

            // This is the producer's own organization policy list, so show simple nav
            this.showChildView('hierarchyRegion', new Backbone.Marionette.ItemView({
                template : _.template(
                    '<a href="'+link+'" class="oa-js-nav">Organization View</a>'
                )
            }));
        }
    },

    /**
     * Create a new childview to show the name
     * @param {string} name - the name to display
     * @param {boolean} [showIconFlag = true] - optional flag to display icon
     */
    showName : function showName(name, showIconFlag) {
        var nameView = new Backbone.Marionette.ItemView({
            model : new Backbone.Model({
                fullName : name,
                showIcon : showIconFlag,
            }),
            template : nameTemplate,
            onRender : function() {
                utils.unwrapView(this);
            }
        });
        this.showChildView('producerNameRegion', nameView);
    },

    /**
     * Handle an error when retrieving hierarchy path info. Should be caught by global handler,
     * but may want to do something specific here.
     * @private
     */
    _handlePathError : function _handlePathError (model, response) {

        // Remove spinner as its failed
        this.showChildView('hierarchyRegion', new Backbone.Marionette.ItemView({
            template:false
        }));
        var message = 'Unable to retrieve hierarchy path(s) for ' + model.get('id');
        debugModule.error(message);
    }

});

module.exports = PendingPolicyListProducerInfoView;