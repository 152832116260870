/* global _:false */

//helper methods for Handlerbars templates

import Handlebars from 'hbsfy/runtime';
import utils from '../utils/utils';

import config from '../config/config';
import moment from 'moment-timezone';

/**
 *
 * Format currency with comma 
 * 
 * @param {string} value to be formatted
 * @param {boolean} [round = false] - Round number to nearest whole dollar
 * @param {boolean} [spaceZero = false] - Return '--' if number is 0
 * 
 * @output {string} formatted currency
 */
const currencyFormat = (value, round, spaceZero) => {
    //if second / third param is missing, hbs will pass 'options' as second param.
    if (typeof round !== 'boolean') {
        round = false;
    }

    if (typeof spaceZero !== 'boolean') {
        spaceZero = false;
    }
    return utils.formatAsCurrency(value, round, spaceZero);
};

/**
 * Helper to Format currency numbers
 * 
 * @example
 * // returns $51,953.00
 * {{currencyFormat 'USD 51953.00'}}
 * 
 * // returns $51,954
 * {{currencyFormat 'USD 51953.51'}}
 * 
 * // returns --
 * {{currencyFormat 'USD 0.00' false true}}   
 * 
 */
Handlebars.registerHelper('currencyFormat', currencyFormat);


//Equality check between two parameters
/**
 *
 * @param {*} controlValue Left side value to compare
 * @param {*} actualValue Right side value to compare
 * @param {object} options Contain 'operator' details to compare
 * 
 * @example
 * {{#compare commission.type 'ib'}}
 *      <strong>Earnings</strong>
 * {{/compare}}
 * 
 * {{#compare some_number 10 operator='>' }}
 *      <strong>Earnings</strong>
 * {{/compare}}
 */
Handlebars.registerHelper('compare', function (controlValue, actualValue, options) {
    if (arguments.length < 3) {
        throw new Error('Handlebars Helper \'compare\' needs 2 parameters');
    }

    let operator = options.hash.operator || '==';

    let operators = {
        '==': function (l, r) { return l === r; },
        '===': function (l, r) { return l === r; },
        '!=': function (l, r) { return l !== r; },   //JS Lint is picky
        '!==': function (l, r) { return l !== r; },
        '<': function (l, r) { return l < r; },
        '>': function (l, r) { return l > r; },
        '<=': function (l, r) { return l <= r; },
        '>=': function (l, r) { return l >= r; },
        '&&': function (l, r) { return l && r; },
        '||': function (l, r) { return l || r; },
        'strcis': function (l, r) { return (l + '').toLowerCase() === (r + '').toLowerCase(); },
        '!strcis': function (l, r) { return (l + '').toLowerCase() !== (r + '').toLowerCase(); },
        'typeof': function (l, r) { return typeof l === r; }
    };

    if (!operators[operator]) {
        throw new Error('Handlebars Helper \'compare\' doesn\'t know the operator ' + operator);
    }

    let result = operators[operator](controlValue, actualValue);

    if (result) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }

});

/**
 * Math helper for arithmetic operator
 * @param  {number} lvalue   left value
 * @param  {number} operator arithmetic operator
 * @param  {number} rvalue   right value
 * 
 * @example 
 * // returns 100
 * {{math 10 '*' 10}} 
 * 
 * // returns 20
 * {{math 10 '+' 10}}
 */
Handlebars.registerHelper('math', (lvalue, operator, rvalue) => {

    if (isNaN(lvalue) || isNaN(rvalue) || ['+', '-', '*', '/', '%'].indexOf(operator) === -1) {
        return 0;
    }

    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);

    return {
        '+': lvalue + rvalue,
        '-': lvalue - rvalue,
        '*': lvalue * rvalue,
        '/': lvalue / rvalue,
        '%': lvalue % rvalue
    }[operator];
});

/**
 * Helper to covert number to percentage format
 * @param  {number} num   number to covert as percentage
 * 
 * @example 
 * // returns 58%
 * {{numberToPercentage 0.58}}
 *
 * // returns 100%
 * {{numberToPercentage 1}}
 * 
 * // returns 33.33%
 * {{numberToPercentage 0.3333}}
 */
Handlebars.registerHelper('numberToPercentage', (num) => {
    if (!_.isNumber(num)) {
        return num;
    }

    // IMPORTANT: We can't just multiply the number by 100 due to problems with
    // how JS represents numbers. We have to multiply it by 1000 and then divide by 10.
    const percent = num * 1000 / 10;

    if (percent === Math.round(percent)) { // is it a whole number?
        return Math.round(percent) + '%';
    } else {
        return percent.toFixed(2) + '%';
    }
});

/**
 * Format number to 2 decimals
 *
 * @example
 * // returns 6.00
 * {{numberToFixed 6.0000}}
 * 
 * // returns 3.50
 * {{numberToFixed 3.5000}}
 *    
 */
Handlebars.registerHelper('numberToFixed', (num) => {
    if (!_.isNumber(num)) {
        return num;
    }

    return num.toFixed(2);
});

/*
 * Convert a string from ISO-4217 currency (USD only) to a number
 * @param {number} value Number to format
 * @param {boolean} absolute To get absolute number
 * @param {boolean} round To get round number

 * @example:
 * // results 1235
 * {{#isoCurrencyToNumber 'USD -1234.56' true true}}
 * 
 *   
 */
Handlebars.registerHelper('isoCurrencyToNumber', (value, absolute, round) => {
    return utils.isoCurrencyToNumber(value, absolute, round);
});

/**
 * Get the time unit of an ISO duration. This is simply using
 * moment's get function {@link http://momentjs.com/docs/#/durations/get/}.
 * 
 * @param {string} value - ISO duration
 * @param {string} unit  - Unit of time to get
 * 
 * @example
 * // results 107
 * {{isoDurationToTimeUnit 'P107Y4M' 'year'}}
 */
Handlebars.registerHelper('isoDurationToTimeUnit', (value, unit) => {
    if (!value) {
        return '';
    }

    if (!unit) {
        throw new Error('Handlebars Helper \'isoDurationToTimeUnit\' ' +
            'requires a time unit to be passed');
    }

    return utils.isoDurationToTimeUnit(value, unit);
});


/**
 * Format phone number to US standard format
 *
 * @example
 * // results 321-545-8555
 * {{#formatPhoneNumber '3215458555'}}
 *    
 */
Handlebars.registerHelper('formatPhoneNumber', (phoneNumber) => {
    return utils.formatPhoneNumber(phoneNumber);
});


/**
 * 
 * Get absolute value of the number
 * @param {number} value Number to get absolute value
 * 
 * @example
 * // results  1
 * {{absolute -1}}
 * 
 */
Handlebars.registerHelper('absolute', (value) => {
    if (value === undefined || value === null) {
        return '';
    }

    //convert to number
    value = Number(value);

    if (isNaN(value)) {
        throw new Error('Expected a numeric inputs');
    }

    return Math.abs(value);
});


/** 
 * Get round value of the number
 * @param {number} value Number to get round value
 * 
 * @example
 * // results 30
 * {{round 29.56}}
 */
Handlebars.registerHelper('round', (value) => {
    if (value === undefined || value === null) {
        return '';
    }

    //convert to number
    value = Number(value);

    if (isNaN(value)) {
        throw new Error('Expected a numeric inputs');
    }

    return Math.round(value);
});

/**
 * Get round down value of the number
 * @param {number} value Number to get round value
 *
 * @example
 * // results 29
 * {{round 29.56}}
 */
Handlebars.registerHelper('floor', (value) => {
    if (value === undefined || value === null) {
        return '';
    }

    //convert to number
    value = Number(value);

    if (isNaN(value)) {
        throw new Error('Expected a numeric inputs');
    }

    return Math.floor(value);
});

/**
 * uppercase converts strings to upper-case values.
 * A null or undefined value will return an empty
 * string.
 * @param {string} value
 *
 * * @example
 * // results TESTING
 * {{uppercase 'testing'}}
 *
 * @return {string}
 */
Handlebars.registerHelper('uppercase', (value) => {
    return (value || '').toUpperCase();
});

/**
 * Converts bytes to megabytes within a decimal place.
 * 
 * @example
 * // results 2.2
 * {{bytesToMegabytes 2343482}}
 */

Handlebars.registerHelper('bytesToMegabytes', (value) => {
    if (isNaN(value)) {
        value = 0;
    }

    return utils.bytesToMegabytesForDisplay(value);
});

/**
 * format date
 * @example
 * //results 05/10/2018, 11:50 AM EDT
 * {{dateTimeFormat '2018-05-10T15:50:00Z' 'MM/DD/YYYY, hh:mm A z'}}
 *      
 * //results 05/10/2018
 * {{dateTimeFormat '2018-05-10T15:50:00Z'}} 
 *      
 * //results 05/10/2018     
 * {{dateTimeFormat '2018-05-10'}} 
 */
Handlebars.registerHelper('dateTimeFormat', (date, format) => {

    if (typeof format !== 'string') {
        format = '';
    }

    return utils.formatDate(date, format);
});

Handlebars.registerHelper('dateFormat', (date, format) => {

    // Ensure that the date format does not contain any 
    // time-related characters like 'h', 'm', 's', or 'z'.
    const invalidFormatCharacters = ['h', 'm', 's', 'z'];

    if (typeof format !== 'string') {
        format = 'MM/DD/YYYY';
    }

    // If any invalid characters were passed in the format, throw an error.
    _.each(invalidFormatCharacters, function (value) {
        if (format.indexOf(value) > -1) {
            throw new Error('Handlerbars Helper \'dateFormat\' only allows date-specific ' +
                'formatting. The format passed (' + format + ') contains time-specific ' +
                'formatting. Try using the helper \'dateTimeFormat\' instead.');
        }
    });

    return utils.formatDate(date, format, null, false);
});

/**
* Conditional Helper for checking whether object is empty
* @param {object} obj
* @output {boolean}
* @example
*   {{#isNotEmpty person}}
*       Name: {{person.name}}<br>
*       Age: {{person.age}}<br>
*   {{/isNotEmpty}}
*
* Note: Since arrow function doesn't support 'this' scope using 'function'
*/
Handlebars.registerHelper('isNotEmpty', function (obj, options) {

    if (_.isEmpty(obj)) {
        return options.inverse(this);
    } else {
        return options.fn(this);
    }
});

/**
* Conditional Helper for checking whether object has dataAvailability property
  with 'noAvailable' value
* @param {object} obj
* 
* @output {boolean} 
* @example 
* 
* {{#ifAvailable policy.billingDetail}}
*    (markup to display the billing details goes here)
* {{/ifAvailable}}
*/
Handlebars.registerHelper('ifAvailable', function (obj, options) {
    let isAvailable = true;
    if (obj && obj.dataAvailability === 'notAvailable') {
        isAvailable = false;
    }
    if (isAvailable) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

/**
 * Conditional helper for only showing content if the current date is within a
 * given date range.
 * 
 * @param {string} startDateIso - start date in ISO 8601 format
 * @param {string} endDateIso - end date in ISO 8601 format
 * @param {Date} currentDate - a JS Date object
 * 
 * @output {boolean}
 * 
 * @example
 * {{#ifWithinDateRange '2018-05-10T00:00:00-0500' '2018-05-19T23:59:59-0500'}}
 *   (Markup that should only be displayed during the specified dates)
 * {{/ifWithinDateRange}}
 */
Handlebars.registerHelper('ifWithinDateRange', function (
    startDateIso, endDateIso, options) {

    if (typeof startDateIso !== 'string') {
        throw new Error('Handlebars helper \'ifWithinRange\' startDateIso parameter' +
            'must be a string, but was sent ' + typeof startDateIso);
    }
    if (typeof endDateIso !== 'string') {
        throw new Error('Handlebars helper \'ifWithinRange\' endDateIso parameter' +
            'must be a string, but was sent ' + typeof endDateIso);
    }

    let currentDate = moment.tz(moment(), config.defaultTimezone);
    let startDate = moment.tz(startDateIso, config.defaultTimezone);
    let endDate = moment.tz(endDateIso, config.defaultTimezone);

    let isInRange = false;
    if (currentDate.isBefore(endDate) && currentDate.isAfter(startDate)
        || (currentDate.isSame(startDate) || currentDate.isSame(endDate))) {
        isInRange = true;
    }

    if (isInRange) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

/**
* Helper method specifically for pending policy details page
* Use case: if there is name exist wrap roleCode with parenthesis
* 
* @param {string} name  
* @param {string} roleCode
*
* @output {string} "(232dss2)" or 232dss2
*
* @example 
* // results (232dss2)
* {{wrapRoleCodeWithParenthesis 232dss2}}
*/
Handlebars.registerHelper('wrapRoleCodeWithParenthesis', (name, roleCode) => {
    if (name && roleCode) {
        return '(' + roleCode + ')';
    }
    return roleCode;
});

/**
 * Block helper to wrap underscore's `has` function.
 * If the given object contains the specified key, the contained block will execute.
 *
 * @example
 * {{#has insured "tobaccoUse"}}
 *     {{#if insured.tobaccoUse}}
 *         Tobacco
 *     {{else}}
 *         Non-Tobacco
 *     {{/if}}
 * {{else}}
 *     Unknown
 * {{/has}}
 *
 */
Handlebars.registerHelper('has', function (object, key, options) {

    let has = _.has(object, key);

    if (has) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

/**
 * Create Hash URL for HREF attributes
 *  
 * @param  {string} pageLink        Page link can be hash URL or http URL 
 * @param  {object} options         Inbuilt Handlebars helper callback parameter
 *                                  which as data, hash and name objects
 * @output {string} pageLink        Updated page link
 * 
 * @example
 * // results #org-policies/pending/?producerOrgId=11000
 * {{buildHrefURL '#org-policies/pending/' producerOrgId=id }} 
 */
Handlebars.registerHelper('buildHrefURL', (pageLink, options) => {

    if (!pageLink || !_.isString(pageLink)) {
        throw new Error('buildHrefURL : Expected pageLink as a string format');
    }

    // options.hash object will contain query parameters
    pageLink = utils.buildHrefURL(pageLink, options.hash);

    return pageLink;
});

/**
 * Concat strings together
 * 
 * @param  {object} params rest parameter
 * @ouput {string} Concantinated string
 * 
 * @example
 * // results #pending-policy-list/allPolicies/?producerId=10002
 * {{concat '#pending-policy-list/' 'allPolicies/' '?producerId=10002'}}
 */
Handlebars.registerHelper('concat', (...params) => {
    let outString = '';
    for (let arg in params) {
        if (typeof params[arg] !== 'object' && params[arg]) {
            outString += params[arg];
        }
    }
    return outString;
});

/**
 * Check if even
 * 
 * @example
 * // results true if the number is even
 * {{#if_even integer}}
 */
Handlebars.registerHelper('if_even', (conditional, options) => {
    if ((conditional % 2) === 0) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});


/* Check whether index is 0 */
Handlebars.registerHelper('if_index_zero', function(a, opts) {
    if (a === 0) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/* Check whether index is less than 2 */
Handlebars.registerHelper('if_index_less_than_two', function(a, opts) {
    if (a < 2) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/* Check whether the method is bankdraft or not */

Handlebars.registerHelper('is_bankdraft', function(a, opts) {
    if (a === 'Electronic Funds Transfer' || a === 'Automatic Draft') {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/* Check whether current billing data exists or not */
Handlebars.registerHelper('if_current_billing_data_exists', function(a, opts) {
    if (a && a.paymentMethod && a.paymentMode && a.paymentAmount) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/* Check whether current billing paymentMethod is "Regular Billing" or not */
Handlebars.registerHelper('if_current_billing_paymentmethod_direct', function(a, b, opts) {
    if (a === 'Direct Pay' && b === 'Regular Billing') {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/* Check whether current billing paymentMethod is "Electronic Funds Transfer" or not */
Handlebars.registerHelper('if_current_billing_paymentmethod_draft', function(a, b, opts) {
    if (a === 'Automatic Draft' && b === 'Electronic Funds Transfer') {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/* Helper that returns payment method text*/
Handlebars.registerHelper('convert_paymentMode', function(paymentMode) {
    paymentMode = paymentMode.trim().toLowerCase().replace(/ /g,'');
    if (paymentMode === 'monthly') {
        return 'Monthly';
    } else if (paymentMode === 'quarterly') {
        return 'Every Three months';
    } else if (paymentMode === 'semi-annual' || paymentMode === 'semiannual') {
        return 'Every Six months';
    } else if (paymentMode === 'annual') {
        return 'Annually';
    }
});

/* Check whether premium mode quotes tab should be visible or not */
Handlebars.registerHelper('if_pmq_tab_visible', function(a, b, c, opts) {
    if ((a || (b && b.length > 0)) && c) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

module.exports = Handlebars;
