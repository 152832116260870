/* global Marionette */
/**
 * The simple, default view of the My Business module.
 */

var myBizTemplate = require('../templates/mybiz-t.hbs');

var MyBizView = Marionette.ItemView.extend({
    template: myBizTemplate
});
module.exports = MyBizView;
