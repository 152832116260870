// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.policyNumber || (depth0 != null ? depth0.policyNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"policyNumber","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "Recently Received\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"profile-info-row\" id=\"product\">\r\n                        <div class=\"profile-info-name\"><h5 class=\"gray-50\">Product:</h5></div>\r\n                        <div class=\"profile-info-value\"><h5>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.productName : stack1), depth0))
    + "</h5></div>\r\n                    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"well well-account-info\">\r\n    <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n            <h3>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.policyIdentification : depth0)) != null ? stack1.label : stack1), depth0))
    + ":&nbsp;\r\n                <span class=\"oa-banner-blue\" id=\"policy-primary-name\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.policyIdentification : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\r\n            </h3>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n            <h3>Policy #:&nbsp;<span id=\"policy-number\" class=\"oa-banner-blue\">"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.policyNumber : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "            </span>\r\n            </h3>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n            <div class=\"profile-user-info\">\r\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.productName : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n            </div>\r\n            <!-- /.profile-user-info -->\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});
