/* global Marionette */
/**
 * The simple, default view of the Page1 module.
 * Created by Jason.Bell on 3/10/2015.
 */

var page1aTemplate = require('../templates/page1a-t.hbs');
var Page1aView = Marionette.ItemView.extend({
    template: page1aTemplate
});
module.exports = Page1aView;
