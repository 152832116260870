/* global Backbone, _ */
/**
 * Utility module for ajax
 *
 * Created by Jason.Bell on 6/25/2015.
 */
import config      from '../config/config';
import debugModule from '../modules/debug/debugModule';
import errorHelper from './error-helper';

const dbg = debugModule.init();

const errorChannel                   = Backbone.Radio.channel('error');
const browserFeatureDetectionChannel = Backbone.Radio.channel('browserFeature');
var checkPoint = require('../modules/checkpoint/checkpointModule');

const ajaxUtils = {

    /**
     * Build a prefilter for ajax calls to allow for Cross Origin Resource Sharing and to add the
     * targetuser param to all requests if the current user is impersonating another user.
     * @param userMod a reference to the UserModule
     * @returns {Function} a function to be used for jQuery's prefilter.
     */
    buildPrefilter (userMod) {
        return function (options, originalOptions, jqXHR) {

            // Need to check for the existence of FormData
            // first so that tests don't break.
            const isFormData = (browserFeatureDetectionChannel.request('detect', 'formdata')
                    && window.FormData && originalOptions.data instanceof window.FormData);

            const targetUser = userMod && userMod.getImpersonatedWebId();
            const targetUserIsInUrl = (typeof originalOptions.url === 'string' &&
                originalOptions.url.indexOf('targetuser=' + targetUser) > -1);
                    
            // Add the impersonated userId to the request (if it exists and is not
            // in the url already)
            if (targetUser && !targetUserIsInUrl) {
                
                if (isFormData) {
                    options.url = originalOptions.url + '?targetuser=' + targetUser;
                } else {

                    // In case ajax.method is POST, data passing to Service 
                    // should be in JSON string that need to extended with targetuser parameter
                    // otherwise data (GET method) will be formatting as query parameters 
                    if (options.method && options.method.toLowerCase() === 'post') {

                        var result = checkPoint.getPageIdFromHash(document.location.hash);
                        if (result === '#resourcecenter') {
                            options.url = originalOptions.url + '?targetuser=' + targetUser;
                        } else if (_.isString(originalOptions.data)) {
                            try {
                                originalOptions.data = JSON.parse(originalOptions.data);

                                options.data = JSON.stringify(Backbone.$.extend(
                                    originalOptions.data, {targetuser : targetUser}
                                ));
                            } catch (error) {
                                dbg.error('buildPrefilter : originalOptions.data '+
                                    'provided is not valid JSON string');
                            }
                        }

                    } else {
                        options.data = Backbone.$.param(Backbone.$.extend(
                            originalOptions.data, {targetuser : targetUser}
                        ));
                    }
                }
            }

            options.xhrFields = {
                withCredentials: true
            };
        };
    },

    /**
     * Function that adds the URL to a property on the jqXHR so that it is available in the
     * default error handler.
     * @param jqXHR the jQuery jqXHR
     * @param options the options for the ajax call
     */
    beforeSend (jqXHR, options) {
        jqXHR.url = options.url;
    },

    /**
     * Determine what to show the user and what to log (if anything) based on the jqXHR.status
     * @param jqXHR the jQuery jqXHR object
     * @param settings the settings object for the request
     * @returns {{userMessage: string, logMessage: string}} messages to display and log
     */
    getAjaxMessages (jqXHR, settings) {
        const genericError  = 'The system has encountered an unexpected error.';
        const logPrefix     = jqXHR.url + ' ' + jqXHR.status + ' ';
        let serverMessage = null;
        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
            serverMessage = jqXHR.responseJSON.message;
        }

        let messages = {};

        switch (jqXHR.status) {

            // "Bad Request"
            case 400:
                messages.logMessage  = logPrefix + (serverMessage || 'Bad Request');
                messages.userMessage = genericError;
                break;
                

            // "Not Found"
            case 404:
                messages.logMessage  = logPrefix + (serverMessage || 'Not Found');
                messages.userMessage = genericError;
                break;

            // "Forbidden"
            case 403:

                // In AS2, this is used to catch Home Office user attempting to POST changes.
                // Leaving it general for now.
                messages.userMessage = config.errorMessages.noPageFound;
                break;

            // "Method Not Allowed" - eg, trying to POST to something that only accepts GET.
            // Primarily useful for development.
            case 405:

                // Log specific message and display generic error message.
                messages.logMessage  = logPrefix + (serverMessage || 'Method Not Allowed');
                messages.userMessage = genericError;
                break;

            // "Internal Server Error" - generic error message from the server.
            case 500:

                // Log specific message and display generic error message.
                messages.logMessage  = logPrefix + (serverMessage || 'Unknown Server Error');
                messages.userMessage = genericError;
                break;

            // "Not Implemented" - Called an endpoint that doesn't exist. Not sure if Spring
            // uses this or a generic 500. Potentially useful for development.
            case 501:

                // Log specific message and display generic error message.
                messages.logMessage  = logPrefix + (serverMessage || 'Not Implemented');
                messages.userMessage = genericError;
                break;

            // "Bad Gateway" - Gateway received a bad response from the upstream server
            case 502:
                messages.logMessage = logPrefix + (serverMessage || 'Bad Gateway');
                messages.userMessage = genericError;
                break;

            // "Service Unavailable" - Client can be cached in user's browser and appear to be
            // fully functional, but server could be down for maintenance.
            case 503:

                // Display message to user that service is down.
                messages.userMessage = serverMessage || 'The system is currently unavailable.';
                break;

            // "Gateway Timeout" - Service response timed out.
            case 504:

                messages.userMessage = serverMessage || 'The system reached its maximum timeout. ' +
                    'Please try again.';
                break;
            
            // HTTP Version Not Supported
            case 505:

            // Variant Also Negotiates
            case 506:

            // Insufficient Storage
            case 507:

            // Loop Detected
            case 508:

            // Not Extended
            case 510:

            // Network Authentication Required 
            case 511:

                messages.userMessage = serverMessage || genericError;
                break;
        }

        return messages;
    },

    /**
     * Create an error handler
     * @param logoutCallback a callback for when the user encounters a 401
     * @returns {Function} a function to be used as a jQuery error handler
     */
    buildErrorHandler (logoutCallback) {
        return function(event, jqXHR, settings, thrownError) {
            const redirectRegex    = /^SiteminderRedirectURL=(.+)$/gm;
            let matches;
            let redirectUrl;

            // "Unauthorized" or an unreadable response - Likely the user's session has timed out.
            // Because our unit tests make all kinds of ajax calls (we need to fix that!) only do
            // the "0" test if it's NOT during TEST. Unfortunately...not unit testable!
            // 
            // Ignore status 0 with statusText is 'abort', as action is taken from application
            if (
                (jqXHR.status === 0 && jqXHR.statusText !== 'abort') ||
                jqXHR.status === 401 ) {
                if (jqXHR.status === 401) {

                    dbg.log('401 response text: ' + jqXHR.responseText);

                    // At this point, parse the jqXHR.responseText to extract the
                    // redirect URL. The format of the response looks like this:
                    //
                    //    SiteminderReason=Challenge
                    //    SiteminderRedirectURL=www.st.oneamerica.com
                    //    SiteminderChallengeURL=
                    if (jqXHR.responseText) {
                        matches = redirectRegex.exec(jqXHR.responseText);
                        if (matches && matches.length > 1) {
                            redirectUrl = matches[1];
                        }
                    }

                    if (redirectUrl && redirectUrl.indexOf('http') !== 0) {

                        // SiteMinder didn't include the protocol in the response.
                        redirectUrl = 'https://' + redirectUrl;

                    } else {
                        
                        // no redirect in siteminder response? Use a default!
                        redirectUrl = config.defaultLoginPage;
                    }

                } else {

                    // unreadable response. Assuming an unsecure response due to the user not being
                    // authenticated, and SiteMinder is not adding proper headers for CORS.
                    redirectUrl = config.defaultLoginPage;
                }

                // Don't call 'logout' if we don't have a place to send the user.
                // Also, if we've gotten a strange response for Performance Center in the old site,
                // which does not have 'defaultLoginPage' prop, don't attempt to log the user out.
                if (redirectUrl) {

                    // Add /login path if that missing
                    // ignore localhost
                    if (!redirectUrl.match(/\/login/i) 
                            && !redirectUrl.match(/http:\/\/localhost/i)) {
                        redirectUrl = redirectUrl + '/login';
                    }

                    logoutCallback.apply(this, [true, redirectUrl]);
                }

                return false;
            }

            const messages = ajaxUtils.getAjaxMessages(jqXHR);

            // Log the message, unless it's a message about not being able to log to the server,
            // as that will just spiral into madness.
            if (! /ui\/log/.test(jqXHR.url) && messages && messages.logMessage) {
                dbg.error(messages.logMessage);
            }

            // Display an error, unless it's handled elsewhere or there's no userMessage to display
            if (errorHelper.shouldDisplayGlobalError(jqXHR.url, jqXHR.status) &&
                messages && messages.userMessage) {
                errorChannel.trigger('showErrorPage', messages.userMessage);
            }
        };
    }
};

module.exports = ajaxUtils;


