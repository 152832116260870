/* global Backbone :false, _:false*/
/**
 * Policy model data parser functions for Policy identification section
 */

import {dateDiff} from '../../../../utils/utils';
const userChannel = Backbone.Radio.channel('user');

const policyIdentificationSection =  {

    /**
     * This function sets the name and label used in the Policy Identification section of the
     * policy detail page. The label and role displayed in this section are determined by
     * the customerRoles/customers returned by the service.
     *
     *
     * ==============================================================================
     * |  Type of Policy / Role |  Label Value  |    Name for Display               |
     * ========================= =============== ====================================
     * | AWD                    | Name:         | Use customers['0']                |
     * ------------------------------------------------------------------------------
     * | (if "Annuitant" role)  | Annuitant:    | Only one 'Annuitant'              |
     * ------------------------------------------------------------------------------
     * | (if "Primary Insured"  | Insured:      | Only one of either                |
     * |  or "Insured role AND  |               | 'Primary Insured' (if it exists)  |
     * |  "Annuitant" role does |               | or 'Insured'.                     |
     * |  NOT exist)            |               |                                   |
     * ------------------------------------------------------------------------------
     *
     * Currently, there is no business rule to use in order to choose which name to display for
     * a given role, so we're just returning the first customerId for that role returned by the
     * service.
     *
     * This method appends an object to the root of the response object. This object looks as
     * follows:
     *
     *          policyIdentification = {
     *               label : 'Insured',
     *               name  : 'Wile E. Coyote'
     *           }
     *
     * @param {*} response
     * @returns {*}
     */
    _setPolicyIdentificationLabelAndName (response) {
        const customers = response.customers;
        const roles     = response.customerRoles;

        let id = {
            label : 'Name',
            name  : ''
        };

        if (response.policyId) {

            if (_.has(roles, 'Annuitant')) {
                id.label = 'Annuitant';
                id.name  = customers[roles.Annuitant[0].customerId].fullName;

            } else if (_.has(roles, 'Primary Insured')) {
                id.label = 'Insured';
                id.name  = customers[roles['Primary Insured'][0].customerId].fullName;

            } else if (_.has(roles, 'Insured')) {
                id.label = 'Insured';
                id.name  = customers[roles.Insured[0].customerId].fullName;
            }

        } else {
            id.label = 'Name';

            if (customers && _.isObject(customers['0'])) {
                id.name = customers['0'].fullName;
            }
        }

        response.policyIdentification = id;

        return response;
    },

    /**
     * Build the mailto for the caseManager and Policy service Contact
     *
     * @param response the response from the REST call
     * @returns {object} - the response, with an additional 'mailto' property 
     * under caseManager/policyServiceContact if the email exists for corresponding object. 
     * Additionally, it will add the policy number and customer's
     * last name in the subject of the mailto if those properties are available.
     */
    _setContactMailto (response) {
        let address      = '';
        let custId       = '';
        let lastName     = '';
        let policyNumber = '';
        let subject      = '';

        if (response && (response.caseManager && response.caseManager.emailAddress) 
            || (response.policyServiceContact && response.policyServiceContact.emailAddress)) {

            if (response.policyNumber) {
                policyNumber = response.policyNumber;
            }

            if (response.customerRoles) {

                // figure out which role we can use
                if (response.customerRoles.Insured && response.customerRoles.Insured.length >= 1) {
                    custId = response.customerRoles.Insured[0].customerId;

                } else if (response.customerRoles['Primary Insured'] &&
                    response.customerRoles['Primary Insured'].length >= 1) {
                    custId = response.customerRoles['Primary Insured'][0].customerId;

                } else if (response.customerRoles.Annuitant &&
                    response.customerRoles.Annuitant.length >= 1) {
                    custId = response.customerRoles.Annuitant[0].customerId;
                }

                // Now find the proper customer record and get the lastName
                if (response.customers && response.customers[custId]) {
                    lastName = response.customers[custId].lastName;
                }
            }

            if (policyNumber || lastName) {
                if (policyNumber && lastName) {
                    subject = 'Policy #' + policyNumber + ' ' + lastName.toUpperCase();
                } else if (policyNumber) {
                    subject = 'Policy #' + policyNumber;
                } else if (lastName) {
                    subject = lastName.toUpperCase();
                }
                subject = encodeURIComponent(subject);
            }
        
            if (response.caseManager) {
                address = response.caseManager.emailAddress;
                response.caseManager.mailto = 'mailto:' + address + '?subject=' + subject;
            }
            else if (response.policyServiceContact) {
                address = response.policyServiceContact.emailAddress;
                response.policyServiceContact.mailto = 'mailto:' + address + '?subject=' + subject;
            }
        }

        return response;
    },

    /**
     * Set a flag to cause the Policy Services section to show or not
     * 
     * @param {object} response the response from the REST call     *
     * @returns boolean
     */
    _shouldShowPolicyServiceSection (response) {
        let shouldShow = false;
        
        //If Online Service Request link is shown, section should be shown        
        if (response && response.iPipelinePolicyHSLinkOptions
            && response.iPipelinePolicyHSLinkOptions.show) {
            shouldShow = true;        
        }
        
        //If Inforce Illustrations link is shown, section should be shown
        if (response && response.iPipelineInforceIllustrationLinkOptions 
            && response.iPipelineInforceIllustrationLinkOptions.show) {
            shouldShow = true;            
        }
        
        //If policyServiceContact is populated, section should be shown
        if (response && response.policyServiceContact) {
            shouldShow = true;            
        }
        
        return shouldShow;
    },

    /**
     * Set a flag to cause the iPipeline Policy HS SSO link should to show or not
     * 
     * @param {object} response the response from the REST call
     * @param {object} activePolicyPaidStatus Paid status of active policy
     * @param {boolean} isEnabled Allows the iPipeline PolicyHS feature to be disabled
     *                              for specific environments (defaults to true)
     * @returns {object} presentation options for the link
     */
    _getIPipelinePolicyHSLinkOptions (response, activePolicyPaidStatus, isEnabled = true) {
        // TODO: Remove the isEnabled parameter when the `enablePolicyHSSSOLink` feature flag
        // is no longer needed.
        let presentationOptions = {
            show: false,
            clickable: true
        };

        if (isEnabled) {
            const hasRequiredCapability = 
            (userChannel.request('hasCapability', 'IPipeline_PolicyHS') ||
            userChannel.request('hasCapability', 'IPipeline_PolicyHS(disabled)'));
            
            if (activePolicyPaidStatus && activePolicyPaidStatus.paid &&
                 hasRequiredCapability ) {
                if(
                    response.policyStatus && 
                    response.policyStatus.status && 
                    response.policyStatus.status.toLowerCase() === 'terminated'
                ){
                    presentationOptions.show = false;
                } else if (response.policyStatus 
                    && response.policyStatus.onlySummaryDataAvailable) {

                    presentationOptions.show = false;
                }else{
                    presentationOptions.show = true;
                }
            }

            presentationOptions.clickable =
                !userChannel.request('hasCapability', 'IPipeline_PolicyHS(disabled)');
        }

        return presentationOptions;
    },

    /**
     * Checks to see if the Same Insured Term rider is in the coverage section.
     * 
     * @param {object} response response received from service
     */
    _hasSameInsuredTermRider(response) {
        if (response.coverage && response.coverage.riders
            && (response.coverage.riders.length > 0)) {

            let riders = response.coverage.riders;
            for (let i = 0; i < riders.length; i++) {
                if (riders[i].coverageType === 'SameInsuredTerm') {
                    return true;
                }
            }
        }

        return false;
    },

    /**
     * Set a flag to cause the iPipeline policy inforceIllustration link should to show or not
     * 
     * Illustrations for Whole Life Select, Whole Life 95 and Whole 121 are currently in Production.
     * Illustrations for VUL are currently being
     * developed, so the link will only be available for those policies in the
     * environments that have been enabled by the feature flag.
     * 
     * Removed usage of feature flag - enableInforceIllusLegacyAndLiberty, and 
     * parameter - isLegacyOrLibertyEnabled with Sept 30, 2023 release
     *
     * @param {object} response the response from the REST call
     * @param {boolean} isVULEnabled Allows the iPipeline policyinforceIllustration to be 
     *      disabled for specific environments for isVULEnabled (defaults to false)
     * @param {boolean} isLegacy121Enabled Allows the iPipeline policyinforceIllustration to be 
     *      disabled for specific environments for isLegacy121Enabled (defaults to false)
     * @returns {object} presentation options for the link
     */
    _getIPipelineInforceIllustrationLinkOptions(response,
        isVULEnabled= false, isLegacy121Enabled = false) {
        let presentationOptions = {
            show: false,
            clickable: true
        };

        const wlsProductCode = '171LPL';
        const wl95ProductCode = '171LPL95';
        const wl121ProductCode = '171LPL121';
        const wl7702ProductCodes = ['211WLS','211WLS95','211WLS121'];
        const vul222ProductCode = '222VFCVAT';
        const legacyOrLibertyProductCode = ['071WL', '081SA'];
        const legacy121ProductCode = '091WL121';

        const hasRequiredCapability =
            (userChannel.request('hasCapability', 'IPipeline_iGO') ||
            userChannel.request('hasCapability', 'IPipeline_iGO(disabled)'));

        let isWholeLifeSelect = false;
        let isWholeLife95121 = false;
        let isWholeLife7702 = false;
        let listBill = false;
        let isVUL = false;
        let isLegacyOrLiberty = false;
        let isLegacy121 = false;

        if (response.policyStatus && response.policyStatus.acordHoldingStatus === 'Active') {
            if (response.product) { 
                if (response.product.productCode === wlsProductCode) {
                    isWholeLifeSelect = true;                    
                } else if (response.product.productCode === wl95ProductCode ||
                    response.product.productCode === wl121ProductCode) {
                    isWholeLife95121 = true;
                } else if (wl7702ProductCodes.indexOf(response.product.productCode) >= 0) {
                    isWholeLife7702 = true;
                } else if (response.product.productCode === vul222ProductCode) {
                    isVUL = true;
                } else if (legacyOrLibertyProductCode.indexOf(response.product.productCode) >= 0) {
                    isLegacyOrLiberty= true;
                } else if (response.product.productCode === legacy121ProductCode) {
                    isLegacy121 = true;
                }        
            }
        }

        listBill = !!(response.billingDetail 
            && response.billingDetail.currentBilling
            && response.billingDetail.currentBilling.listBill);

        if (hasRequiredCapability) {
            //check products that should display inforce illustration link
            if (isWholeLifeSelect || isWholeLife95121 || isWholeLife7702) {
                presentationOptions.show = true;
            } else if (isLegacyOrLiberty) {
                presentationOptions.show = true;
            } else if (isVULEnabled && isVUL) {
                presentationOptions.show = true;
            } else if (isLegacy121Enabled && isLegacy121) {
                presentationOptions.show = true;
            }
            //turn off for policies on listBill
            if (listBill) {
                presentationOptions.show = false;
            }

            if(response.policyStatus 
            && (response.policyStatus.statusDetail === 'Annuitization'
            || response.policyStatus.statusDetail === 'Reduced Paid-Up'
            || response.policyStatus.statusDetail === 'Extended Term Insurance'
            || (response.policyStatus.statusDetail === 'Disability Waiver' && !isVUL))){
                presentationOptions.show = false;
            }
    
            presentationOptions.clickable =
                !userChannel.request('hasCapability', 'IPipeline_iGO(disabled)');
        }

        return presentationOptions;
    },

    /**
     * Set whether current policy is in Inactive status.
     *
     * A new flags will be added based on policy Status
     *
     *      isAbandoned
     * 
     * @param {object} response response received from service
     *
     * @return {object} updated response object with new flags 
     */
    _setAbandonedPolicyFlag (response) {

        const inactivePolicyStatus = [
            'Declined', 
            'Postponed', 
            'Withdrawn', 
            'Incomplete'
        ];

        if (response.policyStatus) {
            
            if (_.contains(inactivePolicyStatus, response.policyStatus.status)) {
                response.isAbandoned = true;
            }
        }

        return response;
    },

    /**
     * Set whether current policy is in active status.
     *
     * A new flags will be added based on policy Status
     *
     *      isActive
     * 
     * @param {object} response response received from service
     *
     * @return {object} updated response object with new flags 
     */
    _setActivePolicyFlag (response) {
        if (response.policyStatus && response.policyStatus.acordHoldingStatus === 'Active') {
            response.isActive = true;
        }
        return response;
    },

    /**
     * Set whether current policy is in the active status view.
     *
     * A new flag will be added based on policy StatusView
     *
     *      isActiveStatusView
     * 
     * @param {object} response response received from service
     *
     * @return {object} updated response object with new flag 
     */
    _setActiveStatusViewFlag (response) {
        if (response.policyStatus && response.policyStatus.statusView === 'Active') {
            response.isActiveStatusView = true;
        }
        return response;
    },

    /**
     * Set policyStatus.show properties if acordHoldingStatus is 'Dormant' or'Unknown'
     * 
     * @param {object} response response received from service
     * @return {object} updated response object with new and updated property.
     */
    _setShowflag (response) {
        if (_.isObject(response.policyStatus)) {
            response.policyStatus.show = true;
            if (_.contains(['Dormant','Unknown'], response.policyStatus.acordHoldingStatus)) {
                response.policyStatus.show = false;
            }
        }
        return response;
    },

    /**
     * Add "show" flags to servicingProducer and writingProducers objects
     * 
     * @param {Object} response service response
     * @param {Object} activePolicyPaidStatus Paid status of active policy
     * 
     * if policy is in active state and paid date is after/within 30 days
     */
    _setShowAgentsFlag (response, activePolicyPaidStatus) {
        if (response.writingProducers) {
            response.showWritingProducers = true;
        }
        if (response.servicingProducer) {
            response.showServicingProducer = false;
        }

        if (activePolicyPaidStatus && activePolicyPaidStatus.after30Days) {
            if (response.servicingProducer) {
                response.showServicingProducer = true;
            }

            if (response.writingProducers) {
                response.showWritingProducers = false;
            }
        }

        return response;
    },

    /**
     * Sort Writing Producers in descending order based on splitPercent field
     * 
     * @param {object} response response received from service
     *
     * @return {object} updated response object with sorted list of writingProducers 
     */
    _sortWritingProducers (response) {
        if (response.writingProducers && response.writingProducers.length > 1) {
            response.writingProducers =  _.sortBy(
                response.writingProducers, 'splitPercent'
            ).reverse();
        }

        return response;
    },

    /**
     * Set alert message to display on policy detail message
     * @param {object} response response received from service
     *
     * @return {object} updated response object with selected alert message 
     */
    _setAlertAttentionMessage (response) {
        let responseCopy  = Backbone.$.extend(true, {}, response);
        this.alertAttentionMessages = {
            activeScopeSLRPPolicy : {
                id      : 'under-construction-alert-message',
                title   : 'No additional information is available online for this policy.',
                message : 'SPIAs are issued on a system that currently does not interface '+
                            'with OSO. More policy details will be provided at a later time.'
            },
            terminated : {
                id      : 'terminated-alert-message',
                title   : 'This policy is terminated.',
                message : 'Information displayed is as of the termination date. '+
                            'Some sections of policy detail are unavailable.'
            },
            suspended : {
                id      : 'suspended-alert-message',
                title   : 'This policy is being updated.',
                message : 'Limited information is available at this time. '+
                            'Some sections of policy detail are unavailable.'
            },
            maintenance  : {
                id      : 'maintenance-alert-message',
                title   : 'This policy is being updated.',
                message : 'Limited information is available at this time. '+
                            'Some sections of policy detail are unavailable.'
            },
            maintenanceAndDeathClaimPending  : {
                id      : 'maintenance-death-claim-pending-alert-message',
                title   : 'This policy is being updated.',
                message : 'Limited information is available at this time. '+
                            'Some sections of policy detail are unavailable. '+
                            'A claim is also pending for this policy. Beneficiary status '+
                            'is subject to confirmation by the insurance company.'
            },
            incompleteFinancials  : {
                id      : 'incomplete-financials-alert-message',
                title   : 'NOTICE:',
                message : 'The policy values do not reflect transactions that might already be ' +
                            'in progress.'
            }            
        };

        if (responseCopy.policyStatus) {
            if (responseCopy.policyStatus.onlySummaryDataAvailable) {
                responseCopy.alertAttentionMessage = 
                    this.alertAttentionMessages.activeScopeSLRPPolicy;
            } else if (responseCopy.policyStatus.acordHoldingStatus ==='Inactive') {
                responseCopy.alertAttentionMessage = this.alertAttentionMessages.terminated;
            } else if (responseCopy.policyStatus.acordHoldingStatus ==='Dormant') {
                responseCopy.alertAttentionMessage = this.alertAttentionMessages.suspended;
            } else if(responseCopy.policyStatus.inMaintenance 
                    && responseCopy.policyStatus.deathClaimPending) {
                responseCopy.alertAttentionMessage 
                        = this.alertAttentionMessages.maintenanceAndDeathClaimPending;
            } else if (responseCopy.policyStatus.inMaintenance) {
                responseCopy.alertAttentionMessage = this.alertAttentionMessages.maintenance;
            } else if (responseCopy.policyStatus.incompleteFinancials) {
                responseCopy.alertAttentionMessage 
                        = this.alertAttentionMessages.incompleteFinancials;
            }
        }

        return responseCopy;
    },

    /**
     * Set showPolicyStatement flag based on acordHoldingStatus and Termination date
     * @param {object} response response received from service
     *
     * @return {object} updated response object with new flag 
     */
    _setShowPolicyStatementLinkFlag(response) {
        const holdingStatus = response.policyStatus.acordHoldingStatus;
        let responseCopy    = Backbone.$.extend(true, {}, response);
        let daysSinceTerminated;

        responseCopy.showPolicyStatementLink = true;
        
        if (holdingStatus === 'Proposed') {
            responseCopy.showPolicyStatementLink = false;
        } else if (holdingStatus === 'Inactive') {
            if (!response.policyStatus.terminationDate) {
                responseCopy.showPolicyStatementLink = false;
            } else {
                daysSinceTerminated = dateDiff(response.policyStatus.terminationDate);

                // two years after termination date
                if (daysSinceTerminated >= 730) {
                    responseCopy.showPolicyStatementLink = false;
                } 
            }
        }
        return responseCopy;
    },
    /**
     * Set showPolicyExplanationBenefits flag based on acordHoldingStatus and statusDetail
     * @param {object} response response received from service
     *
     * @return {object} updated response object with new flag
     */
    _setShowPolicyExplanationBenefitsFlag(response) {

        if(response.policyStatus && response.policyStatus.acordHoldingStatus === 'Inactive'
        && response.policyStatus.statusDetail === 'Death Claim'){
            response.hasExplanationDetails = true;
        }
        else{
            response.hasExplanationDetails = false;
        }
        return response;
    }
};

module.exports = policyIdentificationSection;
