/* global require, Marionette */

/**
* Performance center section View with text alone
*/

var performanceCenterTextTemplate 
    = require('../templates/performance-center-text-t.hbs');

var PerformanceCenterTextView = Marionette.ItemView.extend({
    template: performanceCenterTextTemplate
});

module.exports = PerformanceCenterTextView;