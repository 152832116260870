/* global Backbone:false, Marionette:false */
/**
 * View used to display the form which is needed to
 * send an HTTP POST in a new window.
 */

var FormsModel = require('../models/ipipeline-forms-m');
var template   = require('../templates/ipipeline-forms-t.hbs');

var analyticsChannel = Backbone.Radio.channel('analytics');

var FormsView = Marionette.ItemView.extend({

    errors   : {
        missingProducerRecord : '<strong>Unable to connect you to Forms.</strong> ' +
            'Please contact the Sales Desk with reference code 1.',
        xmlGenerationError    : 'Unable to connect you to Forms at this time.'
    },

    events   : {
        'click @ui.formLink' : '_fetchToken'
    },

    ui       : {
        dataInput  : '#ipipeline-credential-form input[name="ApplicationDataXML"]',
        form       : '#ipipeline-credential-form',
        formLink   : '#form-link'

    },

    template : template,

    /**
     * Initialize the view.
     *
     * "showDisabled" can be set to TRUE in order to create a disabled view to the user.
     *
     * @param {?Object} options
     */
    initialize : function initialize(options) {

        this.model = new FormsModel();

        if (options && options.showDisabled && options.showDisabled === true) {
            this.model.set('showDisabled', options.showDisabled);
        }

        this.listenTo(this.model, 'sync', this._setupFormAndSubmit);
        this.listenTo(this.model, 'error', this._handleFormsSsoError);
        this.listenTo(this.model, 'change:formsPipeErrorMessage', this.render);

    },

    /**
     * Used to handle errors received from the service while requesting the
     * iPipeline FormsPipe token.
     *
     * The service can throw a 500 error when there is an error generating XML.
     *
     * There are some instances where the producer doesn't exist in the expected table
     * or the producer just isn't set up with an account. These will throw a 404 error and
     * 403 error respectively.
     *
     * @see {@link https://oneamerica.atlassian.net/browse/OOSO-2128|OOSO-2128}
     *
     * @param {Object} model The model
     * @param {Object} response The response from the service.
     * @private
     */
    _handleFormsSsoError : function _handleFormsSsoError(model, response) {

        // close since its already opened.
        if (this.formspipeWindow) {
            this.formspipeWindow.close();
        }

        if (response && response.status) {

            if (response.status === 404 || response.status === 403) {
                this.model.set('formsPipeErrorMessage', this.errors.missingProducerRecord);

            } else if (response.status === 500) {
                this.model.set('formsPipeErrorMessage', this.errors.xmlGenerationError);
            }

            analyticsChannel.trigger('trackException', {
                fatal   : false,
                message : 'Received a ' + response.status + ' response from the SSO iPipeline ' +
                    'end point. Error message: ' + response.responseText
            });
        }

    },

    /**
     * Retrieve the targetUrl and XML token from the service.
     * @param {Object} e jQuery event object
     * @private
     */
    _fetchToken : function _fetchToken(e) {
        var isDisabled = Backbone.$(e.currentTarget).closest('.center').hasClass('disabled');

        e.preventDefault();

        // if the parent div.center is disabled, don't do anything else
        if (!isDisabled) {
            
            // close if its already opened. In IE 11 if window already opened
            // window.focus didn't works, if user again clicking link again.
            // Tab get just blinked, when re-opening it, as that's the feature of IE11.
            if (this.formspipeWindow) {
                this.formspipeWindow.close();
            }

            this.formspipeWindow = window.open('about:blank', 'formspipe');

            // Don't allow another fetch if we already have a good token
            if (!this.model.has('ApplicationDataXML') || this.model.has('formsPipeErrorMessage')) {

                this.model.unset('formsPipeErrorMessage');
                this.model.fetch();

            } else {
                this._setupFormAndSubmit();
            }
        }
    },

    /**
     * Sets the action attribute of the form to the "targetUrl" returned from the service,
     * sets the token returned from the service to the "ApplicationDataXML" input, and then
     * submits the form.
     * @private
     */
    _setupFormAndSubmit : function _setupFormAndSubmit () {
        var targetUrl = this.model.get('targetURL');
        var xmlData   = this.model.get('ApplicationDataXML');

        if (targetUrl && xmlData) {
            // set up the form
            this.ui.form.attr('action', targetUrl);
            this.ui.dataInput.val(xmlData);

            this.ui.form.submit();
        }

    }

});

module.exports = FormsView;