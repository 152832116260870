/* global Marionette */
/**
 * The simple, default view of the My Business module.
 */

var productsTemplate = require('../templates/products-t.hbs');

var ProductsView = Marionette.ItemView.extend({
    template: productsTemplate
});
module.exports = ProductsView;
