// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <strong id=\"life-lives-goal-achieved\" ><span class=\"oa-forest\">\r\n        Goal achieved!\r\n    </span></strong>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <strong>\r\n    <span class=\"oa-lake\">"
    + alias4(((helper = (helper = helpers.forNextBonus || (depth0 != null ? depth0.forNextBonus : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"forNextBonus","hash":{},"data":data}) : helper)))
    + "</span>\r\n    </strong> life lives needed to reach goal of \r\n    <strong>\r\n        <span class=\"oa-lake\">"
    + alias4(((helper = (helper = helpers.bonusLevel || (depth0 != null ? depth0.bonusLevel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bonusLevel","hash":{},"data":data}) : helper)))
    + "</span>\r\n    </strong>\r\n    life lives bonus and a \r\n    <strong>\r\n    <span class=\"oa-pumpkin\">"
    + alias4((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.nextBonusValue : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n    </span>\r\n    </strong>\r\n     bonus!\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "oa-banner-red";
},"7":function(container,depth0,helpers,partials,data) {
    return "oa-lake";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<li id=\"life-lives-bonus-values\">\r\n<i class=\"fa-li fa fa-arrow-right\"></i>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.totalSold : depth0),200,{"name":"compare","hash":{"operator":">="},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n</li>\r\n\r\n<li id=\"life-lives-percent-value\">\r\n<i class=\"fa-li fa fa-arrow-right\"></i>\r\n<strong>\r\n<span class=\""
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.totalSold : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = helpers.totalSold || (depth0 != null ? depth0.totalSold : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"totalSold","hash":{},"data":data}) : helper)))
    + "</span>\r\n</strong>  life lives\r\n</li>";
},"useData":true});
