/* global Backbone:false, Marionette:false, module, _:false */
/**
    A model parser for the policy.policyValue object
 */

const policyValue = Marionette.Object.extend({

    setCashValueAmountLabel (policyData) {
        let result = Backbone.$.extend(true, {}, policyData);

        if (policyData.policyValue
            && policyData.policyValue.cashValueAmount
            && policyData.product
            && policyData.product.productTypeCode
            && policyData.product.productName) {

            if (policyData.product.productName.indexOf('Annuity Care') === 0) {
                result.policyValue.cashValueAmountLabel = 'Accumulated Value';
            } else if (policyData.product.productTypeCode === 'VA') {
                result.policyValue.cashValueAmountLabel = 'Cash Value';
            }

        }

        return result;
    },

    /**
     * For the policyValue object, the `netSurrenderValueAmount` is displayed for different
     * items depending on the product as shown below:
     *
     * ========================================================================
     * |  Label Name            |                Used for                     |
     * ========================================================================
     * | "Cash Surrender Value" | Annuity Care, Asset Care Life, INXAN, FDIA  |
     * |                        | Legacy Care, and Asset Care Annuities       |
     * ------------------------------------------------------------------------
     * | "Net Cash Value"       | VUL, FA, UL, ISL                            |
     * ------------------------------------------------------------------------
     *
     * If the `policyValue.netSurrenderValueAmount` exists, a new property
     * named "netSurrenderValueAmountLabel" will be appended to the `policyValue` object.
     *
     * @param {*} policyData
     * @return {*}
     */
    setNetSurrenderValueAmountLabel (policyData) {
        const cashSurrenderProductTypes = ['FDIA', 'INXAN'];
        const netCashProductTypes       = ['FA', 'ISL', 'UL', 'VUL'];
        let result                      = Backbone.$.extend(true, {}, policyData);

        let label;
        let productName;
        let productTypeCode;

        if (policyData.policyValue
            && policyData.policyValue.netSurrenderValueAmount
            && policyData.product
            && policyData.product.productTypeCode
            && policyData.product.productName) {

            productName     = policyData.product.productName;
            productTypeCode = policyData.product.productTypeCode;

            if (_.contains(netCashProductTypes, productTypeCode)) {
                label = 'Net Cash Value';

            } else if (_.contains(cashSurrenderProductTypes, productTypeCode)
                || productName.indexOf('Annuity Care') === 0
                || ((productName.indexOf('Asset-Care') === 0 ||
                    productName.indexOf('Asset Care') === 0) && productTypeCode === 'Life')
                || (productName.indexOf('Legacy Care') === 0 && productTypeCode === 'Annuity')) {

                label = 'Cash Surrender Value';
            }

            // Add "netSurrenderValueAmountLabel" to "policyValue" object in copy of policyData
            result.policyValue.netSurrenderValueAmountLabel = label;

        }

        return result;
    }
});

module.exports = policyValue;