// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " has-error";
},"3":function(container,depth0,helpers,partials,data) {
    return " well-form-error";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\"";
},"7":function(container,depth0,helpers,partials,data) {
    return " hide";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<h2 class=\"header\">eCheck Authorization</h2>\r\n<p>If you are sending requirements with an eCheck form, select the box below. This selection requires an attachment.</p>\r\n<div class=\"form-group"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.creditCardErrorText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n    <div class=\"well margin-btm-0"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.creditCardErrorText : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n        <label>\r\n            <input name=\"credit-card\" type=\"checkbox\" class=\"ace\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.creditCard : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n            <span class=\"lbl\"> Submission includes eCheck authorization</span>\r\n        </label>\r\n    </div>\r\n    <div id=\"credit-card-error-region\" class=\"help-block"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.creditCardErrorText : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = helpers.creditCardErrorText || (depth0 != null ? depth0.creditCardErrorText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"creditCardErrorText","hash":{},"data":data}) : helper)))
    + "</div>\r\n</div>";
},"useData":true});
