// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.isActiveAnnuitizedFDIA),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <div id=\"income-payment\">  \r\n            <h2 class=\"header\">Income Payment Information</h2>\r\n\r\n            <div class=\"row\">\r\n                <div class=\"col-md-6\">\r\n                    <div class=\"profile-user-info profile-user-info-striped\">\r\n                        <div class=\"profile-info-row\">\r\n                            <div class=\"profile-info-name\">Next Income Payment Amount </div>\r\n                            <div class=\"profile-info-value\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.nextPayout : depth0)) != null ? stack1.payoutAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                        </div>\r\n                        <div class=\"profile-info-row\">\r\n                            <div class=\"profile-info-name\">Next Income Payment Date </div>\r\n                            <div class=\"profile-info-value\">"
    + alias3((helpers.dateFormat || (depth0 && depth0.dateFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.nextPayout : depth0)) != null ? stack1.payoutDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "</div>\r\n                        </div>\r\n                        <div class=\"profile-info-row\">\r\n                            <div class=\"profile-info-name\">Last Income Payment Amount </div>\r\n                            <div class=\"profile-info-value\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.lastPayout : depth0)) != null ? stack1.payoutAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"col-md-6\">\r\n                    <div class=\"profile-user-info profile-user-info-striped\">\r\n                        <div class=\"profile-info-row\">\r\n                            <div class=\"profile-info-name\">Last Income Payment Date </div>\r\n                            <div class=\"profile-info-value\">"
    + alias3((helpers.dateFormat || (depth0 && depth0.dateFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.lastPayout : depth0)) != null ? stack1.payoutDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "</div>\r\n                        </div>\r\n                        <div class=\"profile-info-row\">\r\n                            <div class=\"profile-info-name\">Total Income Received </div>\r\n                            <div class=\"profile-info-value\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.payoutAmountTotal : depth0),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"income-payment-unavailable\">\r\n        <h2 class=\"header\">Income Payment Information</h2>\r\n\r\n        <div class=\"bigger-115\">        \r\n            <strong>Section unavailable</strong>\r\n        </div>\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"ifAvailable","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "<!-- end of Income Payment -->";
},"useData":true});
