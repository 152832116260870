/* global Backbone:false */

/**
 * Model object to back the orgs-table-v object.
 * @type {*}
 */
var OrgsTableViewModel = Backbone.Model.extend({
    defaults : {
        col        : 1,
        dir        : 'asc',
        length     : 25,
        producerId : null,
        start      : 0
    },

    /**
     * Lifecycle function.
     * Ensure that numeric attrs are set as Number.
     * Set a listener for changes to the producerId property
     *
     * @param options the standard options to a Model
     */
    initialize : function initialize(attrs, options) {
        if (attrs) {
            this._setNumber('col', attrs.col);
            this._setNumber('length', attrs.length);
            this._setNumber('start', attrs.start);
        }
        this.listenTo(this, 'change:producerId', this._resetModel);
    },

    /**
     * Handler to reset the model to the default sort and page. Does not reset the page length
     * Called when the producerId changes.
     *
     * @private
     */
    _resetModel : function _resetModel() {

        // reset to the default sort, at the first page
        this.set({
            col   : 0,
            dir   : 'asc',
            start : 0
        });
    },

    /**
     * Some attributes need to be Number, or odd things tend to happen.
     * @param attr the name of the attribute
     * @param value the value of the attribute
     * @private
     */
    _setNumber : function _setNumber(attr, value) {
        var numberValue = this.defaults[attr];
        if (value && !isNaN(value)) {
            numberValue = Number(value);
        }
        this.set(attr, numberValue);
    }
});

module.exports = OrgsTableViewModel;
