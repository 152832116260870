/* global Backbone:false */
/**
 * Simple model used to retrieve data used for explanation of benefits.
 */
import config from '../../../config/config';

const PolicyExplanationBenefitsModel = Backbone.Model.extend({

    errors : {
        policyIdRequired : 'policyId id property is required to retrieve explanation'+
        'of benefits links'
    },

    /**
     * Lifecycle method. Requires that the attrs param exists and has an "policyId" property.
     * @param attrs the attributes to set in the model
     * @param options the standard options object
     */
    initialize(attrs, options) {
        if (!attrs || !attrs.policyId) {
            throw new Error(this.errors.policyIdRequired);
        }
    },
    /**
     * Parse the response. Basically, place the response in the model'
     *  "explanationBenefits" attribute.
     * @param response the server response
     * @param options the standard options object
     */
    parse (response) {
        this.set('explanationBenefits', response);
    },

    urlRoot() {
        const url = `${config.apiUrlRoot}policies/${this.get('policyId')}/eobs`;
        return url;
    }

});

module.exports = PolicyExplanationBenefitsModel;