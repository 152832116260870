// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "Investment ";
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div id=\"allocation-info\" class=\"table-responsive\">\r\n        <table class=\"table table-striped table-bordered table-hover\">\r\n            <thead>\r\n                <tr>\r\n                    <th>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.isIndexedAnnuity),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.program(6, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                    </th>\r\n                    <th class=\"text-right\">Percent</th>\r\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.isIndexedAnnuity),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\r\n                </tr>\r\n            </thead>\r\n            <tbody>\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.investment : depth0)) != null ? stack1.investmentAllocation : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "            </tbody>\r\n        </table>\r\n    </div>\r\n    <!-- /.table-responsive -->\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","data":data,"blockParams":blockParams,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                            Account Strategy\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                            Investment Account Name\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "<th>Type</th>";
},"10":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <tr>\r\n                        <td>"
    + alias1(container.lambda(((stack1 = blockParams[0][0]) != null ? stack1.productFullName : stack1), depth0))
    + "</td>\r\n                        <td class=\"text-right\">"
    + alias1((helpers.round || (depth0 && depth0.round) || helpers.helperMissing).call(alias2,((stack1 = blockParams[0][0]) != null ? stack1.allocationPercent : stack1),{"name":"round","hash":{},"data":data,"blockParams":blockParams}))
    + "%</td>\r\n"
    + ((stack1 = helpers["if"].call(alias2,((stack1 = (data && data.root)) && stack1.isIndexedAnnuity),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                    </tr>\r\n";
},"11":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "                            <td>"
    + container.escapeExpression(container.lambda(((stack1 = blockParams[1][0]) != null ? stack1.investmentType : stack1), depth0))
    + "</td>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"allocation-section-unavailable\" class=\"bigger-115\">\r\n        <strong>Section unavailable</strong>\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<h2 class=\"header\">"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (data && data.root)) && stack1.isIndexedAnnuity),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "Allocation</h2>\r\n"
    + ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.investment : depth0),{"name":"ifAvailable","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.program(13, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "<!-- end of investment Allocation -->";
},"usePartial":true,"useData":true,"useBlockParams":true});
