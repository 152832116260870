/* global Marionette, _:false*/
/**
 A model parser class which parses the data for Policy billing data

 */
var policyBillingPayment = Marionette.Object.extend({

    /**
     * Logic to determine if 2 large sections of the billingDetail / payout section will display.
     * If the response contains the billingDetail section, this function will set the following
     * fields within that section:
     * - showBillingModeAndMethod (true if paymentMode or paymentMethod exist in 
     *      billingDetail or if paymentDraftDay exists for active status policy)
     * - showCashIncomePlan (true if premiumSchedule or riderPremiumSchedule exist in 
     *      billingDetail or if cashWithApplication or consolidatedPlan exist for a policy
     *      in pending status view, or billedToDate or lastPremiumDate exist for an active
     *      status policy, or if totalDepositsToDate exist for a policy in active status
     *      view, or if payout.incomeOption or payout.payoutStartDate exists)
     *  
     * @param response the response from the server as a JSON object
     * @returns {*} the response, with the added flags, if the billingDetail section exists
     * @private
     */
    _setBillingDetailDisplayFlags: function _setBillingDetailDisplayFlags(response) {
        var paymentDraftDay;
        var paymentMethod;
        var paymentMode;
        var puaRiderPaymentAmount;
        
        var incomeOption;
        var payoutStartDate;

        if (response.billingDetail && !_.isEmpty(response.billingDetail)) {

            if (response.billingDetail.banking) {
                paymentDraftDay = response.billingDetail.banking.paymentDraftDay;                
            }

            if (response.billingDetail.currentBilling) {
                paymentMode     = response.billingDetail.currentBilling.paymentMode;
                paymentMethod   = 
                    response.billingDetail.currentBilling.paymentMethod;
                puaRiderPaymentAmount 
                    = response.billingDetail.currentBilling.puaRiderPaymentAmount;
            }

            response.billingDetail.showBillingModeAndMethod = !!(
                paymentMode 
                    || paymentMethod
                    || !!(response.isActive && 
                            !!(paymentDraftDay 
                                || response.billingDetail.paidToDate
                                || puaRiderPaymentAmount))
            );
            if (response.payout) {
                if (response.payout.incomeOption) {
                    incomeOption = response.payout.incomeOption;
                }

                if (response.payout.payoutStartDate) {
                    payoutStartDate = response.payout.payoutStartDate;
                }               
            }

            response.billingDetail.showCashIncomePlan = !!(
                incomeOption 
                    || (response.billingDetail.cashWithApplication 
                        && !response.isActiveStatusView)
                    || payoutStartDate 
                    || (response.consolidatedPlan
                        && !response.isActiveStatusView)
                    || response.billingDetail.premiumSchedule
                    || response.billingDetail.riderPremiumSchedule
                    || (response.isActive &&
                        (                          
                            response.billingDetail.billedToDate                            
                            || response.billingDetail.lastPremiumDate
                        )
                    )
                    || (response.isActiveStatusView && 
                        response.billingDetail.totalDepositsToDate)
            );
        }

        return response;
    },

    /**
     * The logic for OSO2.7.67. Determine whether or not
     * to replace "Modal" with either "Single" or <billingMode>.
     *
     * @param {object} policyData - the raw policy data object
     * @returns {*}
     */
    _setBillingHeaderLeadingText (policyData) {
        let headerText = 'Modal';

        if (policyData.billingDetail && policyData.billingDetail.currentBilling) {

            // if paymentMethod is "Single Premium",
            // replace "Modal" with "Single"
            if (policyData.billingDetail.currentBilling.paymentMethod === 'Single Premium') {
                headerText = 'Single';

            } else if (policyData.billingDetail.currentBilling.paymentMode) {
                // if paymentMethod is not "Single Premium",
                // replace "Modal" with paymentMode
                headerText = policyData.billingDetail.currentBilling.paymentMode;
            }
        }

        policyData.billingHeaderLeadingText = headerText;

        return policyData;
    },
    
});

module.exports = policyBillingPayment;