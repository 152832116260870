/* global Backbone */

var config    = require('../config/config');

var userModel = Backbone.Model.extend({

    // Tell Backbone to use the 'webId' attr instead of id.
    idAttribute: 'webId',
    url: config.apiUrlRoot + 'user',

    defaults : {
        producerId      : null,
        displayName     : '',
        capabilities    : []
    },

    validate : function(attr,options){

        var errors = [];

        if (!Array.isArray(attr.capabilities)) {
            errors.push({capabilities:'Capabilities should be an array'});
        }

        return errors.length ? errors : null;
    },

    hasCapability : function(capability) {
        return (capability && this.get('capabilities').indexOf(capability) !== -1);
    }

});

module.exports = userModel;
