// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        <div class=\"alert alert-message alert-attention\" id=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.alertAttentionMessage)) && stack1.id), depth0))
    + "\">\r\n            <p>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.alertAttentionMessage)) && stack1.title),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.alertAttentionMessage)) && stack1.message), depth0))
    + "\r\n            </p>\r\n        </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.alertAttentionMessage)) && stack1.title), depth0))
    + "</strong>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.policyNumber || (depth0 != null ? depth0.policyNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"policyNumber","hash":{},"data":data}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    return "Recently Received\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"profile-info-row\" id=\"product\">\r\n                        <div class=\"profile-info-name\"><h5 class=\"gray-50\">Product:</h5></div>\r\n                        <div class=\"profile-info-value\"><h5>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.productName : stack1), depth0))
    + "</h5></div>\r\n                    </div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <div class=\"profile-info-row\" id=\"company\">\r\n                        <div class=\"profile-info-name\"><h5 class=\"gray-50\">Company:</h5></div>\r\n                        <div class=\"profile-info-value\"><h5>"
    + container.escapeExpression(((helper = (helper = helpers.carrierCode || (depth0 != null ? depth0.carrierCode : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"carrierCode","hash":{},"data":data}) : helper)))
    + "</h5></div>\r\n                    </div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"profile-info-row\" id=\"issue-date\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Issue Date:</h5>\r\n                        </div>\r\n                        <!-- /.profile-info-name -->\r\n                        <div class=\"profile-info-value\">\r\n                            <h5>"
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.issued : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "</h5>\r\n                        </div><!-- /.profile-info-value -->\r\n                    </div><!-- ./profile-info-row -->\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"profile-info-row\" id=\"termination-date\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Termination Date:</h5>\r\n                        </div>\r\n                        <!-- /.profile-info-name -->\r\n                        <div class=\"profile-info-value\">\r\n                            <h5>"
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.terminationDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "</h5>\r\n                        </div><!-- /.profile-info-value -->\r\n                    </div><!-- ./profile-info-row -->\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <div class=\"profile-info-row\" id=\"issue-state\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Issue State:</h5>\r\n                        </div>\r\n                        <!-- /.profile-info-name -->\r\n                        <div class=\"profile-info-value\">\r\n                            <h5>"
    + container.escapeExpression(((helper = (helper = helpers.issueState || (depth0 != null ? depth0.issueState : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"issueState","hash":{},"data":data}) : helper)))
    + "</h5>\r\n                        </div><!-- /.profile-info-value -->\r\n                    </div>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <div class=\"profile-info-row\" id=\"resident-state\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Resident State:</h5>\r\n                        </div>\r\n                        <!-- /.profile-info-name -->\r\n                        <div class=\"profile-info-value\">\r\n                            <h5>"
    + container.escapeExpression(((helper = (helper = helpers.residentState || (depth0 != null ? depth0.residentState : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"residentState","hash":{},"data":data}) : helper)))
    + "</h5>\r\n                        </div><!-- /.profile-info-value -->\r\n                    </div>\r\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.showWritingProducers),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                        <div class=\"profile-info-row\" id=\"writing-agent\">\r\n                            <div class=\"profile-info-name\">\r\n                                <h5 class=\"gray-50\">Agent:</h5>\r\n                            </div>\r\n                            <!-- /.profile-info-name -->\r\n                            <div class=\"profile-info-value\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.writingProducers : depth0),{"name":"each","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\r\n                            <!-- /.profile-info-value -->\r\n                        </div>\r\n";
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                                    <h5>\r\n                                        "
    + alias3(((helper = (helper = helpers.fullName || (depth0 != null ? depth0.fullName : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"fullName","hash":{},"data":data}) : helper)))
    + " "
    + alias3((helpers.wrapRoleCodeWithParenthesis || (depth0 && depth0.wrapRoleCodeWithParenthesis) || alias2).call(alias1,(depth0 != null ? depth0.fullName : depth0),(depth0 != null ? depth0.roleCode : depth0),{"name":"wrapRoleCodeWithParenthesis","hash":{},"data":data}))
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = (depths[1] != null ? depths[1].writingProducers : depths[1])) != null ? stack1.length : stack1),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                    </h5>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.splitPercent : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    return "                                                <span class=\"gray-80\">"
    + container.escapeExpression((helpers.numberToPercentage || (depth0 && depth0.numberToPercentage) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.splitPercent : depth0),{"name":"numberToPercentage","hash":{},"data":data}))
    + "</span>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.showServicingProducer),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                        <div class=\"profile-info-row\" id=\"servicing-agent\">\r\n                            <div class=\"profile-info-name\"><h5 class=\"gray-50\">Agent:</h5></div>\r\n                            <div class=\"profile-info-value\">\r\n                                <h5>\r\n                                    "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.servicingProducer : depth0)) != null ? stack1.fullName : stack1), depth0))
    + " "
    + alias1((helpers.wrapRoleCodeWithParenthesis || (depth0 && depth0.wrapRoleCodeWithParenthesis) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.servicingProducer : depth0)) != null ? stack1.fullName : stack1),((stack1 = (depth0 != null ? depth0.servicingProducer : depth0)) != null ? stack1.roleCode : stack1),{"name":"wrapRoleCodeWithParenthesis","hash":{},"data":data}))
    + "\r\n                                </h5>\r\n                            </div>\r\n                        </div>\r\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"profile-info-row\" id=\"case-manager\">\r\n                        <div class=\"profile-info-name\"><h5 class=\"gray-50\">Case Manager:</h5></div>\r\n                        <div class=\"profile-info-value\">\r\n                            <h5>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.caseManager : depth0)) != null ? stack1.mailto : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </h5>\r\n                        </div>\r\n                    </div>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                    <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.caseManager : depth0)) != null ? stack1.mailto : stack1), depth0))
    + "\">\r\n                                        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.caseManager : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "<i class=\"fa  fa-envelope oa-lake padding-left-5\"></i><span>Email icon</span>\r\n                                    </a>\r\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.caseManager : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\r\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <div class=\"profile-info-row\" id=\"policy-service-contact\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Policy Service:</h5>\r\n                        </div>\r\n                        <div class=\"profile-info-value\">\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.policyServiceContact : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.iPipelineInforceIllustrationLinkOptions : depth0)) != null ? stack1.show : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.iPipelinePolicyHSLinkOptions : depth0)) != null ? stack1.show : stack1),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\r\n                    </div>\r\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                 <h5>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyServiceContact : depth0)) != null ? stack1.mailto : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data})) != null ? stack1 : "")
    + "                                </h5>\r\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                         <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.policyServiceContact : depth0)) != null ? stack1.mailto : stack1), depth0))
    + "\">\r\n                                          "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.policyServiceContact : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "<i class=\"fa fa-envelope oa-lake padding-left-5\"></i>\r\n                                         </a>\r\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                         "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyServiceContact : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\r\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.iPipelineInforceIllustrationLinkOptions : depth0)) != null ? stack1.clickable : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.program(43, data, 0),"data":data})) != null ? stack1 : "")
    + "                                         Inforce Illustration<i class=\"ace-icon fa oa-lake fa-external-link padding-left-5\"></i>\r\n                                        </a>\r\n                                    </h5>\r\n                                <form id=\"ipipeline-InforceIllustration-form\" action=\"\" method=\"post\" target=\"policyInforceIllustration\">\r\n                                    <input type=\"hidden\" name=\"SAMLResponse\"  />\r\n                                    <input type=\"hidden\" name=\"RelayState\" />\r\n                                </form>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.policyInforceErrorMessage : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    return "                                    <h5>\r\n                                        <a href=\"#\" id=\"ipipeline-InforceIllustration-link\">\r\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "                                    <h5 class=\"disabled\">\r\n                                        <a aria-disabled=\"true\" id=\"ipipeline-InforceIllustration-link\">\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                                    <div class=\"alert alert-warning alert-message\">\r\n                                        <p>"
    + ((stack1 = ((helper = (helper = helpers.policyInforceErrorMessage || (depth0 != null ? depth0.policyInforceErrorMessage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"policyInforceErrorMessage","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\r\n                                    </div>\r\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.iPipelinePolicyHSLinkOptions : depth0)) != null ? stack1.clickable : stack1),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(50, data, 0),"data":data})) != null ? stack1 : "")
    + "                                         Online Service Request<i class=\"ace-icon fa oa-lake fa-external-link padding-left-5\"></i>\r\n                                         </a>\r\n                                    </h5>\r\n                                <form id=\"ipipeline-policyhs-form\" action=\"\" method=\"post\" target=\"policyhs\">\r\n                                    <input type=\"hidden\" name=\"SAMLResponse\"  />\r\n                                    <input type=\"hidden\" name=\"RelayState\" />\r\n                                </form>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.policyHSErrorMessage : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    return "                                    <h5>\r\n                                         <a href=\"#\" id=\"ipipeline-policyhs-link\">\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "                                    <h5 class=\"disabled\">\r\n                                         <a aria-disabled=\"true\" id=\"ipipeline-policyhs-link\">\r\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                                    <div class=\"alert alert-warning alert-message\">\r\n                                        <p>"
    + ((stack1 = ((helper = (helper = helpers.policyHSErrorMessage || (depth0 != null ? depth0.policyHSErrorMessage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"policyHSErrorMessage","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\r\n                                    </div>\r\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"profile-info-row\" id=\"tax-qualification-type\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Tax Qualification:</h5>\r\n                        </div>\r\n                        <!-- /.profile-info-name -->\r\n                        <div class=\"profile-info-value\">\r\n                            <h5>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.taxQualification : depth0)) != null ? stack1.type : stack1), depth0))
    + "</h5>\r\n                        </div><!-- /.profile-info-value -->\r\n                    </div>\r\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"profile-info-row\" id=\"tax-qualification-descr\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Tax Qualification Type:</h5>\r\n                        </div>\r\n                        <!-- /.profile-info-name -->\r\n                        <div class=\"profile-info-value\">\r\n                            <h5>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.taxQualification : depth0)) != null ? stack1.description : stack1), depth0))
    + "</h5>\r\n                        </div><!-- /.profile-info-value -->\r\n                    </div>\r\n";
},"58":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"profile-info-row\" id=\"policy-statement\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Policy Statement:</h5>\r\n                        </div>\r\n                        <!-- /.profile-info-name -->\r\n                        <div class=\"profile-info-value\" id=\"policy-statement-region\">\r\n                        </div><!-- /.profile-info-value -->\r\n                    </div>\r\n";
},"60":function(container,depth0,helpers,partials,data) {
    return " <!-- /.well .well-account-info -->\r\n\r\n                    <div  id=\"policy-explanation-region\">\r\n                    </div>\r\n                <!-- /.well .well-attention -->\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"well well-account-info\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.alertAttentionMessage),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n            <h3>"
    + alias3(alias2(((stack1 = ((stack1 = (data && data.root)) && stack1.policyIdentification)) && stack1.label), depth0))
    + ":&nbsp;\r\n                <span class=\"oa-banner-blue\" id=\"policy-primary-name\">"
    + alias3(alias2(((stack1 = ((stack1 = (data && data.root)) && stack1.policyIdentification)) && stack1.name), depth0))
    + "</span>\r\n            </h3>\r\n        </div>\r\n        <!-- /.col-sm-6 -->\r\n        <div class=\"col-sm-6\">\r\n            <h3>Policy #:&nbsp;<span id=\"policy-number\" class=\"oa-banner-blue\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.policyNumber : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                </span>\r\n            </h3>\r\n        </div>\r\n        <!-- /.col-sm-6 -->\r\n    </div>\r\n    <!-- /.row -->\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n            <div class=\"profile-user-info\">\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.productName : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.carrierCode : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.issued : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.terminationDate : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.issueState : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.residentState : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n            </div>\r\n            <!-- profile-user-info -->\r\n        </div>\r\n        <!-- col-sm-6 -->\r\n        <div class=\"col-sm-6\">\r\n            <div class=\"profile-user-info\">\r\n                \r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.writingProducers : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.servicingProducer : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.caseManager : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.shouldShowPolicyServiceSection : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.taxQualification : depth0)) != null ? stack1.type : stack1),{"name":"if","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.taxQualification : depth0)) != null ? stack1.description : stack1),{"name":"if","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.showPolicyStatementLink),{"name":"if","hash":{},"fn":container.program(58, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n            </div>\r\n            <!-- profile-user-info -->\r\n        </div>\r\n        <!-- /.col-sm-6 -->\r\n    </div>\r\n    <!-- /.row -->\r\n</div>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.hasExplanationDetails),{"name":"if","hash":{},"fn":container.program(60, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
