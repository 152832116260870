// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.isActivePremiumPayingFDIA),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "        <div id=\"income-options\">\r\n            <h2 class=\"header\">Available Income Options</h2>\r\n            <div class=\"table-responsive\">\r\n                <table class=\"table table-striped table-bordered table-hover\">\r\n                    <thead>\r\n                        <tr>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.availablePayoutOptions : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                        </tr>\r\n                    </thead>\r\n                    <tbody>\r\n                        <tr>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.availablePayoutOptions : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                        </tr>\r\n                        <tr>\r\n                            <td colspan=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.availablePayoutOptions : depth0)) != null ? stack1.length : stack1), depth0))
    + "\"><span class=\"table-pseudo-header\">Selected Income Option:&nbsp;</span> "
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.selectedPayoutOption : depth0)) != null ? stack1.payoutAmount : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + " "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.selectedPayoutOption : depth0)) != null ? stack1.payoutMode : stack1), depth0))
    + "</td>\r\n                        </tr>\r\n                    </tbody>\r\n                </table>\r\n            </div>\r\n            <!-- /.table-responsive -->        \r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","data":data,"blockParams":blockParams,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "                            <th>"
    + container.escapeExpression(container.lambda(((stack1 = blockParams[0][0]) != null ? stack1.payoutMode : stack1), depth0))
    + "</th>\r\n";
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "                            <td>"
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? stack1.payoutAmount : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"income-options-unavailable\">\r\n        <h2 class=\"header\">Available Income Options</h2>\r\n        <div class=\"bigger-115\">        \r\n            <strong>Section unavailable</strong>\r\n        </div>\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "<!-- start of Available Income Options -->\r\n"
    + ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"ifAvailable","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.program(7, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "<!-- end of Available Income Options -->";
},"usePartial":true,"useData":true,"useBlockParams":true});
