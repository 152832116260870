/* global Backbone, Marionette, _, $ */
/**
 * A module used to encapsulate behaviors of Marionette views
 * @see {@link http://marionettejs.com/docs/v2.4.7/marionette.behavior.html|Marionettejs.com}
 */

import checkpoint  from '../checkpoint/checkpointModule';
import utils from '../../utils/utils';

const Behaviors = Marionette.Object.extend({

    // default behaviors
    defaultBehaviors : {

        /**
         * Float the <thead> for tables that take up
         * over 50% of the vertical space on the page.
         */
        floatTableHeaders : Marionette.Behavior.extend({
            ui     : {
                jumpLinksButton : '.jumplinks-traveling button',
                panels          : '.collapse',
                tables          : 'table'
            },

            events : {

                // These events are used to disable/enable float table header while 
                // expanding or collapsing panels. Otherwise table header
                // won't get moved along with table since headers are using fixed position

                // enable float table headers when panel expand animation end
                'shown.bs.collapse' : '_floatTableHeaders',

                // destroy float table headers when panel expand animation start
                'show.bs.collapse'  : '_destroyFloatTableHeaders',

                // enable float table headers when panel collapse animation end
                'hidden.bs.collapse' : '_delayedFloatTableHeaders',

                // destroy float table headers when panel collapse animation start
                'hide.bs.collapse'  : '_destroyFloatTableHeaders'
            },

            initialize(options) {
                // No initialization necesary. onDomRefresh takes care of setting up
                // the floating theads both when the view is first displayed and when
                // the DOM refreshes due to resizing the browser.
            },

            onDomRefresh() {
                this._floatTableHeaders();
            },

            /**
             * Since there will be some delay in ending animation while collapsing a panel
             * So put a wait to activate floatTableHeaders
             * 
             * eg: In wcm-content-view while collapsing table we have animation
             * at last to open panel a bit. That was executed after 'hidden.bs.collapse'
             * event. By default duration property set as 400 for jquery.animation.
             */
            _delayedFloatTableHeaders() {
                const floatTableHeaders = _.bind(this._floatTableHeaders, this);
                _.delay(floatTableHeaders, 500);
            },

            /**
             * Determine if the table's height value is 50% of the
             * window height. If so, float the tables <thead> section
             * in the table's .table-responsive container.
             *
             * @see {@link http://mkoryak.github.io/floatThead/|FloatTHead Documentation}
             * @private
             */
            _floatTableHeaders() {
                const thresholdHeightPercent = 0.5;
                const windowHeight           = Backbone.$(window).height();

                // Sanity check to avoid a Type Exception if this function is called when
                // it is not connected to a view (the ui.tables property will still be 'tables'
                // instead of being an array of elements)
                if (typeof this.ui.tables === 'string') {
                    return;
                }

                // Loop through the tables in the view.
                this.ui.tables.each(function () {
                    const table          = $(this);
                    const tableContainer = table.closest('.table-responsive');
                    const tableHeight    = table.height();

                    let tableCollapseWrapper;

                    // If we have a table container and the height of
                    // the table exceeds thresholdHeightPercent, then call
                    // floatThead to float the table's header.
                    if (tableContainer.length &&
                        (tableHeight / windowHeight) > thresholdHeightPercent) {
                        // Make the table header float on the page
                        
                        // Avoid binding floatThead to the table which wrapped inside 
                        // the collapse wrapper panel(parent div element) 
                        // and it doesn't have class '.in'
                        tableCollapseWrapper = tableContainer.parent('.collapse');
                        if (tableCollapseWrapper 
                                && tableCollapseWrapper.length 
                                && !tableCollapseWrapper.hasClass('in')) {
                            return;
                        }

                        table.floatThead({
                            responsiveContainer : function () {
                                return tableContainer;
                            }
                        });
                    }
                });
            },

            // Destroy floadThead functionality from all table elements
            _destroyFloatTableHeaders() {
                this.ui.tables.each(function () {
                    const table = $(this);
                    table.floatThead('destroy');
                });
            },

            /**
             * Looks at the CSS "transform" value. The value of this should be
             * a string like the one below:
             *
             * matrix(1, 0, 0, 1, 280, -1872)
             *
             * The last value of the six numbers (-1872 in the example above)
             * is the translateY value.
             *
             * Based heavily on https://stackoverflow.com/a/29962873/4738351
             *
             * @see {
             *  @link https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix
             * }
             * @param {Object} obj jQuery object
             * @returns {?number} value of the translateY
             * @private
             */
            _getTranslateYValue(obj) {
                const matrix = obj.css('-webkit-transform') ||
                    obj.css('-moz-transform') ||
                    obj.css('-ms-transform')  ||
                    obj.css('-o-transform')   ||
                    obj.css('transform');

                let matches;
                let translateYValue;

                if (typeof matrix === 'string' && matrix !== 'none') {
                    // TranslateY is the last value and may be negative.
                    matches = matrix.match(/.*,\s(-?\d+)\)$/);

                    if (matches && Array.isArray(matches) && matches.length > 1) {
                        translateYValue = parseInt(matches[1], 10);

                        if (_.isNumber(translateYValue)) {
                            return translateYValue;
                        }
                    }
                }

                return null;
            },

            /**
             * Display/hide the jumplinks button depending on whether or not
             * it is located underneath a floating table header.
             * @private
             */
            _handleJumpLinksButton(e) {
                const ctx = this;
                if (ctx.ui.jumpLinksContainer instanceof Backbone.$) {
                    $('.floatThead-container').each(function () {
                        const container = $(this);

                        if (ctx._getTranslateYValue(container) === 0) {
                            ctx.ui.jumpLinksButton.addClass('hidden');
                            ctx.ui.jumpLinksButton.next('ul.dropdown-menu').addClass('hidden');

                            // Break out of the loop since the jumpLinks button is
                            // placed underneath one of the .floatThead-container divs.
                            return false;
                        } else {
                            ctx.ui.jumpLinksButton.removeClass('hidden');
                            ctx.ui.jumpLinksButton.next('ul.dropdown-menu').removeClass('hidden');
                        }
                    });
                }
            }
        }),

        /**
         * Attaches a click event to links that jump to sections within a page.
         */
        jumpLinks : Marionette.Behavior.extend({
            ui : {
                jumpLinks : '#jump-links a, a.jump-link'
            },

            events : {
                'click @ui.jumpLinks' : 'jumpLinkClick'
            },

            initialize(options) {
                this.checkpoint = checkpoint;
                this.utils      = utils;
            },

            /**
             * When the link is clicked, scroll to the element and reset the checkpoint with
             * a subpage element.
             * @param e jQuery event object
             */
            jumpLinkClick(e) {
                const $target = $(e.target);
                
                let hash;
                let state = this.checkpoint.readCheckpoint() || {};
                
                e.preventDefault();

                // if the badge was clicked (a SPAN element), make sure
                // we get a reference to the parent anchor tag so that
                // we can access the href attribute.
                hash = $target.prop('tagName') === 'SPAN' ? $target.parent('a').attr('href')
                    : $target.attr('href');

                // write a checkpoint for the page position
                state = _.extend(state, { subpages: [hash.replace('#', '')] } );
                this.checkpoint.writeCheckpoint(state, true);

                this.utils.scrollTo( $(hash) );
            }
        }),

        /**
         * Handles showing/hiding the table instruction text depending on whether
         * or not horizontal scroll bars are present.
         */
        smartScrollTables : Marionette.Behavior.extend({

            ui : {
                accordionPanel : '.panel',
                tabs           : 'a[data-toggle="tab"]'
            },

            events : {
                'shown.bs.collapse @ui.accordionPanel' : '_onPanelShown',
                'shown.bs.tab @ui.tabs'                : '_onPanelShown'
            },

            initialize(options) {
                // Handle resizing of the window
                $(window).on('resize.app', _.debounce(_.bind(this._tablesSmartScroll, this), 100));
            },

            /**
             * Life cycle of Marionette view.
             */
            onRender() {
                this._tablesSmartScroll();
            },

            /**
             * Triggered after a tab panel or accordion panel is displayed.
             * Passes the target panel into `_tableSmartScroll` as `context`.
             * @param {Object} e jQuery event object
             * @private
             */
            _onPanelShown(e) {
                const tabPanelId = $(e.currentTarget).data('target');
                this._tablesSmartScroll(e, $(tabPanelId));
            },

            /**
             * Loops through a collection of tables in the view. If `context` is
             * passed in, the collection is limited to the context. The table's
             * container and associated div.table-instruction-text is found and passed
             * to `utils.setVisibilityOnSmartScrollMessage`.
             *
             * @param {Object} e jQuery event object
             * @param {Object} [context] jQuery element
             * @private
             */
            _tablesSmartScroll(e, context) {
                let tables;
                if (context && context.length && context instanceof $) {
                    tables = context.find('table');
                } else {
                    tables = $('table');
                }

                tables.each(function () {
                    let table = $(this);
                    let instructionDiv;
                    let tableContainer;

                    tableContainer = table.closest('.table-responsive');


                    // Find out if there is a "data-instruction-container" attribute on the table
                    // and use this as the ID of the associated scroll message <div> for the table.
                    // Otherwise, it's most likely in the parent container for an HTML table.
                    if (table.is('[data-instruction-container]')) {
                        instructionDiv = $(table.data('instruction-container'));
                    } else {
                        // If the table is in a "table-responsive" wrapper, the
                        //div we're looking for should be a sibling of the container.
                        if (tableContainer.hasClass('table-responsive')) {
                            instructionDiv = tableContainer
                                .siblings('.table-responsive-instruction');
                        } else {
                            // WCM tables are not always wrapped in "table-responsive" classes
                            instructionDiv = table.siblings('.table-responsive-instruction');
                        }
                    }

                    utils.setVisibilityOnSmartScrollMessage(tableContainer, instructionDiv);
                });

            }
        }),


        /**
         * Attaches a handler for the survey monkey button
         */
        surveyMonkeyButton : Marionette.Behavior.extend({
            
            ui : {
                surveyButton : '#survey-button'
            },

            events : {
                'click @ui.surveyButton' : 'openSurveyInNewWindow'
            },

            openSurveyInNewWindow() {
                window.open('https://www.surveymonkey.com/r/Q5BNNH5');
            }
        }),


        /**
         * Handle events in WCM content
         */
        wcmContentLinks : Marionette.Behavior.extend({
            ui : {
                'wcmContentLinks' : '.wcmContent a[href^="/wps/wcm/connect/"]' +
                ':not([data-toggle="modal"])',
                backLink          : 'a.oso-back-nav'
            },
            
            events : {
                'click @ui.wcmContentLinks' : 'handleWcmLinkClick',
                'click @ui.backLink'        : 'handleBackLinkClick'
            },

            /**
             * Navigates to the previous history.
             * TODO: Remove this when a more permanent solution for duplicate content
             * items in WCM has been architected.
             * @param e
             */
            handleBackLinkClick(e) {
                e.preventDefault();
                Backbone.history.history.back();
            },

            handleWcmLinkClick(e) {
                const $target = $(e.target);
                const href = $target.attr('href');

                // check to see if we have a valid href and ensure
                // that it does not contain an invalid file extension or identifier
                if (href
                        && this.isViewableFileExtension(href)
                        && this.iconIndicatesViewableContent($target)) {

                    e.preventDefault();
                    this.view.trigger('nav', this.view.pageId + '?wcmLink=' + href);
                }
            },

            /**
             * Checks for file extensions ('.exe', '.pdf', '.zip')
             * which cannot be displayed in the view.
             *
             * @param {string} link
             * @returns {boolean}
             */
            isViewableFileExtension(link) {
                const invalidFileExtensions = ['exe', 'pdf', 'zip'];

                if (_.indexOf(invalidFileExtensions, link.split('.').pop()) > -1 ){
                    return false;
                }

                return true;
            },

            /**
             * Checks the next immediate sibling of the link in the
             * markup and determines whether or not it identifies the
             * target file as a valid file type.
             *
             * The links in the WCM content don't contain a file
             * extension (since the targets are also WCM content).
             * However, the file type can be identified through the
             * use of an icon in the markup itself.
             *
             * For example: PDF content is identified by the following icon
             * immediately following the anchor tag:
             *
             *      <i class="fa gray-80 fa-file-pdf-o"></i>
             *
             * If this icon is found to be an immediate sibling to
             * the link, this method will return false.
             *
             * @param {object} $link jQuery element
             * @returns {boolean}
             */
            // TODO: When existing WCM content has replaced the <i> tags
            // links with the ".icon-*" classes, the `iconIndicatesViewableContent`
            // function as well as the `hasPdfOrZipIcon` variable/logic in
            // `modules/wcm-content/views/wcm-content-v.js` can be removed.
            iconIndicatesViewableContent($link) {
                var classList;
                var invalidClassIdentifiers = ['fa-file-pdf-o', 'fa-file-archive-o'];
                var $iconElement = $link.next();
                var intersectResult = [];

                // check to see if we have an actual <i> element
                if ($iconElement.prop('tagName') === 'I') {

                    // get the classes of the element and split them into an array
                    classList = $iconElement.attr('class').split(/\s+/);

                    // use a union to see if we have any matches
                    intersectResult = _.intersection(invalidClassIdentifiers, classList);
                }

                return intersectResult.length === 0;
            }
        }),

        /**
         * WCM content displayed with a "grid" presentation template
         * needs to be adjusted in order to display properly (OOSO-3485).
         */
        wcmContentGrid : Marionette.Behavior.extend({

            onRender() {

                this.$('.wcm-grid-wrapper-top').each(function () {
                    const wrapper        = $(this);
                    let currentSection   = null;
                    let currentRow       = null;
                    let i               = 0;

                    wrapper.children().each(function () {
                        if ($(this).hasClass('wcm-grid-section')) {
                            currentSection = $(this);
                            i              = 0;
                        } else if ($(this).hasClass('wcm-grid-item')) {

                            // Create a new row after two items
                            if ((i % 2) === 0) {
                                currentRow = $('<div class="row"></div>');
                                if (currentSection instanceof $) {
                                    currentSection.append(currentRow);
                                }
                            }

                            // If currentSection is a jQuery object and contains currentRow (.row)
                            // append the wcm-grid-item.
                            if (currentSection instanceof $ && currentSection.find('.row')) {
                                currentRow.append($(this));
                            }
                            i++;
                        }
                    });
                });
            }
        }),

        /**
         * Manage the "#wcmModal" Bootstrap modal and related links in WCM content.
         */
        wcmModalWindow : Marionette.Behavior.extend({

            ui : {
                wcmModalLinks  : '.wcmContent a[data-toggle="modal"]',
                wcmModalWindow : '#wcmModal',
                wcmModalLarge  : '#wcmModalLarge',
                wcmModalXLarge : '#wcmModalXLarge'
            },

            events : {
                'click @ui.wcmModalLinks'            : '_loadWcmModal',
                'hidden.bs.modal @ui.wcmModalWindow' : '_clearModalContent',
                'hidden.bs.modal @ui.wcmModalLarge'  : '_clearLargeModalContent',
                'hidden.bs.modal @ui.wcmModalXLarge' : '_clearXLargeModalContent'
            },

            /**
             * If the wcmModalWindow exists in the ui hash, the
             * div tags with "modal-header" and "modal-body" classes
             * have any content removed.
             *
             * @private
             */
            _clearModalContent() {
                let header;
                let body;

                // To enable re-load remote data based up on href attributes
                // By default Bootstrap only load remote data once and it will not be
                // cleared for next link which have different URL in href attributes
                // 
                // Remote option in modal is deprecated in 3.3.X and will be removed in 4.x
                this.ui.wcmModalWindow.removeData('bs.modal');

                if (this.ui.wcmModalWindow.length) {
                    header = this.ui.wcmModalWindow.find('.modal-header');
                    body   = this.ui.wcmModalWindow.find('.modal-body');

                    if (header.length) {
                        header.html('');
                    }

                    if (body.length) {
                        body.html('');
                    }
                }
            },

            /**
             * If the wcmModalLarge exists in the ui hash, the
             * div tags with "modal-header" and "modal-body" classes
             * have any content removed.
             *
             * @private
             */
            _clearLargeModalContent() {
                let header;
                let body;

                // To enable re-load remote data based up on href attributes
                // By default Bootstrap only load remote data once and it will not be
                // cleared for next link which have different URL in href attributes
                // 
                // Remote option in modal is deprecated in 3.3.X and will be removed in 4.x
                this.ui.wcmModalLarge.removeData('bs.modal');

                if (this.ui.wcmModalLarge.length) {
                    header = this.ui.wcmModalLarge.find('.modal-header');
                    body   = this.ui.wcmModalLarge.find('.modal-body');

                    if (header.length) {
                        header.html('');
                    }

                    if (body.length) {
                        body.html('');
                    }
                }
            },

            /**
             * If the wcmModalXLarge exists in the ui hash, the
             * div tags with "modal-header" and "modal-body" classes
             * have any content removed.
             *
             * @private
             */
            _clearXLargeModalContent() {
                let header;
                let body;

                // To enable re-load remote data based up on href attributes
                // By default Bootstrap only load remote data once and it will not be
                // cleared for next link which have different URL in href attributes
                // 
                // Remote option in modal is deprecated in 3.3.X and will be removed in 4.x
                this.ui.wcmModalXLarge.removeData('bs.modal');

                if (this.ui.wcmModalXLarge.length) {
                    header = this.ui.wcmModalXLarge.find('.modal-header');
                    body   = this.ui.wcmModalXLarge.find('.modal-body');

                    if (header.length) {
                        header.html('');
                    }

                    if (body.length) {
                        body.html('');
                    }
                }
            },

            /**
             * Grabs the "href" attribute from the link clicked
             * and uses jQuery.load() to load the content into the
             * "modal-content" area. This is done since (when updating Bootstrap in OSO)
             * Bootstrap 4 will no longer support the "remote" option
             * for modal windows.
             *
             * @see {@link https://getbootstrap.com/javascript/#modals-options|Boostrap docs}
             *
             * @param {Object} e jQuery event object
             * @private
             */
            _loadWcmModal(e) {
                e.preventDefault();
                const modalContent = this.ui.wcmModalWindow.find('.modal-content');
                const wcmPath      = $(e.currentTarget).attr('href');

                if (modalContent.length === 1 && wcmPath) {
                    this.ui.wcmModalWindow.find('.modal-content').load(wcmPath);
                }
            }
        })
    },


    /**
     * Initialize the behaviors
     * @param options
     */
    initialize(options) {

        if (_.isEmpty(options)) {
            this.behaviors = this.defaultBehaviors;
        } else {
            this.behaviors = options;
        }

        // Store the default implementation of behaviorsLookup to
        // reset prior to this object being destroyed (useful for testing).
        this.originalBehaviorsLookup = Marionette.Behaviors.behaviorsLookup;

        const _this = this;
        Marionette.Behaviors.behaviorsLookup = function () {
            return _this.behaviors;
        };
    },


    /**
     * When destroying, reset to Marionette's default behaviors
     */
    onBeforeDestroy() {
        // restore the behaviorsLookup implementation
        Marionette.Behaviors.behaviorsLookup = this.originalBehaviorsLookup;
    }
});

module.exports = Behaviors;
