// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "    <h2 class=\"header\">Application Status</h2>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasUnderwriterAndFiles : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n    <!-- /.well -->\r\n    <div class=\"row application-status "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.showIcons : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n        <div class=\"col-lg-2\">\r\n            <div class=\"status-icon-container label-"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policyStatus : stack1)) != null ? stack1.displayProps : stack1)) != null ? stack1.labelSuffix : stack1), depth0))
    + "\">\r\n                <i class=\"fa fa-"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policyStatus : stack1)) != null ? stack1.displayProps : stack1)) != null ? stack1.type : stack1), depth0))
    + "\"></i>\r\n                <h3 class=\"text-capitalize\">\r\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policyStatus : stack1)) != null ? stack1.text : stack1), depth0))
    + "\r\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policyStatus : stack1)) != null ? stack1.policyStatusDate : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </h3>\r\n            </div>\r\n            <!-- /.status-icon-container -->\r\n        </div>\r\n        <div class=\"col-lg-10\">\r\n            <ul"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.product)) && stack1.underwritingRequired),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                <li"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.application : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                    <h4>Application</h4>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.application : stack1)) != null ? stack1.iconProps : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </li>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.product)) && stack1.underwritingRequired),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <li"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.readyToIssue : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                    <h4>Ready for Issue</h4>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.readyToIssue : stack1)) != null ? stack1.iconProps : stack1),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </li>\r\n                <li"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policySent : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                    <h4>Policy Sent</h4>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policySent : stack1)) != null ? stack1.iconProps : stack1),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </li>\r\n                <li"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.paid : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                    <h4>Paid</h4>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.paid : stack1)) != null ? stack1.iconProps : stack1),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </li>\r\n            </ul>\r\n        </div>\r\n        <!-- /.col-lg-10 -->\r\n    </div>\r\n    <!-- /.application-status .row -->\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"well well-sm\">\r\n            <ul class=\"list-horizontal\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.product)) && stack1.underwritingRequired),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.applicationDocHref : depth0),(depth0 != null ? depth0.illustrationDocHref : depth0),{"name":"compare","hash":{"operator":"||"},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\r\n        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.underWriter : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <li>\r\n                            <span class=\"gray-50\">Underwriter:</span> \r\n                            <span class=\"text-uppercase\">"
    + container.escapeExpression(((helper = (helper = helpers.underWriter || (depth0 != null ? depth0.underWriter : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"underWriter","hash":{},"data":data}) : helper)))
    + "</span>\r\n                        </li>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <li id=\"files-available\">\r\n                        <span class=\"gray-50\">Files Available:</span> \r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.applicationDocHref : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.illustrationDocHref : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </li>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                            <a id=\"eapp-document-link\" target=\"_blank\" rel=\"noopener noreferrer\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.applicationDocHref || (depth0 != null ? depth0.applicationDocHref : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"applicationDocHref","hash":{},"data":data}) : helper)))
    + "\">\r\n                                eApp</a>&nbsp;\r\n                            <i class=\"fa fa-file-pdf-o\"></i> &nbsp;\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                            <a id=\"illustration-document-link\"  target=\"_blank\" rel=\"noopener noreferrer\"  href=\""
    + container.escapeExpression(((helper = (helper = helpers.illustrationDocHref || (depth0 != null ? depth0.illustrationDocHref : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"illustrationDocHref","hash":{},"data":data}) : helper)))
    + "\">\r\n                                Illustration</a>&nbsp;\r\n                            <i class=\"fa fa-file-pdf-o\"></i>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "application-status-active";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<br>\r\n                       <span class=\"smaller-65\">"
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policyStatus : stack1)) != null ? stack1.policyStatusDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "</span>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return " class=\"four-col\"";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " class=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.application : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1), depth0))
    + "\"";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <i class=\"fa fa-"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.application : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.icon : stack1), depth0))
    + "\"></i>\r\n                        <div class=\"status-date\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.application : stack1)) != null ? stack1.displayAppReceivedDate : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </div>\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "                                <span>APPLICATION RECEIVED</span><br>"
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.received : depth0),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.signed : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.received : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.entered : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "<span>Signed:</span> "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.signed : depth0),{"name":"dateFormat","hash":{},"data":data}))
    + "<br>";
},"25":function(container,depth0,helpers,partials,data) {
    return "<span>Received:</span> "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.received : depth0),{"name":"dateFormat","hash":{},"data":data}))
    + "<br>";
},"27":function(container,depth0,helpers,partials,data) {
    return "<span>Entered:</span> "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.entered : depth0),{"name":"dateFormat","hash":{},"data":data}));
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <li id=\"uw-decision\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.uwDecision : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                        <h4>U/W Decision</h4>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.uwDecision : stack1)) != null ? stack1.iconProps : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n                        <div id=\"jump-links\" class=\"status-date "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.uwDecision : stack1)) != null ? stack1.noUWDetail : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.uwDecision : stack1)) != null ? stack1.displayText : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(42, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.application)) && stack1.acceleratedUnderwritingMessage),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.application)) && stack1.uwDecisionNotApplicable),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\r\n                    </li>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " class=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.uwDecision : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1), depth0))
    + "\"";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <i class=\"fa fa-"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.uwDecision : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.icon : stack1), depth0))
    + "\"></i>\r\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.uwDecision : stack1)) != null ? stack1.noUWDetail : stack1),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    return "                                <i class=\"fa fa-blank\"></i>\r\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "border-top-0";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <span>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.uwDecision : stack1)) != null ? stack1.displayText : stack1), depth0)) != null ? stack1 : "")
    + "</span>\r\n                                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.policyStatus)) && stack1.date),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<br>"
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.policyStatus)) && stack1.date),{"name":"dateFormat","hash":{},"data":data}))
    + "<br>";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.uwDecision : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    return "                                    <a href=\"#requirements\" title=\"See Requirements & U/W Decisions for more information\">See Decision</a><br>\r\n                                    "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.uwDecision : depth0),{"name":"dateFormat","hash":{},"data":data}))
    + "<br>\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <span id=\"auw-status\">Accelerated U/W</span>\r\n                                <br>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.application)) && stack1.acceleratedUnderwritingMessage), depth0))
    + "\r\n";
},"47":function(container,depth0,helpers,partials,data) {
    return "                            No Underwriting Required\r\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " class=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.readyToIssue : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1), depth0))
    + "\"";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <i class=\"fa fa-"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.readyToIssue : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.icon : stack1), depth0))
    + "\"></i>\r\n                        <div class=\"status-date\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.readyToIssue : stack1)) != null ? stack1.displayText : stack1),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(54, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </div>\r\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                                <span>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.readyToIssue : stack1)) != null ? stack1.displayText : stack1), depth0))
    + "</span>\r\n                                <br>"
    + alias1((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.policyStatus)) && stack1.date),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n";
},"54":function(container,depth0,helpers,partials,data) {
    return "                                "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.readyToIssue : depth0),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " class=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policySent : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1), depth0))
    + "\"";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <i class=\"fa fa-"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policySent : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.icon : stack1), depth0))
    + "\"></i>\r\n                        <div class=\"status-date\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policySent : stack1)) != null ? stack1.displayText : stack1),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.program(61, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </div>\r\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                                <span>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.policySent : stack1)) != null ? stack1.displayText : stack1), depth0))
    + "</span>\r\n                                <br>"
    + alias1((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.policyStatus)) && stack1.date),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.policySent : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"62":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.policySent : depth0),{"name":"dateFormat","hash":{},"data":data}));
},"64":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " class=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.paid : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.liClass : stack1), depth0))
    + "\"";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <i class=\"fa fa-"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.paid : stack1)) != null ? stack1.iconProps : stack1)) != null ? stack1.icon : stack1), depth0))
    + "\"></i>\r\n                        <div class=\"status-date\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.paid : stack1)) != null ? stack1.displayText : stack1),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.program(69, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </div>\r\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                                <span>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.statusIcons : depth0)) != null ? stack1.paid : stack1)) != null ? stack1.displayText : stack1), depth0))
    + "</span>\r\n                                <br>"
    + alias1((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.policyStatus)) && stack1.date),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.paid : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"70":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.paid : depth0),{"name":"dateFormat","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showApplicationStatus : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
