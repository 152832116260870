/* global Marionette,  Backbone:false */

import WcmToutView from '../../../pages/home/views/wcm-tout-v';
var menuItemViewTemplate = require('../templates/sidebar-menu-item.hbs');
var utils                = require('../../../utils/utils');
var config = require('../../../config/config');
require('../../../utils/hb-helpers');

var SidebarMenuItemView = Marionette.CompositeView.extend({
    template    : menuItemViewTemplate,

    initialize: function () {
        this.collection = this.model.childItems;
    },

    attachHtml: function (collectionView, childView, index) {
        collectionView.$('ul:first').append(childView.el);
    },

    onBeforeRender: function () {
        this.model.set('activeAndOpen', this.model.containsAnActiveItem());
    },

    onRender: function () {
        // remove the wrapping div to match design markup
        utils.unwrapView(this);
       // displaying a 'New' temp icon on main menu link
        this.displayTempIcon();
    },
     // This method will be used to display 'New' Icon on main menu link.
    displayTempIcon: function () {
        if (config.fflag.enableTempIcon) {
            let subItems = this.model.attributes.subItems;
            let hasTempIcon = false;
            Backbone.$.each(subItems, function (i, item) {
                if (item.tempNewIconWCMLink !== undefined) {
                    hasTempIcon = true;
                }
            });

            if((hasTempIcon || subItems === null) &&
            this.model.attributes.tempNewIconWCMLink !== undefined
    ) {
                let newIconView = new WcmToutView({
                    wcmPath: this.model.attributes.tempNewIconWCMLink.iconUrl,
                });
                if(this.$el.find('.tempNewIcon-container:first') !== undefined) {
                    newIconView
            .render()
            .$el.addClass(this.model.attributes.tempNewIconWCMLink.className);
                    this.$el
            .find('.tempNewIcon-container:first')
            .append(newIconView.render().el);
                }
            }
        }
    },
});

module.exports = SidebarMenuItemView;
