/* global _:false */
/**
 Parser helper functions for coverages
 */

const _isJointPolicy = function (data) {
    return (data.customerRoles && (data.customerRoles['Joint Insured'] ||
        data.customerRoles['Joint Annuitant']));
};

const _getPrimaryCustomerIdFrom = function (policyData) {
    if (policyData.customerRoles && policyData.customerRoles.Insured) {
        return policyData.customerRoles.Insured[0].customerId;
    } else if (policyData.customerRoles && policyData.customerRoles.Annuitant) {
        return policyData.customerRoles.Annuitant[0].customerId;
    }
};


const coverageParserFunctions = {

    /**
     * Sorts the coveragess so that primary insured is listed before joint insured
     * @param {object} policyData - the raw policy data object
     * @returns {object} just the coverages section, sorted
     */
    sortJointCoverages (policyData) {

        const compareByCustomerRoleType = function (a, b) {
            const A_BEFORE_B = -1;
            const A_AFTER_B = 1;
            const A_EQUALS_B = 0;

            // First, sort by the sequence numbers
            if (a.sequence < b.sequence) {
                return A_BEFORE_B;
            } else if (a.sequence > b.sequence) {
                return A_AFTER_B;
            }

            // Since the sequence numbers are the same and customerIds are the same, leave
            // the current order
            if (a.customerId === b.customerId) {
                return A_EQUALS_B;
            }

            // Since the sequence numbers are the same and we are comparing different customers,
            // so the primary insured/annuitant comes first
            if (a.customerId === _getPrimaryCustomerIdFrom(policyData)) {
                return A_BEFORE_B;
            }

            return A_AFTER_B;
        };

        // If there is no coverage data, return undefined
        if (typeof policyData === 'undefined' || typeof policyData.coverage === 'undefined') {
            return;
        }
        
        // deep-copy the coverage object so it doesn't get modified
        let result = JSON.parse(JSON.stringify(policyData.coverage));

        if(!_isJointPolicy(policyData)) {
            return result;
        }

        // Loop over each sub-section of the coverage array, sorting each section
        Object.keys(result).forEach(function (coverageSectionName) {
            if (Array.isArray(result[coverageSectionName])) {
                result[coverageSectionName].sort(compareByCustomerRoleType);
            }
        });

        return result;
    },

    /**
    * set visibility state for 'disclaimer note' based on requirement
    * This is determined by combinations of productTypeCategory,
    * acordHoldingStatus, statusView, and underwritingRequired.
    *
    * The following possible combinations would result in the flag being TRUE:
    *
    * ===============================================================================
    * | productTypeCategory | underwritingRequired | acordHoldingStatus  |statusView|  
    * ====================== ====================== ===================== ===========
    * |        Life         |         true         |      Proposed       |  *any* | 
    * -------------------------------------------------------------------------------
    * |        Life         |         true         |      Active         |  Pending | 
    * -------------------------------------------------------------------------------
    *
    * @param {object} policyData - the raw policy data object
    */

    setDisclaimerNoteState(policyData) {
        const productTypeCategory = 'Life';

        let currentProductTypeCategory = '';
        let underwritingRequired = '';
        let currentAcordHoldingStatus = '';
        let currentStatusView = '';

        if (policyData.product) {
            currentProductTypeCategory = policyData.product.productTypeCategory;
            underwritingRequired = policyData.product.underwritingRequired;
        }

        if (policyData.policyStatus) {
            currentAcordHoldingStatus = policyData.policyStatus.acordHoldingStatus;
            currentStatusView = policyData.policyStatus.statusView;
        }

        if (currentProductTypeCategory === productTypeCategory
            && underwritingRequired
            && (currentAcordHoldingStatus === 'Proposed'
                || (currentAcordHoldingStatus === 'Active' && currentStatusView === 'Pending'))
            && policyData.policyNumber.charAt(policyData.policyNumber.length - 1) !== 'C') {
            policyData.showDisclaimerNote = true;
        }

        return policyData;
    },

    /**
    * Set policy owner as Annuitant based current product
    * An annuity is determined by using a combination of the
    * productTypeCategory and the productTypeCode.
    *
    * Table of combinations:
    *
    * =================================================================
    * | productTypeCategory Value | productTypeCode Value | isAnnuity |
    * ============================ ======================= ============
    * |     Annuity               |    *any* !== 'WL'     |  true     |
    * -----------------------------------------------------------------
    * |       Life                |          SPFA         |  true     |
    * -----------------------------------------------------------------
    * |     Annuity               |           WL          |  false    |
    * -----------------------------------------------------------------
    *
    *
    * @param {object} policyData - the raw policy data object
    * @returns {boolean}
    */
    isAnnuity (policyData) {
        let currentProductTypeCategory;
        let currentProductTypeCode;

        if (policyData.product 
                && policyData.product.productTypeCategory 
                && policyData.product.productTypeCode) {

            currentProductTypeCategory = policyData.product.productTypeCategory
                                            .trim().toUpperCase();
            currentProductTypeCode     = policyData.product.productTypeCode.trim().toUpperCase();

            if ( (currentProductTypeCategory === 'ANNUITY' && currentProductTypeCode !== 'WL') 
                || (currentProductTypeCategory === 'LIFE' && currentProductTypeCode === 'SPFA') ) {
                
                return true;
            }
        }

        return false;
    },

    /**
     * Set the labels for related policies in the  "Coverage" section
     *
     * @param {object} policyData - the raw policy data object
     * @return {object} response
     */
    setRelatedPoliciesLabels (policyData) {

        let relatedPolicies = policyData.relatedPolicies;

        if (relatedPolicies) {

            /**
             * OSO2.7.140
             * 1) Replace "Modal" with "Single" if Billing Method = Single Premium.
             * 2) Replace "Modal" with <Billing Mode>, if Billing Method <> Single Premium
             *
             * Clarified that "Modal" should only appear if billingMode is undefined.
             */

            _.each(relatedPolicies, function(policy, index) {
                let policyLabel = 'Modal Premium';

                if (typeof policy.billingMethod === 'string') {

                    if (policy.billingMethod.trim().toLowerCase() === 'single premium') {
                        policyLabel = 'Single Premium';

                    } else {
                        if (typeof policy.billingMode === 'string') {
                            // Per Stacy, display billingMode without changing case
                            policyLabel = policy.billingMode + ' Premium';
                        }
                    }

                }

                // attach the policyLabel to the policy
                relatedPolicies[index].label = policyLabel;
            });
        }

        return policyData;
    },

    /**
     * set an 'isUlVul' flag at the root of the response. If the product.productTypeCode is UL or
     * VUL, set the flag to true, otherwise to false.
     * 
     * @param {object} policyData - the raw policy data object
     * @returns {*} the response, with the added isUlVul flag
     * @private
     */
    setIsUlVulFlag (policyData) {
        let ulVul   = ['UL', 'SPVUL', 'FPVUL', 'ISL'];
        let isUlVul = false;

        if (policyData.product && policyData.product.productTypeCode) {
            isUlVul = ulVul.indexOf(policyData.product.productTypeCode) > -1;
        }

        policyData.isUlVul = isUlVul;

        return policyData;
    },

    /**
     * Check whether coverage data (Base or Rider) has PUA amount 
     * @param {object} policyData - the raw policy data object
     * @returns {*} the response, with the added hasPUAAmount flag
     * @private
     */
    setHasPUAAmountFlag (policyData) {
        let hasPUAAmount = false;
        if(policyData.coverage) {
            
            // base
            if (policyData.coverage.base) {
                _.each(policyData.coverage.base, function (base) {
                    if(base.divPUA) {
                        hasPUAAmount = true;
                    }
                });
            }

            // riders
            if (!hasPUAAmount && policyData.coverage.riders) {
                _.each(policyData.coverage.riders, function (rider) {
                    if(rider.divPUA) {
                        hasPUAAmount = true;
                    }
                });
            }

            policyData.coverage.hasPUAAmount = hasPUAAmount;    
        }
        return policyData;
    }
    
};

module.exports = coverageParserFunctions;