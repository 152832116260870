/* global require, Marionette, $ */

/**
*  Performance center dial view Base
*/

var DialChart = require('../../dialChart/views/dial-chart-v');

var PerformanceCenterDialView = Marionette.ItemView.extend({
    ui: {
        dialChartPlacerOne     : '.dial-chart-placer:eq(0)',
        dialChartPlacerTwo     : '.dial-chart-placer:eq(1)',
        dialChartPlacerThree   : '.dial-chart-placer:eq(2)',
        dialChartPlacerFour    : '.dial-chart-placer:eq(3)',
        modalIconElements      : '[data-toggle="modal"]',
        tooltipElements        : '[data-rel="tooltip"]',
        lifeLivesHelpModal     : '#pc-life-lives-help-text-modal',
        aprModal               : '#apr-help-text-modal',
        leadersConferenceModal : '#leadersConference-help-text-modal',
        chairmansTripModal     : '#chairmansTrip-help-text-modal'
    },
    initialize: function () {

        //Flag to display check mark on dial
        this.checkMark = false;

        //set color for bar if Met flag is Y
        this.dialColorComplete = '#006347';

        //chart options. refer dialChartModule for options
        this.dialChartOptionsOne = {};
        this.buildDialChartOptions();
    },
    onRender: function () {

        //binding tooltip event to all tooltip elements
        this.ui.tooltipElements.tooltip({
            trigger:  'hover',  
            placement:'auto', 
            container: this.$el 
        });

        //finally render dial chart module and place it to place holder
        this.ui.dialChartPlacerOne.append(this.renderDialChart(this.dialChartOptionsOne));

        //if there is a second chart to render on same time
        //need to look for availability of `dialChartOptionsTwo`
        if (this.dialChartOptionsTwo) {
            this.ui.dialChartPlacerTwo.append(
                this.renderDialChart(this.dialChartOptionsTwo)
            );
        }

        if (this.dialChartOptionsThree) {
            this.ui.dialChartPlacerThree.append(
                this.renderDialChart(this.dialChartOptionsThree)
            );
        }

        if (this.dialChartOptionsFour) {
            this.ui.dialChartPlacerFour.append(
                this.renderDialChart(this.dialChartOptionsFour)
            );
        }
    },

    events: {
        'click @ui.modalIconElements': 'showModalHelp'
    },

    /**
    * Build chart options for dial chart
    */
    buildDialChartOptions: function () {

    },

    /**
     * Create a dial chart, render it
     * @param {object} chartOptions
     * @returns {*} jQuery object
     */
    renderDialChart : function renderDialChart(chartOptions) {
        var dialChart = new DialChart(chartOptions);
        dialChart.render();
        return dialChart.$el;
    },

    /**
     * Display the modal window in the parent span
     * element's data-target attribute.
     *
     * @param {object} e - Event object
     */
    showModalHelp : function (e) {
        var modalWindow = $(e.target).closest('button[data-target]').data('target');
        this.ui[modalWindow].modal('show');
    }
});

module.exports = PerformanceCenterDialView;