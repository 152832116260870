/* global Marionette, _:false*/
/**
 A model parser class which parses the data for Policy relationships data

 */

var config                          = require('../../../../config/config');
var debugModule                     = require('../../../../modules/debug/debugModule')
    .init(config.debugEnabled);

var policyRelationships = Marionette.Object.extend({

    /**
     * Grouping 'customer roles' object based on Relationship Categories
     *
     * Expected output:
     *
     *     'policyRelationshipCategories' : {
     *         primaries     : {
     *             ssnColumnLabel: 'SSN',
     *             data :[{
     *                 relationship: 'Annuitant'
     *                 customerId: 232
     *             }]
     *         },
     *         beneficiaries : {
     *             ssnColumnLabel: 'Tax ID',
     *             data :[{
     *                 relationship: 'Beneficiary'
     *                 customerId: 343
     *             }]
     *         },
     *         others        : {
     *             ssnColumnLabel: 'SSN / Tax ID',
     *             data :[{
     *                 relationship: 'Assignee'
     *                 customerId: 253
     *             }]
     *         }
     *      }
     *
     * Return data for 'owner' category will be sorted based 'roleLabels'.
     *
     * @param {object} response The data recieved from service
     */
    _setCustomerPolicyRelationshipData: function (response) {

        var _this = this;

        // Roles to filter by relationship category. The arrays represent the items in
        // "customerRoles" and the order in which to sort.
        var roles = {
            primaryRoles : [
                'Annuitant',
                'Insured',
                'Primary Insured',
                'Joint Insured',
                'Joint Annuitant',
                'Eligible Person',
                'Other Insured'
            ],
            beneficiaryRoles : [
                'Beneficiary',
                'Primary Beneficiary',
                'Secondary Beneficiary'
            ],
            otherRoles : [
                'Assignee',
                'Conservator',
                'Dependent',
                'Employer',
                'Guardian',
                'Owner',
                'Payor',
                'Spouse',
                'Third Party'
            ]
        };

        //Customer Policy Relationship category
        var cprCategories = {
            primaries     : {
                ssnColumnLabel : '',
                data: []
            },
            beneficiaries : {
                ssnColumnLabel : '',
                data: []
            },
            others        : {
                ssnColumnLabel : '',
                data: []
            }
        };

        var currentCategory;
        var roleLabel;
        var roleData;

        //flag to display Date of birth and Gender
        var showDoBnGender = false;

        //iterate each roles and get customerId stored with each roles
        _.each(response.customerRoles, function (customerIds, role) {

            if (role) {

               //primary
                if (roles.primaryRoles.indexOf(role) !== -1) {
                    currentCategory = 'primaries';
                }

                //beneficiary
                if (roles.beneficiaryRoles.indexOf(role) !== -1) {
                    currentCategory = 'beneficiaries';
                }

                //other
                if (roles.otherRoles.indexOf(role) !== -1) {
                    currentCategory = 'others';
                }

                if (currentCategory) {
                    roleLabel = role;
                    roleData = cprCategories[currentCategory].data;

                    // Iterate each person ID and store as separate objects
                    // to each data array
                    if (roleLabel && roleData) {
                        _.each(customerIds, function (id) {

                            // Date of Birth and gender should only displayed if category
                            // is 'primaries'
                            // role should not be 'Eligible Person'
                            if (currentCategory === 'primaries'
                                    && roleLabel !== 'Eligible Person') {
                                showDoBnGender = true;
                            }

                            roleData.push({
                                relationship          : roleLabel,
                                customerId            : id.customerId,
                                customerRoleContactId : id.customerRoleContactId,
                                showDoBnGender        : showDoBnGender,
                                showDoBnGenderColumns : (currentCategory === 'primaries')

                            });
                        });
                    }

                } else {
                    debugModule.warn('Unknown role "' + role +
                        '" in policy-detail-m._setCustomerPolicyRelationshipData.');
                }

                currentCategory = null;
                roleLabel       = null;
                roleData        = null;
                showDoBnGender  = false;
            }
        });

        //Sort customer policy relationship Category and set SSN column label
        _.each(cprCategories, function (category, role) {

            //if owner then keep the based on roles.owner
            if (role === 'primaries') {
                cprCategories[role].data = _.sortBy(category.data, function(obj){
                    return _.indexOf(roles.primaryRoles, obj.relationship);
                });

            // Beneficiary and Other table's
            // alpha group sorting against relationship and fullName
            // in ascending order
            } else {
              
                category.data = _.chain(category.data)

                    // add fullName for sorting
                    .each(function(cprRole) {
                        _.extend(cprRole, _.pick(
                                response.customers[cprRole.customerId], 
                                'fullName'
                            ));
                    })

                    // sort by relationship and fullName
                    .sortBy(function(customer) {
                        return [customer.relationship, customer.fullName];
                    })

                    // remove fullName field now that the data has been sorted
                    .map(function(customer) {
                        return _.omit(customer, 'fullName');
                    })

                    .value();
            }

            //Set SSN column label for relationship column
            //By default it will be SSN
            cprCategories[role].ssnColumnLabel = 
                _this._setSSNColumnLabelName(category.data, response);

        });

        response.policyRelationshipCategories = cprCategories;

        return response;
    },

    /**
     * Set column name for SSN
     * @param {array} dataArray the array created for each category of
     *                          policy relationship
     *
     * @param {object} response  service response object
     *
     * @return {string}          it might be 'SSN' / 'SSN / Tax ID' / 'Tax ID'
     */
    _setSSNColumnLabelName : function (dataArray, response) {
        var label = null;
        var i = 0;
        var taxId;
        var taxIdType;

        do {
            if (_.isEmpty(dataArray[i])) {
                return false;
            }

            if (response.customers && response.customers[dataArray[i].customerId]) {
                taxIdType = response.customers[dataArray[i].customerId].taxIdType;
                taxId = response.customers[dataArray[i].customerId].taxId;
            }
            
            if (taxIdType === 'SSN' || taxIdType === 'EIN' && (taxId && taxId !== 'NONE')) {
                if (!label) {
                    label = taxIdType;
                } else if (label !== taxIdType) {
                    label = 'SSN / Tax ID';
                    break;
                }
            }

            i += 1;
        } while(i < dataArray.length);

        // If it says EIN, change it to Tax ID
        if (label === 'EIN') {
            label = 'Tax ID';
        }

        return label ? label : 'SSN';
    }
    
});

module.exports = policyRelationships;