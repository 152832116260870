// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <tr>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.insured : depth0)) != null ? stack1.tobaccoUse : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                    Tobacco\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                    Non-Tobacco\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    </tr>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.noRiskData : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <td>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.insured : depth0)) != null ? stack1.fullName : stack1), depth0))
    + "</td>\r\n        <td>\r\n            "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.insured : depth0)) != null ? stack1.underwritingClass : stack1), depth0))
    + "\r\n"
    + ((stack1 = (helpers.has || (depth0 && depth0.has) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.insured : depth0),"tobaccoUse",{"name":"has","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </td>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.noRiskData : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
