/* global Backbone:false, Marionette:false, _:false */
/**
 * View to handle the WCM content. 
 * To render this view below options are mandatory
 *
 * @param {string} options.el - jquery element selector to place the HTML content
 * @param {string} options.wcmPath - path to load the HTML content
 *
 * wcmPath will be passed to wcmModel object to fetch html content.
 * Once html content got fetched, view will start rendering.
 * 
 * Created by jbell on 1/20/16.
 */
import config from '../../../config/config';
import errorHelper from '../../../utils/error-helper';
import wcmTemplate from '../templates/wcm-content-t.hbs';
import WcmModel from '../models/wcm-content-m';
import utils from '../../../utils/utils';

const analyticsChannel = Backbone.Radio.channel('analytics');
const spinnerChannel   = Backbone.Radio.channel('spinner');

// load the global partials.
import '../../../partials';

const WcmView = Marionette.ItemView.extend({

    template : wcmTemplate,

    errors : {
        wcmPathMissing      : 'WcmView.initialize() requires wcmPath to show the content',
        genericErrorMessage : 'An error occurred while processing this request.'
    },

    ui : {
        'jumpLinksContainer'              : '.jumplinks-traveling',
        'jumpLinksDropdownMenu'           : '.jumplinks-traveling .btn-group',
        'jumpLinks'                       : '.jumplinks-traveling ul a',
        'responsiveInstructionContainers' : '.table-responsive-instruction',

        //table collapse
        'tableCollapseButtonWrapper'     : '.collapse-shade-button-wrapper'
    },

    events : {
        'click @ui.jumpLinks' : 'bindScrollToWithJumpLinks'
    },

    behaviors : {
        floatTableHeaders : {},
        jumpLinks         : {},
        smartScrollTables : {},
        wcmContentLinks   : {},
        wcmContentGrid    : {},
        wcmModalWindow    : {}
    },

    initialize(options) {

        let wcmPath;
        let matchWcmPath;

        // Setting utils and ajaxUtils function to local scope for unit testing
        this.utils     = require('../../../utils/utils');
        this.ajaxUtils = require('../../../utils/ajax-utils');
       
        if (options && typeof options === 'object') {
            // check to see if wcmLink is set first
            if (options.stateObj && typeof options.stateObj.wcmLink === 'string') {
                wcmPath = options.stateObj.wcmLink;
            } else if (typeof options.wcmPath === 'string') {
                wcmPath = options.wcmPath;
            }
        }

        // if wcmPath has not been set, check in the location.hash
        if (_.isUndefined(wcmPath) && document.location.hash) {

            //get WCM path from location.hash if it exactly match (start #c/)
            matchWcmPath = document.location.hash.match(/^#c\/(.*)$/);
            if (matchWcmPath && matchWcmPath.length) {
                wcmPath = matchWcmPath[1];
            }
        }

        if (!wcmPath) {
            throw new Error(this.errors.wcmPathMissing);
        }

        this.model = new WcmModel({
            wcmPath: wcmPath
        });

        this.listenTo(this.model, 'error', this._handleWcmError);
        this.listenTo(this.model,'change', this.render);
    },

    onBeforeShow() {
        
        //show wait indicator
        spinnerChannel.trigger('show', {
            viewScope : this,
            position  : 'fixed'
        });

        this.model.fetch();
    },

    onRender() {
        const _this = this;
        spinnerChannel.trigger('hide', this);
                
        utils.convertWcmLinks(this);

        // Hide all .table-responsive-instruction in WCM content by default. The WCM authors
        // have added these for tables without providing the associated table with a
        // "table-responsive" wrapper. Without this wrapper, scroll bars won't appear
        // for the table. If a table does, in fact, have scroll bars, the
        // smartScrollTables behavior will show the message when/if needed.
        this.ui.responsiveInstructionContainers.addClass('hidden');

        if (this.ui.jumpLinksContainer instanceof Backbone.$ 
                && this.ui.jumpLinksContainer.length) {

            // Adding anchor element just before of jumplink menu container and set an UI element
            this.ui.jumpLinksContainer.before('<a id="anchor-jumplink-menu" ></a>');
            this.ui.anchorJumpLinksMenu = Backbone.$('#anchor-jumplink-menu');

            // Duplicating jumplinksContainer to keep visibility of jump links
            // while existing jumplink menu transformed into affix menu, which avoid content jumps
            const jumpLinksContainerCopy = this.ui.jumpLinksContainer.clone();
            jumpLinksContainerCopy.removeClass('jumplinks-traveling')
                .addClass('affix-top jumplinks-traveling-copy')
                .prop('id', 'jumplinka-traveling-copy')
                .hide();
            this.ui.anchorJumpLinksMenu.after(jumpLinksContainerCopy);
            this.ui.jumpLinksContainerCopy = Backbone.$('.jumplinks-traveling-copy');
            
            this.ui.jumpLinksDropdownMenu.on('shown.bs.dropdown', function () {
                _this.ui.jumpLinksContainer.addClass('open');
            });

            this.ui.jumpLinksDropdownMenu.on('hidden.bs.dropdown', function () {
                _this.ui.jumpLinksContainer.removeClass('open');
            });
        }

        // Configure collapse feature to all table element which has collapse class
        this._handleTableCollapse();
    },

    onDestroy() {

        //unbind resize.app.jumpLinks event while destroying this view
        Backbone.$(window).off('resize.app.jumpLinks');
    },

    /**
     * Method to configure Table collapse feature
     * @private
     */
    _handleTableCollapse() {

        const tableCollapseButtonWrappers = this.ui.tableCollapseButtonWrapper;

        let tableCollapseID;
        let tableCollapseWrapperElements = {};
        let tableCollapseWrapperEvents   = {};

        // set default height (collapased) for WCM collapse tables based on screen width
        this.tableCollapseHeight = ((window.innerWidth < 768) ? 100 : 150)+'px';

        if (tableCollapseButtonWrappers instanceof Backbone.$ 
                && tableCollapseButtonWrappers.length) {
            tableCollapseButtonWrappers.each((idx, element) => {
                
                tableCollapseID = 'collapseTable'+idx;

                // Add ID attributes to each collapse table wrapper
                Backbone.$(element).next('.collapse')
                    .attr('id', tableCollapseID)
                    .css({
                        display     : 'block',
                        overflow    : 'hidden',
                        height      : this.tableCollapseHeight
                    });

                // Add HREF attributes to related button to handle the toggle feature
                Backbone.$(element).find('a[data-toggle="collapse"]')
                    .attr({
                        'href'          :'#'+tableCollapseID,
                        'aria-controls' : tableCollapseID
                    });

                tableCollapseWrapperElements[tableCollapseID] = Backbone.$('#'+tableCollapseID);

            });

            // Extent UI objects with collapsible table elements
            this.ui = _.extend(this.ui, tableCollapseWrapperElements);

            // Extent events object
            _.each(tableCollapseWrapperElements,(object, key) => {
                tableCollapseWrapperEvents['hide.bs.collapse #'+key]   = '_handleTableCollapseHide';

                tableCollapseWrapperEvents['hidden.bs.collapse #'+key] 
                    = '_handleTableCollapseHidden';

                tableCollapseWrapperEvents['show.bs.collapse #'+key]   = '_handleTableCollapseShow';

                tableCollapseWrapperEvents['shown.bs.collapse #'+key]  
                    = '_handleTableCollapseShown';
            });

            this.events = _.extend(this.events, tableCollapseWrapperEvents);
            this.delegateEvents();
        }
    },

    _handleTableCollapseHide(event) {
        const collapseTableWrapperId =  Backbone.$(event.currentTarget).attr('id');
        this.ui[collapseTableWrapperId].css({
            overflow: 'hidden'
        });
        this.ui[collapseTableWrapperId].prev().find('span').text('Expand');
    },

    _handleTableCollapseHidden(event) {
        const _this = this;
        const collapseTableWrapperId =  Backbone.$(event.currentTarget).attr('id');
        this.ui[collapseTableWrapperId].animate({
            height: this.tableCollapseHeight
        }, function() {
            _this._scrollToCollapsePanelHeader(collapseTableWrapperId);
        });
    },

    _handleTableCollapseShow(event) {
        const collapseTableWrapperId =  Backbone.$(event.currentTarget).attr('id');
        this.ui[collapseTableWrapperId].prev().find('span').text('Collapse');
    },

    _handleTableCollapseShown(event) {
        
        const collapseTableWrapperId    = Backbone.$(event.currentTarget).attr('id');
        
        // Change overflow property to visible state, otherwise floating contents
        // such as tooltip will trimmed off.
        this.ui[collapseTableWrapperId].css({
            overflow: 'inherit'
        });

        this._scrollToCollapsePanelHeader(collapseTableWrapperId);
    },

    /**
     * Method to scroll page to collapse table wrapper while expanding and collapsing
     * 
     * @param {string} collapseTableWrapperId A valid ID of collapse table wrapper
     */
    _scrollToCollapsePanelHeader(collapseTableWrapperId) {
        const currentScrollPosition     = Backbone.$(window).scrollTop();
        const panelOffsetTop            = this.ui[collapseTableWrapperId].offset().top;

        // As per the standard Table heading will be H3, but considering H2 as optional
        const tableHeaderElement        = this.ui[collapseTableWrapperId].parent().prev('h3, h2');
        let tableHeaderElementHeight    = 0;
        
        if (tableHeaderElement && tableHeaderElement.length) {
            // add default header margin 30px 
            tableHeaderElementHeight = tableHeaderElement.height() + 30;
        }

        // scroll up to starting position of table 
        if (currentScrollPosition > panelOffsetTop) {
            Backbone.$('html, body').animate({
                scrollTop: panelOffsetTop - tableHeaderElementHeight
            }, 500);
        }
    },

    /**
     * Bind scrollTo method with each jump links  
     * @param {object} event - jquery event object
     */
    bindScrollToWithJumpLinks(event) {
        event.preventDefault();

        // For better positioning we need to send additional params to 
        // scrollTo method to subtract height (40) of the Affix button from actual offset.top
        // Otherwise button will overlap with Header.
        this.utils.scrollTo(Backbone.$(event.currentTarget.hash), 40);
    },

    /**
    * Bind affix plugin to JumpLink container based on window size
    */
    _affixJumpLinksContainer() {

        if (this.ui.jumpLinksContainer instanceof Backbone.$
                && this.ui.jumpLinksContainer.length) {
            const _this = this;
            const jumpLinksContainerHeight = this.ui.jumpLinksContainer.height();
            
            // Remove affix data if that exist, so re-bind work correctly 
            // while resizing window
            Backbone.$(window).off('.affix');
            this.ui.jumpLinksContainer.removeData('bs.affix')
                .removeClass('affix affix-top');

            _this.ui.jumpLinksContainer.affix({
                offset: {

                    // set top offset to start showing jumplink menu
                    // currently it will be displayed when scroll reach near by top offset of
                    // anchor element added before jumpmenu
                    top: _this.ui.anchorJumpLinksMenu.offset().top + jumpLinksContainerHeight 
                }
            });

            _this.ui.jumpLinksContainer.off('affixed.bs.affix')
                .on('affixed.bs.affix', function () {
                    _this.ui.jumpLinksContainerCopy.show();
                });
            
            _this.ui.jumpLinksContainer.off('affixed-top.bs.affix')
                .on('affixed-top.bs.affix', function () {
                    _this.ui.jumpLinksContainerCopy.hide();
                });
        }
    },

    onDomRefresh() {

        if (this.ui.jumpLinksContainer instanceof Backbone.$ 
                && this.ui.jumpLinksContainer.length) {

            // Set 'resize' event listener with callback method to re-initialize affix plugin   
            // 
            // Its suppose to do at initialization of view but getting failed in unit testing
            // as it is not passing current reference to `this` context 
            Backbone.$(window).on('resize.app.jumpLinks',
                _.bind(this._affixJumpLinksContainer, this));

            // Initial call to bind affix plugin with jump links container
            Backbone.$(window).trigger('resize.app.jumpLinks');

            // track 'top' offset change of jumplink anchor element and re-position affix element
            this.elPositionChangeListener = this.ui.anchorJumpLinksMenu
                .onPositionChanged(_.bind(this._affixJumpLinksContainer, this), 100);

            this.currentPageOffsetTop = Backbone.$(window).scrollTop();
            
            // Adding scroll and touchmove(only for mobile) to re-initialize JumpLink affix menu
            // Its to re-initialize affix plugin with new top offset property 
            // only if there any change to position of jumplink anchor element for first time.
            Backbone.$(window).on(
                'scroll.wcmContent, touchmove.wcmContent', 
                _.debounce(_.bind(this._unbindPageScrollListener, this),100)
            );
        }
    },

    /**
     * Unbind page scroll/touchmove event bound with window
     */
    _unbindPageScrollListener() {

        // re-initialize JumpLink menu while scrolling for once
        Backbone.$(window).trigger('resize.app.jumpLinks');

        // Ignore initial scroll which will be auto triggered by browser
        // while refresh
        if (this.currentPageOffsetTop !== Backbone.$(window).scrollTop()) {
            
            //unbind scroll.wcmContent event too
            Backbone.$(window).off('scroll.wcmContent, touchmove.wcmContent');
        } 
    },

    /**
     * Logs an error message to analytics providers, hides
     * the spinner, and displays a message to the user.
     * @param {object} model
     * @param {object} response
     * @private
     */
    _handleWcmError(model, response) {
        let logMessage;
        let userMessage;

        if (response && response.status) {

            // Keep the error messages consistent with the global error handling
            if (response.status === 404) {
                userMessage = config.errorMessages.noPageFound;
            } else {
                userMessage = this.ajaxUtils.getAjaxMessages(response, null).userMessage ||
                    this.errors.genericErrorMessage;
            }

            logMessage = 'Error retrieving WCM content from ' + this.model.get('wcmPath')
                + '. Received a ' + response.status + ' status code.';

            // Stop the spinner
            spinnerChannel.trigger('hide', this);

            analyticsChannel.trigger('trackException', {
                fatal   : false,
                message : logMessage
            });

            // Display an error message to the user with an info icon for 403
            // and 404 errors and a warning icon for everything else
            if (response.status === 403 || response.status === 404) {
                this.model.set(
                    'alertMessage',
                    errorHelper.createAlert(userMessage, 'info')
                );
                this.render();

            } else {
                this.model.set(
                    'alertMessage',
                    errorHelper.createAlert(userMessage, 'warning')
                );
                this.render();
            }
        }
    }
});

module.exports = WcmView;