/* global Backbone: false */

/**
 * A singletone module to store local data
 *
 * https://github.com/gr2m/localstorage-memory
 */
var cache  = {};
var LocalStorageMemory = Backbone.Marionette.Object.extend({ 
    
    initialize: function() {
        this.length = 0;
    },

    /**
    * returns item for passed key, or null
    *
    * @para {String} key
    *       name of item to be returned
    * @returns {String|null}
    */
    getItem : function(key) {
        return cache[key] || null;
    },

    /**
    * sets item for key to passed value, as String
    *
    * @param {String} key
    *       name of item to be set
    * @param {String} value
    *       value, will always be turned into a String
    * @returns {undefined}
    */
    setItem : function(key, value) {
        if (typeof value === 'undefined') {
            this.removeItem(key);
        } else {
            if (!(cache.hasOwnProperty(key))) {
                this.length++;
            }

            cache[key] = '' + value;
        }
    },

    /**
    * removes item for passed key
    *
    * @param {String} key
    *       name of item to be removed
    * @returns {undefined}
    */
    removeItem : function(key) {
        if (cache.hasOwnProperty(key)) {
            delete cache[key];
            this.length--;
        }
    },

    /**
    * returns name of key at passed index
    *
    * @param {Number} index
    *       Position for key to be returned (starts at 0)
    * @returns {String|null}
    */
    key : function(index) {
        return Object.keys(cache)[index] || null;
    },

    /**
    * removes all stored items and sets length to 0
    *
    * @returns {undefined}
    */
    clear : function() {
        cache = {};
        this.length = 0;
    }

});

module.exports = new LocalStorageMemory();