// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<h2 class=\"well\">Investment Account Balance as of "
    + alias3((helpers.dateFormat || (depth0 && depth0.dateFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.investment : depth0)) != null ? stack1.valuesAsOfDate : stack1),"MMM D, YYYY",{"name":"dateFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</h2>\r\n<div class=\"row\">\r\n    <div class=\"col-sm-4 col-xs-12\">\r\n        <div class=\"current-elections-class-header-responsive asset-class-header\">\r\n            <h4 class=\"well\">My Balance By Asset Class</h4>\r\n        </div>\r\n        <div id=\"chart_container\"></div>\r\n    </div>\r\n    <div id=\"allocation-active-info\" class=\"table-responsive col-xs-8\">\r\n        <p><small>View more information about the investment options by expanding the asset class and selecting the investment name.</small></p>\r\n        <table class=\"table table-bordered table-hover table-even-odd-stripe\">\r\n            <thead>\r\n                <tr>\r\n                    <th class=\"col-xs-1\">Key</th>\r\n                    <th colspan=\"3\" class=\"col-xs-9\">\r\n                        <button class=\"btn toggleall\" style=\"padding: 0px;\" aria-label=\"Toggle Up\">\r\n                            <i class=\"fa fa-solid fa-chevron-down\"></i>\r\n                        </button>\r\n                        Asset Class\r\n                    </th>\r\n                    <th class=\"text-right col-xs-1\">%</th>\r\n                    <th class=\"text-right col-xs-1\">$</th>\r\n                </tr>\r\n            </thead>\r\n            <tbody>\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.investment : depth0)) != null ? stack1.assetCollection : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "            </tbody>\r\n            <tfoot class=\"final-row\">\r\n                <td colspan=\"5\">Total Account Value</td>\r\n                <td colspan=\"1\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.investment : depth0)) != null ? stack1.totalAccountValue : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\r\n            </tfoot>\r\n        </table>\r\n    </div>\r\n</div>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, alias5="function";

  return "                <tr class=\""
    + ((stack1 = (helpers.if_even || (depth0 && depth0.if_even) || alias2).call(alias1,(data && data.index),{"name":"if_even","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\">\r\n                    <td class=\"text-center\">\r\n                        <i class=\"fa-stack\" style=\"background-color: "
    + alias4(alias3(((stack1 = blockParams[0][0]) != null ? stack1.color : stack1), depth0))
    + ";\"></i>\r\n                    </td>\r\n\r\n                    <td colspan=\"3\">\r\n                        <a role=\"button\" data-toggle=\"collapse\" aria-expanded=\"false\"\r\n                            aria-controls=\"asset"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "_class\" id=\"asset"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "_class\"\r\n                            href=\"#asset"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "_classRow\" class=\"asset-class-button\">\r\n                            <span>"
    + alias4(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "</span>\r\n                            <i class=\"fa fa-angle-down icon-only margin-right-5\"></i>\r\n                        </a>\r\n                    </td>\r\n                    <td class=\"text-right\">"
    + alias4(alias3(((stack1 = blockParams[0][0]) != null ? stack1.totalPercent : stack1), depth0))
    + "%</td>\r\n                    <td class=\"text-right\">"
    + alias4((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.totalValue : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\r\n                </tr>\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.coll : stack1),{"name":"isNotEmpty","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "even";
},"5":function(container,depth0,helpers,partials,data) {
    return "odd";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <tr style=\"display: none;\" class=\"asset"
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "_class\">\r\n                    <td class=\"table-header-background\"></td>\r\n                    <td class=\"table-header-background\">\r\n                        Investment Name\r\n                    </td>\r\n                    <td class=\"table-header-background text-right\">Units</td>\r\n                    <td class=\"table-header-background text-right\">Unit Value</td>\r\n                    <td class=\"table-header-background\"></td>\r\n                    <td class=\"table-header-background\"></td>\r\n                </tr>\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = blockParams[1][0]) != null ? stack1.coll : stack1),{"name":"each","hash":{},"fn":container.program(8, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <tr style=\"display: none;\" class=\"asset"
    + alias2(alias1((container.data(data, 1) && container.data(data, 1).index), depth0))
    + "_class\">\r\n                    <td></td>\r\n                    <td>\r\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = blockParams[0][0]) != null ? stack1.showLink : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                    </td>\r\n                    <td class=\"text-right\">"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.currentNumberUnits : stack1), depth0))
    + "</td>\r\n                    <td class=\"text-right\">"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.unitValue : stack1), depth0))
    + "</td>\r\n                    <td class=\"text-right\">"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.allocationPercent : stack1), depth0))
    + "%</td>\r\n                    <td class=\"text-right\">"
    + alias2((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(alias3,((stack1 = blockParams[0][0]) != null ? stack1.allocationAmount : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\r\n                </tr>\r\n";
},"9":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda;

  return "                        <a \r\n                        target=\"_blank\"\r\n                        rel=\"norgerrer noopener\"\r\n                        style=\"text-decoration: underline;\"\r\n                        href=\"/wps/wcm/connect/ind/OnlineServices/Investments/Portfolio+Descriptions/"
    + container.escapeExpression(alias1(((stack1 = blockParams[1][0]) != null ? stack1.productUrl : stack1), depth0))
    + "\">\r\n                        "
    + ((stack1 = alias1(((stack1 = blockParams[1][0]) != null ? stack1.productFullName : stack1), depth0)) != null ? stack1 : "")
    + "\r\n                        </a>\r\n";
},"11":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "                        "
    + ((stack1 = container.lambda(((stack1 = blockParams[1][0]) != null ? stack1.productFullName : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " <strong>Section Unavailable</strong>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.investment : depth0),{"name":"ifAvailable","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "<!-- end of investment Allocation -->";
},"useData":true,"useDepths":true,"useBlockParams":true});
