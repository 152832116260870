var Handlebars = require('hbsfy/runtime');

Handlebars.registerPartial(
    'pc-ct-rules-link',
    require('./pc-ct-rules-link.hbs')
);

Handlebars.registerPartial(
    'life-lives-ticks',
    require('./pc-life-lives-ticks.hbs')
);

Handlebars.registerPartial(
    'pc-apr-help-text-modal',
    require('./pc-apr-help-text-modal.hbs')
);

Handlebars.registerPartial(
    'pc-headers',
    require('./pc-headers.hbs')
);

Handlebars.registerPartial(
    'pc-lc-ct-help-text-modal',
    require('./pc-lc-ct-help-text-modal.hbs')
);

Handlebars.registerPartial(
    'pc-life-lives-bonus-help-text-modal',
    require('./pc-life-lives-bonus-help-text-modal.hbs')
);

Handlebars.registerPartial(
    'bullets-life-lives',
    require('./pc-bullets-life-lives.hbs')
);

//annual production requirement (APR)
Handlebars.registerPartial(
    'bullets-apr',
    require('./pc-bullets-apr.hbs')
);

// Chairmans Trip
Handlebars.registerPartial(
    'pc-bullets-qualified-ct',
    require('./pc-bullets-qualified-ct.hbs')
);

// Leaders Conference
Handlebars.registerPartial(
    'pc-bullets-qualified-lc',
    require('./pc-bullets-qualified-lc.hbs')
);

Handlebars.registerPartial(
    'pc-bullets-total',
    require('./pc-bullets-total.hbs')
);

// Chairmans Trip
Handlebars.registerPartial(
    'pc-bullets-on-schedule-ct',
    require('./pc-bullets-on-schedule-ct.hbs')
);

// Leaders Conference
Handlebars.registerPartial(
    'pc-bullets-on-schedule-lc',
    require('./pc-bullets-on-schedule-lc.hbs')
);

Handlebars.registerPartial(
    'pc-toggle-buttons-qualified-on-schedule',
    require('./pc-toggle-buttons-qualified-on-schedule.hbs')
);

Handlebars.registerPartial(
    'pc-apr-status-bullet',
    require('./pc-apr-status-bullet.hbs')
);

Handlebars.registerPartial(
    'pc-leaders-apr-status-bullet',
    require('./pc-leaders-apr-status-bullet.hbs')
);



module.exports = Handlebars;
