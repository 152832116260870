/* global require, Backbone, Marionette */

var rootTemplate = require('../templates/root-t.hbs');

var analyticsChannel = Backbone.Radio.channel('analytics');

var RootLayout = Marionette.LayoutView.extend({
    el: '.oso-app-wrapper',

    template: rootTemplate,

    regions: {
        topBarRegion    : '#topbar',
        contentRegion   : '#content',
        footerRegion    : '#footer',
    },
    events: {

        // Capture ALL clicks/touches of <a> / <button> / <input type='submit' /> tags
        // 'mousedown' works for touchstart as well.
        'mousedown a' : 'logAction',
        'mousedown button, input[type=submit], input[type=button]' : 'logAction',
        'submit form' : 'logAction'
    },

    /**
     * Log all clicks for site metrics
     * @param event
     */
    logAction: function(event) {
        analyticsChannel.trigger('trackAction', {
            event : event
        });
    }
});

module.exports = RootLayout;
