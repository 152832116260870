var Handlebars = require('hbsfy/runtime');

Handlebars.registerPartial(
    'message-alert',
    require('./message-alert.hbs')
);

Handlebars.registerPartial(
    'table-responsive-instruction',
    require('./table-responsive-instruction.hbs')
);
