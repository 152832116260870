var Handlebars = require('hbsfy/runtime');


Handlebars.registerHelper({

    // Needed check to know if this item is for 'Forms', where we use Glyphicons
    glyphiconCheck: function(link, options) {
        if(link && typeof link === 'string' && link.toLowerCase() === 'forms') {
            return true;
        }
        return false;
    }

});

module.exports = Handlebars;
