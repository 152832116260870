/* global Backbone:false, _:false */

// load the global partials.
require('../../../partials');
require('../../../utils/hb-helpers');

var debugModule                     = require('../../../modules/debug/debugModule').init();
var PolicyListDataTableViewModel    = require('../viewModels/policy-list-datatable-vm');
var template                        = require('../templates/policy-list-datatable-info-t.hbs');
var utils                           = require('../../../utils/utils');
var spinnerChannel                  = Backbone.Radio.channel('spinner');

var PolicyListDataTableView = Backbone.Marionette.ItemView.extend({

    template : template,

    initialize: function (options) {

        var col;
        var dir;
        var instructionTextId;
        var length;
        var start;
        var status;

        if (options) {
            this.hierarchy       = options.hierarchy;
            this.producerId      = options.producerId;
            this.producerOrgId   = options.producerOrgId;
            status               = options.status;
            if (options.state) {
                col    = options.state.col;
                dir    = options.state.dir;
                length = options.state.length;
                start  = options.state.start;
            }
        }

        instructionTextId = this._getTableInstructionTextId(this.hierarchy, status);

        this.model = new PolicyListDataTableViewModel({
            col               : col,
            dir               : dir,
            hierarchy         : this.hierarchy,
            length            : length,
            producerId        : this.producerId,
            producerOrgId     : this.producerOrgId,
            start             : start,
            status            : status,
            instructionTextId : instructionTextId
        });

    },

    onBeforeRender : function  () {
        var uiElements = {
            lastRefreshDate         : '#last-refresh-date',
            policyListDatatable     : '#policy-list-datatable',
            policyListDTWrapper     : '#policy-list-dt-wrapper'
        };

        if (this.options.hierarchy === 'org') {
            uiElements = _.mapObject(uiElements, function (selector, key) {
                return selector.replace(/#/,'#org-');
            });
        }

        this.ui = uiElements;
    },

    onRender : function () {

        var _this  = this;

        var ordering = this._getTableOrdering(
            this.model.get('status'),
            this.options.state
        );

        if (!this.ui.policyListDatatable.length) {
            return false;
        } 

        //Abort all previous request if there anything active
        //while user continousily swapping tab before rendering datatable
        utils.abortAllDataTableAjaxRequest(this.ui.policyListDatatable.selector);

        var url = this.model.url();

        var datatableColumns = [
            {
                title       : 'Name',
                data        : 'customer.lexicalName',
                name        : 'customerName',
            },
            {
                title       : 'Policy Number',
                data        : 'policyNumber',
                name        : 'policyNumber',
                createdCell : utils.setDataTablePolicyNumberLink
            },
            {
                title   : 'Product Name',
                data    : 'productName' ,
                name    : 'productName'
            },
            {
                title       : 'App Received',
                data        : 'application.received',
                name        : 'receivedDate',
                createdCell : this._setAppReceivedDate,
                type        : 'receivedDate'
            },
            {
                title       : 'Status',
                data        : 'policyStatus.description',
                name        : 'policyStatus',
                createdCell : _.bind(this._setStatusCellColor, this),
                type        : 'policy-status'
            }
        ];

        // for Paid tab Paid Date column will be displayed
        if (this.model.get('status') === 'paid') {
            datatableColumns.push({
                title   : 'Paid Date',
                data    : 'application.paid',
                name    : 'paidDate',
                render  : utils.dataTableFormatDate
            });

        // for other tabs Status Date column will be displayed
        } else {
            datatableColumns.push({
                title   : 'Status Date',
                data    : 'policyStatus.date',
                name    : 'statusDate',
                render  : utils.dataTableFormatDate
            });
        }

        if (this.producerOrgId || this.hierarchy === 'org') {
            datatableColumns.push({
                title   : 'Servicing Agent',
                data    : 'servicingProducer.lexicalName',
                name    : 'producerName'
            });
        }

        var dataTableOptions = utils.getDatatableOptions(this, url);

        _.extend(dataTableOptions, {

            // show info, length control, pagination, table, info, length, pagination
            dom : 'ilptilp',
            
            language : {
                info       : 'Showing _START_ to _END_ of _TOTAL_ ' +
                    'entries for <strong>"<span></span>"</strong>',
                lengthMenu : 'Display _MENU_ records'
            },

            // 0-based start index
            displayStart : this.model.get('start'),

            bAutoWidth: false,

            // initial sorting
            order : ordering,

            // initial page length
            pageLength : this.model.get('length'),

            columns : datatableColumns,

            //work only once for initial time 
            'initComplete' : function (dataTableSettings, jsonResponse) {

                if (jsonResponse.producer) {

                    //update general info section with current producer data
                    _this.trigger('setProducerInfo', jsonResponse.producer, _this.options.status);

                    _this.producerName = jsonResponse.producer.fullName;

                    //There is no direct API to update info label, so updating it on DOM
                    Backbone.$(dataTableSettings.nTableWrapper)
                        .find('.dataTables_info span').text(_this.producerName);
                }

                // update the last refresh date
                if (jsonResponse.lastRefreshDate) {

                    if (_this.ui.lastRefreshDate instanceof Backbone.$) {
                        _this.ui.lastRefreshDate.text(
                            utils.formatDate(
                                jsonResponse.lastRefreshDate, 
                                'MM/DD/YYYY, hh:mm A z'
                            )
                        );

                        _this.ui.lastRefreshDate.parent('p').removeClass('hidden');
                    } else {
                        debugModule.error('OrgPendingPolicListDTView:DT.initComplete - '+
                            '_this.ui.lastRefreshDate is not a jquery object :-' + 
                            _this.ui.lastRefreshDate);
                    }
                } 
            }
        });

        // In order to prevent "null" from being shown in IE/Edge, an
        // empty string will be set as the defaultContent for each column.
        utils.setDatatablesDefaultContent(dataTableOptions.columns, '');
        
        spinnerChannel.trigger('show',{
            viewScope : this
        });

        var table = this.ui.policyListDatatable.DataTable(dataTableOptions)
        .on('xhr', function($event, dataTableSettings, jsonResponse, xhr) {

            spinnerChannel.trigger('hide', _this);

            //if request is aborted callback will reach here with statusText 'abort'
            //So do no proceed to do anything, otherwise it display blank Table
            if (xhr && xhr.statusText === 'abort') {
                return false;
            }
            
            // Make sure we're getting the info back that we should expect
            if (!jsonResponse || _.isUndefined(jsonResponse.recordsTotal) || !xhr) {

                _this.trigger('error');
                return;
            }
            
            // Handle any non-200 HTTP responses
            // after the readyState has been set to 4 (DONE)
            if (xhr.readyState === 4 && xhr.status !== 200) {
                _this.trigger('error');
                return;
            }

            // Handle no results being returned
            if (jsonResponse.recordsTotal === 0) {
                _this.trigger('noResults', _this.options.status);
                return;
            }

            _this.ui.policyListDTWrapper.removeClass('hidden');
                     
        }).on('draw', function ($event, dataTableSettings){
            
            //There is no direct API to update info label, so updating it on DOM
            Backbone.$(dataTableSettings.nTableWrapper).find('.dataTables_info span')
                .text(_this.producerName);
        });

        utils.bindDataTableEvents({
            dtObject            : table, 
            viewScope           : this, 
            viewName            : 'pending-policy-list-datatable-info-v: ',
            spinnerChannel      : spinnerChannel,
            debugModule         : debugModule,
            addOrderListener    : true
        });

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));

    },

    /**
     * Construct the ID attribute of the table-responsive-instruction text
     * which corresponds to the datatable being rendered with this view.
     *
     * @param {string} [hierarchy]
     * @param {string} [status]
     * @private
     */
    _getTableInstructionTextId : function _getTableInstructionTextId(hierarchy, status) {
        var text   = '';

        if (hierarchy) {
            text = hierarchy + '-';
        }

        if (status) {
            text += status + '-';
        }

        text += 'policy-list_instruction';

        return text;
    },

    /**
     * Set background color to 'status' column. For pending
     * status, a progressive fill may be used to indicate progress.
     * Color mapping and fill will be based on - policy status mapping
     * Uses the columns.render function from DataTables.
     *
     * NOTE: Using the `createdCell` callback rather than `render` so that the custom sort uses the
     * data value, rather than the rendered html value.
     *
     * @param {DOM} td       TD element
     * @param {string} cellData value of cell
     * @param {[type]} rowData  Data source object / array for the whole row
     * @param {number} row      DataTables' internal index for the row
     * @param {number} col      DataTables' internal index for the column
     * @private
     */
    _setStatusCellColor: function (td, cellData, rowData, row, col) {
        var cellClass    = '';
        var cellTemplate = _.template(
            '<span class="label<%= cellClass %>"><%= cellData %></span>'
        );

        if (rowData && rowData.policyStatus && rowData.policyStatus.description) {
            
            // Update policyStatus object, if that contain dataAvailability object
            rowData   = utils.nullifyPolicyStatusNotAvailableProps(rowData);
            cellClass = ' ' + utils.getCssClassForStatus(
                rowData.policyStatus.description,
                rowData.policyStatus.status, 
                rowData.policyStatus.acordHoldingStatus
            );
            cellData = rowData.policyStatus.description;
        }

        Backbone.$(td).html(cellTemplate({ cellClass : cellClass, cellData  : cellData }));
    },

    _setAppReceivedDate: function (td, cellData, rowData, row, col) {
        var receivedDate = '';
        if (cellData) {
            receivedDate = utils.formatDate(cellData);
        }
        Backbone.$(td).html(receivedDate);
    },


    /**
     * Determine the ordering to use for the table.
     *
     * @param {string} the current tab, which determines the default sort
     * @param {object} the state object, which may be null
     *
     * @returns {Array} an Array in the form of:
     * [
     *     [col, dir]
     * ]
     *
     * @private
     */
    _getTableOrdering: function _getTableOrdering(tab, state) {
        var ordering = [];

        // default ordering
        switch (tab) {

            // statusDate
            case 'inactive':
            case 'paid':
                ordering = [
                    [5, 'desc']
                ];
                break;

            // receivedDate only
            default:
                ordering = [
                    [3, 'desc']
                ];
        }

        if (state) {

            // Need to explicitly check for col === 0, because it's valid even though it's falsy
            if (state.col || state.col === 0) {
                ordering[0][0] = Number(state.col);
            }

            if (state.dir) {
                ordering[0][1] = state.dir;
            }
        }

        return ordering;
    }
});

module.exports = PolicyListDataTableView;

