/* global Backbone */
var SidebarMenuItemModel = require('../models/sidebar-menu-item-m');

var SidebarNavCollection = Backbone.Collection.extend({

    model: SidebarMenuItemModel

});

module.exports = SidebarNavCollection;
