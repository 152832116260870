/* global Backbone:false, _:false */
/**
 * Model to retrieve data from /secure/rest/producers/{producerId}/roles endpoint.
 */

var config = require('../config/config');

var analyticsChannel = Backbone.Radio.channel('analytics');

var ProducerRolesModel = Backbone.Model.extend({

    errors : {
        missingProducerId : 'producerId must be a number in the options object sent to ' +
        'initialize the ProducerRolesModel.'
    },

    initialize : function initialize(options) {
        if (_.isObject(options) && options.producerId && _.isNumber(options.producerId)) {
            this.producerId = options.producerId;

            this.listenTo(this, 'error', this._handleFetchError);
        } else {
            throw new Error(this.errors.missingProducerId);
        }
    },

    /**
     * Get the active role (determined by the "reporting" attribute
     * being set to TRUE) and set it to an "activeRole" key on the
     * response if it exists.
     * @param {*} response
     * @returns {*}
     */
    parse : function parse(response) {
        var activeRole;
        var responseCopy;

        if (response) {
            responseCopy = Backbone.$.extend(true, {}, response);

            if (response.roles && _.isArray(response.roles)) {
                activeRole = _.findWhere(responseCopy.roles, {reporting : true});
            }

            if (activeRole) {
                responseCopy.activeRole = activeRole;
            }
        }

        return responseCopy;
    },

    /**
     * Return the URL root based on the values in the model.
     * @returns {string}
     */
    urlRoot : function urlRoot () {
        if (this.producerId) {
            return config.apiUrlRoot + 'producers/' + this.producerId + '/roles';
        } else {
            throw new Error(this.errors.missingProducerId);
        }
    },

    /**
     * Silently logs the fetch error. Since the producer role data
     * does not impact the functioning of the application itself
     * (only analytics data), handle all errors gracefully.
     *
     * @param {object} model
     * @param {object} response
     * @private
     */
    _handleFetchError : function _handleFetchError(model, response) {
        var message;

        if (response && response.status) {
            message = 'Error retrieving producer roles for producerId ' + this.producerId +
                '. Received a ' + response.status + ' status code.';
        }

        analyticsChannel.trigger('trackException', {
            fatal : false,
            message : message
        });

    }
});

module.exports = ProducerRolesModel;