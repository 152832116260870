/* global require:false, Backbone:false */

var PolicySearchFormView        = require('./policy-search-form-v');
var PolicySearchFormViewModel   = require('../viewModels/policy-search-form-vm');
var PolicySearchModule          = require('../../../modules/policySearch/policySearchModule');
var template                    = require('../templates/policy-search-t.hbs');

var PolicySearchWrapperView = Backbone.Marionette.LayoutView.extend({

    template  : template,
    className : 'tab-pane',

    regions: {
        searchForm    : '#pending-search-form-region',
        searchResults : '#pending-search-results-region'
    },

    ui: {
        searchResultsDiv : '#pending-search-results-region'
    },

    initialize : function initialize (options) {
        var searchFormModel;
        var searchOption = 'policySearch';
        var searchTerm   = null;
        var searchType   = null;

        // Create the search module and set up listeners
        this.policySearchModule = new PolicySearchModule();

        this.listenTo(this.policySearchModule, 'policyIdFound', this._showPolicy);
        this.listenTo(this.policySearchModule, 'resultStateChange', this._notifyOfStateChange);
        this.listenTo(this.policySearchModule, 'serverMessage', this._showServerMessage);
        this.listenTo(this.policySearchModule, 'showResultsView', this._showResultsView);


        // Set up the search form and set up listeners
        if (options) {
            searchTerm = options.searchTerm;
            searchType = options.searchType;

            if (searchType === 'producerName') {
                searchOption = searchType;
            } else if (searchType === 'producerNumber') {
                searchOption = searchType;
            }
        }

        searchFormModel = new PolicySearchFormViewModel({
            searchOption : searchOption,
            searchTerm   : searchTerm,
            searchType   : searchType
        });

        this.searchFormView = new PolicySearchFormView({
            model: searchFormModel
        });

        this.listenTo(this.searchFormView, 'clientNameSearch', this._performClientNameSearch);
        this.listenTo(this.searchFormView, 'policyNumberSearch', this._performPolicyNumberSearch);
        this.listenTo(this.searchFormView, 'producerNameSearch', this._performProducerNameSearch);
        this.listenTo(this.searchFormView, 
            'producerNumberSearch', this._performProducerNumberSearch
        );
    },


    onBeforeShow : function onBeforeShow () {
        var searchState = {};
        var searchType  = this.searchFormView.model.get('searchType');

        this.showChildView('searchForm', this.searchFormView);

        if (this.options && this.options.searchState) {
            searchState = this.options.searchState;
        }

        if (searchType === 'clientName') {
            this.policySearchModule.performClientNameSearch(
                this.searchFormView.model.get('searchTerm'),
                searchState
            );

        } else if (searchType === 'producerName') {
            this.policySearchModule.performProducerNameSearch(
                this.searchFormView.model.get('searchTerm'),
                searchState
            );
        } else if (searchType === 'producerNumber') {
            this.policySearchModule.performProducerNumberSearch(
                this.searchFormView.model.get('searchTerm'),
                searchState
            );
        }
    },


    /**
     * Handler for performing a client name search. This is simply a passthrough to the
     * PolicySearchModule's `performClientNameSearch` function. It is necessary because the
     * callback attached via the `listenTo` function always uses the observer as the context
     * (meaning that the `this` variable within the PolicySearchModule's function would refer
     * to this view instead of the PolicySearchModule itself).
     *
     * @param searchTerm the client name to search for
     * @private
     */
    _performClientNameSearch : function _performClientNameSearch(searchTerm) {
        var state = {
            searchTerm: searchTerm,
            searchType: 'clientName'
        };
        this.trigger('stateChange', state, true);
        this._clearCurrentResults();
        this.policySearchModule.performClientNameSearch(searchTerm);
    },


    /**
     * Handler for performing a Policy Number search. This is simply a passthrough to the
     * PolicySearchModule's `performPolicyNumberSearch` function. It is necessary because the
     * callback attached via the `listenTo` function always uses the observer as the context
     * (meaning that the `this` variable within the PolicySearchModule's function would refer
     * to this view instead of the PolicySearchModule itself).
     *
     * @param policyNumber the policyNumber to search for
     * @private
     */
    _performPolicyNumberSearch : function _performPolicyNumberSearch (policyNumber) {
        this._clearCurrentResults();
        this.policySearchModule.performPolicyNumberSearch(policyNumber);
    },


    /**
     * Handler for performing a producer name search.
     * @see {@link _performClientNameSearch}
     * @param searchTerm
     * @param options
     * @private
     */
    _performProducerNameSearch : function _performProducerNameSearch (searchTerm) {
        var state = {
            searchTerm : searchTerm,
            searchType : 'producerName'
        };
        this.trigger('stateChange', state, true);
        this._clearCurrentResults();
        this.policySearchModule.performProducerNameSearch(searchTerm);
    },

    /**
     * Handler for peforming a Producer Number search
     * @param  {string} searchTerm
     * @private
     */
    _performProducerNumberSearch : function _performProducerNumberSearch (searchTerm) {
        var state = {
            searchTerm : searchTerm,
            searchType : 'producerNumber'
        };
        this.trigger('stateChange', state, true);
        this._clearCurrentResults();
        this.policySearchModule.performProducerNumberSearch(searchTerm);
    },

    /**
     * Show the results view in the 'searchResults' region.
     * @param resultsView the view to show
     * @private
     */
    _showResultsView : function _showResultsView (resultsView) {
        this.ui.searchResultsDiv.removeClass('hidden');
        this.showChildView('searchResults', resultsView);
    },


    /**
     * Display a message from the server
     * @param message the message to display
     * @param type the type of the message (info, warn, etc)
     * @private
     */
    _showServerMessage : function _showServerMessage (message, type) {
        this.searchFormView.showServerMessage(message, type);
    },


    /**
     * Navigate to the policy detail page
     * @param policyId the ID of the policy to display
     * @private
     */
    _showPolicy : function _showPolicy (policyId) {
        this.trigger('showPolicy', policyId);
    },


    /**
     * Destroy the current search results view, if it exists.
     * @private
     */
    _clearCurrentResults : function _clearCurrentResults () {
        this.ui.searchResultsDiv.addClass('hidden');
        var currentResults = this.getChildView('searchResults');
        if (currentResults) {
            currentResults.destroy();
        }
    },


    /**
     * Replace the hash when the user changes the state (paging/sorting) of the result table.
     * @param {object} [changedState] The properties that have changed
     * @private
     */
    _notifyOfStateChange : function _notifyOfStateChange (changedState) {

        // Maintain the search term/type
        changedState.searchTerm = this.searchFormView.model.get('searchTerm');
        changedState.searchType = this.searchFormView.model.get('searchType');

        this.trigger('stateChange', changedState);
    }
});

module.exports = PolicySearchWrapperView;