// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.hierarchy || (depth0 != null ? depth0.hierarchy : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hierarchy","hash":{},"data":data}) : helper)))
    + "-";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <li id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "search-tab\">\r\n                <a data-toggle=\"tab\" data-tabname=\"search\" data-target=\"#"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "search-tab-pane\" href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,(helpers.concat || (depth0 && depth0.concat) || alias2).call(alias1,(depth0 != null ? depth0.hashPrefix : depth0),"/search",(depth0 != null ? depth0.tabLinksId : depth0),{"name":"concat","hash":{},"data":data}),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" aria-expanded=\"false\" class=\"tab-search oa-js-tab\">\r\n                    <i class=\"fa fa-search oa-lake\"></i><span class=\"hidden-xs\"> Search</span>\r\n                    \r\n                </a>\r\n            </li>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <li id=\"inforce-sr-tab\">\r\n                <a data-toggle=\"tab\" data-tabname=\"inforce-service-request\" data-target=\"#inforce-sr-tab-pane\" href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,(helpers.concat || (depth0 && depth0.concat) || alias2).call(alias1,(depth0 != null ? depth0.hashPrefix : depth0),"/inforce-service-request",(depth0 != null ? depth0.tabLinksId : depth0),{"name":"concat","hash":{},"data":data}),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" aria-expanded=\"false\" class=\"tab-inforce-sr oa-js-tab\">\r\n                    <span class=\"hidden-xs\">Inforce Service Requests</span>\r\n                    <span class=\"visible-xs\">ISR</span>\r\n                </a>\r\n            </li>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <div role=\"tabpanel\" class=\"tab-pane\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "search-tab-pane\">\r\n                <div id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "policy-search-region\"></div>\r\n            </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            <div role=\"tabpanel\" class=\"tab-pane\" id=\"inforce-sr-tab-pane\">\r\n                <div id=\"inforce-sr-region\"></div>\r\n            </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div id=\"tabs-region\" class=\"tabbable\">\r\n    <ul class=\"nav nav-tabs\" >\r\n        <li id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "all-policies-tab\">\r\n            <a data-toggle=\"tab\" data-tabname=\"allPolicies\" data-status=\"all\" data-target=\"#"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "all-policy-list-tab-pane\" href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,(helpers.concat || (depth0 && depth0.concat) || alias2).call(alias1,(depth0 != null ? depth0.hashPrefix : depth0),"/allPolicies",(depth0 != null ? depth0.tabLinksId : depth0),{"name":"concat","hash":{},"data":data}),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" aria-expanded=\"true\" class=\"tab-all-policies oa-js-tab\" >\r\n                All\r\n                <span class=\"hidden-xs\"> New Business</span>\r\n                <strong class=\"oa-lake hidden-xs\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "count-all-policies\"></strong>\r\n            </a>\r\n        </li>\r\n\r\n        <li id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "pending-tab\">\r\n            <a data-toggle=\"tab\" data-tabname=\"pending\" data-status=\"pending\" data-target=\"#"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "pending-policy-list-tab-pane\" href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,(helpers.concat || (depth0 && depth0.concat) || alias2).call(alias1,(depth0 != null ? depth0.hashPrefix : depth0),"/pending",(depth0 != null ? depth0.tabLinksId : depth0),{"name":"concat","hash":{},"data":data}),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" aria-expanded=\"false\" class=\"tab-pending oa-js-tab\">\r\n                Pending\r\n                <strong class=\"oa-lake hidden-xs\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "count-pending-policies\"></strong>\r\n            </a>\r\n        </li>\r\n\r\n        <li id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "paid-tab\">\r\n            <a data-toggle=\"tab\" data-tabname=\"paid\" data-status=\"paid\" data-target=\"#"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "paid-policy-list-tab-pane\"  href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,(helpers.concat || (depth0 && depth0.concat) || alias2).call(alias1,(depth0 != null ? depth0.hashPrefix : depth0),"/paid",(depth0 != null ? depth0.tabLinksId : depth0),{"name":"concat","hash":{},"data":data}),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" aria-expanded=\"false\" class=\"tab-paid oa-js-tab\">\r\n                Paid\r\n                <strong class=\"oa-lake hidden-xs\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "count-paid-policies\"></strong>\r\n            </a>\r\n        </li>\r\n        <li id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "inactive-tab\">\r\n            <a data-toggle=\"tab\" data-tabname=\"inactive\" data-status=\"inactive\" data-target=\"#"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "inactive-policy-list-tab-pane\" href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,(helpers.concat || (depth0 && depth0.concat) || alias2).call(alias1,(depth0 != null ? depth0.hashPrefix : depth0),"/inactive",(depth0 != null ? depth0.tabLinksId : depth0),{"name":"concat","hash":{},"data":data}),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" aria-expanded=\"false\" class=\"tab-inactive oa-js-tab\">\r\n                Inactive\r\n                <strong class=\"oa-lake hidden-xs\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "count-inactive-policies\"></strong>\r\n            </a>\r\n        </li>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchEnabled : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.inforceServiceRequestEnabled : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\r\n\r\n    <div class=\"tab-content\">\r\n\r\n        <div role=\"tabpanel\" class=\"tab-pane\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "all-policy-list-tab-pane\">\r\n            <h2 class=\"header\">All Policies</h2>\r\n            <div id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "all-policy-list-datatable-region\" ></div>\r\n        </div>\r\n\r\n\r\n        <div role=\"tabpanel\" class=\"tab-pane\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "pending-policy-list-tab-pane\">\r\n            <h2 class=\"header\">Pending Policies</h2>\r\n            <div id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "pending-policy-list-datatable-region\" ></div>\r\n        </div>\r\n\r\n        <div role=\"tabpanel\" class=\"tab-pane\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "paid-policy-list-tab-pane\">\r\n            <h2 class=\"header\">Paid Policies</h2>\r\n            <div id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "paid-policy-list-datatable-region\" ></div>\r\n        </div>\r\n\r\n        <div role=\"tabpanel\" class=\"tab-pane\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "inactive-policy-list-tab-pane\">\r\n            <h2 class=\"header\">Inactive Policies</h2>\r\n            <div id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "inactive-policy-list-datatable-region\" ></div>\r\n        </div>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchEnabled : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.inforceServiceRequestEnabled : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"useData":true});
