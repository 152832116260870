// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "has-error";
},"3":function(container,depth0,helpers,partials,data) {
    return "hide";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"center\"><i class=\""
    + alias4(((helper = (helper = helpers.iconCss || (depth0 != null ? depth0.iconCss : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconCss","hash":{},"data":data}) : helper)))
    + "\"></i>\r\n    <h4><a href=\""
    + alias4((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,(depth0 != null ? depth0.href : depth0),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" class=\"oa-js-nav\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a></h4>\r\n    <p>"
    + ((stack1 = ((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\r\n    <form class=\"simple-search\" id=\"home-simple-search\">\r\n        <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchTermErrorText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n            <label class=\"control-label sr-only\" for=\"home-simple-search-input\"> Policy &amp; Client Search </label>\r\n            <input type=\"search\" class=\"form-control\" id=\"home-simple-search-input\" name=\"home-simple-search-input\"\r\n                placeholder=\"Policy &amp; Client search\" aria-describedby=\"simple-search-input-restrictions\"\r\n                value=\""
    + alias4(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\" />\r\n            <button aria-label=\"information\" class=\"info-wrapper\" type=\"button\" data-toggle=\"popover\"\r\n                data-content='Policy Number must be 9 or 10 digits. Name searches can be \"Name\" or \"Last Name, First Name.\"'>\r\n                <i class=\"fa fa-info-circle oa-lake pointer no-margin\" id=\"simple-search-input-restrictions\"></i>\r\n            </button>\r\n            <span class=\"help-block "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTermErrorText : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                "
    + alias4(((helper = (helper = helpers.searchTermErrorText || (depth0 != null ? depth0.searchTermErrorText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchTermErrorText","hash":{},"data":data}) : helper)))
    + "\r\n            </span>\r\n        </div>\r\n        <button class=\"btn btn-info btn-sm\" type=\"submit\" id=\"home-simple-search-btn\"\r\n            "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTerm : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n            <i class=\"ace-icon fa fa-search bigger-110\"></i> Search\r\n        </button>\r\n    </form>\r\n</div>";
},"useData":true});
