// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<ul class=\"nav nav-tabs\" role=\"tablist\">\r\n    <li class=\"active\">\r\n        <a href=\"#"
    + alias4(((helper = (helper = helpers.sectionType || (depth0 != null ? depth0.sectionType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sectionType","hash":{},"data":data}) : helper)))
    + "-qualified\" aria-controls=\"qualified\" role=\"tab\" data-toggle=\"tab\">Am I Qualified?</a>\r\n    </li>\r\n    <li>\r\n        <a href=\"#"
    + alias4(((helper = (helper = helpers.sectionType || (depth0 != null ? depth0.sectionType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sectionType","hash":{},"data":data}) : helper)))
    + "-on-schedule\" aria-controls=\"on-schedule\" role=\"tab\" data-toggle=\"tab\">Am I On Schedule?</a>\r\n    </li>\r\n</ul>";
},"useData":true});
