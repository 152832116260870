// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "        <td>"
    + alias2(((helper = (helper = helpers.fullName || (depth0 != null ? depth0.fullName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"fullName","hash":{},"data":data}) : helper)))
    + "</td>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].showDoBnGenderColumns : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n        <td>"
    + alias2(container.lambda((depths[1] != null ? depths[1].relationship : depths[1]), depth0))
    + "</td>\r\n        <td>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.taxId : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </td>\r\n        <td class=\"tbody-address\">\r\n\r\n"
    + ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,((stack1 = (data && data.root)) && stack1.customerRoleContacts),(depths[1] != null ? depths[1].customerRoleContactId : depths[1]),{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </td>\r\n        <td>\r\n"
    + ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,((stack1 = (data && data.root)) && stack1.customerRoleContacts),(depths[1] != null ? depths[1].customerRoleContactId : depths[1]),{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n        </td>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <td>"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].showDoBnGender : depths[1]),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\r\n            <td>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].showDoBnGender : depths[1]),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </td>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.dateOfBirth : depth0),{"name":"dateFormat","hash":{},"data":data}));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.gender : depth0),"m",{"name":"compare","hash":{"operator":"strcis"},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                    "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.gender : depth0),"f",{"name":"compare","hash":{"operator":"strcis"},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "Male";
},"8":function(container,depth0,helpers,partials,data) {
    return "Female";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.taxId : depth0),"NONE",{"name":"compare","hash":{"operator":"!=="},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    "
    + container.escapeExpression(((helper = (helper = helpers.taxId || (depth0 != null ? depth0.taxId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"taxId","hash":{},"data":data}) : helper)))
    + "\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.post : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.post : depth0)) != null ? stack1.lines : stack1),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                    "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.post : depth0)) != null ? stack1.city : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.post : depth0)) != null ? stack1.stateProvince : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.post : depth0)) != null ? stack1.postalCode : stack1), depth0))
    + "\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                        "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + " <br />\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.phoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.phoneNumber : depth0)) != null ? stack1.primary : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.phoneNumber : depth0)) != null ? stack1.alternate : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.phoneNumber : depth0)) != null ? stack1.primary : stack1),"0000000000",{"name":"compare","hash":{"operator":"!="},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            Primary: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.phoneNumber : depth0)) != null ? stack1.primary : stack1), depth0))
    + " <br />\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.phoneNumber : depth0)) != null ? stack1.alternate : stack1),"0000000000",{"name":"compare","hash":{"operator":"!="},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            Alternate: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.phoneNumber : depth0)) != null ? stack1.alternate : stack1), depth0))
    + " <br />\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <a href=\"mailto:"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</a>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<tr role=\"row\">\r\n"
    + ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,((stack1 = (data && data.root)) && stack1.customers),(depth0 != null ? depth0.customerId : depth0),{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</tr>";
},"useData":true,"useDepths":true});
