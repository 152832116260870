/* global _ */
var _global = (function() { return window;})();

// Will be overridde by preprocessing in browserify
var debugValue = 'undefined' || false;

// Only place values in here that you will allow to be overridden
// For other values, place in the protectedConfig
var config = {
    debug: debugValue
};

// Values placed here will not be overridder by query params
// or localStoreage
var protectedConfig = {
    console: !!_global.console,
    namespace: 'SIDEBAR'
};

// TODO handle arrays, objects
// Currently just returns strings
function parseLocalStorage(configObj) {

    var localStorageConfig = {};

    // loop variables, for iterating over object properties
    var current, prop;

    if (!_global.localStorage) {
        return localStorageConfig;
    }

    for (prop in configObj) {
        if (
            configObj.hasOwnProperty(prop) === true &&
                !!(_global.localStorage.getItem(prop))
        ) {
            current = _global.localStorage.getItem(prop);
            if (current === 'true') {
                current = true;
            } else if (current === 'false') {
                current = false;
            }
            localStorageConfig[prop] = current;
        }
    }
    return localStorageConfig;
}

function parseQueryParams(params) {

    // return value
    var configObj = {};
    // looping vars
    var i, len, current;
    // temp variables for dealing with name/value pairs
    var name, value;

    if (!params) {
        return configObj;
    }

    if (/^\?/.test(params) === true) {
        params = params.substr(1);
    }

    // get a list of all values that were passed
    if (params.length > 0) {

        // check for encoded html
        if (/&amp;/.test(params) === true) {
            params = params.replace('&amp;', '&');
        }

        // break up into name/value pairs
        params = params.split('&');
        len = params.length;
        for (i = 0; i < len; i++) {

            // now split into name/value
            current = params[i];
            current = current.split('=');

            if (current.length > 0) {

                name = current[0];
                value = current[1];

                // convert to real booleans
                if (value === 'true') {
                    value = true;
                } else if (value === 'false') {
                    value = false;
                } else {
                    // Don't want to re-encode here, but set to strings again
                    // just in case of references to scoped variables
                    value = value + '';
                }
                configObj[name] = value;

            } else {

                // If here, wasn't a valid foo=bar
                continue;

            } // if/else

        } // for loop

    } // for loop over params.length

    return configObj;

}

// Singleton
module.exports = (function() {
    var runtimeConfig = parseQueryParams(_global.location.search);
    runtimeConfig = _.extend({}, runtimeConfig, parseLocalStorage(config));
    config = _.extend({}, config, runtimeConfig, protectedConfig);
    return config;
})();
