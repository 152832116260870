/* global Backbone:false, _:false */
/**
 * Policy Search View
 */
import debugModule from '../../../modules/debug/debugModule';
import errorHelper from '../../../utils/error-helper';
import PolicySearchViewModel from '../viewModels/policy-search-form-vm';
import template from '../templates/policy-search-form-t.hbs';

const dbg = debugModule.init();

// Need to include this, otherwise unit tests blow up.
import '../../../utils/hb-helpers';

// load the global partials.
import '../../../partials';

const analyticsChannel = Backbone.Radio.channel('analytics');
const userChannel = Backbone.Radio.channel('user');

const PolicySearchFormView = Backbone.Marionette.ItemView.extend({

    template: template,

    ui: {
        popoverElements: '[data-toggle="popover"]',
        searchButton: '#pending-search-button',
        searchForm: '#pending-search-form',
        searchTerm: '#pending-search-term-input',
        searchOption: '[name="search-option"]',
    },

    events: {
        'input @ui.searchTerm': '_enableSearchButton',
        'submit @ui.searchForm': '_search',
        'click @ui.popoverElements': '_toggleInfoPopover',
        'blur @ui.popoverElements': '_hideInfoPopover',
        'mouseenter @ui.popoverElements': '_showInfoPopover',
        'mouseleave @ui.popoverElements': '_hideInfoPopover'
    },

    /**
     * Initialize the view. This function will create a model for the view if one was not
     * included in the options object.
     * 
     * @param options
     */
    initialize(options) {

        const canSearchPolicyByProducer = userChannel.request('hasCapability',
            'Policy_Search_by_Producer');
        const canViewAsProducer = userChannel.request('hasCapability',
            'HO_ViewAsProducer');

        // Create and set a new model if there isn't one passed in
        if (!options.model) {
            this.model = new PolicySearchViewModel();
        }

        // searchType isn't set, and can search by Producer, so default to 'policyNumber'
        if (!this.model.get('searchType') && canSearchPolicyByProducer) {
            this.model.set({
                searchType: 'policyNumber'
            });
        }

        this.model.set({
            hasPolicySearchByProducer: canSearchPolicyByProducer,
            hasHOViewAsProducer: canViewAsProducer
        });
    },

    /**
     * When rendering, bind the popover handler to the popover ui elements.
     */
    onRender() {

        // Binding popover event to all popover elements
        // Popover will be displayed on click, enter, hover events
        this.ui.popoverElements.popover({
            trigger: 'manual',
            placement: 'auto',
            container: 'body'
        });
    },

    _toggleInfoPopover(e) {
        this.ui.popoverElements.popover('toggle');
    },

    _showInfoPopover(e) {
        this.ui.popoverElements.popover('show');
    },

    _hideInfoPopover(e) {
        this.ui.popoverElements.popover('hide');
    },

    /**
     * Show a server message
     * @param message The text of the message
     * @param messageType The type of the message - 'process' or 'server'
     */
    showServerMessage(message, messageType) {
        const messageTemplate = _.template(message);
        const messageText = messageTemplate({ searchTerm: this.model.get('searchTerm') });

        this.model.set('alertMessage', errorHelper.createAlert(messageText, messageType));
        this.render();
    },

    /**
     * Enable search button if input is not empty
     */
    _enableSearchButton() {
        const searchText = this.ui.searchTerm.val();
        if (searchText.length >= 1) {
            this.ui.searchButton.attr('disabled', false);
        } else {
            this.ui.searchButton.attr('disabled', true);
        }
    },

    /**
     * Validate the entire form and then perform the search if there are no errors.
     *
     * @param event The event that initiated the call to this function.
     * @private
     */
    _search(event) {
        // Don't want to just go submitting all willy-nilly.
        if (event) {
            event.preventDefault();
        }

        // Update the model and validate
        // error messages are available via this.model.validation
        this._updateModel();
        const isValid = this.model.isValid();

        if (isValid) {

            // Track form submission through Enter key
            if (event && event.currentTarget
                && event.currentTarget.tagName
                && event.currentTarget.tagName.toLowerCase() === 'form') {
                analyticsChannel.trigger('trackAction', {
                    event: event
                });
            }

            // Input OK, trigger appropriate search
            switch (this.model.get('searchType')) {

                case 'clientName':
                    this.trigger('clientNameSearch', this.model.get('searchTerm'));
                    break;

                case 'policyNumber':
                    this.trigger('policyNumberSearch', this.model.get('searchTerm'));
                    break;

                case 'producerName':
                    this.trigger('producerNameSearch', this.model.get('searchTerm'));
                    break;
                case 'producerNumber':
                    this.trigger('producerNumberSearch', this.model.get('searchTerm'));
                    break;

                default:
                    // somehow passed validation, but didn't have a searchType. Error.
                    dbg.error('Unknown searchType "' + this.model.get('searchType') + '"');
            }
        }

        // render to show/hide errors
        this.render();

    },


    /**
     * Update the model object, based on the UI
     *
     * @private
     */
    _updateModel() {
        const searchOption = this.ui.searchOption.filter(':checked').val();
        let searchType = null;
        const searchTerm = this.ui.searchTerm.val();

        // if type is required, get the selection 
        // (only for the user has 'Policy_Search_by_Producer' capability)
        if (this.model.get('hasPolicySearchByProducer') && searchOption === 'producerName') {
            searchType = 'producerName';
        } else if (searchOption === 'producerNumber') {
            searchType = searchOption;
        }

        // update the model with the proper form values, and clear any alert message
        this.model.set({
            alertMessage: null,
            searchOption: searchOption,
            searchTerm: searchTerm,
            searchType: searchType
        });
    }

});

module.exports = PolicySearchFormView;
