// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"row\" id=\"general-info-container\" >\r\n            <div class=\"col-sm-6\">\r\n                <div class=\"profile-user-info\">\r\n                    <div class=\"profile-info-row\">\r\n                        <div class=\"profile-info-name\">\r\n                            <h5 class=\"gray-50\">Pending Policies:</h5>\r\n                        </div>\r\n                        <!-- /.profile-info-name -->\r\n                        <div class=\"profile-info-value\" id=\"general-info-data-region\">\r\n                        </div>\r\n                        <!-- /.profile-info-value --> \r\n                    </div>\r\n                </div>\r\n                <!-- /.profile-user-info --> \r\n            </div>\r\n        </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"well well-account-info\">\r\n    <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n            <h3>Name:&nbsp;\r\n                <span class=\"oa-banner-blue\" id=\"general-info-producer-name-region\">\r\n                </span>\r\n            </h3>\r\n        </div>\r\n        <!-- /.col-sm-6 -->\r\n        <div class=\"col-sm-6\"> </div>\r\n        <!-- /.col-sm-6 --> \r\n    </div>\r\n    <div id=\"hierarchy-paths-region\">\r\n    </div>\r\n    <!-- /.row -->\r\n    \r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
