/* global require:false, module:false, Backbone:false */
/**
 * HierarchyPathsModel encapsulates the list of hierarchy paths for a user.
 */

import config from '../../../config/config';

const HierarchyPathsModel = Backbone.Model.extend({

    errors : {
        idRequired : 'An id property is required to retrieve paths'
    },

    /**
     * Lifecycle method. Requires that the attrs param exists and has an "id" property.
     * @param attrs the attributes to set in the model
     * @param options the standard options object
     */
    initialize (attrs, options) {
        if (!attrs || !attrs.id) {
            throw new Error(this.errors.idRequired);
        }
    },

    /**
     * Parse the response. Basically, place the response in the model's "paths" attribute.
     * @param response the server response
     * @param options the standard options object
     */
    parse (response, options) {
        this.set('paths', response);
    },

    /**
     * Retrieve the URL to fetch the model
     * @returns {string}
     */
    url () {
        if (! this.has('id')) {
            throw new Error(this.errors.idRequired);
        }
        return config.apiUrlRoot + 'producers/' + this.get('id') + '/paths';
    },

    /**
     * Return true if the current id is at the root of all hierarchy paths.
     */
    producerIsRoot () {
        let retVal = false;
        let producerId;
        let thisProducerAtRoot;

        if (this.has('id')) {
            // Depending on the situation (user using back button), producerId can evaluate to a
            // string or a number. Force this to a number so that it can be evaluated properly
            // below.
            producerId = Number(this.get('id'));
            if (this.has('paths')) {
                thisProducerAtRoot = this.get('paths').filter(function(element, index, paths) {
                    const producers = element.producers;
                    return (
                        producers &&
                        producers.length &&
                        producers[0].producer.id === producerId
                    );
                });
            }
            if (thisProducerAtRoot &&
                thisProducerAtRoot.length &&
                thisProducerAtRoot.length === this.get('paths').length) {
                retVal = true;
            }

        } else {
            throw new Error(this.errors.idRequired);
        }
        return retVal;
    }
});

module.exports = HierarchyPathsModel;