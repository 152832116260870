/* global Marionette:false */

var dbg             = require('../../../modules/debug/debugModule').init();

var HelloTemplate   = require('../templates/hello-t.hbs');
var HelloModel      = require('../models/hello-m');

var HelloView = Marionette.ItemView.extend({

    defaultName : 'stranger',
    template    : HelloTemplate,
    model       : new HelloModel(),

    initialize: function (options) {
        var name;

        // If there is no name in the stateObj, use the default name
        if (options && options.stateObj && typeof options.stateObj.name === 'string') {
            name = options.stateObj.name;
        } else {
            name = this.defaultName;
        }

        this.model.set('name', name);
    },

    // Added a little here to either render the response or log that it failed.
    onShow: function () {
        var _this = this;
        this.fetchReq = this.model.fetch({
            done: function() {
                _this.render();
            },
            fail: function(model, response, options) {
                switch (response.statusText) {
                    case 'abort' :
                        dbg.log('hello model fetch aborted');
                        break;
                    case 'error' :
                        dbg.log('hello model fetch error');
                        break;
                    default:
                        dbg.log('hello model fetch failed');
                }
            }
        });
    },

    // If this view is shown twice in quick succession (faster than the
    // model fetch can complete), then it's possible that by the time the
    // success callback is executed, this view might have been destroyed.
    // To avoid this situation, we abort the XHR (if there is one) when the
    // view is destroyed.
    onDestroy: function () {
        // cancel any pending XHR requests
        this.fetchReq.abort();
    }
});

module.exports = HelloView;
