// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"well well-account-info hidden\" id=\"producer-info-region\"></div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"well well-account-info hidden\" id=\"orgs-producer-info-region\"></div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"page-content\">\r\n    <div class=\"page-header\">\r\n        <h1>Policy View</h1>\r\n    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showProducerInfoRegion : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showOrgProducerInfoRegion : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n    <div id=\"policy-list-region\"></div>\r\n</div>";
},"useData":true});
