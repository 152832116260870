// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div id=\"orgsTableWrapper\" class=\"hidden\">\r\n        <div class=\"well\"  >  \r\n            <h2 class=\"header\">Producers with Pending Policies</h2>\r\n            <p class=\"hidden\">\r\n                <span class=\"oa-lake\">Last Refresh Date:</span> \r\n                <span id=\"orgs-table-last-refresh-date\"></span>\r\n            </p>\r\n\r\n            <div class=\"table-responsive\">\r\n                <table class=\"table table-striped table-bordered table-hover table-sortable table-producer-view\" id=\"orgs-table\" data-instruction-container=\"#orgs-table_instruction\">\r\n                </table>\r\n            </div>\r\n            <!-- /.table-responsive --> \r\n\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","hash":{"id":"orgs-table_instruction"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\r\n        <!-- /.well, #hierarchy-table-region-->\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
