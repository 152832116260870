/* global Backbone:false, _:false*/
/**
 * A module (Singletone) to check whether HTML5 feature will support on Browser.  
 * This module should be initialized on app start and use Backbone.Radio Request. 
 * 
 * Usage:
 * 
 * //require at OSOApp.js or from start up of the App
 * require('browserFeatureDetectionModule');
 *
 * //later on use channel call from any module or page
 * var browserFeatureDetectionChannel = Backbone.Radio.channel('browserFeature');
 * 
 * //to check
 * browserFeatureDetectionChannel.request('detect', 'filereader');
 * 
 */

// list of feature those need to check support on browser
var browserFeatureList = {
    filereader  : undefined,
    formdata    : undefined,
    webp        : undefined
};

var BrowserFeatureDetectionModule  = Backbone.Marionette.Object.extend({

    initialize: function() {
        this.setChannelAndDetectFeatures();
    },

    /**
     * A Method to detect browser features list and set a channel name
     * to access from Modules and Pages
     * 
     * Method invoked automatically while initializing this module.
     */
    setChannelAndDetectFeatures : function () {

        // Create a channel name for browserFeature sharing
        var channelName = this.options && this.options.channelName || 'browserFeature';
        this.browserFeatureDetectionChannel = Backbone.Radio.channel(channelName);
        
        /*
         * Register browser Feature Detection Channel callback for feature check
         */
        this.browserFeatureDetectionChannel.reply('detect', this._detect, this);

        //detect each features listed
        for (var feature in browserFeatureList) {
            if (feature === 'filereader') {
                browserFeatureList[feature] 
                    = window.File && window.FileList && window.FileReader;
            } else if (feature === 'formdata') {
                browserFeatureList[feature] = window.FormData;
            } else if (feature === 'webp') {
                
                Backbone.$.when(
                    this._checkWebpFeature('lossless'), 
                    this._checkWebpFeature('alpha')
                ).then ( this._setWebpSupportFlag );
            }
        }
    },

    /**
     * Set webP feature support 
     * params will based on deffered method defined in setChannelAndDetectFeatures
     * method
     */
    _setWebpSupportFlag : function _setWebpSupportFlag (lossless, alpha) {
        if (lossless && alpha) {
            Backbone.$('body').addClass('webp');
            browserFeatureList.webp = true;
        }
    },

    /**
     * Private method to detect a browser feature which is already 
     * @param  {String} featureName Name of the feature mentioned window object
     * @return {object / undefined}  Return can be a object or undefined based on inputs
     */
    _detect : function (featureName) {
        if (featureName && browserFeatureList 
                && browserFeatureList[featureName]) {
            return browserFeatureList[featureName];
        } else {
            return undefined;
        }
    },

    /**
     * Deferred method to check webP feature on browser.
     * Reference:
     * https://developers.google.com/speed/webp/faq#how_can_i_detect_browser_support_for_webp
     * 
     * @param {String} feature 
     */
    _checkWebpFeature : function _checkWebpFeature(feature) {
        var deferred = Backbone.$.Deferred();

        var kTestImages = {
            lossy       : 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
            lossless    : 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
            alpha       : 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/'+
                            'Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
            animation   : 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1G'+
                            'JgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
        };
        var img = new Image();
        img.onload = function () {
            return deferred.resolve(true);
        };
        img.onerror = function () {
            return deferred.reject();
        };

        if (!feature && !_.has(kTestImages, feature)) {
            return deferred.reject();
        } else {
            img.src = 'data:image/webp;base64,' + kTestImages[feature];
        }
        return deferred.promise();
    }

});

module.exports = new BrowserFeatureDetectionModule();
