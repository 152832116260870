/* global Backbone:false */

import config from '../../../config/config';

const EditDelegateModel = Backbone.Model.extend({
    
    urlRoot() {
        let producerId = this.get('producerId');
        let relationshipId = this.get('relationshipId');
        const url = `${config.apiUrlRoot}producers/${producerId}/delegates/${relationshipId}`;
        return url;
    },
    
    /**
     * Parse the response. Basically, place the response in the model'
     * @param response the server response
     * @param options the standard options object
     */
    parse(response) {
        this.set('delegate', response);
    },

});
module.exports = EditDelegateModel;