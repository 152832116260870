/* global Backbone:false, _:false */
/**
 * View used to display a WCM-based tout.
 *
 * @param {boolean} [options.buildHrefUrl=false] options.buildHrefUrl - Use the
 * utils.buildHrefURL method on all of the links.
 * @param {string} options.wcmPath - The path used to retrieve content.
 * 
 */

var WcmContentModel = require('../../../modules/wcm-content/models/wcm-content-m');
var utils           = require('../../../utils/utils');

var analyticsChannel = Backbone.Radio.channel('analytics');
var spinnerChannel   = Backbone.Radio.channel('spinner');

var WcmToutView = Backbone.Marionette.ItemView.extend({

    errors : {
        invalidOptions : 'The options passed to initialize the WCM tout view must ' +
            'be an object and contain a "wcmPath" string.'
    },

    template : _.template('<div class="center"><%= body%></div>'),

    ui : {
        root  : 'div:first',
        links : 'a'
    },

    initialize : function initialize(options) {
        // Validate the options
        if (_.isUndefined(options)
            || !_.isObject(options)
            || !options.hasOwnProperty('wcmPath')
            || typeof options.wcmPath !== 'string') {

            throw new Error(this.errors.invalidOptions);
        }

        // attach utils for testing
        this.utils = utils;

        if (options.buildHrefUrl) {
            this.buildHrefUrl = true;
        }

        if (options.showDisabledIcon) {
            this.showDisabledIcon = true;
        }

        this.model = new WcmContentModel({
            wcmPath : options.wcmPath
        });

        this.listenTo(this.model, 'sync', this.render);
        this.listenTo(this.model, 'error', this._handleWcmError);

        spinnerChannel.trigger('show', {
            viewScope   : this,
            size        : 'medium'
        });

        this.model.fetch();
    },

    onRender : function onRender () {
        spinnerChannel.trigger('hide', this);

        if (this.showDisabledIcon) {
            // Apply the "disabled" class to the root
            this.ui.root.addClass('disabled');
        }

        this._updateLinks();
    },

    /**
     * Logs an error message to analytics providers and hides the spinner.
     * @param {object} model
     * @param {object} response
     * @private
     */
    _handleWcmError : function _handleWcmError(model, response) {
        var errorMessage = 'Error retrieving WCM content from ' + this.model.get('wcmPath')
            + '. Received a ' + response.status + ' status code.';

        // Stop the spinner
        spinnerChannel.trigger('hide', this);

        analyticsChannel.trigger('trackException', {
            fatal   : false,
            message : errorMessage
        });
    },

    /**
     * Manipulates links returned from WCM content. The links will be converted to "#c" links.
     * If `buildHrefUrl` is set, the utils.buildHrefURL function is ran on the href attribute value.
     * @private
     */
    _updateLinks : function _updateLinks () {
        var ctx = this;

        // Convert WCM links to "#c" links
        this.utils.convertWcmLinks(this);

        this.ui.links.each(function () {
            var link    = Backbone.$(this);
            var hasHref;

            if (ctx.showDisabledIcon) {
                // Remove the href attribute from the links
                link.removeAttr('href');
            }

            hasHref = link.is('[href]');

            if (ctx.buildHrefUrl && hasHref) {
                link.attr('href', ctx.utils.buildHrefURL(link.attr('href')));
            }

        });
    }
    
});

module.exports = WcmToutView;