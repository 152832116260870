/* global _:false */
/**
 Policy model data parser functions for Notes section
*/

import {isImplemented} from '../../../../utils/utils';

const policyNotes = {

    /**
     * A Method to sort policy notes grouped by section
     *  
     * @param  {Object} response response received from service
     * @return {object} response
     */
    sortPolicyNotesBySection (response) {

        // order to sort notes based on 'key'
        const notesSectionOrder = [ 
            'Billing',
            'Coverage', 
            'Payments',
            'Loan',
            'Investment'
        ];

        // bail out if response.policyNotes is not implemented
        if (response.policyNotes && !isImplemented(response.policyNotes)) {
            return response;
        }

        // add group sort order
        let returnNotes = _.map(response.policyNotes,
            function (val) { 
                let idx = _.indexOf(notesSectionOrder, val.section);
                // convert sortOrder to a decimal and add to group order
                // sortOrder can't be greater than 99 for this to work
                val.groupSortOrder = idx + (val.sortOrder / 100);
                return val;
            }
        );

        // now sort by group sort order
        response.policyNotes = _.sortBy(returnNotes, 'groupSortOrder');

        return response;
    }
};

module.exports = policyNotes;