/* global Backbone:false, $:false, _:false */

// load the global partials.
require('../../../partials');
require('../../../utils/hb-helpers');

var config               = require('../../../config/config');
var debugModule          = require('../../../modules/debug/debugModule').init();
var OrgsTableViewModel   = require('../viewModels/orgs-table-vm');
var spinnerChannel       = Backbone.Radio.channel('spinner');
var template             = require('../templates/orgs-table-t.hbs');
var utils                = require('../../../utils/utils');

var linkTemplate = _.template(
    '<br/><a href="<%= link %>" data-id="<%= id %>" class="<%= css %>"><%= label %></a>'
);

/**
 * Organization Hierarchy Table View
 *
 */
var OrgsTableView = Backbone.Marionette.ItemView.extend({

    template: template,

    ui : {
        lastRefreshDate             : '#orgs-table-last-refresh-date',
        orgLinks                    : '.organization-link',
        orgsTableWrapper            : '#orgsTableWrapper',
        orgsTable                   : '#orgs-table',
        producerLinks               : '.producer-link',
        responsiveTableInstruction  : '.table-responsive-instruction'
    },
    errors: {
        noPolicies          : 'No pending policies.',
        invalidProducer     : 'No organization found for <%= producerId %>.',
        systemError         : 'The system has encountered an unexpected error.'
    },
    
    events : {
        'click @ui.producerLinks' : '_retrieveProducer',
        'click @ui.orgLinks'      : '_retrieveOrganization'
    },

    /**
     * Lifecycle function
     */
    initialize : function (options) {
        var col;
        var dir;
        var length;
        var producerId = null;
        var start;

        if (options) {
            producerId = options.producerId;
            if (options.state) {
                col    = options.state.col;
                dir    = options.state.dir;
                length = options.state.length;
                start  = options.state.start;
            }
        }

        //if model is not provided, in case of displaying error message
        //there will be model data to serve 
        if (!this.options.model) {
            this.model = new OrgsTableViewModel({
                col        : col,
                dir        : dir,
                length     : length,
                producerId : producerId,
                start      : start
            });
        }
        
    },

    /**
     * Lifecycle function
     */
    onRender : function () {

        var url   = config.apiUrlRoot + 'orgs';
        var _this = this;
        
        // If producerId exists, then append that to the url
        if (this.model.has('producerId')) {
            url += '/'+ this.model.get('producerId');
        }

        if (!this.ui.orgsTable.length) {
            return false;
        }

        spinnerChannel.trigger('show', {
            viewScope : this
        });

        var dataTableOptions =  utils.getDatatableOptions(this, url);

        _.extend(dataTableOptions, {

            // show info, length control, pagination, table, info, length, pagination
            dom : 'ilptilp',
            
            language : {
                info       : 'Showing _START_ to _END_ of _TOTAL_ ' +
                    'entries for <strong>"<span></span>"</strong>',
                lengthMenu : 'Display _MENU_ records'
            },

            // 0-based start index
            displayStart : this.model.get('start'),

            bAutoWidth: false,

            order : [this.model.get('col'), this.model.get('dir')],

            // initial page length
            pageLength : this.model.get('length'),

            columns : [
                {
                    title       : 'Sub-Org',
                    data        : 'hasDownlineReports',
                    name        : 'hasDownlineReports',
                    render      : this._setSubOrgs
                },
                {
                    title       : 'Name',
                    data        : 'lexicalName',
                    name        : 'lexicalName',
                    createdCell : this._setProducerOrgsContent
                },
                {
                    title           : 'Pending Policies Number',
                    data            : null,
                    defaultContent  : '',
                    orderable   : false
                },
                {
                    title           : 'Life Single',
                    data            : null,
                    defaultContent  : '',
                    orderable   : false
                },
                {
                    title           : 'Life Annual',
                    data            : null,
                    defaultContent  : '',
                    orderable   : false
                },
                {
                    title           : 'Annuity',
                    data            : null,
                    defaultContent  : '',
                    orderable   : false
                },
                {
                    title           : 'CoB/PUA Rider',
                    data            : null,
                    defaultContent  : '',
                    orderable   : false
                }
            ],

            //work only once for initial time //unit testable
            'initComplete' : function (dataTableSettings, jsonResponse) {

                if (_this.isDestroyed) {
                    return false;
                }

                if (jsonResponse.managingProducer) {

                    //update general info section with current producer data
                    _this.trigger('updateGeneralInfo', jsonResponse.managingProducer);

                    _this.producerName = jsonResponse.managingProducer.fullName;

                    //There is no direct API to update info label, so updating it on DOM
                    $(dataTableSettings.nTableWrapper)
                        .find('.dataTables_info span').text(_this.producerName);
                }

                if (jsonResponse.lastRefreshDate) {
                    if (_this.ui.lastRefreshDate instanceof Backbone.$) { 
                        _this.ui.lastRefreshDate.text(
                            utils.formatDate(
                                jsonResponse.lastRefreshDate, 
                                'MM/DD/YYYY, hh:mm A z'
                            )
                        );
                        _this.ui.lastRefreshDate.parent('p').removeClass('hidden');
                    } else {
                        debugModule.error('OrgTableView:DT.initComplete - '+
                            '_this.ui.lastRefreshDate is not a jquery object :- '+ 
                            _this.ui.lastRefreshDate);
                    }
                } 
            }
        });

        // In order to prevent "null" from being shown in IE/Edge, an
        // empty string will be set as the defaultContent for each column.
        utils.setDatatablesDefaultContent(dataTableOptions.columns, '');
        
        var table = this.ui.orgsTable.DataTable(dataTableOptions)
        .on('xhr', function($event, dataTableSettings, jsonResponse, xhr) {

            spinnerChannel.trigger('hide', _this);
            
            var errorResponse;
            var errorMessage;
            var errorTemplate;
            var serverErrorMessage;

            // Handle any non-200 HTTP responses
            // after the readyState has been set to 4 (DONE)
            if (xhr && xhr.readyState === 4 && xhr.status !== 200) {

                errorMessage = _this.errors.systemError;
                //parsing response text to JSON
                if (xhr.responseText) {

                    //prevent uncaught error if response text is string
                    try{
                        errorResponse = JSON.parse(xhr.responseText);
                    }catch(e){
                        debugModule.warn(e);
                    }
                }
                
                if (_.isObject(errorResponse) && errorResponse.errors && errorResponse.errors[0] ) {
                    serverErrorMessage = errorResponse.errors[0].message;
                    
                    //messages received from service for invalid producer id / user
                    if (serverErrorMessage === 'No organization hierarchy found.') {

                        if (_this.model.get('producerId')) {
                            errorTemplate = _.template(_this.errors.invalidProducer);
                            errorMessage = errorTemplate({
                                producerId : _this.model.get('producerId')
                            });
                        } else {
                            errorMessage =  _this.errors.noPolicies;
                        }


                    } 
                }

                _this.trigger('error', errorMessage);
                return;
            }

            // Make sure we're getting the info back that we should expect
            if (!jsonResponse || _.isUndefined(jsonResponse.recordsTotal) || !xhr) {

                _this.trigger('error', _this.errors.systemError);
                return;
            }           

            // Handle no results being returned
            if (jsonResponse.recordsTotal === 0) {
                _this.trigger('noResults', _this.errors.noPolicies, 'info');
                return;
            }

            _this.ui.orgsTableWrapper.removeClass('hidden');
              
        }).on('draw', function ($event, dataTableSettings){            
            
            //There is no direct API to update info label, so updating it on DOM
            $(dataTableSettings.nTableWrapper).find('.dataTables_info span')
                .text(_this.producerName);
        });

        utils.bindDataTableEvents({
            dtObject            : table, 
            viewScope           : this, 
            viewName            : 'org-table-v: ', 
            spinnerChannel      : spinnerChannel,
            debugModule         : debugModule,
            addOrderListener    : true
        });

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));
    },

    _setSubOrgs : function (data, type, row, meta) {
        var htmlTeamIcon = '<i class="ace-icon fa fa-users grey"></i>';

        if (data){
            return htmlTeamIcon;
        }
        else {
            return '';
        }
    }, 


    /**
     * Set content for producer / orgs name cell
     *
     * @param {DOM} td       TD element
     * @param {string} cellData value of cell
     * @param {[type]} rowData  Data source object / array for the whole row
     * @param {number} row      DataTables' internal index for the row
     * @param {number} col      DataTables' internal index for the column
     * @private
     */
    _setProducerOrgsContent : function (td, cellData, rowData, row, col) {
        var displayValue = rowData.lexicalName ;
        var link;

        if (rowData.hasDownlineReports) {

            // Its to add query param 'as' if impersonated webID exist
            link = utils.addTargetUserQueryParamToURL(
                '#policy-manager/org/?producerOrgId='+rowData.id
            );
            displayValue = displayValue + 
                linkTemplate({
                    css   : 'organization-link',
                    id    : rowData.id,
                    label : 'Producers in Sub-Organization',
                    link  : link,
                });

            // Its to add query param 'targetuser' if impersonated webID exist
            link = utils.addTargetUserQueryParamToURL(
                '#org-policies/pending/?producerOrgId='+rowData.id
            );
            displayValue = displayValue +
                linkTemplate({
                    css   : 'text-nowrap policy-list-link oa-js-nav',
                    id    : rowData.id,
                    label : 'Policies in Sub-Organization',
                    link  : link
                });

        } else {

            // Its to add query param 'targetuser' if impersonated webID exist
            link = utils.addTargetUserQueryParamToURL(
                '#producer-policy-list/pending/?producerId='+rowData.id
            );
            displayValue = displayValue +
                linkTemplate({
                    css   : 'text-nowrap producer-link',
                    id    : rowData.id,
                    label : 'Policies',
                    link  : link
                });
        }

        Backbone.$(td).html(displayValue);
    },

    /**
     * Handle retrieval of an organization when a user clicks. This will:
     * 1) set the producerId in the view model
     * 2) trigger a 'hierarchyChage' with a new producerOrgId so parent(s) can react
     * 3) re-render the view, which will trigger a new call via DataTables for the new info
     *
     * @param $event the jQuery event object
     * @private
     */
    _retrieveOrganization : function _retrieveOrganization($event) {
        $event.preventDefault();
        var id = Backbone.$($event.currentTarget).data('id');
        this.model.set('producerId', id);
        this.trigger('hierarchyChange', { producerOrgId : id });
        this.render();
    },

    /**
     * Handle retrieval of a producer when a user clicks a "Policies" link.
     *
     * @param $event the jQuery event object
     * @private
     */
    _retrieveProducer : function _retrieveProducer($event) {
        $event.preventDefault();

        // get the id passed in the links data attribute
        var id = Backbone.$($event.currentTarget).data('id');
        this.trigger('showProducerPolicyList', { producerId : id });
    }
});

module.exports = OrgsTableView;