/* global Backbone:false, _:false */

var errorHelper         = require('../../../utils/error-helper');
var OrgsGeneralInfoView = require('./orgs-general-info-v');
var OrgsTableView       = require('./orgs-table-v');
var template            = require('../templates/orgs-t.hbs');

/**
 * Organization View
 *
 */
var OrganizationView = Backbone.Marionette.LayoutView.extend({

    template : template,

    regions : {
        generalInfo : '#general-info-region',
        orgsTable   : '#orgs-table-region'
    },

    ui : {
        generalInfo : '#general-info-region',
    },

    errors : {
        systemError : 'The system has encountered an unexpected error.'
    },

    onBeforeShow : function() {

        this.producerId    = this.options.producerId;
        this.producerOrgId = this.options.producerOrgId;

        // For the table, we only need the ID of the producer we're showing.
        // If we have the producerOrgId (drilling down to a particular user/org), use that.
        // Otherwise, go with producerId (the producer selected for the overall page).
        var selectedProducer = this.producerOrgId || this.producerId;
        var state;

        //get state to pass with orgs table view
        if (this.options.orgsState) {
            state = this.options.orgsState;
        }

        this._createGeneralInfo(selectedProducer);
        this._showOrgsTable(selectedProducer, state);
    },

    /**
     * Create a new OrgsTableView, listen to events, and show it in the 'orgsTable' region.
     *
     * @param producerId the ID of the producer to display orgs for
     * @param state the state (sort, page, etc) of the table
     * @private
     */
    _showOrgsTable : function _showOrgsTable(producerId, state) {
        var orgsTableView = new OrgsTableView({
            producerId : producerId,
            state      : state
        });

        this.listenTo(orgsTableView, 'updateGeneralInfo', this._showGeneralInfo);
        this.listenTo(orgsTableView, 'tableStateChange', this._stateChange);
        this.listenTo(orgsTableView, 'error', this._showServerMessage);
        this.listenTo(orgsTableView, 'noResults', this._showServerMessage);
        this.listenTo(orgsTableView, 'hierarchyChange', this._hierarchyChange);
        this.listenTo(orgsTableView, 'showProducerPolicyList', this._showProducerPolicyList);

        this.showChildView('orgsTable', orgsTableView);
    },

    _createGeneralInfo : function _createGeneralInfo(producerId) {
        this.orgGeneralInfoView = new OrgsGeneralInfoView({
            model : new Backbone.Model({
                id : producerId
            })
        });
        this.showChildView('generalInfo', this.orgGeneralInfoView);

        // As we already have ID with us we don't need to wait to get it from server
        // response.
        this.orgGeneralInfoView.showData({
            id : producerId
        });
    },

    /**
     * Display the General Info section for the producer in the hierarchy.
     *
     * @param data the data sent in the event
     * @private
     */
    _showGeneralInfo : function (data) {

        if (_.isObject(data)) {

            this.orgGeneralInfoView.showName(data.fullName);

            // listen for hierarchyChange event from user clicking on hierarchy path links
            this.listenTo(this.orgGeneralInfoView, 'hierarchyChange', this._hierarchyPathTraversal);
        }

        this.ui.generalInfo.removeClass('hidden');
    },

    /**
     * Clear the generalInfo view.
     * @private
     */
    _clearGeneralInfo : function _clearGeneralInfo() {
        var generalInfoView = this.getChildView('generalInfo');
        if (generalInfoView) {
            generalInfoView.destroy();
        }
        this.ui.generalInfo.addClass('hidden');
    },

    /**
     * A method to invoke parent stateChage method
     * @param  {object} changedState 
     *
     */
    _stateChange : function (changedState) {
        var newState;

        // Make sure we have an object
        if (_.isObject(changedState)) {
            newState = changedState;
        } else {
            newState = {};
        }

        // add producer ID props to make sure they are maintained in the state
        if (this.producerId) {
            newState.producerId = this.producerId;
        }

        if (this.producerOrgId) {
            newState.producerOrgId = this.producerOrgId;
        }

        this.trigger('stateChange', newState);
    },

    /**
     * When a user clicks on a link in the hierarchy path, we need to show the orgs for the
     * specified producer and handle a hierarchy change event.
     * @param changedState An object which should contain the selected producerOrgId
     * @private
     */
    _hierarchyPathTraversal : function _hierarchyPathTraversal(changedState) {
        this._showOrgsTable(changedState.producerOrgId);
        this._hierarchyChange(changedState);
    },

    /**
     * Handler for a hierarchyChange (user views someone else in the hierarchy). It will:
     * 1) set the new producerOrgId on the view
     * 2) clear the generalInfo view
     * 3) trigger hierarchyChange to parent
     *
     * @param changedState an object containing the new producerOrgId
     * @private
     */
    _hierarchyChange : function (changedState) {
        if (changedState && changedState.producerOrgId) {
            this.producerOrgId = changedState.producerOrgId;
        }
        this._clearGeneralInfo();
        this._createGeneralInfo(this.producerOrgId);
        this.trigger('hierarchyChange', changedState);
    },

    _showProducerPolicyList : function _showProducerPolicyList(changedState) {
        this.trigger('showProducerPolicyList', changedState);
    },

    /**
     * Error handling will be part of OOSO-2965
     * 
     * Show server message if results is empty or server error
     * @param  {string} message message to print
     */
    _showServerMessage : function (message, type) {
        if (!message) {
            message = this.errors.systemError;
        }

        if (!type) {
            type = 'warning';
        }

        this.showChildView('orgsTable', new OrgsTableView({
            model : new Backbone.Model({
                alertMessage : errorHelper.createAlert(message, type)
            })
        }));
    }
});

module.exports = OrganizationView;