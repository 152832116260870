// Data Layer variable renamed to gtmDataLayer following guidance at:
// https://developers.google.com/tag-manager/devguide#renaming

var config = require('../js/config/config');

window.dataLayer = window.dataLayer || [];

(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-P6HWRJJ');
