// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"text-error\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<span\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.commentTooLong : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n>"
    + container.escapeExpression(((helper = (helper = helpers.commentsLength || (depth0 != null ? depth0.commentsLength : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"commentsLength","hash":{},"data":data}) : helper)))
    + "</span>";
},"useData":true});
