// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div id=\"premium-mode-quotes\">\r\n        <h2 class=\"header\">Premium Mode Quotes</h2>\r\n        <div class=\"row\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCurrentMode : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCurrentMode : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "            <div class=\"col-md-12 premium-mode-quotes-note\">\r\n                <span class='note-pmq'>Note:</span> Modal premiums are based on the policy anniversary date and must be paid to a current date prior to a modal change\r\n            </div>\r\n        </div>\r\n    </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <div class=\"col-md-6\" >\r\n                    <div class=\"profile-user-info profile-user-info-striped\">\r\n                        <div class=\"profile-info-row\"  id=\"available-loan\">\r\n                            <div class=\"profile-info-name\">Billed "
    + alias3((helpers.convert_paymentMode || (depth0 && depth0.convert_paymentMode) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.showCurrentMode : depth0)) != null ? stack1.paymentMode : stack1),{"name":"convert_paymentMode","hash":{},"data":data}))
    + " \r\n                                "
    + ((stack1 = (helpers.is_bankdraft || (depth0 && depth0.is_bankdraft) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.showCurrentMode : depth0)) != null ? stack1.paymentMethod : stack1),{"name":"is_bankdraft","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                                (Current Mode)\r\n                            </div>\r\n                            <div class=\"profile-info-value\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.showCurrentMode : depth0)) != null ? stack1.paymentAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alternatePremiumModesList : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return " Bankdraft ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternatePremiumModesList : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.if_index_zero || (depth0 && depth0.if_index_zero) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),{"name":"if_index_zero","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                            <div class=\"col-md-6\" >\r\n                                <div class=\"profile-user-info profile-user-info-striped\">\r\n                                    <div class=\"profile-info-row\" id=\"available-loan\">\r\n                                        <div class=\"profile-info-name\">Billed "
    + alias3((helpers.convert_paymentMode || (depth0 && depth0.convert_paymentMode) || alias2).call(alias1,(depth0 != null ? depth0.paymentMode : depth0),{"name":"convert_paymentMode","hash":{},"data":data}))
    + " \r\n"
    + ((stack1 = (helpers.is_bankdraft || (depth0 && depth0.is_bankdraft) || alias2).call(alias1,(depth0 != null ? depth0.paymentMethod : depth0),{"name":"is_bankdraft","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                        </div>\r\n                                        <div class=\"profile-info-value\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.paymentAmount : depth0),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                                    </div>\r\n                                </div>\r\n                            </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                                                Bankdraft\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternatePremiumModesList : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternatePremiumModesList : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.if_index_less_than_two || (depth0 && depth0.if_index_less_than_two) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),{"name":"if_index_less_than_two","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                            <div class=\"col-md-6\" >\r\n                                <div class=\"profile-user-info profile-user-info-striped\">\r\n                                    <div class=\"profile-info-row\" id=\"available-loan\">\r\n                                        <div class=\"profile-info-name\">Billed "
    + alias3((helpers.convert_paymentMode || (depth0 && depth0.convert_paymentMode) || alias2).call(alias1,(depth0 != null ? depth0.paymentMode : depth0),{"name":"convert_paymentMode","hash":{},"data":data}))
    + "\r\n"
    + ((stack1 = (helpers.is_bankdraft || (depth0 && depth0.is_bankdraft) || alias2).call(alias1,(depth0 != null ? depth0.paymentMethod : depth0),{"name":"is_bankdraft","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                        </div>\r\n                                        <div class=\"profile-info-value\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.paymentAmount : depth0),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                                    </div>\r\n                                </div>\r\n                            </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternatePremiumModesList : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternatePremiumModesList : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.if_index_zero || (depth0 && depth0.if_index_zero) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),{"name":"if_index_zero","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternatePremiumModesList : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternatePremiumModesList : depth0),{"name":"each","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.if_index_less_than_two || (depth0 && depth0.if_index_less_than_two) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),{"name":"if_index_less_than_two","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<!-- start of Premium Mode Quotes -->\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.billingDetail : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<!-- end of Premium Mode Quotes -->";
},"useData":true});
