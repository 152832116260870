// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <form id=\"ipipeline-igo-form\" action=\"\" method=\"post\" target=\"igo\">\r\n        <input type=\"hidden\" name=\"SAMLResponse\"  />\r\n        <input type=\"hidden\" name=\"RelayState\" />\r\n    </form>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"#\" data-link-type=\"caresolutions\" id=\"care-solutions-products-link\" class=\"gray-60\">\r\n            Only Care Solutions <span class=\"text-nowrap\">Products<i class=\"ace-icon fa fa-external-link gray-60\"></i></span>\r\n		</a>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "        <div class=\"alert alert-warning alert-message\">\r\n            <button type=\"button\" class=\"close\" data-dismiss=\"alert\">\r\n                <i class=\"ace-icon fa fa-times\"></i>\r\n            </button>   \r\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.iGoErrorMessage || (depth0 != null ? depth0.iGoErrorMessage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"iGoErrorMessage","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\r\n        </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <p>Find \r\n    <a href=\"#\" data-link-type=\"flexUnderwritingResources\" id=\"flex-underwriting-resources-link\" target=\"_blank\">\r\n		<span class=\"text-nowrap\">OneAmerica Flex Underwriting <i class=\"ace-icon fa fa-external-link oa-banner-blue\"></i></span>\r\n	</a>\r\n    <br class=\"visible-lg\">tools and resources\r\n    </p>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.showDisabled : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n<div class=\"center "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDisabled : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"> \r\n    <span class=\"fa-stack laptop fa-2x\"> \r\n        <i class=\"fa fa-laptop oa-lake fa-stack-2x\"></i> \r\n        <i class=\"fa fa-list-ul oa-lake fa-inverse fa-stack-1x\"></i> \r\n    </span>\r\n    <h4>\r\n        <a href=\"#\" data-link-type=\"eapp\" id=\"eapp-illustration-link\" >\r\n			Sales <span class=\"text-nowrap\">Connection<i class=\"ace-icon fa fa-external-link oa-banner-blue\"></i></span>\r\n		</a>	\r\n    </h4>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCareSolutionsProductsLink : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.iGoErrorMessage : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n    <p>Accelerate your business with <br class=\"visible-lg\">online eApps & Illustrations</p>\r\n    <p class=\"small\">\r\n        <a href=\"#\" data-link-type=\"practice\" id=\"practice-center-link\">\r\n			Sales Connection Practice <span class=\"text-nowrap\">Center<i class=\"ace-icon fa fa-external-link oa-banner-blue\"></i></span>\r\n		</a>\r\n    </p>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showFlexUnderwritingResourcesLink : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\r\n";
},"useData":true});
