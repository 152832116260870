/* global Backbone:false */
/* eslint-disable max-len */
/**
 *
 * The WcmStructureModel contains a property named 'structure' which is an array of elements to be
 * incorporated into the side navigation.
 *
 * {
 *     displayText : String,
 *                   Required,
 *                   The label for the item in the menu
 *
 *     icon        : String,
 *                   Optional,
 *                   the name of a class or Font Awesome icon
 *                   (http://fortawesome.github.io/Font-Awesome/icons/)
 *
 *     subItems    : Array,
 *                   Required for branch-level items,
 *                   an array containing more nodes in the menu structure.
 *
 *     isExternal  : Boolean,
 *                   Optional,
 *                   When true, indicates that the menu item should open in a new window. An
 *                   icon which indicates the link as external will be placed after the link text.
 *
 *     link        : String,
 *                   Required for leaf-level items,
 *                   The uriEncoded name used in the URL. This is preferred over the ID because it
 *                   is more easily readable, making bookmarks and web history more useful for the
 *                   end user. This will also serve as the identifier for routing within the app.
 *
 *     capability  : Object,
 *                   Required for leaf-level items,
 *                   the capability needed for this item to show in the side nav
 * }
 *
 *
 * Hypothetical app flow when the user clicks a WCM link in the sidebar (the URL hashes will be in
 * the form "#content/readable-link-name"):
 *
 * 1) Router invokes handler for "content" (which will be added to the link hash by the utility that
 *    integrates this structure into the sidebar menu).
 *
 * 2) "content" handler parses the remainder of the URL hash to get the link name
 *
 * 3) handler looks up the WCM ID based on the link name. For this, we will need to build a lookup
 *    for the ID based on the link. This will be a similar lookup to that used by the router for
 *    dynamic portions of the app. See OOSO-2309 for information.
 *
 * 4) handler creates a wcm-content-v to display the content for the ID found above in the content
 *    area.
 */

import PathwayMenuStructureModel from './pathwayMenuStructure-m';

const WcmStructureModel = Backbone.Model.extend({
    defaults: {
        structure: [
            {
                icon        : 'fa fa-university',
                displayText : 'OA Securities',
                link        : 'c/oas',
                capability  : {
                    forSidebar : [{
                        'OAS_View' : true
                    }]
                },
                activeFor   : [
                    'c/oas/bd-communications',
                    'c/oas/docupace',
                    'c/oas/ria',
                    'c/oas/ria/tools-resources',
                    'c/oas/ria/submit-business',
                    'c/oas/products',
                    'c/oas/products/mutual-funds',
                    'c/oas/products/variable-annuities',
                    'c/oas/products/group-variable-annuities',
                    'c/oas/products/life/variable-universal-life',
                    'c/oas/products/529-college-savings-plans',
                    'c/oas/products/general-securities',
                    'c/oas/products/unit-investment-trusts',
                    'c/oas/products/alternative-investments',
                    'c/oas/technology',
                    'c/oas/business-resources',
                    'c/oas/business-resources/same-day-processing',
                    'c/oas/business-resources/deposits-funds-availability',
                    'c/oas/business-resources/client-onboarding',
                    'c/oas/business-resources/client-onboarding-tips',
                    'c/oas/business-resources/commissions-grid',
                    'c/oas/business-resources/margin-trading',
                    'c/oas/business-resources/client-fees',
                    'c/oas/advisory-ria',
                    'c/oas/direct-business',
                    'c/oas/brokerage-business',
                    'c/oas/knowledge-center'
                ]
            },{
                icon        : 'fa fa-bell',
                displayText : 'Compliance',
                link        : 'c/compliance',
                capability  : {
                    forSidebar : [{
                        'Home_Office': true
                    }, {
                        'WCM_Retail_Manager': true
                    }, {
                        'WCM_Retail_Producer': true
                    }, {
                        'WCM_IB_Manager': true
                    }, {
                        'WCM_IB_Producer': true
                    }, {
                        'WCM_CS_Manager': true
                    }, {
                        'WCM_CS_Producer': true
                    }, {
                        'WCM_OAS_Representative': true
                    }]
                },
                activeFor   : [
                    'c/compliance/updates',
                    'c/compliance/updates-archives',
                    'c/compliance/training'
                ]
            }, {
                displayText : 'Compliance Updates',
                link        : 'c/compliance/updates',
                capability  : {
                    forPageAccess: {
                        'Home_Office': true
                        ,'WCM_Retail_Manager': true
                        ,'WCM_Retail_Producer': true
                        ,'WCM_IB_Manager': true
                        ,'WCM_IB_Producer': true
                        ,'WCM_CS_Manager': true
                        ,'WCM_CS_Producer': true
                        ,'WCM_OAS_Representative': true
                    }
                },
            }, {
                displayText : 'Compliance Updates Archives',
                link        : 'c/compliance/updates-archives',
                capability  : {
                    forPageAccess: {
                        'Home_Office': true
                        ,'WCM_Retail_Manager': true
                        ,'WCM_Retail_Producer': true
                        ,'WCM_IB_Manager': true
                        ,'WCM_IB_Producer': true
                        ,'WCM_CS_Manager': true
                        ,'WCM_CS_Producer': true
                        ,'WCM_OAS_Representative': true
                    }
                },
            }, {
                displayText : 'Compliance Training',
                link        : 'c/compliance/training',
                capability  : {
                    forPageAccess: {
                        'Home_Office': true
                        ,'WCM_Retail_Manager': true
                        ,'WCM_Retail_Producer': true
                        ,'WCM_IB_Manager': true
                        ,'WCM_IB_Producer': true
                        ,'WCM_CS_Manager': true
                        ,'WCM_CS_Producer': true
                        ,'WCM_OAS_Representative': true
                    }
                },
            }, {
                displayText : 'Broker/Dealer Communications',
                link        : 'c/oas/bd-communications',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Docupace',
                link        : 'c/oas/docupace',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Registered Investment Advisor',
                link        : 'c/oas/ria',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'RIA Tools and Resources',
                link        : 'c/oas/ria/tools-resources',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'How to Submit RIA Business',
                link        : 'c/oas/ria/submit-business',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'OAS Nonproprietary Products',
                link        : 'c/oas/products',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Mutual Funds',
                link        : 'c/oas/products/mutual-funds',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Variable Annuities',
                link        : 'c/oas/products/variable-annuities',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Group Variable Annuities',
                link        : 'c/oas/products/group-variable-annuities',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Variable Universal Life',
                link        : 'c/oas/products/life/variable-universal-life',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : '529 College Savings Plans',
                link        : 'c/oas/products/529-college-savings-plans',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'General Securities',
                link        : 'c/oas/products/general-securities',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Unit Investment Trusts',
                link        : 'c/oas/products/unit-investment-trusts',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Alternative Investments',
                link        : 'c/oas/products/alternative-investments',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Technology',
                link        : 'c/oas/technology',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Business Resources',
                link        : 'c/oas/business-resources',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Advisory Ria',
                link        : 'c/oas/advisory-ria',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Direct Business',
                link        : 'c/oas/direct-business',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Brokerage Business',
                link        : 'c/oas/brokerage-business',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Knowledge Center',
                link        : 'c/oas/knowledge-center',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Same-day Processing Cutoffs',
                link        : 'c/oas/business-resources/same-day-processing',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Account Deposits and Funds',
                link        : 'c/oas/business-resources/deposits-funds-availability',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'NetX360 Client Onboarding',
                link        : 'c/oas/business-resources/client-onboarding',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'NetX360 Client Onboarding Tips',
                link        : 'c/oas/business-resources/client-onboarding-tips',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Pershing Commissions Grid',
                link        : 'c/oas/business-resources/commissions-grid',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            }, {
                displayText : 'Pershing Margin Trading',
                link        : 'c/oas/business-resources/margin-trading',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            },{
                displayText : 'Schedule of Miscellaneous Account and Service Fees',
                link        : 'c/oas/business-resources/client-fees',
                capability  : {
                    forPageAccess : {
                        'OAS_View' : true
                    }
                }
            },
            {
                icon        : 'fa fa-list',
                displayText : 'Products',
                subItems    : [
                    {
                        icon        : '',
                        displayText : 'Care Solutions',
                        subItems    : [
                            {
                                icon        : 'fa fa-arrow-right',
                                displayText : 'Annuity Care',
                                capability  : {
                                    forSidebar : [{
                                        'Home_Office': true
                                    }, {
                                        'WCM_Retail_Manager': true
                                    }, {
                                        'WCM_Retail_Producer': true
                                    }, {
                                        'WCM_IB_Manager': true
                                    }, {
                                        'WCM_IB_Producer': true
                                    }, {
                                        'WCM_CS_Manager': true
                                    }, {
                                        'WCM_CS_Producer': true
                                    }]
                                },
                                link        : 'c/product-pages/annuity-care'
                            }, {
                                icon        : 'fa fa-arrow-right',
                                displayText : 'Asset Care',
                                capability  : {
                                    forSidebar : [{
                                        'Home_Office': true
                                    }, {
                                        'WCM_Retail_Manager': true
                                    }, {
                                        'WCM_Retail_Producer': true
                                    }, {
                                        'WCM_IB_Manager': true
                                    }, {
                                        'WCM_IB_Producer': true
                                    }, {
                                        'WCM_CS_Manager': true
                                    }, {
                                        'WCM_CS_Producer': true
                                    }]
                                },
                                link        : 'c/product-pages/assetcare',
                                activeFor   : [
                                    'c/product-pages/asset-care',
                                    'c/product-pages/assetcare/asset-care-riders'
                                ]

                            }, {
                                displayText : 'Asset-Care',
                                capability  : {
                                    forPageAccess : {
                                        'Home_Office': true,
                                        'WCM_Retail_Manager': true,
                                        'WCM_Retail_Producer': true,
                                        'WCM_IB_Manager': true,
                                        'WCM_IB_Producer': true,
                                        'WCM_CS_Manager': true,
                                        'WCM_CS_Producer': true
                                    }
                                },
                                link        : 'c/product-pages/asset-care'
                            },
                            {
                                displayText: 'New Asset Care Riders Available in July 2019',
                                capability: {
                                    forPageAccess: {
                                        'Home_Office': true,
                                        'WCM_Retail_Manager': true,
                                        'WCM_Retail_Producer': true,
                                        'WCM_IB_Manager': true,
                                        'WCM_IB_Producer': true,
                                        'WCM_CS_Manager': true,
                                        'WCM_CS_Producer': true
                                    }
                                },
                                link: 'c/product-pages/assetcare/asset-care-riders'

                            }

                        ]
                    }, {
                        icon        : '',
                        displayText : 'Term Conversions',
                        activeFor   : [
                            'c/product-pages/life/term-conversions/life-events',
                            'c/product-pages/life/term-conversions/paperwork',
                            'c/product-pages/life/term-conversions/credit',
                            'c/product-pages/life/term-conversions/eligibility',
                            'c/product-pages/life/term-conversions/underwriting',
                            'c/product-pages/life/term-conversions/asset-care'
                        ],
                        capability  : {
                            forSidebar: [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_IB_Manager': true
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/product-pages/life/term-conversions'
                    }, {
                        displayText : 'Life Events',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/life/term-conversions/life-events'
                    }, {
                        displayText : 'Paperwork',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/life/term-conversions/paperwork'
                    }, {
                        displayText : 'Term Conversion Credit',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/life/term-conversions/credit'
                    }, {
                        displayText : 'Term Conversion Eligibility',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/life/term-conversions/eligibility'
                    }, {
                        displayText : 'Term Conversion Underwriting',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/life/term-conversions/underwriting'
                    }, {
                        displayText : 'Term Conversion to Asset Care',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/life/term-conversions/asset-care'
                    }, {
                        icon        : '',
                        displayText : 'Term Life',
                        activeFor   : [
                            'c/product-pages/life/american-protector-plus',
                        ],
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_IB_Manager': true
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/product-pages/life/term-life'
                    }, {
                        icon        : '',
                        displayText : 'Variable Universal Life',
                        activeFor   : [
                            'c/product-pages/life/variable-universal-life',
                        ],
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_OAS_Representative': true
                            }]
                        },
                        link        : 'c/product-pages/life/variable-universal-life'
                    }, {
                        displayText : 'American Protector Plus',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/life/american-protector-plus'
                    }, {
                        icon        : '',
                        displayText : 'Whole Life',
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_IB_Manager': true
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/product-pages/life/whole-life'
                    }, {
                        icon        : '',
                        displayText : 'Fixed Annuities',
                        subItems    : [
                            {
                                icon        : 'fa fa-arrow-right',
                                displayText : 'Freedom Builder',
                                capability  : {
                                    forSidebar : [{
                                        'Home_Office': true
                                    }, {
                                        'WCM_Retail_Manager': true
                                    }, {
                                        'WCM_Retail_Producer': true
                                    }, {
                                        'WCM_IB_Manager': true
                                    }, {
                                        'WCM_IB_Producer': true
                                    }]
                                },
                                link        : 'c/product-pages/fixed-annuities/freedom-builder'
                            }, {
                                icon        : 'fa fa-arrow-right',
                                displayText : 'Secure Income Stream',
                                capability  : {
                                    forSidebar : [{
                                        'Home_Office': true
                                    }, {
                                        'WCM_Retail_Manager': true
                                    }, {
                                        'WCM_Retail_Producer': true
                                    }, {
                                        'WCM_IB_Manager': true
                                    }, {
                                        'WCM_IB_Producer': true
                                    }]
                                },
                                link        : 'c/product-pages/fixed-annuities/secure-income-stream'
                            }, {
                                icon        : 'fa fa-arrow-right',
                                displayText : 'Single Premium Immediate Annuity',
                                capability  : {
                                    forSidebar : [{
                                        'Home_Office': true
                                    }, {
                                        'WCM_Retail_Manager': true
                                    }, {
                                        'WCM_Retail_Producer': true
                                    }, {
                                        'WCM_IB_Manager': true
                                    }, {
                                        'WCM_IB_Producer': true
                                    }]
                                },
                                link        : 'c/product-pages/fixed-annuities/single-premium-immediate-annuity'
                            }
                        ]
                    }, {
                        icon        : '',
                        displayText : 'Service Only',
                        activeFor   : [
                            'c/product-pages/service-only/atp3-term',
                            'c/product-pages/service-only/term-2002',
                            'c/product-pages/service-only/century',
                            'c/product-pages/service-only/lifestyle-freedom-ul',
                            'c/product-pages/service-only/optimum-plus',
                            'c/product-pages/service-only/optimum-accumulator',
                            'c/product-pages/service-only/american-accumulator',
                            'c/product-pages/service-only/asset-care-single-premium-vul',
                            'c/product-pages/service-only/asset-care-last-survivor-spvul',
                            'c/product-pages/service-only/flexible-premium-vul',
                            'c/product-pages/service-only/last-survivor-flexible-premium-vul',
                            'c/product-pages/service-only/last-survivor-single-premium-vul',
                            'c/product-pages/service-only/single-premium-vul',
                            'c/product-pages/service-only/legacy-2004',
                            'c/product-pages/service-only/liberty',
                            'c/product-pages/service-only/accumannuity-ii',
                            'c/product-pages/service-only/secure5',
                            'c/product-pages/service-only/securechoice',
                            'c/product-pages/service-only/directpoint',
                            'c/product-pages/service-only/starpoint',
                            'c/product-pages/service-only/voyage-protector',
                            'c/product-pages/service-only/portfolio-descriptions',
                            'c/product-pages/service-only/legacy-121-liberty-select'
                        ],
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_IB_Manager': true
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/product-pages/service-only'
                    }, {
                        displayText : 'ATP3',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/atp3-term'
                    }, {
                        displayText : 'Term 2002',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/term-2002'
                    }, {
                        displayText : 'Century',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/century'
                    }, {
                        displayText : 'LifeStyle Freedom UL',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/lifestyle-freedom-ul'
                    }, {
                        displayText : 'Optimum Plus',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/optimum-plus'
                    }, {
                        displayText : 'American Accumulator VUL',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/american-accumulator'
                    }, {
                        displayText : 'Optimum Accumulator',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/optimum-accumulator'
                    }, {
                        displayText : 'Asset-Care Single Premium VUL',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/asset-care-single-premium-vul'
                    }, {
                        displayText : 'Asset-Care Last Survivor Single Premium VUL',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/asset-care-last-survivor-spvul'
                    }, {
                        displayText : 'Flexible Premium VUL',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/flexible-premium-vul'
                    }, {
                        displayText : 'Last Survivor Flexible Premium VUL',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/last-survivor-flexible-premium-vul'
                    }, {
                        displayText : 'Last Survivor Single Premium VUL',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/last-survivor-single-premium-vul'
                    }, {
                        displayText : 'Single Premium VUL',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/single-premium-vul'
                    }, {
                        displayText : 'Legacy 2004',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/legacy-2004'
                    }, {
                        displayText : 'Liberty',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/liberty'
                    }, {
                        displayText : 'AccumAnnuity II',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/accumannuity-ii'
                    }, {
                        displayText : 'Secure5',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/secure5'
                    }, {
                        displayText : 'SecureChoice',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/securechoice'
                    }, {
                        displayText : 'DirectPoint',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/directpoint'
                    }, {
                        displayText : 'StarPoint',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/starpoint'
                    }, {
                        displayText : 'Voyage Protector',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/voyage-protector'
                    }, {
                        displayText : 'Portfolio Descriptions',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/portfolio-descriptions'

                    },
                    {
                        displayText : 'Legacy, Legacy 121 and Liberty Select',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/product-pages/service-only/legacy-121-liberty-select'

                    },{
                        icon        : '',
                        displayText : 'Interest Rates',
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_IB_Manager': true
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/interest-rates/Content-Retail'
                    },{
                        icon        : '',
                        displayText : 'Interest Rates',
                        capability  : {
                            forSidebar: [{
                                'WCM_CS_Manager': true,
                                'Home_Office': false,
                                'WCM_Retail_Manager': false,
                                'WCM_Retail_Producer': false,
                                'WCM_IB_Manager': false,
                                'WCM_IB_Producer': false
                            }, {
                                'WCM_CS_Producer': true,
                                'Home_Office': false,
                                'WCM_Retail_Manager': false,
                                'WCM_Retail_Producer': false,
                                'WCM_IB_Manager': false,
                                'WCM_IB_Producer': false
                            }, {
                                'WCM_CS_Bank_Manager': true,
                                'Home_Office': false,
                                'WCM_Retail_Manager': false,
                                'WCM_Retail_Producer': false,
                                'WCM_IB_Manager': false,
                                'WCM_IB_Producer': false
                            }, {
                                'WCM_CS_Bank_Producer': true,
                                'Home_Office': false,
                                'WCM_Retail_Manager': false,
                                'WCM_Retail_Producer': false,
                                'WCM_IB_Manager': false,
                                'WCM_IB_Producer': false
                            }]
                        },
                        link        : 'c/interest-rates/Content-Care-Solutions'
                    }
                ]
            },
            {
                icon        : 'fa fa-bullhorn',
                displayText : 'Campaigns',
                subItems    : [
                    {
                        icon        : '',
                        displayText : 'Relationship Roadmap',                       
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_IB_Manager': true
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/campaigns/relationship-roadmap'
                    },
                    {
                        icon        : '',
                        displayText : 'Virtual Connections',                       
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_IB_Manager': true
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/campaigns/virtual-connections'
                    },
                    {
                        icon        : '',
                        displayText : 'Whole Life Campaign',                       
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true
                            }, {
                                'WCM_Retail_Manager': true
                            }, {
                                'WCM_Retail_Producer': true
                            }, {
                                'WCM_IB_Manager': true
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/campaigns/whole-life'
                    }
                ]
            },
            {
                icon        : 'fa fa-calendar-check-o',
                displayText : 'Underwriting',
                subItems    : [
                    {
                        icon        : 'fa fa-play-circle',
                        displayText : 'Traditional Life',
                        activeFor   : [
                            'c/underwriting/consider-the-source',
                            'c/underwriting/more-standard-policies',
                            'c/underwriting/military-guidelines',
                            'c/underwriting/programs/preferred-point-system',
                            'c/underwriting/cover-letter',
                            'c/underwriting/spo-rider',
                            'c/underwriting/programs/professional-athletes',
                            'c/underwriting/vendors',
                            'c/underwriting/foreign-travel',
                            'c/underwriting/programs/foreign-nationals'
                        ],
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true,
                            }, {
                                'WCM_Retail_Manager': true,
                            }, {
                                'WCM_Retail_Producer': true,
                            }, {
                                'WCM_IB_Manager': true,
                            }, {
                                'WCM_IB_Producer': true
                            }]
                        },
                        link        : 'c/underwriting/traditional-life'
                    }, {
                        icon        : 'fa fa-play-circle',
                        displayText : 'Care Solutions',
                        activeFor   : [
                            'c/underwriting/asset-care-height-weight',
                            'c/underwriting/cover-letter',
                            'c/underwriting/vendors'
                        ],
                        capability  : {
                            forSidebar : [{
                                'Home_Office': true,
                            }, {
                                'WCM_Retail_Manager': true,
                            }, {
                                'WCM_Retail_Producer': true,
                            }, {
                                'WCM_IB_Manager': true,
                            }, {
                                'WCM_IB_Producer': true,
                            }, {
                                'WCM_CS_Manager': true,
                            }, {
                                'WCM_CS_Producer': true,
                            }, {
                                'WCM_CS_Bank_Manager': true,
                            }, {
                                'WCM_CS_Bank_Producer': true
                            }]
                        },
                        link        : 'c/underwriting/care-solutions'
                    }, {
                        displayText : 'Consider the Source program',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/underwriting/consider-the-source'
                    }, {
                        displayText : 'More Standard Policies (MSP) Underwriting Program',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/underwriting/more-standard-policies'
                    }, {
                        displayText : 'underwriting guidelines for military personnel',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/underwriting/military-guidelines'
                    }, {
                        displayText : 'Get details about suggested information to include',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true,
                                'WCM_CS_Manager': true,
                                'WCM_CS_Producer': true,
                                'WCM_CS_Bank_Manager': true,
                                'WCM_CS_Bank_Producer': true
                            }
                        },
                        link        : 'c/underwriting/cover-letter'
                    }, {
                        displayText : 'preferred point system',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/underwriting/programs/preferred-point-system'
                    }, {
                        displayText : 'underwriting program for professional athletes',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/underwriting/programs/professional-athletes'
                    }, {
                        displayText : 'Survivor Purchase Option (SPO) Rider',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/underwriting/spo-rider'
                    }, {
                        displayText : 'See our vendor information',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true,
                                'WCM_CS_Manager': true,
                                'WCM_CS_Producer': true,
                                'WCM_CS_Bank_Manager': true,
                                'WCM_CS_Bank_Producer': true
                            } 
                        },
                        link        : 'c/underwriting/vendors'
                    }, {
                        displayText : 'foreign travel',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/underwriting/foreign-travel'
                    }, {
                        displayText : 'Foreign National',
                        capability  : {
                            forPageAccess : {
                                'Home_Office' : true,
                                'WCM_Retail_Manager' : true,
                                'WCM_Retail_Producer' : true,
                                'WCM_IB_Manager' : true,
                                'WCM_IB_Producer' : true
                            }
                        },
                        link        : 'c/underwriting/programs/foreign-nationals'
                    }, {
                        displayText : 'Refer to the chart',
                        capability  : {
                            forPageAccess : {
                                'Home_Office': true,
                                'WCM_Retail_Manager': true,
                                'WCM_Retail_Producer': true,
                                'WCM_IB_Manager': true,
                                'WCM_IB_Producer': true,
                                'WCM_CS_Manager': true,
                                'WCM_CS_Producer': true,
                                'WCM_CS_Bank_Manager': true,
                                'WCM_CS_Bank_Producer': true
                            }
                        },
                        link        : 'c/underwriting/asset-care-height-weight'
                    }
                ]
            },
            // Add "The Pathway" menu item
            new PathwayMenuStructureModel().get('structure')[0],
            {
                icon        : 'fa fa-users',
                displayText : 'Contact List',
                link        : 'c/contact-lists/contact-list-home-office',
                capability  : {
                    forSidebar : [{
                        'Home_Office': true
                    }]
                }
            },
            {
                icon        : 'fa fa-users',
                displayText : 'Contact List',
                link        : 'c/contact-lists/contact-list-retail',
                capability  : {
                    forSidebar: [{
                        'WCM_Retail_Manager': true,
                        'Home_Office': false
                    }, {
                        'WCM_Retail_Producer': true,
                        'Home_Office': false,
                        'WCM_Retail_Manager': false
                    }]
                }
            },
            {
                icon        : 'fa fa-users',
                displayText : 'Contact List',
                link        : 'c/contact-lists/contact-list-ib',
                capability  : {
                    forSidebar : [{
                        'WCM_IB_Manager': true,
                        'Home_Office': false,
                        'WCM_Retail_Manager': false,
                        'WCM_Retail_Producer': false
                    }, {

                        'WCM_IB_Producer': true,
                        'Home_Office': false,
                        'WCM_Retail_Manager': false,
                        'WCM_Retail_Producer': false,
                        'WCM_IB_Manager': false
                    }]
                }
            },
            {
                icon        : 'fa fa-users',
                displayText : 'Contact List',
                link        : 'c/contact-lists/contact-list-care-solutions',
                capability  : {
                    forSidebar : [{
                        'WCM_CS_Manager': true,
                        'Home_Office': false,
                        'WCM_Retail_Manager': false,
                        'WCM_Retail_Producer': false,
                        'WCM_IB_Manager': false,
                        'WCM_IB_Producer': false
                    }, {
                        'WCM_CS_Producer': true,
                        'Home_Office': false,
                        'WCM_Retail_Manager': false,
                        'WCM_Retail_Producer': false,
                        'WCM_IB_Manager': false,
                        'WCM_IB_Producer': false,
                        'WCM_CS_Manager':false
                    }, {
                        'WCM_CS_Bank_Manager': true,
                        'Home_Office': false,
                        'WCM_Retail_Manager': false,
                        'WCM_Retail_Producer': false,
                        'WCM_IB_Manager': false,
                        'WCM_IB_Producer': false,
                        'WCM_CS_Manager': false,
                        'WCM_CS_Producer': false
                    }, {
                        'WCM_CS_Bank_Producer': true,
                        'Home_Office': false,
                        'WCM_Retail_Manager': false,
                        'WCM_Retail_Producer': false,
                        'WCM_IB_Manager': false,
                        'WCM_IB_Producer': false,
                        'WCM_CS_Manager': false,
                        'WCM_CS_Producer': false,
                        'WCM_CS_Bank_Manager': false
                    }]
                }
            }, {
                icon        : 'fa fa-question-circle',
                displayText : 'FAQ',
                activeFor   : [
                    'c/faq/oso',
                    'c/faq/oso-how-to-training',
                    'c/faq/oso-how-to-training/registration',
                    'c/faq/oso-how-to-training/oso-registration/steps',
                    'c/faq/oso-how-to-training/delegates/add-delete-edit',
                    'c/faq/oso-how-to-training/delegates/register',
                    'c/faq/oso-how-to-training/delegates/access',
                    'c/faq/oso-how-to-training/find-info/business',
                    'c/faq/oso-how-to-training/tech-tips/pop-up-blocker',
                    'c/faq/oso-how-to-training/performance-center-my-profile',
                    'c/faq/oso-vintage-retirement',
                    'c/faq/underwriting',
                    'c/faq/commissions',
                    'c/faq/commissions/view',
                    'c/faq/oams',
                    'c/faq/benefits'
                ],
                capability  : {
                    forSidebar : [{
                        'Home_Office': true,
                    }, {
                        'WCM_Retail_Manager': true,
                    }, {
                        'WCM_Retail_Producer': true,
                    }, {
                        'WCM_IB_Manager': true,
                    }, {
                        'WCM_IB_Producer': true,
                    }, {
                        'WCM_CS_Manager': true,
                    }, {
                        'WCM_CS_Producer': true,
                    }, {
                        'WCM_CS_Bank_Manager': true,
                    }, {
                        'WCM_CS_Bank_Producer': true
                    }]
                },
                link        : 'c/faq'
            }, {
                displayText : 'OSO FAQ',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso'
            }, {
                displayText : 'Training FAQ',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training'
            }, {
                displayText : 'OSO Registration',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training/registration'
            }, {
                displayText : 'Registration Steps',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training/oso-registration/steps'
            }, {
                displayText : 'Add, Delete or Edit Delegates',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training/delegates/add-delete-edit'
            }, {
                displayText : 'Register as a Delegate',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training/delegates/register'
            }, {
                displayText : 'Access OneSource Online as a Delegate',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training/delegates/access'
            }, {
                displayText : 'View Pending and Inforce Business',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training/find-info/business'
            }, {
                displayText : 'Disable Pop-up Blockers',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training/tech-tips/pop-up-blocker'
            }, {
                displayText : 'Performance Center and My Profile',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-how-to-training/performance-center-my-profile'
            }, {
                displayText : 'Vintage Retirement FAQ',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/oso-vintage-retirement'
            }, {
                displayText : 'Underwriting FAQ',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/underwriting'
            }, {
                displayText : 'Commissions FAQ',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/commissions'
            }, {
                displayText : 'View Commissions',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/faq/commissions/view'
            }, {
                displayText : 'OA Marketing Store FAQs',
                capability  : {
                    forPageAccess : {
                        'Home_Office' : true,
                        'WCM_Retail_Manager' : true,
                        'WCM_Retail_Producer' : true,
                        'WCM_IB_Manager' : true,
                        'WCM_IB_Producer' : true
                    }
                },
                link        : 'c/faq/oams'
            }, {
                displayText : 'Benefits',
                capability  : {
                    forPageAccess : {
                        'Home_Office' : true,
                        'WCM_Retail_Manager' : true,
                        'WCM_Retail_Producer' : true
                    }
                },
                link        : 'c/faq/benefits'
            }, {
                displayText : 'Emergency',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/ep/oso-1'
            },{
                displayText : 'Emergency',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/ep/oso-2'
            },{
                displayText : 'Emergency',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/ep/oso-3'
            },{
                displayText : 'Emergency',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/ep/oso-4'
            },{
                displayText : 'Emergency',
                capability  : {
                    forPageAccess : {
                        'Home_Office': true,
                        'WCM_Retail_Manager': true,
                        'WCM_Retail_Producer': true,
                        'WCM_IB_Manager': true,
                        'WCM_IB_Producer': true,
                        'WCM_CS_Manager': true,
                        'WCM_CS_Producer': true,
                        'WCM_CS_Bank_Manager': true,
                        'WCM_CS_Bank_Producer': true
                    }
                },
                link        : 'c/ep/oso-5'
            },

            // News landing page and news articles don't appear in the left nav
            // and even though the future plan is that nothing in the nav should
            // be highlighted when viewing the news page, for now we're highlighting
            // the Home link.
            {
                displayText : 'News Landing Page',
                capability  : {
                    forPageAccess : {
                        'WCM_Retail_Producer' : true,
                        'WCM_Retail_Manager' : true,
                        'WCM_IB_Producer' : true,
                        'WCM_IB_Manager' : true,
                        'WCM_CS_Producer' : true,
                        'WCM_CS_Manager' : true,
                        'Home_Office' : true
                    }
                },
                link : 'c/news'
            }, {
                displayText : '',
                capability : { forPageAccess: { 'all': true }},
                isSiteArea : true,
                link : 'c/news/articles/**'
            },

            {
                icon        : 'fa fa-comment',
                displayText : 'Feedback',
                link        : 'https://www.surveymonkey.com/r/OSO-Survey',
                isExternal  : true,
                capability  : {
                    forSidebar : [{
                        all : true
                    }]
                }                    
            },

            {
                displayText : 'Annual Report',
                capability  : {
                    forPageAccess : {
                        'Home_Office' : true,
                        'WCM_Retail_Producer' : true,
                        'WCM_Retail_Manager' : true,
                        'WCM_IB_Producer' : true,
                        'WCM_IB_Manager' : true,
                        'WCM_CS_Producer' : true,
                        'WCM_CS_Manager' : true                     
                    }
                },
                link : 'c/touts/homepage/education-and-practice-development/news-tout/annual-report'
            }
        ]
    }
});

module.exports = WcmStructureModel;
