/* global Backbone:false */
/**
 * This is a view used to display the total file size for the Requirement Submission form.
 * The view is used to allow this data to display without re-rendering the actual form which
 * will remove any previously-attached files.
 */
import template                           from '../templates/total-file-size-t.hbs';
import RequirementSubmissionFormViewModel from '../viewModels/requirement-submission-form-vm';

// add handlebar helpers
import '../../../utils/hb-helpers';

const FileSizeView = Backbone.Marionette.ItemView.extend({

    errors   : {
        invalidOptions : 'Options passed to FileSizeView must contain either a policyId or a model'
    },

    template : template,

    /**
     * Initialize the view.
     *
     * @param {Object=} options Optional parameter which contains the model to use. If not
     * supplied, a model will be created.
     */
    initialize (options) {

        if (!options.model && !options.policyId) {
            throw new Error(this.errors.invalidOptions);

        } else if (options.model) {
            this.model = options.model;

        } else {
            this.model = new RequirementSubmissionFormViewModel({
                policyId : options.policyId
            });
        }

        // respond to a change in the model by rendering
        this.listenTo(this.model, 'change:totalFileSizeInBytes', this.render);
        this.listenTo(this.model, 'change:serviceFileErrorText', this.render);
    }
});

module.exports = FileSizeView;