/* global require:false, _:false */

/**
* Extending Performance center dial chart Base view for Life Lives
*/

var utils  = require('../../../utils/utils');
var PerformanceCenterDialBaseView = require('./performance-center-dial-base-v');
var performanceCenterDialLifeLivesTemplate 
    = require('../templates/performance-center-dial-lifelives-apr-t.hbs');

var PerformanceCenterDialLifeLivesView = PerformanceCenterDialBaseView.extend({
    template: performanceCenterDialLifeLivesTemplate,
    
    // Add the bonus milestone overlays to the prototype's hash
    ui: _.extend({}, PerformanceCenterDialBaseView.prototype.ui, {
        bonusLevelMarkers: 'div[class^="overlay"]'
    }),

    buildDialChartOptions: function () {

        //set bonus level
        this.model.set(
            'bonusLevel',
            this.getLifeLivesBonusLevel(this.model.get('lifeLives'))
        );

        //get sign
        var lifeLivesSign = utils.signOfNumber(this.model.get('totalSold'));

        //check mark
        if (this.model.get('totalSold') >= 200) {
            this.checkMark = true;
        }

        this.dialChartOptionsOne = {
            chartType: 'single',
            outerValue:this.model.get('totalSold'),
            classLabelOne: 'chart-text-big',
            classLabelTwo: 'chart-text-label',
            textLabelOne: this.model.get('totalSold'),
            textLabelTwo: 'Life Lives',
            checkMark: this.checkMark,
            chartOptions: {
                maxValue:200,
                centerLabel: {
                    animate: true,
                    elementSelector: '.chart-text-big',
                    sign: lifeLivesSign,
                    round:true
                }
            }
        };
    },

    /**
    * Get bonus level for Life Lives dial
    * @param {object} lifeLives
    * @return {number} bonusLevel
    */
    getLifeLivesBonusLevel: function (lifeLives) {
        var bonusLevel;
        // Currently, the bonus levels are hard-coded at 50, 60, 75, 100, 150, and 200
        // Under the first bonus level, so leave the 'overlay50' class
        if (this.model.get('totalSold') < 50) {
            bonusLevel = 50;

        // Next bonus is at 60, switch to the 'highlight' class
        } else if (this.model.get('totalSold') <= 59) {
            bonusLevel = 60;

        // Next bonus is at 75, switch to the 'highlight' class
        } else if (this.model.get('totalSold') <=74) {
            bonusLevel = 75;

        // Next bonus is at 100, so switch to 'highlight' class
        } else if (this.model.get('totalSold') <= 99) {
            bonusLevel = 100;

        // Next bonus at 150, switch to 'highlight' class
        } else if (this.model.get('totalSold') <= 149) {
            bonusLevel = 150;

        // Final bonus at 200, switch to 'highlight' class
        } else if (this.model.get('totalSold') <= 200) {
            bonusLevel = 200;   
        }

        return bonusLevel;
    },
    
    onDomRefresh: function() {
        
        // fade in the bonus milestones
        this.ui.bonusLevelMarkers.animate({opacity: 1}, 400);
    }
});

module.exports = PerformanceCenterDialLifeLivesView;