/* global Backbone:false, Marionette:false, $:false, _:false */
/**
 * Layout view for Requirement Submission page.
 *
 * "As-you-type" errors are displayed as child views to avoid rendering the entire page.
 * We do not want to have to render the form if the user has submitted files in the file inputs.
 * This would delete the file inputs that have been added dynamically and we cannot
 * add them back programmatically.
 */

// Load partials
import '../partials';

// add global partials
import '../../../partials';

import CommentsCharacterCountView         from './comments-character-count-v';
import CommentsErrorView                  from './comments-error-v';
import CreditCardView                     from './credit-card-v';
import {createAlert}                      from '../../../utils/error-helper';
import FileSizeView                       from './total-file-size-v';
import FormErrorView                      from './form-error-v';
import PolicyDetailModel                  from '../../policy/models/policy-detail-m';
import RequirementSubmissionFormModel     from '../models/requirement-submission-form-m';
import RequirementSubmissionFormViewModel from '../viewModels/requirement-submission-form-vm';
import template                           from '../templates/requirement-submission-form-t.hbs';
import utils                              from '../../../utils/utils';

const errorChannel         = Backbone.Radio.channel('error');
const spinnerChannel       = Backbone.Radio.channel('spinner');
const focusNavBarChannel   = Backbone.Radio.channel('focusNavBar');

// add handlebar helpers
import '../../../utils/hb-helpers';

const RequirementSubmissionView = Marionette.LayoutView.extend({

    errors    : {
        policyNotFoundMessage : 'The policy you requested was not found.',
        policyNumberMissing   : 'No Policy Number Specified',
        serverError           : 'An error occurred while attempting to submit the form'
    },

    events    : {
        'keyup @ui.comments'                    : '_updateModel',
        'paste @ui.comments'                    : '_onMousePaste',
        'change @ui.attachmentButton'           : '_handleAddFile',
        'click @ui.removeFileButton'            : '_confirmFileDelete',
        'click @ui.confirmFileDeleteButton'     : '_removeFile',
        'click @ui.submitButton'                : '_submitClick',
        'click @ui.cancelSubmitButton'          : '_removeFadeClassFromModal'
    },

    regions   : {
        commentsCharacterCountRegion   : '#comments-character-count-region',
        commentsErrorRegion            : '#comments-error-region',
        creditCardRegion               : '#credit-card-region',
        fileSizeRegion                 : '#file-size-region',
        formErrorRegion                : '#form-error-region'
    },

    template  : template,

    ui        : {
        attachmentButton        : '.file-input-btn',
        attachmentFormGroup     : '#attachment-form-group',
        cancelSubmitModal       : '#cancel-submit-modal',
        cancelSubmitButton      : '#cancel-submit-modal .btn-light',
        comments                : 'textarea',
        commentsContainer       : '#comments-container',
        commentsError           : '#comments-error',
        confirmFileDeleteModal  : '#confirm-file-remove-modal',
        confirmFileDeleteName   : '#confirm-file-remove-modal .modal-body>p>strong',
        confirmFileDeleteButton : '#confirm-remove-file-button',
        fileInputGroup          : '#file-input-group',
        removeFileButton        : '.remove-file-btn',
        submitButton            : 'button:submit'
    },

    /**
     * Initialize the view
     * @param options
     */
    initialize (options) {
        let domainModel;
        let policyId;
        let policyDetailsModel;

        // add utils for testing
        this.utils = utils;

        if (options && options.stateObj && options.stateObj.policyId) {
            policyId = options.stateObj.policyId;
        }

        // Currently, AWD cases will not be submitted,
        // so only "policyId" will be passed to the model.
        if (!options.model && policyId) {

            policyDetailsModel = new PolicyDetailModel({ id : policyId });

            domainModel = new RequirementSubmissionFormModel({ policyId : policyId });

            // the domain model and the policy details model are passed into the viewModel
            this.model = new RequirementSubmissionFormViewModel({
                domainModel       : domainModel,
                policyDetailModel : policyDetailsModel
            });

        } else if (options.model) {

            this.model = options.model;

        } else {

            // show error message since policyId is missing
            errorChannel.trigger('showErrorPage', this.errors.policyNumberMissing);

            // Destroy view to prevent calling render method
            // and show the error page which triggered above
            this.destroy();

            return false;
        }

        // manage errors when submitting the form to the service
        this.listenTo(this.model.domainModel, 'error', this._showAlertMessage);

        // attach events to the viewModel
        this.listenTo(this.model, 'change:fileErrorText', this._setErrorOnLastFile);
        this.listenTo(this.model, 'change:commentsErrorStyle', this._setCommentsErrorStyling);
        this.listenTo(this.model, 'change:serviceFileErrorText', this._setErrorOnFileGroup);
    },

    /**
     * Lifecycle event.
     * After the view has been rendered, create child views to show the info/error
     * messages which need to update in response to changes in the form.
     */
    onBeforeShow () {
        const options = {
            model : this.model
        };

        // create a child view to display the credit card checkbox and associated errors
        this.showChildView('creditCardRegion', new CreditCardView(options));

        // create a child view to display the character count for the comments
        this.showChildView('commentsCharacterCountRegion',
            new CommentsCharacterCountView(options));

        // create a child view to display errors in the Comments textarea
        this.showChildView('commentsErrorRegion', new CommentsErrorView(options));

        // create a child view used to display the total file size
        this.showChildView('fileSizeRegion', new FileSizeView(options));

        // create the view used to display error near the bottom of the form
        this.showChildView('formErrorRegion', new FormErrorView(options));

        this.bindUIElements();
    },

    /**
     * This creates new markup for a new file input field for the form.
     * @param {string} id The name to use for the ID attribute of the input and the FOR
     * attribute of the label
     * @param {Object} elem The jQuery element to which we want to append the new markup
     * @private
     */
    _addFileField () {
        // create a template for a new .well with a label and file input
        const compiled = _.template('<div class="well">' +
            '<label type="button" class="file-input-btn btn-primary btn" for="file<%= count %>">'+
            '<span>Choose File</span>' +
            '<input type="file" name="file<%= count %>" id="file<%= count %>" ' +
            'accept="application/pdf,image/tif">' +
            '</label><p>No file chosen</p></div>');

        const fileCount = this.ui.fileInputGroup.find('input:file').length + 1;
        const lastWell  = this.ui.fileInputGroup.find('.well').last();

        if (lastWell.length === 1) {
            $(compiled({ count : fileCount })).insertAfter(lastWell);
        }

    },

    /**
     * Changes the text for the label
     * @param {object} $label jQuery instance of the label to be changed
     * @private
     */
    _changeFileLabelText ($label) {
        if ($label.length) {
            $label.find('span').text('Remove File');
            $label.removeClass('file-input-btn btn-primary');
            $label.addClass('remove-file-btn');
        }
    },

    /**
     * Removes error styling applied to file input section
     * as well as its containers.
     * @private
     */
    _clearFileErrors () {
        const wells = this.ui.fileInputGroup.find('.well');

        this.ui.attachmentFormGroup.removeClass('has-error');

        // Reset the paragraph tag text to "No file chosen" for any
        // <p> tag associated with a "Choose File" label
        wells.each(function () {
            let well  = $(this);
            let label = well.find('label');
            let pTag  = well.find('p');

            if (label.hasClass('file-input-btn') && pTag.hasClass('text-error')) {
                pTag.text('No file chosen');
            }

            pTag.removeClass('text-error');

            well.removeClass('well-form-error');
        });

        // show the well containing the 'Choose file' button if it's been hidden
        wells.last().removeClass('hidden');

        // enable the submit button if the rest of the form is valid
        this._setSubmitButtonState(this.model.isValid());
    },

    /**
     * Populate the modal dialog box with the name of the file
     * to allow the user to confirm whether or not to delete the file.
     *
     * This updates the "data-inputId" attribute of the ui.confirmFileDeleteButton
     * so that, if clicked, the file information will be passed to the method
     * used to delete the input from the form.
     *
     * @param {object} e event object
     * @private
     */
    _confirmFileDelete (e) {
        let file;
        let inputId;

        // prevent this from opening another file select dialog box
        e.preventDefault();

        // get the "for" attribute of the label to determine
        // which file the user wants to be deleted
        inputId = $(e.currentTarget).attr('for');

        // find the file using the inputId
        file = _.findWhere(this.model.get('files'), { inputId : inputId });

        if (_.isObject(file)) {

            // set the name in the modal
            this.ui.confirmFileDeleteName.text(file.name);

            // update the data attribute on the submit button with the inputId
            this.ui.confirmFileDeleteButton.data('inputId', inputId);
        }

        this.ui.confirmFileDeleteModal.modal('show');
    },

    /**
     * Calculates the number of allowable characters remaining
     * in the comments section.
     * @returns {Number}
     * @private
     */
    _getCommentsLengthRemaining () {
        const maxLength = Number(this.model.get('maxCommentsLength'));

        return maxLength - this.ui.comments.val().length;
    },

    /**
     * Loops through the file input fields on the form
     * and creates an object from each one in which a file
     * has been added.
     *
     * The resulting array is made up of an object which is a File
     * object with an "inputId" key added. The "inputId" key is simply
     * the "id" attribute of the file input. This allows each File object
     * to be associated with a file input in the event that a file is removed
     * from the form.
     *
     * @returns {Array.<Object>}
     * @private
     */
    _getFilesArrayFromInputs () {
        let files = [];

        // loop through all of the file inputs and get properties used for the form
        this.ui.fileInputGroup.find('input:file').each(function() {

            const $inputField = $(this);
            if ($inputField.val().length > 0) {
                let fileObj = $inputField[0].files[0];

                // add the inputId of the field to the File object
                let fileObjWithInputId = _.extend(fileObj, { inputId : $inputField.attr('id') });
                files.push(fileObjWithInputId);
            }
        });

        return files;
    },

    /**
     * Manage the act of a user adding a file to the form. This method
     * will validate the file type. If the file type is valid, another
     * well is added to let the user choose another file. Other methods
     * are called to display the file name and size to the user and update
     * the model.
     *
     * @param {Object} e event
     * @private
     */
    _handleAddFile (e) {
        let $label    = $(e.currentTarget);
        let fileInput = $label.find('input:file');
        let inputId   = $label.attr('for');

        let fileObj;

        // get the actual file input object for the file the user is attempting to add
        fileObj = fileInput[0].files[0];

        // Check to see if the type is valid and whether or not it's a duplicate
        if (_.isObject(fileObj)) {

            // pre-validate the file
            if (this.model.domainModel.preValidateFile(this.model.get('files'), fileObj)) {

                // clear out any existing error messages
                this._clearFileErrors();

                // create a new file input element and append it to lastWell
                this._addFileField();

                // change the label to a "Remove File" button
                this._changeFileLabelText($label);

                this._updateModel();

                // display the file name and size to the user
                this._updateFileNameAndSize(inputId);

            } else {
                // if the file didn't pass validation, clear out the value from the file input
                fileInput.val('');
            }
        }
    },

    /**
     * In the event that the mouse is used to paste text, set a small
     * delay before updating the model to allow the .val() function to update.
     *
     * @private
     */
    _onMousePaste () {
        const _this = this;
        setTimeout(function _waitForValUpdate() {
            _this._updateModel();
        }, 100);
    },

    /**
     * Remove the 'fade' CSS class from the modal in order to immediately dismiss the
     * window before navigating away from this form. Set a data attribute
     * on the modal window for the _navigateToPolicyDetailPage function to check
     * before navigating away.
     *
     * @see {@link _navigateToPolicyDetailPage}
     * @private
     */
    _removeFadeClassFromModal () {
        this.ui.cancelSubmitModal.removeClass('fade');
        this.ui.cancelSubmitModal.data('navigate', true);
    },

    /**
     * Remove the file input and it's container from the DOM.
     * The event will have an "inputId" data attribute which tells
     * us the ID attribute of the file input to be deleted.
     *
     * @param {Object} e event object
     * @private
     */
    _removeFile (e) {
        let inputId;

        // clear file errors
        this._clearFileErrors();

        // figure out which file input we're removing from the form
        inputId = $(e.currentTarget).data('inputId');

        if (inputId) {
            // remove the input as well as the container div
            this.$el.find('#' + inputId).closest('.well').remove();
        }

        this._renumberFileInputs();

        this._updateModel();
    },

    /**
     * In order to prevent any issues with adding more file inputs,
     * the id attributes of the file inputs and the corresponding
     * 'for' attributes on the labels should be re-numbered.
     *
     * @private
     */
    _renumberFileInputs () {
        // loop through the file inputs on the form
        this.ui.fileInputGroup.find('input:file').each(function(index) {
            const count = index + 1;
            let $inputField = $(this);
            const value = 'file' + count;

            // set the name and id attributes of the input
            $inputField.attr('name', value);
            $inputField.attr('id', value);

            // set the "for" attribute of the label
            $inputField.parent().attr('for', value);
        });
    },

    /**
     * Add or remove the "has-error" css class in the textarea container.
     *
     * @param {Object} model Instance of the model
     * @param {boolean} showErrorStyling
     * @private
     */
    _setCommentsErrorStyling (model, showErrorStyling) {
        let well = this.ui.commentsContainer.find('.well');

        if (showErrorStyling) {
            this.ui.commentsContainer.addClass('has-error');
            well.addClass('well-form-error');

        } else {
            this.ui.commentsContainer.removeClass('has-error');
            well.removeClass('well-form-error');
        }
    },

    /**
     * Called in the event that the the service returns an error related to the files.
     * In the event of an error, the "Choose File" button and it's container
     * are hidden from the user so that more files cannot be added. Additionally,
     * the "has-error" class is added to the error message container.
     *
     * @param {String} errorMsg This is the value of the error message returned from the service.
     * @private
     */
    _setErrorOnFileGroup (errorMsg) {
        let lastWell = this.ui.fileInputGroup.find('.well').last();
        let wells    = this.ui.fileInputGroup.children('.well');

        if (errorMsg) {

            wells.addClass('well-form-error');

            // hide the last well to prevent the user from adding more files
            lastWell.addClass('hidden');

            this.ui.attachmentFormGroup.addClass('has-error');

            this._setSubmitButtonState(false);

        } else {
            this._clearFileErrors();
        }
    },

    /**
     * Adds/removes classes used to highlight the last file input on the form.
     * Adds error text to the individual file placeholder.
     *
     * @param {Object} model
     * @param {String} errorMsg
     * @private
     */
    _setErrorOnLastFile (model, errorMsg) {
        let lastWell     = this.ui.fileInputGroup.find('.well').last();
        let paragraphTag = lastWell.find('p');

        if (errorMsg) {
            this.ui.attachmentFormGroup.addClass('has-error');
            lastWell.addClass('well-form-error');

            // Do not add text for an empty form error message or missing
            // attachments, only highlight the file field to indicate the error.
            if (errorMsg !== this.model.domainModel.errors.emptyForm
                && errorMsg !== this.model.domainModel.errors.attachmentRequired) {
                paragraphTag.addClass('text-error');
                paragraphTag.text(errorMsg);
            }

        } else {
            this._clearFileErrors();
        }
    },

    /**
     * Enables/disables the submit button. If being disabled, the
     * CSS class "disabled" is added.
     *
     * @param {boolean} doEnable Set to TRUE to enable the button
     * @private
     */
    _setSubmitButtonState (doEnable) {

        if (doEnable) {
            this.ui.submitButton.removeClass('disabled');
            this.ui.submitButton.removeAttr('disabled');
        } else {
            // add the 'disabled' css class to the submit button
            this.ui.submitButton.addClass('disabled');
            this.ui.submitButton.attr('disabled', true);
        }
    },

    /**
     * Display a message to the user if an error occurs
     * @param model
     * @param response
     * @private
     */
    _showAlertMessage (model, response) {

        if (response && response.status === 500) {
            this.model.set(
                'alertMessage',
                createAlert(this.errors.serverError, 'warning')
            );
            this.render();
        }
    },

    /**
     * Handle the click event of the submit button for the form.
     * @param {Object} e The event
     * @private
     */
    _submitClick (e) {
        let isValid;
        e.preventDefault();

        this._updateModel();

        this.model.domainModel.set({
            comments   : this.model.get('comments'),
            creditCard : this.model.get('creditCard'),
            files      : this.model.get('files')
        });

        isValid = this.model.domainModel.isValid();

        if (isValid) {
            // disable the submit button and display the wait indicator
            spinnerChannel.trigger('show', {
                viewScope : this,
                position  : 'fixed'
            });
            this._setSubmitButtonState(false); // disable to prevent extra clicks
            this.model.domainModel.save();
            focusNavBarChannel.trigger('focusNavBar');
        }
    },

    /**
     * Updates the label and associated text with the name and size
     * of the file chosen.
     * @param {string} inputId The "id" attribute of the file input which
     * is being updated.
     * @private
     */
    _updateFileNameAndSize (inputId) {
        const compiled = _.template('<%= name%> <span class="margin-left-10 text-muted">' +
            '<%= size %> MB</span>');
        let file;
        let paragraphTag;
        let sizeInMb;

        paragraphTag = this.$el.find('#' + inputId).parent().next('p');

        if (paragraphTag.length) {

            file = _.findWhere(this.model.get('files'), { inputId : inputId });

            if (_.isObject(file) && file.name && file.size && !isNaN(file.size)) {
                sizeInMb = this.utils.bytesToMegabytesForDisplay(file.size);

                // add the values to the template and add them to the paragraphTag
                paragraphTag.html(compiled({
                    name : file.name,
                    size : sizeInMb
                }));
            }
        }
    },

    /**
     * Update the viewModel with elements from
     * the form and enable/disable the submit button.
     *
     * @private
     */
    _updateModel () {

        this.model.set({
            comments         : this.ui.comments.val(),
            commentsLength : this._getCommentsLengthRemaining(),
            files            : this._getFilesArrayFromInputs()
        });

        // ensure that the submit button enabled/disabled state is
        // correct since something was changed
        this._setSubmitButtonState(this.model.isValid());
    }

});

module.exports = RequirementSubmissionView;
