/* global _, Backbone*/
/* eslint no-console: 0 */
/**
 * A module for debugging the application which will be parameter driven.
 *
 * This module can be initialized on app start.
 * 
 * Created by Peter on 4/29/2015.
 * Usage example :
 *      var debugModule = require("modules/debug/debugModule");
 *      var debug = debugModule.init(config.debugEnabled);
 *      debug.log("log data",{objects:true});
 *      debug.info("log informations");
 *
 * To enable / disable debug
 *      debug.enable();
 *      debug.disable();
 * Also can be enable / disable debug module from querystring as well (add below query string
 * to the URL)
 *      ?debug = true / false
 */
var config              = require('../../config/config');
var analyticsChannel    = Backbone.Radio.channel('analytics');

var debugModule = (function () {

    var instance;

    function Debug(enabled) {

        //local reference
        var _this = this;
        var con = window.console;
        var debugQueryString = 'debug';
        var debugQueryValue;
        var args;

        //declare enabled based on parameter set with init function
        if (enabled !== undefined) {
            this.enabled = enabled;
        } else {
            this.enabled = false;
        }           

        this.enable = function() {
            this.enabled = true;
        };

        this.disable = function() {
            this.enabled = false;
        };

        this.isEnabled = function() {
            return this.enabled;
        };

        this.log = function() {
            if (con && con.log) {
                args = Array.prototype.slice.call(arguments);
                con.log(args);
            }            
            return this.enabled;           
        };

        this.info = function() {
            if (con && con.info) {
                args = Array.prototype.slice.call(arguments);
                con.info(args);
            }            
            return this.enabled;
        };

        this.trace = function() {
            if (con && con.trace) {
                args = Array.prototype.slice.call(arguments);
                con.trace(args);
            }            
            return this.enabled;
        };

        this.error = function() {
            if (con && con.error) {
                args = Array.prototype.slice.call(arguments);
                con.error(args);
            }            
            return this.enabled;
        };

        this.warn = function() {
            if (con && con.warn) {
                args = Array.prototype.slice.call(arguments);
                con.warn(args);
            }            
            return this.enabled;
        };

        this.dir = function() {
            if (con && con.dir) {
                args = Array.prototype.slice.call(arguments);
                con.dir(args);
            }            
            return this.enabled;
        };

        //override enabled based on query string with debug
        this.changeDebugStatusByQueryString = function(URL,name) {
            
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(URL);
            if (results !== null) {
                debugQueryValue = decodeURIComponent(results[1].replace(/\+/g, ' '));
            } else {
                debugQueryValue = null;
            }

            if (debugQueryValue && debugQueryValue === 'true') {
                this.enable();
            } else if (debugQueryValue && debugQueryValue === 'false') {
                this.disable();
            }
        };

        this.changeDebugStatusByQueryString(location.href, debugQueryString);          
        
        //fix for ie9 as  typeof console log/info is object instead of function
        if (Function.prototype.bind && window.console && typeof console.log === 'object') {
            var methods = [
                'log','info','warn','error','assert','dir','clear','profile','profileEnd'
            ];
            methods.forEach(function (method) {
                console[method] = this.bind(console[method], console);
            }, Function.prototype.call);
        }
        
        //override browser built console methods based on enabled value
        if (con) {

            if (con.log) {
                var oldLog = con.log;
                con.log = function() {
                    if (_this.enabled) { 
                        oldLog.apply(this, arguments);                       
                    }
                };
            }

            if (con.info) {
                var oldInfo = con.info;
                con.info = function() {
                    if (_this.enabled) {
                        oldInfo.apply(this, arguments);                        
                    }
                };
            }

            if (con.trace) {
                var oldTrace = con.trace;
                con.trace = function() {
                    if (_this.enabled) {
                        oldTrace.apply(this, arguments);
                    }
                };
            }

            if (con.error) {
                var oldError = con.error;

                con.error = function() {
                    if (_this.enabled) {
                        oldError.apply(this, arguments);
                    } else {
                        try {
                            var message  = _.values(arguments).join(', ');
                            analyticsChannel.trigger('trackException', {
                                fatal   : false,
                                message : message
                            });

                            //send error details to server
                            _this.logToServer({
                                level   : 'error',
                                message : message
                            });
                        } catch (e) {
                            // Since an exception was thrown while we were trying to
                            // handle an error, stop here to avoid cascading exceptions.
                        }
                    }
                };
            }

            if (con.warn) {
                var oldWarn = con.warn;
                con.warn = function() {
                    if (_this.enabled) {
                        oldWarn.apply(this, arguments);
                    }
                };
            }

            if (con.dir) {
                var oldDir = con.dir;
                con.dir = function() {
                    if (_this.enabled) {
                        oldDir.apply(this, arguments);
                    }
                };
            }

        }

        this.overrideWindowOnError = function() {
            //override window.onerror method based on enabled value
            var oldWindowOnError = window.onerror;
            window.onerror = function(errorMsg, url, lineNumber, columnNo, error) {
                if (_this.enabled) {
                    if (oldWindowOnError) {
                        return oldWindowOnError(errorMsg, url, lineNumber, columnNo, error);
                    }
                } else {

                    // Make object as string
                    if (_.isObject(errorMsg)) {
                        errorMsg = JSON.stringify(errorMsg);
                    }
                    
                    //send error details to analytics provider
                    var message = [
                        'Message: ' + errorMsg,
                        'Line: ' + lineNumber 
                    ];

                    if (columnNo) {
                        message.push('Column: ' + columnNo);
                    }

                    if (error) {
                        message.push('Error stack: ' + error.stack);
                    }

                    message = message.join('  -|-  ');

                    analyticsChannel.trigger('trackException', {
                        fatal: true,
                        message : message
                    });

                    //send error details to server
                    _this.logToServer({
                        level: 'error',
                        message: '\'message : \''+message+'\' URL : \''+ url+
                                ' Application URL: '+location.href
                    });

                    return true;
                }              
            };
        };

        this.overrideWindowOnError();

        /**
         * Method to log error in server
         * @param  {object}   data     data to log error
         *                             Format :
         *                             {
         *                                 level   : 'error',
         *                                 message : 'error message'
         *                             }
         * @param  {Function} callback callback method
         * 
         */
        this.logToServer = function (data, callback) {

            if(!data && !_.isObject(data)){
                return false;
            } else if (!data.level || !data.message) {
                return false;
            }

            if(typeof data.message === 'object'){
                data.message = _.map(data.message).join(', ');
            }

            Backbone.$.ajax({
                url: config.apiUrlRoot+'ui/log',
                method:'POST',
                data:JSON.stringify(data),
                contentType:'application/json',
                success: function(response) {
                    var msg = 'message logged to server successfully';
                    _this.log(msg);
                    if(typeof callback === 'function'){
                        callback(msg);
                    }
                },
                error: function(jqXHR, statusText, error) {
                    var msg =   'Some error occured at server side.'+
                                ' status: ' + jqXHR.status+
                                ' status Text:' + statusText+
                                ' error:' + error;
                    _this.log(msg);
                    if(typeof callback === 'function'){
                        callback(msg);
                    }
                }
            });
        };

    }

    return {

        init : function(enabled) {
            if (!instance) {
                //it can be 'enabled' by params or it will be override by config settings
                instance = new Debug(enabled ? enabled : config.debugEnabled);
                return instance;
            } else {
                return instance;
            }
        }

    };

})();

module.exports = debugModule;
