// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<!-- src/apps/navbar/partials/navbar-header-brand.hbs -->\r\n<!-- #section:basics/navbar.layout.brand -->\r\n<a id=\"__OA_LINK__\" href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,"#home",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" class=\"navbar-brand\">\r\n    <small class=\"lighter\">\r\n        <div class=\"navbar-logo\" title=\"OneAmerica logo\"></div>\r\n        "
    + alias3(((helper = (helper = helpers.appName || (depth0 != null ? depth0.appName : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"appName","hash":{},"data":data}) : helper)))
    + "\r\n    </small>\r\n</a>\r\n<!-- /section:basics/navbar.layout.brand -->\r\n";
},"useData":true});
