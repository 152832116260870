/* global Marionette */
/**
 * The simple, default view to run e6 support.
 */

var template = require('../templates/es6-feature-test-t.hbs');
var debugModule    = require('../../../modules/debug/debugModule').init();
var Es6FeatureTestView = Marionette.ItemView.extend({
    template: template,
    ui : {
        soa : '#soa',
        soo : '#soo',
        daa : '#daa',
        dao : '#dao',
        af  : '#af' ,
        ro  : '#ro',
        tl  : '#tl',
        dp  : '#dp',
        fol : '#fol',
        os  : '#os'
       
    },
    onRender () {
        
        //Spread operator Array
        try {
            let arr1 = [0, 1, 2];
            let arr2 = [3, 4, 5];
            arr1 = [...arr2, ...arr1];
            debugModule.log('Spread operator Array output:');
            debugModule.log(arr1);
            this.ui.soa.html('&#9989;');
        }
        catch (error) {
            this.ui.soa.html('&#10060;');
        }

        //Spread operator Object
        //Its not support now
        // for enabling this we need to add plugin-proposal-object-rest-spread
        try { 
            // let obj1 = { foo: 'bar', x: 42 };
            // let obj2 = { foo: 'baz', y: 13 };

            // let clonedObj = { ...obj1 };
            // debugModule.log('Spread operator Object output:');
            // debugModule.log('clonedObj');
            // debugModule.log(clonedObj);

            // let mergedObj = { ...obj1, ...obj2 };
            // debugModule.log('merged');
            // debugModule.log(mergedObj);

            this.ui.soo.html('&#10060;');
        }
        catch (error) {
            this.ui.soo.html('&#10060;');
        }

        //Destructuring assignment Array
        try {
            debugModule.log('Destructuring assignment test Array:');
            let a, b, rest;
            [a, b] = [10, 20];

            debugModule.log(a);
            // expected output: 10

            debugModule.log(b);
            // expected output: 20

            [a, b, ...rest] = [10, 20, 30, 40, 50];

            debugModule.log(rest);
            this.ui.daa.html('&#9989;');
        }
        catch (error) {
            this.ui.daa.html('&#10060;');
        }
        
        //Destructuring assignment Object
        try {
            debugModule.log('Destructuring assignment test Object:');
            const o = {p: 42, q: true};
            let {p, q} = o;

            debugModule.log(p); // 42
            debugModule.log(q); // true
            this.ui.dao.html('&#9989;');
        }
        catch (error) {
            this.ui.dao.html('&#10060;');
        }

        //Arrow function
        try {
            debugModule.log('Arrow function');
            function foo(n) { 
                let f = (args) => args + n; 
                debugModule.log('Arrow function');
                debugModule.log(f(10)); 
            }

            foo(1); // 11

            this.ui.af.html('&#9989;');
        }
        catch (error) {
            this.ui.af.html('&#10060;');
        }

        //Rest operator
        try {
            debugModule.log('Rest operator:');
            function foo(n) { 
                let f = (...args) => args[0] + n; 
                debugModule.log('Rest operator');
                debugModule.log(f(10)); 
            }

            foo(1); // 11

            this.ui.ro.html('&#9989;');
        }
        catch (error) {
            this.ui.ro.html('&#10060;');
        }

        // Template literals
        try {
            const a = 5;
            const b = 10;
            debugModule.log('Template literals');
            debugModule.log(`Fifteen is ${a + b} and not ${2 * a + b}.`);
            this.ui.tl.html('&#9989;');
        }
        catch (error) {
            this.ui.tl.html('&#10060;');
        }

        //Default parameter
        try {
            function multiply(a, b = 1) {
                return a * b;
            }
            debugModule.log('Default parameter');
            debugModule.log(multiply(5, 2));
            debugModule.log(multiply(5));
            this.ui.dp.html('&#9989;');
        }
        catch (error) {
            this.ui.dp.html('&#10060;');
        }

        // For-of loop
        try {
            let iterable = [10, 20, 30];
            debugModule.log('For-of loop');
            for (let value of iterable) {
                value += 1;
                debugModule.log(value);
            }
            this.ui.fol.html('&#9989;');
        }
        catch (error) {
            this.ui.fol.html('&#10060;');
        }

        // Operator shorthand
        try {
            const lukeSkywalker = 'Luke Skywalker';
            const obj = {
                lukeSkywalker,
            };
            debugModule.log('Operator shorthand');
            debugModule.log(obj);
            this.ui.os.html('&#9989;');
        }
        catch (error) {
            this.ui.os.html('&#10060;');
        }
        
    }
});
module.exports = Es6FeatureTestView;
