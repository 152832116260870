/* global Backbone:false, _:false */

var debugModule          = require('../../../modules/debug/debugModule').init();
var HierarchyPathsModel  = require('../models/hierarchy-paths-m');
var HierarchyPathsView   = require('./hierarchy-paths-v');
var nameTemplate         = require('../templates/general-info-producer-name-t.hbs');
var spinnerChannel       = Backbone.Radio.channel('spinner');
var template             = require('../templates/orgs-general-info-t.hbs');
var userChannel          = Backbone.Radio.channel('user');
var utils                = require('../../../utils/utils');

require('../../../utils/hb-helpers');

/**
 * Organization General Info View
 * Includes the subview for hierarchy paths.
 *
 */
var OrgsGeneralInfoView = Backbone.Marionette.LayoutView.extend({

    ui : {
        policyListLinks       : '.policy-list-link',
        generalInfoContainer  : '#general-info-container'
    },

    regions : {
        generalInfoDataRegion : '#general-info-data-region',
        hierarchyRegion       : '#hierarchy-paths-region',
        producerNameRegion    : '#general-info-producer-name-region'
    },

    template : template,

    onBeforeShow : function onBeforeShow() {
        var canViewHierarchy = userChannel.request('hasCapability', 'Producer_Role_View');
        var id               = this.model.get('id');

        if (canViewHierarchy && id) {
            this.model.set('paths', new HierarchyPathsModel({
                id : id
            }));

            this.listenTo(this.model.get('paths'), 'sync', this._showHierarchyPaths);
            this.listenTo(this.model.get('paths'), 'error', this._handlePathError);

            spinnerChannel.trigger('show', {
                viewScope    : this,
                regionName   : 'hierarchyRegion',
                size         : 'medium',
                position     : 'static'
            });
            
            this.model.get('paths').fetch();
        }
    },

    /**
     * Create and show the hierarchy path subview
     * @private
     */
    _showHierarchyPaths : function _showHierarchyPaths () {
        
        var hierarchyModel = this.model.get('paths');
        var paths          = hierarchyModel.get('paths');

        // Just in case there are no paths
        // or the root of the path is the same as the producer being viewed.
        if (paths && !hierarchyModel.producerIsRoot()) {
            this.hierarchyView = new HierarchyPathsView({
                model : hierarchyModel
            });

            this.listenTo(this.hierarchyView, 'hierarchyChange', this._hierarchyChange);
            this.showChildView('hierarchyRegion', this.hierarchyView);
        
        // added fix to remove spinner if user is root producer
        } else {

            // Replace region with blank view 
            this.hierarchyView = new Backbone.Marionette.ItemView({
                template : false
            });
            this.showChildView('hierarchyRegion', this.hierarchyView);

            // Hide general info container for root user, even stateObj has producer ID 
            // Generally it has links to access 'Policies in Sub-Organization'
            this.ui.generalInfoContainer.addClass('hidden');
        }
    },

    /**
     * Create a new childview to show the name
     * @param name the name to display
     */
    showName : function _showName(name) {
        var nameView = new Backbone.Marionette.ItemView({
            model : new Backbone.Model({
                fullName : name,
                showIcon : true
            }),
            template : nameTemplate,
            onRender : function() {
                utils.unwrapView(this);
            }
        });
        this.showChildView('producerNameRegion', nameView);
    },

    /**
     * Create a new childview to display data. This is currently limited to a link to pending
     * policies
     * @param data the data to show
     */
    showData : function showData(data) {

        // if no ID exist then return false;
        if (!data.id) {
            return false;
        }

        var impersonatedWebId = userChannel.request('getImpersonatedWebId');
        if (impersonatedWebId && _.isObject(data)) {
            data.targetuser = impersonatedWebId;
        }

        var dataView = new Backbone.Marionette.ItemView({
            model    : new Backbone.Model(data),
            template : require('../templates/general-info-data-t.hbs'),
            onRender : function() {
                utils.unwrapView(this);
            }
        });
        this.showChildView('generalInfoDataRegion', dataView);
        
    },

    /**
     * Handle an error when retrieving hierarchy path info. Should be caught by global handler,
     * but may want to do something specific here.
     * @private
     */
    _handlePathError : function _handlePathError (model, response) {

        // Remove spinner as its failed
        this.showChildView('hierarchyRegion', new Backbone.Marionette.ItemView({
            template:false
        }));
        var message = 'Unable to retrieve hierarchy path(s) for ' + model.get('id');
        debugModule.error(message);
    },

    /**
     * bubble-up the 'hierarchyChange' event
     * @param changedState
     * @private
     */
    _hierarchyChange : function _hierarchyChange (changedState) {
        this.trigger('hierarchyChange', changedState);
    }
});

module.exports = OrgsGeneralInfoView;