/* global require, Marionette, Backbone, _ */

//load all partials and helpers
import '../partials';
import '../../../utils/hb-helpers';

import performanceCenterTemplate from '../templates/performance-center-t.hbs';
import PerformanceCenterModel from '../models/performance-center-m';

import PerformanceCenterTextView from './performance-center-text-v';

import PerformanceCenterDialAPRView from './performance-center-dial-apr-v';
import PerformanceCenterDialLifeLivesView from './performance-center-dial-lifelives-v';
import PerformanceCenterDialCTView from './performance-center-dial-ct-v';
import PerformanceCenterDialLCView from './performance-center-dial-lc-v';


const PerformanceCenterLayout = Marionette.LayoutView.extend({
    template: performanceCenterTemplate,
    ui : {
        performanceCenterCollapseWrapper : '#collapse-performance-center',
        performanceCenterHeading         : '#performance-center-heading',
        performanceCenterCollapseBtn     : '#performance-center-collapse-btn',
        refreshDate                      : '#refresh-date'
    },
    events : {
        'hide.bs.collapse @ui.performanceCenterCollapseWrapper'   : '_handlePCCollapseHide',
        'hidden.bs.collapse @ui.performanceCenterCollapseWrapper' : '_handlePCCollapseHidden',
        'show.bs.collapse @ui.performanceCenterCollapseWrapper'   : '_handlePCCollapseShow',
        'shown.bs.collapse @ui.performanceCenterCollapseWrapper'  : '_handlePCCollapseShown'
    },
    initialize() {

        //to set rules to view the more info / rule links
        this.showInfoLinks = false;
        if (this.options.showInfoLinks) {
            this.showInfoLinks = true;
        }

        if (this.options.model && this.options.model instanceof Backbone.Model) {
            this.model = this.options.model;
        } else {
            this.model = new PerformanceCenterModel();
        }

        this.model.on('sync', this.render, this);
        this.model.on('error', function (model, response, options) {
            throw new Error('PerformanceCenterLayout.initialize: data fetch failed.' +
                JSON.stringify(response) + JSON.stringify(options));
        });

        this.model.fetch();
    },

    onRender() {
        this._addPerformanceCenterRegions();
        this._addSeparator();
        this._handlePerformanceCenterCollapse();
    },

    /**
     * Method to configure Peformance Center collapse feature
     * @private
     */
    _handlePerformanceCenterCollapse() {

        //Handling Peformance Center  collapse feature.
        // set default height (collapased) for Performance Center Panel based on screen width
        this.pcCollapseHeight = ((window.innerWidth < 768) ? 45 : 55)+'px';
        this.ui.performanceCenterCollapseWrapper.css({
            display: 'block',
            overflow: 'hidden',
            height: this.pcCollapseHeight
        });
    },

    /**
     * Event callback method for Performance Center collapse feature
     * It will be fired while finishing collapse
     * 
     * Animate back to default height while collapsing
     * @private
    */
    _handlePCCollapseHidden() {
        this.ui.performanceCenterCollapseWrapper.animate({
            height: this.pcCollapseHeight
        });
    },

    _handlePCCollapseHide() { 
        this.ui.performanceCenterCollapseWrapper.css({
            overflow: 'hidden'
        });
        this.ui.performanceCenterCollapseBtn.find('span').text('Expand');
    },

    /**
     * Event callback method for Performance Center collapse feature
     * It will be fired while finishing expand
     * 
     * Scroll position is correct for initial expand.
     * But for frequent expand, scroll position is moved to bottom 
     * So scroll to PC panel if body scroll position is greater than panel offset
     * @private
    */
    _handlePCCollapseShown() {
        const panelOffsetTop          = this.ui.performanceCenterCollapseWrapper.offset().top;
        const currentScrollPosition   = Backbone.$(window).scrollTop();

        // Change overflow property to visible state, otherwise floating contents
        // such as tooltip will trimmed off.
        this.ui.performanceCenterCollapseWrapper.css({
            overflow: 'inherit'
        });

        // Adding 30px to header height, as height function ignore margin value to
        // calculate height.
        let pcHeadingHeight     = this.ui.performanceCenterHeading.height() + 30;
        let refreshDateHight    = this.ui.refreshDate.height() + 10;
        var totalHeadingHeight  = pcHeadingHeight + refreshDateHight;

        // reduce height of Performance Center header from panelOffsetTop, 
        // Otherwise heading will be cut of while scrolling up
        if (currentScrollPosition > panelOffsetTop) {
            Backbone.$('html, body').animate({
                scrollTop: panelOffsetTop - totalHeadingHeight
            }, 500);
        }
    },

    _handlePCCollapseShow() { 
        this.ui.performanceCenterCollapseBtn.find('span').text('Collapse');
    },

    /**
     * Add a horizontal rule to separate items in first two rows (OOSO-3376)
     * @private
     */
    _addSeparator() {
        const firstRow = this.$el.find('.row:first');
        const rowCount = this.$el.find('.row').length;
        const hr       = '<hr class="margin-top-30 margin-btm-40 hidden-xs">';

        if (rowCount > 1) {
            Backbone.$(hr).insertAfter(firstRow);
        }
    },

    /**
    * Add regions dynamically based on availability of data
    */
    _addPerformanceCenterRegions() {
        const _this =  this;

        //iterate model object and get section objects to pas to view
        _.each(this.model.toJSON(),(sectionObjects, key) => {

            // accept only objects to draw regions
            if (!_.isObject(sectionObjects)) {
                return;
            }

            //set showInfoLinks flag to all childViews
            sectionObjects.showInfoLinks = _this.showInfoLinks;

            //create div elements to add region for layout
            _this._addColumn(key, sectionObjects.viewType);

            //add region to layout
            _this.addRegion(key+'Region', '#'+key+'-region');

            //choose view for each type section
            if (key ==='fyc' 
                    || key === 'wgtprem' 
                    || key === 'qualifyingFyc') {
                _this.showChildView(key+'Region',
                    new PerformanceCenterTextView({
                        model: new Backbone.Model(sectionObjects)
                    })
                );

            } else if (key === 'lifeLives'){
                _this.showChildView(key+'Region',
                    new PerformanceCenterDialLifeLivesView({
                        model: new Backbone.Model(sectionObjects)
                    })
                );
            } else if (key ==='chairmansTrip') {
                _this.showChildView(key+'Region',
                    new PerformanceCenterDialCTView({
                        model: new Backbone.Model(sectionObjects)
                    })
                );
            } else if (key === 'leadersConference') {
                _this.showChildView(key+'Region',
                    new PerformanceCenterDialLCView({
                        model: new Backbone.Model(sectionObjects)
                    })
                );
            } else if (key === 'annualProductionRequirements') {
                _this.showChildView(key+'Region',
                    new PerformanceCenterDialAPRView({
                        model: new Backbone.Model(sectionObjects)
                    })
                );
            }
        });
    },

    /**
    * Create column to map with layout region for each section
    * @param {string} columnId
    */
    _addColumn(columnId, viewType) {

        if (!columnId) {
            return false;
        }

        const column        = 3;
        const rowHTML       = '<div class="row" ></div>';
        const columnHTML    = `<div class="col-sm-4" id="${columnId}-region" ></div>`;
        let lastRowElement  = this.$el.find('.row:last');

        // start new row if column count reach at maximum (3)
        // or current viewType is different from previous one (text vs dial)
        if(!lastRowElement.length 
                || lastRowElement.children('.col-sm-4').length >= column 
                || viewType !== this.previousBoxViewType) {
            this.ui.performanceCenterCollapseWrapper.append(rowHTML);
        }

        //update row element
        lastRowElement = this.$el.find('.row:last');

        lastRowElement.append(columnHTML);
        this.previousBoxViewType = viewType;
    }

});

module.exports = PerformanceCenterLayout;
