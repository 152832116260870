/* global Marionette:false, _:false */

/**
 * View for Policy Search Page. This view is responsible for initializing and showing the search
 * form view, and then listening for valid user input and executing the proper search. In the case
 * of a client name search, it will initialize and show the client name search results view.
 *
 * Created by jbell on 2/3/16.
 */

var checkpointModule    = require('../../../modules/checkpoint/checkpointModule');
var PolicySearchView    = require('./policy-search-wrapper-v');
var template            = require('../templates/policy-search-page-t.hbs');
var utils               = require('../../../utils/utils');


var PolicySearchPageView = Marionette.LayoutView.extend({

    template : template,

    regions : {
        searchRegion : '#pending-search-region'
    },


    initialize: function initialize(options) {

        var searchOptions = {};

        // Get the search params
        if (options && options.stateObj) {
            searchOptions = utils.parseSearchParams(options.stateObj);
        }

        this.policySearchView = new PolicySearchView(searchOptions);
        this.listenTo(this.policySearchView, 'stateChange', this._setResultsCheckpoint);
        this.listenTo(this.policySearchView, 'showPolicy', this._showPolicy);
    },


    /**
     * Lifecycle function.
     */
    onBeforeShow: function onBeforeShow () {
        this.showChildView('searchRegion', this.policySearchView);
    },


    /**
     * Navigate to the policy detail page
     * @param policyId the ID of the policy to display
     * @private
     */
    _showPolicy: function _showPolicy (policyId) {
        this.trigger('nav', '#policy-detail?policyId=' + policyId);
    },


    /**
     * Replace the hash when the user changes the state (paging/sorting) of the result table.
     * @param {object} [changedState] The properties that have changed
     * @param {boolean} reset Reset checkpoint, If its undefined or false, it will not 
     *                        overwrite with current checkpoint.
     * @private
     */
    _setResultsCheckpoint: function _setResultsCheckpoint(changedState, reset) {
        var state    = checkpointModule.readCheckpoint();
        var newState;
        if (reset) {
            newState = _.extend({}, changedState);
        } else {
            newState = _.extend({}, state, changedState);
        }
        
        checkpointModule.writeCheckpoint(newState, true);
    }
});

module.exports = PolicySearchPageView;
