/* global Backbone:false */
/* eslint-disable max-len */
/**
 * A data structure that mimics the WCM Structure Model (wcmStructure-m.js).
 * This will contain all of the menu options for "The Pathway" menu item, its
 * sub-menus, and related data.
 */
const PathwayMenuStructureModel = Backbone.Model.extend({
    defaults : {
        structure : [
            {
                icon        : 'pathway',
                displayText : 'The Pathway',
                subItems    : [
                    /*************** BEGIN RECRUIT MENU ***************/
                    {
                        icon        : '',
                        displayText : 'Recruit',
                        capability  : {
                            forSidebar : [
                                {
                                    'Home_Office'                 : false,
                                    'Pathway_IB_Manager_View'     : true
                                }, {
                                    'Pathway_Retail_Manager_View' : true
                                }
                            ]
                        },
                        activeFor   : [
                            'c/pathway/recruit/interviewing',
                            'c/pathway/recruit/interviewing/ab-201-sample-quarterly-training-calendar',
                            'c/pathway/recruit/interviewing/four-step-recruiting-process',
                            'c/pathway/recruit/interviewing/project-100-200',
                            'c/pathway/recruit/planning-attracting',
                            'c/pathway/recruit/selecting',
                            'c/pathway/recruit/selecting/background-checks',
                            'c/pathway/recruit/selecting/career-profile-overview',
                            'c/pathway/recruit/sourcing',
                            'c/pathway/recruit/sourcing/financial-roundtable-series',
                            'c/pathway/recruit/sourcing/linkedin-recruiting-best-practices',
                            'c/pathway/recruit/sourcing/referral-program',
                            'c/pathway/recruit/sourcing/social-media-recruiting-campaign'

                        ],
                        link        : 'c/pathway/recruit'
                    }, {
                        displayText : 'Interviewing',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/interviewing'
                    }, {
                        displayText : 'Agency Building 201 Sample Quarterly Training Calendar',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/interviewing/ab-201-sample-quarterly-training-calendar'
                    }, {
                        displayText : 'Four-Step Recruiting Process',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/interviewing/four-step-recruiting-process'
                    }, {
                        displayText : 'Project 100/200',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/interviewing/project-100-200'
                    }, {
                        displayText : 'Planning & Attracting',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/planning-attracting'
                    }, {
                        displayText : 'Selecting',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/selecting'
                    }, {
                        displayText : 'Background Checks',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/selecting/background-checks'
                    }, {
                        displayText : 'Career Profile Overview',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/selecting/career-profile-overview'
                    }, {
                        displayText : 'Financial Roundtable Series',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/sourcing/financial-roundtable-series'
                    }, {
                        displayText : 'LinkedIn Best Practices',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/sourcing/linkedin-recruiting-best-practices',
                    }, {
                        displayText : 'Social Media Recruiting Campaign',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/sourcing/social-media-recruiting-campaign',
                    }, {
                        displayText : 'Sourcing',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/sourcing'
                    }, {
                        displayText : 'Referral Program',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/recruit/sourcing/referral-program'
                    },
                    /*************** BEGIN LAUNCH MENU ***************/
                    {
                        icon        : '',
                        displayText : 'Launch',
                        capability  : {
                            forSidebar : [
                                {
                                    'Home_Office'                  : false,
                                    'Pathway_IB_Manager_View'      : true
                                }, {
                                    'Home_Office'                  : false,
                                    'Pathway_Retail_Manager_View'  : true
                                }, {
                                    'Pathway_Retail_Manager_View'  : false,
                                    'Pathway_Retail_Producer_View' : true
                                }, {
                                    'Pathway_IB_Manager_View'      : true,
                                    'Pathway_Retail_Manager_View'  : true,
                                    'Pathway_Retail_Producer_View' : true
                                }
                            ]
                        },
                        activeFor   : [
                            'c/pathway/launch/announcements-expectations',
                            'c/pathway/launch/marketing-new-associate',
                            'c/pathway/launch/marketing-yourself',
                            'c/pathway/launch/monitoring-evaluating-coaching',
                            'c/pathway/launch/onboarding-business-development',
                            'c/pathway/launch/onboarding-planning-monitoring',
                            'c/pathway/launch/planning',
                            'c/pathway/launch/teaming-joint-work-coaching'
                        ],
                        link        : 'c/pathway/launch'
                    }, {
                        displayText : 'Announcements & Expectations',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/launch/announcements-expectations'
                    }, {
                        displayText : 'Marketing the New Associate',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/launch/marketing-new-associate'
                    }, {
                        displayText : 'Marketing Yourself',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/launch/marketing-yourself'
                    }, {
                        displayText : 'Monitoring, Evaluating & Coaching',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/launch/monitoring-evaluating-coaching'
                    }, {
                        displayText : 'Onboarding & Business Development',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/launch/onboarding-business-development'
                    }, {
                        displayText : 'Onboarding, Planning & Monitoring',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/launch/onboarding-planning-monitoring'
                    }, {
                        displayText : 'Planning',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/launch/planning'
                    }, {
                        displayText : 'Teaming, Joint Work & Coaching',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/launch/teaming-joint-work-coaching'
                    },
                    /*************** BEGIN DEVELOP MENU ***************/
                    {
                        icon        : '',
                        displayText : 'Develop',
                        capability  : {
                            forSidebar : [
                                {
                                    'Home_Office'                  : false,
                                    'Pathway_IB_Manager_View'      : true
                                }, {
                                    'Home_Office'                  : false,
                                    'Pathway_Retail_Manager_View'  : true
                                }, {
                                    'Pathway_IB_Manager_View'      : false,
                                    'Pathway_IB_Producer_View'     : true
                                }, {
                                    'Pathway_Retail_Manager_View'  : false,
                                    'Pathway_Retail_Producer_View' : true
                                }, {
                                    'Pathway_IB_Manager_View'      : true,
                                    'Pathway_Retail_Manager_View'  : true,
                                    'Pathway_Retail_Producer_View' : true
                                }
                            ]
                        },
                        activeFor   : [
                            'c/pathway/develop/selling-concepts-skills-systems/advanced-markets',
                            'c/pathway/develop/selling-concepts-skills-systems/advanced-markets/meet-staff',
                            'c/pathway/develop/selling-concepts-skills-systems/advanced-markets/renaissance',
                            'c/pathway/develop/selling-concepts-skills-systems/advanced-markets/pension-concepts',
                            'c/pathway/develop/selling-concepts-skills-systems/advanced-markets/business-valuation',
                            'c/pathway/develop/selling-concepts-skills-systems/wbc',
                            'c/pathway/develop/conferences-education',
                            'c/pathway/develop/conferences-education/educational-reimbursement',
                            'c/pathway/develop/conferences-education/reimbursement-levels',
                            'c/pathway/develop/leadership-development',
                            'c/pathway/develop/selling-concepts-skills-systems',
                            'c/pathway/develop/rewards-recognition',
                            'c/pathway/develop/rewards-recognition/vip-program',
                            'c/pathway/develop/rewards-recognition/success-or-vip-rewards-programs',
                            'c/pathway/develop/rewards-recognition/calendar-year-awards',
                            'c/pathway/develop/rewards-recognition/success-rewards',
                        ],
                        link        : 'c/pathway/develop'
                    }, {
                        displayText : 'Advanced Markets',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/selling-concepts-skills-systems/advanced-markets'
                    }, {
                        displayText : 'Meet the Staff',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/selling-concepts-skills-systems/advanced-markets/meet-staff'
                    }, {
                        displayText : 'Renaissance',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/selling-concepts-skills-systems/advanced-markets/renaissance'
                    }, {
                        displayText : 'Pension Concepts',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/selling-concepts-skills-systems/advanced-markets/pension-concepts'
                    }, {
                        displayText: 'Business Valuation',
                        capability: {
                            forPageAccess: {
                                'Pathway_IB_Manager_View': true,
                                'Pathway_IB_Producer_View': true,
                                'Pathway_Retail_Manager_View': true,
                                'Pathway_Retail_Producer_View': true
                            }
                        },
                        link: 'c/pathway/develop/selling-concepts-skills-systems/advanced-markets/business-valuation'
                    }, {
                        displayText : 'Wealth Building Cornerstones',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/selling-concepts-skills-systems/wbc'
                    },{
                        displayText : 'Conferences & Education',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/conferences-education'
                    }, {
                        displayText : 'Educational Reimbursement',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/conferences-education/educational-reimbursement'
                    }, {
                        displayText : 'Reimbursement Levels',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/conferences-education/reimbursement-levels'
                    }, {
                        displayText : 'Leadership & Development',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/leadership-development'
                    }, {
                        displayText : 'Rewards & Recognition',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/rewards-recognition'
                    }, {
                        displayText : 'VIP Program for Independent Brokers',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true
                            }
                        },
                        link        : 'c/pathway/develop/rewards-recognition/vip-program'
                    }, {
                        displayText : 'Success or VIP Rewards Programs',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/rewards-recognition/success-or-vip-rewards-programs'
                    }, {
                        displayText : '2019 Calendar-year Awards',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/rewards-recognition/calendar-year-awards'
                    }, {
                        displayText : 'Success Rewards',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/rewards-recognition/success-rewards'
                    }, {
                        displayText : 'Selling Concepts, Skills & Systems',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/develop/selling-concepts-skills-systems'
                    },
                    /*************** BEGIN ELEVATE MENU ***************/
                    {
                        icon        : '',
                        displayText : 'Elevate',
                        capability  : {
                            forSidebar : [
                                {
                                    'Home_Office'                  : false,
                                    'Pathway_IB_Manager_View'      : true
                                }, {
                                    'Home_Office'                  : false,
                                    'Pathway_Retail_Manager_View'  : true
                                }, {
                                    'Home_Office'                  : false,
                                    'Pathway_Retail_Manager_View'  : false,
                                    'Pathway_Retail_Producer_View' : true
                                }, {
                                    'Home_Office'                  : false,
                                    'Pathway_IB_Manager_View'      : false,
                                    'Pathway_IB_Producer_View'     : true
                                }, {
                                    'Pathway_IB_Manager_View'      : true,
                                    'Pathway_Retail_Manager_View'  : true,
                                    'Pathway_Retail_Producer_View' : true
                                }
                            ]
                        },
                        activeFor   : [
                            'c/pathway/elevate/identity/approved-designations',
                            'c/pathway/elevate/digital-media/social-media/boosting-facebook',
                            'c/pathway/elevate/campaigns-events',
                            'c/pathway/elevate/identity/collateral-overview',
                            'c/pathway/elevate/digital-media/websites/copywriting-service',
                            'c/pathway/elevate/digital-media/websites/event-success',
                            'c/pathway/elevate/digital-media',
                            'c/pathway/elevate/diverse-markets',
                            'c/pathway/elevate/selling-existing-clients',
                            'c/pathway/elevate/digital-media/social-media/expand-networks',
                            'c/pathway/elevate/digital-media/social-media/facebook-guidelines',
                            'c/pathway/elevate/digital-media/social-media/facebook-quick-tips',
                            'c/pathway/elevate/digital-media/websites/be-findable-google-maps',
                            'c/pathway/elevate/digital-media/websites/five-quick-tips',
                            'c/pathway/elevate/digital-media/websites/google-analytics',
                            'c/pathway/elevate/identity/headshot',
                            'c/pathway/elevate/digital-media/social-media/go-mobile',
                            'c/pathway/elevate/digital-media/social-media/leveraging-offline',
                            'c/pathway/elevate/identity',
                            'c/pathway/elevate/campaigns-events/insurance-seminar-series',
                            'c/pathway/elevate/campaigns-events/events',
                            'c/pathway/elevate/identity/job-title-options',
                            'c/pathway/elevate/digital-media/websites/leap-content',
                            'c/pathway/elevate/digital-media/social-media/linkedin-client-gathering',
                            'c/pathway/elevate/digital-media/social-media/linkedin-profile',
                            'c/pathway/elevate/digital-media/social-media/linkedin-tips',
                            'c/pathway/elevate/identity/logo-creation-enhancement',
                            'c/pathway/elevate/identity/oneamerica-business-cards',
                            'c/pathway/elevate/content-library',
                            'c/pathway/elevate/pr-advertising',
                            'c/pathway/elevate/practice-management',
                            'c/pathway/elevate/practice-management/bulk-mail-discounts',
                            'c/pathway/elevate/practice-management/preparing-interview-questions',
                            'c/pathway/elevate/practice-management/security-requirements',
                            'c/pathway/elevate/practice-management/lease-arrangements',
                            'c/pathway/elevate/practice-management/office-equipment',
                            'c/pathway/elevate/practice-management/office-technology-setup',
                            'c/pathway/elevate/practice-management/protection-equipment-systems',
                            'c/pathway/elevate/practice-management/staffing-office',
                            'c/pathway/elevate/digital-media/radio',
                            'c/pathway/elevate/oams',
                            'c/pathway/elevate/oams/sender-policy-framework',
                            'c/pathway/elevate/digital-media/websites/seo-guide',
                            'c/pathway/elevate/digital-media/social-media',
                            'c/pathway/elevate/digital-media/social-media/bio',
                            'c/pathway/elevate/digital-media/social-media/content',
                            'c/pathway/elevate/digital-media/social-media/likes',
                            'c/pathway/elevate/digital-media/social-media/policy',
                            'c/pathway/elevate/digital-media/social-media/6-steps-get-started',
                            'c/pathway/elevate/digital-media/social-media/resources',
                            'c/pathway/elevate/digital-media/social-media/twitter-compliance-guidelines',
                            'c/pathway/elevate/digital-media/social-media/twitter',
                            'c/pathway/elevate/digital-media/video',
                            'c/pathway/elevate/video-series-retail',
                            'c/pathway/elevate/digital-media/websites',
                            'c/pathway/elevate/digital-media/websites/analytics-guide',
                            'c/pathway/elevate/digital-media/websites/program-guidelines'
                        ],
                        link        : 'c/pathway/elevate'
                    }, {
                        displayText : 'Approved Designations',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/identity/approved-designations'
                    }, {
                        displayText : 'Boosting on Facebook',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/boosting-facebook'
                    }, {
                        displayText : 'Campaigns & Events',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/campaigns-events'
                    }, {
                        displayText : 'Collateral Overview',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/identity/collateral-overview'
                    }, {
                        displayText : 'Copywriting Service',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Producer_View' : true,
                                'Pathway_Retail_Manager_View'  : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/copywriting-service'
                    }, {
                        displayText : 'Elevate Your Event\'s Success',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/event-success'
                    }, {
                        displayText : 'Digital Media',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media'
                    }, {
                        displayText : 'Diverse Markets',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/diverse-markets'
                    }, {
                        displayText : 'Selling to Existing Clients',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/selling-existing-clients'
                    }, {
                        displayText : 'Expand Your Network',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/expand-networks'
                    }, {
                        displayText : 'Facebook Page',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/facebook-guidelines'
                    }, {
                        displayText : '5 Quick Tips on Using Facebook',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/facebook-quick-tips'
                    }, {
                        displayText : 'Be Findable on Google Maps',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/be-findable-google-maps'
                    }, {
                        displayText : 'Five Quick Tips on Driving Traffic to Your Website',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/five-quick-tips'
                    }, {
                        displayText : 'Five Google Analytics Metrics to Watch',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/google-analytics'
                    }, {
                        displayText : 'Professional Headshot Guidelines',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/identity/headshot'
                    }, {
                        displayText : 'Go Mobile with Hearsay Social',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/go-mobile'
                    }, {
                        displayText : 'Leveraging Offline Networking to Drive More Business Opportunities',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/leveraging-offline'
                    }, {
                        displayText : 'Interview',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/identity'
                    }, {
                        displayText : 'Insurance Seminar Series',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/campaigns-events/insurance-seminar-series'
                    }, {
                        displayText : 'Elevate Events',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/campaigns-events/events'
                    }, {
                        displayText : 'Job Title Options',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/identity/job-title-options'
                    }, {
                        displayText : 'Leap Guidelines',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/leap-content'
                    }, {
                        displayText : 'LinkedIn For Client Gathering',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/linkedin-client-gathering'
                    }, {
                        displayText : 'LinkedIn Profile',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/linkedin-profile'
                    }, {
                        displayText : 'LinkedIn Top 10 Tips for Producers',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/linkedin-tips'
                    }, {
                        displayText : 'Logo Creation and Enhancement',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/identity/logo-creation-enhancement'
                    }, {
                        displayText : 'OneAmerica Business Cards',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/identity/oneamerica-business-cards'
                    }, {
                        displayText : 'OneAmerica Content Library',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/content-library'
                    }, {
                        displayText : 'Public Relations & Advertising',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/pr-advertising'
                    }, {
                        displayText : 'Practice Management',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management'
                    }, {
                        displayText : 'Bulk Mail Discounts',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management/bulk-mail-discounts'
                    }, {
                        displayText : 'Management and Protection of Equipment and Systems',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_IB_Producer_View'    : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management/protection-equipment-systems'
                    }, {
                        displayText : 'Preparing Interview Questions',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_IB_Producer_View'    : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management/preparing-interview-questions'
                    }, {
                        displayText : 'Security and Recommended PC/Laptop Requirements',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_IB_Producer_View'    : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management/security-requirements'
                    }, {
                        displayText : 'Lease Arrangements',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_IB_Producer_View'    : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management/lease-arrangements'
                    }, {
                        displayText : 'Office Equipment',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_IB_Producer_View'    : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management/office-equipment'
                    }, {
                        displayText : 'Office Technology Set Up',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_IB_Producer_View'    : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management/office-technology-setup'
                    }, {
                        displayText : 'Staffing Your Office',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'     : true,
                                'Pathway_IB_Producer_View'    : true,
                                'Pathway_Retail_Manager_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/practice-management/staffing-office'
                    }, {
                        displayText : 'Radio',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/radio'
                    }, {
                        displayText : 'OneAmerica Marketing Store',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/oams'
                    }, {
                        displayText : 'Sender Policy Framework Career Agency',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/oams/sender-policy-framework'
                    }, {
                        displayText : 'SEO Guide',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/seo-guide'
                    }, {
                        displayText : 'Social Media',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media'
                    }, {
                        displayText : 'Approved Wording Suggestions for Social Media Bio Info',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/bio'
                    }, {
                        displayText : 'Social Media Content',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/content'
                    }, {
                        displayText : 'Guidance on "Likes" for Social Media Business',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/likes'
                    }, {
                        displayText : 'Social Media Policy',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/policy'
                    }, {
                        displayText : 'Six Steps',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/6-steps-get-started'
                    }, {
                        displayText : 'Social Media Resources',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/resources'
                    }, {
                        displayText : 'Twitter Account',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/twitter-compliance-guidelines'
                    }, {
                        displayText : 'Twitter Tips to Grow Your Business',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/social-media/twitter'
                    }, {
                        displayText : 'Video',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/video'
                    }, {
                        displayText : 'Video Series',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/video-series-retail'
                    }, {
                        displayText : 'Website',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites'
                    }, {
                        displayText : 'View Your Website Analytics in 3 Easy Steps',
                        capability  : {
                            forPageAccess : {
                                'Pathway_IB_Manager_View'      : true,
                                'Pathway_IB_Producer_View'     : true,
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/analytics-guide'
                    }, {
                        displayText : 'Website Program Guidelines',
                        capability  : {
                            forPageAccess : {
                                'Pathway_Retail_Manager_View'  : true,
                                'Pathway_Retail_Producer_View' : true
                            }
                        },
                        link        : 'c/pathway/elevate/digital-media/websites/program-guidelines'
                    },
                    /**subItems    : [

                        ]
                    }*/
                ]
            }
        ]
    }
});

module.exports = PathwayMenuStructureModel;