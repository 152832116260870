/* global _, Backbone */

import ToutView from '../../../modules/tout/views/tout-v';

import ToutTemplate from '../templates/policies-tout-t.hbs';

import PolicySearchViewModel from '../../policy/viewModels/policy-search-form-vm';
import PolicySearchModule from '../../../modules/policySearch/policySearchModule';

// Load handlebars helper method to avoid unit test failure
import '../../../utils/hb-helpers';

// load the global partials.
import '../../../partials';

const userChannel = Backbone.Radio.channel('user');
const analyticsChannel = Backbone.Radio.channel('analytics');

const PoliciesToutView = ToutView.extend({

    template: ToutTemplate,

    ui: {
        homeSimpleSearchForm: '#home-simple-search',
        homeSimpleSearchButton: '#home-simple-search-btn',
        homeSimpleSearchInput: '#home-simple-search-input',
        popoverElements: '[data-toggle="popover"]'
    },

    events: {
        'input @ui.homeSimpleSearchInput': '_enableSearchButton',
        'submit @ui.homeSimpleSearchForm': '_search',
        'click @ui.popoverElements': '_toggleInfoPopover',
        'mouseenter @ui.popoverElements': '_showInfoPopover',
        'mouseleave @ui.popoverElements': '_hideInfoPopover',
        'blur @ui.popoverElements': '_hideInfoPopover'
    },

    /**
     * When rendering, bind the popover handler to the popover ui elements.
     */
    onRender() {

        if (!this.searchModel && !this.policySearchModule) {
            // Create Policy Search Model
            this.searchModel = new PolicySearchViewModel();

            // Create the search module and set up listeners
            this.policySearchModule = new PolicySearchModule();
            this.listenTo(this.policySearchModule, 'policyIdFound', this._showPolicy);
            this.listenTo(this.policySearchModule, 'serverMessage', this._showServerMessage);
        }
        // Binding popover event to all popover elements
        // Popover will be displayed on click, enter, hover events
        this.ui.popoverElements.popover({
            trigger: 'manual',
            placement: 'auto',
            container: 'body'
        });
    },

    _toggleInfoPopover(e) {
        this.ui.popoverElements.popover('toggle');
    },

    _showInfoPopover(e) {
        this.ui.popoverElements.popover('show');
    },

    _hideInfoPopover(e) {
        this.ui.popoverElements.popover('hide');
    },

    /**
     * Enable button of simple search if input is not empty
     * 
     * */

    _enableSearchButton() {
        let searchTerm = this.ui.homeSimpleSearchInput.val();
        if (searchTerm.length >= 1) {
            this.ui.homeSimpleSearchButton.attr('disabled', false);
        } else {
            this.ui.homeSimpleSearchButton.attr('disabled', true);
        }
    },

    /**
     * Start search from simple search form
     */
    _search(event) {

        // Don't want to just go submitting all willy-nilly.
        if (event) {
            event.preventDefault();
        }

        let searchTerm = this.ui.homeSimpleSearchInput.val();

        this.model.set({
            searchTermErrorText: null,
            searchTerm: searchTerm
        });

        // using existing policy search view model for validation
        // update the model with the proper form values, and clear any alert message
        this.searchModel.set({
            searchTerm: searchTerm
        });

        // validate searchModel
        // error messages are available via this.searchModel.validation
        let isValid = this.searchModel.isValid();

        if (isValid) {

            // Track form submission 
            analyticsChannel.trigger('trackView', {
                event: event,
                uri: '#home',
                title: 'OneSource Online',
            });

            // Input OK, trigger appropriate search
            switch (this.searchModel.get('searchType')) {

                case 'clientName':
                    this._redirectToSearchPage();
                    break;

                case 'policyNumber':
                    this.policySearchModule.performPolicyNumberSearch(searchTerm);
                    break;
            }
        } else {
            this.model.set('searchTermErrorText', this.searchModel.get('searchTermErrorText'));
        }

        this.render();

    },

    /**
     * Show Policy Number 
     * call to listener attached in toutView(used in home view)
     * @param {number} policyId Policy Id returned from policy number search
     */
    _showPolicy(policyId) {
        this.trigger('showPolicy', policyId);
    },

    /**
     * Show server error message for policy number searh
     * @param {string} message Error template string returned from Policy search policySearchModule
     * @param {string} type Type of error
     * 
     */
    _showServerMessage(message, messageType) {
        let messageTemplate = _.template(message);
        let messageText = messageTemplate({
            searchTerm: this.searchModel.get('searchTerm')
        });
        this.model.set('searchTermErrorText', messageText);
        this.render();
    },

    /**
     * Redirect to search page based user type 
     */
    _redirectToSearchPage() {

        const userType = this._getUserType();
        const searchTerm = encodeURIComponent(this.searchModel.get('searchTerm'));
        const searchType = this.searchModel.get('searchType');

        let url;
        let queryString = `?searchTerm=${searchTerm}&searchType=${searchType}`;

        if (userType.isHomeUser) {
            url = `#search${queryString}`;
            this.trigger('redirectToSearchPage', url);
        } else if (userType.isProducer) {
            url = `#policies/search${queryString}`;
            this.trigger('redirectToSearchPage', url);
        } else if (userType.isProducerManager) {
            url = `#policy-manager/search${queryString}`;
            this.trigger('redirectToSearchPage', url);
        }
    },

    /**
     * Get user type based on capability
     */
    _getUserType() {
        let userType = {};

        const hasHOCapability = userChannel.request('hasCapability', 'Home_Office');
        const hasPolicyViewCapability = userChannel.request('hasCapability', 'Policy_View');
        const hasPolicySearchByProducerCapability = userChannel.request(
            'hasCapability', 'Policy_Search_by_Producer'
        );

        if (hasPolicyViewCapability && !hasHOCapability && !hasPolicySearchByProducerCapability) {
            userType.isProducer = true;
        } else if (hasPolicySearchByProducerCapability && !hasHOCapability) {
            userType.isProducerManager = true;
        } else if (hasHOCapability) {
            userType.isHomeUser = true;
        }

        return userType;
    }

});

module.exports = PoliciesToutView;

