/* global Backbone:false, _:false, Spinner:false, URI:false */

/**
 * A utility sub module with UI utility methods.
 *
 */

const analyticsChannel = Backbone.Radio.channel('analytics');
const userChannel      = Backbone.Radio.channel('user');

const utilsUI = {

    errors: {
        pageLinkExpectedAsString   : 'buildHrefURL : Expected pageLink as a string format',
        scrollToJQuery             : '$element must be a jQuery object',
        sendFormErrorsToAnalytics : {
            emptyErrors   : 'errors parameter passed to sendFormErrorsToAnalytics must be ' +
                            'an object',
            emptyFieldMap : 'fieldMap parameter passed to sendFormErrorsToAnalytics must be ' +
                            'an object',
            formName      : 'formName passed to sendFormErrorsToAnalytics must not be null'
        },
        invalidURL      : 'addTargetUserQueryParamToURL: URL parameter should start with ' +
                          'hash/http/https',
        urlIsMissing    : 'addTargetUserQueryParamToURL: URL parameter is missing',
        invalidBaseURL  : 'base URL provided is not valid URL, it should start with http/https',
        invalidRelativePath : 'relative path should be in string format',
        missingURLParam     : 'URL is missing to add targetuser parameter',
    },

    /**
     * Remove the wrapping div element that Backbone adds to the view by default.
     * @param marionetteView the Marionette view to be unwrapped.
     */
    unwrapView(marionetteView) {
        marionetteView.$el = marionetteView.$el.children();
        marionetteView.$el.unwrap();
        marionetteView.setElement(marionetteView.$el);
    },


    /**
     * Scroll to the top of the specified jQuery element
     * @param {jquery object} $element the element to scroll to
     * @param {number} minusOffset subtraction some offset with actual one
     */
    scrollTo($element, minusOffset) {
        if (! $element || ! ($element instanceof Backbone.$)) {
            throw new Error(utilsUI.errors.scrollToJQuery);
        }
        const _this = this;

        //get scroll position 
        const scrollPosition = this.getOffsetTop($element, minusOffset);
        Backbone.$('html, body').animate({
            scrollTop: scrollPosition
        }, {
            durarion : 400,

            // to check variance of scroll position during scrolling
            // and stop current one, start with new value
            step: function(now, fx) {
                let newScrollPosition = _this.getOffsetTop($element, minusOffset);
                if (scrollPosition !== newScrollPosition  ) {
                    Backbone.$(this).stop().animate({scrollTop: newScrollPosition}, 400);
                }
            }
        });
    },

    /**
     * Get offset top
     * @param  {jquery object} $element    
     * @param  {number} minusOffset For some cases if we want to minus actual offset 
     *                               value for better positionng 
     * @return {number}             scroll position
     */
    getOffsetTop($element, minusOffset) {
        
        //get element offset from document(parent)
        const offset = $element.offset();
        let scrollPosition = offset ? offset.top : 0;

        if (minusOffset && typeof minusOffset === 'number') {
            scrollPosition = scrollPosition - minusOffset;
        }

        return scrollPosition;
    },

    /**
     * Verify URL validity
     * Currently only support
     *     * Should start with https:// or http://
     *     * White space not allowed
     * 
     * @param  {string}  url 
     * @return {Boolean}  
     */
    isValidURL(url) {
        const urlRegex = new RegExp(
            /^(https?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        );

        if (url && urlRegex.test(url)) {
            return true;
        }

        return false;
    },

    /**
     * Parse the stateObj and create an object for the search wrapper.
     * @param stateObj
     * @returns {{}}
     */
    parseSearchParams(stateObj) {
        let options     = {};
        let searchState = {};

        if (stateObj.searchTerm) {
            options.searchTerm = stateObj.searchTerm;
        }

        if (stateObj.searchType) {
            options.searchType = stateObj.searchType;
        }

        if (stateObj.col || stateObj.col === 0) {
            searchState.col = stateObj.col;
        }

        if (stateObj.dir) {
            searchState.dir = stateObj.dir;
        }

        if (stateObj.length) {
            searchState.length = stateObj.length;
        }

        if (stateObj.start || stateObj.start === 0) {
            searchState.start = stateObj.start;
        }

        if (stateObj.noCache) {
            searchState.noCache = stateObj.noCache;
        }

        if (!_.isEmpty(searchState)) {
            options.searchState = searchState;
        }

        return options;
    },

    /**
     * Generate spinner element to show wait indicator
     * @param {string} size Optional parameter to define the size small|medium
     * @param {string} position Optional parameter It can be static|relative|fixed|absolute
     * @return {String}      Spinner element
     */
    generateSpinnerElement(size, position) {
        
        //standard
        let options = {
            lines: 12, 
            corners:0,
            width: 5,
            length: 8,
            radius:10,
            color: '#4899ce'
        };
        let spinner;

        if (position) {
            options.position = position; 
        }

        if (size === 'small') {
            _.extend(options, {
                width:2,
                length:3,
                radius:3
            });
        } else if (size === 'medium') {
            _.extend(options, {
                width:2,
                length:5,
                radius:5
            });
        }

        spinner = new Spinner(options).spin();
        return spinner.el;
    },

    /**
     * Handles the mapping of error messages to form fields and sends the errors and
     * related fields to "trackException" in the analytics module.
     *
     * The fieldMap object maps keys in the errors object to fields in the form. For
     * example, an errors object which looks like this:
     *
     * {
     *    serviceFileErrorText : "This file is too big",
     *    creditCardErrorText  : "This selection requires an attachment"
     * }
     *
     * ... may have a fieldMap which looks like this:
     *
     * {
     *     serviceFileErrorText : "attachments",
     *     fileErrorText        : "attachments",
     *     creditCardErrorText  : "creditCard"
     * }
     *
     * @param {Object} errors
     * @param {Object} fieldMap Used to associate fields on the form with errors keys
     * @param {string} formName The name of the form
     */
    sendFormErrorsToAnalytics(errors, fieldMap, formName) {
        let messageObject = {
            form   : null,
            errors : {}
        };

        if (!errors || !_.isObject(errors) || _.isEmpty(errors)) {
            throw new Error(this.errors.sendFormErrorsToAnalytics.emptyErrors);
        }

        if (!fieldMap || !_.isObject(fieldMap) || _.isEmpty(fieldMap)) {
            throw new Error(this.errors.sendFormErrorsToAnalytics.emptyFieldMap);
        }

        if (!formName) {
            throw new Error(this.errors.sendFormErrorsToAnalytics.formName);
        }

        messageObject.form = formName;

        // map the errors to field names
        _.each(_.keys(errors), (key) => {
            let fieldName    = fieldMap[key];
            let errorMessage = errors[key];

            if (!_.isUndefined(fieldName)) {
                messageObject.errors[fieldName] = errorMessage;
            }

        });

        analyticsChannel.trigger('trackException', {
            fatal   : false,
            message : JSON.stringify(messageObject)
        });

    },

    /**
     * Determine whether to show/hide the "smart scroll" message for a table. If vertical
     * scroll bar is present on the tableContainer, the message will be shown.
     *
     * @param {Object} tableContainer
     * @param {Object} messageContainer
     */
    setVisibilityOnSmartScrollMessage(tableContainer, messageContainer) {

        // let nativeDomContainer;
        if (messageContainer && messageContainer instanceof Backbone.$ &&
            tableContainer && tableContainer instanceof Backbone.$ && tableContainer.length) {

        /**
              * Decision was made to not display the smart scroll message, so always setting
              * to hidden.  Leaving code in place but commented out to make it easier to enable
              * the smart scroll message again, if needed.
        **/

            messageContainer.addClass('hidden');


            // The only way to reliably detect a scroll bar
            // is by using scrollWidth and offsetWidth on the native DOM
            // element. jQuery's innerWidth() and outerWidth() were tested
            // but didn't reliably detect the overflow.
            // nativeDomContainer = tableContainer[0];

            // if (nativeDomContainer.scrollWidth > nativeDomContainer.offsetWidth) {
            //     messageContainer.removeClass('hidden');
            // } else {
            //     messageContainer.addClass('hidden');
            // }
        }
    },

    /**
     * Add 'targetuser' query parameter to URL if impersonate Web Id exist in user Module
     *
     * @param {string} url It can be  hash URL / HTTP URL
     * @return {string} url updated with 'targetuser' query parameter
     */
    addTargetUserQueryParamToURL(url) {

        if (!url) {
            throw new Error(this.errors.urlIsMissing);
        } else if (url.indexOf('#')!==0 && !this.isValidURL(url)) {
            throw new Error(this.errors.invalidURL);
        }

        const impersonatedWebId = userChannel.request('getImpersonatedWebId');

        if (!impersonatedWebId) {
            return url;
        }

        // Regex to check whether query param has 'targetuser'
        const regexHasAsInURL = new RegExp('([?&])targetuser=.*?(&|$)', 'i');

        // separator to add 'targetuser' query based on existence of ?
        const separator = url.indexOf('?') !== -1 ? '&' : '?';

        //add only if 'targetuser' query is not exist in queryParams
        if (!url.match(regexHasAsInURL)) {

            url =  url + separator + 'targetuser=' + impersonatedWebId;
        }/* else {
            // There will be another scenario that we may need to overwrite value of 
            // url query 'targetuser' with current value got from url
            // not sure whether this scenario exist or not.
            // So keeping in comment
            url =  url.replace(regexHasAsInURL, '$1targetuser=' + queryParamsObj.targetuser + '$2');
        }*/

        return url;
    },

    /**
     * Create final Hash URL / HTTP URL for href attribute.
     * @param  {string} pageLink  It can hash URL / HTTP URL.
     *                            Will be prefix hash 
     *                            If pageLink is missing hash / http
     *                            
     * @param  {object} parameters  An object to build URL query params
     * @return {string} pageLink Updated page Link;
     */
    buildHrefURL(pageLink, parameters) {
        
        let queryParams;

        if (!pageLink || !_.isString(pageLink)) {
            throw new Error (this.errors.pageLinkExpectedAsString);
        }

        // Separator to add 'targetuser' query based on existence of '?'
        const separator = pageLink.indexOf('?') !== -1 ? '&' : '?';

        // If pageLink is missing #
        if (pageLink.charAt(0) !== '#' && !pageLink.match(/^(http|https):\/\//gi) ) {
            pageLink = '#'+pageLink;
        }

        if (parameters && !_.isEmpty(parameters)) {
            queryParams = Backbone.$.param(parameters);
            pageLink = pageLink+separator+queryParams;
        }

        //add 'targetuser' query param if user in impersonation/delegation mode
        pageLink = this.addTargetUserQueryParamToURL(pageLink);

        return pageLink;
    },

    /**
     * Add target user to document url
     * while user in impersonated state.
     *
     * @param {string} url
     * @return {string} url Updated with adding targetuser param
     * @private
     */
    buildHrefUrlForDocument(url) {
        if (!url) {
            throw new Error(this.errors.missingURLParam);
        }
        const impersonatedWebId   = userChannel.request('getImpersonatedWebId');
        let separator;
        if (impersonatedWebId) {
            separator = url.indexOf('?') !== -1 ? '&' : '?';
            url = url + separator + 'targetuser='+ impersonatedWebId;
        }
        return url;
    },

    /**
     * Find all 'a' elements from the view passed in as a parameter to convert to determine
     * whether they should be converted to "#c" links. Adds the "targetuser" query parameter when
     * needed.
     *
     * @param {object} view
     */
    convertWcmLinks(view) {
        const _this = this;

        // Find all 'a' elements from WCM contents:
        //   1. Elements with href attribute with the URL pattern matched.
        //   2. Ignore the elements those are having CSS classes or data attributes listed below.
        const aElements = view.$el.find(
            'a[href^="/wps/wcm/connect/indcontent/OSO/"], '+
            'a[href^="/wps/wcm/connect/indcontent/oso/"], '+
            'a[href^="/wps/wcm/myconnect/indcontent/OSO/"], '+
            'a[href^="/wps/wcm/myconnect/indcontent/oso/"], '+
            'a[href^="#c/"], ' +
            'a[href^="/#c/"]'
        ).not(
            '.icon-pdf, '+
            '.icon-external,'+
            '.icon-email,'+
            '.icon-video,'+
            '[data-toggle="modal"]'
        );

        aElements.each( function (idx, aElement) {
            let hasPdfOrZipIcon;
            let href = Backbone.$(aElement).attr('href');

            // TODO: When existing WCM content has replaced the <i> tags
            // following the links with the ".icon-*" classes, the `hasPdfOrZipIcon`
            // as well as the `iconIndicatesViewableContent` function in `modules/behaviors.js`
            // can be removed.
            hasPdfOrZipIcon = Backbone.$(aElement).next().is('.fa-file-pdf-o,.fa-file-archive-o');

            // If the link is not followed by an <i> tag which indicates a link
            // to either a PDF or a ZIP archive, we can safely assume that the
            // content is HTML and convert the href to a "#c" link and/or add 'targetuser' param.
            if (!hasPdfOrZipIcon) {
                //replace with #c if URL prefix pattern is matched
                href = href.replace(
                    /\/wps\/wcm\/(?:my)?connect\/indcontent\/OSO/i,
                    '#c'
                );

                // Add 'targetuser' query param if user is in impersonate state
                href = _this.addTargetUserQueryParamToURL(href);

                Backbone.$(aElement).attr('href', href);
            }
        });
    },
    
    /**
     * Merge relative path with base url and build an absolute url
     * 
     * NOTE: Currently this method is not using anywhere since we dropped out
     * support on HATEOAS data for policy detail page. It can be removed along with
     * URI npm package added in vendor.js
     * 
     * @param {string} baseURL 
     * @param {string} relativePath
     * 
     * @return {string} absolute url
     */
    mergeRelativeToAbsoluteURL(baseURL, relativePath) {

        if (!baseURL || !baseURL.match(/^(http|https):\/\//i)) {
            throw new Error(this.errors.invalidBaseURL);
        } else if(!relativePath || !_.isString(relativePath)) {
            throw new Error(this.errors.invalidRelativePath);
        }

        // Add trailing slash if that missing
        let splitBaseURL = baseURL.split('?');
        if (!splitBaseURL[0].match(/\/$/)) {
            splitBaseURL[0] = splitBaseURL[0]+ '/';
        }
        if (splitBaseURL[1]) {
            baseURL = splitBaseURL[0]+'?'+splitBaseURL[1];
        } else {
            baseURL = splitBaseURL[0];
        }

        let url;
        if (typeof window.URL === 'function') {
            url = new URL(relativePath, baseURL).toString();
        } else {
            url = new URI(relativePath).absoluteTo(baseURL).toString();
        }

        return url;
    },

    /**
     * Generic function for fetching taxonomy roles or groups 
     * upon user selection for  WCM pages or Resource Center menu.
     * @param {boolean} isResourceFlag flag for checking 'Resource Center' menu presence
     * @returns Array<String> array of roles or groups
     */
    _getTAXRolesWithGroups: function(isResourceFlag = false) {
        let userWcmRoles = [];
        let prefix = '';
        const data = [
            // IMPORTANT:
            // Don't change the order of the items in this array!
            // It is sorted in order of priority so that the correct wcmRole
            // will be returned if the user has more than one of the listed
            // capabilities.
            { taxRole: 'Home_Office', capability: 'Home_Office' },
            { taxRole: 'Retail_Manager', capability: 'WCM_Retail_Manager' },
            { taxRole: 'Retail_Producer', capability: 'WCM_Retail_Producer' },
            { taxRole: 'IB_Manager', capability: 'WCM_IB_Manager' },
            { taxRole: 'IB_Producer', capability: 'WCM_IB_Producer' },
            { taxRole: 'CS_Manager', capability: 'WCM_CS_Manager' },
            { taxRole: 'CS_Producer', capability: 'WCM_CS_Producer' },
            { taxRole: 'CS_Bank_Manager', capability: 'WCM_CS_Bank_Manager' },
            { taxRole: 'CS_Bank_Producer', capability: 'WCM_CS_Bank_Producer' },
            { taxRole: 'Third_Party_BD_Manager', capability: 'WCM_Third_Party_BD_Manager' },
            { taxRole: 'Third_Party_BD_Producer', capability: 'WCM_Third_Party_BD_Producer' },
            { taxGroup: 'OAS_Representative', capability: 'WCM_OAS_Representative' }
        ];

        // Refactored code for handling TAX roles and groups 
        let i = 0;
        let taxRoleFlag = false;
        while (i < data.length) {
            if (userChannel.request('hasCapability', data[i].capability)) {
                if (!taxRoleFlag) {
                    if (data[i].taxRole) { // check if TAX-ROLE is present
                        prefix =  this._getPrefixFormat('taxRole',  isResourceFlag);
                        userWcmRoles.push(prefix + data[i].taxRole);
                        taxRoleFlag = true;
                    }
                }
                if (data[i].taxGroup) { // check if TAX-GROUP is present
                    prefix = this._getPrefixFormat('taxGroup', isResourceFlag);
                    userWcmRoles.push(prefix  + data[i].taxGroup);
                }
            }
            i++;
        }
        if (userWcmRoles.length === 0) {
            userWcmRoles = null;
        }
        return userWcmRoles;
    },

    /**
     * This function gives the prefix string based on the tax role and groups
     * upon user selected menu.
     * @param {string} taxData prefix string wrt tax role and group
     * @param {boolean} isResourceFlag flag required to check whether menu item selected 
     *                                 is 'Resource Center'
     * @returns prefix string
     */

    _getPrefixFormat(taxData = '',  isResourceFlag = false) {
        if (isResourceFlag) { // handle 'Resource Center' page 
            return '';
        } 
        let prefix = '/inddesign';
        return taxData === 'taxRole' ? prefix + '/TAX-Roles/' : prefix + '/TAX-Groups/';
    },

    /**
     * This function sets the targetUser parameter in cookie for impersonation
     * This cookie is needed by OSO Vintage for the Single User Experience
     * @param {string} targetUser prefix string for targetUser
     * @param {number} expiry Number of days for expiry of the cookie parameter default to 1-day. 
     *                               
     */
    createCookie(targetUser, expiry) {
        var cookieData = 'targetuser' + '=' + targetUser + 
        ';domain=.oneamerica.com;path=/;';
        //checking expiry condition on logout event and adding it to cookie
        if (expiry) {
            var now = new Date();
            now.setTime(expiry);
            cookieData = cookieData + 'expires=' + now.toUTCString() + ';';
        }
        document.cookie = cookieData;
       
    }	
    
};

module.exports = utilsUI;
