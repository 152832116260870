/* global Backbone:false */
/**
 * View used to the display errors for the requirement submission form.
 */

import template                           from '../templates/form-error-t.hbs';
import RequirementSubmissionFormViewModel from '../viewModels/requirement-submission-form-vm';

const FormErrorView = Backbone.Marionette.ItemView.extend({

    errors   : {
        invalidOptions : 'Options passed to FormErrorView must contain either a policyId or a model'
    },

    template : template,

    /**
     * Initialize the view.
     *
     * @param {Object=} options Optional parameter which contains the model to use. If not
     * supplied, a model will be created.
     */
    initialize (options) {

        if (!options.model && !options.policyId) {
            throw new Error(this.errors.invalidOptions);

        } else if (options.model) {
            this.model = options.model;

        } else {
            this.model = new RequirementSubmissionFormViewModel({
                policyId : options.policyId
            });
        }

        // respond to a change in the model by rendering
        this.listenTo(this.model, 'change:formErrorText', this.render);
    }

});

module.exports = FormErrorView;