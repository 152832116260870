// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return " id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"table-responsive-instruction hidden\""
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n    <div class=\"align-center\"><i class=\"ace-icon fa fa-hand-o-right inline gray-60\"></i>\r\n        <p class=\"text-info inline\">Swipe/scroll to view entire table</p>\r\n        <i class=\"ace-icon fa fa-hand-o-left inline gray-60\"></i>\r\n    </div>\r\n    <!-- /.align-center -->\r\n</div>\r\n<!-- /.table-responsive-swipe -->";
},"useData":true});
