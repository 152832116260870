/* global Backbone: false */
/**
 * A View Model to back to delegate-maintenance-vm module.
 *
 */
const DelegateMaintenanceViewModel = Backbone.Model.extend({
    defaults: {
        producerId: '',
        producerFullName: ''
    },
  
});

module.exports = DelegateMaintenanceViewModel;
