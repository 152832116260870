/* global Backbone:false, _:false */
/* eslint-disable max-len */

var config = require('../config/config');

const AppStructureModel = Backbone.Model.extend({

    defaults : {

        /*
        * A data structure that describes how the OSO application is
        * organized. It is used as a configuration attribute for various UI components,
        * allowing the components be reusable rather than being OSO-specific.
        *
        * icon - (optional) A string, naming a CSS class or Font Awesome icon.
        *        Look at src/css/font-awesome.css for the full list of Font Awesome icons.
        *
        * capability - (required for menu items that navigate to a page, but not for items
        *        that are only used as a parent for a submenu).
        *        An object which should have at least one of the below properties:
        *
        *        1. forSidebar (optional) 
        *            
        *            An array of objects considered to display item on sidebar.
        *            If it does not exist, it will be ignored.
        *                
        *            Each object in the array will contain one or more capabilities,
        *            which need to match with user capabilities to display on sidebar
        *            and to get access to that particular view.
        *            
        *            Capability 'all' mean, every user has access to that page.
        *            
        *        2. forPageAccess (optional) 
        *            If any user has any additional capabilities can be included here.
        *            Which will be considered while access check is failed against 'forSidebar'
        *            property.
        *
        * displayText - (required) A string that is displayed in the left nav sidebar (if
        *         the item has a capability.forSidebar array), and it is also the title sent
        *         to the analytics providers upon navigation.
        * 
        * 
        * tempNewIconWCMLink (optiional)  - Object - An object to define the temporary icon on the main menu link.
        *                         Oject contains class name and WCM URL which will return the "New" icon based 
        *                         on the time duration.
        *
        * alternateDisplayText (optional) - Object - An Object to define alternate string 
        *         to display on Menu. Object contain 'capability' and 'displayText'.
        *
        * subItems - An array of child app structure objects to be grouped together
        *        under the current object.
        *
        * link - (required for menu items that navigate to a page, but not for items
        *        that are only used as a parent for a submenu) A string that is used
        *        for the URL fragment identifier (hash) when the user clicks a link.
        *        It should be all lowercase, with '-' in place of spaces.
        *
        * view - (required for menu items that navigate to a page, but not for items
        *        that are only used as a parent for a submenu) a `require()` statement
        *        to load the view for the page.
        *
        * active - (optional) A boolean that indicates the "active" menu item, i.e. the
        *        menu item that reflects the current content page. There must only be
        *        ONE active item, and it must be a page item (an item with a view
        *        property), not a submenu parent.
        *
        * activeFor - (optional) An Array of 'link' values of other pages which do not
        *        have their own item displayed in the menu. Instead, when the app navigates
        *        to that other non-displayed link, this item (the one with the activeFor) is
        *        what gets highlighted in the sidebar menu.
        * 
        *        Important: Every link listed in an activeFor array *must* have its own
        *        item in the appStructure. That item will only have forPageAccess capabilities
        *        and not forSidebar capabilities since it will not itself be displayed in the
        *        sidebar.
        *
        * isExternal - (optional) When true, indicates that the menu item should open
        *               in a new window. An icon which indicates the link as external
        *               will be placed after the link text.
        *
        * isDisabled - (optional) When true, the link property becomes optional and the
        *              <a> tag that is rendered does not have an href property. Also,
        *              the anchor is given the property aria-disabled="true" and the
        *              parent <li> tag is given the "disabled" class.
        * 
        * addClass - (optional) Additional CSS class that can be added to <li> tag.
        * 
        * disableHandler - (optional) Disables navigation handling in the code.
        * 
        */
        structure : [
            {
                icon            : 'fa fa-arrow-left',
                displayText     : 'Original OneSource Online',
                link            : config.vintageURL + 'home.faces',
                addClass        : 'vintage-home',
                disableHandler  : true,
                capability  : {
                    forSidebar : [{
                        all : true
                    }]
                }                    
            },           
            {
                icon            : 'fa fa-home',
                capability    : {
                    forSidebar : [{
                        all : true
                    }]
                },
                displayText     : 'Home',
                link            : 'home',
                activeFor       : [
                    'c/ep/oso-1',
                    'c/ep/oso-2',
                    'c/ep/oso-3',
                    'c/ep/oso-4',
                    'c/ep/oso-5',
                    'error',
                    'producer-delegate-list',
                    'delegate-producer-list',
                    'producer-delegate-maintenance',
                    'producer-delegate-add',
                    'producer-delegate-edit',
                    // WCM News landing page and articles
                    'c/news',
                    'c/news/articles/**',
                    'c/touts/homepage/education-and-practice-development/news-tout/annual-report'
                    
                ],
                view            : require('../pages/home/views/home-v')
            }, {
                capability    : {
                    forPageAccess : {
                        all : true
                    }
                },
                link        : 'error',
                displayText : 'Error',
                view        : require('../pages/error/views/error-v')
            }, {
                capability    : {
                    forPageAccess : {
                        'OLS:DELEGATE' : true
                    }
                },
                link        : 'producer-delegate-list',
                displayText : 'Producer Delegate List',
                view        : require('../pages/delegate-access/views/delegate-producer-list-v')
            },
            {
                capability    : {
                    forPageAccess : {
                        'OLS:DELEGATE' : true
                    }
                },
                link        : 'delegate-producer-list',
                displayText : 'Delegate Producer List',
                view        : require('../pages/delegate-access/views/delegate-producer-list-v')
            }, 
            {
                capability    : {
                    forPageAccess : {
                        'Delegate_Maintenance' : true
                    }
                },
                link        : 'producer-delegate-maintenance',
                displayText : 'Delegate Maintenance',
                view        : require('../pages/delegate-maintenance/views/delegate-maintenance-v')
            },
            {
                capability    : {
                    forPageAccess : {
                        'Delegate_Maintenance' : true
                    }
                },
                link        : 'producer-delegate-add',
                displayText : 'Add Delegate',
                view        : require('../pages/delegate-maintenance/views/add-delegate-v')
            },
            {
                capability    : {
                    forPageAccess : {
                        'Delegate_Maintenance' : true
                    }
                },
                link        : 'producer-delegate-edit',
                displayText : 'Edit Delegate',
                view        : require('../pages/delegate-maintenance/views/edit-delegate-v')
            }, 
            {
                icon        : 'fa fa-pencil-square-o',
                displayText : 'My Business',               
                subItems: [
                    {
                        capability      : {
                            forSidebar: [{
                                'Home_Office' : true,
                                'Policy_Search_by_Producer' : true
                            }]
                        },
                        displayText           : 'Search',
                        link                  : 'search',
                        view                  : require(
                            '../pages/policy/views/policy-search-page-v'
                        ),
                        defaultMyBusinessPage : true,
                        activeFor             : [
                            'pending-policies',
                            'policies',
                            'pending-policy-manager',
                            'policy-manager',
                            'pending-policy-detail',
                            'policy-detail',
                            'producer-policy-list',
                            'requirement-submission'
                        ]
                    }, {
                        capability        : {
                            forSidebar : [{
                                'Policy_Search_by_Producer' : true,
                                'Home_Office' : false,
                            }],
                            forPageAccess : {
                                'Policy_Search_by_Producer' : true
                            }
                        },
                        displayText             : 'Policies',
                        link                    : 'policy-manager',
                        view                    : require(
                            '../pages/policy/views/policy-manager-page-v'
                        ),
                        defaultMyBusinessPage   : true,
                        activeFor               : [
                            'org-pending-policies',
                            'org-policies',
                            'pending-policies',
                            'policies',
                            'pending-policy-detail',
                            'policy-detail',
                            'pending-policy-manager',
                            'producer-policy-list',
                            'requirement-submission'
                        ]
                    }, {
                        capability        : {
                            forPageAccess : {
                                'Policy_Search_by_Producer' : true
                            }
                        },
                        displayText             : 'Policies',
                        link                    : 'pending-policy-manager',
                        view                    : require(
                            '../pages/policy/views/policy-manager-page-v'
                        ),
                        defaultMyBusinessPage   : true
                    }, {
                        capability        : {
                            forSidebar : [{
                                'Policy_View' : true,
                                'Policy_Search_by_Producer' : false,
                                'Home_Office' : false
                            }],
                            forPageAccess : {
                                'Policy_View' : true
                            }
                        },
                        displayText           : 'Policies',
                        link                  : 'policies',
                        view                  : require(
                            '../pages/policy/views/policy-list-page-v'
                        ),
                        defaultMyBusinessPage : true,
                        activeFor             : [
                            'pending-policies',
                            'org-pending-policies',
                            'org-policies',
                            'pending-policy-manager',
                            'policy-manager',
                            'pending-policy-detail',
                            'policy-detail',
                            'producer-policy-list',
                            'requirement-submission'
                        ]
                    }, {
                        capability        : {
                            forPageAccess : {
                                'Policy_View' : true
                            }
                        },
                        displayText           : 'Policies',
                        link                  : 'pending-policies',
                        view                  : require(
                            '../pages/policy/views/policy-list-page-v'
                        ),
                        defaultMyBusinessPage : true
                    }, {
                        capability        : {
                            forPageAccess : {
                                'Policy_View' : true
                            }
                        },
                        displayText           : 'Org Policies',
                        link                  : 'org-policies',
                        view                  : require(
                            '../pages/policy/views/policy-list-page-v'
                        )
                    }, {
                        capability        : {
                            forPageAccess : {
                                'Policy_View' : true
                            }
                        },
                        displayText           : 'Org Pending Policies',
                        link                  : 'org-pending-policies',
                        view                  : require(
                            '../pages/policy/views/policy-list-page-v'
                        )
                    }, {
                        capability  : {
                            forPageAccess : {
                                'Policy_View' : true
                            }
                        },
                        displayText : 'Policy Detail',
                        link        : 'policy-detail',
                        view        : require(
                            '../pages/policy/views/policy-detail-v')
                    }, {
                        capability  : {
                            forPageAccess : {
                                'Policy_View' : true
                            }
                        },
                        displayText : 'Pending Policy Detail',
                        link        : 'pending-policy-detail',
                        view        : require(
                            '../pages/policy/views/policy-detail-v')
                    }, {
                        capability : {
                            forPageAccess : {
                                'Policy_Search_by_Producer': true
                            }
                        },
                        displayText : 'Producer Policy List',
                        link        : 'producer-policy-list',
                        view        : require('../pages/policy/views/policy-list-page-v')
                    }, {
                        capability : {
                            forPageAccess : {
                                'Policy_View' : true
                            }
                        },
                        displayText : 'Requirement Submission',
                        link        : 'requirement-submission',
                        view        : require(
                            '../pages/requirementSubmission/views/requirement-submission-page-v'
                        )
                    }, {
                        capability : {
                            forSidebar : [{
                                'BoB_Download_View': true
                            }]
                        },
                        displayText : 'Book of Business Download',
                        link : 'c/deeplink/downloads/bobDownload.faces',
                        view        : require('../pages/deeplinks/views/vintage-user-experience-v'),
                        defaultMyBusinessPage : true
                    }, {
                        capability : {
                            forSidebar : [{
                                'Life_Events_View': true
                            }]
                        },
                        displayText : 'Life Events',
                        link : 'c/deeplink/lifeevents/leSearch.faces',
                        view        : require('../pages/deeplinks/views/vintage-user-experience-v'),
                        defaultMyBusinessPage : true
                    },
                    {
                        capability  : {
                            forSidebar : [{
                                'Commissions_Daily_View(disabled)' : true,
                            }, {  
                                'OneDailyComp_View(disabled)' : true
                            }]
                        },
                        isDisabled  : true,
                        icon        : '',
                        displayText : 'Commissions',
                        addClass:  config.menuItemFlag.commissions
                    },
                    {
                        capability  : {
                            forSidebar : [{
                                'Commissions_Daily_View' : true,
                            }, {  
                                'OneDailyComp_View' : true
                            }]
                        },
                        icon        : '',
                        displayText : 'Commissions',
                        defaultMyBusinessPage : true,
                        addClass:  config.menuItemFlag.commissions,
                        subItems: [
                            {
                                capability      : {
                                    forSidebar: [{
                                        'Commissions_Daily_View' : true
                                    }]
                                },
                                icon                  : 'fa fa-arrow-right',
                                displayText           : 'Commissions - Daily',
                                link                  : 'c/deeplink/dailycommissions/default.faces',
                                view                  :  require('../pages/deeplinks/views/vintage-user-experience-v'),                       
                                defaultMyBusinessPage : true,
                                addClass              : config.menuItemFlag.dailyCommission
                            },
                            {
                                capability      : {
                                    forSidebar: [{
                                        'OneDailyComp_View' : true
                                    }]
                                },
                                icon                  : 'fa fa-arrow-right',
                                displayText           : 'One Daily Compensation',
                                link                  : 'c/deeplink/onedailyactivity/default_oneDaily.faces',
                                view                  :  require('../pages/deeplinks/views/vintage-user-experience-v'),                       
                                defaultMyBusinessPage : true,
                                addClass              : config.menuItemFlag.oneDailyCompViewActivity
                            }
                        ]
                    }
                ]
            }, {
                icon        : 'mybenefits',
                displayText : 'My Benefits',
                link        : 'https://api.oneamerica.com/oso/secure/web/sso/pinpoint?RelayState=https://oneamerica.pinpointglobal.com/Portal/resourcepages/gainfocenterpages/CompContractBen/benefit/general-agent',
                isExternal  : true,
                capability  : {
                    forSidebar : [{
                        'Benefits_Retail_GeneralAgent' : true
                    }]
                }
            }, {
                icon        : 'mybenefits',
                displayText : 'My Benefits',
                isDisabled  : true,
                isExternal  : true,
                capability  : {
                    forSidebar : [{
                        'Benefits_Retail_GeneralAgent(disabled)' : true
                    }]
                }
            }, {
                icon        : 'mybenefits',
                displayText : 'My Benefits',
                link        : 'c/my-benefits',
                view        : require('../modules/wcm-content/views/wcm-content-v'),
                capability  : {
                    forSidebar : [{
                        'Benefits_Retail' : true
                    }]
                }
            }, {
                icon        : 'fa fa-thumbs-o-up',
                displayText : 'My Compensation',
                link        : 'c/my-compensation',
                view        : require('../modules/wcm-content/views/wcm-content-v'),
                capability  : {
                    forSidebar : [{
                        'WCM_Retail_Manager' : true,
                    }, {
                        'WCM_Retail_Producer': true
                    }]
                }
            }, {
                icon        : 'fa fa-briefcase',
                displayText : 'Resource Center',
                link        : 'resourcecenter',
                view        : require('../pages/resourcecenter/views/resource-center-layout-v'),
                capability  : {
                    forSidebar : [{
                        'Home_Office': true
                    }, {
                        'WCM_CS_Bank_Manager': true
                    }, {
                        'WCM_CS_Bank_Producer': true
                    }, {
                        'WCM_CS_Manager': true
                    }, {
                        'WCM_CS_Producer': true
                    }, {
                        'WCM_Retail_Manager': true
                    }, {
                        'WCM_Retail_Producer': true
                    }, {
                        'WCM_IB_Manager': true
                    }, {
                        'WCM_IB_Producer': true
                    }]
                }
            }, 
         
            {
                icon        : 'fa fa-bar-chart',
                displayText : 'Investments',
                subItems: [
                    {
                        capability      : {
                            forSidebar: [{
                                'Unit_Values_View': true
                            }]
                        },
                        icon                  : '',
                        displayText           : 'Unit Values',
                        link                  : 'c/deeplink/unitvalues/fundSearch.faces',
                        view                  : require('../pages/deeplinks/views/vintage-user-experience-v'), 
                        addClass    		  : config.menuItemFlag.unitValues
    
                    }],
            },
        
            {
                icon        : 'fa fa-flask',
                displayText : 'UI Test',
                subItems    : [
                    {
                        capability  : {
                            forSidebar : [{
                                'user.test' : true
                            }]
                        },
                        icon        : 'fa fa-tachometer',
                        displayText : 'EasyPieChart Dials',
                        link        : 'ui-test',
                        view        : require('../pages/uiTest/views/ui-test-v')
                    }, {
                        capability  : {
                            forSidebar : [{
                                'user.test' : true,
                                'Producer_Performance_View' : true
                            }]
                        },
                        icon        : 'fa fa-tachometer',
                        displayText : 'Performance Center Vintage',
                        link        : 'performance-center-vintage-ui-test',
                        view        : require('../pages/uiTest/views/performance-center-vintage-v')
                    }, {
                        capability  : {
                            forSidebar : [{
                                'user.test' : true
                            }]
                        },
                        icon        : 'fa fa-file-word-o',
                        displayText : 'WCM',
                        link        : 'wcm-test',
                        view        : require('../pages/uiTest/views/wcm-test-v')
                    }, {
                        capability  : {
                            forSidebar : [{
                                'user.test' : true
                            }]
                        },
                        icon        : 'fa fa-file-word-o',
                        displayText : 'Error Page Test',
                        link        : 'error-page-test',
                        view        : require('../pages/uiTest/views/ui-error-handler-test-v')
                    }, {
                        capability  : {
                            forSidebar : [{
                                'user.test' : true
                            }]
                        },
                        isDisabled  : true,
                        icon        : 'fa fa-file-word-o',
                        displayText : 'Disabled Item'
                    }, {
                        capability  : {
                            forSidebar : [{
                                'user.test' : true
                            }]
                        },
                        icon        : 'fa fa-file-word-o',
                        displayText : 'ES6 feature test',
                        link        : 'es6-feature-test',
                        view        : require('../pages/uiTest/views/es6-feature-test-v')
                    }, {
                        icon        : 'fa fa-info-circle',
                        displayText : 'FAQs & Resources',
                        subItems    : [
                            {
                                capability  : {
                                    forSidebar : [{
                                        'user.test' : true
                                    }]
                                },
                                displayText : 'Products',
                                link        : 'products',
                                view        : require('../pages/products/views/products-v')
                            }, {
                                capability  : {
                                    forSidebar : [{
                                        'user.test' : true
                                    }]
                                },
                                displayText : 'My Business',
                                link        : 'mybiz',
                                view        : require('../pages/myBusiness/views/mybiz-v')
                            }, {
                                displayText : 'Page One',
                                subItems    : [
                                    {
                                        capability  : {
                                            forSidebar : [{
                                                'user.test' : true
                                            }]
                                        },
                                        icon        : 'fa fa-arrow-right',
                                        displayText : 'Page 1A',
                                        link        : 'pageone-1a',
                                        view        : require('../pages/page1/views/page1a-v')
                                    }, {
                                        capability  : {
                                            forSidebar : [{
                                                'user.test' : true
                                            }]
                                        },
                                        icon        : 'fa fa-arrow-right',
                                        displayText : 'Page 1B',
                                        link        : 'pageone-1b',
                                        view        : require('../pages/page1/views/page1b-v')
                                    }
                                ]
                            }
                        ]
                    }, {
                        icon        : 'fa fa-tachometer',
                        capability  : {
                            forSidebar : [{
                                'user.test' : true
                            }]
                        },
                        displayText : 'Hello',
                        link        : 'hello',
                        view        : require('../pages/hello/views/hello-v')
                    }, {
                        icon        : '',
                        capability  : {
                            forSidebar : [{
                                'user.test'         : true,
                                'HO_ViewAsProducer' : true
                            }]
                        },
                        displayText : 'View As Producer',
                        link        : 'view-as-producer-test',
                        view        : require('../pages/uiTest/views/view-as-producer-test-v')
                    }, {
                        icon        : '',
                        capability  : {
                            forSidebar : [{
                                'user.test'         : true
                            }]
                        },
                        displayText : 'Analytics Test',
                        link        : 'analytics-test',
                        view        : require('../pages/uiTest/views/analytics-test-v')
                    },
                    {
                        icon        : '',
                        capability  : {
                            forSidebar : [{
                                'user.test'         : true
                            }]
                        },
                        displayText : 'Web Push Test',
                        link        : 'web-push-test',
                        view        : require('../pages/uiTest/views/web-push-v')
                    }
                ]
            }
        ]
    },

    errors : {
        structureShouldbeArray          : 'Structure passed should be an Array',
        viewIsNotInstanceOfBackboneView : 'View should be an instance of Backbone.View',
        capabilitiesShouldbeArray       : 'capabilities parameter must be an Array'
    },

    initialize() {

        //setting up a channel to share data from appStructure
        this.appStructureChannel = Backbone.Radio.channel('appStructure');

        //Register channel callback to share page Data
        // this -> used to get current context from appStructure
        this.appStructureChannel.reply('getPageDataByKeyValue', this._getPageDataByKeyValue, this);
    },

    /**
     * Method get page data matching 'page' string
     * @param  {string} keyName  Name of the key
     * @param  {string/boolean/number} value  value to compare
     *
     * @return {object}      First matched will returned as Page data
     */
    _getPageDataByKeyValue(keyName, value) {

        let pageData;

        const iterateFilteredStructure = (item) => {
            if (item.subItems) {
                _.each(item.subItems, (subItem) => {
                    if (!pageData) {
                        iterateFilteredStructure(subItem);
                    }
                });
            } else {
                if (item[keyName] && item[keyName] === value) {
                    pageData = item;
                }
            }
        };

        if (this.filteredAppStructure) {
            _.each(this.filteredAppStructure, (item) => {
                if (!pageData) {
                    iterateFilteredStructure(item);
                }
            });

        }
        return pageData;
    },

    /**
     * Appending WCM navigation structure to application structure
     * Each item in structure require to add new property (view) if that
     * item doesn't have subItems
     *
     * @param {Array} structure Navigation structure to append with appStructure
     * @param {object} view An object Backbone view object
     *
     * @return {object} model will be updated items from wcmStructure model
     */
    appendStructure(structure, view) {
        const _this = this;

        //recursive method to iterate structure passed
        const iterateStructure = (item) => {
            if (item.subItems) {
                _.each(item.subItems, (subItem) => {
                    iterateStructure(subItem);
                });
            } else {
                if (view && view.prototype && view.prototype instanceof Backbone.View) {
                    item.view = view;
                } else {
                    throw new Error (_this.errors.viewIsNotInstanceOfBackboneView);
                }
            }
        };

        if (structure && Array.isArray(structure)) {

            //adding 'view' property to each nav item in the structure
            //for those aren't have subitems
            _.each(structure, (item) => {
                iterateStructure(item);
            });

            this.set('structure',
                this.get('structure').concat(structure)
            );

        } else {
            throw new Error (this.errors.structureShouldbeArray);
        }
    },

    /**
     * Filter appstructure based on user capabilities mapped.
     * 
     * @param {Array} [capabilities]
     * 
     * @return {Array} filteredAppStructure
     */
    filterForSidebar(capabilities) {

        if (!capabilities) {
            capabilities = [];
        } else if (!Array.isArray(capabilities)) {
            throw new Error(this.errors.capabilitiesShouldbeArray);
        }

        const _this = this;
        const appStructure = this.get('structure');
        this.filteredAppStructure = [];

       /**
        * Iterate menu items
        *
        * @param menuItems - object - current item
        * @param isSubItem - boolean - confirmation on whether its a subitems
        * @param parentItem - object - parent object to push subitems
        */
        const iterateMenuItems = (menuItem, isSubItem, parentItem) => {
            let currentItem;
            let hasCapability = false;
            let index;
            let menuData = {};
            let capabilitiesForSidebar;
            let hasDupNavItemByDisplayText;
          
            //if its first time and doesn't have subItem object
            if (isSubItem && parentItem !== undefined && !parentItem.subItems) {
                parentItem.subItems = [];
            }

            //Check whether menuItem has capability and it match exactly with
            //existing user capabilities
            if (menuItem.capability) {

                capabilitiesForSidebar = menuItem.capability.forSidebar;

                // If menuItem has forSidebar props, this item should only displayed
                // if all flags match with user capabilities.
                if (capabilitiesForSidebar && Array.isArray(capabilitiesForSidebar)) {

                    for (let i = 0; i < capabilitiesForSidebar.length; i++) {
                        let sidebarCapability = capabilitiesForSidebar[i];

                        //if capability is 'all' should display to everyone
                        if (_.has(sidebarCapability, 'all')) {
                            hasCapability = true;

                            //should match all capability with user capabilities
                            //to display on side bar
                        } else {
                            /* eslint-disable no-loop-func */
                            hasCapability = _.all(sidebarCapability, (flag, capability) => {
                                return (flag === _.contains(capabilities, capability));
                            });
                            /* eslint-enable no-loop-func */
                        }

                        // If we found a match, break out of the loop
                        if (hasCapability) {
                            break;
                        }
                    }
                }
            }

            //Checking menuItem has this capability or a parent item
            if (hasCapability || (!menuItem.capability && menuItem.subItems )) {

                //adding each available objects to menu data except subitem
                //which will be added while iterating it
                for(let key in menuItem) {
                    if (key !=='subItems' ) {
                        menuData[key] = menuItem[key];
                    }
                }

                if (isSubItem) {

                    // If there are duplicate nav items with same displayText at same level
                    // and user has capabilities mapped with these specific nav items, 
                    // app will be showing only first item in side bar
                    // Considering nav item ordered in a priority wise from top to bottom
                    if (parentItem !== undefined) {
                        hasDupNavItemByDisplayText = _this._getDupNavItemFlagByDisplayText(
                            parentItem.subItems, menuData.displayText
                        );
                    }
                  
                    if (!hasDupNavItemByDisplayText && parentItem !== undefined && parentItem.subItems) {
                        index = parentItem.subItems.length;
                        currentItem = parentItem.subItems[index] = menuData;
                    }

                } else {
                    hasDupNavItemByDisplayText = _this._getDupNavItemFlagByDisplayText(
                        _this.filteredAppStructure, menuData.displayText
                    );
                    if (!hasDupNavItemByDisplayText) {
                        index = _this.filteredAppStructure.length;
                        currentItem = _this.filteredAppStructure[index] = menuData;
                    }
                }

                //start iterating subitems if that exist
                if (menuItem.subItems && menuItem.subItems.length) {
                    _.each(menuItem.subItems, (subItem) => {
                        iterateMenuItems(subItem, true, currentItem);
                    });

                    //if no stubs the trim of that parent
                    if (currentItem && !currentItem.subItems.length && parentItem) {
                        parentItem.subItems.splice(index, 1);
                    }
                }
            }

            //delete parent node if there is no submenu
            if (_this.filteredAppStructure[index] &&
                    _this.filteredAppStructure[index].subItems &&
                    !_this.filteredAppStructure[index].subItems.length) {
                _this.filteredAppStructure.splice(index, 1);
            }

        };

        //start iterating menu items stored in appStructure
        _.each(appStructure, (menuItem, index) => {
            iterateMenuItems(menuItem);
        });

        return this.filteredAppStructure;
    },

    /**
     * Basically App never expected Duplicated Navgation Item at same level
     * This method to find nav entries which already added to filtered appStructure
     * based on user capabilities.
     * @param {Array} menuItems array of menu item objects
     * @param {string} displayText to search for
     * @returns {boolean} 
     */
    _getDupNavItemFlagByDisplayText (menuItems, displayText) {
        return menuItems.some(item => item.displayText === displayText);
    }

});

module.exports = AppStructureModel;
