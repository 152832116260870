/* global require */

/**
* Extending Performance center dial chart Base view for APR
*/

var utils  = require('../../../utils/utils');
var PerformanceCenterDialBaseView = require('./performance-center-dial-base-v');
var performanceCenterDialAPRTemplate 
    = require('../templates/performance-center-dial-lifelives-apr-t.hbs');

var PerformanceCenterDialAPRView = PerformanceCenterDialBaseView.extend({
    template: performanceCenterDialAPRTemplate,
    /**
    * Build chart options for dial chart
    */
    buildDialChartOptions: function () {

        var remainingQualifyingNumber = utils.isoCurrencyToNumber(
            this.model.get('remainingQualifyingFyc'), false, true
        );
        this.model.set('remainingQualifyingNumber', remainingQualifyingNumber);

        var requiredQualifyingFycNumber = utils.isoCurrencyToNumber(
            this.model.get('requiredQualifyingFyc'), true, true
        );

        var actualAdjustedYtdFycNumber = utils.isoCurrencyToNumber(
            this.model.get('actualAdjustedYtdFyc'), false, true
        );
        this.model.set('actualAdjustedYtdFycNumber', actualAdjustedYtdFycNumber);

        //get signs
        var actualAdjustedYtdFycSign  = utils.signOfNumber(actualAdjustedYtdFycNumber);
    
        //check marks
        if(this.model.get('qualifyingFycFlag') === true ){
            this.qualifyingCheckMark=true;
        }

        // Show Comparison bullet only when apr status is not
        // FQ or New-Agent
        if (this.model.get('actualAprStatus') !== 'FQ' 
                && this.model.get('actualAprStatus') !== 'New-Agent') {
            this.model.set('showComparisonBullet', true);
        }

        // Add 'type' flag to APR model ('retail' is default for APR)
        this.model.set('type', 'retail');
        
        this.dialChartOptionsOne = {
            chartType: 'single',
            outerValue:actualAdjustedYtdFycNumber,
            classLabelOne: 'chart-text-one',
            classLabelTwo: 'chart-text-two lighter',
            textLabelOne: this.model.get('actualAdjustedYtdFyc'),
            textLabelTwo: 'Qualifying FYC',
            checkMark: this.qualifyingCheckMark,
            chartOptions: {
                maxValue:requiredQualifyingFycNumber,
                scaleColor: '#ffffff',
                centerLabel: {
                    animate: true,
                    elementSelector: '.chart-text-one',
                    sign: actualAdjustedYtdFycSign,
                    formatFunction: utils.formatAsCurrency,
                    round: true
                }
            }
        };
    }
});

module.exports = PerformanceCenterDialAPRView;