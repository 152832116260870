/* global Backbone:false, Marionette:false, _:false */

var checkpoint                  = require('../../../modules/checkpoint/checkpointModule');

var PolicyListView              = require('./policy-list-v');
var PolicyListProducerInfoView  = require('./policy-list-producer-info-v');
var ProducerPolicyListInfoView  = require('./producer-policy-list-info-v');
var template                    = require('../templates/policy-list-page-t.hbs');
var utils                       = require('../../../utils/utils');

const userChannel = Backbone.Radio.channel('user');

var PolicyListPageView = Marionette.LayoutView.extend({

    template : template,

    regions  : {
        policyListRegion : '#policy-list-region'
    },

    initialize : function initialize (options) {
        
        var producerOrgId = null;
        var producerId    = null;
        var searchEnabled = true;
        var inforceServiceRequestEnabled = true;
        var tab;
        var tabState      = {};

        this.checkpoint = checkpoint;
        this.model      = new Backbone.Model({
            showProducerInfoRegion: false
        });

        // Set up vars based on stateObj to pass to policy list view
        if (options && options.stateObj) {

            if (options.stateObj.subpages) {
                tab = options.stateObj.subpages[0];
            }

            tabState   = utils.parseSearchParams(options.stateObj);
            
            //set producer id and producerOrgId based on state data availability 
            producerId = options.stateObj.producerId;
            producerOrgId = options.stateObj.producerOrgId;

            //reset subpage with actual hash
            //which remove all other unwanted hash
            if (tab) {
                options.stateObj.subpages = [tab];
            }

            this.checkpoint.writeCheckpoint(options.stateObj, true);

            // if we have a producerId, then we do not show the search tab (the user is viewing
            // another producer's pending list)
            if (producerId || producerOrgId) {
                searchEnabled = false;
            }

        //write default subpage
        } else {
            this.checkpoint.writeCheckpoint({subpages : [tab]}, true);  
        }

        // set default tab if stateObject doesn't have tab data
        if (!tab) {
            if (searchEnabled) {
                tab = 'search';
            } else {
                tab = 'pending';
            }
        }

        const hasHOCapability = userChannel.request('hasCapability', 'Home_Office');

        // don't display if HO
        if (hasHOCapability) {
            inforceServiceRequestEnabled = false;
        }

        //if producerOrgId exist in stateObj
        if (producerOrgId) {
            this.pendingPolicyListView = new PolicyListView({
                currentTab    : tab,
                producerOrgId : producerOrgId,
                tabState      : tabState
            });

            //set new region for Producer Info
            //and set listener for policyListView to update producer Info
            this.model.set({
                producerOrgId               : producerOrgId,
                showOrgProducerInfoRegion   : true
            });
            this.addRegion('orgsProducerInfoRegion', '#orgs-producer-info-region');
            this.listenTo(
                this.pendingPolicyListView,
                'setProducerInfo',
                this._setOrgProducerInfo
            );

        // otherwise show 
        } else {
            this.pendingPolicyListView = new PolicyListView({
                currentTab    : tab,
                producerId    : producerId,
                searchEnabled : searchEnabled,
                inforceServiceRequestEnabled : inforceServiceRequestEnabled,
                tabState      : tabState
            });
        }


        this.listenTo(this.pendingPolicyListView, 'stateChange', this._setCheckpoint);
        this.listenTo(this.pendingPolicyListView, 'showPolicy', this._showPolicy);

        //from producer search result
        if (!producerOrgId) {
            this.model.set({
                producerId             : producerId,
                showProducerInfoRegion : true
            });
            this.addRegion('producerInfoRegion', '#producer-info-region');
            this.listenTo(
                this.pendingPolicyListView,
                'setProducerInfo',
                this._setProducerInfo
            );
        }
    },


    onBeforeShow : function onBeforeShow () {
        this.showChildView('policyListRegion', this.pendingPolicyListView);
        if (this.model.get('showOrgProducerInfoRegion')) {
            this._createOrgProducerInfo(this.model.get('producerOrgId'));
        }
        if (this.model.get('showProducerInfoRegion')) {
            this._createProducerInfo(this.model.get('producerId'));
        }
    },


    /**
     * Set the producer info in the producerInfoRegion.
     * @param info - the info to display in the view
     * @private
     */
    _setProducerInfo : function _setProducerName(info) {
        if (_.isObject(info)) {
            this.producerInfoView.showName(info.fullName);
            this.$el.find('#producer-info-region').removeClass('hidden');
        }
    },


    _createProducerInfo : function _createProducerInfo(producerId) {
        this.producerInfoView = new ProducerPolicyListInfoView({
            model    : new Backbone.Model({
                id : producerId
            })
        });
        this.showChildView('producerInfoRegion', this.producerInfoView);
    },

    _createOrgProducerInfo : function _createOrgProducerInfo(producerId) {
        this.orgsPendingPolicyListProducerInfoView = new PolicyListProducerInfoView({
            model : new Backbone.Model({
                id : producerId
            })
        });
        this.showChildView('orgsProducerInfoRegion', this.orgsPendingPolicyListProducerInfoView);
    },

    /**
     * Set Organization producer info 
     */
    _setOrgProducerInfo : function _setOrgProducerInfo (info) {
        if (_.isObject(info)) {
            this.orgsPendingPolicyListProducerInfoView.showName(info.fullName, true);

            this.$el.find('#orgs-producer-info-region').removeClass('hidden');
        }
    },


    /**
     * Replace the hash when the user changes the state (paging, sorting, etc)
     * @param {object} [changedState] The properties that have changed
     * @private
     */
    _setCheckpoint: function _setCheckpoint(changedState) {

        var stateObj = {};
        if (this.model.has('producerId')) {
            stateObj.producerId = this.model.get('producerId');
        }

        if (changedState) {

            // if switching to policy list, need to query for current sub tab
            var subTab = this.pendingPolicyListView.getCurrentTab();
            stateObj.subpages = [subTab];

            stateObj = _.extend(stateObj, changedState);
        }

        // If there is 'targetuser' in the stateObj please add it back
        // Delegate and VaP access 
        if (this.options.stateObj && this.options.stateObj.targetuser) {
            stateObj.targetuser = this.options.stateObj.targetuser;
        }

        this.checkpoint.writeCheckpoint(stateObj, true);

    },


    /**
     * Navigate to the policy detail page
     * @param policyId the ID of the policy to display
     * @private
     */
    _showPolicy : function _showPolicy (policyId) {
        this.trigger('nav', '#policy-detail?policyId=' + policyId);
    }

});

module.exports = PolicyListPageView;