/* global Marionette:false, _:false */

/**
 * 
 * A Model Parser class to parse 1035 Exchange History data
 * 
 */
var policy1035ExchangeHistory = Marionette.Object.extend({

    errors : {
        invalidResponse : 
            'exchange1035TransferComments: exchangeHistory data should be valid object'
    },

    initialize : function(exchangeHistory) {
        if (!exchangeHistory || !_.isObject(exchangeHistory)) {
            throw new Error(this.errors.invalidResponse);
        }
        this.exchangeHistory = exchangeHistory;
    },

    /**
     * A method to sort Comments and Transfer history 
     * 
     * @return {object} Updated service response
     * 
     */
    sort : function () {

        //sort comments by Date column in Descending order
        _.each(this.exchangeHistory, function (history){
            if (history.comments) {
                history.comments = _.sortBy(history.comments, 'date').reverse();
            }
        });

        //initial Group sorting using Company Name by Ascending order
        //and Policy Number by Ascending order
        return  _.sortBy(this.exchangeHistory, function (history) {
            return [history.otherCarrierName, history.otherCarrierPolicyNumber];
        });
    },

    /**
     * Determines the number of pending/received exchanges. A history is
     * considered to be in "Pending" status when the amountReceived
     * has a value of "USD 0.00."
     *
     * @return {object}
     */
    getStatusCounts : function () {
        var counts = {
            pending  : 0,
            received : 0
        };

        _.each(this.exchangeHistory, function(history) {
            if (typeof history.amountReceived === 'string' 
                    && history.amountReceived.trim() === 'USD 0.00') {
                counts.pending++;
            } else {
                counts.received++;
            }
        });

        return counts;
    }
});

module.exports = policy1035ExchangeHistory;