/*global $:false, _:false */
/*
    Idle Watcher
    Monitors user activity, and periodically lets Siteminder on the server know that the
    user is still active so that the Siteminder session won't time out.

    Usage:      var idleWatcher = require('./modules/idle/idleModule');

                //option 'callback' will be a method to call after idle timeout
                //currently provided 'logout' method from userModule
                idleWatcher.start({
                    callback: userModule.logout
                });

*/

var IdleModule = (function () {

    var idleTimerID = null;

    //1 min, in millis
    var oneMin = 60000;

    // 30 minutes, in millis
    var halfHour = 1800000;

    //singleton object of idle module
    var idleModule;

    var idleCallback;

    //Idle master class
    var Idle = function () {

        /**
        * Send a GET request to let Siteminder know that the user is still active.
        * To avoid cross-origin problems, the GET request must come from the browser itself
        * and not from JavaScript. We accomplish this by changing the src property of a
        * hidden image. The browser will request the new image file, thus generating the GET.
        *
        * Since the request is a direct GET on the browser, if the session has expired,
        * SiteMinder will return a 30x which the browser will follow, forcing the user to
        * log in again. This is also why it CANNOT be an XHR - if it was, the browser would
        * just follow the XHR versus redirecting to the new page.
        */
        var ping = function() {
            // Insert the image tag into the DOM if it's not already there
            var imageEl = $('#smping');
            if (imageEl.length === 0) {
                imageEl = $('<img>').prop('id', 'smping').prop('alt', '').hide().appendTo('body');
            }
            
            // Set the src of the image to the blank image, adding a timestamp querystring
            // param to force the image to reload
            /* Date.now() = IE9 AND ABOVE. EVAN 6/17/2014 */
            imageEl.prop('src', '/api/oso/secure/servlet/BlankImage?_=' + new Date().valueOf());
        };

        /**
        * Event handler triggered on user activity.
        */
        var activityDetected = function (event) {

            // By default, this event handler will execute once for each event type: click,
            // scroll, and keydown. We only want to react to *one* of those events, so cancel
            // the event listener here.
            $(window).off('click scroll keydown', activityDetected);

            // Restart the idle timer
            this._startIdleTimer();

            // Notify Siteminder that the user is active
            ping();

            // Restart the activity listener in one minute
            window.setTimeout(_.bind(this._startActivityListener, this), oneMin);
        };

        /**
        * User has been idle for too long
        */
        var idleTimeout = function () {
            if(idleCallback){
                idleCallback();
            }
        };

        /**
        * Start listening for user activity: mouse clicks, keypresses, or window scrolling.
        */
        this._startActivityListener = function () {
            // Only catch the first activity event, then stop listening.
            $(window).one('click scroll keydown', _.bind(activityDetected, this));
        };

        /**
        * Start (or restart) the idle timer.
        * If the timer is allowed to complete, the user will be logged out of the application.
        */
        this._startIdleTimer = function () {
            if (idleTimerID) {
                window.clearTimeout(idleTimerID);
            }
            idleTimerID = window.setTimeout(idleTimeout, halfHour);
        };

        /**
        * Kick off the Idle Watcher
        */
        this.start = function (options) {
            idleCallback = options.callback ? options.callback : undefined;
            
            this._startActivityListener();
            this._startIdleTimer();
        };

    };

    if (!idleModule) {
        idleModule = new Idle();
    }

    return idleModule;  

})();

module.exports = IdleModule;
