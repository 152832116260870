// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <li>\r\n                <a href=\"#transfers\" class=\"bolder pending-brown\" title=\"See 1035/Transfers:"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.exchangeHistory : depth0)) != null ? stack1.statusCounts : stack1)) != null ? stack1.pending : stack1),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "&nbsp;("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.exchangeHistory : depth0)) != null ? stack1.statusCounts : stack1)) != null ? stack1.received : stack1), depth0))
    + ") Received\">1035 / Transfers"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.exchangeHistory : depth0)) != null ? stack1.statusCounts : stack1)) != null ? stack1.pending : stack1),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a>\r\n\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.exchangeHistory : depth0)) != null ? stack1.statusCounts : stack1)) != null ? stack1.pending : stack1),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&nbsp;("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.exchangeHistory : depth0)) != null ? stack1.statusCounts : stack1)) != null ? stack1.pending : stack1), depth0))
    + ") Pending |";
},"4":function(container,depth0,helpers,partials,data) {
    return " Pending";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <a href=\"#transfers\" class=\"no-underline\">\r\n                        <span class=\"badge badge-info\" title=\"See 1035/Transfers:"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.exchangeHistory : depth0)) != null ? stack1.statusCounts : stack1)) != null ? stack1.pending : stack1),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "&nbsp;("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.exchangeHistory : depth0)) != null ? stack1.statusCounts : stack1)) != null ? stack1.received : stack1), depth0))
    + ") Received\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.exchangeHistory : depth0)) != null ? stack1.statusCounts : stack1)) != null ? stack1.pending : stack1), depth0))
    + "</span>\r\n                    </a>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.requirements : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                <li>\r\n                    <a href=\"#requirements\" class=\"bolder pending-brown\" title=\"See Requirements:"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.pendingCount : stack1),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "&nbsp;("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.nonPendingCount : stack1), depth0))
    + ") Received\">Requirements "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.pendingCount : stack1),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a>\r\n\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.pendingCount : stack1),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </li>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&nbsp;("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.pendingCount : stack1), depth0))
    + ") Pending |";
},"12":function(container,depth0,helpers,partials,data) {
    return "Outstanding";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                        <a href=\"#requirements\" class=\"no-underline\">\r\n                            <span class=\"badge badge-"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.pendingCount : stack1),9,{"name":"compare","hash":{"operator":">"},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "\" title=\"See Requirements: ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.pendingCount : stack1), depth0))
    + ") Pending | ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.nonPendingCount : stack1), depth0))
    + ") Received\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.requirements : depth0)) != null ? stack1.pendingCount : stack1), depth0))
    + "\r\n                            </span>\r\n                        </a>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "important";
},"17":function(container,depth0,helpers,partials,data) {
    return "info";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"jump-links\" class=\"col-lg-7 jump-link-page-top\">\r\n    <ul class=\"list-horizontal\">"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.exchangeHistory : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.showRequirements),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\r\n</div>";
},"useData":true});
