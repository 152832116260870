/* global Backbone:false, Marionette:false, _:false */

/**
 * The view used to display the footer content. This view uses
 * wcm-content/models/wcm-content-m.js as the model in order to retrieve content from WCM.
 */

import WcmContentModel from '../../wcm-content/models/wcm-content-m';
var analyticsChannel = Backbone.Radio.channel('analytics');

var FooterView = Marionette.ItemView.extend({

    errors   : {
        invalidWcmPath : 'footer view requires a valid "wcmPath" value'
    },

    template : false,

    initialize (options) {

        if (options && _.isObject(options) && _.has(options, 'wcmPath')
            && _.isString(options.wcmPath)) {

            this.model = new WcmContentModel({
                wcmPath : options.wcmPath
            });

        } else {
            throw new Error(this.errors.invalidWcmPath);
        }
    },

    onBeforeShow () {

        this.listenTo(this.model, 'sync', this._handleDisplay);
        this.listenTo(this.model, 'error', this._handleWcmError);

        this.model.fetch();
    },

    /**
     * Handle the display of the WCM content.
     * @private
     */
    _handleDisplay () {
        var footerContent = this.model.get('body');

        if (footerContent) {
            this.$el.append(footerContent);
        }
    },

    /**
     * Handle errors returned from the WCM server.
     *
     * @param {object} model
     * @param {object} response
     * @private
     */
    _handleWcmError (model, response) {
        var errorMessage = 'Error retrieving WCM content from ' + this.model.get('wcmPath')
                + '. Received a ' + response.status + ' status code.';

        analyticsChannel.trigger('trackException', {
            fatal   : false,
            message : errorMessage
        });
    }

});

module.exports = FooterView;
