/* global require:false, Backbone:false */

/**
 * View to test Push Notification functionality
 * Currently it supported only latest Chrome and Firefox
 */

var config       = require('../../../config/config');
var template     = require('../templates/web-push-t.hbs');
var dbg          = require('../../../modules/debug/debugModule').init();

var WebPushView = Backbone.Marionette.ItemView.extend({

    template : template,
    ui : {
        btnEnablePush   : '#btn-enable-push',
        btnSendPush     : '#btn-send-push',
        message         : '#message',
        responseMessage : '.response-message'
    },
    events : {
        'click @ui.btnEnablePush'   : '_enablePush',
        'click @ui.btnSendPush'     : '_sendPush'
    },
    initialize : function () {

        // Application server public key to subscribe push
        // Which created with help of web-push npm package
        // There will Private key matching with public one, which will be stored
        // in server to send push notification
        this.applicationServerPublicKey = 'BMReyVdAAgS1hZVgS2h5FJNjFdg01AkMZPhlh-Bqj'+
            '0wrF156XK_pZJSCM2xy6X2S3GFEZFjTFpFWqAZJCQmg_O0';
        
        this.isSubscribed = false;
        this.swRegistration = null;

        this.model = new Backbone.Model();
        
    },

    onShow : function () {
        var _this = this;

        // Checking browser support for ServiceWorker and Push
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            dbg.log('Service Worker and Push is supported');
          
            // Register Sercive Work file. Which will be downloaded
            // installed it in browser while registering and have access to
            // Service Worker API.
            // This file will run in backgroud even browser is closed.
            navigator.serviceWorker.register('js/sw.js')
            .then(function(swReg) {
                dbg.log('Service Worker is registered', swReg);
          
                _this.swRegistration = swReg;
                
                // Set the initial subscription value
                _this.swRegistration.pushManager.getSubscription()
                .then(function(subscription) {
                    _this.isSubscribed = !(subscription === null);
            
                    // Save subscription data on server to send push
                    _this._updateSubscriptionOnServer(subscription);
            
                    if (this.isSubscribed) {
                        dbg.log('User IS subscribed.');
                    } else {
                        dbg.log('User is NOT subscribed.');
                    }
            
                    _this._updateBtn();
                });
            })
            .catch(function(error) {
                dbg.error('Service Worker Error', error);
            });
        } else {
            dbg.warn('Push messaging is not supported');
            this.ui.btnEnablePush.text('Push Not Supported');
        }
    },
    onRender : function () {
        this._updateBtn();
    },

    // Method to decode public key.
    _urlB64ToUint8Array : function _urlB64ToUint8Array (base64String) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
          .replace(/\-/g, '+')
          .replace(/_/g, '/');
      
        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);
      
        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    },

    _updateBtn : function() {
        if (this.isSubscribed) {
            this.ui.btnEnablePush.text('Disable Push Messaging');
            this.ui.btnSendPush.attr('disabled', false);
        } else {
            this.ui.btnEnablePush.text('Enable Push Messaging');
            this.ui.btnSendPush.attr('disabled', true);
        }
        this.ui.btnEnablePush.attr('disabled', false);
    },

    _enablePush : function () {
        this.ui.btnEnablePush.attr('disabled', true);
        if (this.isSubscribed) {
            // TODO: Unsubscribe user
        } else {
            this._subscribeUser();
        }
    },

    /**
     * Subscribe for push notification
     */
    _subscribeUser : function() {
        var _this = this;
        var applicationServerKey = this._urlB64ToUint8Array(this.applicationServerPublicKey);
        
        // Which will prompt user to give access to notification API
        // and promise callback provide subscription data which contain endpoint and keys
        // that need to store in server
        //
        // sample subscription data:
        //{
        // "endpoint": "https://some.pushservice.com/something-unique",
        // "keys": {
        //     "p256dh": "BIPUL12DLfytvTajnryr2PRdAgXS3HGKiLqndI=",
        //     "auth":"FPssNDTKnInHVndSTdbKFw=="
        // }
        // }
        this.swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        })
        .then(function(subscription) {
            dbg.log('User is subscribed');
      
            _this._updateSubscriptionOnServer(subscription);
      
            _this.isSubscribed = true;
      
            _this._updateBtn();
        })
        .catch(function(err) {
            dbg.log('Failed to subscribe the user: ', err);
            _this._updateBtn();
        });
    },

    /**
     * Send subscription data to server to store on DB
     */
    _updateSubscriptionOnServer : function (subscription) {
        var endpoint;
        var key;
        var auth;
        var encodedKey;
        var encodedAuth;

        if (subscription) {
            endpoint = subscription.endpoint;
            key = subscription.getKey('p256dh');
            auth = subscription.getKey('auth');

            encodedKey = btoa(String.fromCharCode.apply(null, new Uint8Array(key)));
            encodedAuth = btoa(String.fromCharCode.apply(null, new Uint8Array(auth)));

            this.model.set('subscriptionData', JSON.stringify(subscription));
            this.model.set('endpoint', endpoint);
            this.model.set('key', encodedKey);
            this.model.set('auth', encodedAuth);
            this.render();

            // TODO: Send subscription to application server
        }     
    },

    /**
     * Send push from Test form 
     */
    _sendPush : function (e) {
        e.preventDefault();

        this.ui.responseMessage.remove();

        //Send push using REST API test
        var message = this.ui.message.val();
        var _this = this;
        if (this.ui.message.val()) {
            this.model.set('message', message);
            
            this.model.save(null,{
                url : config.apiUrlRoot + 'sendPushNotification',
                type: 'POST',
                success: function (model, response, options) {
                    dbg.log(response);
                    if (response.success) {
                        _this.model.set('successMessage', response.message);
                        _this.model.unset('errorMessage');
                    } else {
                        _this.model.set('errorMessage', response.message);
                        _this.model.unset('successMessage');
                    }
                    _this.render();
                },
                error: function (model, xhr, options) {
                    dbg.log(xhr);
                }
            });
        }
    }

});

module.exports = WebPushView;
