/* global _:false */
/**
 Policy model data parser functions for Requirements section
*/

import {dateDiff, isImplemented} from '../../../../utils/utils';

const policyRequirements = {

    /**
     * Boolean function to see if there are pending requirements
     * @param {object} requirements
     * @returns {boolean}
     * @private
     */
    _hasPendingRequirements (requirements) {
        if (requirements) {
            
            for (let key in requirements) {
                if (requirements.hasOwnProperty(key)) {
                    for (var i=0; i<requirements[key].length; i++) {
                        if (requirements[key][i].status 
                            && typeof requirements[key][i].status === 'string'
                            && requirements[key][i].status.trim().toUpperCase()
                                === 'PENDING') {
                            return true;
                        }
                    }
                }
            }

        }

        return false;
    },

    /**
     * Determine whether or not the Requirements section should be displayed
     * 
     * @param {object} response
     * @returns boolean
     */
    shouldShowRequirements (response) {
        // Always show the section for policies that have never been 'Active'
        if (response.requirements && response.policyStatus 
                && response.policyStatus.acordHoldingStatus === 'Proposed') {
            return true;
        }

        // For non-pending policies, show if the paid date is within 92 days or
        // there are pending requirements
        if (response.application && response.application.paid) {
            const daysSincePaid = dateDiff(response.application.paid);
            if (daysSincePaid <= 92) {
                return true;
            }
        }
        return this._hasPendingRequirements(response.requirements);
    },

    /**
     * Determine whether or not the Received Requirements subsection should be displayed
     * 
     * @param {object} response
     * @returns boolean
     */
    shouldShowReceivedRequirements (response) {
        // Always show received requirements for policies that have never been 'Active'
        if (response.requirements && response.policyStatus 
            && response.policyStatus.acordHoldingStatus === 'Proposed') {
            return true;
        }

        // Don't show if the paid date is more than 92 days ago
        if (response.application && response.application.paid) {
            const daysSincePaid = dateDiff(response.application.paid);
            if (daysSincePaid > 92) {
                return false;
            }
        }

        return true;
    },

    /**
     * Deletes the received requirements from the policy data
     * 
     * @param {object} response
     * @returns {object} response with the received requirements removed
     */
    deleteReceivedRequirements (response) {
        if (response.requirements) {
            delete response.requirements.received;
        }
        return response;
    },

    /**
     * Set customer first name (insuredName) against customer Id
     * for requirements list
     * 
     * @param  {Object} response response received from service
     * @return {object} response
     */
    setCustomerFirstNamePolicyRequirements (response) {
        if (response.requirements && isImplemented(response.requirements)) {
        
            //iterate each requirements
            _.each(response.requirements, function (requirements) {
                if (requirements) {
                    _.each(requirements, function (requirement) {                      
                        
                        // Datatable require a field to render data
                        // otherwise dt throw error
                        requirement.insuredName = '';
                        if (requirement.customerId 
                                && response.customers
                                && response.customers[requirement.customerId]) {

                            requirement.insuredName = 
                                response.customers[requirement.customerId].fullName;
                        } 
                    });
                }
            });
        }

        return response;
    },

    /**
     * Get a count of all pending and non-pending 
     * requirements for a policy and append this
     * structure to the response. 
     * 
     * @param {object} response
     * @returns {object} response
     */
    setRequirementsCounts (response) {
        let pendingCount      = 0;
        let nonPendingCount   = 0;

        if (response.requirements && isImplemented(response.requirements)) {
            
            _.each(_.keys(response.requirements), function(reqType) {
                
                _.each(response.requirements[reqType], function(req) {
                    
                    if (req.status 
                            && typeof req.status === 'string'
                            && req.status.trim().toUpperCase() === 'PENDING') {
                        pendingCount++;
                    } else {
                        nonPendingCount++;
                    }
                    
                });
            });
            
            response.requirements.pendingCount    = pendingCount;
            response.requirements.nonPendingCount = nonPendingCount;
        }
        
        return response;
    },

    /**
     * A Method to sort 'received' list property 
     * which is coming under 'requirements' object of response.
     * Finally it will sort requirements list based on 'key'. 
     * Both are uses custom sort order
     * 
     * @param  {Object} response response received from service
     * @return {object} response
     */
    sortPolicyRequirements (response) {

        //order to sort Requirement based on 'key'
        const requirementKeyOrder = [ 
            'neededForUnderwriting',
            'obtainAtDelivery', 
            'received'
        ];

        //Received list sorting
        //default sort order for 'Status' column of 'received' property
        const statusSortOrder = [
            'In House',
            'Complete',
            'Waived'
        ];

        // Bail out if response.requirements is not implemented
        if (response.requirements && !isImplemented(response.requirements)) {
            return response;
        }

        let receivedList  = response.requirements && response.requirements.received;

        if (receivedList) {

            receivedList = _.chain(receivedList)

                .sortBy('requirementName')
                .reverse()

                //sort by date received
                .sortBy(function(list){ 
                    const dateReceived = new Date(list.dateReceived);
                    if (dateReceived) {
                        return dateReceived.getTime();
                    } 
                    return 0;
                })

                //descending
                .reverse()

                // sort by status order 
                .sortBy(function(list){ 
                    return _.indexOf(statusSortOrder, list.status); 
                })

                .value();

            response.requirements.received = receivedList;

        }

        //Requirement list sorting
        response.requirements = _.object(
            _.sortBy(
                _.pairs(response.requirements),
                function(value){
                    return _.indexOf(requirementKeyOrder,value[0]);
                }
            )
        );

        return response;
    }
};

module.exports = policyRequirements;