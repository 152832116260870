/* global _:false */

/**
 * A utility sub module with all formatting utility methods.
 *
 */

var config = require('../config/config');
var moment = require('moment-timezone');

var utilsFormatting = {

    errors: {
        bytesToMegabytes: 'Parameter passed to bytesToMegabytesForDisplay must be a ' +
            'number!',
        invalidDate: 'Date parameter(s) provided is invalid',
        wrongDateDiffFormat: 'Provided format to find date difference is not supported',
        expectedDateInString: 'Provided date parameter is not a string'
    },

    /**
     * Given a number of bytes, the method returns the value in megabytes.
     *
     * @param {Number} sizeInBytes
     * @returns {string}
     */
    bytesToMegabytesForDisplay: function bytesToMegabytesForDisplay(sizeInBytes) {
        if (isNaN(sizeInBytes)) {
            throw new Error(this.errors.bytesToMegabytes);
        }
        return (sizeInBytes / (1024 * 1024)).toFixed(1);
    },

    // adapted from
    // http://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
    titleCase: function (text) {
        if (text) {
            return text.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }

        return '';
    },

    /**
     * Format a number or an iso-4217 currency value in the more familiar $ notation.
     * @param {string} value to be formatted
     * @param {boolean} round - flag to round the number
     * @param {boolean} spaceZero - flag to return -- if amount is 0
     * @returns {string}
     */
    formatAsCurrency: function (value, round, spaceZero) {

        var dollarSign = '$';

        //remove USD (if it exists) and trim whitespace
        if (value) {
            if (value.replace) {
                value = value.replace(/USD/ig, '').trim();
            }

            // round the number to remove cents
            if (round) {
                value = Math.round(value);
            }

            //to add minus sign in-front of $
            if (value < 0) {
                dollarSign = '-$';
                value = value * -1;
            }

            // thousands grouping
            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        } else {
            value = 0;
        }

        if (parseInt(value, 10) === 0 && spaceZero) {
            return '--';
        }

        return dollarSign + value;
    },

    /**
     * Convert a string from ISO-4217 currency (USD only) to a number
     * @param value the ISO-4217 value to convert
     * @param absolute whether or not to return an absolute value
     * @param round wheter or not to round the value
     * @returns {number}
     */
    isoCurrencyToNumber: function (value, absolute, round) {
        var number = 0;
        if (value) {
            number = value.replace(/USD/ig, '').trim();
            if (absolute) {
                number = Math.abs(number);
            }
            if (round) {
                number = Math.round(number);
            }
        }
        return Number(number);
    },

    /**
     * Extract a unit of time from an ISO duration.
     * Uses {@link http://momentjs.com/docs/#/durations/get/}
     * @param {string} value
     * @param {string} unit
     * @returns {string}
     */
    isoDurationToTimeUnit: function (value, unit) {
        var duration = moment.duration(value);

        if (!moment.isDuration(duration)) {
            throw new Error('isoDurationToTimeUnit requires an ISO duration string to be passed');
        }

        return duration.get(unit);
    },

    /**
     * Get difference between to dates. 
     * @param  {String} startDate 
     * @param  {String} [endDate]   
     * @param  {String} [format]    expected output format are
     *                            years, months, weeks, days, hours, minutes, and seconds.
     *                            Default will be 'days' if param is missing
     * @return {number}           date difference in expected format
     */
    dateDiff: function dateDiff(startDate, endDate, format) {

        var formats = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];

        if (!format) {
            format = 'days';
        }

        if (startDate && typeof startDate === 'string') {
            startDate = moment.tz(startDate, config.defaultTimezone);
        } else {
            throw new Error(this.errors.expectedDateInString);
        }

        if (endDate) {
            if (typeof endDate === 'string') {
                endDate = moment.tz(endDate, config.defaultTimezone);
            } else {
                throw new Error(this.errors.expectedDateInString);
            }
        } else {
            endDate = moment.tz(config.defaultTimezone);
        }

        if (startDate.isValid() && endDate.isValid()) {
            if (_.contains(formats, format)) {
                return endDate.diff(startDate, format);
            } else {
                throw new Error(this.errors.wrongDateDiffFormat);
            }
        } else {
            throw new Error(this.errors.invalidDate);
        }

    },

    /**
     * Reference : https://developer.mozilla.org/en-US/docs/Web/JavaScript
     * /Reference/Global_Objects/Math/sign
     *
     * Return negative sign if value less than zero
     * @param - number
     * @return - number  -1, 0, or 1
     */
    signOfNumber: function (value) {
        if (value === undefined || value === null) {
            return '';
        }

        // convert to a number
        value = Number(value);

        if (value === 0) {
            return value;
        } else if (isNaN(value)) {
            throw new Error('Expected a numeric input');
        }

        return value > 0 ? 1 : -1;
    },

    /**
    * format date 
    * @param - date - string    a valid date string (ISO 8601 UTC date) - Mandatory
    * @param - format - string  optional default is MM/DD/YYYY
    *  returned value based on timeZone
    *
    **/
    formatDate: function (date, format) {

        if (!date) {
            return '';
        }

        //EST by default
        var moDate = moment.tz(date, config.defaultTimezone);

        if (moDate.isValid()) {

            if (!format) {
                format = 'MM/DD/YYYY';
            }

            return moDate.format(format);

        } else {
            throw new Error(this.errors.invalidDate);
        }
    },

    /**
     * format phone number to standard US format (212-252-4545)
     * @param  {string} phoneNumber 
     * @return {string}             
     */
    formatPhoneNumber: function (phoneNumber) {
        if (phoneNumber === undefined || phoneNumber === null) {
            return '';
        }

        //convert to string if its number
        if (!isNaN(phoneNumber)) {
            phoneNumber = String(phoneNumber);
        }

        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    },
    formatNumberDecimals: function (val, place, addCommas) {
        var i, multiplier, prefix;
        val = val || 0;
        //for some reason this method breaks down for certian low negative
        // numbers, Ex. -0.0011 when passing in place of 6
        //the fix is to add negative in at the end
        prefix = (val < 0) ? '-' : '';
        val = Math.abs(val);
        multiplier = Math.pow(10, place);
        val = String(Math.round(val * multiplier));
        if (place > 0) {
            for (i = val.length - 1; i < place; i++) {
                val = '0' + val;
            }
            i = val.length - place;
            val = val.substring(0, i) + '.' + val.substring(i);
        } else if (place < 0 && val !== '0') {
            for (i = place; i < 0; i++) {
                val += '0';
            }
        }
        if (addCommas) {
            val = this.addCommas(val);
        }
        return prefix + val;
    },
    addCommas: function (val) {
        var i, post, pre;
        pre = '';
        post = '';
        val = String(val);
        //break at the decimal point
        i = val.indexOf('.');
        if (i > -1) {
            post = val.substring(i);
            val = val.substring(0, i);
        }
        //set aside leading non-digits
        i = val.search(/\d/);
        if (i > 0) {
            pre = val.substring(0, i);
            val = val.substring(i);
        }
        //set aside trailing non-digits
        i = val.search(/\D/);
        if (i > -1) {
            post = val.substring(i) + post;
            val = val.substring(0, i);
        }
        //insert commas
        for (i = val.length - 3; i > 0; i -= 3) {
            val = val.substring(0, i) + ',' + val.substring(i);
        }
        return pre + val + post;
    }
};

module.exports = utilsFormatting;