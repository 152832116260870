/* global Backbone: false, _: false */

/**
 * Display list for a Producers who have delegation access.
 */

var debugModule          = require('../../../modules/debug/debugModule').init();
var errorHelper          = require('../../../utils/error-helper');
var spinnerChannel       = Backbone.Radio.channel('spinner');
var template             = require('../templates/delegate-producer-list-t.hbs');
var userChannel          = Backbone.Radio.channel('user');
var utils                = require('../../../utils/utils');

// Need to include this, otherwise unit tests blow up.
require('../../../utils/hb-helpers');

// load the global partials.
require('../../../partials');

var ProducerDelegateListView = Backbone.Marionette.ItemView.extend({

    template: template,

    ui: {
        producerResultsWrapper     : '#producerSearchResults',
        dataTable                  : '#producerSearchResultsTable',
        responsiveTableInstruction : '.table-responsive-instruction',
        linkViewAsProducer         : '.link-view-as-producer'
    },

    errors : {
        noDelegateTargetUsers : 'There are no active producers for your delegate access'
    },

    events: {
        'click @ui.linkViewAsProducer' : '_startImpersonation'
    },

    onBeforeShow: function () {

        var delgateProducers = userChannel.request('getDelegateTargets');

        if (delgateProducers && delgateProducers.length > 1) {

            var columnDefs = [
                { 
                    data: 'producer.lexicalName', 
                    name: 'lexicalName' 
                },
                { 
                    data: 'producer.webId', 
                    name: 'webId', 
                    render: this.renderProducerActions 
                },
                { 
                    data: 'producer.roles', 
                    name: 'roles', 
                    render: utils.getActiveProducerRoleCodesAsString 
                }
            ];

            // In order to prevent "null" from being shown in IE/Edge, an
            // empty string will be set as the defaultContent for each column.
            utils.setDatatablesDefaultContent(columnDefs, '');

            this.ui.producerResultsWrapper.removeClass('hidden');

            this.ui.dataTable.DataTable({

                
                bSort : false,

                // column definitions. Length MUST match number of columns in template
                columns : columnDefs,

                data : delgateProducers,

                // show table only for now
                dom : 't',

                paging : false,
                info   : false,

                // show a 'processing' indicator when the widget is waiting/working
                processing : false,

                // don't allow the built-in search input
                searching : false
                
            });

            utils.formatDataTable(this.$el.find('.dataTables_wrapper'));

        } else {

            this.model =  new Backbone.Model({
                alertMessage : errorHelper.createAlert(
                    this.errors.noDelegateTargetUsers, 'info'
                )
            });

            this.render();
        }
    },

    renderProducerActions : function renderProducerActions(webId, type, row, meta) {
        var actionsTemplate;
       
        actionsTemplate = _.template(
            '<a class="link-view-as-producer" '+'href="#" '+
            'data-webid="<%=webId %>" '+
            'data-name="<%=fullName %>">'+
            'Start Delegate Access</a>'
        );

        return actionsTemplate( { 
            webId       : webId,
            fullName    : row.producer.fullName
        });
    },

    /**
     * Start impersonate session
     * @return {object} event event object
     */
    
    _startImpersonation : function _startImpersonation (event) {
        event.preventDefault();
        
        var anchor = Backbone.$(event.currentTarget);
        var webId  = anchor.data('webid');

        if (webId) {
            // Adding cookie for the impersonated user
            utils.createCookie(webId);

            spinnerChannel.trigger('show', {
                viewScope : this,
                position  : 'fixed'
            });

            userChannel.trigger(
                'viewAsProducer',
                {
                    webId    : webId,
                    fullName : anchor.data('name')
                }
            );
        } else {
            debugModule.warn('producer-search-results-v: webId missing for a producer');
        }
    }
});

module.exports = ProducerDelegateListView;
