/**
 * Utility methods for validating data
 */

var utilsData = {

    /**
    * Examine an object to see if the service reports it as being not implemented.
    * @param obj Data object to check
    * @returns boolean
    */
    isImplemented : function isImplemented (obj) {
        var implementedFlag = true;
        if (obj && obj.dataAvailability && obj.dataAvailability === 'notImplemented') {
            implementedFlag = false;
        }
        return implementedFlag;
    }

};

module.exports = utilsData;