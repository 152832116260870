/* global Backbone:false */

var config   = require('../../../config/config');

var HelloModel = Backbone.Model.extend({

    // defaults: {
    //     title   : 'Hello Title',
    //     text    : 'Here is the hello text'
    // },

    url: config.apiUrlRoot + 'greeting'
    
});

module.exports = HelloModel;
