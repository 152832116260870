/* global Backbone:false */
/**
 * A View Model to back the client-name-search-results-v module.
 *
 * Created by jbell on 3/4/16.
 */
var ClientNameSearchResultsViewModel = Backbone.Model.extend({
    defaults: {
        hasPolicySearchByProducer : false
    }
});

module.exports = ClientNameSearchResultsViewModel;
