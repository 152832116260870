/* global Backbone: false, Marionette:false, _:false */

var config = require('../../../config/config');
var DelegateMaintenanceViewModel = require('../viewModels/delegate-maintenance-vm');
var debugModule = require('../../../modules/debug/debugModule').init();
var DMTemplate = require('../templates/delegate-maintenance-t.hbs');
var utils = require('../../../utils/utils');

const errorChannel = Backbone.Radio.channel('error');

import '../../../utils/hb-helpers';
import '../../../partials';
import errorHelper from '../../../utils/error-helper';

var DelegateMaintenanceView = Marionette.LayoutView.extend({

    template: DMTemplate,

    ui: {
        dataTable: '#delegateResultsTable',
        responsiveTableInstruction: '.table-responsive-instruction',
        resendCodeConfirmModal: '#resend-code-confirm-modal',
        resendCode: '#resendCode',
        resendCodeConfirmModalOk: '#resendCodeConfirmModalOk',
        deleteDelegate: '#deleteDelegate',
        deleteDelegateConfirmModal: '#confirm-delete-delegate-modal',
        deleteDelegateConfirmModalOk: '#deleteDelegateConfirmModalOk',
        deleteDelegateSuccessModal: '#delete-delegate-success-modal',
        deleteDelegateSuccessModalOk: '#deleteDelegateSuccessModalOk'
    },

    events: {
        'click @ui.resendCode': '_resendCode',
        'click @ui.resendCodeConfirmModalOk': '_resendCodeConfirmModalOk',
        'click @ui.deleteDelegate': '_deleteDelegate',
        'click @ui.deleteDelegateConfirmModalOk': '_deleteDelegateConfirmModalOk',
        'click @ui.deleteDelegateSuccessModalOk': '_deleteDelegateSuccessModalOk',
    },

    regions: {
        producerNameRegion: '#general-info-producer-name-region'
    },

    errors: {
        serverError: 'An error occurred while processing the request',
        producerIdRequired: 'No Producer Specified',
        fetchDelegateListError: 'An error has occurred while processing this request.'
    },

    initialize: function (options) {
        this.userChannel = Backbone.Radio.channel('user');
        this.spinnerChannel = Backbone.Radio.channel('spinner');

        this.model = new DelegateMaintenanceViewModel();

        // CHECK FOR OPTIONS OBJECT AND PRODUCERID INSIDE OF IT
        if (!options || !options.stateObj || !options.stateObj.producerId) {
            errorChannel.trigger('showErrorPage', this.errors.producerIdRequired);
            this.destroy();
            return false;
        }

        this.model.set('producerId', options.stateObj.producerId);
        this.listenTo(this, 'noResults', this._showNoResultsMessage);
        this._initAlerts();
    },

    onRender: function () {
        const _this = this;
        _this.showName(this.model.get('producerFullName', false));

        if (!this.ui.dataTable.length) {
            return false;
        }

        this.spinnerChannel.trigger('show', {
            viewScope: this,
            position: 'fixed'
        });

        const table = this.ui.dataTable.DataTable(this._getDataTableOptions())
            .on('xhr', function ($event, dataTableSettings, jsonResponse, xhr) {
                _this.spinnerChannel.trigger('hide', _this);

                if (!jsonResponse || !xhr) {
                    errorChannel.trigger('showErrorPage', _this.errors.fetchDelegateListError);
                    _this.destroy();
                    return;
                }

                // Handle any non-200 HTTP responses
                // after the readyState has been set to 4 (DONE)
                if (xhr.readyState === 4 && xhr.status !== 200) {
                    errorChannel.trigger('showErrorPage', this.errors.fetchDelegateListError);
                    _this.destroy();
                    return;
                }

                _this.model.set('producerId', jsonResponse.producer.id);
                _this.model.set('producerFullName', jsonResponse.producer.fullName);
                
                if (jsonResponse.recordsTotal === 0) {
                    _this.trigger('noResults');
                    return false;
                }

                _this.showName(jsonResponse.producer.fullName);
            }).on('draw', function ($event, dataTableSettings) {

                //There is no direct API to update info label, so updating it on DOM
                Backbone.$(dataTableSettings.nTableWrapper).find('.dataTables_info span')
                    .text(_this.model.get('producerFullName'));
            });

        utils.bindDataTableEvents({
            dtObject: table,
            viewScope: this,
            viewName: 'delegate-maintenance-v: ',
            spinnerChannel: this.spinnerChannel,
            debugModule: debugModule,
            addOrderListener: true
        });

        this.dt = table;

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));
    },

    onBeforeDestroy: function () { },

    _getDataTableOptions: function () {
        if (!this.dataTableOptions) {
            this.dataTableOptions = this._setDataTableOptions();
        }

        return this.dataTableOptions;
    },

    _setDataTableOptions: function () {
        var _this = this;

        const columnDefs = [
            {
                data: 'name',
                name: 'name'
            },
             // ACTIONS
            {
                data: null,
                name: 'actions',
                render: _.bind(this.renderActions, this),
                orderable: false
            },
            {
                data: 'webLogonId',
                name: 'userId'
            },
            {
                data: 'commissionAccess',
                name: 'accessLevel',
                render: utils.renderCommissionAccess
            },
            {
                data: 'webId',
                name: 'registered',
                render: utils.renderRegisteredStatus,
                orderable: false
            },
            {
                data: 'emailAddress',
                name: 'email',
                className : 'wordbreak',
                width: '25%'
            },
        ];

        utils.setDatatablesDefaultContent(columnDefs, '');
        let producerId = this.model.get('producerId');

        this.url = `${config.apiUrlRoot}producers/${producerId}/delegates`;

        const dtOptions = utils.getDatatableOptions(this, this.url, false);

        _.extend(dtOptions, {

            // show info, length control, pagination, table, info, length, pagination
            dom: 'ilptilp',

            language: {
                info: 'Showing _START_ to _END_ of _TOTAL_ ' +
                    'entries for <strong>"<span>' + _this.model.get('producerFullName') +
                    '</span>"</strong>',
                lengthMenu: 'Display _MENU_ records'
            },

            // 0-based start index
            displayStart: 0,

            bAutoWidth: false,

            // initial page length
            pageLength: 25,

            columns: columnDefs
        });

        return dtOptions;
    },

    showName: function showName(name) {
        Backbone.$('#producerName').html(name);
    },

    renderActions: function renderActions(webId, type, row, meta) {
        var producerId = this.model.get('producerId');
        var relationshipId = row.relationshipId;
        var editDelegateUrl = utils.buildHrefURL(
            '#producer-delegate-edit?producerId=' + producerId +
            '&relationshipId=' + relationshipId
        );
        var hideResendCodeLink = 'show';
        if (row.webId) {
            hideResendCodeLink = 'hide';
        }
        return _.template(
            '<strong><a href="' + editDelegateUrl + '">Edit</a><br>' +
            '<a id="deleteDelegate" href="#" data-relationshipId = "' + relationshipId +
            '">Delete</a><br>' +
            '<a id="resendCode" data-relationshipId = "' + relationshipId + '" class="'
            + hideResendCodeLink + '" href="#">' +
            'Resend Code</a></strong>'
        );
    },

    _showResendCodeConfirmationModal: function () {
        this.ui.resendCodeConfirmModal.modal({
            show: true,
            backdrop: 'static'
        });
    },

    _hideResendCodeConfirmationModal: function () {
        this.ui.resendCodeConfirmModal.modal('hide');
    },

    _showDeleteDelegateConfirmationModal: function () {
        this.ui.deleteDelegateConfirmModal.modal('show');
    },

    _hideDeleteDelegateConfirmationModal: function () {
        this.ui.deleteDelegateConfirmModal.modal('hide');
    },

    _showDeleteDelegateSuccessModal: function () {
        this.ui.deleteDelegateSuccessModal.modal({
            show: true,
            backdrop: 'static'
        });
    },

    _hideDeleteDelegateSuccessModal: function () {
        this.ui.deleteDelegateSuccessModal.modal('hide');
    },

    _deleteDelegateSuccessModalOk: function (event) {
        if (event) {
            event.preventDefault();
        }
        this.ui.dataTable.DataTable(this._getDataTableOptions()).draw();
    },


    _deleteDelegateConfirmModalOk: function (event) {
        const _this = this;
        if (event) {
            event.preventDefault();
        }
        this.spinnerChannel.trigger('show', {
            viewScope: this,
            position: 'fixed'
        });

        let producerId = this.model.get('producerId');
        let deleteDelegateUrl =
            `${config.apiUrlRoot}producers/${producerId}/delegates/${this.selectedRelationshipId}`;

        let xRequestedWithHeader = {
            'X-Requested-With': 'XMLHttpRequest'
        };

        this.model.save(null, {
            url: deleteDelegateUrl,
            type: 'DELETE',
            contentType: 'application/json',
            data: '',
            headers: xRequestedWithHeader,
            success: function (model) {
                _this.spinnerChannel.trigger('hide', _this);
                _this._hideDeleteDelegateConfirmationModal();
                _this._showDeleteDelegateSuccessModal();
                _this._hideErrorAlert();
            },
            error: function (response) {
                _this.spinnerChannel.trigger('hide', _this);
                _this._hideDeleteDelegateConfirmationModal();
                _this._showErrorAlert();
            }
        });
    },

    _deleteDelegate: function (event) {
        if (event) {
            event.preventDefault();
        }
        let anchor = Backbone.$(event.currentTarget);
        let relationshipId = anchor.data('relationshipid');
        this.selectedRelationshipId = relationshipId;
        this._showDeleteDelegateConfirmationModal();

    },

    _resendCode: function (event) {
        const _this = this;
        if (event) {
            event.preventDefault();
        }
        this.spinnerChannel.trigger('show', {
            viewScope: this,
            position: 'fixed'
        });
        let anchor = Backbone.$(event.currentTarget);
        let relationshipId = anchor.data('relationshipid');
        let producerId = this.model.get('producerId');
        let resendCodeUrl =
            `${config.apiUrlRoot}producers/${producerId}/delegates/${relationshipId}/accessCode`;
        let xRequestedWithHeader = {
            'X-Requested-With': 'XMLHttpRequest'
        };

        this.model.save(null, {
            url: resendCodeUrl,
            type: 'PUT',
            contentType: 'application/json',
            headers: xRequestedWithHeader,
            success: function (model) {
                _this.spinnerChannel.trigger('hide', _this);
                _this._showResendCodeConfirmationModal();
                _this._hideErrorAlert();
            },
            error: function (response) {
                _this.spinnerChannel.trigger('hide', _this);
                _this._showErrorAlert();
            }
        });
    },

    _resendCodeConfirmModalOk: function (event) {
        if (event) {
            event.preventDefault();
        }
        this._hideResendCodeConfirmationModal();
    },

    _showErrorAlert: function () {
        Backbone.$('#errorAlert').removeClass('hide');
    },

    _hideErrorAlert: function () {
        Backbone.$('#errorAlert').addClass('hide');
    },

    _initAlerts: function () {
        this.model.set(
            'alertMessage',
            errorHelper.createAlert(this.errors.serverError, 'warning')
        );
    },

    _showNoResultsMessage : function _showNoResultsMessage () {
        this.model.set('noResults', true);
        this.model.set(
            'alertMessage',
            errorHelper.createAlert('No Delegates', 'info')
        );
        this.render();
    },

    onDestroy: function () {
        Backbone.$('.modal-backdrop.fade.in').remove();
        Backbone.$('body').removeClass('modal-open');
    }
});

module.exports = DelegateMaintenanceView;