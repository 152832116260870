/* global Backbone:false */

/**
 * Displays the credit card checkbox and associated error messages.
 */

import template                           from '../templates/credit-card-t.hbs';
import RequirementSubmissionFormViewModel from '../viewModels/requirement-submission-form-vm';

const CreditCardView = Backbone.Marionette.ItemView.extend({

    errors   : {
        invalidOptions : 'Options passed to CreditCardView ' +
                         'must contain either a policyId or a model'
    },

    events   : {
        'change @ui.creditCardCheckbox' : '_updateModel'
    },

    template : template,

    ui       : {
        creditCardCheckbox : '[name="credit-card"]'
    },

    /**
     * Initialize the view.
     *
     * @param {Object=} options Optional parameter which contains the model to use. If not
     * supplied, a model will be created.
     */
    initialize (options) {

        if (!options.model && !options.policyId) {
            throw new Error(this.errors.invalidOptions);

        } else if (options.model) {
            this.model = options.model;

        } else {
            this.model = new RequirementSubmissionFormViewModel({
                policyId : options.policyId
            });
        }

        // respond to a change in the model by rendering
        this.listenTo(this.model, 'change:creditCardErrorText', this.render);
    },

    _updateModel () {
        this.model.set('creditCard', this.ui.creditCardCheckbox.is(':checked'));
    }
});

module.exports = CreditCardView;