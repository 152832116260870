/* global Backbone:false, _:false */

/**
 * Model for the Requirement Submission Form.
 * This model is also a wrapper for the domain model
 * used by the form.
 */

import PolicyDetailModel              from '../../policy/models/policy-detail-m';
import RequirementSubmissionFormModel from '../models/requirement-submission-form-m';

const RequirementSubmissionFormViewModel = Backbone.Model.extend({

    defaults : {
        comments                  : '',
        commentsLength            : 500,
        creditCard                : false,
        files                     : [],
        maxCommentsLength         : 500,
        maxFileSizeInMegabytes    : 25,
        totalFileSizeInBytes      : 0,
        validFileTypes            : [
            'application/pdf',
            'image/tiff'
        ]
    },

    errors : {
        missingPolicyIdOrModel : 'RequirementSubmissionViewModel: Missing "policyId" or ' +
                                  '"policyDetailModel"'
    },
    // Contains the properties to copy from the different models
    sharedProperties : {

        // properties from the policy details model
        policyDataModel : [
            'customers',
            'customerRoles',
            'policyId',
            'policyIdentification',
            'policyNumber',
            'product'
        ],
        domainModel : [
            'commentsErrorStyle',
            'commentsErrorText',
            'creditCardErrorText',
            'fileErrorText',
            'formErrorText',
            'serviceFileErrorText'
        ]
    },

    /**
     * Initializes the model.
     *
     * @param {Object} options
     */
    initialize (options) {

        if (!options || (!options.policyDetailModel && !options.policyId)) {
            throw new Error(this.errors.missingPolicyIdOrModel);
        }

        if (!options.domainModel) {
            this.domainModel = new RequirementSubmissionFormModel();
        } else {
            this.domainModel = options.domainModel;
        }

        if (!options.policyDetailModel) {

            this.policyDetailModel = new PolicyDetailModel({
                id : options.policyId
            });

        } else {
            this.policyDetailModel = options.policyDetailModel;
        }

        this.domainModel.set(this.defaults);

        // when the policy details have been received, update the this view model
        this.listenTo(this.policyDetailModel, 'sync', this._setPolicyIdentificationValues);

        this.listenTo(this.domainModel, 'change', this._getErrorMessagesFromDomainModel);

        this.listenTo(this, 'change:files', this._updateTotalFileSize);

        this.listenTo(this, 'change:comments',
            // set the context to the domain model
            _.bind(this.domainModel.validateComments, this.domainModel));

    },

    /**
     * Resets the model using the silent option, reloads the default
     * settings (without the silent option so that the views will update),
     * and reloads the policy identification information.
     */
    reset () {
        this.clear({ silent : true });

        // Set the form properties back to their default.
        this.set(this.defaults);

        // Reload the policy identification information
        this._setPolicyIdentificationValues();
    },

    validate () {
        if (this.get('commentsLength') < 0) {
            return 'Comment is too long.';
        }
    },

    /**
     * Used to copy error messages from the domain model to this view model.
     * @private
     */
    _getErrorMessagesFromDomainModel () {
        const errorTypes = this.sharedProperties.domainModel;

        _.each(errorTypes, function(key) {
            // If the key has changed on the domain model,
            // copy it to this view model.
            if (this.domainModel.hasChanged(key)) {
                this.set(key, this.domainModel.get(key));
            }
        }, this);
    },

    /**
     * Gets the domain model values and copies them over to the
     * corresponding values of this view model.
     * @private
     */
    _setPolicyIdentificationValues () {
        let keys = this.sharedProperties.policyDataModel;

        _.each(keys, function (key) {
            this.set(key, this.policyDetailModel.get(key));
        }, this);
    },

    /**
     * Calculates the total file size of all files in the "files" array
     * and sets the value in "totalFileSizeInBytes".
     * @private
     */
    _updateTotalFileSize () {
        const files      = this.get('files');
        const totalBytes =  _.reduce(files, function(memo, file) {
            return Number(memo + file.size);
        }, 0);

        this.set('totalFileSizeInBytes', totalBytes);
    }
});

module.exports = RequirementSubmissionFormViewModel;