/* global Backbone:false, _:false */

// load the global partials.
require('../../../partials');
require('../../../utils/hb-helpers');

var debugModule                     = require('../../../modules/debug/debugModule').init();
var TransactionHistoryDataTableViewModel 
                                   = require('../viewModels/transaction-history-datatable-vm');
var template                        = require('../templates/transaction-history-datatable-t.hbs');
var utils                           = require('../../../utils/utils');
var spinnerChannel                  = Backbone.Radio.channel('spinner');
import errorHelper from '../../../utils/error-helper';

var TransactionHistoryDataTableView = Backbone.Marionette.ItemView.extend({

    template : template,

    initialize: function (options) {

        var col;
        var dir;
        var length;
        var start;
        var status;

        if (options) {
            this.policyId       = options.policyId;
            this.showNoteForVariableProducts       = options.showNoteForVariableProducts;
            status               = options.status;
            if (options.state) {
                col    = options.state.col;
                dir    = options.state.dir;
                length = options.state.length;
                start  = options.state.start;
            }
        }

        this.model = new TransactionHistoryDataTableViewModel({
            col               : col,
            dir               : dir,
            length            : length,
            policyId          : this.policyId,
            showNoteForVariableProducts: this.showNoteForVariableProducts,
            
            start             : start,
            status            : status
        });
        this.listenTo(
            this, 
            'noResults', 
            this._showNoResultsMessage
        );

    },

    onBeforeRender : function  () {
        var uiElements = {
            transactionHistoryTitle : '#transactionHistoryTitle',
            fromToDate              : '#from-to-date',
            transactionHistoryListDatatable     : '#transaction-history-list-datatable',
            transactionHistoryListDTWrapper     : '#transaction-history-dt-wrapper',
            pointOneOfNoteForVariableProducts   : '#pointOneOfNoteForVariableProducts',
            pointTwoOfNoteForVariableProducts   : '#pointTwoOfNoteForVariableProducts'
        };

        this.ui = uiElements;
    },
    _toggleInfoPopover(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if(Backbone.$(e.currentTarget).hasClass('popoverShown')){
            Backbone.$(e.currentTarget).removeClass('popoverShown');
            Backbone.$(e.currentTarget).popover('hide');
        }else{
            Backbone.$(e.currentTarget).addClass('popoverShown');
            Backbone.$(e.currentTarget).popover('show');
        }
    },

    _showInfoPopover(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        Backbone.$(e.currentTarget).popover('show');
    },

    _hideInfoPopover(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        Backbone.$(e.currentTarget).popover('hide');
    },

    onRender : function () {

        var _this  = this;

        var ordering = this._getTableOrdering(
            this.model.get('status'),
            this.options.state
        );

        if (!this.ui.transactionHistoryListDatatable.length) {
            return false;
        } 

        //Abort all previous request if there anything active
        //while user continousily swapping tab before rendering datatable
        utils.abortAllDataTableAjaxRequest(this.ui.transactionHistoryListDatatable.selector);

        var url = this.model.url();

        var datatableColumns = [
            {
                title       : 'Transaction Date',
                data        : 'transactionDate',
                name        : 'transactionDate',
                render      : utils.dataTableFormatDate
            },
            {
                title       : 'Transaction Type',
                data        : 'transactionType',
                name        : 'transactionType'
            },
            {
                title   : 'Transaction Description',
                data    : 'transactionDescription' ,
                name    : 'transactionDescription'
            },
            {
                title       :  
                '<button aria-label="information" class="title-hover" type="button"'
                +'data-toggle="popover"'
                +'data-content="Gross Amount - It represents the entire transaction '
                +'amount including operational charges,'
                +' interest amount and actual transaction payable amount.">'
                +'<i class="fa fa-info-circle oa-lake pointer no-margin pull-right"'
                +' ></i></button>'
                +'<span class="title">Gross Amount</span>',
                data        : 'grossAmount',
                name        : 'grossAmount',
                render      : utils.dataTableFormatCurrency,
                type        : 'grossAmount',
                width       : '105px'
            },
            {
                title       :
                '<button aria-label="information" class="title-hover" type="button"'
                +'data-toggle="popover"'
                +'data-content="Net Amount - It represents the actual '
                +'transaction amount excluding operational charges and interest amount.">'
                +'<i class="fa fa-info-circle oa-lake pointer no-margin pull-right"'
                +' ></i></button>' 
                +'<span class="title">Net Amount</span>',
                data        : 'netAmount',
                name        : 'netAmount',
                render      : utils.dataTableFormatCurrency,
                type        : 'netAmount',
                width       : '105px'
            },
            {
                title       : 'Interest Amount',
                data        : 'interestAmount',
                name        : 'interestAmount',
                render      : utils.dataTableFormatCurrency,
                type        : 'interestAmount'
            },
            {
                title       : 'Transaction Status',
                data        : 'status',
                name        : 'status',
                type        : 'status'
            }
        ];

        var dataTableOptions = utils.getDatatableOptions(this, url);

        _.extend(dataTableOptions, {

            // show info, length control, pagination, table, info, length, pagination
            dom : 'ilptilp',
            
            language : {
                info       : 'Showing _START_ to _END_ of _TOTAL_ ' +
                    'entries',
                lengthMenu : 'Display _MENU_ records',
                emptyTable: 'No transactions to display'
            },

            // 0-based start index
            displayStart : this.model.get('start'),

            bAutoWidth: false,

            // initial sorting
            order : ordering,

            // initial page length
            pageLength : this.model.get('length'),

            columns : datatableColumns,

            //work only once for initial time 
            'initComplete' : function (dataTableSettings, jsonResponse) {

                // update the last refresh date
                if (jsonResponse.headingDates 
                    && jsonResponse.headingDates.fromDate
                    && jsonResponse.headingDates.toDate) {

                    if (_this.ui.fromToDate instanceof Backbone.$) {
                        _this.ui.fromToDate.text(
                            utils.formatDate(
                                jsonResponse.headingDates.fromDate
                            )
                            +' - '
                            + utils.formatDate(
                                jsonResponse.headingDates.toDate
                            )
                        );

                        _this.ui.transactionHistoryTitle.removeClass('hidden');
                        _this.ui.fromToDate.parent('p').removeClass('hidden');
                        if(_this.ui.pointOneOfNoteForVariableProducts instanceof Backbone.$){
                            _this.ui.pointOneOfNoteForVariableProducts.removeClass('hidden');
                        }
                        if(_this.ui.pointTwoOfNoteForVariableProducts instanceof Backbone.$){
                            _this.ui.pointTwoOfNoteForVariableProducts.removeClass('hidden');
                        }
                    }
                    Backbone.$(dataTableSettings.nTableWrapper).find('.dataTables_info')
                    .removeClass('hidden');
                }else{
                    Backbone.$(dataTableSettings.nTableWrapper).find('.dataTables_info')
                    .addClass('hidden');
                } 
            }
        });

        // In order to prevent "null" from being shown in IE/Edge, an
        // empty string will be set as the defaultContent for each column.
        utils.setDatatablesDefaultContent(dataTableOptions.columns, '');
        
        spinnerChannel.trigger('show',{
            viewScope : this
        });

        var table = this.ui.transactionHistoryListDatatable.DataTable(dataTableOptions)
        .on('xhr', function($event, dataTableSettings, jsonResponse, xhr) {

            spinnerChannel.trigger('hide', _this);

            //if request is aborted callback will reach here with statusText 'abort'
            //So do no proceed to do anything, otherwise it display blank Table
            if (xhr && xhr.statusText === 'abort') {
                return false;
            }
            
            // Make sure we're getting the info back that we should expect
            if (!jsonResponse || _.isUndefined(jsonResponse.recordsTotal) || !xhr) {

                _this.trigger('error');
                return;
            }
            
            // Handle any non-200 HTTP responses
            // after the readyState has been set to 4 (DONE)
            if (xhr.readyState === 4 && xhr.status !== 200) {
                _this.trigger('error');
                return;
            }

            if (jsonResponse.recordsTotal === 0) {
                _this.trigger('noResults');
                return;
            }

            _this.ui.transactionHistoryListDTWrapper.removeClass('hidden');
            _this.ui.transactionHistoryListDatatable.find('th:eq(4)')
            .css('paddingRight','35px');
            _this.ui.transactionHistoryListDatatable.find('th:eq(3)')
            .css('paddingRight','35px');
        }).on('draw', function ($event, dataTableSettings){
            
            //There is no direct API to update info label, so updating it on DOM
            Backbone.$(dataTableSettings.nTableWrapper).find('.dataTables_info span')
                .text(_this.producerName);
            Backbone.$('[data-toggle="popover"]').popover({
                trigger: 'manual',
                placement: 'auto',
                container: 'body'
            });
            
            Backbone.$('[data-toggle="popover"]').mouseleave(_this._hideInfoPopover);
            Backbone.$('[data-toggle="popover"]').mouseenter(_this._showInfoPopover);
            Backbone.$('[data-toggle="popover"]').click(_this._toggleInfoPopover);
            Backbone.$('[data-toggle="popover"]').blur(_this._hideInfoPopover);
        });

        utils.bindDataTableEvents({
            dtObject            : table, 
            viewScope           : this, 
            viewName            : 'transaction-history-datatable-v: ',
            spinnerChannel      : spinnerChannel,
            debugModule         : debugModule,
            addOrderListener    : true
        });

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));

    },
  
    /**
     * Determine the ordering to use for the table.
     *
     * @param {string} the current tab, which determines the default sort
     * @param {object} the state object, which may be null
     *
     * @returns {Array} an Array in the form of:
     * [
     *     [col, dir]
     * ]
     *
     * @private
     */
    _getTableOrdering: function _getTableOrdering(tab, state) {
        var ordering = [];
        if (state) {

            // Need to explicitly check for col === 0, because it's valid even though it's falsy
            if (state.col || state.col === 0) {
                ordering[0][0] = Number(state.col);
            }

            if (state.dir) {
                ordering[0][1] = state.dir;
            }
        }

        return ordering;
    },

    /**
     * Show no result messages for transaction history view
     * @private
     */
    _showNoResultsMessage : function _showNoResultsMessage () { 
        this.model.set(
            'alertMessage',
            errorHelper.createAlert('No Transaction History', 'info')
        );
        this.render();
    },
});

module.exports = TransactionHistoryDataTableView;