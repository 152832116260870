var selectors = {

    SIDEBAR: '.js-sidebarapp',

    REGIONS: {

        MENU: '.js-sidebarapp-region-menu'

    }

};

module.exports = selectors;
