/* global Marionette:false, Backbone:false, $:false*/
/**
 * Controller section for handling generic controls for handling OSO-Next User experience pages
 * The business logic would be fetched from oso-onlineservicesprj (Vintage) application 
 *
*/
var DeepLinkModel = require('../models/vintage-user-experience-m');
var spinnerChannel   = Backbone.Radio.channel('spinner');

var template = require('../templates/vintage-user-experience-t.hbs');
import errorHelper from '../../../utils/error-helper';
var DeepLinkView = Marionette.LayoutView.extend({
    template: template,
    regions: {
        vintageContentRegion: '#vintage-events-wrapper',
    },
    errors : {
        deepLinkPathMissing      : 'requires deepLinkPath to show the content',
        dataLoadIssue      : 'Error loading the data. Please reload the page.'
    },
 
    initialize: function initialize(options) {
        // get the hash path from #c/deeplink/ endpoint
        let deeplinkPath = location.hash;
        let deepLinkInd = deeplinkPath.indexOf('deeplink');
        if (deepLinkInd > 0) {
            deeplinkPath = deeplinkPath.substring(deepLinkInd);
        }
        // throw error if the deeplink path is missing.
        if (!deeplinkPath) {
            throw new Error(this.errors.deepLinkPathMissing);
        }
        
        // initialize the model
        this.model = new DeepLinkModel({
            deepLinkPath: deeplinkPath
        });
        // setting url path for Object in template
        this.model.set('dataUrl',this.model.url());
        this.listenTo(this, 'dataload', this._showVintageServerMessage);
    },
  
    onRender: function onRender() {
        var obj = document.createElement('object');
        var this_ = this;
        //obj.className = 'vintage-events-object-display';
       
        $(obj).addClass('vintage-events-object vintage-events-object-hidden');
     
      
        obj.onload = function () {
            spinnerChannel.trigger('hide', this_);
            $(obj).removeClass('vintage-events-object-hidden');
        };
        obj.onerror = function () {
            spinnerChannel.trigger('hide', this_);
            this_.trigger('dataload', this_.errors.dataLoadIssue, 'warning', this_);
        };
        obj.data = this.model.url();
        this.$el.find('.vintage-event-object-div').append(obj);

    },
  /**
  * Lifecycle function.
  */
    onBeforeShow: function onBeforeShow() {
        var regionData = this.regions.vintageContentRegion;
        spinnerChannel.trigger('show', {
            viewScope   : this,
            regionName: regionData,
            size        : 'standard',
            position: 'fixed'
        });
    },

     /**
     * Error handling to show server message for server error
     * @param  {string} message message to print
     */
    _showVintageServerMessage: function _showVintageServerMessage( message, type, this_) {
        this_.model.set(
          'alertMessage',
           errorHelper.createAlert(message, type)
        );
        this_.render();
    },

    
    
   
});
module.exports = DeepLinkView;