/**
 * Policy detail data cleanup functions for properties with dataAvailability values
 */

var policyDataAvailability = {

    /**
     * Deletes any object properties that have a sub-property called 'dataAvailability'
     * with a value of 'notImplemented'.
     * 
     * @param policyData object
     * @returns object - the input object, with 'notImplemented' properties removed
     */
    deleteNotImplemented : function deleteNotImplemented (policyData) {
        var iterate = function (dataObj) {
            if (!dataObj || typeof dataObj !== 'object') {
                return;
            }
            for (var key in dataObj) {
                if (dataObj.hasOwnProperty(key)) {
                    if (dataObj[key] && dataObj[key].dataAvailability === 'notImplemented') {
                        delete dataObj[key];
                    } else {
                        iterate(dataObj[key]);
                    }
                }
            }
        };

        iterate(policyData);
        return policyData;
    },

    /**
     * Sets to `null` any object properties deeper than the top level that have a sub-property
     * called 'dataAvailability' with a value of 'notAvailable'. Top-level properties are ignored.
     * 
     * @param policyData object
     * @returns object - the input object, with 'notAvailable' properties removed
     */
    nullifyNotAvailableChildProperties : function nullifyNotAvailableChildProperties (policyData) {
        var iterate = function (dataObj) {
            if (!dataObj || typeof dataObj !== 'object') {
                return dataObj;
            }
            for (var key in dataObj) {
                if (dataObj[key] && dataObj[key].dataAvailability === 'notAvailable') {
                    dataObj[key] = null;
                } else {
                    iterate(dataObj[key]);
                }
            }
        };

        // Loop through the top-level properties and nullify notAvailable properties
        // *below* the top level.
        for (var key in policyData) {
            if (policyData.hasOwnProperty(key)) { // skip inherited properties
                iterate(policyData[key]);
            }
        }
        return policyData;
    }
};

module.exports = policyDataAvailability;