/* global Backbone:false, Marionette:false, _:false */

/**
 * The view used to display the alert banner at the top of every OSO view. This view uses
 * wcm-content/models/wcm-content-m.js as the model in order to retrieve content from WCM.
 */
var analyticsChannel = Backbone.Radio.channel('analytics');
var spinnerChannel   = Backbone.Radio.channel('spinner');
var WcmContentModel  = require('../../wcm-content/models/wcm-content-m');

var TopBannerView = Marionette.ItemView.extend({

    // Add SurveyMonkey behavior
    behaviors : {
        surveyMonkeyButton : {}
    },

    errors   : {
        invalidWcmPath : 'siteWideBanner view requires a valid "wcmPath" value'
    },

    template : false,

    initialize : function initialize(options) {

        if (options && _.isObject(options) && _.has(options, 'wcmPath')
            && _.isString(options.wcmPath)) {

            this.model = new WcmContentModel({
                wcmPath : options.wcmPath
            });

        } else {
            throw new Error(this.errors.invalidWcmPath);
        }
    },

    onBeforeShow : function onBeforeShow () {

        this.listenTo(this.model, 'sync', this._handleDisplay);
        this.listenTo(this.model, 'error', this._handleWcmError);

        spinnerChannel.trigger('show', {
            viewScope   : this,
            size        : 'medium'
        });

        this.model.fetch();
    },

    /**
     * Handle the display of the WCM content.
     * @private
     */
    _handleDisplay : function _handleDisplay () {
        var bannerContent = this.model.get('body');

        // stop the spinner regardless of whether or not there's content to display
        spinnerChannel.trigger('hide', this);

        if (bannerContent) {
            this.$el.append(bannerContent);
        }

        this.collapseBanner(this.options.collapseBanner);

    },

    /**
     * Set banner collpase state based on flag parameter
     * 
     * As content is from WCM, 
     * need to make sure the ID of collapse div is `#alertcollapse`
     * @param  {boolean} flag 
     */
    collapseBanner : function collapseBanner (flag) {
        if (flag) {
            this.$el.find('#alertcollapse').collapse('hide');
        } else {
            this.$el.find('#alertcollapse').collapse('show');
        }
    },

    /**
     * Handle errors returned from the WCM server.
     *
     * @param {object} model
     * @param {object} response
     * @private
     */
    _handleWcmError : function _handleWcmError(model, response) {
        var errorMessage = 'Error retrieving WCM content from ' + this.model.get('wcmPath')
                + '. Received a ' + response.status + ' status code.';

        // Stop the spinner
        spinnerChannel.trigger('hide', this);

        analyticsChannel.trigger('trackException', {
            fatal   : false,
            message : errorMessage
        });
    }

});

module.exports = TopBannerView;