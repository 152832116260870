// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        ("
    + container.escapeExpression(((helper = (helper = helpers.fileCount || (depth0 != null ? depth0.fileCount : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"fileCount","hash":{},"data":data}) : helper)))
    + ") File"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.fileCount : depth0),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " uploaded, \r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "s";
},"4":function(container,depth0,helpers,partials,data) {
    return "        (0) Files uploaded,\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "        comment uploaded\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        no comment  \r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <dd  class=\"text-uppercase\">"
    + container.escapeExpression(((helper = (helper = helpers.comment || (depth0 != null ? depth0.comment : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"comment","hash":{},"data":data}) : helper)))
    + "</dd>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<dt>\r\n    <strong>\r\n        "
    + container.escapeExpression((helpers.dateTimeFormat || (depth0 && depth0.dateTimeFormat) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.submitTimestamp : depth0),"MM/DD/YYYY, hh:mm A z",{"name":"dateTimeFormat","hash":{},"data":data}))
    + "\r\n    </strong> &mdash;\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fileCount : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "    \r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.comment : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</dt>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.comment : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                        ";
},"useData":true});
