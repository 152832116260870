/* global Backbone:false, _:false */
/**
 * Model to retrieve producer details from /secure/rest/producers/{producerId} endpoint.
 */

import config from '../../js/config/config';
var analyticsChannel = Backbone.Radio.channel('analytics');

const ProducerDetailsModel = Backbone.Model.extend({

    errors : {
        missingProducerId : 'producerId must be a number in the options object sent to ' +
        'initialize the ProducerDetailsModel.'
    },

    urlRoot() {
        if (this.producerId) {
            return config.apiUrlRoot + 'producers/' + this.producerId;
        } else {
            throw new Error(this.errors.missingProducerId);
        }
    },

    initialize : function initialize(options) {
        if (_.isObject(options) && options.producerId) {
            this.producerId = options.producerId;

            this.listenTo(this, 'error', this._handleFetchError);
        } else {
            throw new Error(this.errors.missingProducerId);
        }
    },

    parse(response) {
        return response;
    },

    /**
     * Silently logs the fetch error. Since the producer details data
     * does not impact the functioning of the application itself
     * (only analytics data), handle all errors gracefully.
     *
     * @param {object} model
     * @param {object} response
     * @private
     */
    _handleFetchError : function _handleFetchError(model, response) {
        var message;

        if (response && response.status) {
            message = 'Error retrieving producer details for producerId ' + this.producerId +
                '. Received a ' + response.status + ' status code.';
        }

        analyticsChannel.trigger('trackException', {
            fatal : false,
            message : message
        });

    }

});

module.exports = ProducerDetailsModel;