// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "highlight";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<!-- \".highlight\" is added to the next bonus level --> \r\n\r\n<div class=\"overlay50 "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.bonusLevel : depth0),50,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-rel=\"tooltip\" data-placement=\"right\" title=\"50 life lives = $750 bonus!\"></div>\r\n<div class=\"overlay60 "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.bonusLevel : depth0),60,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-rel=\"tooltip\" data-placement=\"right\" title=\"60 life lives = $1,000 bonus!\"></div>\r\n<div class=\"overlay75 "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.bonusLevel : depth0),75,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-rel=\"tooltip\" data-placement=\"right\" title=\"75 life lives = $1,500 bonus!\"></div>\r\n<div class=\"overlay100 "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.bonusLevel : depth0),100,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-rel=\"tooltip\" data-placement=\"bottom\" title=\"100 life lives = $2,000 bonus!\"></div>\r\n<div class=\"overlay150 "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.bonusLevel : depth0),150,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-rel=\"tooltip\" data-placement=\"left\" title=\"150 life lives = $3,000 bonus!\"></div>\r\n<div class=\"overlay200 "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.bonusLevel : depth0),200,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-rel=\"tooltip\" data-placement=\"left\" title=\"200 life lives = $5,000 bonus!\"></div>";
},"useData":true});
