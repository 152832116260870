// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.ifWithinDateRange || (depth0 && depth0.ifWithinDateRange) || alias2).call(alias1,(depth0 != null ? depth0.comingSoonStart : depth0),(depth0 != null ? depth0.comingSoonEnd : depth0),{"name":"ifWithinDateRange","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifWithinDateRange || (depth0 && depth0.ifWithinDateRange) || alias2).call(alias1,(depth0 != null ? depth0.nowOpenStart : depth0),(depth0 != null ? depth0.nowOpenEnd : depth0),{"name":"ifWithinDateRange","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"well well-enrollment\">\r\n            <div class=\"row\">\r\n                <div class=\"col-lg-8\">\r\n                    <div class=\"enrollment-icon-wrapper\">\r\n                        <span class=\"fa-stack\">\r\n                            <i class=\"fa fa-television fa-stack-2x\"></i>\r\n                            <i class=\"fa fa-heart fa-stack-1x\"></i>\r\n                        </span>\r\n                        <span>"
    + alias4(((helper = (helper = helpers.enrollmentYear || (depth0 != null ? depth0.enrollmentYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"enrollmentYear","hash":{},"data":data}) : helper)))
    + "</span>\r\n                    </div>\r\n                    <div class=\"well-enrollment-content\">\r\n                        <h2 class=\"text-uppercase\">Open Enrollment <br class=\"hidden-lg\"><small>&mdash; "
    + alias4(((helper = (helper = helpers.startDayText || (depth0 != null ? depth0.startDayText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startDayText","hash":{},"data":data}) : helper)))
    + " &ndash; "
    + alias4(((helper = (helper = helpers.endDayText || (depth0 != null ? depth0.endDayText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endDayText","hash":{},"data":data}) : helper)))
    + "</small></h2>\r\n                        <hr class=\"visible-lg\">\r\n                        <p>Open enrollment for Career Agency general agents, associate general agents, sales directors and career agents.</p>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disableBenefitsLinks : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "                            <i class=\"fa fa-arrow-right margin-right-5\"></i>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.userIsGA : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </p>\r\n                    </div>\r\n                </div>\r\n                <div class=\"col-lg-4\">\r\n                    <hr class=\"hidden-lg margin-top-20 margin-btm-20\">\r\n                    <h2>Coming Soon!</h2>\r\n                    <hr class=\"visible-lg\">\r\n                    <p>Enrollment starts in...</p>\r\n                    <div class=\"countdown-wrapper\" id=\"comingSoonCountdown\">\r\n                        <div class=\"countdown-square\">\r\n                            <span class=\"number js-countdown-days\">&nbsp;</span>\r\n                            <span class=\"letters text-uppercase\">Days</span>\r\n                        </div>\r\n                        <div class=\"countdown-square\">\r\n                            <span class=\"number js-countdown-hours\">&nbsp;</span>\r\n                            <span class=\"letters text-uppercase\">Hours</span>\r\n                        </div>\r\n                        <div class=\"countdown-square\">\r\n                            <span class=\"number js-countdown-minutes\">&nbsp;</span>\r\n                            <span class=\"letters text-uppercase\">Minutes</span>\r\n                        </div>\r\n                        <div class=\"countdown-square\">\r\n                            <span class=\"number js-countdown-seconds\">&nbsp;</span>\r\n                            <span class=\"letters text-uppercase\">Seconds</span>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                            <p class=\"disabled\">\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                            <p>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.disableBenefitsLinks : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                                More enrollment\r\n                                <span class=\"text-nowrap\">information<i class=\"ace-icon fa fa-external-link margin-left-5\"></i></span></a>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                                <a aria-disabled=\"true\">\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                                <a href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"https://api.oneamerica.com/oso/secure/web/sso/pinpoint?RelayState=https://oneamerica.pinpointglobal.com/Portal/resourcepages/gainfocenterpages/CompContractBen/benefit/general-agent",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\"\r\n                                target=\"_blank\" rel=\"noopener noreferrer\" >\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                            <a href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"#c/my-benefits",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\">More enrollment information</a>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"well well-enrollment well-enrollment-enrolling-now\">\r\n            <div class=\"row\">\r\n                <div class=\"col-lg-8\">\r\n                    <div class=\"enrollment-icon-wrapper\">\r\n                        <span class=\"fa-stack\">\r\n                            <i class=\"fa fa-television fa-stack-2x\"></i>\r\n                            <i class=\"fa fa-heart fa-stack-1x\"></i>\r\n                        </span>\r\n                        <span>"
    + alias4(((helper = (helper = helpers.enrollmentYear || (depth0 != null ? depth0.enrollmentYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"enrollmentYear","hash":{},"data":data}) : helper)))
    + "</span>\r\n                    </div>\r\n                    <div class=\"well-enrollment-content\">\r\n                        <h2 class=\"text-uppercase\">Open Enrollment <br class=\"hidden-lg\"><small>&mdash; "
    + alias4(((helper = (helper = helpers.startDayText || (depth0 != null ? depth0.startDayText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startDayText","hash":{},"data":data}) : helper)))
    + " &ndash; "
    + alias4(((helper = (helper = helpers.endDayText || (depth0 != null ? depth0.endDayText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endDayText","hash":{},"data":data}) : helper)))
    + "</small></h2>\r\n                        <hr class=\"visible-lg\">\r\n                        <p>Open enrollment for Career Agency general agents, associate general agents, sales directors and career agents.</p>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disableBenefitsLinks : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "                            <i class=\"fa fa-arrow-right margin-right-5\"></i>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.userIsGA : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </p>\r\n                    </div>\r\n                </div>\r\n                <div class=\"col-lg-4\">\r\n                    <hr class=\"hidden-lg margin-top-20 margin-btm-20\">\r\n                    <h2>Enrolling Now!</h2>\r\n                    <hr class=\"visible-lg\">\r\n                    <p>Enrollment ends in...</p>\r\n                    <div class=\"countdown-wrapper\" id=\"nowOpenCountdown\">\r\n                        <div class=\"countdown-square\">\r\n                            <span class=\"number js-countdown-days\">&nbsp;</span>\r\n                            <span class=\"letters text-uppercase\">Days</span>\r\n                        </div>\r\n                        <div class=\"countdown-square\">\r\n                            <span class=\"number js-countdown-hours\">&nbsp;</span>\r\n                            <span class=\"letters text-uppercase\">Hours</span>\r\n                        </div>\r\n                        <div class=\"countdown-square\">\r\n                            <span class=\"number js-countdown-minutes\">&nbsp;</span>\r\n                            <span class=\"letters text-uppercase\">Minutes</span>\r\n                        </div>\r\n                        <div class=\"countdown-square\">\r\n                            <span class=\"number js-countdown-seconds\">&nbsp;</span>\r\n                            <span class=\"letters text-uppercase\">Seconds</span>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.disableBenefitsLinks : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "                                    More enrollment\r\n                                    <span class=\"text-nowrap\">information<i class=\"ace-icon fa fa-external-link margin-left-5\"></i></span></a>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                    <a aria-disabled=\"true\">\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                                    <a href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"https://api.oneamerica.com/oso/secure/web/sso/pinpoint?RelayState=https://oneamerica.pinpointglobal.com/Portal/resourcepages/gainfocenterpages/CompContractBen/benefit/general-agent",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\"\r\n                                    target=\"_blank\" rel=\"noopener noreferrer\" >\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "                                <a href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"#c/my-benefits",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\">More enrollment information</a>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.userCanSeeCountdowns : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
