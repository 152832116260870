// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "                <div id=\"resource-center-table\" class=\"table-responsive hidden\">\r\n                    <table class=\"table table-striped table-sortable resource-center-table-view\" id=\"resource-center-datatable\" >\r\n                    </table>\r\n                </div>\r\n                <!-- /.table-responsive --> \r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "     <div id=\"resource-center-container\">\r\n        <div class=\"resource-center-wrapper\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n  ";
},"usePartial":true,"useData":true});
