/* global Marionette*/

/*
* Base class for analytics providers and it can be extended.
*
* example usage : 
*  
*  var BaseAnalyticsProvider = require('./base-analytics-providers');
* 
*  var WebTrendsProvider  = BaseAnalyticsProviders.extend({ override methods with options });
*
*/
var BaseAnalyticsProvider  = Marionette.Object.extend({
    /**
     * Track that a user viewed a page
     * @param options
     */
    trackView : function(options) {
    
    },

    /*
     * Set user id for all providers, which will be invoked 
     * from analytics module
     */
    setUser: function(id) {
        if (id) {
            this.webId = id;
            return true;
        }
        this.webId = '';
    },

    /**
     * Set webId of current impersonated user
     * @param {string} id webId received from 'user' service response
     */
    setViewingAs : function (id) {
        
    },

    //track all event actions
    trackAction: function(options) {
       
    },

    //track all exceptions occurred in applications
    trackException: function(options) {
       
    }
});

// export a singleton instance of the module
module.exports = BaseAnalyticsProvider;