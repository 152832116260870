/* global require */

/**
* Extending Performance center dial chart Base view for Leaders' Conference (LC)
*/

var utils  = require('../../../utils/utils');
var PerformanceCenterDialBaseView = require('./performance-center-dial-base-v');
var performanceCenterDialLCTemplate 
    = require('../templates/performance-center-dial-lc-t.hbs');

var PerformanceCenterDialLCView = PerformanceCenterDialBaseView.extend({
    template: performanceCenterDialLCTemplate,
    buildDialChartOptions: function () {

        //*** Qualifying Amount Qualified ***/
        var actualAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('actualQualAmount'), false, true
        );
        this.model.set('actualAmountNumber', actualAmountNumber);

        var requiredAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('requiredQualAmount'), true, true
        );

        // Check mark flag
        // Same flag will be used for Qualifying Amount onSchedule
        if (this.model.get('qualifyingGoalAchievedFlag') === true) {
            this.checkMark = true;
        }
        if (this.model.get('leadersAprStatus')) {
            if (this.checkMark && (this.model.get('leadersAprStatus') !== 'FQ'
                    && this.model.get('leadersAprStatus') !== 'NR' )) {
                this.checkMark = false;
            }
        }

        this.dialChartOptionsOne = {
            chartType: 'double',
            outerValue:actualAmountNumber,
            innerValue:this.model.get('actualLives'),
            classLabelOne: 'chart-double-text-one',
            classLabelTwo: 'chart-double-text-two',
            textLabelOne: utils.formatAsCurrency(actualAmountNumber),
            textLabelTwo: this.model.get('actualLives')+ ' lives',
            checkMark: this.checkMark,
            outerChartOptions: {
                maxValue   : requiredAmountNumber,
                scaleColor : '#ffffff'
            },
            innerChartOptions: {
                maxValue   : this.model.get('requiredLives'),
                scaleColor : '#ffffff'
            }
        };

        var requiredRemainingQualAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('requiredRemainingQualAmount'), true, true
        );
        this.model.set('requiredRemainingQualAmountNumber', requiredRemainingQualAmountNumber);

        //show green color for outer chart if flag Y
        if (requiredRemainingQualAmountNumber === 0 && !this.checkMark) {
            this.dialChartOptionsOne.outerChartOptions.barColor = this.dialColorComplete;
        } else if (this.model.get('requiredRemainingLives') === 0 && !this.checkMark) {
            this.dialChartOptionsOne.innerChartOptions.barColor = this.dialColorComplete;
        }

        //*** Qualifying Amount onSchedule ***/
        var onScheduleAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('onscheduleQualAmount'), true, true
        );

        var onscheduleRemainingQualAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('onscheduleRemainingQualAmount'), true, true
        );
        this.model.set('onscheduleRemainingQualAmountNumber', onscheduleRemainingQualAmountNumber);

        this.dialChartOptionsTwo = {
            chartType: 'double',
            outerValue:actualAmountNumber,
            innerValue:this.model.get('actualLives'),
            classLabelOne: 'chart-double-text-one',
            classLabelTwo: 'chart-double-text-two',
            textLabelOne: utils.formatAsCurrency(actualAmountNumber),
            textLabelTwo: this.model.get('actualLives')+ ' lives',
            checkMark: this.checkMark,
            outerChartOptions: {
                maxValue:onScheduleAmountNumber,
                scaleColor: '#ffffff'
            },
            innerChartOptions: {
                maxValue:this.model.get('onscheduleLives'),
                scaleColor: '#ffffff'
            }
        };

        //show green color for chart bars depending on flags
        if (onscheduleRemainingQualAmountNumber === 0 && !this.checkMark) {
            this.dialChartOptionsTwo.outerChartOptions.barColor = this.dialColorComplete;
        }
        if (this.model.get('onscheduleRemainingLives') === 0 && !this.checkMark) {
            this.dialChartOptionsTwo.innerChartOptions.barColor = this.dialColorComplete;
        }

        // Only worry about the "Total FYC" if this is the "leadersConference" section AND
        // this is a CA (type = "retail").

        if (this.model.get('section') === 'leadersConference'
            && this.model.get('type') === 'retail') {

            //** Total FYC Qualified **/
            var actualTotalAmountNumber = utils.isoCurrencyToNumber(
                this.model.get('actualTotalAmount'), false, true
            );
            this.model.set('actualTotalAmountNumber', actualTotalAmountNumber);

            var requiredTotalAmountNumber = utils.isoCurrencyToNumber(
                this.model.get('requiredTotalAmount'), false, true
            );

            var requiredRemainingTotalAmountNumber = utils.isoCurrencyToNumber(
                this.model.get('requiredRemainingTotalAmount'), true, true
            );
            this.model.set(
				'requiredRemainingTotalAmountNumber', requiredRemainingTotalAmountNumber);

            var actualTotalAmountSign = utils.signOfNumber(actualTotalAmountNumber);

            this.checkMark = false;
            if (this.model.get('totalGoalAchievedFlag') === true
                && (this.model.get('leadersAprStatus') === 'FQ'
                    || this.model.get('leadersAprStatus') === 'NR')) {
                this.checkMark = true;
            }

            this.dialChartOptionsThree = {
                chartType     : 'single',
                outerValue    : actualTotalAmountNumber,
                classLabelOne : 'chart-text-one',
                classLabelTwo : 'chart-text-two lighter',
                textLabelOne  : actualTotalAmountNumber,
                textLabelTwo  : 'Total FYC',
                checkMark     : this.checkMark,
                chartOptions  : {
                    maxValue    : requiredTotalAmountNumber,
                    scaleColor  : '#ffffff',
                    centerLabel : {
                        animate         : true,
                        elementSelector : '.chart-text-one',
                        sign            : actualTotalAmountSign,
                        formatFunction  : utils.formatAsCurrency,
                        round           : true
                    }
                }
            };

            if (requiredRemainingTotalAmountNumber === 0) {
                this.dialChartOptionsThree.chartOptions.barColor = this.dialColorComplete;
                this.dialChartOptionsThree.classLabelTwo = 
                    this.dialChartOptionsThree.classLabelTwo +' oa-forest';
            }

            //*** Total FYC onSchedule ***/

            var onscheduleTotalAmountNumber = utils.isoCurrencyToNumber(
                this.model.get('onscheduleTotalAmount'), false, true
            );

            this.dialChartOptionsFour = {
                chartType     : 'single',
                outerValue    : actualTotalAmountNumber,
                classLabelOne : 'chart-text-one',
                classLabelTwo : 'chart-text-two lighter',
                textLabelOne  : actualTotalAmountNumber,
                textLabelTwo  : 'Total FYC',
                checkMark     : this.checkMark,
                chartOptions  : {
                    maxValue    : onscheduleTotalAmountNumber,
                    scaleColor  : '#ffffff',
                    centerLabel : {
                        animate         : true,
                        elementSelector : '.chart-text-one',
                        sign            : actualTotalAmountSign,
                        formatFunction  : utils.formatAsCurrency,
                        round           : true
                    }
                }
            };

            // if onscheduleRemainingTotalAmount is 0, the dial and the inner text should be green
            var onscheduleRemainingTotalAmountNumber = utils.isoCurrencyToNumber(
                this.model.get('onscheduleRemainingTotalAmount'), true, true
            );
            this.model.set(
                'onscheduleRemainingTotalAmountNumber', 
                onscheduleRemainingTotalAmountNumber
            );

            if (onscheduleRemainingTotalAmountNumber === 0 
                    || requiredRemainingTotalAmountNumber === 0) {
                this.dialChartOptionsFour.chartOptions.barColor = this.dialColorComplete;
                this.dialChartOptionsFour.classLabelTwo = this.dialChartOptionsFour.classLabelTwo +
                    ' oa-forest';
            }

        }

    }

});

module.exports = PerformanceCenterDialLCView;
