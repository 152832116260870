/* global Backbone: false, Marionette:false, $:false, _:false */

//load all partials and helpers
require('../partials');

// Load handlebars helper method to avoid unit test failure
require('../../../utils/hb-helpers');

var checkpoint                      = require('../../../modules/checkpoint/checkpointModule');
var debugModule                     = require('../../../modules/debug/debugModule').init();
var errorHelper                     = require('../../../utils/error-helper');
var PolicyListDataTableInfoView     = require('./policy-list-datatable-info-v');
var PolicyListCountsModel           = require('../models/policy-list-counts-m');
var PolicySearchView               = require('./policy-search-wrapper-v');
var InforceSRView                   = require('./inforce-sr-wrapper-v');
var template                        = require('../templates/policy-list-t.hbs');
var utils                           = require('../../../utils/utils');
var validTabs               = [
    'allPolicies', 'pending', 'paid', 'inactive', 'search', 'inforce-service-request'
];

/**
 * Layout view for viewing producer's policies.
 *
 * A common view for displaying Policy List for following users
 *
 *  1. Producer
 *  2. Home office - who has Producer name search capability 
 *  3. Org - Managers 
 *  4. Own Policy List
 *
 *  Currently we are identifying the type based upon availability of 
 *  'producerOrgId' or 'producerId' or both not exist in options object.
 *  The value of producerOrgId/ producerId will be passed through 'producerId' query param
 *  of 'policies/summaries' service along with:
 *
 *  1. producerOrgId - stand for Organization manger's Id which will set
 *     value 'org' for 'hierarchy' query param for 'policies/summaries' service.
 *     
 *  2. producerId or  both not exist - That mean user is a producer and 'hierarchy' query param
 *     will set 'producer' by default.
 *
 *  Note:
 *  For placing this view in a multiple tabs of same view, we require to prevent duplication
 *  of ID attributes used in the views templates. For avoiding ID conflict we have used
 *  new property 'hierarchy' (especially for Org Manager - self) in options which update the
 *  template by prepending each and every elements with ID attributes and same will be done 
 *  while defining regions and UI blocks in this view.
 *     
 */
var PolicyListView = Marionette.LayoutView.extend({

    template : template,
    regions : function (options) {
        var regionsElements = {
            allPolicyListDatatableRegion      : '#all-policy-list-datatable-region',
            inactivePolicyListDatatableRegion : '#inactive-policy-list-datatable-region',
            paidPolicyListDatatableRegion     : '#paid-policy-list-datatable-region',
            pendingPolicyListDatatableRegion  : '#pending-policy-list-datatable-region',
            pendingSearchRegion               : '#policy-search-region',
            inforceSRRegion                   : '#inforce-sr-region',

            countAllPolicies                  : '#count-all-policies',
            countInactivePolicies             : '#count-inactive-policies',
            countPaidPolicies                 : '#count-paid-policies',
            countPendingPolicies              : '#count-pending-policies'
        };

        // If hierarchy option is exist then and has 'org' value
        // then all region element need to start with #org
        // so replacing # with #org-
        if (options.hierarchy === 'org') {
            regionsElements = _.mapObject(regionsElements, function (selector, key) {
                return selector.replace(/#/,'#org-');
            });
        }
       
        return regionsElements;
    },

    ui: {
        tabLink : '.oa-js-tab'
    },

    errors : {
        missingRegionName    : 'Missing region name to render policy list',
        missingStatus        : 'Missing status to render policy list',
        systemError          : 'The system has encountered an unexpected error.',
        serverError          : 'An error occurred while attempting to retrieve the policy list',
        noPolicies           : {
            allPolicies : 'No policies.',
            pending     : 'No pending policies.',
            inactive    : 'No inactive policies.',
            paid        : 'No paid policies.'
        }
    },
    
    events : {
        'click @ui.tabLink' : '_showPolicyList',
    },

    initialize : function initialize (options) {

        var _this         = this;

        // If view is placed on multiple tab has different URL
        // then we need to expect hashPrefix in options parameter
        // otherwise hashPrefix will be taken from current url hash
        var hashPrefix    = this.options.hashPrefix 
            ? this.options.hashPrefix : checkpoint.getPageIdFromHash(location.hash);
        var searchOptions = {};

        this.hierarchy      = options.hierarchy;
        
        this.producerId     = options.producerId;

        this.producerOrgId  = options.producerOrgId;

        this.currentTab     = 'pending';

        this.model          = new Backbone.Model();

        this.hasInvalidTab  = false;

        // set the hashPrefix for the tabs in the template
        this.model.set({
            hashPrefix : hashPrefix,
            hierarchy  : this.hierarchy
        });

        if (this.producerOrgId) {

            // set the hashPrefix and tabLinksQueryString for the tabs in the template
            this.model.set({
                tabLinksId : '?producerOrgId=' + this.producerOrgId
            });
        } else if (this.producerId) {

            // set the hashPrefix and tabLinksQueryString for the tabs in the template
            this.model.set({
                tabLinksId : '?producerId=' + this.producerId
            });
        } 

        // create an object to store tab state. Used to set the proper checkpoint when user
        // navigates between tabs.
        this.tabState = {};

        if (options) {

            // set the initial tab and state
            if (options.currentTab && validTabs.indexOf(options.currentTab) > -1) {
                this.currentTab = options.currentTab;

            } else {

                //As Trigger method is not firing from initialization 
                //this flag will be used at onBeforeShow method to
                //rewrite url;
                this.hasInvalidTab = true;
            }

            if (this.currentTab === 'search') {
                searchOptions = options.tabState;
                this.tabState[this.currentTab] = options.tabState;
            } else if (options.tabState) {
                this.tabState[this.currentTab] = options.tabState.searchState;
            }

            _.each(validTabs, function (tab) {
                if (!_.isObject(_this.tabState[tab])) {
                    _this.tabState[tab] = {};
                } 

                if (_this.producerOrgId) {
                    _.extend(_this.tabState[tab], {producerOrgId : _this.producerOrgId});
                }

                if (_this.producerId) {
                    _.extend(_this.tabState[tab], {producerId : _this.producerId});
                }
            });

            this.model.set({
                searchEnabled : this.options.searchEnabled ? this.options.searchEnabled : false,
                inforceServiceRequestEnabled : this.options.inforceServiceRequestEnabled ? 
+                        this.options.inforceServiceRequestEnabled : false
            });

            // Set up the policySearchModule and listen to events.
            if (this.options.searchEnabled) {

                // Create the Search panel
                this.pendingSearchView = new PolicySearchView(searchOptions);
                this.listenTo(this.pendingSearchView, 'stateChange', this._notifyOfStateChange);
                this.listenTo(this.pendingSearchView, 'showPolicy', this._showPolicy);
            }

            if (this.options.inforceServiceRequestEnabled) {
                this.inforceSRView = new InforceSRView({
                    state           : this.tabState
                });
            }
        }
    },

    onBeforeRender : function () {

        // define UI elements dynamically
        var uiElements = {
            allPoliciesTab        : '#all-policies-tab',
            pendingTab            : '#pending-tab',
            paidTab               : '#paid-tab',
            inactiveTab           : '#inactive-tab',
            searchTab             : '#search-tab',
            inforceServiceTab     : '#inforce-sr-tab'
        };
        if (this.options.hierarchy === 'org') {
            uiElements = _.mapObject(uiElements, function (selector, key) {
                return selector.replace(/#/,'#org-');
            });
        }

        this.ui = _.extend(this.ui, uiElements);
    },
    
    onBeforeShow: function onBeforeShow () {     

        var TempCountView = Backbone.Marionette.ItemView.extend({
            tagName  : 'span',
            className : 'spinner-wrapper-tabs',
            template : utils.generateSpinnerElement('small')
        });

        this.showChildView('countAllPolicies', new TempCountView());
        this.showChildView('countPendingPolicies', new TempCountView());
        this.showChildView('countPaidPolicies', new TempCountView());
        this.showChildView('countInactivePolicies', new TempCountView());

        //re-write /subpage-hash if current /subpage-hash is invalid
        if (this.hasInvalidTab) {
            this.trigger('stateChange', {});
            this.hasInvalidTab = false;
        }

        if (this.options.searchEnabled) {
            this.showChildView('pendingSearchRegion', this.pendingSearchView);
        }

        if (this.options.inforceServiceRequestEnabled) {
            this.showChildView('inforceSRRegion', this.inforceSRView);
        }
    },

    onRender : function onRender () {

        // remove the wrapping div that backbone adds to the DOM
        utils.unwrapView(this);
        this._setTabActive();

        //set up policy count model fetch
        this.model.set('counts', new PolicyListCountsModel({
            hierarchy     : this.hierarchy,
            producerId    : this.producerId,
            producerOrgId : this.producerOrgId
        }));

        this.listenTo(this.model.get('counts'), 'sync', this._showPolicyCounts);
        this.listenTo(this.model.get('counts'), 'error', this._handleCountsFetchError);

        this.model.get('counts').fetch();

        // Pre-load pending Tab when currentTab is search and not 'pending' one
        if (this.currentTab !== 'pending' && this.currentTab === 'search') {
            this._showPolicyListView('pendingPolicyListDatatableRegion', 'pending');
        }
    },

    /**
    * Method to render policy count views
    */
    _showPolicyCounts : function _showPolicyCounts () {

        var PolicyListCountsView  = Backbone.Marionette.ItemView.extend({
            tagName  : 'span',
            template : _.template('(<%= count%>)')
        });

        this.showChildView('countAllPolicies', new PolicyListCountsView({
            model : new Backbone.Model({
                count : this.model.get('counts').get('ALL')
            })
        }));


        this.showChildView('countPendingPolicies', new PolicyListCountsView({
            model : new Backbone.Model({
                count : this.model.get('counts').get('PENDING')
            })
        }));

        this.showChildView('countPaidPolicies', new PolicyListCountsView({
            model : new Backbone.Model({
                count : this.model.get('counts').get('PAID')
            })
        }));

        this.showChildView('countInactivePolicies', new PolicyListCountsView({
            model : new Backbone.Model({
                count : this.model.get('counts').get('INACTIVE')
            })
        }));
    },

    /**
    * Method to handle error occurred by counts service
    * @param {Object} model
    * @param {Object} response - The response returned from the service
    */
    _handleCountsFetchError : function _handleCountsFetchError(model, response) {

        // Replace spinner animation with empty parentheses (OOSO-3303)
        var EmptyParensView = Backbone.Marionette.ItemView.extend({
            tagName  : 'span',
            template : _.template('()')
        });
        var message = 'Unable to retrieve counts for ' + this.producerId + '.';

        this.showChildView('countAllPolicies', new EmptyParensView());
        this.showChildView('countPendingPolicies', new EmptyParensView());
        this.showChildView('countPaidPolicies', new EmptyParensView());
        this.showChildView('countInactivePolicies', new EmptyParensView());

        if (response) {

            // Add the status if it exists
            if (response.status) {
                message = message + ' HTTP status code: ' + response.status + '.';
            }

            if (response.responseText) {
                message = message + ' HTTP response text: "' + response.responseText + '"';
            }
        }

        debugModule.error(message);
    },

    /**
     * _setTabActive makes a particular tab on the page active using
     * the ui object as an associative array. 
     *
     * Tab pane selectors will be identified based on value of 'data-target'
     * attributes.
     * 
     * By default, the pending tab is set to active.
     * 
     * @private
     */
    _setTabActive : function _setTabActive () {

        var activeTab = this._getTabCurrent();
        var activeTabPaneId = activeTab.find('a').data('target');

        activeTab.addClass('active');
        this.$el.find(activeTabPaneId).addClass('active'); 
        
        //Load default tab data 
        //This will invoke method bind with ui element 'tabLink'
        this._showPolicyList(activeTab.find('a'));
    },

    /**
     * Gets the currently selected tab or the pending tab if none selected. 
     * 
     * @private
     */
    _getTabCurrent : function _getTabCurrent () {
        var defaultTab = this.ui.pendingTab;

        // determine tab element name
        if (this.currentTab && this.currentTab === 'inforce-service-request') {
            return this.ui.inforceServiceTab;
        } else if (this.currentTab && this.ui.hasOwnProperty(this.currentTab + 'Tab')) {
            return this.ui[this.currentTab + 'Tab'];
        } else {
            return defaultTab;
        }
    },

    /**
     * show policy for all status tabs 'all, pending, paid, inactive'
     * It will set 'status' (data attr) to fetch data  
     * and 'region' to render the data table 
     * 
     * @param  {object} e event/jquery object
     */
    _showPolicyList : function _showPolicyList (e) {

        var policyListRegion;
        var tabLink = e;
        if (e.currentTarget) {
            tabLink  = $(e.currentTarget);
        }         
        var status   = tabLink.data('status');
        var tabName  = tabLink.data('tabname');
        var tabState = _.extend({}, this.tabState[tabName], {});

        this.currentTab        = tabName;
        this.tabState[tabName] = tabState;

        this.trigger('stateChange', tabState);

        // 'status' indicates we're on a policy list tab
        if (status) {
            policyListRegion = status + 'PolicyListDatatableRegion';
        }

        // set data property to Tab element (A tag) to identify current situation
        // of data load 
        //  undefined - not loaded
        //  1 - completed
        if (!tabLink.data('dataFetchStatus') && this.currentTab !== 'search' 
            && this.currentTab !== 'inforce-service-request') {
            this._showPolicyListView(policyListRegion, status, tabState);
        }
    },

    /**
     * Show policy list view with status (all, pending, paid and inactive )
     * in specific region
     * 
     * @param {string} policyListRegion 
     * @param {string} status 
     * @param {object} tabState
     * 
     * @private
     */
    _showPolicyListView : function _showPolicyListView (policyListRegion, status, tabState) {
         
        if (!policyListRegion) {
            throw new Error(this.errors.missingRegionName);
        } else if (!status) {
            throw new Error(this.errors.missingStatus);
        }

        if (!tabState) {
            tabState = {};
        }
        
        this.pendingPolicyListDataTableInfoView = new PolicyListDataTableInfoView({
            hierarchy       : this.hierarchy,
            producerOrgId   : this.producerOrgId,
            producerId      : this.producerId,
            status          : status,
            state           : tabState
        });

        this.listenTo(
            this.pendingPolicyListDataTableInfoView, 
            'tableStateChange', 
            this._notifyOfStateChange
        );

        this.listenTo(
            this.pendingPolicyListDataTableInfoView, 
            'error', 
            this._showServerErrorMessage
        );

        this.listenTo(
            this.pendingPolicyListDataTableInfoView, 
            'noResults', 
            this._showNoResultsMessage
        );

        this.listenTo(
            this.pendingPolicyListDataTableInfoView, 
            'setProducerInfo', 
            this._setProducerInfo
        );
        
        //finally render data table
        this.showChildView(policyListRegion, this.pendingPolicyListDataTableInfoView);
    },

    /**
     * Replace the hash when the user changes the state (paging/sorting) of the result table.
     * @param {object} [changedState] The properties that have changed
     * @private
     */
    _notifyOfStateChange : function _notifyOfStateChange (changedState) {
        var state = this.tabState[this.currentTab];
        if (!state) {
            state = {};
        }
        _.extend(state, changedState);
        this.tabState[this.currentTab] = state;
        this.trigger('stateChange', state);
    },

    /**
     * Navigate to the policy detail page
     * @param policyId the ID of the policy to display
     * @private
     */
    _showPolicy : function _showPolicy (policyId) {
        this.trigger('showPolicy', policyId);
    },

    /**
     * Method to show Producer Info section once service is returned data 
     * @param  {object} info
     * @param {string} policyDataStatus status name of policy data returned
     *                                  (all, pending, paid or inactive)
     * 
     * @private
     */
    _setProducerInfo : function _setProducerInfo (info, policyDataStatus) {
        
        //set data property to "1" for dataFetchStatus
        //as this method is called once when data fetched successfully
        var currentTabElement = this._getTabCurrent();

        // In case of current policy data loaded is not for currentTab,
        // so set dataFetchStaus against the policy data status
        if (policyDataStatus) {
            
            // UI element for 'all' tab is defined as 'allPolicies'
            // for better readability. Mean time service request
            // only require 'all'. So appending 'Policies' with 'all'
            if (policyDataStatus === 'all') {
                policyDataStatus = 'allPolicies';
            }
            if (policyDataStatus !== this.currentTab) {
                currentTabElement = this.ui[policyDataStatus+'Tab'];
            }
        }

        if (currentTabElement && currentTabElement.find('a') ) {
            currentTabElement.find('a').data('dataFetchStatus', 1);
        }
        
        this.trigger('setProducerInfo', info);
    },

    /**
     * Show server error messages   
     * @private
     */
    _showServerErrorMessage : function _showServerErrorMessage (message, type) {

        if (!message) {
            message = this.errors.serverError;
        }

        if (!type) {
            type = 'warning';
        }

        this.pendingPolicyListDataTableInfoView.model.set(
            'alertMessage',
            errorHelper.createAlert(message, type)
        );
        this.pendingPolicyListDataTableInfoView.render();
    },

    /**
     * Show not result message
     * @param {string} policyDataStatus status name of policy data returned
     *                                  (all, pending, paid or inactive)
     */
    _showNoResultsMessage : function _showNoResultsMessage (policyDataStatus) {
        
        var currentTab = this.currentTab;

        // In case of current policy data loaded is not for currentTab,
        // so set dataFetchStaus against the policy data status
        if (policyDataStatus) {

            // UI element for 'all' tab is defined as 'allPolicies'
            // for better readability. Mean time service request
            // only require 'all'. So appending 'Policies' with 'all'
            if (policyDataStatus === 'all') {
                policyDataStatus = 'allPolicies';
            }
            if (policyDataStatus !== currentTab) {
                currentTab = policyDataStatus;
            }
        }
        
        this._showServerErrorMessage(this.errors.noPolicies[currentTab],'info');
    },

    /**
     * Function to expose the view's current tab to the parent view
     * @returns {string}
     */
    getCurrentTab : function getCurrentTab() {
        return this.currentTab;
    },

    /***
     * Function to expose the view's current tab's state to the parent view.
     * @returns {*}
     */
    getCurrentTabState : function getCurrentTabState() {
        return this.tabState[this.currentTab];
    },

});

module.exports = PolicyListView;