/* global Backbone:false, Marionette:false */
/**
 * Layout view for Edit Delegate page.
 */

import '../../../utils/hb-helpers';

import EditDelegateViewModel from '../viewModels/edit-delegate-vm';
import EditDelegateModel from '../models/edit-delegate-m';
import editDelegateTemplate from '../templates/edit-delegate-t.hbs';
import errorHelper from '../../../utils/error-helper';
import utils from '../../../utils/utils';

const focusNavBarChannel = Backbone.Radio.channel('focusNavBar');
const spinnerChannel = Backbone.Radio.channel('spinner');
const errorChannel = Backbone.Radio.channel('error');

const EditDelegateView = Marionette.LayoutView.extend({

    defaultName: { name },
    template: editDelegateTemplate,

    errors: {
        serverError: 'An error occurred while attempting to submit the form',
        producerAndRelationshipIdRequired: 'Both Producer and Delegate should be specified',
        producerIdRequired: 'No Producer Specified',
        relationshipIdRequired: 'No Delegate Specified'
    },

    modelEvents: {
        'validated:invalid': 'onValidationFail'
    },

    ui: {
        submitButton: '#edit-delegate-submit',
        cancelButton: '#edit-delegate-cancel',
        name: '#name',
        email: '#email',
        accessLevel: 'select[name=accessLevel]',
        ok: '#ok'
    },

    events: {
        'click @ui.submitButton': '_submitClick',
        'click @ui.cancelButton': '_cancel',
        'change @ui.accessLevel': '_accessLevelChange',
        'click @ui.ok': '_ok'
    },

    /**
     * Initialize the view
     * @param options
     */
    initialize(options) {
        let producerId;
        let relationshipId;
        let domainModel;
        this.utils = utils;

        // Create and set a new model if there isn't one passed in
        if (!options.model) {
            domainModel = new EditDelegateModel();
            this.model = new EditDelegateViewModel({
                domainModel: domainModel
            });
        } else if (options.model) {
            this.model = options.model;
        }

        if (!options || !options.stateObj || !(options.stateObj.producerId || 
            options.stateObj.relationshipId)) {
            errorChannel.trigger('showErrorPage', this.errors.producerAndRelationshipIdRequired);
            this.destroy();
            return false;
        }
        else if (!options.stateObj.producerId) {
            errorChannel.trigger('showErrorPage', this.errors.producerIdRequired);
            this.destroy();
            return false;
        }
        else if (!options.stateObj.relationshipId) {
            errorChannel.trigger('showErrorPage', this.errors.relationshipIdRequired);
            this.destroy();
            return false;
        }

        producerId = options.stateObj.producerId;
        relationshipId = options.stateObj.relationshipId;

        this.model.domainModel.set('producerId', producerId);
        this.model.domainModel.set('relationshipId', relationshipId);
        this.listenTo(this.model.domainModel, 'error', this._onError);
        this.listenTo(this.model.domainModel, 'sync', this._onSuccess);
    },

    onBeforeShow() {
        const _this = this;
        this.listenTo(this.model, 'sync', this.render);
        this.model.domainModel.fetch({

            //to hide wait indicator
            success: function () {
                _this._showDelegateInfo();
                spinnerChannel.trigger('hide', _this);
            },
            error: function () {
                _this._handleServerError();
                spinnerChannel.trigger('hide', _this);
            }
        });

        spinnerChannel.trigger('show', {
            viewScope: this,
            size: 'small',
            position: 'static'
        });
    },

    /**
     * Show Delegate Information from edit delegate service
     *
     * @private
     */
    _showDelegateInfo() {

        var delegateInfo = this.model.domainModel.get('delegate');
        if (delegateInfo) {
            if (delegateInfo.producer) {
                this.model.set('producerFullName', delegateInfo.producer.fullName);
            }

            if (delegateInfo.delegates && delegateInfo.delegates.length > 0) {
                this.model.set('name', delegateInfo.delegates[0].name);
                let webId = delegateInfo.delegates[0].webId;
                let isEmailAddressRegistered = webId && webId > 0;
                this.model.set('isEmailAddressNotRegistered', !isEmailAddressRegistered);
                if (!isEmailAddressRegistered) {
                    this.model.set('emailAddress', delegateInfo.delegates[0].emailAddress);
                }
                let accessLevel = this._getAccessLevel(delegateInfo.delegates[0].commissionAccess);
                this.model.set('accessLevel', accessLevel);
            }
        }


        // Need to flag to display messages/link after getting data
        // till that time FE need to display spinner animation
        spinnerChannel.trigger('hide', this);
        this.render();
    },

    /**
     * When rendering, bind the popover handler to the popover ui elements.
     */
    onValidationFail(errors) {
        this.validationErrors = errors;
        this.render();
    },


    /**
       * Enables/disables the submit button. If being disabled, the
       * CSS class "disabled" is added.
       *
       * @param {boolean} doEnable Set to TRUE to enable the button
       * @private
       */
    _setSubmitButtonState(doEnable) {
        if (doEnable) {
            this.ui.submitButton.removeClass('disabled');
            this.ui.submitButton.removeAttr('disabled');
        } else {
            // add the 'disabled' css class to the submit button
            this.ui.submitButton.addClass('disabled');
            this.ui.submitButton.attr('disabled', true);
        }
    },


    /**
     * Display a message to the user if an error occurs
     * @param model
     * @param response
     * @private
     */

    _onError(model, response) {
        if (response && (response.status === 404 || response.status === 400
            || response.status === 500)) {
            this.model.set(
                'alertMessage',
                errorHelper.createAlert(this.errors.serverError, 'warning')
            );
            this.render();
        }
    },


    _onSuccess(model, response) {
        spinnerChannel.trigger('hide', this);
    },

    _accessLevelChange: function (e) {
        let accessLevel = e.target.value;
        this.model.set('accessLevel', accessLevel);
    },

    _hasFullAccess: function (accessLevel) {
        return accessLevel === 'Full Access';
    },

    _getAccessLevel: function (commissionAccess) {
        return commissionAccess ? 'Full Access' : 'Limited Access';
    },

    _redirectToDelegateMaintenancePage: function () {
        let producerId = this.model.domainModel.get('producerId');
        let url = '#producer-delegate-maintenance?producerId=' + producerId;
        this.trigger('nav', url);
    },

    _ok: function (event) {
        if (event) {
            event.preventDefault();
        }
        this._redirectToDelegateMaintenancePage();
    },

    _cancel: function () {
        this._redirectToDelegateMaintenancePage();
    },

    _submitClick(event) {
        let isValid;
        let _this = this;
        if (event) {
            event.preventDefault();
        }

        spinnerChannel.trigger('show', {
            viewScope: this,
            position: 'fixed'
        });

        this._updateModel();
        isValid = this.model.isValid();
        if (isValid) {
            this._setSubmitButtonState(false);
            let xRequestedWithHeader = {
                'X-Requested-With': 'XMLHttpRequest'
            };

            this.model.domainModel.save(null, {
                dataType: 'json',
                data: JSON.stringify(this._getData()),
                type: 'PUT',
                contentType: 'application/json',
                headers: xRequestedWithHeader,
                success: function(model) {
                    spinnerChannel.trigger('hide', _this);
                    _this._redirectToDelegateMaintenancePage();
                }
            });
            focusNavBarChannel.trigger('focusNavBar');
        }
        this.render();
    },


    _updateModel() {
        this.model.set({
            name: this.ui.name.val(),
            emailAddress: this.ui.email.val()
        });
    },

    _getData() {
        return {
            name: this.model.get('name'),
            emailAddress: this.model.get('emailAddress'),
            commissionAccess: this._hasFullAccess(this.model.get('accessLevel'))
        };
    },

    // If this view is shown twice in quick succession (faster than the
    // model fetch can complete), then it's possible that by the time the
    // success callback is executed, this view might have been destroyed.
    // To avoid this situation, we abort the XHR (if there is one) when the
    // view is destroyed.
    onDestroy: function () {
        // cancel any pending XHR requests
        // this.fetchReq.abort();
        Backbone.$('.modal-backdrop.fade.in').remove();
    }
});
module.exports = EditDelegateView;
