/* global Backbone:false, _:false */
/**
 * Page which displays the Requirement Submission form and any associated error messages.
 */

// Load partials
import '../partials';

// add global partials
import '../../../partials';

import {createAlert} from '../../../utils/error-helper';
import template      from '../templates/requirement-submission-page-t.hbs';
import utils         from '../../../utils/utils';

import PolicyDetailModel                     from '../../policy/models/policy-detail-m';
import RequirementSubmissionConfirmationPage from './requirement-submission-confirmation-v';
import RequirementSubmissionFormModel        from '../models/requirement-submission-form-m';
import RequirementSubmissionFormView         from './requirement-submission-form-v';
import RequirementSubmissionFormViewModel    from '../viewModels/requirement-submission-form-vm';

// add handlebar helpers
import '../../../utils/hb-helpers';

const browserFeatureDetectionChannel  = Backbone.Radio.channel('browserFeature');
const errorChannel     = Backbone.Radio.channel('error');
const spinnerChannel   = Backbone.Radio.channel('spinner');

const RequirementSubmissionPage = Backbone.Marionette.LayoutView.extend({

    errors  : {
        browserRequirements   : 'Your web browser is unsupported for this feature. If you would ' +
                                'like to use the latest and greatest of OneSource Online, ' +
                                'please upgrade to a supported browser.',
        policyNotFoundMessage : 'The policy you requested was not found.',
        policyNumberMissing   : 'No Policy Number Specified',
        serverError           : 'An error occurred while attempting to retrieve the policy'
    },

    events  : {
        'click @ui.submitAnotherFormLink' : '_resetModelsAndReloadForm',
        // "hidden.bs.modal" is the event triggered after a modal is hidden. If
        // we navigate away before it's hidden, the modal backdrop will remain.
        // http://getbootstrap.com/javascript/#modals-events
        'hidden.bs.modal @ui.cancelSubmitModal' : '_navigateToPolicyDetailPage'
    },

    regions : {
        contentRegion : '#requirement-submission-page-content-region'
    },

    requiredBrowserFeatures : [
        'filereader',
        'formdata'
    ],

    template : template,

    ui       : {
        cancelSubmitModal     : '#cancel-submit-modal',
        contentView           : '#requirement-submission-page-content-region',
        submitAnotherFormLink : '#submit-another-form-link'
    },

    /**
     * Initialize the view.
     * @param {Object} options
     * @returns {*}
     */
    initialize (options) {
        let domainModel;
        let policyDetailsModel;

        if (options && options.stateObj && options.stateObj.policyId) {
            this.policyId = options.stateObj.policyId;
        }

        if (!this._checkRequiredBrowserFeatures(this.requiredBrowserFeatures)) {
            return false;
        }

        // Currently, AWD cases will not be submitted,
        // so only "policyId" will be passed to the model.
        if (!options.model && this.policyId) {

            policyDetailsModel = new PolicyDetailModel({ id : this.policyId });

            domainModel = new RequirementSubmissionFormModel({ policyId : this.policyId });

            // the domain model and the policy details model are passed into the viewModel
            this.model = new RequirementSubmissionFormViewModel({
                domainModel        : domainModel,
                policyDetailModel : policyDetailsModel
            });

        } else {

            // show error message since policyId is missing
            errorChannel.trigger('showErrorPage', this.errors.policyNumberMissing);

            // Destroy view to prevent calling render method
            // and show the error page which triggered above
            this.destroy();

            return false;
        }

        // attach utils for testing
        this.utils = utils;
    },

    onBeforeShow () {

        // attach events to the policyDetail model
        this.listenTo(this.model.policyDetailModel, 'sync', this._showForm);
        this.listenTo(this.model.policyDetailModel, 'error', this._showAlertMessage);

        // attach events to the domain model
        this.listenTo(this.model.domainModel, 'sync', this._showConfirmation);

        // show spinner in contentRegion view, till data is ready
        spinnerChannel.trigger('show', {
            viewScope    : this,
            regionName   : 'contentRegion',
            position     : 'fixed'
        });

        this.model.policyDetailModel.fetch();
    },

    /**
     * Check for the browser features necessary for the page. If there is a
     * required feature that is not detected, an alert is created for the user
     * and the method returns false.
     *
     * @param {Array.<string>} requiredFeatures A collection of browser features to detect.
     * @returns {boolean}
     * @private
     */
    _checkRequiredBrowserFeatures (requiredFeatures) {

        // Loop through each of the browser features required for the page.
        for (let i = 0; i<requiredFeatures.length; i++) {
            var feature = requiredFeatures[i];

            if (!browserFeatureDetectionChannel.request('detect', feature)) {

                let link = '#policy-detail?policyId=<%= policyId %>';

                // add 'targetuser' query param for impersonated user
                link  = utils.addTargetUserQueryParamToURL(link);

                // create a link to return the user to the policy detail page
                const message = _.template('<p><%= errorMsg %></p><p><i class="fa fa-arrow-left">' +
                    '</i> <a class="oa-js-nav" href="'+link+'">Return to policy detail</a></p>');

                this.model = new Backbone.Model({
                    alertMessage : createAlert(
                        message({
                            errorMsg : this.errors.browserRequirements,
                            policyId : this.policyId
                        }), 'warning')
                });

                return false;
            }
        }

        return true;
    },

    /**
     * Send the user back to the policy detail page using the policyId passed in
     * to the form.
     *
     * Since this is triggered by waiting for the "hidden.bs.modal"  event, check
     * the data attribute "navigate" on the modal to see if it's just being dismissed
     * or if the user selected to navigate to the policy detail page.
     *
     * @param {Object} e event
     * @private
     */
    _navigateToPolicyDetailPage (e) {
        const navigate = Backbone.$(e.currentTarget).data('navigate');

        if (navigate) {
            this.trigger('nav', '#policy-detail?policyId=' + this.policyId);
        }
    },

    /**
     * Calls _resetModels, empties the region, destroying the view being displayed.
     * _showForm is then called to display the form again.
     * @see {@link _resetModels}
     * @see {@link _showForm}
     * @param e {Object} event object
     * @private
     */
    _resetModelsAndReloadForm (e) {
        e.preventDefault();

        this._resetModels();

        // destroy the confirmation page view and empty the region
        this.getRegion('contentRegion').empty();

        this._showForm();
    },

    /**
     * Clear the view model and domain model. Reload defaults on the domain
     * model. This is used for cases where the user has another submission
     * to make.
     * @private
     */
    _resetModels () {

        // Clear the domain model (silently so that none of the change
        // events are triggered) and load the defaults from the view model.
        this.model.domainModel.clear({ silent : true }).set(this.model.defaults);

        // make sure that the policyId is set on the domainModel
        this.model.domainModel.set('policyId', this.policyId);

        // Don't pass the silent option here so that the views get updated
        this.model.reset();
    },

    /**
     * Display a message to the user if an error occurs
     * @param model
     * @param response
     * @private
     */
    _showAlertMessage (model, response) {

        if (response) {

            if (response.status === 404) {
                this.model.set(
                    'alertMessage',
                    createAlert(this.errors.policyNotFoundMessage, 'info')
                );
                this.render();

            } else if (response.status === 500) {
                this.model.set(
                    'alertMessage',
                    createAlert(this.errors.serverError, 'warning')
                );
                this.render();
            }
        }
    },

    /**
     * Displays the Confirmation view in the contentRegion.
     * @private
     */
    _showConfirmation () {
        const confirmationPage = new RequirementSubmissionConfirmationPage({
            model : this.model
        });

        const contentRegion = this.getRegion('contentRegion');

        // destroy the form view
        contentRegion.empty();
        // add the confirmation page view
        contentRegion.show(confirmationPage);

        this.utils.scrollTo(Backbone.$('body'));
    },

    /**
     * Displays the form view in the contentRegion.
     * @private
     */
    _showForm () {
        const formView = new RequirementSubmissionFormView({
            model : this.model
        });

        this.showChildView('contentRegion', formView);
    }

});

module.exports = RequirementSubmissionPage;
