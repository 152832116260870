// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <span class=\"user-info\">\r\n            <small>Welcome,</small>\r\n            "
    + container.escapeExpression(((helper = (helper = helpers.userName || (depth0 != null ? depth0.userName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"userName","hash":{},"data":data}) : helper)))
    + "\r\n        </span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"user-info user-info-noname\">Welcome</span>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<!-- src/apps/navbar/partials/navbar-dropdown-usermenu-user.hbs -->\r\n<a data-toggle=\"dropdown\" href=\"#\" class=\"dropdown-toggle\">\r\n    <i class=\"ace-icon fa fa-user\"></i>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.userName : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "  \r\n    <i class=\"ace-icon fa fa-caret-down\"></i>\r\n     \r\n</a>\r\n";
},"useData":true});
