// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "                    class=\"col-sm-3\"\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    class=\"col-sm-4 tout-align\"\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div id=\"daily-commissions-tout-region\"\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alignTout : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                >\r\n                </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                        class=\"col-sm-3\"\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        class=\"col-sm-4 tout-align\"\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div id=\"sales-connection-links-region\"\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alignTout : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                >\r\n                </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div id=\"forms-region\"\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alignTout : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "                ></div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alterAlignTout : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "                            class=\"col-sm-4 second-row-alter-tout\"\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                            class=\"col-sm-4 second-row-tout\"\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alignTout : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "                >\r\n                    <div class=\"center\">\r\n                        <i class=\"ace-icon fa fa-plus-circle oa-banner-blue fa-3x\"></i>\r\n                        <h4>\r\n                            <a href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(alias1,"https://www.oneamerica.com/claims",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" target=\"_blank\">\r\n                                Online Claims\r\n                                <span class=\"text-nowrap\">Resources<i class=\"ace-icon fa fa-external-link oa-banner-blue\"></i></span>\r\n                            </a>\r\n                        </h4>\r\n                        <p>During a loss, we’re here to<br class=\"visible-lg\"> help guide you through the<br class=\"visible-lg\">process</p>\r\n                    </div>\r\n                </div>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                        class=\"col-sm-4\"\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <!-- Education -->\r\n        <h2 id=\"education-header\" class=\"oa-lake\"><i class=\"ace-icon fa fa-graduation-cap green\"></i> Education, News &amp; Marketing</h2>\r\n        <div id=\"education-well\" class=\"well well-callout\">\r\n            <div class=\"row\">\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.showCOELinkRegion : depth0),(depth0 != null ? depth0.showCOELinkRegionDisabled : depth0),{"name":"compare","hash":{"operator":"||"},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showNewsToutRegion : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.showOAMSToutRegion : depth0),(depth0 != null ? depth0.showOAMSToutRegionDisabled : depth0),{"name":"compare","hash":{"operator":"||"},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\r\n        </div>\r\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "                    <div id=\"coe-link-region\" class=\"col-sm-4\"></div>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                    <div id=\"news-link-region\" class=\"col-sm-4\"></div>\r\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                    <div id=\"oams-link-region\" class=\"col-sm-4\"></div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"page-content\">\r\n    <div class=\"page-header\">\r\n         <h1>Welcome to the <i>New</i> OneSource Online</h1>\r\n    </div><!-- /.page-header-->\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    \r\n    <!-- Open Enrollment -->\r\n    <div id=\"open-enrollment-region\">\r\n    </div>\r\n\r\n\r\n    <!-- My Business -->\r\n    <h2 id=\"my-business-header\" class=\"oa-lake hidden\"><i class=\"ace-icon fa fa-clipboard green\"></i> My Business</h2>\r\n    <div id=\"my-business-well\" class=\"well well-callout hidden\">\r\n        <div class=\"row\">\r\n            <div id=\"policies-tout-region\" \r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alignTout : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "            >\r\n            </div>\r\n\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.showDailyCommissionsRegion : depth0),(depth0 != null ? depth0.showDailyCommissionsRegionDisabled : depth0),{"name":"compare","hash":{"operator":"||"},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.showSalesConnectionLinksRegion : depth0),(depth0 != null ? depth0.showSalesConnectionLinksRegionDisabled : depth0),{"name":"compare","hash":{"operator":"||"},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.showFormsRegion : depth0),(depth0 != null ? depth0.showFormsRegionDisabled : depth0),{"name":"compare","hash":{"operator":"||"},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showClaimsTout : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showEducationSection : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12\">\r\n            <div id=\"performance-center-region\">\r\n            </div>\r\n        </div><!-- /.col-xs-12-->\r\n    </div><!-- /.row-->\r\n\r\n\r\n</div><!-- /.page-content -->";
},"usePartial":true,"useData":true});
