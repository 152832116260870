/* global Backbone:false */

var debugModule = require('../../debug/debugModule').init();
var SpinnerView = Backbone.Marionette.ItemView.extend({

    //No template require. but 'template' property mandatory for ItemView
    template  : false,
    className : 'spinner-wrapper',
    errors    : {
        spinnerObjectMissing : 'SpinnerView : option Spinner (spinjs object) missing'
    },
    onBeforeRender : function () {
        if (this.options.position === 'static') {

            // Adjustment class to align spinner properly as per design
            this.$el.addClass('align-static');
        } else if (this.options.position !== 'fixed') {

            // For fixed there is no need to alignmen as it automatically
            // alingn center. for others we need to speicic height to DIV to
            // hold the spinner. otherwise it will float on top tab/region
            this.$el.addClass('align-relative');
        }
    },
    onRender : function () {
        if (this.options.spinner) {

            // Its suppose to add as part of template but for some reason
            // wrapper element skipping off while specifying spinner element as template
            this.$el.append(this.options.spinner);
        } else {
            debugModule.error(this.errors.spinnerObjectMissing);
        }
    }
});

module.exports = SpinnerView;