// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.isActive),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div id=\"loan-information\">\r\n            <h2 class=\"header\">Loan Information</h2>\r\n            <div id=\"loan-date\" >\r\n                <h5>Loan Information as of "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(alias1,((stack1 = (data && data.root)) && stack1.lastRefreshDate),"MMM DD, YYYY",{"name":"dateFormat","hash":{},"data":data}))
    + "</h5>\r\n            </div>\r\n            <div class=\"row\" id=\"loan\">\r\n                <div class=\"col-md-6\" id=\"loan-amounts\">\r\n                    <div class=\"profile-user-info profile-user-info-striped\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.loanBalance : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.maximumAvailableLoan : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.payoffBalance : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.loanInterestTiming : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n                <div class=\"col-md-6\" id=\"loan-rates\">\r\n                    <div class=\"profile-user-info profile-user-info-striped\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.loanInterestRate : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.loanedAmountInterestRate : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.netInterestRate : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"profile-info-row\" id=\"loan-balance\">\r\n                                <div class=\"profile-info-name\">Current Loan Balance </div>\r\n                                <div class=\"profile-info-value\">"
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.loanBalance : depth0),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                            </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"profile-info-row\" id=\"available-loan\">\r\n                                <div class=\"profile-info-name\">Available Loan Value </div>\r\n                                <div class=\"profile-info-value\">"
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.maximumAvailableLoan : depth0),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                            </div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"profile-info-row\" id=\"loan-payoff\">\r\n                                <div class=\"profile-info-name\">Current Loan Payoff </div>\r\n                                <div class=\"profile-info-value\">"
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.payoffBalance : depth0),{"name":"currencyFormat","hash":{},"data":data}))
    + "</div>\r\n                            </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                            <div class=\"profile-info-row\" id=\"loan-interest-method\">\r\n                                <div class=\"profile-info-name\">Loan Interest Calculation Method </div>\r\n                                <div class=\"profile-info-value\">"
    + container.escapeExpression(((helper = (helper = helpers.loanInterestTiming || (depth0 != null ? depth0.loanInterestTiming : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"loanInterestTiming","hash":{},"data":data}) : helper)))
    + "</div>\r\n                            </div>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"profile-info-row\" id=\"loan-interest\">\r\n                                <div class=\"profile-info-name\">Interest Rate Charged on Loan </div>\r\n                                <div class=\"profile-info-value\">"
    + container.escapeExpression((helpers.numberToFixed || (depth0 && depth0.numberToFixed) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.loanInterestRate : depth0),{"name":"numberToFixed","hash":{},"data":data}))
    + "%</div>\r\n                            </div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"profile-info-row\" id=\"interest-rate-credited\">\r\n                                <div class=\"profile-info-name\">Interest Rate Credited on Loan Amount </div>\r\n                                <div class=\"profile-info-value\">"
    + container.escapeExpression((helpers.numberToFixed || (depth0 && depth0.numberToFixed) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.loanedAmountInterestRate : depth0),{"name":"numberToFixed","hash":{},"data":data}))
    + "%</div>\r\n                            </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"profile-info-row\" id=\"net-loan-rate\">\r\n                                <div class=\"profile-info-name\">Net Loan Rate </div>\r\n                                <div class=\"profile-info-value\">"
    + container.escapeExpression((helpers.numberToFixed || (depth0 && depth0.numberToFixed) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.netInterestRate : depth0),{"name":"numberToFixed","hash":{},"data":data}))
    + "%</div>\r\n                            </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<!-- start of Loan Information -->\r\n"
    + ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"ifAvailable","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<!-- end of Loan Information -->";
},"useData":true});
