// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <li>\r\n            <a id=\"delegate_access\" href=\"#\">Switch to Delegate Access</a>\r\n        </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <li>\r\n            <a id=\"maintain_delegate_access\" href=\"#\">\r\n                Maintain Delegate Access\r\n            </a>\r\n        </li>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!-- src/apps/navbar/partials/navbar-dropdown-usermenu.hbs -->\r\n<li class=\"ace-nav-welcome\">\r\n\r\n"
    + ((stack1 = container.invokePartial(partials["navbar-dropdown-usermenu-user"],depth0,{"name":"navbar-dropdown-usermenu-user","hash":{"name":(depth0 != null ? depth0.firstName : depth0),"greeting":(depth0 != null ? depth0.GREETING : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n\r\n    <ul class=\"user-menu dropdown-menu-right dropdown-menu dropdown-light dropdown-caret dropdown-close\">\r\n\r\n        <li class=\"dropdown-header\">\r\n            <i class=\"ace-icon fa fa-cog\"></i> Access Settings\r\n        </li>\r\n        <li>\r\n            <a id=\"security_settings\" href=\"#\">Security Access Settings</a>\r\n        </li>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasProducerDelegateAccess : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasDelegateMaintAccess : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <li class=\"divider\"></li>\r\n        <li>\r\n            <a id='logout_link' href=\""
    + container.escapeExpression(((helper = (helper = helpers.LOGOUT_ROUTE || (depth0 != null ? depth0.LOGOUT_ROUTE : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"LOGOUT_ROUTE","hash":{},"data":data}) : helper)))
    + "\"><i class=\"ace-icon fa fa-power-off\"></i>Logout</a>\r\n        </li>\r\n    </ul>\r\n\r\n</li>";
},"usePartial":true,"useData":true});
