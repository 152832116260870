// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <h2 class=\"oa-lake\" id=\"performance-center-heading\" >\r\n        <i class=\"ace-icon fa fa-signal green\"></i> Performance Center\r\n    </h2>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.refreshDate : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"collapse-shade-style1\">\r\n        <div class=\"collapse-shade-button-wrapper\">\r\n            <a class=\"btn btn-primary\" id=\"performance-center-collapse-btn\" type=\"button\" data-toggle=\"collapse\" href=\"#collapse-performance-center\" aria-expanded=\"false\" aria-controls=\"collapse\">\r\n                <span>Expand</span>\r\n                <i class=\"bigger-125 ace-icon fa fa-caret-down\" data-icon-hide=\"ace-icon fa fa-caret-up\" data-icon-show=\"ace-icon fa fa-caret-down\"></i>\r\n            </a>\r\n        </div>\r\n        <div class=\"collapse collapse-shade\"  id=\"collapse-performance-center\">\r\n        </div>\r\n    </div>\r\n    <!-- / .collapse-shade-button-wrapper -->\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <p id=\"refresh-date\">\r\n            <span class=\"oa-lake\" >Last Refresh Date:</span> "
    + container.escapeExpression((helpers.dateTimeFormat || (depth0 && depth0.dateTimeFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.refreshDate : depth0),{"name":"dateTimeFormat","hash":{},"data":data}))
    + "\r\n        </p>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.root),{"name":"isNotEmpty","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
