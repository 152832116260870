/* global Marionette:false */

var utils = require('../../../utils/utils');

var uiTestTemplate = require('../templates/ui-test-t.hbs');

//dial view
var DialChart = require('../../../modules/dialChart/views/dial-chart-v');

var UiTestView = Marionette.LayoutView.extend({
    template    : uiTestTemplate,
    regions     : {
        dialSingle      : '#dial-single',
        dialDouble      : '#dial-double',
        dialSingleTrial : '#dial-single-trial'
    },
    onBeforeShow: function () {
        
        var dialSingle = new DialChart({
            chartType: 'single',
            outerValue:5,
            classLabelOne: 'chart-text-big',
            classLabelTwo: 'chart-text-label',
            textLabelOne: 60,
            textLabelTwo: 'Life Lives',
            checkMark: false,
            chartOptions: {
                maxValue:200,
                centerLabel: {
                    animate: true,
                    elementSelector: '.chart-text-big',
                    sign: 1,
                    round:true
                }
            }
        });

        this.showChildView('dialSingle',dialSingle);

        var dialDouble = new DialChart({
            chartType: 'double',
            outerValue:-23998,
            innerValue:-24544,
            classLabelOne: 'chart-double-text-one',
            classLabelTwo: 'chart-double-text-two',
            textLabelOne: '$-23,998',
            textLabelTwo: '$-24,544',
            checkMark: false,
            outerChartOptions: {
                maxValue:25000,
                scaleColor: '#ffffff'
            },
            innerChartOptions: {
                maxValue:30000,
                scaleColor: '#ffffff'
            }
        });

        this.showChildView('dialDouble',dialDouble);

        var dialSingleTrial = new DialChart({
            chartType: 'single',
            outerValue:68998,
            classLabelOne: 'chart-text-one',
            classLabelTwo: 'chart-text-two lighter',
            textLabelOne: '$68,998',
            textLabelTwo: 'Qualifying FYPC',
            checkMark: false,
            chartOptions: {
                maxValue:70000,
                scaleColor: '#ffffff',
                centerLabel: {
                    animate: true,
                    elementSelector: '.chart-text-one',
                    sign: 1,
                    formatFunction: utils.formatAsCurrency,
                    round: true
                }
            },
        });

        this.showChildView('dialSingleTrial',dialSingleTrial);

    }
});

module.exports = UiTestView;