/* global Backbone:false, _:false */

// load the global partials.
require('../../../partials');
require('../../../utils/hb-helpers');

var debugModule                     = require('../../../modules/debug/debugModule').init();
var InforceSRViewModel              = require('../viewModels/inforce-sr-list-datatable-vm');
var template                        = require('../templates/inforce-sr-datatable-t.hbs');
var utils                           = require('../../../utils/utils');
var spinnerChannel                  = Backbone.Radio.channel('spinner');
var errorHelper                     = require('../../../utils/error-helper');

var InforceSRView = Backbone.Marionette.ItemView.extend({
    defaultColumns: [
        {
            title       : 'Name',
            data        : 'customerLexicalName',
            name        : 'customerLexicalName',
        },
        {
            title       : 'Policy Number',
            data        : 'policyNumber',
            name        : 'policyNumber',
            createdCell : utils.setDataTablePolicyNumberLink
        },
        {
            title   : 'Service Type',
            data    : 'serviceRequestType' ,
            name    : 'serviceRequestType'
        },
        {
            title       : 'Received On',
            data        : 'receivedDate',
            name        : 'receivedDate',
            createdCell : utils.formatDateCell,
            type        : 'receivedDate'
        },
        {
            title       : 'Status',
            data        : 'status',
            name        : 'status',
            type        : 'status'
        },
        {
            title       : 'Service Promise<br>(Bus. Days)',
            data        : 'slaDays',
            name        : 'slaDays',
            className   : 'dt-body-center',
            createdCell : utils.formatDaysCell            
        },
        {
            title       : 'Completed On',
            data        : 'completedDate.',
            name        : 'completedDate',
            createdCell : utils.formatDateCell,
            type        : 'receivedDate'
        }
    ],

    orgColumns: [
        {
            title       : 'Servicing Agent',
            data        : 'servicingAgentLexicalName',
            name        : 'servicingAgent',
        },
    ],

    datatableColumns: undefined,

    template : template,    

    ui: {
        isrHelpTextIcon: '#inforce-sr-help-text-icon',
        genericModal: '#generic-modal',
        listDatatable: '#inforce-sr-datatable',
        listWrapper: '#inforce-sr-dt-wrapper'
    },

    errors: {
        noInforceServiceRequests          : 'No Inforce Service Requests.'
    },

    events: {
        'click @ui.isrHelpTextIcon': 'showHelpTextModal',
    },

    initialize: function (options) {

        var col;
        var dir;
        var length;
        var start;
        var orgView;

        if (options) {           
            if (options.state) {
                col    = options.state.col;
                dir    = options.state.dir;
                length = options.state.length;
                start  = options.state.start;
            }
            orgView = options.orgView;
        }
    
        this.model = new InforceSRViewModel({
            col               : col,
            dir               : dir,
            length            : length,
            start             : start,
            orgView           : orgView
        });

        if (orgView) {
            this.datatableColumns = this.defaultColumns.concat(this.orgColumns);
        } else {
            this.datatableColumns = this.defaultColumns;
        }

        this.listenTo(this, 'noResults', this._showNoResultsMessage);
    },

    onRender : function () {

        var _this  = this;

        this.showProducerName();

        //default sort is by status
        var ordering = utils.getTableOrdering([[4, 'asc']], this.options.state);

        if (!this.ui.listDatatable.length) {
            return false;
        } 

        //Abort all previous request if there anything active
        //while user continousily swapping tab before rendering datatable
        utils.abortAllDataTableAjaxRequest(this.ui.listDatatable.selector);

        var url = this.model.url();

        var dataTableOptions = utils.getDatatableOptions(this, url);

        _.extend(dataTableOptions, {

            // show info, length control, pagination, table, info, length, pagination
            dom : 'ilptilp',

            language : {  
                info: 'Showing _START_ to _END_ of _TOTAL_ ' +
                    'entries for <strong><span></span></strong>',
                lengthMenu: 'Display _MENU_ records'   
            },

            // 0-based start index
            displayStart : this.model.get('start'),

            bAutoWidth: false,

            // initial sorting
            order : ordering,

            // initial page length
            pageLength : this.model.get('length'),

            columns : this.datatableColumns,

            'initComplete' : function (dataTableSettings, jsonResponse) {

                if (jsonResponse.producer) {

                    _this.producerName = jsonResponse.producer.fullName;

                    Backbone.$(dataTableSettings.nTableWrapper)
                        .find('.dataTables_info span').text(_this.producerName);
                }               
            }
        });

        // In order to prevent "null" from being shown in IE/Edge, an
        // empty string will be set as the defaultContent for each column.
        utils.setDatatablesDefaultContent(dataTableOptions.columns, '');
        
        spinnerChannel.trigger('show',{
            viewScope : this
        });

        let table = this.ui.listDatatable.DataTable(dataTableOptions)
        .on('xhr', function($event, dataTableSettings, jsonResponse, xhr) {

            spinnerChannel.trigger('hide', _this);

            //if request is aborted callback will reach here with statusText 'abort'
            //So do no proceed to do anything, otherwise it display blank Table
            if (xhr && xhr.statusText === 'abort') {
                return false;
            }
            
            // Make sure we're getting the info back that we should expect
            if (!jsonResponse || _.isUndefined(jsonResponse.recordsTotal) || !xhr) {

                _this.trigger('error');
                return;
            }
            
            // Handle any non-200 HTTP responses
            // after the readyState has been set to 4 (DONE)
            if (xhr.readyState === 4 && xhr.status !== 200) {
                _this.trigger('error');
                return;
            }
           
            _this.model.set('producerFullName', jsonResponse.producer.fullName);

            _this.showProducerName();

            // Handle no results being returned

            if (jsonResponse.recordsTotal === 0) {
                _this.trigger('noResults');
                return;
            }

            
            _this.ui.listWrapper.removeClass('hidden');
                     
        }).on('draw', function ($event, dataTableSettings){
            
            //There is no direct API to update info label, so updating it on DOM
            Backbone.$(dataTableSettings.nTableWrapper).find('.dataTables_info span')
            .text(_this.producerName);   
        });

        utils.bindDataTableEvents({
            dtObject            : table, 
            viewScope           : this, 
            viewName            : 'inforce-sr-wrapper-v: ',
            spinnerChannel      : spinnerChannel,
            debugModule         : debugModule,
            addOrderListener    : true
        });

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));

    },

    /**
     * Show help text modal and display WCM content
     * 
     * @param {Object} e jQuery event object
     */
    showHelpTextModal(e) {
        e.preventDefault();

        const wcmHelpPath = '/wps/wcm/connect/indcontent/OSO/misc/awd-isr-help';

        // load the wcm content in the modal-content area
        window.$('#generic-modal').modal('show').find('.modal-content').load(wcmHelpPath);
    },

    showProducerName() {
        Backbone.$('#producerName').html(this.model.get('producerFullName') +
            '&nbsp;<i class="ace-icon fa fa-users grey"></i>'); 
    },

    _showNoResultsMessage : function _showNoResultsMessage () {
        
        this.model.set(
            'alertMessage',
            errorHelper.createAlert('No Inforce Service Requests', 'info')
        );
        this.render();
    },
});

module.exports = InforceSRView;

