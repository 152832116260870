/* global Marionette:false, Backbone:false */

var dbg             = require('../../../modules/debug/debugModule').init();
var cp  = require('../../../modules/checkpoint/checkpointModule');

var uiErrorHandlerTestTemplate = require('../templates/ui-error-handler-test-t.hbs');

var errorChannel  = Backbone.Radio.channel('error');

var UiTestView = Marionette.ItemView.extend({

    template    : uiErrorHandlerTestTemplate,
    model       : new Backbone.Model(),

    ui: {
        inputErrorMsg   : '#errorMessage',
        btnShowError    : '.btn-show-error'
    },

    events: {
        'click @ui.btnShowError': 'showErrorMessage'
    },

    initialize: function (options) {
        var msg = '';

        // If there is no msg in the stateObj, use the default name
        if (options && options.stateObj && typeof options.stateObj.msg === 'string') {
            msg = options.stateObj.msg;
        }

        this.model.set('errorMsg', msg);

        // Show the message immediately after the view renders?
        if (options && options.stateObj) {
            dbg.log(options.stateObj.auto);
        }
        if (options && options.stateObj && options.stateObj.auto) {
            this.showImmediately = options.stateObj.auto;
            dbg.log('Showing error page immediately');
        }
    },

    onRender: function () {
        if (this.showImmediately) {
            this.showErrorMessage();
        }
    },

    showErrorMessage : function () {
        var errorMessage = this.ui.inputErrorMsg.val();
        cp.writeCheckpoint({msg: errorMessage});
        errorChannel.trigger('showErrorPage', errorMessage);
        return false;
    }
});

module.exports = UiTestView;
