/* global Backbone:false, _:false */

/**
 * Model object backing the Policy Search Form. It encapsulates the form's state, including the
 * user input for the search, the search type (either manually selected by the user, or interpreted
 * from the input), error messages, and capability flags.
 *
 * Created by jbell on 2/8/16.
 */

var utils = require('../../../utils/utils');

var PolicySearchFormViewModel = Backbone.Model.extend({

    /*
     * The default values for a new model. None of the properties are required.
     */
    defaults: {

        // search option radio button
        searchOption        : 'policySearch',

        // search term input
        searchTerm          : '',
        searchTermErrorText : '',

        // search type
        searchType          : '',
        searchTypeErrorText : '',

        // capability flags
        hasPolicySearchByProducer  : false

    },

    /*
     * Error messages. Keep from repeating strings in this mod and in test specs!
     */
    errors: {
        minimumInputLength   : 'Name search requires a minimum of one (1) character in the last ' +
                                 'name.',
        nameFormat           : 'Name is not valid. Please enter a name in one of the following ' +
                                 'formats: "Name" or "Last Name, First Name."',
        policyNumberFormat   : 'Policy number is not valid. Please enter 9 or 10 digits.',
        termRequired         : 'Search criteria is blank. Please enter a value.',
        optionRequired       : 'Please select an option.',
        producerNumberFormat : 'Producer number is not valid. Please enter a minimum of five (5) '+
                                 'alphanumeric characters. ',
    },

    errorKeysToFormFieldMap : {
        searchTermErrorText : 'searchTerm',
        searchTypeErrorText : 'searchType'
    },

    initialize: function (options) {
        this._sanitizeSearchTerm();

        this.listenTo(this, 'change:searchTerm', this._sanitizeSearchTerm);
    },

    /**
     * Validate the model's properties. If valid, it returns nothing. If invalid, it will return
     * an object containing a hash of error messages
     *
     * @returns {Object} if the model is invalid, with properties for error messages.
     *          Nothing if it is valid.
     */
    validate: function() {

        var errors       = {};
        var searchOption = this.get('searchOption');
        var searchType   = this.get('searchType');
        var value        = this.get('searchTerm');

        //search type required only for the users, has 'Policy_Search_by_Producer' capability
        var typeRequired   = this.get('hasPolicySearchByProducer');

        // all digits, with optional C at end denotes policy number search
        var isPolicyNumberSearch = /^\d+[Cc]?$/.test(value);

        // 8 or 9 digits plus a C or 9 or 10 digits is VALID policy number search
        var isValidPolicyNumber = (
            isPolicyNumberSearch &&
            (/^\d{8,9}[Cc]$/.test(value) || /^\d{9,10}$/.test(value))
        );

        // If it isn't a policy number search,
        // the presence of a comma indicates an attempt at "last, first" search
        var isLastFirst = (
            !isPolicyNumberSearch &&
            (value.indexOf(',') !== -1)
        );

        // Grab the lastName portion. We may need to check it's length below to set the correct
        // error message.
        var lastName = (isLastFirst) ? value.split(',')[0].trim() : '';

        // a VALID "last, first" search has at least 1 non-whitespace character before the comma
        // only has one comma (because any more than that just doesn't make sense)
        // "last name" just needs 1 non-whitespace character
        // "first name" doesn't even need to be there - force a last name only search.
        // Oddly, something like "..," is a valid search here.
        var isValidLastFirst =
            isLastFirst &&
            value.replace(/[^,]/g, '').length === 1 &&
            /^\S{1,}[\S\s]*,/.test(value);

        // A VALID name search does not have a comma,
        // has at least 1 non-whitespace
        // character and has at least one non-digit
        var isValidName = (
            !isPolicyNumberSearch &&
            !isLastFirst &&
            /^\S{1,}[\S\s]*$/.test(value)
        );

        // A valid producer number
        // Minimum 5 characters
        // Accepted only alphanumeic, no special characters
        var isValidProducerNumber = /^[a-zA-Z0-9]{5,}$/.test(value);

        // Search option is required, but empty
        if (typeRequired && !searchOption) {
            errors.searchTypeErrorText = this.errors.optionRequired;
        }

        if (! this.get('searchTerm')) {
            errors.searchTermErrorText = this.errors.termRequired;

        } else {

            // No explicit search type or using the "policySearch" option value,
            // need to infer based on input
            if ((!typeRequired && !searchType) || searchOption === 'policySearch') {

                // if it fits the policy number format, even if invalid, we infer policy number
                if (isPolicyNumberSearch) {
                    searchType = 'policyNumber';

                } else {
                    searchType = 'clientName';
                }
            }

            if (searchType === 'policyNumber' && !isValidPolicyNumber) {
                // Invalid Policy Number Search
                errors.searchTermErrorText = this.errors.policyNumberFormat;

            } else if ((searchType === 'clientName' || searchType === 'producerName')
                && !isValidName && !isValidLastFirst) {

                // Invalid client or producer name search
                if (value.length < 1 || (isLastFirst && lastName.length < 1)) {
                    errors.searchTermErrorText = this.errors.minimumInputLength;

                } else {
                    errors.searchTermErrorText = this.errors.nameFormat;
                }

            }
            else if (searchType === 'producerNumber' && !isValidProducerNumber) {
                errors.searchTermErrorText = this.errors.producerNumberFormat;
            }
        }

        // set the error messages and search type.
        this.set({
            searchTermErrorText : errors.searchTermErrorText,
            searchTypeErrorText : errors.searchTypeErrorText,
            searchType          : searchType
        });

        if (! _.isEmpty(errors)) {
            utils.sendFormErrorsToAnalytics(errors, this.errorKeysToFormFieldMap, 'Pending Search');
            return errors;
        }
    },


    /**
     * Sanitize the search term - for now, just trim leading and trailing whitespace.
     * This method gets called when something changes the searchTerm attribute via a 'set' call.
     *
     * @private
     */
    _sanitizeSearchTerm: function() {
        var searchTerm = this.get('searchTerm');
        if (searchTerm && _.isString(searchTerm)) {
            // updating directly does not cause the 'change' event to fire again
            this.attributes.searchTerm = searchTerm.trim();
        }
    }
});

module.exports = PolicySearchFormViewModel;
