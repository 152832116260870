/* global require:false, Backbone:false */
/**
 * View for displaying the "viewing as producer" ribon within OSO.
 */

var template    = require('./vap-ribbon-t.hbs');
var userChannel = Backbone.Radio.channel('user');

var ViewAsProducerRibbonView = Backbone.Marionette.ItemView.extend({

    template : template,

    ui : {
        changeProducerLink : '#change-vap-link',
        endSessionLink     : '#end-vap-session-link'
    },

    events : {
        'click @ui.changeProducerLink' : '_changeProducer',
        'click @ui.endSessionLink'     : '_endSession'
    },

    errors : {
        producerRequired : 'Producer is required in the options object'
    },


    /**
     * Lifecycle function. Checks that there is a producer property in the options.
     * @param options
     */
    initialize : function initialize(options) {

        // create the view's model
        if (options && options.producer) {
            this.model = new Backbone.Model({
                label        : options.label,
                endLinkLabel : options.endLinkLabel,
                producer     : options.producer,
                showEnd      : options.showEnd
            });

        } else {
            throw new Error(this.errors.producerRequired);
        }
    },


    /**
     * End the "view as producer" session
     * @param event the jQuery event
     * @private
     */
    _endSession : function _endSession(event) {
        event.preventDefault();
        userChannel.trigger('endViewAsProducer');
    }
});

module.exports = ViewAsProducerRibbonView;