/* global Backbone:false */

/**
 * Model object to back the policy-datatable-info-v object.
 * 
 */

var config               = require('../../../config/config');

var PolicyListDataTableViewModel = Backbone.Model.extend({
    defaults : {
        col             : 0,
        dir             : 'asc',
        length          : 25,
        producerId      : null,
        start           : 0,
        status          : 'pending'
    },

    /**
     * Build url for datatable to fetch data
     *
     * Default value for 'hierarchy' query param will be set as 'producer' 
     * if 'hierarchy' item missing in model.
     * 
     * And if model has 'producerOrgId' item 'hierarchy' query param will set as 'org'
     * 
     * @return {string} url
     */
    urlRoot: function () {
        var hierarchyValue = this.get('hierarchy') ? this.get('hierarchy') : 'producer';
        var producerIdNameValue = '';
        var reportingGroup      = '';

        if (this.get('producerOrgId')) {
            if (hierarchyValue !== 'org') {
                hierarchyValue = 'org';
            }
            producerIdNameValue = '&producerId=' + this.get('producerOrgId');
        } else if (this.get('producerId')) {
            producerIdNameValue = '&producerId=' + this.get('producerId');
        }

        if (this.get('status') !== 'all') {
            reportingGroup = this.get('status');
        }

        return config.apiUrlRoot + 'policies/summaries?' +
            'hierarchy=' + hierarchyValue +
            '&statusView=pending' +
            '&reportingGroup=' + reportingGroup +
            producerIdNameValue;
    },

    /**
     * Life cycle function
     * Ensure that numeric attrs are set as Number. 
     */
    initialize : function initialize (attrs, options) {
        if (attrs) {
            this._setNumber('col', attrs.col);
            this._setNumber('length', attrs.length);
            this._setNumber('start', attrs.start);
        }
    },

    /**
     * Some attributes need to be Number, or odd things tend to happen.
     * @param attr the name of the attribute
     * @param value the value of the attribute
     * @private
     */
    _setNumber : function _setNumber(attr, value) {
        var numberValue = this.defaults[attr];
        if (value && !isNaN(value)) {
            numberValue = Number(value);
        }
        this.set(attr, numberValue);
    }

});

module.exports = PolicyListDataTableViewModel;
