// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "              <i class='fa fa-search' id=\"resource-search-icon\" ></i>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "              <i class=\"fa fa-times\" id=\"resource-cross-icon\" ></i>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "hide";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div id='resource-center-container'>\r\n  <div class='resource-center-wrapper'>\r\n    <div class='resource-center-header'>\r\n      <div class='resource-center-title'>\r\n        <div class='page-header'>\r\n          <h1>Resource Center</h1>\r\n        </div>\r\n      </div>\r\n\r\n      <form class='form-horizontal' role='form' id='resource-search-form'>\r\n        <div class='resource-search-container'>\r\n          <input type='search' placeholder='Search Resource Item' id='resource-search-term-input' name='search' maxlength='63' value=\""
    + alias4(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\" />\r\n          <button type='submit' id='resource-search-button'>\r\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTerm : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "          </button>\r\n        </div>\r\n      </form>\r\n    </div>\r\n    <div id=\"resource-center-search-term-error\"\r\n      class=\"help-block "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTermErrorText : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n      "
    + alias4(((helper = (helper = helpers.searchTermErrorText || (depth0 != null ? depth0.searchTermErrorText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchTermErrorText","hash":{},"data":data}) : helper)))
    + "\r\n    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\r\n</div>";
},"usePartial":true,"useData":true});
