// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<li class=\"active "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.addClass : depth0), depth0))
    + "\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.activeAndOpen : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "        <li class=\"active open "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.addClass : depth0), depth0))
    + "\">\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isDisabled : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "            <li class=\"disabled "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.addClass : depth0), depth0))
    + "\">\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            <li class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.addClass : depth0), depth0))
    + "\">\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"dropdown-toggle\" href=\"\">\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <a data-title=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.displayText : depth0), depth0))
    + "\"\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDisabled : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isExternal : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "        aria-disabled=\"true\"\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disableHandler : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    return " href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.link : depth0),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\"";
},"19":function(container,depth0,helpers,partials,data) {
    return " data-disable-handler=\"true\"";
},"21":function(container,depth0,helpers,partials,data) {
    return " target=\"_blank\" rel=\"noopener noreferrer\"";
},"23":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"menu-icon glyphicons glyphicons-"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></i>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.icon : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"menu-icon "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></i>\r\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "            <i class=\"menu-icon fa fa-caret-right\"></i>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "<i class=\"ace-icon fa fa-external-link\"></i>";
},"32":function(container,depth0,helpers,partials,data) {
    return "    <b class=\"arrow fa fa-angle-down\"></b>\r\n";
},"34":function(container,depth0,helpers,partials,data) {
    return " <span class= 'tempNewIcon-container' style = 'float:right; display:inline-block;'></span>\r\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "    <ul class=\"submenu\"></ul>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subItems : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(helpers.glyphiconCheck || (depth0 && depth0.glyphiconCheck) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"glyphiconCheck","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "    \r\n    <span class=\"menu-text\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.displayText : depth0), depth0))
    + "</span>\r\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isExternal : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subItems : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.tempNewIconWCMLink : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subItems : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</li>\r\n";
},"useData":true});
