/* global require:false, Backbone:false */

var template    = require('../templates/analytics-test-t.hbs');

var AnalyticsTestView = Backbone.Marionette.ItemView.extend({

    template : template
});

module.exports = AnalyticsTestView;
