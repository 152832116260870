/* global Backbone:false*/

/**
 * This class serves as view-model for filter categories on Resource Center page.
 *
 */

 var ResourceCenterFilterModel = Backbone.Model.extend({
 
     /*
      * The default values for a new model. None of the properties are required.
      */
     defaults: {
      
         // filter term input
         filterTerm          : '',
         filterType : '',
     },
 
     initialize: function (options) {
        
     },
   
 });
 
 module.exports = ResourceCenterFilterModel;
 