// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"page-content\">\r\n    <div class=\"page-header\">\r\n        <h1>Select a Producer</h1>\r\n    </div>\r\n\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    \r\n    <div id=\"producerSearchResults\" class=\"hidden\" >\r\n        <table id=\"producerSearchResultsTable\" class=\"table table-striped table-bordered table-hover\" width=\"100%\">\r\n            <thead>\r\n            <tr>\r\n                <th>Name</th>\r\n                <th>Actions</th>\r\n                <th>Producer Number</th>\r\n            </tr>\r\n            </thead>\r\n        </table>\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\r\n</div>";
},"usePartial":true,"useData":true});
