/* global Backbone:false */
/**
 * Simple model to encapsulate pending policy summary information for search by policy number.
 * Intented to be used within the policy-summary-c collection for searches. The model
 * only needs a policyId in order to determine the uniqueness of the results.
 * 
 * Created by jbell on 4/27/16.
 */
const PendingPolicyResultModel = Backbone.Model.extend({
    defaults: {
        policyId: ''
    }
});

module.exports = PendingPolicyResultModel;
