/* global _:false */

/**
 * Provider class for Google Tag Manager.
 * This module loads the vendor script to the page and
 * provides an abstraction for working with the
 * Google Tag Manager data layer.
 *
 * {@link https://developers.google.com/tag-manager/quickstart}
 */
var BaseAnalyticsProviders = require('./baseAnalyticsProvider');
var debugModule            = require('../../debug/debugModule').init();

// Load the vendor script
require('../../../../vendor/google-tag-manager');

var GoogleTagManagerProvider = BaseAnalyticsProviders.extend({

    errors : {
        setDataLayerValueObject     : 'setDataLayerValue() must be passed an object',
        unsetDataLayerValueObject   : 'unsetDataLayerValue() must be passed an object',
        trackExceptionObject        : 'The parameter passed to trackException() must ' +
            'be an object which contains a "message" key with a string value.'
    },

    initialize : function initialize(options) {
        this.dataLayer = window.dataLayer;
    },

    /**
     * Used to push data to the data layer.
     * @param {Object} options
     */
    setDataLayerValue : function setDataLayerValue(options) {
        if (_.isUndefined(options) || !_.isObject(options)) {
            throw new Error(this.errors.setDataLayerValueObject);
        }

        this.dataLayer.push(options);

        debugModule.log('Google Tag Manager dataLayer:', this.dataLayer);
    },

    /**
     * Set undefined to the fields exist in 'dataLayerValues' which already pushed to gtm
     * 
     * @param {Object} dataLayerValues
     */
    unsetDataLayerValue : function unsetDataLayerValue (dataLayerValues) {

        if (_.isUndefined(dataLayerValues) || !_.isObject(dataLayerValues)) {
            throw new Error(this.errors.unsetDataLayerValueObject);
        }

        dataLayerValues = _.mapObject(dataLayerValues, function(data, key) {
            return undefined;
        });

        this.dataLayer.push(dataLayerValues);

        debugModule.log('Google Tag Manager dataLayer:', this.dataLayer);
    },

    /**
     * Method used to send an "exception" event to the data layer.
     * @param {string} options.message
     * @param {boolean} [options.fatal=false]
     */
    trackException : function trackException(options) {
        var isFatal = false;
        if (!_.isObject(options) || !_.has(options, 'message') || !_.isString(options.message)) {
            throw new Error(this.errors.trackExceptionObject);
        }

        if (_.has(options, 'fatal') && _.isBoolean(options.fatal)) {
            isFatal = options.fatal;
        }

        // Call setDataLayerValue with the appropriate values
        this.setDataLayerValue({
            'event'                : 'exception',
            'exceptionDescription' : options.message,
            'exceptionFatal'       : isFatal
        });
    },

    /**
     * Method to send "newViewEvent" to data layer.
     * If gtmTrackViewEvent option is set on the view, options.view will be bind with
     * this event to track page view after loading server data. 
     * Basically this method will be mostly triggered from contentLayout-view
     * @param {Object} options - Object containing the title and pageId of the new view.
     * @param {String} [options.view.gtmTrackViewEvent] - When set, the `newViewEvent` will not be
     * set on the data layer until this even has been fired.
     * @param {Object} [options.view.dataLayerValues] - Object containing data values to send
     * along with the `newViewEventObject`.
     */
    trackView : function trackView(options) {
        var _this = this;
        var newViewEventObject = {
            event : 'newViewEvent'
        };

        // Listen for gtmTrackViewEvent if view has that event bind
        if (options && options.view && options.view.gtmTrackViewEvent &&
            _.isString(options.view.gtmTrackViewEvent) && options.view.dataLayerValues) {
            
            if (options.view) {
                this.listenTo(
                    options.view, 
                    options.view.gtmTrackViewEvent,
                    function () {
                        // Combine both the newViewEventObject with the dataLayerValues and
                        // send everything to the data layer in one call.
                        var eventWithData =
                            _.extend(options.view.dataLayerValues, newViewEventObject);
                        _this.setDataLayerValue(eventWithData);
                        _this.stopListening(options.view);
                    }
                );
            }
           
        } else {
            this.setDataLayerValue(newViewEventObject);
        }
    }
});

module.exports = GoogleTagManagerProvider;