// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "Policy";
},"3":function(container,depth0,helpers,partials,data) {
    return "Policies";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "    <ul class=\"list-horizontal\">\r\n        <li>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.showViewLabel : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\r\n            <a href=\""
    + alias2((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(alias1,"#policy-detail",{"name":"buildHrefURL","hash":{"policyId":((stack1 = blockParams[0][0]) != null ? stack1.policyId : stack1)},"data":data,"blockParams":blockParams}))
    + "\">"
    + alias2(alias3(((stack1 = blockParams[0][0]) != null ? stack1.policyNumber : stack1), depth0))
    + " &ndash; "
    + alias2(alias3(((stack1 = blockParams[0][0]) != null ? stack1.productName : stack1), depth0))
    + "</a>\r\n        </li>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].showModalPremium : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </ul>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                View:\r\n";
},"8":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[1][0]) != null ? stack1.modalPremium : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.escapeExpression;

  return "                <li>\r\n                    <span class=\"oa-lake\">"
    + alias1(container.lambda(((stack1 = blockParams[2][0]) != null ? stack1.label : stack1), depth0))
    + ":</span> "
    + alias1((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[2][0]) != null ? stack1.modalPremium : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "\r\n                </li>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<hr class=\"visible-xs\">\r\n<h4>Related "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.relatedPolicies : depth0)) != null ? stack1.length : stack1),1,{"name":"compare","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "</h4>\r\n\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.relatedPolicies : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"useData":true,"useDepths":true,"useBlockParams":true});
