/* global Marionette, _ */
/**
 A model parser class which parses the data for Policy Ivestment allocation

 */

import config from '../../../../config/config';
import chartutils from '../../../../utils/chart-utils';

var policyInvestmentAllocation = Marionette.Object.extend({

    /**
     * Set whether or not the policy is an indexed annuity 
     * (identified by product.productTypeCode === 'INXAN').
     * @param response
     * @private
     */
    _setIndexedAnnuityState: function (response) {
        if (response.product
            && response.product.productTypeCode
            && response.product.productTypeCode.trim().toUpperCase() === 'INXAN') {

            response.isIndexedAnnuity = true;
        }

        return response;
    },
    setAssetCollection: function (response) {
        if (response.investment && response.investment.investmentAllocation) {
            response.investment.investmentAllocation = 
                _.sortBy(response.investment.investmentAllocation, 'assetClassId');
            const assetCollection =
                _.compact(_.map(_.map(response.investment.investmentAllocation, _.clone),
                    (investmentAll) => {
                        if (investmentAll.allocationPercent > 0) {
                            return investmentAll;
                        }
                    }));
            response.investment.assetCollection =
                this.groupByAssetCollection(assetCollection);

            const currentElectionCollection =
                _.compact(_.map(response.investment.investmentAllocation, (investmentAll) => {
                    if (investmentAll.currentElectedPercent > 0) {
                        return investmentAll;
                    }
                }));
            response.investment.currentElectionCollection =
                this.groupByAssetCollection(currentElectionCollection);

            response.investment.totalElectedPercent = _.reduce(
                currentElectionCollection, function (memo, collData) {
                    return (parseFloat(
                        collData.currentElectedPercent) + memo);
                }, 0).toFixed(2);
        }

        return response;
    },
    groupByAssetCollection: function (investmentAllocation) {
        const assetCollection = {};
        _.each(_.groupBy(investmentAllocation, 'assetClass')
            , function (obj, key) {
                _.each(
                    obj, function (collData) {
                        collData.allocationAmount = parseFloat(
                            collData.allocationAmount.split(' ')[1]).toFixed(2);
                        collData.allocationPercent = parseFloat(
                            collData.allocationPercent).toFixed(2);
                        collData.unitValue = parseFloat(
                            collData.unitValue).toFixed(6);
                        collData.currentNumberUnits = parseFloat(
                            collData.currentNumberUnits).toFixed(4);
                        collData.productUrl =
                            encodeURI(collData.productFullName + '');
                        collData.showLink = 
                            (collData.fundType || '').toLowerCase() !== 'fixed';
                    });

                assetCollection[key] = {
                    coll: obj
                    , totalValue: _.reduce(
                        obj, function (memo, collData) {
                            return (parseFloat(
                                collData.allocationAmount) + memo);
                        }, 0).toFixed(2)
                    , totalPercent: _.reduce(
                        obj, function (memo, collData) {
                            return (parseFloat(
                                collData.allocationPercent) + memo);
                        }, 0).toFixed(2)
                    , totalElectedPercent: _.reduce(
                        obj, function (memo, collData) {
                            return (parseFloat(
                                collData.currentElectedPercent) + memo);
                        }, 0).toFixed(2)
                };
            });
        //Set color code
        var i = 0;
        for (var key in assetCollection) {
            if (!assetCollection[key]) {
                return;
            }
            assetCollection[key].color = chartutils.colorsArray[i];
            i++;
        }
        return assetCollection;
    },
    shouldShowInvestmentsForActive: function (response) {
        //Investment Allocation section for Active should display
        //when a policy has an active status or is in the active status view

        let shouldShowInvestmentsForActive = false;
        if (config.fflag.displayInvestmentSection) {

            if (response.isActiveStatusView || response.isActive) {
                //only display investment allocations for variable products
                if (response.isVariableProduct) {
                    shouldShowInvestmentsForActive = true;
                }
            }
        }

        return shouldShowInvestmentsForActive;
    },
    shouldShowInvestmentsForPending: function (response) {
        //Investment Allocation section for Pending should only display
        //when policy is in not in active status view 

        let shouldShowInvestmentsForPending = false;
        if (!response.isActiveStatusView && !response.isActive) {
            shouldShowInvestmentsForPending = true;
        }
        return shouldShowInvestmentsForPending;
    }
});

module.exports = policyInvestmentAllocation;