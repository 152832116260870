/* global Backbone:false, Marionette:false, _:false */
/**
 * A module used to display the alert banner at the top of every OSO view. This module uses
 * the wcm-content/models/wcm-content-m.js to retrieve content from WCM.
 *
 * Usage:
 *
 * var banner = new SiteWideBanner({
 *    region : regionRef  // Required reference to a region to hold the banner
 * });
 */

var SiteWideBannerView = require('./views/siteWideBanner-v');

var userChannel = Backbone.Radio.channel('user');

var SiteWideBanner = Marionette.Object.extend({

    errors : {
        options      : 'SiteWideBanner requires options with a region!',
        parentRegion : 'SiteWideBanner requires a region in which to render!'
    },

    wcmPathByCapability : {
        homeOffice : 'banners/site-alert-home-office',
        producer   : 'banners/site-alert-all'
    },

    initialize : function initialize(options) {
        var hasHomeOfficeCapability = userChannel.request('hasCapability', 'Home_Office');

        if (_.isUndefined(options) || !_.isObject(options)) {
            throw new Error(this.errors.options);
        }

        if (!options.region) {
            throw new Error(this.errors.parentRegion);
        }

        if (hasHomeOfficeCapability) {
            this.wcmPath = this.wcmPathByCapability.homeOffice;
        } else {
            this.wcmPath = this.wcmPathByCapability.producer;
        }

        this.listenTo(this,'collapseBanner', this._collapseBanner);

        this.view = new SiteWideBannerView({
            wcmPath         : this.wcmPath,
            collapseBanner  : this.options.collapseBanner
        });

        options.region.show(this.view);
    },

    /**
     * Intermediate method to invoke view.collapseBanner 
     * @param  {boolean} flag
     * @private
     */
    _collapseBanner : function _collapseBanner (flag) {
        this.view.collapseBanner(flag);
    }
});

module.exports = SiteWideBanner;
