/* global _:false, $:false, Backbone: false,  Marionette:false */

var contentLayoutTemplate           = require('./contentLayout-t.hbs');

var analyticsChannel                = Backbone.Radio.channel('analytics');
var browserFeatureDetectionChannel  = Backbone.Radio.channel('browserFeature');
var errorHelper                     = require('../../utils/error-helper');
var SiteWideBannerModule            = require('../siteWideBanner/siteWideBannerModule');

import '../../utils/hb-helpers';
require('../../partials');
const focusNavBarChannel   = Backbone.Radio.channel('focusNavBar');

var ContentLayout = Marionette.LayoutView.extend({

    behaviors : {
        smartScrollTables : {}
    },

    childPageView: null,

    template: contentLayoutTemplate,

    regions: {
        banner  : '#siteWideBannerView',
        content : '#contentView',
        ribbon  : '#ribbonView'
    },

    ui: {
        ribbonDiv : '#ribbonView',
        maincontentinnerdiv :'#main-content-inner'
    },
    events: {
        'click a.oa-js-nav': '_handleNavAnchorClicks'
    },

    errors : {
        pageLookup   : 'ContentLayout constructor requires a pageLookup object',
        viewRequired : 'showRibbon requires a view to show'
    },

    initialize: function (options) {
        if (!options || !options.pageLookup || !_.isObject(options.pageLookup)) {
            throw new Error(this.errors.pageLookup);
        }

        this.pageLookup = options.pageLookup;

        //Sample usage of browser Feature Detection
        if (!browserFeatureDetectionChannel.request('detect','filereader') 
                || !browserFeatureDetectionChannel.request('detect','formdata')) {
            
            if (!this.model) {
                this.model = new Backbone.Model();
            }

            this.model.set(
                'alertMessage',
                errorHelper.createAlert('You are using an outdated browser. Please upgrade to ' +
                    'the latest version for the best OneSource Online experience.', 'warning')
            );
        }


        focusNavBarChannel.trigger('focusNavBar');

        this.focusChannel = Backbone.Radio.channel('focus');

        // Reset channel if it already have any events binded
        // In unit test we initialize module mutliple times event bind getting multiplied
        this.focusChannel.reset();

        /*
         * Register focus channel callback for onfocus.
         */
        this.listenTo(this.focusChannel, 'focus', this.onfocus);
    },

    onfocus:function(){
        this.ui.maincontentinnerdiv.focus();
    },

    /**
    * @param {string} pageId - Id of the page to display. Comes from the 'link' property of
    *                 the appStructure.
    * @param {object} [stateObj] - Optional object that a page can use to initialize itself
    * @returns {Marionette.View} a reference to the child view that was shown
    */
    showPage: function (pageId, stateObj) {

        if (!pageId || typeof pageId !== 'string') {
            throw new Error('ContentLayout.showPage requires a pageId (string) parameter');
        }

        var newChildView = this._instantiateChildViewForId(pageId, stateObj);

        // Listen for 'nav' events from the child views. These events are not triggered from link
        // clicks, but instead from logic within the application.
        this.listenTo(newChildView, 'nav', function(hash) {
            this.trigger('nav', hash);
        });

        //check whether view destroyed
        if (!newChildView.isDestroyed) {
            this.showChildView('content', newChildView);
        }

        // Collapse alert banner for other pages except Home or delegate-producer-list.
        // 'delegate-producer-list' page is Home for the Producer 
        // who have multiple delegate access.
        // 
        // As per current design, Alert banner will be 
        // rendered only once as it placed in contentLayout template, 
        // so we need to page Id collapse banner for each page change.

        // REMOVING FUNCTIONALITY PER OOSO-4370
        /*if (this.bannerModule) {
            if (this._trimHash(pageId) !== 'home' 
                    && this._trimHash(pageId) !== 'delegate-producer-list') {
                this.bannerModule.trigger('collapseBanner', true);
            } else {
                this.bannerModule.trigger('collapseBanner', false);
            }
        }*/
        this.showBanner();
        
        // call analytics w/ fragment and set 'title' value with displayText object.
        var title = this.pageLookup[this._trimHash(pageId)].displayText;
        analyticsChannel.trigger('trackView', {
            uri   : pageId,
            title : title,
            view  : newChildView
        });
        focusNavBarChannel.trigger('focusNavBar');
        return newChildView;
    },

    /**
     * Used to display a site-wide banner with content coming from WCM.
     */
    showBanner : function showBanner () {
        var bannerRegion = this.getRegion('banner');

        // Clean up any previously displayed view and module
        bannerRegion.empty();
        if (this.bannerModule && _.isFunction(this.bannerModule.destroy)) {
            this.bannerModule.destroy();
        }

        // To collapse alert banner for the page other than Home or delegate-producer-list
        // It will be passed through SiteWideBannerModule to instantiate siteWideBannerView
        
        // REMOVING FUNCTIONALITY PER OOSO-4370
        /*var collapseBanner = (
            Backbone.history.fragment
            && Backbone.history.fragment !== '' 
            && !Backbone.history.fragment.match(/^(home|delegate-producer-list)/)
        );*/

        if (Backbone.history.fragment!==undefined && (Backbone.history.fragment === '' 
            || Backbone.history.fragment.match(/^(home|delegate-producer-list)/))) {

            this.bannerModule = new SiteWideBannerModule({
                region          : bannerRegion,
                collapseBanner  : false
            });
        }
    },

    /**
     * Show the specified view in the 'banner' region
     * @param view the view to show
     */
    showRibbon : function showRibbon(view) {
        if (view) {
            this.showChildView('ribbon', view);
            this.ui.ribbonDiv.removeClass('hidden');
        } else {
            throw new Error(this.errors.viewRequired);
        }
    },


    /**
     * Remove the view in the banner region, and hide the div so the UI does not show an empty
     * element.
     */
    removeRibbon : function removeRibbon() {
        this.ui.ribbonDiv.addClass('hidden');
        var ribbonView = this.getChildView('ribbon');
        if (ribbonView) {
            ribbonView.destroy();
        }
    },


    /**
    * @param {string} pageId - Id of the page to display. Comes from the 'link' property of
    *                 the appStructure.
    * @param {object} [stateObj] - Optional object that a page can use to initialize itself
    * @returns {Marionette.View} a reference to the new child view
    */
    _instantiateChildViewForId: function (pageId, stateObj) {
        var originalPageId = pageId;

        // Trim off the leading # character if it is present on pageId
        pageId = this._trimHash(pageId);

        if (!this.pageLookup[pageId]) {
            throw new Error('ContentLayout._instantiateChildViewForId received ' +
                'an unknown pageId "' + originalPageId + '"');
        }

        var PageView = this.pageLookup[pageId].view;
        this.childPageView = new PageView({
            stateObj:  stateObj
        });

        this.childPageView.pageId = pageId;

        return this.childPageView;
    },

    

    /**
    * Handle <a class="oa-js-nav"> clicks within child views
    */
    _handleNavAnchorClicks: function (evt) {
        var $clickTarget = $(evt.currentTarget);
        var href = $clickTarget.attr('href');
        evt.preventDefault();
        this.trigger('nav', href);
    },

    /**
    * Remove hash from pageId if exist
    */
    _trimHash: function (pageId) {
        if (pageId.charAt(0) === '#') {
            pageId = pageId.slice(1);
        }
        return pageId;
    }
});

module.exports = ContentLayout;
