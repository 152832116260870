/*global
    Backbone, document, window, Highcharts
*/
var config = require('config/config');
var CONST = require('../config/constants');
var utils = require('./utils');

var chartutils = {

    /**
     * Creates/formats data for creating account detail charts and
     * accordions (tables)
     *
     * This method is designed to replace the getClasses method
     * on the views/account-detail-v.js view
     *
     * @param {Object} configObj Configuration options
     * {
     *     assetClasses: {array} Array of asset classes
     *     assetsByClass: {array} Array of assets by class
     *     mappedColors: {boolean} Should it map colors to assets
     *     total: {number} Total value
     * }
     * @returns {Array} results Data to pass to the detail view
     * @throws {Error} error If options are not passed as needed
     */
    getSummaryClasses: function (configObj) {

        // sanity check
        var msg;

        if (
            typeof configObj !== 'object' ||
            typeof configObj.assetClasses === 'undefined' ||
            typeof configObj.assetsByClass === 'undefined' ||
            typeof configObj.total === 'undefined'
        ) {
            msg = [
                'ConfigObj passed to',
                'chartutils.getSummaryClasses',
                'must be an object with these',
                'properties:',
                '1. assetClasses',
                '2. assetsByClass',
                '3. mappedColors (optional)',
                '4. total'
            ].join(' ');
            throw new Error(msg);
        }

        // Mapping colors defaults to true which means that
        // we are mapping colors against assets.
        // Pass false to cycle through all the colors in order
        // instead of using these mappings.
        if (typeof configObj.mappedColors === 'undefined') {
            configObj.mappedColors = true;
        }
        if (typeof configObj.mappedColors !== 'boolean') {
            msg = 'configObj.mappedColors must be a boolean';
            throw new Error(msg);
        }

        // begin real function

        // the color for each entry
        var color;
        // object that will be used to build the accordion table
        var contextClass;
        // loop placeholder for data
        var currentAsset;
        // loop placeholder for data
        var currentClass;
        // rows alternate dark/light
        var dark = true;
        // notes to render at the bottom of the page
        var notes = [];
        // return value
        var results = [];
        // string, built if the right data is passed
        var shortName;
        // needed if we don't map
        var unmappedColors = assetColorsArraySubset();

        // loop variables
        // i and j are loop counters
        var i, j;
        // k is counter for color values
        // and doesn't have a loop initializer
        var k = 0;
        // length calculations
        var classesLength, currentAssetLength;
        classesLength = configObj.assetClasses.length;
        // loop through all the classes
        for (i = 0; i < classesLength; i++) {

            // and start dealing with each grouping of assets
            currentClass = configObj.assetClasses[i];
            currentAsset = configObj.assetsByClass[currentClass];

            if (currentAsset.length > 0) {

                // get the correct color for this asset family
                if (configObj.mappedColors === true) {
                    color = configObj.assetColors[currentClass];
                    if (!color) {
                        msg = [
                            'There is no color configured for',
                            'asset class "',
                            currentClass,
                            '".'
                        ].join(' ');
                        this.console.warn(msg);
                        color = config.unused_asset_colors[k++];
                    } // !color
                } else {
                    // if we don't map colors
                    // just cycle through all of them in turn
                    // and repeat if there are too many
                    if (k === unmappedColors.length) {
                        k = 0;
                    }
                    color = unmappedColors[k];
                    k++;
                } // configObj.mappedColor

                // create data for the higher level row in
                // the table that will expand to show
                // per-investment details
                contextClass = {
                    // color shared between table and chart
                    color: color,
                    // alternate rows
                    dark: dark,
                    // what family of assets
                    name: currentClass,
                    // options are the actual investments
                    // that group under this parent row
                    options: [],
                    // percentage of total this family is
                    percent: 0,
                    // will build a total in this
                    value: 0
                };

                // flip the row
                dark = !dark;

                // Loop thorugh the entries for this
                // asset family
                currentAssetLength = currentAsset.length;
                for (j = 0; j < currentAssetLength; j++) {

                    // add this entry to the total
                    contextClass.value =
                        utils.number.addMoney(contextClass.value, currentAsset[j].value);

                    // need a note about this one?
                    if (currentAsset[j].frozen) {
                        notes.push('investment.frozen');
                    }

                    // need a note about this one?
                    if (currentAsset[j].retired) {
                        notes.push('investment.retired');
                    }

                    // if given, show the abbreviation
                    shortName = currentAsset[j].investmentNameShort ?
                        '(' + currentAsset[j].investmentNameShort + ')' :
                        '';

                    // add the entry for this asset
                    contextClass.options.push({
                        name: currentAsset[j].investmentName + shortName,
                        fundPdf: currentAsset[j].investmentSheetURL,
                        disclosurePdf: currentAsset[j].investmentDisclosureURL,
                        notes: notes,
                        percent:
                            utils.number.toPercent(currentAsset[j].value / configObj.total, 2),
                        type: currentAsset[j].investmentStyle,
                        units: currentAsset[j].units,
                        unitValues: currentAsset[j].unitValues,
                        value: utils.number.toMoney(currentAsset[j].value)
                    });
                }

                // calculate the percentage of this asset family
                contextClass.percent =
                    utils.number.toPercent(contextClass.value / configObj.total, 2);

                // format the total
                contextClass.valueFormatted = utils.number.toMoney(contextClass.value);

                // store for returning
                results.push(contextClass);
            }
        }
        return results;
    },

    /**
     * Generate a gradent object (SVG-formatted) based on a solid color
     *
     * @param   {string|Array}  colors  a string specifying a color value (or an array of them)
     *
     * @returns {object} - Object that can be sent to Highcharts to set the
     *  color gradient on a slice of a pie chart.
     */
    radializeColors: function (colors) {
        var out, single;
        if (colors) {
            if (typeof colors === 'string') {
                colors = [colors];
                single = true;
            }
            try {
                out = Highcharts.map(colors, function (color) {
                    return {
                        radialGradient: {
                            cx: 0.5,
                            cy: 0.3,
                            r: 0.7
                        },
                        stops: [
                            [0, color],
                            [1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
                        ]
                    };
                });
            } catch (e) {
                // Failure in radializeColors
                out = colors;
            }
            out = single ? out[0] : out;
        } else {
            out = colors;
        }
        return out;
    },

    /**
     * Create a config object for pie charts,
     *
     * @param {Array} chartData - The array of data points to be charted,
     *  structured how Highcharts expects
     * @returns {Object} Config object that can be passed into Highcharts.
     * @example
     * $('#myChartDiv').highcharts( config.pieChartConfig( dataSet );
     */
    pieChartConfig: function (chartData) {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: false
            },
            xAxis: {
                categories: null
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            tooltip: {
                hideDelay: 500,
                formatter: function () {
                    return this.point.name + '<br /><b>' + this.y + '</b>';
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: { enabled: false },
                    point: {
                        events: {
                            legendItemClick: function (evt) {
                                // Prevent clicks on items in the legend from causing the
                                // corresponding slice of the chart to be hidden
                                evt.preventDefault();
                            }
                        }
                    },
                    showInLegend: true,
                    size: '100%'
                }
            },
            series: [
                {
                    type: 'pie',
                    data: chartData
                }
            ]
        };
    },

    // key/color lookup object that is built by getColorByKey()
    colorsByKey: {},
    // array of colors strings intended for use by asset class tables and charts
    colorsArray: assetColorsArraySubset(),
    // index placeholder into the colors array
    currColorIndex: 0,

    /**
    * Get a color that is associated with a key
    *
    * This function can be used for any future charts, but existing RS tables and
    * charts use a specific mapping of asset classes to colors. RS should not use
    * this function unless ALL RS tables and charts are refactored.
    *
    * @param {string} key   - a string that will always return the same color string
    *                         (in this session)
    * @returns {string}     - six-digit HTML color string
    */
    getColorByKey: function (key) {
        var colorString = '#000000';

        if (typeof key === 'string') {

            // Do we already have a color for this key?
            if (key in this.colorsByKey) {
                colorString = this.colorsByKey[key];
            } else {
                // Get a new color from the array, wrapping around if we have reached the
                // end of the array
                colorString = this.colorsArray[this.currColorIndex % this.colorsArray.length];
                this.currColorIndex++;

                // Save this key/color for future lookup
                this.colorsByKey[key] = colorString;
            }
        }

        return colorString;
    },// same array as above, but w/o the LITE colors
    // ex
    // BANNER_BLUE: "#023D69",
    // BANNER_BLUE_LITE: "#819eb4" - will be removed

    assetColorsArray: function () {
        var colors, item, list;
        colors = CONST.ASSETCLASSES.COLORS;
        list = [];
        for (item in colors) {
            if (colors.hasOwnProperty(item)) {
                list.push(colors[item]);
            }
        }
        return list;
    }
};

function assetColorsArraySubset() {
    var colors, item, list;
    colors = CONST.ASSETCLASSES.COLORS;
    list = [];
    for (item in colors) {
        if (
            colors.hasOwnProperty(item) &&
            /_LITE/.test(item) === false
        ) {
            list.push(colors[item]);
        }
    }
    return list;
}

Backbone.$.extend(true, chartutils, Highcharts, config, utils);

module.exports = chartutils;

