
module.exports = {
    ASSETCLASSES: {
        COLORS: {
            BANNER_BLUE: '#023D69',
            BANNER_BLUE_LITE: '#819eb4',
            BANNER_RED: '#AF1732',
            BANNER_RED_LITE: '#d88b99',
            BARK: '#724E20',
            BARK_LITE: '#b9a790',
            CACTUS: '#B8D095',
            CACTUS_LITE: '#dce8cb',
            CANVAS: '#EADBB6',
            CANVAS_LITE: '#f5eddb',
            FOREST: '#006347',
            FOREST_LITE: '#80b1a3',
            GARNET: '#A14A3E',
            GARNET_LITE: '#d0a59f',
            GRASS: '#557A28',
            GRASS_LITE: '#aabd94',
            LAKE: '#5A91C4',
            LAKE_LITE: '#acc8e2',
            PUMPKIN: '#E58127',
            PUMPKIN_LITE: '#f2c193',
            SKY: '#ADD8F4',
            SKY_LITE: '#d6ecf9',
            SLATE: '#59798F',
            SLATE_LITE: '#acbcc7',
            STONE: '#B7B28C',
            STONE_LITE: '#dbd9c6',
            SUN: '#F4B531',
            SUNSET: '#DD5126',
            SUNSET_LITE: '#eea893',
            SUN_LITE: '#fadb98',
            WHEAT: '#FDE285',
            WHEAT_LITE: '#fef1c2'
        },

    }
};
