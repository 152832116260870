// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <h2>Billing / Payment</h2>\r\n\r\n"
    + ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.billingDetail : depth0),{"name":"ifAvailable","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.paymentAmount : stack1),(depth0 != null ? depth0.relatedPolicies : depth0),{"name":"compare","hash":{"operator":"||"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <div class=\"well\" id=\"policy-billing-premiums\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.paymentAmount : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.relatedPolicies : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>  \r\n            <!-- /.well -->\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <div class=\"row\">\r\n                        <div class=\"col-sm-12\">\r\n                            <h3>\r\n                                "
    + alias1(container.lambda(((stack1 = (data && data.root)) && stack1.billingHeaderLeadingText), depth0))
    + " Premium Total: <span class=\"oa-banner-blue\">\r\n                                    "
    + alias1((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(alias2,((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.paymentAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + ((stack1 = helpers["if"].call(alias2,((stack1 = (data && data.root)) && stack1.showDisclaimerNote),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                                </span>\r\n                            </h3>\r\n                        </div>\r\n                        <!-- /.col-sm-12-->\r\n                    </div>\r\n                    <!-- /.row -->\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "*";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["related-policies"],depth0,{"name":"related-policies","hash":{"showModalPremium":true},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row\" id=\"policy-billing-info\">\r\n"
    + ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.billingDetail : depth0),{"name":"ifAvailable","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(50, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.showBillingModeAndMethod : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.showCashIncomePlan : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <div class=\"col-md-6\" id=\"billing-mode-method\"> \r\n                <div class=\"profile-user-info profile-user-info-striped\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.paymentMode : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.paymentMethod : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.showPlanningPDR : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.isActive),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"profile-info-row\" id=\"billing-mode\">\r\n                            <div class=\"profile-info-name\"> Billing Mode </div>\r\n                            <div class=\"profile-info-value\"> \r\n                                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.paymentMode : stack1), depth0))
    + " \r\n                            </div>\r\n                        </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"profile-info-row\" id=\"billing-method\">\r\n                            <div class=\"profile-info-name\"> Billing Method </div>\r\n                            <div class=\"profile-info-value\"> \r\n                                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.paymentMethod : stack1), depth0))
    + "\r\n                            </div>\r\n                        </div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"profile-info-row\" id=\"planned-PDR-premium\">\r\n                            <div class=\"profile-info-name\"> Planned PDR Premium </div>\r\n                            <div class=\"profile-info-value\">\r\n                                "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.unscheduledPremiumAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                            </div>\r\n                        </div>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.banking : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.paidToDate : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.puaRiderPaymentAmount : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.banking : stack1)) != null ? stack1.paymentDraftDay : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <div class=\"profile-info-row\" id=\"bank-draft-day\">\r\n                                    <div class=\"profile-info-name\"> Bank Draft Day </div>\r\n                                    <div class=\"profile-info-value\"> \r\n                                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.banking : stack1)) != null ? stack1.paymentDraftDay : stack1), depth0))
    + " \r\n                                    </div>\r\n                                </div>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"profile-info-row\" id=\"paid-to-date\">\r\n                                <div class=\"profile-info-name\"> Paid to Date </div>\r\n                                <div class=\"profile-info-value\"> \r\n                                    "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.paidToDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + " \r\n                                </div>\r\n                            </div>\r\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"profile-info-row\" id=\"pua-rider-payment-amount\">\r\n                                <div class=\"profile-info-name\"> PUA Rider Premium </div>\r\n                                <div class=\"profile-info-value\"> \r\n                                    "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.currentBilling : stack1)) != null ? stack1.puaRiderPaymentAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + " \r\n                                </div>\r\n                            </div>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <div class=\"col-md-6\" id=\"cash-income-plan\">\r\n                <div class=\"profile-user-info profile-user-info-striped\">\r\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (data && data.root)) && stack1.isActiveStatusView),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.payout : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (data && data.root)) && stack1.isActiveStatusView),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.premiumSchedule : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.riderPremiumSchedule : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    \r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.isActive),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.isActiveStatusView),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.cashWithApplication : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " \r\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"profile-info-row\" id=\"cash-with-application\">\r\n                                <div class=\"profile-info-name\"> Premium Received </div>\r\n                                <div class=\"profile-info-value\"> \r\n                                    "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.cashWithApplication : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + " \r\n                                </div>\r\n                            </div>\r\n                        ";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.payout : depth0)) != null ? stack1.incomeOption : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.payout : depth0)) != null ? stack1.payoutStartDate : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"profile-info-row\" id=\"income-settlement-option\">\r\n                                <div class=\"profile-info-name\"> Settlement / Income Option </div>\r\n                                <div class=\"profile-info-value\"> "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.payout : depth0)) != null ? stack1.incomeOption : stack1), depth0))
    + " </div>\r\n                            </div>\r\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"profile-info-row\" id=\"income-date\">\r\n                                <div class=\"profile-info-name\"> Income Date </div>\r\n                                <div class=\"profile-info-value\"> "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.payout : depth0)) != null ? stack1.payoutStartDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + " </div>\r\n                            </div>\r\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.consolidatedPlan),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"profile-info-row\" id=\"consolidated-plan\">\r\n                                <div class=\"profile-info-name\"> Consolidated Plan </div>\r\n                                <div class=\"profile-info-value\">Yes</div>\r\n                            </div>\r\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"profile-info-row\" id=\"premium-pay-period\">\r\n                            <div class=\"profile-info-name\"> Pay Period </div>\r\n                            <div class=\"profile-info-value\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.premiumSchedule : stack1), depth0))
    + "</div>\r\n                        </div>\r\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"profile-info-row\" id=\"premium-rider-pay-period\">\r\n                            <div class=\"profile-info-name\"> Rider Pay Period </div>\r\n                            <div class=\"profile-info-value\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.riderPremiumSchedule : stack1), depth0))
    + "</div>\r\n                        </div>\r\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.billedToDate : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.lastPremiumDate : stack1),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"profile-info-row\" id=\"billed-to-date\">\r\n                                <div class=\"profile-info-name\"> Billed to Date </div>\r\n                                <div class=\"profile-info-value\"> \r\n                                    "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.billedToDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n                                </div>\r\n                            </div>\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"profile-info-row\" id=\"last-premium-date\">\r\n                                <div class=\"profile-info-name\"> Last Premium Received </div>\r\n                                <div class=\"profile-info-value\"> \r\n                                    "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.lastPremiumDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n                                </div>\r\n                            </div>\r\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.totalDepositsToDate : stack1),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"profile-info-row\" id=\"total-deposits-to-date\">\r\n                                <div class=\"profile-info-name\"> Total Policy Contributions </div>\r\n                                <div class=\"profile-info-value\"> \r\n                                    "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.billingDetail : depth0)) != null ? stack1.totalDepositsToDate : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                                </div>\r\n                            </div>\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "        <div id=\"billing-section-unavailable\" class=\"col-lg-5 bigger-115\">\r\n            <strong>Section unavailable</strong>\r\n        </div>\r\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.payout : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"53":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <h2>Billing / Payment</h2>\r\n\r\n        <div class=\"row\" id=\"policy-billing-info2\">\r\n"
    + ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.payout : depth0),{"name":"ifAvailable","hash":{},"fn":container.program(54, data, 0),"inverse":container.program(59, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\r\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <div class=\"col-md-6\">\r\n                <div class=\"profile-user-info profile-user-info-striped\">        \r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.payout : depth0)) != null ? stack1.incomeOption : stack1),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.payout : depth0)) != null ? stack1.payoutStartDate : stack1),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"profile-info-row\" id=\"income-settlement-option2\">\r\n                            <div class=\"profile-info-name\"> Settlement / Income Option </div>\r\n                            <div class=\"profile-info-value\"> "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.payout : depth0)) != null ? stack1.incomeOption : stack1), depth0))
    + " </div>\r\n                        </div>\r\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"profile-info-row\" id=\"income-date2\">\r\n                            <div class=\"profile-info-name\"> Income Date </div>\r\n                            <div class=\"profile-info-value\"> "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.payout : depth0)) != null ? stack1.payoutStartDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + " </div>\r\n                        </div>\r\n";
},"59":function(container,depth0,helpers,partials,data) {
    return "            <div id=\"billing-section-unavailable2\" class=\"col-lg-5 bigger-115\">\r\n                <strong>Section unavailable</strong>\r\n            </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.billingDetail : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.billingDetail : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(52, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
