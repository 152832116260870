// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "            <li id=\"inforce-sr-org-tab\">\r\n                <a data-toggle=\"tab\" data-tabname=\"inforce-service-request\" data-target=\"#inforce-sr-org-tab-pane\" href=\""
    + container.escapeExpression((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"#policy-manager/inforce-service-request",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" aria-expanded=\"false\" class=\"tab-inforce-sr oa-js-tab\">\r\n                    <span class=\"hidden-xs\">Inforce Service Requests</span>\r\n                    <span class=\"visible-xs\">ISR</span>\r\n                </a>\r\n            </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div id=\"inforce-sr-org-tab-pane\" class=\"tab-pane\">\r\n                <div id=\"inforce-sr-org-region\"></div>\r\n            </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"page-content\">\r\n    <div class=\"page-header\">\r\n        <h1>Organization View</h1>\r\n    </div>\r\n\r\n    <div class=\"tabbable\">\r\n        <ul class=\"nav nav-tabs nav-tabs-highlight\" id=\"orgTabs\">\r\n            <li id=\"org-tab\">\r\n                <a data-toggle=\"tab\" data-tabname=\"org\" href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,"#policy-manager/org",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" data-target=\"#my-organization-tab-pane\" aria-expanded=\"true\" class=\"oa-js-tab\">\r\n                    Organization\r\n                </a> \r\n            </li>\r\n            <li id=\"org-policies-tab\">\r\n                <a data-toggle=\"tab\" data-tabname=\"orgPolicies\" href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,"#policy-manager/orgPolicies/pending",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" data-target=\"#org-pending-policies-tab-pane\" aria-expanded=\"false\" class=\"oa-js-tab\">\r\n                    Policies in Organization\r\n                </a> \r\n            </li>\r\n            <li id=\"policies-tab\">\r\n                <a data-toggle=\"tab\" data-tabname=\"policies\" href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,"#policy-manager/policies/pending",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" data-target=\"#my-pending-policies-tab-pane\" aria-expanded=\"false\" class=\"oa-js-tab\">\r\n                    My Pending\r\n                    <span class=\"hidden-xs\"> Policies</span>\r\n                </a> \r\n            </li>\r\n            <li id=\"search-tab\">\r\n                <a data-toggle=\"tab\" data-tabname=\"search\" href=\""
    + alias3((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,"#policy-manager/search",{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" data-target=\"#search-tab-pane\" aria-expanded=\"false\" class=\"oa-js-tab\">\r\n                    <i class=\"ace-icon fa fa-search\" class=\"oa-js-tab\"></i>\r\n                    <span class=\"hidden-xs\">Search</span>\r\n                </a>\r\n            </li>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.inforceServiceRequestEnabled : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\r\n        <div class=\"tab-content tab-content-highlight\">\r\n            <div id=\"my-organization-tab-pane\" class=\"tab-pane\">\r\n                <div id=\"my-organization-region\">\r\n\r\n                </div>\r\n                <!--#my-organization-region-->\r\n            </div>\r\n            <!-- #my-organization-tab-pane .tab-pane -->\r\n\r\n            <div id=\"org-pending-policies-tab-pane\" class=\"tab-pane\">\r\n                <div class=\"well well-account-info hidden\" id=\"org-producer-info-region\"></div>\r\n                <div id=\"org-pending-policies-region\" class=\"well\"></div>\r\n            </div>\r\n            <!-- / #my-pending-policies-tab-pane .tab-pane -->\r\n            \r\n            <div id=\"my-pending-policies-tab-pane\" class=\"tab-pane\">\r\n                <div class=\"well well-account-info hidden\" id=\"producer-info-region\"></div>\r\n                <div id=\"my-pending-policies-region\" class=\"well\"></div>\r\n            </div>\r\n            <!-- / #my-pending-policies-tab-pane .tab-pane -->\r\n\r\n            <div id=\"search-tab-pane\" class=\"tab-pane\">\r\n                <div id=\"pending-search-region\"></div>\r\n            </div>\r\n            <!-- / #search-tab-pane .tab-pane -->\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.inforceServiceRequestEnabled : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\r\n        <!-- / .tab-content --> \r\n    </div>\r\n    <!-- / .tabbable --> \r\n</div>";
},"useData":true});
