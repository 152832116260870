/* global Backbone:false */

/**
 * Model object to back the transaction-history-datatable-v object.
 * 
 */

var config = require('../../../config/config');
var utils = require('../../../utils/utils');

var TransactionHistoryDataTableViewModel = Backbone.Model.extend({
    defaults: {
        col: 0,
        dir: 'asc',
        length: 25,
        producerId: null,
        start: 0,
        status: 'pending',
        showNoteForVariableProducts: false
    },

    /**
     * Build url for datatable to fetch data
     * 
     * @return {string} url
     */
    urlRoot: function () {
        var policyId = '';

        if (this.get('policyId')) {
            policyId = this.get('policyId');
        }
        
        return config.apiUrlRoot + 'policies/' + policyId + '/transactionHistory';
    },

    /**
     * Life cycle function
     * Ensure that numeric attrs are set as Number. 
     */
    initialize: function initialize(attrs, options) {
        if (attrs) {
            utils.setNumber(this, 'col', attrs.col);
            utils.setNumber(this, 'dir', attrs.dir);
            utils.setNumber(this, 'length', attrs.length);
            utils.setNumber(this, 'start', attrs.start);
        }
    }
});

module.exports = TransactionHistoryDataTableViewModel;
