// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <strong><span class=\"oa-forest\">"
    + container.escapeExpression(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + " Goal Achieved!</span></strong>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.toggleType : depth0),"on-schedule",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.onscheduleRemainingTotalAmountNumber : depth0),0,{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <strong><span class=\"oa-forest\">"
    + container.escapeExpression(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + " On Schedule!</span></strong>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <strong>\r\n                    <span class=\"oa-lake\" id=\"total-on-schedule-remaining-amount\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.onscheduleRemainingTotalAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n                </strong>\r\n                "
    + alias3(((helper = (helper = helpers.measureTotalLabel || (depth0 != null ? depth0.measureTotalLabel : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"measureTotalLabel","hash":{},"data":data}) : helper)))
    + " needed to reach goal of\r\n                <strong>\r\n                    <span class=\"oa-pumpkin\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.onscheduleTotalAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n                </strong>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <strong>\r\n                <span class=\"oa-lake\" id=\"total-"
    + alias4(((helper = (helper = helpers.toggleType || (depth0 != null ? depth0.toggleType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggleType","hash":{},"data":data}) : helper)))
    + "-required-remaining-amount\">"
    + alias4((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.requiredRemainingTotalAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n            </strong>\r\n            "
    + alias4(((helper = (helper = helpers.measureTotalLabel || (depth0 != null ? depth0.measureTotalLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"measureTotalLabel","hash":{},"data":data}) : helper)))
    + " needed to reach goal of\r\n            <strong>\r\n                <span class=\"oa-pumpkin\">"
    + alias4((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.requiredTotalAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n            </strong>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "oa-banner-red";
},"13":function(container,depth0,helpers,partials,data) {
    return "oa-lake";
},"15":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"https://oasf.my.salesforce.com/sfc/p/50000000bbUu/a/2J000000NJkh/GrbeBCGPIRO7KwZJRB.xPv1rHhlYEAoZC4uv0Ob1Wa0\" target=\"_blank\" rel=\"noopener noreferrer\">Rules</a> <i class=\"fa gray-80 fa-file-pdf-o\"></i>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"https://oasf.my.salesforce.com/sfc/p/50000000bbUu/a/2J000000NJdg/e.vCyjL9XX_rO0b4HzPRvhe.b.n.ZkmFdZLwN8dgNeI\" target=\"_blank\" rel=\"noopener noreferrer\">Rules</a> <i class=\"fa gray-80 fa-file-pdf-o\"></i>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li id=\"total-"
    + alias4(((helper = (helper = helpers.toggleType || (depth0 != null ? depth0.toggleType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggleType","hash":{},"data":data}) : helper)))
    + "-amount-goal-text\">\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.requiredRemainingTotalAmountNumber : depth0),0,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>\r\n<li>\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n    <strong>\r\n        <span id=\"total-"
    + alias4(((helper = (helper = helpers.toggleType || (depth0 != null ? depth0.toggleType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggleType","hash":{},"data":data}) : helper)))
    + "-actual-amount\" class=\""
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.actualTotalAmountNumber : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias4((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.actualTotalAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n        </span>\r\n    </strong>\r\n    actual "
    + alias4(((helper = (helper = helpers.measureTotalLabel || (depth0 != null ? depth0.measureTotalLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"measureTotalLabel","hash":{},"data":data}) : helper)))
    + "\r\n</li>\r\n\r\n"
    + ((stack1 = container.invokePartial(partials["pc-leaders-apr-status-bullet"],depth0,{"name":"pc-leaders-apr-status-bullet","hash":{"toggleType":(depth0 != null ? depth0.toggleType : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n\r\n<li class=\"margin-top-10\">\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"ib",{"name":"compare","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>\r\n";
},"usePartial":true,"useData":true});
