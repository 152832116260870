// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"center\"><i class=\""
    + alias4(((helper = (helper = helpers.iconCss || (depth0 != null ? depth0.iconCss : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconCss","hash":{},"data":data}) : helper)))
    + "\"></i>\r\n    <h4><a href=\""
    + alias4((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || alias2).call(alias1,(depth0 != null ? depth0.href : depth0),{"name":"buildHrefURL","hash":{},"data":data}))
    + "\" class=\"oa-js-nav\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a></h4>\r\n    <p>"
    + ((stack1 = ((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\r\n</div>\r\n";
},"useData":true});
