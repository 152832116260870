/* global Backbone:false */
/**
 * View used to display the character count for the comments section of the
 * Requirement Submission form.
 */

import template                           from '../templates/comments-character-count-t.hbs';
import RequirementSubmissionFormViewModel from '../viewModels/requirement-submission-form-vm';

const CommentsCharacterCountView = Backbone.Marionette.ItemView.extend({

    errors   : {
        invalidOptions : 'Options passed to CommentsCharacterCountView ' +
                         'must contain either a policyId or a model'
    },

    template : template,

    initialize (options) {

        if (!options.model && !options.policyId) {
            throw new Error(this.errors.invalidOptions);

        } else if (options.model) {
            this.model = options.model;

        } else {
            this.model = new RequirementSubmissionFormViewModel({
                policyId : options.policyId
            });
        }

        this.listenTo(this.model, 'change:commentsLength', this._respondToCommentChange);
    },

    _respondToCommentChange () {
        if (this.model.get('commentsLength') < 0) {
            this.model.set('commentTooLong', true);
        } else {
            this.model.set('commentTooLong', false);
        }
        this.render();
    }

});

module.exports = CommentsCharacterCountView;