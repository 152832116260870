/* global Backbone:false, _:false*/

/**
* policy details model
* 
* To build url to fetch data:
* if Policy Number exist: 
*   new PolicyDetailModel({
*       id : 1234567890   
*    });
*
* if policy number is not ready yet but there is caseId:
*   this.model = new PolicyDetailModel({
*       caseId:212121
*   });
**/

import config from '../../../config/config';
import policyDataAvailability from './parsers/policy-data-availability';
import Policy1035ExchangeHistory from './parsers/policy-1035-exchange-history';
import PolicyApplicationStatusTracker from './parsers/policy-application-status-tracker';
import PolicyBillingPayment from './parsers/policy-billing-payment';
import policyCoverage from './parsers/policy-coverage';
import policyIdentification from './parsers/policy-identification';
import PolicyInvestmentAllocation from './parsers/policy-investment-allocation';
import PolicyRatingInformation from './parsers/policy-rating-information';
import PolicyRelationships from './parsers/policy-relationships';
import policyRequirements from './parsers/policy-requirements';
import PolicyValue from './parsers/policy-value';
import policyNotes from './parsers/policy-notes';
import utils from '../../../utils/utils';
import moment from 'moment-timezone';

let applicationStatusTrackerParser;
let policy1035ExchangeParser;
let policyBillingPayment;
let policyInvestmentAllocation;
let policyRatingInformation;
let policyRelationships;
let policyValue;

const PolicyDetailModel = Backbone.Model.extend({

    errors : {
        missingURLParam     : 'URL is missing to add targetuser parameter',
        missingRelParameter : 'relation partmeter is missing to find link'
    },

    /**
     * Return the URL root based on the values in the model.
     * If 'id' is not set, it uses the URL with a caseId querystring parameter.
     * @returns {string}
     */
    urlRoot() {
        if (this.id) {
            return config.apiUrlRoot + 'policies';
        } else if (this.get('caseId')) {
            return config.apiUrlRoot + 'policies?caseId=' + this.get('caseId');
        } else {
            throw new Error('PolicyDetailModel.urlRoot: missing \'id\' or \'caseId\'');
        }
    },


    /**
     * Override the default parse in order to set some flags based on other values within the
     * returned data.
     * 
     * @param response the JSON response from the service
     * @returns {*} the response with flags set based on returned values
     */
    parse(response) {

        // These two calls must come first! They remove "notImplemented" data from
        // the policy data object so that later functions don't fail due to unexpected
        // data.
        response = policyDataAvailability.deleteNotImplemented(response);
        response = policyDataAvailability.nullifyNotAvailableChildProperties(response);

        response = policyIdentification._setShowPolicyExplanationBenefitsFlag(response);
        //Flag to notify whether policy detail is ready
        response.hasPolicyDetails = true;

        //set an object to show disclaimer note to a specific users
        response = policyCoverage.setDisclaimerNoteState(response);

        //set whether policy is annuity
        response.isAnnuity = policyCoverage.isAnnuity(response);

        response.coverage = policyCoverage.sortJointCoverages(response);
        
        //set labels for related policy detail in the "Coverage" section
        response = policyCoverage.setRelatedPoliciesLabels(response);

        // set flag for header in coverages.
        response = policyCoverage.setIsUlVulFlag(response);

        response = policyCoverage.setHasPUAAmountFlag(response);

        if (utils.isImplemented(response.customerRoles)) {
            policyRelationships = new PolicyRelationships();
            //set  data or Policy Relationship section
            response = policyRelationships._setCustomerPolicyRelationshipData(response);
        }

        // set policy identification information
        response = policyIdentification._setPolicyIdentificationLabelAndName(response);

        response.iPipelinePolicyHSLinkOptions =
            policyIdentification._getIPipelinePolicyHSLinkOptions(
                response,
                this._getActivePolicyPaidStatus(response),
                config.fflag.enablePolicyHSSSOLink
            );

        //Removed usage of feature flag - enableInforceIllusLegacyAndLiberty and 
        //as parameter with Sept 30, 2023 release
        //Added feature flag - enableInforceIllusLegacy121
        response.iPipelineInforceIllustrationLinkOptions =
            policyIdentification._getIPipelineInforceIllustrationLinkOptions(
                response,
                config.fflag.enableInforceIllusVUL,
                config.fflag.enableInforceIllusLegacy121
            );

        //displaying the section could be dependent on whether the links are shown
        //so this must come after calling the methods to determine if the links
        //should be shown        
        response.shouldShowPolicyServiceSection =
            policyIdentification._shouldShowPolicyServiceSection(response);

        // Generate contact mail link (caseManager / Policy Service Contact)
        response = policyIdentification._setContactMailto(response);

        //set active status flag
        response = policyIdentification._setActivePolicyFlag(response);

        //set active status view flag
        response = policyIdentification._setActiveStatusViewFlag(response);

        response = policyIdentification._setAbandonedPolicyFlag(response);
        response = policyIdentification._setShowflag(response);

        // To determing display of Writing / Servicing agents
        response = policyIdentification._setShowAgentsFlag(
            response,
            this._getActivePolicyPaidStatus(response)
        );

        // sort writing producers
        response = policyIdentification._sortWritingProducers(response);

        // set alert messages
        response = policyIdentification._setAlertAttentionMessage(response);

        // set showPolicyStatement flag
        response = policyIdentification._setShowPolicyStatementLinkFlag(response);

        response = policyRequirements.setCustomerFirstNamePolicyRequirements(response);

        // set counts on requirements
        response = policyRequirements.setRequirementsCounts(response);

        //sort received policy requirement list
        response = policyRequirements.sortPolicyRequirements(response);

        response.showRequirements = policyRequirements.shouldShowRequirements(response);

        if (!policyRequirements.shouldShowReceivedRequirements(response)) {
            response = policyRequirements.deleteReceivedRequirements(response);
        }

        // sort policy notes
        response = policyNotes.sortPolicyNotesBySection(response);

        if (utils.isImplemented(response.ratingInformation)) {
            policyRatingInformation = new PolicyRatingInformation();
            // set flags and data to display rating information in the coverage section
            response = policyRatingInformation._setRatingInformationFlagsAndData(response);
        }

        //sort exchange history
        let exchangeHistory = response.exchangeHistory;
        if (exchangeHistory && utils.isImplemented(exchangeHistory)) {
            policy1035ExchangeParser = new Policy1035ExchangeHistory(exchangeHistory);
            response.exchangeHistory = policy1035ExchangeParser.sort();
            response.exchangeHistory.statusCounts = policy1035ExchangeParser.getStatusCounts();
        }

        // parse policyStatus to get icons for the "Application Status" tracker
        if (response.policyStatus && response.application) {

            // instantiate the parser
            applicationStatusTrackerParser
                = new PolicyApplicationStatusTracker(response.policyStatus, response.application,
                    response.policyId);

            // set the acceleratedUnderwritingMessage if acceleratedUnderwriting exists
            if (response.application.acceleratedUnderwriting) {
                response.application.acceleratedUnderwritingMessage =
                    applicationStatusTrackerParser._setAcceleratedUnderwritingMessage(
                        response.application.acceleratedUnderwriting
                    );
            }
            if (response.billingDetail) {
                var dateDifference = 0;
                if (response.application.issued) {
                    dateDifference = utils.dateDiff(response.application.issued);
                }
                response.billingDetail.showPlanningPDR = (
                    response.billingDetail.unscheduledPremiumAmount &&
                    dateDifference <= 365);
            }
            // append the icon properties object to the application object
            response.application.statusIcons
                = applicationStatusTrackerParser.getIconPropertiesObject();

            // set application display flags
            applicationStatusTrackerParser._setApplicationStatusDisplayFlags(
                this._getActivePolicyPaidStatus(response),
                response.policyStatus.statusView
            );

        }

        policyBillingPayment = new PolicyBillingPayment();

        response = policyBillingPayment._setBillingDetailDisplayFlags(response);
        // determine the billing section header
        response = policyBillingPayment._setBillingHeaderLeadingText(response);

        if (response.isActive) {

            // TODO remove this when Policy Highlights are displayed in production
            this._setDisplayHighlights(config.fflag.displayPolicyHighlights);

            policyValue = new PolicyValue();

            response = policyValue.setNetSurrenderValueAmountLabel(response);
            response = policyValue.setCashValueAmountLabel(response);
        }

        // set FDIA attributes
        response.isActivePremiumPayingFDIA = this._getActivePremiumPayingFDIAStatus(response);
        response.isActiveAnnuitizedFDIA = this._getActiveAnnuitizedFDIAStatus(response);

        //set whether policy is a variable product
        response.isVariableProduct = this._setVariableProductState(response);

        policyInvestmentAllocation = new PolicyInvestmentAllocation();

        // set whether policy is indexed annuity
        response = policyInvestmentAllocation._setIndexedAnnuityState(response);

        //set whether to show investments for active
        response.showInvestmentsForActive =
        policyInvestmentAllocation.shouldShowInvestmentsForActive(response);
        //set whether to show investments for pending
        response.showInvestmentsForPending =
        policyInvestmentAllocation.shouldShowInvestmentsForPending(response);

        if (response.showInvestmentsForActive){            
            if(response.isActive && response.isVariableProduct){
                response = policyInvestmentAllocation.setAssetCollection(response);
            }
        }   
        
        // set Active or Proposed Acord Holding Status
        response.isActiveOrProposedAcordHoldingStatus =
         this._getActiveOrProposedPolicyStatus(response);

        response.isActiveAccordHoldingStatus = 
         this._getActivePolicyStatus(response);

        // set "Paid-Up" or "Reduced Paid-Up" statusDetail
        // for premium mode quotes
        response.isStatusDetail =
         this._getStatusDetail(response);

        // set premiumSchedule check for PMQ
        response.isPremiumSchedule =
        this._getPremiumSchedule(response);

        // set COB for PMQ
        response.showPremiumModeQuotes= config.fflag.enablePremiumModeQuotes &&
         !this._showPremiumModeQuotesTab(response);

        response.alternatePremModeQuotesList =
         this._getAlternatePremModeQuotes(response);

        //set showCurrentModeForPMQ Premium Mode Quotes
        response.showCurrentMode =
         this._fetchCurrentBilling(response);

        response.showNoteForVariableProducts =
         this._setVariableProductState(response);
        
        response.showFinancialTab =
         this._getDisplayForFinancialTab(response);

        //return final response as model object
        return response;
    },

    /**
     * Get active policies paid status whether its within / after 30days
     * @param  {object} response Service response
     * @return {object}          paidStatus object with flags for within/after days
     */
    _getActivePolicyPaidStatus(response) {

        let paidStatus = {
            paid: false,
            afterDisplayPeriod: false,
            withinDisplayPeriod: false,

            // Has it been more than 30 days since original paid date?
            after30Days: false
        };

        // Hide icons if holding status is not Active, Unknown, Dormant or Inactive
        // if paid date is within 92 days, 
        // if its more than 92 days hide Application Status section itself
        if ((response.policyStatus && response.policyStatus.acordHoldingStatus !== 'Proposed')
            && (response.application && response.application.paid)) {
            paidStatus.paid = true;
            const dateDifference = utils.dateDiff(response.application.paid);
            if (dateDifference > 92) {
                paidStatus.afterDisplayPeriod = true;
            } else {
                paidStatus.withinDisplayPeriod = true;
            }

            if (dateDifference > 30) {
                paidStatus.after30Days = true;
            }
        }

        return paidStatus;
    },

    /**
    * Get active premium paying FDIA status
    * @param  {object} response Service response
   */
    _getActivePremiumPayingFDIAStatus(response) {
        return response.policyStatus
            && response.policyStatus.acordHoldingStatus === 'Active'
            && response.policyStatus.statusDetail === 'Premium Paying'
            && response.product.productTypeCode === 'FDIA';
    },

    /**
     * Get active annuitized FDIA status
     * @param  {object} response Service response
    */
    _getActiveAnnuitizedFDIAStatus(response) {
        return response.policyStatus
            && response.policyStatus.acordHoldingStatus === 'Active'
            && response.policyStatus.statusDetail === 'Annuitization'
            && response.product.productTypeCode === 'FDIA';
    },

    /**
     * Get active Policy status
     * @param  {object} response Service response
    */
    _getActiveOrProposedPolicyStatus(response) {
        return response.policyStatus
            && (response.policyStatus.acordHoldingStatus === 'Active'
                || response.policyStatus.acordHoldingStatus === 'Proposed');
    },

    /**
     * Get active Policy status
     * @param  {object} response Service response
    */
    _getActivePolicyStatus(response) {
        return response.policyStatus
            && (response.policyStatus.acordHoldingStatus === 'Active');
    },

    _getStatusDetail(response) {
        var PMQTabEnable = true;
        if(response.policyStatus
        && response.policyStatus.statusDetail
        && (response.policyStatus.statusDetail === 'Reduced Paid-Up'
        || response.policyStatus.statusDetail === 'Paid-Up'
        || response.policyStatus.statusDetail === 'Disability Waiver'
        || response.policyStatus.statusDetail === 'Extended Term Insurance')){
            PMQTabEnable = false;
        }
        return PMQTabEnable;
    },

    _isInFirstYear(date) {
        const parsedDate = moment(date,  'YYYY-MM-DD');
        const currentDate = moment();
        return currentDate.diff(parsedDate, 'years') <= 0;
    },

    _showPremiumModeQuotesTab(response)
    {
        if (response.product
            && response.product.productName.toLowerCase().
            trim().indexOf('continuation of benefit' ) > -1
            && response.billingDetail
            && (response.billingDetail.currentBilling))
            {
            const givenDate = moment(response.application.issued, 'YYYY-MM-DD');
            if(!response.billingDetail.currentBilling.hasOwnProperty('paymentAmount')
                && this._isInFirstYear(givenDate))
                {
                return true;
            }
            return false;
        }
    },
	
    _getPremiumSchedule(response) {
        var PMQTabStatus = true;
        if(response.billingDetail && 
        response.billingDetail.premiumSchedule &&
        (response.billingDetail.premiumSchedule === 'Single Pay' ||
        response.billingDetail.premiumSchedule === 'Single Premium')){
            PMQTabStatus = false;
        }
        return PMQTabStatus;
    },

    _fetchCurrentBilling(response) {
        var currentMode = '';
        if(response.billingDetail && 
            response.billingDetail.currentBilling &&
            response.billingDetail.alternatePremiumModes) {
            var alternatePremiumModeQuotesArray = 
                response.billingDetail.alternatePremiumModes;
            var currentBillingObj = response.billingDetail.currentBilling;
            currentMode =
                alternatePremiumModeQuotesArray.find(pmq => 
                    pmq.paymentAmount && pmq.paymentMethod && pmq.paymentMode &&
                    currentBillingObj.paymentMode && 
                    this.convertPaymentMode(pmq.paymentMode) === 
                    this.convertPaymentMode(currentBillingObj.paymentMode) &&
                    currentBillingObj.paymentAmount && 
                    pmq.paymentAmount === currentBillingObj.paymentAmount &&
                    currentBillingObj.paymentMethod && ((pmq.paymentMethod === 'Regular Billing' && 
                    currentBillingObj.paymentMethod === 'Direct Pay') ||
                    (pmq.paymentMethod === 'Electronic Funds Transfer' &&
                     currentBillingObj.paymentMethod === 'Automatic Draft'))
                );
        }
        return currentMode;
    },

    convertPaymentMode(paymentMode){
        var convertedStr = paymentMode.replace(/[^a-zA-Z0-9 ]/g, '');
        return convertedStr.toLowerCase();
    },

    _getAlternatePremModeQuotes(response) {
        var currentBillingIfMatched = this._fetchCurrentBilling(response);
        var uniqueAlternatePremModeQuotes = [];
        if(currentBillingIfMatched && response.billingDetail &&
            response.billingDetail.alternatePremiumModes){
            var altPremModeQuotes = response.billingDetail.alternatePremiumModes;
            uniqueAlternatePremModeQuotes = altPremModeQuotes.filter((pmq) => 
                (pmq && pmq.paymentMethod && pmq.paymentMode && pmq.paymentAmount) &&
                (JSON.stringify(currentBillingIfMatched) !== JSON.stringify(pmq)));
        }else{
            if(response.billingDetail &&
                response.billingDetail.alternatePremiumModes){
                var altPMQ = response.billingDetail.alternatePremiumModes;
                uniqueAlternatePremModeQuotes = altPMQ.filter((pmq) => 
                pmq && pmq.paymentMethod && pmq.paymentMode && pmq.paymentAmount);
            }
        }
        return uniqueAlternatePremModeQuotes;
    },

    /**
     * Show Note for Variable Products 
     * @param  {object} response Service response
    */
    _setVariableProductState(response) {
        let activeProductType = ['FPVA', 'FPVUL', 'SPVA', 'SPVUL'];
        
        if( response.product 
            && activeProductType.indexOf(response.product.productTypeCode) >=0) {
            return true;
        }
        
        return false;
    },
     /**
     * Show / Hide Financial Tab
     * @param  {boolean} response Show / Hide
    */
    _getDisplayForFinancialTab(response) {
        return response.policyStatus
        && (this._getActiveOrProposedPolicyStatus(response)
        || !response.policyStatus.onlySummaryDataAvailable);
    },

    /**
     * Return link based on relation type
     *
     * @param {string} rel relation type specified in link property
     * @return {object} model object created with relation type provided.
     * @private
     */
    _getLinkForRel(rel) {
        if (!rel) {
            throw new Error(this.errors.missingRelParameter);
        }
        const links = this.get('links');
        let link;
        if (links && _.isObject(links) && !_.isEmpty(this.get('links'))) {
            link = _.find(links, (linksItem) => {
                return linksItem.rel === rel;
            });

            if (link && _.isObject(link)) {
                return new Backbone.Model(link);
            }
        }
        return undefined;
    },

    /**
     * Add 'hasUnderwriterAndFiles' flag and documents URL(application and illustration) in
     * application object based on availability of data.
     */
    setUnderwriterAndFilesAvailabilityFlag() {
        const application = this.get('application');
        const applicationDoc = this._getLinkForRel('application-document');
        const illustrationDoc = this._getLinkForRel('illustration-document');

        if ((application && application.underWriter)
            || (applicationDoc && applicationDoc.get('href'))
            || (illustrationDoc && illustrationDoc.get('href'))) {

            application.hasUnderwriterAndFiles = true;

            if (applicationDoc && applicationDoc.get('href')) {
                application.applicationDocHref = utils.buildHrefUrlForDocument(
                    config.apiDocUrlRoot + applicationDoc.get('href')
                );
            }

            if (illustrationDoc && illustrationDoc.get('href')) {
                application.illustrationDocHref = utils.buildHrefUrlForDocument(
                    config.apiDocUrlRoot + illustrationDoc.get('href')
                );
            }
        }
    },

    /**
    * TODO remove this when Policy Highlights are displayed in production,
    * and all code referencing this function. Also remove the condition in
    * policy-detail-t.hbs in the policyValue section
    * 
    * @param {boolean} displayPH - from config.fflag.displayPolicyHighlights
    */
    _setDisplayHighlights(displayPH) {
        this.set('displayPolicyHighlights', displayPH);
    }
});

module.exports = PolicyDetailModel;