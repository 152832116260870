/* global _:false */

/**
 * Utility methods for validating email
 */

var utilsEmailValidation = {

    errors: {
        required: 'Email Address is required.',
        invalid: 'Please enter a valid Email Address.'
    },

    /**
    * Examine an email address is valid or not.
    * @param emailAddress input email value
    * @returns string
    */
    validateEmailAddress: function (emailAddress) {
        if (_.isEmpty(emailAddress)) {
            return this.errors.required;
        }
        if (!/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(emailAddress)) {
            return this.errors.invalid;
        }
        var ruleAdherence = this.validateEmailLengthCheck(emailAddress);
        if (ruleAdherence.lengthExceed) {
            return this.errors.invalid;
        }
        return;
    },

    validateEmailLengthCheck: function (emailAddress) {
        var maxLength = 64;
        var rules = {};
        var email = emailAddress;
        rules.lengthMax = (email.length <= maxLength);
        rules.lengthExceed = (email.length > maxLength);
        return rules;
    },

};

module.exports = utilsEmailValidation;