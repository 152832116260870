// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "    <li class=\"margin-btm-5\">\r\n        "
    + ((stack1 = container.lambda(((stack1 = blockParams[0][0]) != null ? stack1.description : stack1), depth0)) != null ? stack1 : "")
    + "\r\n    </li>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "<hr>\r\n<p><strong>Notes</strong></p>\r\n\r\n<ul  id=\"policy-notes\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.policyNotes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "</ul>";
},"useData":true,"useBlockParams":true});
