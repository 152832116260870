/* global require:false, module:false, Backbone:false */
/**
 * ProducerDelegateModel encapsulates the list of delegates for a producer.
 */

import config from '../../../config/config';

const ProducerDelegateModel = Backbone.Model.extend({

    errors : {
        idRequired : 'An id property is required to retrieve delegates list'
    },

    /**
     * Lifecycle method. Requires that the attrs param exists and has an "id" property.
     * @param attrs the attributes to set in the model
     * @param options the standard options object
     */
    initialize (attrs, options) {
        if (!attrs || !attrs.id) {
            throw new Error(this.errors.idRequired);
        }
    },

    /**
     * Parse the response. Basically, place the response in the model's "delegates" attribute.
     * @param response the server response
     * @param options the standard options object
     */
    parse (response, options) {
        this.set('delegates', response.data);
    },

    /**
     * Retrieve the URL to fetch the model
     * Appended '?start=0&length=1000' to the URL to make sure we get all of the delegates back
     * Without the start/length, it would just display 25 delegates
     * 
     * @returns {string}
     */
    url () {
        if (! this.has('id')) {
            throw new Error(this.errors.idRequired);
        }
        return config.apiUrlRoot + 'producers/' + this.get('id') + 
             '/delegates?start=0&length=1000'; 
    }
});
              

module.exports = ProducerDelegateModel;