/* global Marionette, _ */
/**
 A model parser class which parses the policyStatus and application
 data in order to define the properties used to display the related
 icons in the "Application Status" section of the Policy Details
 page.

 The object is instantiated using the policyStatus data from the
 response.  The getIconPropertiesObject method will return an object
 with each status icons properties based upon the business rules for
 each section of the tracker.

 */

var utils  = require('../../../../utils/utils');

var policyApplicationStatusTracker = Marionette.Object.extend({

    errors : {
        invalidPolicyStatusObject :
            'policyStatus data should be an object',
        invalidApplicationObject  :
            'application data should be an object'
    },

    // Properties used for the large "policy status" on the left-hand side of the tracker
    policyStatusIcons : {
        default    : {
            labelSuffix : '',
            type        : 'pencil'
        },
        pending    : {
            labelSuffix : 'yellow',
            type        : 'pencil'
        },
        complete   : {
            labelSuffix : 'success',
            type        : 'check'
        },
        terminated : {
            labelSuffix : 'grey',
            type        : ''
        },
        warning    : {
            labelSuffix : 'grey',
            type        : 'warning'
        }
    },

    /**
     * Properties used for the smaller icons displayed in the trackers <li> tags
     * (e.g. "Application", "U/W Decision", "Paid", etc.)
     * @typedef iconProps
     * @type {object}
     * @property {?string} liClass The class used to apply to the <li> tag in the markup
     * @property {string} icon The icon class used for the <i> tag in the markup
     */
    smallStatusIcons : {
        success  : {
            liClass : 'success',
            icon    : 'check'
        },
        complete : {
            liClass : null,
            icon    : 'check'
        },
        awaiting : {
            liClass : 'active',
            icon    : 'hourglass-half'
        },
        warning  : {
            liClass : null,
            icon    : 'warning'
        },
        inprocess   : {
            liClass : 'in-process',
            icon        : 'blank'
        }
    },

    /**
     * Initialize the parser
     * @param {object} policyStatus Information returned from the service
     * @param {object} application Application object returned from the service
     * @param {?string} policyNumber The policyNumber value returned from the service
     */
    initialize : function (policyStatus, application, policyNumber) {
        // validate policyStatus
        if (!policyStatus  || !_.isObject(policyStatus)) {
            throw new Error(this.errors.invalidPolicyStatusObject);
        }
        this.policyStatus = policyStatus;

        // validate application
        if (!application || !_.isObject(application)) {
            throw new Error(this.errors.invalidApplicationObject);
        }
        this.application = application;

        // null value for policyId is a valid value in the case of AWD
        this.policyNumber = policyNumber;

        // set the status and description values to use for logic
        this._setDefaultStatusAndDescriptionValues();
    },

    /** Checks to see whether or not a status and a description
     * exist in policyStatus. If not, it will set them to
     * an empty string.
     * The values are then used in most of the other methods' logic.
     */
    _setDefaultStatusAndDescriptionValues : function () {
        this.statusLowerCase      = '';
        this.descriptionLowerCase = '';

        if (typeof this.policyStatus.status === 'string') {
            this.statusLowerCase = this.policyStatus.status.trim().toLowerCase();
        }

        if (typeof this.policyStatus.description === 'string') {
            this.descriptionLowerCase = this.policyStatus.description.trim().toLowerCase();
        }
    },

    /**
     * The main "public" method which calls "private" methods to build the data
     * structure which represents the icons used in the "Application Status" section
     * of the policy details page.
     *
     * Sample output for a policy in "Pending - In Process":
     *
     * {
     *      // The "main" large icon
     *      policyStatus : {
     *          displayProps : {
     *              labelSuffix : 'yellow',
     *              type        : 'pencil'
     *          }
     *      },
     *
     *      // subsequent icons displayed in each of the list items
     *      application : {
     *          iconProps : {
     *              liClass : 'success',
     *              icon    : 'check'
     *          },
     *          displayAppReceivedDate : false
     *      },
     *
     *      uwDecision : {
     *          iconProps : {
     *              liClass : 'active',
     *              icon    : 'hourglass-half'
     *          }
     *      },
     *
     *      readyToIssue : {
     *          iconProps   : null,
     *          displayText : null
     *      },
     *
     *      policySent : {
     *          iconProps   : null,
     *          displayText : null
     *      },
     *
     *      paid : {
     *          iconProps   : null,
     *          displayText : null
     *      }
     * }
     *
     * @return {object} An object which represents the icons used in the "pizza tracker"
     *
     */
    getIconPropertiesObject : function () {
        var iconProps = {
            policyStatus : this._getPolicyStatusDisplayProperties(),
            application  : this._getApplicationIconProperties(),
            uwDecision   : this._getUwDecisionIconProperties(),
            readyToIssue : this._getReadyToIssueIconProperties(),
            policySent   : this._getPolicySentIconProperties(),
            paid         : this._getPaidIconProperties()
        };

        return iconProps;
    },

    /**
     * This method returns an object which represents the main, large
     * icon used in the "Application Status" section.
     *
     * @return {object} Which represents the display properties to apply
     * to the large status icon in the "Application Status" section.
     */
    _getPolicyStatusDisplayProperties : function () {
        var acordHoldingStatus    = this.policyStatus.acordHoldingStatus;
        var description           = '';
        var text                  = '';
        if (typeof this.policyStatus.status === 'string') {
            text = this.policyStatus.status.trim();
        }
        var returnObj             = {
            displayProps : null,
            text         : text
        };
        var warningStatusValues   = ['withdrawn', 'incomplete'];
        var workflowStatus        = this.policyStatus.workflowStatus;
        
        if (this.policyStatus.description) {
            description = this.policyStatus.description;

            if (utils.policyStatusReportingGroup.pending.indexOf(description) !== -1) {
                returnObj.displayProps = this.policyStatusIcons.pending;

            } else if (utils.policyStatusReportingGroup.paid.indexOf(description) !== -1) {
                returnObj.displayProps = this.policyStatusIcons.complete;

            } else if (utils.policyStatusReportingGroup.inactive.indexOf(description) !== -1) {
                returnObj.displayProps = this.policyStatusIcons.warning;
            }

            // Differentiate between the different status values when
            // description is "Paid".
            if ((description === 'Paid' && this.policyStatus.status) || workflowStatus === 'Paid') {
                if (this.policyStatus.status === 'Issued') {
                    
                    returnObj.text = description;
                    
                    if (_.contains(['Active', 'Dormant' , 'Unknown'], acordHoldingStatus)) {
                        returnObj.displayProps = this.policyStatusIcons.complete;
                        returnObj.text = workflowStatus;
                    }
                } else if (this.policyStatus.status === 'Terminated') {
                    returnObj.text = description;
                    returnObj.displayProps = this.policyStatusIcons.terminated;

                    if (acordHoldingStatus === 'Inactive') {
                        returnObj.text = workflowStatus;
                    }

                } else if (this.policyStatus.status === 'Pending') {
                    returnObj.displayProps = this.policyStatusIcons.pending;
                }

            } else if (this.policyStatus.status === 'Terminated') {
                returnObj.displayProps = this.policyStatusIcons.terminated;
            }

        }

        // Do not have a workflow status, so description will not be generated.
        // and if there is some issue with Status Description such as case sensitive
        // or not included with reporting group etc.
        if (!returnObj.displayProps) {
            returnObj.displayProps = this.policyStatusIcons.default;
        }

        if ((_.contains(warningStatusValues, this.statusLowerCase)
                || this.descriptionLowerCase === 'in process') && this.policyStatus.date) {
            returnObj.policyStatusDate  = this.policyStatus.date;
        }

        // Some of the logic in other areas of the tracker will use the displayProps.
        // Setting this to a local variable for use with these other methods.
        this.policyStatusDisplayProperties = returnObj.displayProps;

        return returnObj;
    },

    /**
     * This returns the icon properties for the "Application" column.
     *
     * @returns {object} returnObj Contains displayAppReceivedDate and {iconProps}
     */
    _getApplicationIconProperties : function () {
        var returnObj = {
            iconProps              : null,
            displayAppReceivedDate : false
        };

        // if any date exists (application.received, application.signed, or application.entered)
        // and the status Status icon is a yellow pencil, a green checkmark is displayed
        if (this.application.signed || this.application.received || this.application.entered) {

            if ((this.policyStatusDisplayProperties === this.policyStatusIcons.pending) ||
                (this.policyStatusDisplayProperties === this.policyStatusIcons.complete)) {
                returnObj.iconProps = this.smallStatusIcons.success;
            } else {
                returnObj.iconProps = this.smallStatusIcons.complete;
            }

        }

        // if this is an AWD policy and status isn't available or
        // the description is "Application Received", display
        // an hourglass icon and show application.received date
        if (!this.policyNumber && this.descriptionLowerCase === 'application received') {
            returnObj.iconProps = this.smallStatusIcons.awaiting;
            returnObj.displayAppReceivedDate = true;
        }


        return returnObj;
    },

    /**
     * Determines the icon properties to use for the "U/W Decision" section
     *
     * @returns {object} returnObj Contains {iconProps}
     */
    _getUwDecisionIconProperties : function () {
        var returnObj = {
            iconProps   : null,
            displayText : null,
            noUWDetail : false
        };

        var requirementsJumpLinkTemplate = _.template('<a href="#requirements" ' +
            'title="See Requirements & U/W Decisions for more information"><%= status %></a>');

        if (this.application.uwDecision) {

            // Return a green check if the main status icon is a
            // yellowPencil or complete (for active policies).
            if ((this.policyStatusDisplayProperties === this.policyStatusIcons.pending) ||
                (this.policyStatusDisplayProperties === this.policyStatusIcons.complete)) {
                returnObj.iconProps = this.smallStatusIcons.success;
            } else {
                // return a grey check for other status values
                returnObj.iconProps = this.smallStatusIcons.complete;
            }

        }else if ((this.application.acceleratedUnderwritingMessage)
            && (this.policyStatusDisplayProperties.labelSuffix === 'yellow'
                || this.policyStatusDisplayProperties.labelSuffix === 'green')) {
            returnObj.iconProps = this.smallStatusIcons.inprocess;
        }

        // if the status is "Declined" or "Postponed" (regardless of
        // whether or not uwDecision value is returned) display a warning icon.
        if (this.statusLowerCase === 'declined' || this.statusLowerCase === 'postponed') {
            returnObj.iconProps = this.smallStatusIcons.warning;
            returnObj.displayText = requirementsJumpLinkTemplate({
                // Retain case from service by using policyStatus.status
                status: this.policyStatus.status
            });

        } else if (this.descriptionLowerCase === 'in underwriting') {
            returnObj.iconProps = this.smallStatusIcons.awaiting;
            returnObj.displayText = this.descriptionLowerCase;

        }
        if (!returnObj.displayText
            && !this.application.uwDecision
            && !this.application.acceleratedUnderwritingMessage
            && !this.application.uwDecisionNotApplicable) {
            returnObj.noUWDetail = true;
        }
        return returnObj;
    },

    /**
     * Used to determine the display properties for the "Ready to Issue"
     * column in the "Application Status" section.
     *
     * @returns {object} returnObj Contains displayText and {iconProps}
     */
    _getReadyToIssueIconProperties : function () {
        var awaitingValues = [
            'requirements outstanding, underwriting complete',
            'requirements outstanding, not issued'
        ];
        var returnObj = {
            iconProps   : null,
            displayText : null
        };

        if (this.application.readyToIssue) {

            // Return a green check if the main status icon is a
            // yellowPencil or complete (for active policies).
            if ((this.policyStatusDisplayProperties === this.policyStatusIcons.pending) ||
                (this.policyStatusDisplayProperties === this.policyStatusIcons.complete)) {

                returnObj.iconProps = this.smallStatusIcons.success;

            } else if (this.statusLowerCase !== 'declined') {

                returnObj.iconProps = this.smallStatusIcons.complete;

            }
        }

        // the awaiting icon should appear when the description
        // is one of the awaitingValues -- regardless of whether
        // or not the application.readyToIssue date is present
        if (_.contains(awaitingValues, this.descriptionLowerCase)) {

            returnObj.iconProps = this.smallStatusIcons.awaiting;
            returnObj.displayText = 'Requirements Outstanding';

        }

        return returnObj;
    },

    /**
     * Used to determine the display properties for the "Policy Sent"
     * column in the "Application Status" section.
     *
     * @returns {object} returnObj Contains displayText and {iconProps}
     */
    _getPolicySentIconProperties : function () {
        var returnObj = {
            iconProps   : null,
            displayText : null
        };

        if (this.application.policySent) {

            // Return a green check if the main status icon is a
            // yellowPencil or complete (for active policies).
            if ((this.policyStatusDisplayProperties === this.policyStatusIcons.pending) ||
                (this.policyStatusDisplayProperties === this.policyStatusIcons.complete)) {
                returnObj.iconProps = this.smallStatusIcons.success;
            } else {
                returnObj.iconProps = this.smallStatusIcons.complete;
            }

        } else if (this.descriptionLowerCase === 'ready for issue') {
            returnObj.iconProps   = this.smallStatusIcons.awaiting;
            returnObj.displayText = this.descriptionLowerCase;
        }

        return returnObj;

    },

    /**
     * Used to determine the display properties for the "Paid"
     * column in the "Application Status" section.
     *
     * @returns {object} returnObj Contains displayText and {iconProps}
     */
    _getPaidIconProperties : function () {
        var awaitingDescriptionValues = ['issued not paid', 'ready for payment'];
        var returnObj                 = {
            iconProps   : null,
            displayText : null
        };

        if (this.application.paid) {

            // Return a green check if the main status icon is a
            // yellowPencil or complete (for active policies).
            if ((this.policyStatusDisplayProperties === this.policyStatusIcons.pending) ||
                (this.policyStatusDisplayProperties === this.policyStatusIcons.complete)) {

                returnObj.iconProps = this.smallStatusIcons.success;

            } else {

                returnObj.iconProps = this.smallStatusIcons.complete;
            }
        }

        if (_.contains(awaitingDescriptionValues, this.descriptionLowerCase)) {

            returnObj.iconProps = this.smallStatusIcons.awaiting;
            returnObj.displayText = this.descriptionLowerCase;

        }

        return returnObj;
    },

    /**
     * Change the acceleratedUnderwriting message if needed. "Underwriting" should be
     * abbreviated as "U/W".
     * @params {string} acceleratedUnderwriting
     * @returns {string|null} aUwString with "Underwriting" abbreviated as "U/W". Null if
     * input param is not a string.
     * @private
     */
    _setAcceleratedUnderwritingMessage : function _setAcceleratedUnderwritingMessage(aUwString) {

        if (_.isString(aUwString)) {
            return aUwString.replace('Underwriting', 'U/W');
        }

        return null;
    },

    /**
     * Set display flags for application status section
     * @returns {object} udpated application status object
     */
    _setApplicationStatusDisplayFlags:
        function _setApplicationStatusDisplayFlags(paidStatus, statusView) {
            this.application.showApplicationStatus = true;
            this.application.showIcons = true;

            if (statusView === 'Active') {
                this.application.showApplicationStatus = false;
            } else if (paidStatus) {
                if (paidStatus.withinDisplayPeriod) {
                    this.application.showIcons = false;
                } else if (paidStatus.afterDisplayPeriod) {
                    this.application.showApplicationStatus = false;
                }
            }

            return this.application;
        }

});

module.exports = policyApplicationStatusTracker;