/* global Backbone:false */
/**
 * Simple model used to retrieve data used for single sign-on to iPipeline forms.
 */
import config from '../../../config/config';

const PolicyStatementLinkModel = Backbone.Model.extend({

    errors : {
        policyIdRequired : 'policyId id property is required to retrieve link'
    },

    /**
     * Lifecycle method. Requires that the attrs param exists and has an "policyId" property.
     * @param attrs the attributes to set in the model
     * @param options the standard options object
     */
    initialize(attrs, options) {
        if (!attrs || !attrs.policyId) {
            throw new Error(this.errors.policyIdRequired);
        }
    },

    urlRoot() {
        const url = `${config.apiUrlRoot}policies/${this.get('policyId')}/statements`;
        return url;
    }

});

module.exports = PolicyStatementLinkModel;