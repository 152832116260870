/* global Marionette, $ , global Backbone */
var navbarViewTemplate = require('../templates/navbar.hbs');

// will autoload the partials in that directory
require('../partials');

// Load handlebars helper method to avoid unit test failure
require('../../../utils/hb-helpers');
const focusChannel   = Backbone.Radio.channel('focus');

var NavbarView = Marionette.ItemView.extend({

    template: navbarViewTemplate,

    ui: {
        homeLink                      : '#__OA_LINK__',
        logoutLink                    : '#logout_link',
        producerMaintainDelegatesLink : '#maintain_delegate_access',
        producerDelegateAccessLink    : '#delegate_access',
        securitySettingsLink          : '#security_settings',
        skipnav                       : '.skipnav',
        navbarContainer               : '#navbar-container'
    },

    events: {
        'click @ui.homeLink'                      : 'triggerNavEvent',
        'click @ui.logoutLink'                    : 'triggerLogoutEvent',
        'click @ui.producerMaintainDelegatesLink' : 'triggerMaintainDelegateAccessEvent',
        'click @ui.producerDelegateAccessLink'    : 'triggerProducerDelegateAccessEvent',
        'click @ui.securitySettingsLink'          : 'triggerSecuritySettingsEvent',
        'click @ui.skipnav'                       : 'skipnav',
    },

    modelEvents: {
        'change:userName'                   : 'userNameChanged',
        'change:hasProducerDelegateAccess'  : 'showProducerDelegateMenuOptions',
    },

    initialize: function () {

        this.focusNavBarChannel = Backbone.Radio.channel('focusNavBar');

        // Reset channel if it already have any events binded
        // In unit test we initialize module mutliple times event bind getting multiplied
        this.focusNavBarChannel.reset();

        /*
         * Register focus channel callback for onfocus.
         */
        this.listenTo(this.focusNavBarChannel, 'focusNavBar', this.onfocus);
        this.focusNavBarChannel = Backbone.Radio.channel('focusNavBar');
    },
   
    onfocus: function () {
        this.ui.navbarContainer.focus();
    },

    userNameChanged: function() {
        this.render();
    },

    showProducerDelegateMenuOptions : function showProducerDelegateMenuOptions () {
        this.render();
    },

    updateUser: function (model) {
        this.model = model;
    },

    triggerNavEvent: function (e) {
        e.preventDefault();

        var $clickTarget = $(e.currentTarget);
        var href = $clickTarget.attr('href');

        this.trigger('nav', href);
    },

    triggerLogoutEvent: function (e) {
        e.preventDefault();

        this.trigger('logout');
    },

    triggerMaintainDelegateAccessEvent: function(e) {
        e.preventDefault();

        this.trigger('maintainDelegateAccess');
    },

    triggerProducerDelegateAccessEvent: function triggerProducerDelegateAccessEvent (e) {
        e.preventDefault();

        this.trigger('startProducerDelegateAccess');
    },

    triggerSecuritySettingsEvent: function triggerSecuritySettingsEvent (e) {
        e.preventDefault();

        this.trigger('securitySettings');
    },

    skipnav :function()    {
        focusChannel.trigger('focus');
    }
});

module.exports = NavbarView;
