// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.hierarchy || (depth0 != null ? depth0.hierarchy : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hierarchy","hash":{},"data":data}) : helper)))
    + "-";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.producerName : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <div class=\"well\">\r\n            <h3 class=\"clearfix\">\r\n                <span class=\"policy-name smaller-75\">Name:</span>\r\n                <span class=\"text-uppercase oa-banner-blue\">"
    + container.escapeExpression(((helper = (helper = helpers.producerName || (depth0 != null ? depth0.producerName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"producerName","hash":{},"data":data}) : helper)))
    + "</span>\r\n            </h3>\r\n        </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"dataTables_wrapper form-inline no-footer hidden\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "policy-list-dt-wrapper\">\r\n        <div class=\"table-responsive\">\r\n            <table class=\"table table-striped table-bordered table-hover table-sortable\" id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "policy-list-datatable\"\r\n                   data-instruction-container=\"#"
    + container.escapeExpression(((helper = (helper = helpers.instructionTextId || (depth0 != null ? depth0.instructionTextId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"instructionTextId","hash":{},"data":data}) : helper)))
    + "\">\r\n            </table>\r\n        </div>\r\n\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","hash":{"id":(depth0 != null ? depth0.instructionTextId : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n\r\n        <p class=\"margin-top-10 small datatables-bottom-legends\">\r\n            <span class=\"badge badge-info\" title=\"Requirements Outstanding icon\">&nbsp;&nbsp;</span>  Requirements Outstanding\r\n        </p>\r\n\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<p class=\"hidden\">\r\n    <span class=\"oa-lake\">Last Refresh Date:</span>\r\n    <span id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hierarchy : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "last-refresh-date\"></span>\r\n</p>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showInfoBox : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n";
},"usePartial":true,"useData":true});
