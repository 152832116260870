/* global Backbone:false*/
/**
 * Producer delegates View, used to display delegaes information based on producer id.
 */
import ProducerDelegateModel from '../models/producer-delegate-m';
import template from '../partials/producer-delegates-list.hbs';

// Need to include this, otherwise unit tests blow up.
import '../../../utils/hb-helpers';

// load the global partials.
import '../../../partials';

const ProducerDelegateListView = Backbone.Marionette.ItemView.extend({
    template: template,

    ui: {
        producerDelegatesModal: '#modal-delegates'
    },
    
    errors: {
        systemError: 'The system has encountered an unexpected error.'
    },
    /**
     * Initialize the view. This function will create a model for the view if one was not
     * included in the options object.
     *
     * @param options
     */
    initialize(options) {

        // Create and set a new model if there isn't one passed in
        if (options) {
            this.model = new ProducerDelegateModel({ id: options.id });
        }

        this.model.fetch();
        this.listenTo(this.model, 'sync', this.render);
        this.listenTo(this.model, 'sync', this.show);
    },
    onBeforeShow() {

        this.listenTo(this.model, 'error', function () {
            this.trigger('serverMessage', this.errors.systemError, 'warning');
        });
    },

    show() {

        this.ui.producerDelegatesModal.modal('show');
    }
});

module.exports = ProducerDelegateListView;
