/* global Backbone:false */
/**
 * Collection for policy summaries.
 * 
 * This collection requires a policyNumber in order to retrieve the information. In the future,
 * it could take a variety of parameters to build the proper URL to retrieve data.
 */

import PolicySummaryModel from '../models/policy-summary-m';

const PolicyModelCollection = Backbone.Collection.extend({
    
    // the model type this collection contains
    model: PolicySummaryModel,

    config : require('../../../config/config'),
    
    errors: {
        policyNumberRequired: 'pending-policy-result-m requires a policyNumber in the options'
    },

    /**
     * URL used to fetch the data. Uses the policyNumber property, which is a required option to
     * initialize the collection.
     * 
     * @returns {string}
     */
    url () {
        return this.config.apiUrlRoot + 'policies?' +
            'policyNumber=' + this.policyNumber;
    },

    /**
     * Initialize the collection.
     * 
     * @param collection the collection to initialize with. Can be null.
     * @param options the options object. Must contain a 'policyNumber' property
     */
    initialize (collection, options) {
        
        if (options && options.policyNumber) {
            this.policyNumber = options.policyNumber;

        } else {
            throw new Error(this.errors.policyNumberRequired);
        }
    }
});

module.exports = PolicyModelCollection;
