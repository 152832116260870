// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <li class=\"qualifying-fyc-comparison\">\r\n        <i class=\"fa-li fa fa-arrow-right\"></i>\r\n        <strong id=\"apr-remaining-fyc\">\r\n            <span class=\"oa-lake\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.remainingQualifyingFyc : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n        </strong> Qualifying FYC needed to reach goal of\r\n        <strong id=\"apr-required-fyc\" >\r\n            <span class=\"oa-pumpkin\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.requiredQualifyingFyc : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n        </strong>\r\n    </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "oa-banner-red";
},"5":function(container,depth0,helpers,partials,data) {
    return "oa-lake";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = container.invokePartial(partials["pc-apr-status-bullet"],depth0,{"name":"pc-apr-status-bullet","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showComparisonBullet : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n<li class=\"actual-qualifying-fyc\" >\r\n<i class=\"fa-li fa fa-arrow-right\"></i>\r\n    <strong>\r\n        <span id=\"apr-qualifying-fyc\" class=\""
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.actualAdjustedYtdFycNumber : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\r\n            "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.actualAdjustedYtdFyc : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n        </span>\r\n    </strong> actual Qualifying FYC\r\n</li>\r\n\r\n<li class=\"margin-top-10\">\r\n    <a href=\"https://oasf.my.salesforce.com/sfc/p/50000000bbUu/a/2J000000NJkX/8sy4o4xiUofxl4uDDLfMTvY8jNYYAPyxTX3h1UFyANE\" target=\"_blank\" rel=\"noopener noreferrer\">More Info</a> <i class=\"fa gray-80 fa-file-pdf-o\" ></i>\r\n</li>\r\n";
},"usePartial":true,"useData":true});
