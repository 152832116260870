/* global Backbone:false */
var config = require('../../../config/config');

var ResourceCenterDataTableViewModel = Backbone.Model.extend({
    defaults: {
        col: 1,
        dir: 'asc',
        length: 25,
        start: 0,
    },

  /**
   * Build url for datatable to fetch data
   *
   * @return {string} url
   */
    urlRoot: function () {
        return config.apiBase + 'resourcecenter';
    },

  /**
   * Life cycle function
   * Ensure that numeric attrs are set as Number.
   */
    initialize: function initialize(attrs, options) {
        if (attrs) {
            this._setNumber('col', attrs.col);
            this._setNumber('dir', attrs.dir);
            this._setNumber('length', attrs.length);
            this._setNumber('start', attrs.start);
        }
    },

    _setNumber: function _setNumber(attr, value) {
        var numberValue = this.defaults[attr];
        if (value && !isNaN(value)) {
            numberValue = Number(value);
        }
        this.set(attr, numberValue);
    },
});

module.exports = ResourceCenterDataTableViewModel;