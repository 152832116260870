// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<strong>";
},"3":function(container,depth0,helpers,partials,data) {
    return "</strong>";
},"5":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<p>Total file size:\r\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fileErrorText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3((helpers.bytesToMegabytes || (depth0 && depth0.bytesToMegabytes) || alias2).call(alias1,(depth0 != null ? depth0.totalFileSizeInBytes : depth0),{"name":"bytesToMegabytes","hash":{},"data":data}))
    + " MB"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fileErrorText : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " of "
    + alias3(((helper = (helper = helpers.maxFileSizeInMegabytes || (depth0 != null ? depth0.maxFileSizeInMegabytes : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"maxFileSizeInMegabytes","hash":{},"data":data}) : helper)))
    + " MB\r\n</p>\r\n<div id=\"service-file-error\" class=\"help-block"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.serviceFileErrorText : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias3(((helper = (helper = helpers.serviceFileErrorText || (depth0 != null ? depth0.serviceFileErrorText : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"serviceFileErrorText","hash":{},"data":data}) : helper)))
    + "</div>";
},"useData":true});
