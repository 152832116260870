/* global Backbone:false */
/**
 * Manages the view of the error message(s) related to the comments section
 * for the Requirements Submission form.
 */
import template                           from '../templates/comments-error-t.hbs';
import RequirementSubmissionFormViewModel from '../viewModels/requirement-submission-form-vm';

const CommentsErrorView = Backbone.Marionette.ItemView.extend({

    errors   : {
        invalidOptions : 'Options passed to CommentsErrorView ' +
                         'must contain either a policyId or a model'
    },

    template : template,

    initialize (options) {

        if (!options.model && !options.policyId) {
            throw new Error(this.errors.invalidOptions);

        } else if (options.model) {
            this.model = options.model;

        } else {
            this.model = new RequirementSubmissionFormViewModel({
                policyId : options.policyId
            });
        }

        this.listenTo(this.model, 'change:commentsErrorText', this.render);
    }
});

module.exports = CommentsErrorView;