/* global Backbone:false */
/**
 * Simple error page view to display an unexpected/unhandled error.
 * 
 * This view can display message text sent in the stateObj.message property, or if that is null
 * it will use a default.
 * 
 * Future possibilities:
 *   - Display stack trace DebugModule is in debug
 *   - variations for the alert type (could be info if in maintenance, for example)
 *   
 * Created by jbell on 5/12/16.
 */

var config        = require('../../../config/config');
var errorHelper   = require('../../../utils/error-helper');
var errorTemplate = require('../templates/error-t.hbs');
var utils         = require('../../../utils/utils');

require('../../../partials');

var ErrorPageView = Backbone.Marionette.ItemView.extend({

    template: errorTemplate,
    
    errors: {
        defaultError: 'An unexpected error occurred.'
    },

    /**
     * Initialize the view. If a stateObj.message property exists in the options param, it will
     * use that value for the displayed error message. Otherwise, it will use the value in
     * errors.defaultError.
     * 
     * @param options
     */
    initialize: function (options) {
        var alert = null;
        if (options && options.stateObj) {
            if (options.stateObj.message && options.stateObj.message !== '') {
                alert = errorHelper.createAlert(options.stateObj.message, 'warning');
            } else if(options.stateObj.status && Number(options.stateObj.status) === 403) {
                alert = errorHelper.createAlert(
                    config.errorMessages.noPageFound, 'warning'
                );
            } 
        }
        
        if(!alert) {
            alert = errorHelper.createAlert(this.errors.defaultError, 'warning');
        }

        this.model = new Backbone.Model({ alertMessage: alert });
    },

    /**
     * When rendering, remove the wrapping div that Backbone adds.
     */
    onRender: function() {

        // remove the wrapping div to match design
        utils.unwrapView(this);
    }
});

module.exports = ErrorPageView;
