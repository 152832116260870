/* global $:false, Backbone:false, _:false */

/**
* Functions to read and write checkpoint data to the URL hash.
*
* @module modules/checkpoint/checkpointModule
*/
var checkpointUtils = {

    errors: {
        stateObjType:
            'writeCheckpoint(stateObj) parameter must be a plain JS object',
        bbHistoryNotStarted:
            'writeCheckpoint failed because Backbone.history is not started',
        jqueryDeparamMissing:
            'readCheckpoint failed because $.deparam plugin is not loaded'
    },

    /**
    * Encode an object into a querystring and insert it into the URL hash.
    *
    * @param {Object} [stateObj] - Obj to be encoded into the URL (optional). If no stateObj
    *        is provided, the state data is cleared in the URL. Must be a plain JS object--
    *        not an Array, String, Date, or some other built-in object. However, the object
    *        can contain nested objects, arrays, dates, etc.
    * @param {boolean} [replace] - (optional, defaults to false) If true, Backbone.history.navigate
    *       will overwrite the current hash. Useful for keeping a page's state, but not keeping
    *       history of state change (paging / sorting a result set, for example). Note: this param
    *       is simply a passthrough (as part of an options object) to the navigate call. If we add
    *       more, we should simply pass a navigateOptions object.
    *
    * @throws Will throw an error if the argument is not a plain JS object or undefined.
    * @throws Will throw an error if Backbone.history is not started.
    */
    writeCheckpoint: function (stateObj, replace) {
        var newHash = '';
        var encodedState;
        var pageId;
        var replaceHash = (replace === true);
        var subpage;

        // stateObj must either be a plain JS object or undefined
        if (typeof stateObj !== 'undefined'
            && !$.isPlainObject(stateObj)) {
            throw new Error (this.errors.stateObjType);
        }

        // Backbone.history must already be started
        if (!Backbone.History.started) {
            throw new Error (this.errors.bbHistoryNotStarted);
        }

        // get everything before the '?' and before any '/'
        pageId = this.getPageIdFromHash(document.location.hash); 
        
        // get the subpage, if it exists.
        subpage = this._getSubpageFromHash(document.location.hash);

        // Undefined stateObj will clear the state data from the hash
        if (typeof stateObj !== 'undefined') {
            // grab all of the nodes except for "subpage" to create newHash
            encodedState = $.param(_.omit(stateObj, ['subpages']));
            newHash = (encodedState) ? '?' + encodedState : '';
            
            // treat the subpage node separately if it exists
            // and override the subpage from the hash.
            if (stateObj.hasOwnProperty('subpages')) {
                if (stateObj.subpages.length) {
                    _.each(stateObj.subpages, function (page, index) {

                        //if 0
                        if (!index) {
                            subpage = '/'+page +'/';
                        } else {
                            subpage += page +'/';
                        }
                            
                    });
                } else {
                    subpage = '';
                }
            }
        }

        // Write the new hash
        Backbone.history.navigate(pageId + subpage + newHash, {replace: replaceHash});
    },

    /**
    * Read the encoded state in the URL hash and return it as a JS object.
    *
    * @throws Will throw an error if the jQuery.deparam plugin is not loaded.
    * @returns {object|undefined} state object decoded from the URL hash or undefined if no
    *          checkpoint data exists in URL hash
    */
    readCheckpoint: function () {
        var fullHash = document.location.hash;
        var dataPart = fullHash.split('?')[1];

        // Ensure the $.deparam plugin is loaded
        if (!$.deparam) {
            throw new Error (this.errors.jqueryDeparamMissing);
        }

        if (typeof dataPart === 'undefined') {
            return undefined;
        }

        // $.deparam true argument = coerce the values into datatypes rather than leaving
        // everything as strings.
        return $.deparam(dataPart, true);
    },

    /**
     * getPageIdFromHash parses the document.location.hash
     * to return the pageId part of the URL.
     * 
     * Example: '#foo/sub/?bar=1' would yield '#foo'
     * 
     * Example: '#foo/?bar=1' would yield '#foo/"
     * 
     * @param hash
     * @returns {*}
     */
    getPageIdFromHash : function(hash) {
        var preQueryString = hash.split('?')[0];

        // Return a trailing slash if it
        // at the end of the preQueryString
        // since this was the behavior prior
        // to subpage being implemented.

        if (preQueryString.indexOf('/') === preQueryString.length - 1) {
            return preQueryString;
        }
        
        return preQueryString.split('/')[0];
    },

    /**
     * Return the 
     * @param hash
     * @returns {string} the subPage or empty string if the hash does not have a subpage element
     * @private
     */
    _getSubpageFromHash : function(hash) {
        var retVal         = '';
        var preQueryString = hash.split('?')[0];
        var subPage        = preQueryString.split('/')[1];
        if (subPage) {
            retVal = '/' + subPage + '/';
        }
        return retVal;
    }

};

module.exports = checkpointUtils;
