// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.isActivePremiumPayingFDIA),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "        <div id=\"additional-premium\">\r\n            <h2 class=\"header\">Premium Received</h2>\r\n            <div class=\"table-responsive\">\r\n                <table class=\"table table-striped table-bordered table-hover\">\r\n                    <thead>\r\n                        <tr>\r\n                            <th>Premium Received</th>\r\n                            <th>Premium Amount</th>\r\n                            <th>Income Payment Purchased</th>\r\n                            <th>Taxable Amount</th>\r\n                        </tr>\r\n                    </thead>\r\n                    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                    </tbody>\r\n                </table>\r\n            </div>\r\n            <!-- /.table-responsive -->        \r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","data":data,"blockParams":blockParams,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                            <tr>\r\n                                <td>"
    + alias3((helpers.dateFormat || (depth0 && depth0.dateFormat) || alias2).call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.paymentDate : stack1),{"name":"dateFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\r\n                                <td>"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.paymentAmount : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\r\n                                <td>"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.incomePaymentPurchased : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\r\n                                <td>"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.taxableAmount : stack1),{"name":"currencyFormat","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\r\n                            </tr>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"additional-premium-unavailable\">\r\n        <h2 class=\"header\">Premium Received</h2>\r\n        <div class=\"bigger-115\">        \r\n            <strong>Section unavailable</strong>\r\n        </div>\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "<!-- start of Premium Received -->\r\n"
    + ((stack1 = (helpers.ifAvailable || (depth0 && depth0.ifAvailable) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"ifAvailable","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.program(5, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "<!-- end of Premium Received -->";
},"usePartial":true,"useData":true,"useBlockParams":true});
