/* global Marionette */
/**
 *
 * Created by Jason.Bell on 3/9/2015.
 */
var page1bTemplate = require('../templates/page1b-t.hbs');


var Page1bView = Marionette.ItemView.extend({
    template: page1bTemplate
});

module.exports = Page1bView;
