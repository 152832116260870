/* global _:false, Backbone:false, Marionette:false */
/**
 * Module to handle some of the policy search related functions, to be reused in pages.
 * 
 * Created by jbell on 7/6/16.
 */

var ClientNameSearchResultsView    = require(
    '../../pages/policy/views/client-name-search-results-v'
);
var PolicySummaryCollection = require(
    '../../pages/policy/collections/policy-summary-c'
);
var ProducerSearchResultsView  = require(
    '../../pages/policy/views/producer-search-results-v'
);
var spinnerChannel           = Backbone.Radio.channel('spinner');

var PolicySearchModule = Marionette.Object.extend({

    errors: {
        noClients               : 'No clients found that match "<%= searchTerm %>."',
        noPolicies              : 'No policy found that matches "<%= searchTerm %>."',
        noProducersNameSearch   : 'No producers found that match "<%= searchTerm %>."',
        systemError             : 'The system has encountered an unexpected error.',
        noProducersNumberSearch : 'No producer found that matches “<%= searchTerm %>.”  '+
                                    'Producer number must be active and the producer must be a '+
                                    'registered user of OneSource Online.'
    },

    /**
     * Clean up listeners when destroying this object.
     */
    onBeforeDestroy: function() {
        this.stopListening();
    },

    /**
     * Perform a search for a client name - ie, create the ClientNameSearchResultsView, passing in
     * the searchTerm param allowing the results view to handle the actual calls
     * to the server for data (including pagination and sorting).
     *
     * @param searchTerm the text string that the user is searching for
     */
    performClientNameSearch: function(searchTerm, options) {

        // Create a results view, with the searchTerm as an option prop
        var resultsView = new ClientNameSearchResultsView(
            _.extend({searchTerm : searchTerm}, options)
        );

        this._attachEventsToResultsView(resultsView, this.errors.noClients);
    },

    /**
     * Handle a search for a policy number
     *
     * @param {string} [policyNumber] the text string the user is searching for
     */
    performPolicyNumberSearch: function(policyNumber) {

        spinnerChannel.trigger('show', {
            viewScope : this,
            position  : 'fixed'
        });

        var policies = new PolicySummaryCollection(null, {
            policyNumber: policyNumber
        });

        this.listenTo(policies, 'sync', this._handlePolicyNumberSearchResponse);

        this.listenTo(policies, 'error', this._handlePolicyNumberSearchError);

        // fetch the policyId(s)
        policies.fetch();
    },

    /**
     * Perform a search for a producer name - ie, create the ProducerNameSearchResultsView,
     * passing in the searchTerm param allowing the results view to handle the actual calls
     * to the server for data (including pagination and sorting).
     *
     * @param {string} searchTerm the text for which to search
     * @param {Object} options
     */
    performProducerNameSearch: function(searchTerm, options) {
        var resultsView = new ProducerSearchResultsView(
            _.extend({searchTerm : searchTerm, searchType: 'name'}, options)
        );

        this._attachEventsToResultsView(resultsView, this.errors.noProducersNameSearch);
    },

    /**
     * Perform a search for producer number 
     * @param  {string} searchTerm the text input to perform search
     * @param  {object} options    
     */
    performProducerNumberSearch : function (searchTerm, options) {
        var resultsView = new ProducerSearchResultsView(
            _.extend({searchTerm : searchTerm, searchType: 'number'}, options)
        );

        this._attachEventsToResultsView(resultsView, this.errors.noProducersNumberSearch);
    },

    /**
     * Attach event listeners to the resultsView
     *
     * @param {Object} resultsView The Marionette view to attach the listeners
     * @param {string} noResultsMsg The message used for a "noResults" event
     * @private
     */
    _attachEventsToResultsView : function(resultsView, noResultsMsg) {

        // results view triggers 'tableStateChange' on paging, sorting, etc
        this.listenTo(resultsView, 'tableStateChange', this._handleResultStateChange);

        this.listenTo(resultsView, 'error', function () {
            this.trigger('serverMessage', this.errors.systemError, 'warning');
        });

        this.listenTo(resultsView, 'noResults', function () {
            this.trigger('serverMessage', noResultsMsg, 'info');
        });

        this.trigger('showResultsView', resultsView);
        
    },

    /**
     * Handle errors from the policy number search
     * @param collection the collection, which should have 0 models
     * @param response the server response
     * @private
     */
    _handlePolicyNumberSearchError: function(collection, response) {

        spinnerChannel.trigger('hide', this);

        // display error for 404 and 500. Let others go to the global handler.
        if (response) {
            if (response.status === 404) {
                this.trigger('serverMessage', this.errors.noPolicies, 'info');
            } else if (response.status === 500) {
                this.trigger('serverMessage', this.errors.systemError, 'warning');
            }
        }
    },

    /**
     * Handle the response from the policy number search. There could be 0, 1, or more than 1 items
     * in the collection.
     * 0 - no policy found for the given policyNumber, display message
     * 1 - exactly 1 result, display policy
     * 2 - multiple results, but may all be for the same policy. Need to check the IDs and then:
     *     if truly 1 result, display policy
     *     otherwise, display message
     *
     * @param policyCol the collection
     * @param response the response
     * @param options options to the request
     * @private
     */
    _handlePolicyNumberSearchResponse: function(policyCol, response, options) {
        var currentId = null;
        var i         = 0;
        var policyId  = null;

        spinnerChannel.trigger('hide', this);

        // check the results, and go to policy details page
        if (policyCol.length === 0) {
            this.trigger('serverMessage', this.errors.noPolicies, 'info');

        } else if (policyCol.length === 1) {

            // exactly one result. Get the id and go!
            policyId = policyCol.at(0).get('policyId');
            this.trigger('policyIdFound', policyId);

        } else {
            // more than one result. find out if all results are for same policyId.

            do {
                currentId = policyCol.at(i).get('policyId');

                if (!policyId) {

                    // first result.
                    policyId = currentId;

                } else if (policyId !== currentId) {

                    // we've found a different ID, so this is not unique
                    policyId = null;
                    break;
                }
                i++;

            } while (i < policyCol.length);

            if (policyId) {
                // we have one!
                this.trigger('policyIdFound', policyId);

            } else {
                // policy number is not unique, so some kind of error...
                this.trigger('serverMessage', this.errors.noPolicies, 'info');
            }
        }
    },

    /**
     * When the underlying datatable changes state (paging, sorting, etc), trigger a message to the
     * parent page view object.
     * 
     * @param changedState the new state
     * @private
     */
    _handleResultStateChange: function(changedState) {
        this.trigger('resultStateChange', changedState);
    }
});

module.exports = PolicySearchModule;