/* global require:false, Backbone:false, $:false, _:false */
/**
 * Pending Policy Detail View.
 */

// Policy Detail template partials
import '../partials';

// Global template partials (message-alert and table-responsive-instruction)
import '../../../partials';
import '../../../utils/hb-helpers';

import config from '../../../config/config';
import errorHelper from '../../../utils/error-helper';
import PolicyDetailModel from '../models/policy-detail-m';
import template from '../templates/policy-detail-t.hbs';
import utils from '../../../utils/utils';
import utilsFormatting from '../../../utils/utils-formatting';

import PolicyStatementLinkView from './policy-statement-link-v';
import PolicyExplanationBenefitsView from './policy-explanation-benefits-v';

import chartutils from '../../../utils/chart-utils';

import TransactionHistoryDataTableView from './transaction-history-datatable-v';

const analyticsChannel = Backbone.Radio.channel('analytics');
const errorChannel = Backbone.Radio.channel('error');
const spinnerChannel = Backbone.Radio.channel('spinner');

const PolicyDetailView = Backbone.Marionette.LayoutView.extend({

    template: template,

    behaviors: {
        jumpLinks: {},
        smartScrollTables: {}
    },

    ui: {
        policyStatusHelpTextIcon: '#policy-status-help-text-icon',
        policyStatusHelpTextModal: '#policy-status-help-text-modal',

        //policy requirement tables
        neededForUnderwritingRequirementsTable: 'table#neededForUnderwriting',
        obtainAtDeliveryRequirementsTable: 'table#obtainAtDelivery',
        receivedRequirementsTable: 'table#received',

        //iPipeline PolicyHS SSO elements
        iPipelinePolicyHSLink: '#ipipeline-policyhs-link',
        iPipelinePolicyHSForm: '#ipipeline-policyhs-form',
        samlResponseInput: '#ipipeline-policyhs-form input[name="SAMLResponse"]',
        relayStateURLInput: '#ipipeline-policyhs-form input[name="RelayState"]',

        //iPipeline Inforce Illustration SSO elements
        iPipelineInforceIllustrationLink: '#ipipeline-InforceIllustration-link',
        iPipelineInforceIllustrationForm: '#ipipeline-InforceIllustration-form',
        inforceIllustrationsamlResponseInput
            : '#ipipeline-InforceIllustration-form input[name="SAMLResponse"]',
        inforceIllustrationrelayStateURLInput
            : '#ipipeline-InforceIllustration-form input[name="RelayState"]',
        coverageViewBenefitsButton: '.coverage-view-benefits-button',
        assetClassButton: '.asset-class-button',
        transactionHistoryTab: '#transactionHistoryTab',
        chart: '#chart_container',
        currentElectionsChart: '#current_elections_chart_container',
        currentElectionsTab: '#currentElectionsTab',
        toggleall: '.toggleall'
    },

    events: {
        'click @ui.policyStatusHelpTextIcon': 'showHelpTextModal',
        'click @ui.iPipelinePolicyHSLink': '_fetchiPipelineSSOToken',
        'click @ui.iPipelineInforceIllustrationLink'
            : '_fetchiPipelineSSOTokenForInforceIllustration',
        'click @ui.coverageViewBenefitsButton': 'showCoverageViewBenefits',
        'click @ui.assetClassButton': 'showAssetClass',
        'click @ui.transactionHistoryTab': '_showTransactionHistoryTab',
        'click @ui.toggleall': 'toggleAllAssetClass',
        'click @ui.currentElectionsTab': 'currentElectionsTabClick'
    },

    errors: {
        policyNotFoundMessage: 'The policy you requested was not found.',
        policyNumberMissing: 'No Policy Number Specified',
        serverError: 'An error occurred while processing this request.',
        iPipelinePolicyHSError: 'Unable to connect to Online Service Request at this time.',
        iPipelinePolicyInfoceError: 'Unable to connect to Inforce Illustration at this time.'
    },

    initialize(options) {

        let caseId;
        let policyId;

        if (options && options.stateObj && options.stateObj.policyId) {
            policyId = options.stateObj.policyId;
        }

        if (options && options.stateObj && options.stateObj.caseId) {
            caseId = options.stateObj.caseId;
        }

        // Add gtmTrackViewEvent so that Google Tag Manager
        // will hold off on tracking the new view until this event.
        this.gtmTrackViewEvent = 'dataFetched';

        // This code is for a future optimization that makes this view more easily
        // testable by allowing an existing model to be passed in as an option
        // instead of the view initializer always fetching data and creating a new
        // model object. --RKC 09/11/2017
        // if (options.model) {
        //     // the model was provided, but needs the id property
        //     this.model.set('id', policyId);
        //     return true;
        // }

        if (!options.model && (policyId || caseId)) {

            // if both policyId and caseId are present, model will fetch with policyId
            this.model = new PolicyDetailModel({
                id: policyId,
                caseId: caseId
            });
        } else {

            //show error message as policy number or case Id is missing
            errorChannel.trigger('showErrorPage', this.errors.policyNumberMissing);

            //destroying view to prevent calling render method
            //an show the error page which triggered above
            this.destroy();

            return false;
        }

        // If an error message is added, re-render the view so that the error will appear
        this.listenTo(this.model, 'change:policyHSErrorMessage', this.render);
    },

    showCoverageViewBenefits(e) {
        e.preventDefault();
        var parentTR = e.currentTarget.parentElement.parentElement;
        var arrow = e.currentTarget.parentElement.getElementsByClassName('fa')[0];
        if ($(e.currentTarget).find('span').text() === 'View Benefits') {
            $(e.currentTarget).find('span').text('Hide Benefits');
            $(e.currentTarget).attr('aria-expanded', 'true');
            parentTR.classList.add('table-toggle-highlight-header');
            $('.' + e.currentTarget.id).addClass('table-toggle-highlight');
            $('.' + e.currentTarget.id).last().addClass('table-toggle-highlight-footer');
            $(arrow).addClass('fa-angle-up');
            $(arrow).removeClass('fa-angle-down');
        } else {
            $(e.currentTarget).find('span').text('View Benefits');
            $(e.currentTarget).attr('aria-expanded', 'false');
            parentTR.classList.remove('table-toggle-highlight-header');
            $('.' + e.currentTarget.id).removeClass('table-toggle-highlight');
            $('.' + e.currentTarget.id).last().removeClass('table-toggle-highlight-footer');
            $(arrow).addClass('fa-angle-down');
            $(arrow).removeClass('fa-angle-up');
        }
        $('.' + e.currentTarget.id).toggle();
    },
    toggleAllAssetClass(e) {
        e.preventDefault();
        var isDownToggle = true;
        if($(e.currentTarget).find('i').hasClass('fa-chevron-down')){
            isDownToggle = false;
            $(e.currentTarget).find('i').removeClass('fa-chevron-down');
            $(e.currentTarget).find('i').addClass('fa-chevron-up');
        }else{
            $(e.currentTarget).find('i').addClass('fa-chevron-down');
            $(e.currentTarget).find('i').removeClass('fa-chevron-up');
        }
        if(isDownToggle){
            $(e.currentTarget).attr('aria-label','Toggle Up');
        }else{
            $(e.currentTarget).attr('aria-label', 'Toggle Down');
        }
        var parentElem = $(e.currentTarget).parent().parent().parent().parent();
        $(parentElem).find('.asset-class-button').each(function () {
            if($(this).attr('aria-expanded') === (isDownToggle+'')){
                $(this).trigger('click');
            }
        });
    },
    currentElectionsTabClick(e){
        e.preventDefault();
        var _this = this;
        setTimeout(function(){
            _this.activateCurrentElectionsChart();
        },100);
    },
    showAssetClass(e) {
        e.preventDefault();
        var parentRow = $(e.currentTarget).parent().parent();
        var arrow = $(e.currentTarget).parent().find('.fa');
        if ($(e.currentTarget).attr('aria-expanded') === 'true') {
            $(e.currentTarget).attr('aria-expanded', 'false');
            $(parentRow).parent().find('.' + $(e.currentTarget).attr('id')).hide();
            $(parentRow).removeClass('table-toggle-highlight-header');
            $(arrow).removeClass('fa-angle-up');
            $(arrow).addClass('fa-angle-down');
        } else {
            $(e.currentTarget).attr('aria-expanded', 'true');
            $(parentRow).parent().find('.' + $(e.currentTarget).attr('id')).show();
            $(parentRow).addClass('table-toggle-highlight-header');
            $(arrow).addClass('fa-angle-up');
            $(arrow).removeClass('fa-angle-down');
        }

    },

    onBeforeShow() {
        const _this = this;
        this.listenTo(this.model, 'sync', this.render);

        this.listenTo(this.model, 'error', this._showAlertMessage);

        //show wait indicator
        spinnerChannel.trigger('show', {
            viewScope: this,
            position: 'fixed'
        });

        this.model.fetch({

            //to hide wait indicator
            success: function () {
                spinnerChannel.trigger('hide', _this);

                _this._prepPolicyHighlightsConfig(_this.model);

                // set flag to display underwriter and files under applcation status section
                // This cannot be moved to application parsers 
                // because model object will be created only after parse
                _this.model.setUnderwriterAndFilesAvailabilityFlag();
            }
        });
    },

    _showAlertMessage(model, response) {
        let errorMessage;
        spinnerChannel.trigger('hide', this);

        if (response) {

            if (response.status === 404) {
                this.model.set(
                    'alertMessage',
                    errorHelper.createAlert(this.errors.policyNotFoundMessage, 'info')
                );
                this.render();

            } else if (response.status === 500 || response.status === 400) {
                this.model.set(
                    'alertMessage',
                    errorHelper.createAlert(this.errors.serverError, 'warning')
                );
                this.render();
            }

            errorMessage = JSON.stringify(_.pick(response, [
                'status',
                'statusText',
                'url',
                'responseText'
            ]));

            // track server exception error
            analyticsChannel.trigger('trackException', {
                message: errorMessage,
                fatal: false
            });
        }
    },

    onRender() {

        //bind DataTable to each Tables under Policy Requirements section
        this._bindRequirementsTablesWithDataTable();

        // If the subpage is set, scroll to the section
        if (this.options && this.options.stateObj && this.options.stateObj.subpages) {
            utils.scrollTo($('#' + this.options.stateObj.subpages[0]));
        }

        this.buildChart();
        this.buildElectionsChart();

        // add policy-related data for analytics
        this._setAnalyticsData();
    },

    /**
     *  Lifecycle method
     */
    onDomRefresh() {

        // This method suppose to call from OnRender method. But for some reaseon
        // unit test get failed because onRender method missing full updated DOM tree
        // there we need to add a view to region
        this._showPolicyStatementLink();
        this._showPolicyExplanationLink();
    },

    onDestroy() {
        if (this.dataLayerValues && _.isObject(this.dataLayerValues)) {
            analyticsChannel.trigger('unsetDataLayerValue', this.dataLayerValues);
        }
        Backbone.$('.modal-backdrop.fade.in').remove();
    },

    /**
     * Show Help text modal
     * to display help information from WCM
     * @param {Object} e jQuery event object
     */
    showHelpTextModal(e) {
        e.preventDefault();

        var wcmHelpPath = '/wps/wcm/connect/indcontent/OSO/misc/policy-status-help';

        // Load the wcm content in the modal-content area
        this.ui.policyStatusHelpTextModal.modal('show').find('.modal-content').load(wcmHelpPath);
    },

    /**
     * Bind DataTable to each the each table of Policy Requirements 
     * Section
     * 
     */
    _bindRequirementsTablesWithDataTable() {

        //Policy requirements table binding with DataTable
        const _this = this;

        const dataTablesOptionsDefault = {

            //t stand for only contain table. no wrapper elements
            //https://datatables.net/reference/option/dom
            dom: 't',
            searching: false,
            paging: false,
            info: false,
            columns: [
                {
                    title: 'Insured',
                    data: 'insuredName',
                    className: 'insured-head',
                    orderable: false
                },
                {
                    title: 'Requirement',
                    data: 'requirementName',
                    className: 'requirement-head',
                    orderable: false
                },
                {
                    title: 'Status',
                    data: 'status',
                    className: 'status-head',
                    orderable: false
                },
                {
                    title: 'Date Created',
                    data: 'dateCreated',
                    className: 'ordered-head',
                    render: utils.dataTableFormatDate
                },
                {
                    title: 'Comments',
                    data: 'comments',
                    orderable: false
                }
            ],
            order: [

                //default sorting Date Created
                [3, 'desc']
            ]
        };

        //Needed to Pay Comp column to be added for Obtain At Delivery table
        const needToPayColumn = {
            title: 'Needed to Pay Comp',
            data: 'neededToPay',
            className: 'need-head',
            orderable: false
        };

        //Date Received column to be added for Received table
        var dateReceivedColumn = {
            title: 'Date Received',
            data: 'dateReceived',
            className: 'received-head',
            render: utils.dataTableFormatDate
        };

        let dataTablesOptions;
        let policyIdentificationLabel = 'Insured';
        let requirementsData;

        if (this.model.get('requirements')) {

            requirementsData = this.model.get('requirements');

            // set the "insuredName" column label to match Policy Identification header
            if (this.model.get('policyIdentification')) {
                policyIdentificationLabel = this.model.get('policyIdentification').label;
            }

            // Table : Need for underwriting 
            if (requirementsData.neededForUnderwriting) {
                dataTablesOptions = Backbone.$.extend(true, {}, dataTablesOptionsDefault);
                dataTablesOptions.columns[0].title = policyIdentificationLabel;
                this._bindEventsAndLoadData(
                    this.ui.neededForUnderwritingRequirementsTable.DataTable(dataTablesOptions),
                    requirementsData.neededForUnderwriting);
            }

            //Table : May Obtain at Delivery 
            if (requirementsData.obtainAtDelivery) {
                dataTablesOptions = Backbone.$.extend(true, {}, dataTablesOptionsDefault);

                //add column 'Needed to Pay Comp' at index 4
                dataTablesOptions.columns.splice(4, 0, needToPayColumn);

                //to highlight with green color 'Needed to Pay Comp' 
                //cell if value is 'Yes'
                dataTablesOptions.columnDefs = [{
                    targets: 4,
                    createdCell: _this._setNeedToPayCellContent
                }];

                dataTablesOptions.columns[0].title = policyIdentificationLabel;
                this._bindEventsAndLoadData(
                    this.ui.obtainAtDeliveryRequirementsTable.DataTable(dataTablesOptions),
                    requirementsData.obtainAtDelivery);
            }


            // Table : Received Requirements 
            if (requirementsData.received) {
                dataTablesOptions = Backbone.$.extend(true, {}, dataTablesOptionsDefault);

                //add column 'Date Received column' at index 4
                dataTablesOptions.columns.splice(4, 0, dateReceivedColumn);
                dataTablesOptions.order = [];
                dataTablesOptions.columns[0].title = policyIdentificationLabel;
                this._bindEventsAndLoadData(
                    this.ui.receivedRequirementsTable.DataTable(dataTablesOptions),
                    requirementsData.received);
            }

            dataTablesOptions = null;
        }

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));
    },

    /**
     * Bind "column-sizing" and "draw" events on the dataTable and then
     * load the data in a way that will trigger a "draw" event. Both
     * events are needed in order to use the "smart" scrolling message.
     * @param {object} dataTable
     * @param {Array} data
     * @private
     */
    _bindEventsAndLoadData(dataTable, data) {
        dataTable.on('column-sizing draw', _.bind(this._onDrawAndColumnResize, this)
        ).rows.add(data).draw();
    },

    /**
     * Get the DOM elements needed to call "smartScrollTable" in "utils.js"
     * using the DataTables settings for context.
     * @param {object} e jQuery event
     * @param {object} settings DataTables settings
     * @private
     */
    _onDrawAndColumnResize(e, settings) {
        const tableContainer = $(settings.nTable).closest('.table-responsive');
        const instructionDiv = this.$el.find($(settings.nTable).data('instruction-text'));

        if (tableContainer.length === 1 && instructionDiv.length === 1) {
            utils.setVisibilityOnSmartScrollMessage(tableContainer, instructionDiv);
        }
    },

    /**
     * Send policy data to analytics if it exists. The render method
     * on this view appears to be triggered twice for some reason.
     * Because of this, data is not sent to analytics unless there is
     * data to send.
     * @private
     */
    _setAnalyticsData() {
        this.dataLayerValues = {};
        const product = this.model.get('product');
        const policyStatus = this.model.get('policyStatus');

        if (product && product.productName) {
            this.dataLayerValues['Policy Product Type'] = product.productName;
        }

        if (policyStatus) {
            if (policyStatus.description) {
                this.dataLayerValues['Policy Status'] = policyStatus.description;
            }

            if (policyStatus.statusView) {
                this.dataLayerValues['Status View'] = policyStatus.statusView;
            }
        }

        if (this.model.has('policyId')) {
            this.dataLayerValues['Policy ID'] = this.model.get('policyId');
        } else if (this.model.has('caseId')) {
            this.dataLayerValues['Case ID'] = this.model.get('caseId');
        }

        // Currently googleTagMangerProvider.trackView method is
        // listening to this event to track page view along with `dataLayerValues`.
        this.trigger(this.gtmTrackViewEvent);
    },

    /**
     * Set text color to brown in 'Needed to Pay Comp'
     * cell of Policy Requirements Table based on value 'Yes'.
     * Uses the columns.render function from DataTables.
     * 
     * @param {DOM} td       TD element
     * @param {string} cellData value of cell
     * @param {[type]} rowData  Data source object / array for the whole row
     * @param {number} row      DataTables' internal index for the row
     * @param {number} col      DataTables' internal index for the column
     * @private
     */
    _setNeedToPayCellContent(td, cellData, rowData, row, col) {
        if (cellData === true) {
            Backbone.$(td)
                .html('<strong class="pending-brown">Yes</strong>');
        } else {
            Backbone.$(td)
                .text('No');
        }
    },

    /**
     * Method to show Policy statement link field in Policy identification section
     */
    _showPolicyStatementLink() {
        // Show policy statement link
        if (this.model.get('showPolicyStatementLink') && this.model.has('policyId')) {

            // add statement region since element only available after rendering view
            // This region is added in policy-identification partials
            this.regionManager.addRegion('policyStatementRegion', '#policy-statement-region');

            this.showChildView('policyStatementRegion', new PolicyStatementLinkView({
                policyId: this.model.get('policyId')
            }));
        }
    },

    /**
     * Method to show Transaction History tab in ‘Policy Financials’ section
     */
    _showTransactionHistoryTab() {
        this.regionManager.addRegion('transactionHistoryRegion'
            , '#transactionHistoryRegion');

        this.transactionHistory = new TransactionHistoryDataTableView({
            policyId: this.model.get('policyId'),
            showNoteForVariableProducts: this.model.get('showNoteForVariableProducts')
        });

        this.showChildView('transactionHistoryRegion'
            , this.transactionHistory);

        this.listenTo(
            this.transactionHistory,
            'error',
            this._showTransactionHistoryServerErrorMessage
        );
    },
    /**
    * Show server error messages for transaction history view
    * @private
    */
    _showTransactionHistoryServerErrorMessage:
        function _showTransactionHistoryServerErrorMessage(message, type) {

            if (!message) {
                message = this.errors.serverError;
            }

            if (!type) {
                type = 'warning';
            }

            this.transactionHistory.model.set(
                'alertMessage',
                errorHelper.createAlert(message, type)
            );
            this.transactionHistory.render();
        },

    /**
     * Method to show Policy Explanation benefits link
     */
    _showPolicyExplanationLink() {
        // Show policy Explanation link
        if (this.model.get('hasExplanationDetails') && this.model.has('policyId')) {

            // add ExplanationBefits region since element only available after rendering view
            // This region is added in policy-identification partials
            this.regionManager.addRegion('policyExplanationBefitsRegion',
                '#policy-explanation-region');

            this.showChildView('policyExplanationBefitsRegion', new PolicyExplanationBenefitsView({
                policyId: this.model.get('policyId')
            }));
        }
    },
    /**
    * Add a `policyHighlightsConfig` property to the model that contains data that controls
    * the appearance of the Policy Highlights section.
    *
    * Note that it takes a reference to the model as a parameter instead of modifying `this.model`
    * as a side effect. This makes the function much easier to test.
    *
    * @param {object} policyModel - a PolicyDetailModel object
    */
    _prepPolicyHighlightsConfig(policyModel) {
        const policyData = {
            coverage: policyModel.get('coverage'),
            policyValue: policyModel.get('policyValue')
        };
        const columnClasses = ['invalid', 'one-col', 'two-col', 'three-col', 'four-col'];
        let visibleColumns = 4;

        let policyHLConfig = {
            columnClass: 'four-col',
            show: {
                policyValues: true,  // Change these defaults to false when each column
                ltcValues: false, // is implemented. (And when all four are implemented,
                cashValues: false, // delete this comment.)
                rates: false
            }
        };

        // Should we show the Long Term Care Values column?
        if (policyData.coverage && policyData.coverage.coverageDetail &&
            policyData.coverage.coverageDetail.ltcCoverageDetail &&
            (policyData.coverage.coverageDetail.ltcCoverageDetail.ltcCurrentBenefitBalance ||
                policyData.coverage.coverageDetail.ltcCoverageDetail.ltcTotalWithdrawnAmount ||
                policyData.coverage.coverageDetail.ltcCoverageDetail.monthlyBenefitAmount)) {
            policyHLConfig.show.ltcValues = true;
        }

        // Should we show the Cash Withdrawal Values column?
        if (policyData.policyValue && (policyData.policyValue.penaltyFreeCashWithdrawalAmount ||
            policyData.policyValue.totalCashAmountWithdrawn ||
            policyData.policyValue.remainingCashFreeOutAmount)) {
            policyHLConfig.show.cashValues = true;
        }

        // Should we show the Rates column?
        if (policyData.policyValue && (policyData.policyValue.accumValueInterestRateCurrent ||
            policyData.policyValue.currentInterestRate ||
            policyData.policyValue.guaranteedInterestRate) ||
            (policyData.coverage && policyData.coverage.coverageDetail &&
                policyData.coverage.coverageDetail.ltcCoverageDetail &&
                policyData.coverage.coverageDetail.ltcCoverageDetail
                    .accumulatedValueLtcInterestRateCurrent)) {
            policyHLConfig.show.rates = true;
        }

        // Set the column class correctly based on how many columns we're going to show
        visibleColumns = _.filter(
            policyHLConfig.show,
            function (isShown) { return isShown; }
        ).length;
        policyHLConfig.columnClass = columnClasses[visibleColumns];

        policyModel.set('policyHighlightsConfig', policyHLConfig);
    },

    /**
     * Retrieve the iPipeline PolicyHS targetUrl and XML token from the service.
     * @param {Object} e jQuery event object
     */
    _fetchiPipelineSSOToken(e) {
        const isDisabled = Backbone.$(e.currentTarget).parent().hasClass('disabled');

        e.preventDefault();

        // if the parent is disabled, don't do anything else
        if (!isDisabled) {

            const phsUrl =
                config.apiUrlRoot +
                'sso/ipipeline/phs?policyNumber=' +
                this.model.get('policyNumber');

            // Using a simple jQuery getJSON() call because the desired behavior
            // is simple, and creating a separate view and model would be unnecessarily
            // complex, and also would be difficult to accomplish given the odd
            // monolithic way the policy detail page has been implemented.
            Backbone.$.getJSON(phsUrl)
                .done(_.bind(this._setupFormAndSubmit, this))
                .fail(_.bind(this._handleiPipelineSsoError, this));
        }
    },

    /**
    * Retrieve the iPipeline PolicyInforceIllustration targetUrl and XML token from the service.
    * @param {Object} e jQuery event object
    */
    _fetchiPipelineSSOTokenForInforceIllustration(e) {
        const isDisabled = Backbone.$(e.currentTarget).parent().hasClass('disabled');

        e.preventDefault();

        // if the parent is disabled, don't do anything else
        if (!isDisabled) {

            const phUrl =
                config.apiUrlRoot +
                'sso/ipipeline/igo?policyNumber=' +
                this.model.get('policyNumber');

            // Using a simple jQuery getJSON() call because the desired behavior
            // is simple, and creating a separate view and model would be unnecessarily
            // complex, and also would be difficult to accomplish given the odd
            // monolithic way the policy detail page has been implemented.
            Backbone.$.getJSON(phUrl)
                .done(_.bind(this._setupFormAndSubmitInforceIllustration, this))
                .fail(_.bind(this._handleiPipelineInforceSsoError, this));
        }
    },

    /**
     * Sets the action attribute of the form to the "targetUrl" returned from the service,
     * sets the values of hidden inputs, and then submits the form.
     * 
     * @param {object} data - ajax response data object
     * @param {string} textStatus - HTTP status
     * @param {object} jqXHR - jQuery XHR object
     */
    _setupFormAndSubmit(data, textStatus, jqXHR) {

        const targetURL = data.targetURL;
        const SAMLResponseData = data.SAMLResponse;
        const relayStateURL = data.RelayState;

        if (targetURL && SAMLResponseData && relayStateURL) {

            // Close target window if its already opened
            if (this.policyHSWindow) {
                this.policyHSWindow.close();
            }
            this.policyHSWindow = window.open('about:blank', 'policyhs');

            // set up the form
            this.ui.iPipelinePolicyHSForm.attr('action', targetURL);
            this.ui.relayStateURLInput.val(relayStateURL);
            this.ui.samlResponseInput.val(SAMLResponseData);

            this.ui.iPipelinePolicyHSForm.submit();
        }
    },

    /**
 * Sets the action attribute of the form to the "targetUrl" returned from the service,
 * sets the values of hidden inputs, and then submits the form.
 *
 * @param {object} data - ajax response data object
 * @param {string} textStatus - HTTP status
 * @param {object} jqXHR - jQuery XHR object
 */
    _setupFormAndSubmitInforceIllustration(data, textStatus, jqXHR) {

        const targetURL = data.targetURL;
        const SAMLResponseData = data.SAMLResponse;
        const relayStateURL = data.RelayState;

        if (targetURL && SAMLResponseData && relayStateURL) {

            // Close target window if its already opened
            if (this.policytInforceIllustrationWindow) {
                this.policytInforceIllustrationWindow.close();
            }
            this.policytInforceIllustrationWindow
                = window.open('about:blank', 'policyInforceIllustration');

            // set up the form
            this.ui.iPipelineInforceIllustrationForm.attr('action', targetURL);
            this.ui.inforceIllustrationrelayStateURLInput.val(relayStateURL);
            this.ui.inforceIllustrationsamlResponseInput.val(SAMLResponseData);


            this.ui.iPipelineInforceIllustrationForm.submit();
        }
    },

    /**
     * Used to handle errors received from the service while requesting the
     * iPipeline PolicyHS token.
     *
     * @param {object} jqXHR - The response from the service
     * @param {string} textStatus - Possible values: timeout, error, abort, parsererror
     * @param {string} errorThrown - text version of HTTP error, e.g. "Internal Server Error"
     */
    _handleiPipelineSsoError(jqXHR, textStatus, errorThrown) {
        if (jqXHR.status) {

            this.model.set('policyHSErrorMessage', this.errors.iPipelinePolicyHSError);

            analyticsChannel.trigger('trackException', {
                fatal: false,
                message: 'Received a ' + jqXHR.status + ' response from the SSO iPipeline ' +
                    'end point. Error message: ' + jqXHR.statusText
            });
        }
    },

    /**
     * Used to handle errors received from the service while requesting the
     * iPipeline PolicyInforce token.
     *
     * @param {object} jqXHR - The response from the service
     * @param {string} textStatus - Possible values: timeout, error, abort, parsererror
     * @param {string} errorThrown - text version of HTTP error, e.g. "Internal Server Error"
     */
    _handleiPipelineInforceSsoError(jqXHR, textStatus, errorThrown) {
        if (jqXHR.status) {

            this.model.set('policyInforceErrorMessage', this.errors.iPipelinePolicyInfoceError);

            analyticsChannel.trigger('trackException', {
                fatal: false,
                message: 'Received a ' + jqXHR.status + ' response from the SSO iPipeline ' +
                    'end point. Error message: ' + jqXHR.statusText
            });
        }
    },
    chartSeries: [],
    clickSlice: function (event) {
        $('#asset' + event.currentTarget.data + '_class').trigger('click');
    },
    currentElectionsClickSlice: function (event) {
        $('#currentElectionsAsset' + event.currentTarget.data + '_class').trigger('click');
    },
    // Builds the chart from data but does not actually
    // render it out.
    buildChart: function () {
        if (!this.model.get('isActive') 
        || !this.model.get('showInvestmentsForActive')
        || !this.model.get('investment') ) {
            return;
        }
        var i;
        var items;
        //console.log(this.model.get('investment'));
        if (this.model.get('investment')) {
            // builds the pie chart
            this.chartSeries = [];
            items = this.model.get('investment').assetCollection;
            i = 0;
            for (var key in items) {
                if (!items[key]) {
                    return;
                }
                this.chartSeries.push({
                    color: chartutils.radializeColors(items[key].color),
                    events: {
                        // This wires up the chart click events to
                        // the accordion for the expand and
                        // collapse events that are triggered.
                        // It's a normal event that passes an
                        // an event object
                        // and highchart puts the needed
                        // info in e.currentTarget.
                        click: this.clickSlice
                    },
                    name: key,
                    data: i,
                    y: parseFloat(items[key].totalPercent)
                });
                i++;
            }
            this.activateChart();
        }
    },
    buildElectionsChart: function () {
        if (!this.model.get('isActive')) {
            return;
        }
        var i;
        var items;
        //console.log(this.model.get('investment'));
        if (this.model.get('investment')) {
            // builds the pie chart
            this.chartElectionSeries = [];
            items = this.model.get('investment').currentElectionCollection;
            i = 0;
            for (var key in items) {
                if (!items[key]) {
                    return;
                }
                this.chartElectionSeries.push({
                    color: chartutils.radializeColors(items[key].color),
                    events: {
                        // This wires up the chart click events to
                        // the accordion for the expand and
                        // collapse events that are triggered.
                        // It's a normal event that passes an
                        // an event object
                        // and highchart puts the needed
                        // info in e.currentTarget.
                        click: this.currentElectionsClickSlice
                    },
                    name: key,
                    data: i,
                    y: parseFloat(items[key].totalElectedPercent)
                });
                i++;
            }
        }
    },
    activateChart: function () {
        if (!this.ui.chart.highcharts) {
            return;
        }
        this.ui.chart.highcharts({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: false
            },
            xAxis: {
                categories: null
            },
            legend: {
                verticalAlign: 'top'
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            tooltip: {
                hideDelay: 500,
                formatter: function () {
                    var percentage = utilsFormatting.formatNumberDecimals(this.y, 2) + '%';
                    return this.point.name + '<br /><b>' + percentage + '</b>';
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [
                {
                    type: 'pie',
                    data: this.chartSeries
                }
            ]
        });
        //this.chart = Highcharts.charts[Highcharts.charts.length - 1];
    },
    activateCurrentElectionsChart: function () {
        if (!this.ui.currentElectionsChart.highcharts) {
            return;
        }
        this.ui.currentElectionsChart.highcharts({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: false
            },
            xAxis: {
                categories: null
            },
            legend: {
                verticalAlign: 'top'
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            tooltip: {
                hideDelay: 500,
                formatter: function () {
                    var percentage = utilsFormatting.formatNumberDecimals(this.y, 2) + '%';
                    return this.point.name + '<br /><b>' + percentage + '</b>';
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [
                {
                    type: 'pie',
                    data: this.chartElectionSeries
                }
            ]
        });
        //this.chart = Highcharts.charts[Highcharts.charts.length - 1];
    }

});

module.exports = PolicyDetailView;
