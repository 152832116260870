/* global Backbone:false, _:false */
/**
 * Resource Center Search View to display the search result on searh of parameter
 */
import errorHelper from '../../../utils/error-helper';
import template from '../templates/resource-center-search-t.hbs';
// Need to include this, otherwise unit tests blow up.
import '../../../utils/hb-helpers';
// load the global partials.
import '../../../partials';
import ResourceCenterFormModel from '../viewModels/resource-center-search-form-vm';

var ResourceCenterFormView = Backbone.Marionette.ItemView.extend({
    template: template,

    ui: {
        popoverElements: '[data-toggle="popover"]',
        searchButton: '#resource-search-button',
        searchForm: '#resource-search-form',
        searchTerm: '#resource-search-term-input',
        searchOption: '[name="search-option"]',
        searchIcon: '#resource-search-icon',
        crossIcon: '#resource-cross-icon'
    },

    events: {
        'input @ui.searchTerm': '_enableResourceSearch',
        'submit @ui.searchForm': '_search',
        'click @ui.popoverElements': '_toggleInfoPopover',
        'blur @ui.popoverElements': '_hideInfoPopover',
        'mouseenter @ui.popoverElements': '_showInfoPopover',
        'mouseleave @ui.popoverElements': '_hideInfoPopover',
    },

    /**
     * Initialize the view. This function will create a model for the view if one was not
     * included in the options object.
     *
     * @param options
     */
    initialize(options) {
        // Create and set a new model if there isn't one passed in
        if (!options.model) {
            this.model = new ResourceCenterFormModel();
        }
    },

    /**
     * When rendering, bind the popover handler to the popover ui elements.
     */
    onRender() {
        // Binding popover event to all popover elements
        // Popover will be displayed on click, enter, hover events
        this.ui.popoverElements.popover({
            trigger: 'manual',
            placement: 'auto',
            container: 'body',
        });
        if (this.model.get('searchTerm').length >= 1) {
            this.ui.searchButton.attr('disabled', false);
            this.ui.searchIcon.css('visibility', 'hidden');
            this.ui.crossIcon.css('visibility', 'visible');
        } else {
            this.ui.searchButton.attr('disabled', true);
            this.ui.searchIcon.css('visibility', 'visible');
            this.ui.searchIcon.css('color', '#d8d9da');
            this.ui.crossIcon.css('visibility', 'hidden');
        }
    },

    _toggleInfoPopover(e) {
        this.ui.popoverElements.popover('toggle');
    },

    _showInfoPopover(e) {
        this.ui.popoverElements.popover('show');
    },

    _hideInfoPopover(e) {
        this.ui.popoverElements.popover('hide');
    },

    /**
     * Show a server message
     * @param message The text of the message
     * @param messageType The type of the message - 'process' or 'server'
     */
    showServerMessage(message, messageType) {
        const messageTemplate = _.template(message);
        const messageText = messageTemplate({
            searchItem: this.model.get('searchTerm'),
        });

        this.model.set(
            'alertMessage',
            errorHelper.createAlert(messageText, messageType)
        );
        this.render();
    },

    /**
     * Enable search button if input is not empty
     */
    _enableResourceSearch() {
        const searchText = this.ui.searchTerm.val();
        if (searchText.length >= 1) {
            this.ui.searchButton.attr('disabled', false);
            this.ui.searchIcon.css('visibility', 'visible');
            this.ui.searchIcon.css('color', '#687074');
        } else {
            this.ui.searchButton.attr('disabled', true);
            this.ui.crossIcon.css('visibility', 'hidden');
            this.model.set({
                searchTermErrorText: '',
                searchTerm: '',
                alertMessage: null
            });
            this.render();
            this.trigger('_searchResource', this.model.get('searchTerm'));
        }
    },

    /**
     * Validate the entire form and then perform the search if there are no errors.
     *
     * @param event The event that initiated the call to this function.
     * @private
     */
    _search(event) {
        // Don't want to just go submitting all willy-nilly.
        if (event) {
            event.preventDefault();
        }

        // Update the model and validate
        // error messages are available via this.model.validation
        const searchTerm = this.ui.searchTerm.val();
        if (this.model.get('searchTerm') !== searchTerm) { // search icon
            this._updateModel();
            const isValid = this.model.isValid();
            if (isValid) {
                this.trigger('_searchResource', this.model.get('searchTerm'));
            }
        } else {
            this.model.set({
                searchTerm: '',
                searchTermErrorText: '',
                alertMessage: null
            });
            this.trigger('_searchResource', this.model.get('searchTerm'));
        }
        // render to show/hide errors
        this.render();
    },

    /**
     * Update the model object, based on the UI
     *
     * @private
     */
    _updateModel() {
        const searchTerm = this.ui.searchTerm.val();
        // update the model with the proper form values, and clear any alert message
        this.model.set({
            alertMessage: null,
            searchTerm: searchTerm,
        });
    },
});

module.exports = ResourceCenterFormView;
