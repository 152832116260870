// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<br><span class=\"smaller-65\">As of "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.asOfDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n                    <br>(Last system cycle)</span>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <p>\r\n                        Account Value: <br class=\"visible-xs visible-lg\">\r\n                        <span id=\"accountValueSpan\">\r\n                            "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.accountValue : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                        </span>\r\n                    </p>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                    <p>\r\n                        "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashValueAmountLabel : stack1), depth0))
    + ":\r\n                        <br class=\"visible-xs visible-lg\">\r\n                        <span id=\"accumulatedValueSpan\">\r\n                            "
    + alias1((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashValueAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                        </span>\r\n                    </p>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                    <p>\r\n                        "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.netSurrenderValueAmountLabel : stack1), depth0))
    + ":\r\n                        <br class=\"visible-xs visible-lg\">\r\n                        <span id=\"netSurrenderValueAmountSpan\">\r\n                            "
    + alias1((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.netSurrenderValueAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                        </span>\r\n                    </p>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <p>\r\n                        Cost Basis: <br class=\"visible-xs visible-lg\">\r\n                        <span id=\"costBasisSpan\">"
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.costBasis : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n                    </p>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <p>\r\n                        Loan Balance: <br class=\"visible-xs visible-lg\">\r\n                        <span id=\"loanBalanceSpan\">"
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.loan)) && stack1.loanBalance),{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n                    </p>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <p>\r\n                        Cash Withdrawals: <br class=\"visible-xs visible-lg\">\r\n                        <span id=\"cashWithdrawalsSpan\">\r\n                            "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashWithdrawals : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                        </span>\r\n                    </p>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <p>\r\n                        Total Policy Contributions: <br class=\"visible-xs visible-lg\">\r\n                        <span id=\"totalPolicyContributionsSpan\">\r\n                            "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.billingDetail)) && stack1.totalDepositsToDate),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                        </span>\r\n                    </p>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <li class=\"js-ltc-col\">\r\n                <h4>\r\n                    Long Term Care Values"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.asOfDate : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</h4>\r\n                <div class=\"status-date\">\r\n                   \r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ltcCoverageDetail : depth0)) != null ? stack1.ltcCurrentBenefitBalance : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ltcCoverageDetail : depth0)) != null ? stack1.ltcTotalWithdrawnAmount : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ltcCoverageDetail : depth0)) != null ? stack1.monthlyBenefitAmount : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\r\n            </li>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<br><span id=\"ltcAsOfDate\" class=\"smaller-65\">\r\n                        As of "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.asOfDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "<br>(Last system cycle)\r\n                    </span>";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            Current LTC Benefit Balance: <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"ltcCurrentBenefitBalance\">\r\n                                "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ltcCoverageDetail : depth0)) != null ? stack1.ltcCurrentBenefitBalance : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                            </span>\r\n                        </p>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            LTC Total Amount Withdrawn: <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"ltcTotalWithdrawnAmount\">\r\n                                "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ltcCoverageDetail : depth0)) != null ? stack1.ltcTotalWithdrawnAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                            </span>\r\n                        </p>\r\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            LTC Monthly Maximum: <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"monthlyBenefitAmount\">\r\n                                "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ltcCoverageDetail : depth0)) != null ? stack1.monthlyBenefitAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                            </span>\r\n                        </p>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <li class=\"js-cash-col\">\r\n                <h4>Cash Withdrawal Values"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashWithdrawalFromDate : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</h4>\r\n                <div class=\"status-date\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.penaltyFreeCashWithdrawalAmount : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.totalCashAmountWithdrawn : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.remainingCashFreeOutAmount : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\r\n            </li>\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashWithdrawalToDate : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<br><span id=\"cashColDates\" class=\"smaller-65\">\r\n                                From "
    + alias3((helpers.dateFormat || (depth0 && depth0.dateFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashWithdrawalFromDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n                                to "
    + alias3((helpers.dateFormat || (depth0 && depth0.dateFormat) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashWithdrawalToDate : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "\r\n                                <br>(Current policy year)\r\n                            </span>";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            Penalty Free Cash Withdrawal Amount:\r\n                            <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"penaltyFreeCashWithdrawalAmount\">\r\n                                "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.penaltyFreeCashWithdrawalAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                            </span>\r\n                        </p>\r\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            Total Cash Amount Withdrawn:\r\n                            <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"totalCashAmountWithdrawn\">\r\n                                "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.totalCashAmountWithdrawn : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                            </span>\r\n                        </p>\r\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            Remaining Cash Free Out Amount:\r\n                            <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"remainingCashFreeOutAmount\">\r\n                                "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.remainingCashFreeOutAmount : stack1),{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n                            </span>\r\n                        </p>\r\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <li class=\"js-rates-col\">\r\n                <h4>Rates</h4>\r\n                <div class=\"status-date\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.accumValueInterestRateCurrent : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ltcCoverageDetail : depth0)) != null ? stack1.accumulatedValueLtcInterestRateCurrent : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.currentInterestRate : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.guaranteedInterestRate : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\r\n            </li>\r\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            Accumulated Value Current Interest&nbsp;Rate:\r\n                            <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"accumValueInterestRateCurrent\">\r\n                                "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.accumValueInterestRateCurrent : stack1), depth0))
    + "%\r\n                            </span>\r\n                        </p>\r\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            Accumulated Value LTC Current Interest&nbsp;Rate:\r\n                            <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"accumulatedValueLtcInterestRateCurrent\">\r\n                                "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ltcCoverageDetail : depth0)) != null ? stack1.accumulatedValueLtcInterestRateCurrent : stack1), depth0))
    + "%\r\n                            </span>\r\n                        </p>\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            Current Interest Rate:\r\n                            <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"currentInterestRate\">\r\n                                "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.currentInterestRate : stack1), depth0))
    + "%\r\n                            </span>\r\n                        </p>\r\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p>\r\n                            Minimum Guaranteed Interest&nbsp;Rate:\r\n                            <br class=\"visible-xs visible-lg\">\r\n                            <span id=\"guaranteedInterestRate\">\r\n                                "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.guaranteedInterestRate : stack1), depth0))
    + "%\r\n                            </span>\r\n                        </p>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<h2 class=\"header\">Policy Highlights</h2>\r\n<div class=\"application-status policy-highlights clearfix\">\r\n    <ul class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.columnClass : stack1), depth0))
    + "\">\r\n        <li class=\"js-policy-values-col\">\r\n            <h4>Policy Values"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.asOfDate : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</h4>\r\n            <div class=\"status-date\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.accountValue : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashValueAmountLabel : stack1),"accumulated value",{"name":"compare","hash":{"operator":"strcis"},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.netSurrenderValueAmountLabel : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.costBasis : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isoCurrencyToNumber || (depth0 && depth0.isoCurrencyToNumber) || alias2).call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.loan)) && stack1.loanBalance),true,true,{"name":"isoCurrencyToNumber","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.policyValue : depth0)) != null ? stack1.cashWithdrawals : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.billingDetail)) && stack1.totalDepositsToDate),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\r\n        </li>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.show : stack1)) != null ? stack1.ltcValues : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.show : stack1)) != null ? stack1.cashValues : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.show : stack1)) != null ? stack1.rates : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        \r\n    </ul>\r\n</div>\r\n<!-- /.application-status -->\r\n";
},"useData":true});
