/* global Backbone: false, Marionette:false, _:false */

/**
 * Display result list for a Producer Name and Number Search.
 *
 * This view relies almost entirely on jQuery DataTables, which uses ajax calls for the initial
 * population of the data, as well as for sorting and paging.
 *
 * Created by rkonecky on 9/30/16.
 */

import config       from '../../../config/config';
import debugModule  from '../../../modules/debug/debugModule';
import template     from '../templates/producer-search-results-t.hbs';
import utils        from '../../../utils/utils';
import ProducerDelegateListView from '../views/producer-delegate-list-v';

const userChannel      = Backbone.Radio.channel('user');
const spinnerChannel   = Backbone.Radio.channel('spinner');

const dbg = debugModule.init();

// Need to include this, otherwise unit tests blow up.
import '../../../utils/hb-helpers';

// load the global partials.
import '../../../partials';

const ProducerSearchResultsView = Marionette.LayoutView.extend({

    pageOptions: ['searchTerm', 'searchType'],

    template: template,
    regions : {
        producerDelegateRegion : '#producer-delegate-list'
    },
    ui: {
        producerResultsWrapper     : '#producerSearchResults',
        dataTable                  : '#producerSearchResultsTable',
        responsiveTableInstruction : '.table-responsive-instruction',
        linkViewAsProducer         : '.link-view-as-producer',
        tableLegend                : '#producer-results-table-legend',
        delegatesLink              : '.btn.btn-link',
        producerDelegatesModal   : '#producer-delegates-modal'
    },

    events: {
        'click @ui.linkViewAsProducer' : '_startImpersonation',
        'click @ui.delegatesLink'      : 'showDelegateModal'
    },

    errors: {
        searchTypeMissing : 'Search type is missing to perform producer search'
    },

    initialize(options) {

        this.mergeOptions(options, this.pageOptions);

        // SearchType is mandatory option to decide type of search
        if (!this.searchType && !(typeof this.searchType === 'string')) {
            throw new Error(this.errors.searchTypeMissing);
        }

    },

    onRender() {

        let startIndex = 0;
        let pageLength = 25;
        let dataTable;
        let dataTableOptions;

        //shouldn't perform search if search term undefined
        if (!this.searchTerm) {
            return false;
        }

        const _this = this;

        spinnerChannel.trigger('show', {
            viewScope   : this,
            position    : 'fixed'
        });

        // Use the available options for datatables setup
        if (this.options) {
            if (this.options.start) {
                startIndex = Number(this.options.start);
            }
            if (this.options.length) {
                pageLength = Number(this.options.length);
            }
        }
        
        const columnDefs = [
            { data: 'lexicalName', name: 'lexicalName' },
            { data: 'id', name: 'id', render: _.bind(this.renderProducerActions, this)},
            { data: 'roles', name: 'roles', render: utils.getActiveProducerRoleCodesAsString },
            { data: 'ico[, ]', name: 'ico' },
            { data: 'email[, ]', name: 'email', className : 'wordbreak', width: '25%' }
        ];

        // In order to prevent "null" from being shown in IE/Edge, an
        // empty string will be set as the defaultContent for each column.
        utils.setDatatablesDefaultContent(columnDefs, '');

        this.url = '';
        let dataSource ='data';
        if (this.searchType === 'name') {
            this.url = config.apiUrlRoot + 'producers?producerName=' +
                encodeURIComponent(this.searchTerm);
        } else if (this.searchType === 'number') {
            this.url = config.apiUrlRoot + 'producers?roleCode=' +
                encodeURIComponent(this.searchTerm);
            dataSource = '';
        }

        dataTableOptions =  utils.getDatatableOptions(this, this.url);

        Backbone.$.extend(true, dataTableOptions, {

            ajax : {
                dataSrc  : dataSource
            },

            autoWidth: false,

            // TODO: Remove this line and add sorting when OOSO-2932 is complete
            bSort : false,

            // column definitions. Length MUST match number of columns in template
            columns : columnDefs,

            // show table only for now
            dom : 't',

            paging : false,
            info   : false,
        });

        // Enabling pagination for ProducerName search
        // 
        // Based on SVC story # OOSO-2932 there will be sorting alone for producer name search.
        // 'order' property can be added once OOSO-2932 is ready.
        if (this.searchType === 'name') {
            _.extend(dataTableOptions, {
                paging : true,
                info : true,

                // show info, length control, pagination, table, info, length, pagination
                dom : 'ilptilp',
                language : {
                    info : 'Showing _START_ to _END_ of _TOTAL_ entries for <strong>"' +
                    this.searchTerm + '"</strong>',

                    lengthMenu : 'Display _MENU_ records'
                },

                // 0-based start index
                displayStart : startIndex,

                // initial page length
                pageLength : pageLength
            });
        }

        dataTable = this.ui.dataTable.DataTable(dataTableOptions)
        .on('xhr', function($event, dataTableSettings, jsonResponse, xhr) {

            spinnerChannel.trigger('hide', _this);

            // Make sure we're getting the info back that we should expect
            if (!jsonResponse || !xhr) {
                _this.trigger('error');
                return;
            }

            // Handle any non-200 HTTP responses
            // after the readyState has been set to 4 (DONE)
            if (xhr.readyState === 4 && xhr.status !== 200) {
                _this.trigger('error');
                return;
            }

            // Name search have sorting and pagination
            if (_this.searchType === 'name') {
            
                // Checking whether response have property 'recordsTotal'
                if (_.isUndefined(jsonResponse.recordsTotal)) {
                    _this.trigger('error');
                    return;
                }

                // Show the table legend
                _this.ui.tableLegend.removeClass('hidden');

                // Handle no results being returned
                if (jsonResponse.data && jsonResponse.data.length === 0) {
                    _this.trigger('noResults');
                    return;
                }
            } else if (_this.searchType === 'number') {

                if (jsonResponse.length === 0) {
                    _this.trigger('noResults');
                    return;
                }

                // Hide the legend for this search
                _this.ui.tableLegend.addClass('hidden');
            }
                
            // display the results
            _this.ui.producerResultsWrapper.removeClass('hidden');

            // scroll to results section
            utils.scrollTo(_this.ui.producerResultsWrapper);
            
        });

        utils.bindDataTableEvents({
            dtObject            : dataTable, 
            viewScope           : this, 
            viewName            : 'producer-search-results-v: ', 
            spinnerChannel      : spinnerChannel,
            debugModule         : dbg
        });

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));
    },

    renderProducerActions(producerId, type, row, meta) {
        const hasHOCapability = userChannel.request('hasCapability', 'Home_Office');
        const hasDelegateMaintenance 
        = userChannel.request('hasCapability', 'Delegate_Maintenance');
        let actionsTemplate;
        let pendingViewCount;
        let policiesListURL;
        let links = [];
        let linksHTML;

        if (this.searchType === 'name') {

            // If the pendingViewCount is 0 or undefined, don't display the link
            if (row.pendingViewCount && row.pendingViewCount > 0) {

                // Its to add query param 'targetuser' if impersonated webID exist
                policiesListURL = utils.buildHrefURL(
                    '#policies?producerId=<%= id %>'
                ); 
                pendingViewCount = row.pendingViewCount;

                links.push('<a class="link-policies-list" href="'+policiesListURL+'">'+
                    'Go&nbsp;to&nbsp;Pending&nbsp;List</a>'+
                    '&nbsp;<strong class="oa-lake">(<%=pendingViewCount%>)</strong>');
            }

            if (row.hasDelegates && hasHOCapability) {
                links.push('<button type="button" class=" btn btn-link" ' +
                    'data-id="<%=id %>" ' +
                    '>View Delegates</button>');
            }
            if (row.webId && hasHOCapability) {
                links.push(this._getVaPLinkHTML());
            }
            if (hasHOCapability && hasDelegateMaintenance && row.webId) {
                links.push(this._getMainteanceDelegateHTML());
            }
            linksHTML = links.join('<br />');
            
            if (_.isEmpty(linksHTML)) {
                return;
            }

            actionsTemplate = _.template(linksHTML);

        } else if (this.searchType === 'number') {
            actionsTemplate = _.template(this._getVaPLinkHTML());
        }
        return actionsTemplate( { 
            id                : producerId,
            webId             : row.webId,
            fullName          : row.fullName,
            pendingViewCount  : pendingViewCount
        });
    },

    _getVaPLinkHTML() {
        return '<a class="link-view-as-producer" href="#home?targetuser=<%=webId%>" '+
            'data-webid="<%=webId %>" '+
            'data-name="<%=fullName %>">'+
            'View&nbsp;as&nbsp;Producer</a>';
    },

    _getMainteanceDelegateHTML() {
        return '<a class="producer-delegate-maintenance" '
            +'href="#producer-delegate-maintenance?producerId=<%=id%>" '
            +'>'+'Maintain&nbsp;Delegates</a>';
    },
    /**
     * Start impersonate session
     * @return {object} event event object
     */
    _startImpersonation(event) {
        event.preventDefault();
        
        let anchor = Backbone.$(event.currentTarget);
        let webId  = anchor.data('webid');

        if (webId) {
            // Adding cookie for the impersonated user
            utils.createCookie(webId);
            
            spinnerChannel.trigger('show', {
                viewScope : this,
                position  : 'fixed'
            });

            userChannel.trigger(
                'viewAsProducer',
                {
                    webId    : webId,
                    fullName : anchor.data('name')
                }
            );
        } else {
            dbg.warn('producer-search-results-v: webId missing for a producer');
        }
    },

        /**
     * Show Help text modal
     * to display help information from WCM
     * @param {Object} e jQuery event object
     */
    showDelegateModal (e) {
        e.preventDefault();
        let anchor = Backbone.$(e.currentTarget);
        let Id  = anchor.data('id');

        if (Id) {

            this.showChildView('producerDelegateRegion',   new ProducerDelegateListView({id : Id}));


        } else {
            dbg.warn('producer-search-results-v: Id missing for a producer');
        }
    },
    
    destroy: function () {
        Backbone.$('.modal-backdrop.fade.in').remove();
    }
});

module.exports = ProducerSearchResultsView;
