/* global Backbone, Marionette, _ */
/**
 * A module to draw Spinner animation for waiting period
 * and should part of current view.
 *
 * The Module designed to handle three scenario to display
 * Spinner animation.
 *
 * 1. Displaying Spinner message to 'region' of layoutView 
 *    where data need to fetch and replace. 
 *
 * 2. Displaying Spinner message to a stand alone view (ItemView)
 *    It can be positioned as static and fixed based on parameter send.
 *
 * 3. For Some cases application need to show spinner from other locations
 *    such as Marrionette Application and Object. Here may be or may not available
 *    view to show spinner. We will appendng spinner to contentView, 
 *    as there will be contentView rendered and '#contentView'
 *    element will be exist for App Start. 
 * 
 * For all above scenarios are recongnized by passing 'this' scope to 'showSpinner'
 * method.
 *
 * **** Usage: *****
 *
 * We need to require this module at biginnng of App and so Radion.Channel 'spinner'
 * will initiated and it used from any where in app.
 * 
 *     var SpinnerModule    = require('./modules/waitIndicator/spinnerModule');
 *     this.spinner         = new SpinnerModule();
 *     this.spinnerChannel  = Backbone.Radio.channel('spinner');
 *
 * ** Displaying Spinner in a region from LayoutView.
 *
 *     1. If region is specified spinner will render it inside region itself
 *     
 *         spinnerChannel.trigger('show', {
 *              viewScope    : this,
 *              regionName   : 'hierarchyRegion',
 *              size         : 'medium' // options: small|medium|standard
 *         });
 *       
 *     "viewScope" property should set with 'this' scope object 
 *     its mandatory to understand object type.
 *
 *     "regionName" property is valid name of current Layout passed
 *     
 *     "size" property to set size of Spinner. By default its 3
 *     
 *     2. If there is no region specified it will be added LayoutView's element.
 *        For some scenario there won't be any regions to update with data.
 *     
 *         spinnerChannel.trigger('show', {
 *              viewScope    : this,
 *              position     : 'fixed' //for positioned at center of page
 *         });
 *
 * ** Displaying Spinner on ItemView
 *
 *     spinnerChannel.trigger('show', {
 *          viewScope : this
 *     });
 *
 *     If we add "fixed" property to object passing. Spinner will position as
 *     'fixed' and aligned center of screen.
 *
 *     spinnerChannel.trigger('show', {
 *         viewScope : this,
 *         position   : 'fixed' // options : fixed|static|relative|absolute
 *     });
 *
 * ** Displaying Spinner on Marionette Application / Object
 *      
 *     this.spinnerChannel.trigger('show', {
 *          viewScope : this,
 *          position  : 'fixed'
 *     });
 *
 * ** Remove spinner if its not for LayoutView
 *     It just need pass 'this' object current view / Object / Application
 * 
 *     spinnerChannel.trigger('hide', this);
 *     
 * 
 */
var utils = require('../../utils/utils');
var SpinnerView = require('./views/spinner-v');
var SpinnerModule  = Marionette.Object.extend({

    initialize: function(options) {

        // Let's make the interface with Backbone.Radio
        var channelName = options && options.channelName || 'spinner';


        this.spinnerChannel = Backbone.Radio.channel(channelName);
        
        // Reset channel if it already have any events binded
        // In unit test we initialize module mutliple times event bind getting multiplied
        this.spinnerChannel.reset();

        /*
         * Register wait indicator channel callback for showSpinner and hideSpinner
         */
        this.listenTo(this.spinnerChannel, 'show', this.showSpinner);
        this.listenTo(this.spinnerChannel, 'hide', this.hideSpinner);
    },

    /**
     * Method to show spinner
     *
     * @param {Object} params It will be an object, expected with properties are:
     *                    {
     *                        // scope of current view / object
     *                        // Its Mandatory property
     *                        viewScope   : this,
     *
     *                        // name of layout region
     *                        regionName  : 'regionName',
     *
     *                        // positioning spinner
     *                        position    : 'static|fixed|absolute|relative',
     *
     *                        // size of spinner
     *                        size        : 'small|medium|standard'
     *                    }
     */
    showSpinner : function showSpinner (params) {

        if (!params || !_.isObject(params) || !params.viewScope) {
            return false;
        }
        
        var spinner = utils.generateSpinnerElement(params.size, params.position);

        var spinnerView = new SpinnerView({
            spinner  : spinner,
            position : params.position
        });

        spinnerView.render();

        // Layout view and a valid regionName
        if (params.viewScope.regions) {

            // If there is valid 'region' property in params then use that 
            // property to show spinnerView
            if (params.regionName && params.viewScope.regions[params.regionName]) {
                params.viewScope.showChildView(params.regionName, spinnerView);

            // If there is no 'region' property then add spinnerView element 
            // to layout element itself. Again it will be part of layout view.
            } else {
                params.viewScope.$el.prepend(spinnerView.el);
            }

        // ItemView
        } else if (params.viewScope.$el) {
            params.viewScope.$el.prepend(spinnerView.el);

        // Not an view, but contentLayout exist
        } else {
            if (Backbone.$('#contentView').length) {
                Backbone.$('#contentView').append(spinnerView.el); 
            }
        }        
    },

    /**
     * Method to remove spinner, 
     * mostly applicable only for the ItemView / Object / Application
     * which has added spinner by 'jquery.append' method
     *  
     */
    hideSpinner : function (viewScope) {
        if (!viewScope) {
            return false;
        }

        // Called from Marionette ItemView
        if (viewScope.$el) {
            viewScope.$el.find('.spinner-wrapper').remove();

        // Called from Marionette Object /Applications
        } else if (!viewScope.regions) {
            Backbone.$('#contentView').find('.spinner-wrapper').remove();
        }
    }

});

module.exports = SpinnerModule;
