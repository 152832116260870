/**
 * Helper module for errors.
 * 
 * Created by jbell on 5/4/16.
 */
var errorHelper = (function() {

    /**
     * Define the endpoints and status codes that should be handled immediately.
     *
     * The key is a string, to be matched against an actual request URL.
     * The value is an array of HTTP status codes.
     */
    var handledEndpoints = {

        // Bypass the global error handling for all response codes (empty array)
        '/counts'               : [],
        '/delegates'            : [400,500,404],
        '/policies'             : [400, 404, 500],
        '/policies/summaries'   : [504],
        '/orgs'                 : [404, 500],
        '/producers'            : [500],
        '/roles'                : [],
        '/sso/'                 : [],
        '/ui/log'               : [],
        '/resourcecenter'       : [403, 404],
        
        // handle all errors within WCM content items
        '/wps/wcm/' : []
    };

    /**
     * Errors hash
     */
    var errors = {
        invalidType          : 'The supplied messageType is invalid',
        typeAndTextRequired  : 'The messageText and messageType parameters are required'
    };

    /**
     * Check if a global error handler should display a message
     * 
     * @param url The URL for the request
     * @param status the status code returned
     * @returns {boolean}
     */
    var shouldDisplayGlobalError = function shouldDisplayGlobalError(url, status) {

        if (!url || !status) {
            return true;
        }

        var skipRegex;
        var isHandled = false;

        for (var endpointName in handledEndpoints) {
            if (handledEndpoints.hasOwnProperty(endpointName)) {
                skipRegex = new RegExp(endpointName);

                // If the URL matches a handledEndpoint entry, and the status code matches one
                // of the ednpoint's codes, then something else should have handled this error.
                // Skip displaying the message and break out of the loop.
                isHandled = (
                    skipRegex.test(url) && (
                        handledEndpoints[endpointName].length === 0 ||
                        handledEndpoints[endpointName].indexOf(status) >= 0
                    )
                );
                if (isHandled) {
                    break;
                }
            }
        }

        return ! isHandled;
    };

    /**
     * Create an alert object, to be used with the message-alert Handlebars partial. This
     * encapsulates the styles for the overall div and for the Font Awesome icon.
     * 
     * @param messageType the type of message (success, info, warning, error)
     * @param messageText the text of the message
     * @returns {{message: {string}, divClass: {string}, icon: {string}}}
     */
    var createAlert = function createAlert(messageText, messageType) {
        
        if (!messageText || !messageType) {
            throw new Error(errors.typeAndTextRequired);
        }

        // If the messageText doesn't begin with paragraph tag, add them.
        if (messageText.substring(0, 3) !== '<p>') {
            messageText = '<p>' + messageText + '</p>';
        }

        var alert = {
            message: messageText
        };

        switch (messageType) {
            case 'success':
                alert.divClass = 'alert-success';
                alert.icon     = 'fa-check';
                break;

            case 'info':
                alert.divClass = 'alert-info';
                alert.icon     = 'fa-exclamation';
                break;

            case 'warning':
                alert.divClass = 'alert-warning';
                alert.icon     = 'fa-bullhorn';
                break;

            case 'error':
                alert.divClass = 'alert-danger';
                alert.icon     = 'fa-times';
                break;

            default:
                throw new Error(errors.invalidType);
        }

        return alert;
    };
    
    return {
        errors                   : errors,
        handledEndpoints         : handledEndpoints,
        shouldDisplayGlobalError : shouldDisplayGlobalError,
        createAlert              : createAlert
    };
})();

module.exports = errorHelper;
