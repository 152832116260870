/* global Backbone:false */
/**
 * Resource Center Search View to display the search result on searh of parameter
 */

import template from '../templates/resource-center-filter-t.hbs';
// Need to include this, otherwise unit tests blow up.
import '../../../utils/hb-helpers';
// load the global partials.
import '../../../partials';
import ResourceCenterFilterModel from '../viewModels/resource-center-filter-vm';
 
var ResourceCenterFilterView = Backbone.Marionette.ItemView.extend({
    template: template,
 
    ui: {

        resourceFilterBtn: '.resource-center-filter-btn',
        filterMenuGroup: '#filter-menu-group',
        closeFilterMenuId: '#resource-center-filter-close',
        overlayFilter: '.filter-overlay'
    },
 
    events: {
    
        'click @ui.resourceFilterBtn': '_openFilterMenu',
        'click @ui.closeFilterMenuId': '_closeFilterMenu',
        'click li.sidebargap':  '_clickCategory',
        'click label.sub-category-container': '_selectSubCategory', //click on sub-category name
        'keydown a.sub-category-item': '_selectSubCategory', //select by enter key
        'click button.clear-all-btn': '_clearAllFilters' // clear all filters
    },
 
     /**
      * Initialize the view. This function will create a model for the view if one was not
      * included in the options object.
      *
      * @param options
      */
    initialize(options) {
        // Create and set a new model if there isn't one passed in
        if (!options.model) {
            this.model = new ResourceCenterFilterModel();
        }
        window.addEventListener('scroll', (e) => {  
            if (document.documentElement.scrollTop === 0) {
                this.$el.find('.filter-overlay').removeClass('filter-scroll-down');
                this.$el.find('.filter-overlay').addClass('filter-scroll-up');
            } else {
                this.$el.find('.filter-overlay').removeClass('filter-scroll-up');
                this.$el.find('.filter-overlay').addClass('filter-scroll-down');
            }
        });
    },

    _clickCategory: function(e) { // make only the selected category active
        if (e) {
            e.preventDefault();
        }
        if (e.currentTarget.className.indexOf(' category-active') === -1) {
            var lastActiveCategory = 
                document.getElementsByClassName('sidebargap category-item category-active');
            lastActiveCategory[0].classList.remove('category-active');
            e.currentTarget.className += ' category-active';
            var subCategory = 
                document.querySelector("[id='"+e.currentTarget.id+'-subcategory'+"']");
            var lastActiveSubCategory = 
                document.getElementsByClassName('sub-category-list-unstyled view');
            if  (lastActiveSubCategory.length > 0) {
                lastActiveSubCategory[0].classList.add('hide');
                lastActiveSubCategory[0].classList.remove('view');
            }
            subCategory.className = subCategory.className.replace(' hide', '');
            subCategory.className += ' view';
        }
    },

    _selectSubCategory: function(e) {
        let target = e.currentTarget;
        if (e.keyCode === 13) { // enter key when tab
            let subCategoryCheck = this._chooseSubCategory(target.children[0].innerText);
            if(subCategoryCheck) {
                target.children[0].classList.add('sub-category-selected');
            } else {
                target.children[0].classList.remove('sub-category-selected');
            }
            this._updateSubCategoriesCount(target.children[0].children[0].name);
            this._activateApplyAndClearButtons();
            return false;
        } else if (
            target.className.toLowerCase() === 'sub-category-container' ||
            target.className.toLowerCase() 
                === 'sub-category-container sub-category-selected'){// click on sub-category name
            e.preventDefault();
            let subCategoryCheck = this._chooseSubCategory(target.innerText);
            if(subCategoryCheck) {
                target.className += ' sub-category-selected';
            } else {
                target.className = target.className.replace(' sub-category-selected', '');
            }
            this._activateApplyAndClearButtons();
            this._updateSubCategoriesCount(target.children[0].name);
        }
    },

    _updateSubCategoriesCount: function(target) {// sub category count
        var count = this.$el.find("input[name ='"+target+"']:checked").length;
        let targetElement = document.querySelector("[id='"+target+'-count'+"']");
        if(count > 0){
            targetElement.innerHTML = count;
        } else {
            targetElement.innerHTML = '';
        }
    },

    _activateApplyAndClearButtons: function() {
        var subCategories = document.querySelectorAll("input[type='checkbox']:checked").length;
        if (subCategories < 1) { // buttons deactivate
            this.$el.find('.apply-filters-btn').addClass('disable-filter');
            this.$el.find('.clear-all-btn').addClass('disable-filter-clear');
            this.$el.find('.apply-filters-btn').removeClass('hover');
        } else { // buttons activate
            this.$el.find('.apply-filters-btn').removeClass('disable-filter');
            this.$el.find('.apply-filters-btn').addClass('hover');
            this.$el.find('.clear-all-btn').removeClass('disable-filter-clear');
        }
    },

    _chooseSubCategory: function(subCategoryName) {
        var subCategorySelected = document.querySelector("[value='"+subCategoryName.trim()+"']");
        subCategorySelected.checked = !subCategorySelected.checked;
        return subCategorySelected.checked;
    },

    _clearAllFilters: function(e) {// clear all button click
        if (e) {
            e.preventDefault();
        }
        var categories = document.getElementsByClassName('sidebargap');
        var checkedSubCategories = this.$el.find('input:checkbox:checked');
        if(checkedSubCategories.length > 0){// all checkboxes unchecked
            for(var i = 0; i < checkedSubCategories.length; i++) {
                checkedSubCategories[i].checked = false;   
            }
            for (var j = 0; j < categories.length; j++) {
                categories[j].getElementsByTagName('span')[0].innerHTML = '';
            }
            // disable clear all & apply filters
            this.$el.find('.apply-filters-btn').addClass('disable-filter');
            this.$el.find('.clear-all-btn').addClass('disable-filter-clear');
            this.$el.find('.apply-filters-btn').removeClass('hover');
            this.$el.find('.sub-category-container').removeClass(' sub-category-selected');
        }
    },

 
     /**
      * When rendering, bind the popover handler to the popover ui elements.
      */
    onRender() {
        // Binding popover event to all popover elements
    
    
    },
   
    _openFilterMenu(event){
        if (event) {
            event.preventDefault();
        }
        this.ui.filterMenuGroup.addClass('open-filter-menu');
        this.ui.overlayFilter.css('display', 'block');
        this.$el.find('.filter-overlay').addClass('filter-scroll-up');
    },

    _closeFilterMenu(event){
        if (event) {
            event.preventDefault();
        }
        this.ui.filterMenuGroup.removeClass('open-filter-menu');
        this.ui.overlayFilter.css('display', 'none');
    }
});

module.exports = ResourceCenterFilterView;
 