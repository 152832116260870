/* global Backbone:false, _:false */

var utils = require('../../../utils/utils');
import AddDelegateModel from '../models/add-delegate-m';

const AddDelegateViewModel = Backbone.Model.extend({
    defaults: function () {
        return {
            producerName: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            accessLevel: '',
            errors: {}
        };
    },

    errors: {
        firstNameRequired: 'First Name is required.',
        lastNameRequired: 'Last Name is required.',
        emailAddressRequired: 'Email Address is required.',
        accessLevelRequired: 'Access Level is required.',
        invalidEmail: 'Please enter a valid Email Address.'
    },

    errorKeysToFormFieldMap: {
        firstNameErrorText: 'firstName',
        lastNameErrorText: 'lastName',
        emailAddressErrorText: 'emailAddress',
        accessLevelErrorText: 'accessLevel'
    },

    initialize: function (options) {
        if (!options.domainModel) {
            this.domainModel = new AddDelegateModel();
        } else {
            this.domainModel = options.domainModel;
        }
    },

    validate: function (callback) {
        var errors = {};
        this.set({ errors: {} });

        if (!this.get('firstName')) {
            errors.firstNameErrorText = this.errors.firstNameRequired;
        }
        if (!this.get('lastName')) {
            errors.lastNameErrorText = this.errors.lastNameRequired;
        }
        if (!this.get('accessLevel') || this.get('accessLevel') === '--please select--') {
            errors.accessLevelErrorText = this.errors.accessLevelRequired;
        }
        let emailAddressErrorText = utils.validateEmailAddress(this.get('emailAddress'));
        if (emailAddressErrorText && emailAddressErrorText.length > 0) {
            errors.emailAddressErrorText = emailAddressErrorText;
        }
        this.set({
            firstNameErrorText: errors.firstNameErrorText,
            lastNameErrorText: errors.lastNameErrorText,
            emailAddressErrorText: errors.emailAddressErrorText,
            accessLevelErrorText: errors.accessLevelErrorText
        });

        if (!_.isEmpty(errors)) {
            utils.sendFormErrorsToAnalytics(errors, this.errorKeysToFormFieldMap,
                'Add Delegate');
            return errors;
        }
    }
});

module.exports = AddDelegateViewModel;