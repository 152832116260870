const Handlebars = require('hbsfy/runtime');

Handlebars.registerPartial(
    'policy-identification',
    require('./policy-identification.hbs')
);

Handlebars.registerPartial(
    'application-status',
    require('./application-status.hbs')
);

Handlebars.registerPartial(
    'billing-payment-info',
    require('./billing-payment-info.hbs')
);

Handlebars.registerPartial(
    'jump-links',
    require('./jump-links.hbs')
);

Handlebars.registerPartial(
    'policy-highlights',
    require('./policy-highlights.hbs')
);

Handlebars.registerPartial(
    'policy-relationships',
    require('./policy-relationships.hbs')
);
Handlebars.registerPartial(
    'policy-relationships-table-row',
    require('./policy-relationships-table-row.hbs')
);
Handlebars.registerPartial(
    'coverages',
    require('./coverages.hbs')
);
Handlebars.registerPartial(
    'notes',
    require('./policy-notes.hbs')
);
Handlebars.registerPartial(
    'rating-information',
    require('./rating-information.hbs')
);

Handlebars.registerPartial(
    'rating-information-table-row',
    require('./rating-information-table-row.hbs')
);

Handlebars.registerPartial(
    'rating-information-col-insured-rateclass',
    require('./rating-information-col-insured-rateclass.hbs')
);

Handlebars.registerPartial(
    'rating-information-rating-data-asset-care',
    require('./rating-information-rating-data-asset-care.hbs')
);

Handlebars.registerPartial(
    'rating-information-rating-data-non-asset-care',
    require('./rating-information-rating-data-non-asset-care.hbs')
);

Handlebars.registerPartial(
    'related-policies',
    require('./related-policies.hbs')
);

Handlebars.registerPartial(
    'policy-requirements',
    require('./policy-requirements.hbs')
);

Handlebars.registerPartial(
    'policy-requirement-submission-log-items',
    require('./policy-requirement-submission-log-items.hbs')
);

Handlebars.registerPartial(
    'investment-allocation-pending',
    require('./investment-allocation-pending.hbs')
);

Handlebars.registerPartial(
    'investment-allocation-active',
    require('./investment-allocation-active.hbs')
);

Handlebars.registerPartial(
    'current-elections-active',
    require('./current-elections-active.hbs')
);

Handlebars.registerPartial(
    'policy-workflow-status-help-text-modal',
    require('./policy-workflow-status-help-text-modal.hbs')
);

Handlebars.registerPartial(
    'producer-delegates-list',
    require('./producer-delegates-list.hbs')
);

Handlebars.registerPartial(
    'policy-1035-exchange-history-comments',
    require('./policy-1035-exchange-history-comments.hbs')
);

Handlebars.registerPartial(
    'table-responsive-instruction',
    require('../../../partials/table-responsive-instruction.hbs')
);

Handlebars.registerPartial(
    'policy-explanation-benefits',
    require('./policy-explanation-benefits.hbs')
);

Handlebars.registerPartial(
    'additional-premium',
    require('./additional-premium.hbs')
);

Handlebars.registerPartial(
    'income-payment',
    require('./income-payment.hbs')
);

Handlebars.registerPartial(
    'income-options',
    require('./income-options.hbs')
);

Handlebars.registerPartial(
    'loan-information',
    require('./loan-information.hbs')
);

Handlebars.registerPartial(
    'premium-mode-quotes',
    require('./premium-mode-quotes.hbs')
);

module.exports = Handlebars;
