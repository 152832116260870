/* global Backbone:false, _:false, $:false*/

// load the global partials.
require('../../../partials');
require('../../../utils/hb-helpers');

var debugModule = require('../../../modules/debug/debugModule').init();
var ResourceCenterViewModel = require('../viewmodels/resource-center-datatable-vm');
var template = require('../templates/resource-center-datatable-t.hbs');
var utils = require('../../../utils/utils');
var spinnerChannel = Backbone.Radio.channel('spinner');

import errorHelper from '../../../utils/error-helper';

var ResourceCenterItemView = Backbone.Marionette.ItemView.extend({
    template: template,
    pageOptions: ['searchItem'],
    errors: {
        noResult          : 'No Resources found.',
        noSearchTermFound     : 'No resources found for search term \'<%= searchItem %>\'.',
        systemError         : 'The system has encountered an unexpected error.'
    },
    initialize: function (options) {
        var col;
        var dir;
        var length;
        var start;

        if (options) {
            if (options.state) {
                col = options.state.col;
                dir = options.state.dir;
                length = options.state.length;
                start = options.state.start;
            }
            this.mergeOptions(options, this.pageOptions);
        }
        

        this.model = new ResourceCenterViewModel({
            col: col,
            dir: dir,
            length: length,
            start: start,
        });

        this.listenTo(this, 'noResults', this._showServerMessage);
        this.listenTo(this, 'error', this._showServerMessage);
       
    },
  
    onBeforeRender: function () {
        var uiElements = {
            resourceCenterDatatable: '#resource-center-table',
            resourceCenterDataTableItems: '#resource-center-datatable',
            resourceSearchContainer:'.resource-search-container',
        };

        this.ui = uiElements;
    },
    _toggleInfoPopover(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (Backbone.$(e.currentTarget).hasClass('popoverShown')) {
            Backbone.$(e.currentTarget).removeClass('popoverShown');
            Backbone.$(e.currentTarget).popover('hide');
        } else {
            Backbone.$(e.currentTarget).addClass('popoverShown');
            Backbone.$(e.currentTarget).popover('show');
        }
    },

    _showInfoPopover(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        Backbone.$(e.currentTarget).popover('show');
    },

    _hideInfoPopover(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        Backbone.$(e.currentTarget).popover('hide');
    },

    _handleCSPDownloadClicks(e){
        e.preventDefault();
        e.stopPropagation();
    },

    onRender: function () {
        var _this = this;
      

        if (!this.ui.resourceCenterDataTableItems.length) {
            return false;
        }

        //Abort all previous request if there anything active
        //while user continousily swapping tab before rendering datatable
        utils.abortAllDataTableAjaxRequest(
            this.ui.resourceCenterDataTableItems.selector
        );

        var url = this.model.url();

        var datatableColumns = [
            {
                title: '',
                data: 'medium',
                name: 'medium',
                orderable: false,
            },
            {
                title: 'Name',
                data: 'title',
                name: 'title',
                render: this._setClickableTitle,
                orderable: true,
            },
            {
                title: 'Document Type', 
                data: 'docType',
                name: 'docType',
                orderable: true,
            }
            // {
            //     title: 'Action',
            //     data: 'url',
            //     name: 'url',
            //     render: this._setDownloadOption,
            //     orderable: false,
            // },
        ];
        var dataTableOptions = utils.postDatatableOptions(this, url);
         
        _.extend(dataTableOptions, {
            // show info, length control, pagination, table, info, length, pagination
            dom: 'ilptilp',

            language: {
                info: 'Showing _START_ to _END_ of _TOTAL_ ' + 'entries',
                lengthMenu: 'Display _MENU_ records',
                emptyTable: 'No resources found',
            },

            // 0-based start index
            displayStart: this.model.get('start'),

            bAutoWidth: false,

            // initial sorting
            order: [this.model.get('col'), this.model.get('dir')],

            // initial page length
            pageLength: this.model.get('length'),

            columns: datatableColumns,
            rowCallback: function(row, data, index) {
            
                var videoIcon = '<i class="fa fa-video-camera"></i>';
                var pdfIcon = '<i class="fa fa-file-pdf-o"></i>';
                var xlsIcon = '<i class="fa fa-file-excel-o"   ></i>';
                var htmlIcon = '<i class="fa fa-html5"  ></i>';
                var emailIcon = '<i class="fa fa-envelope"  ></i>';
                var wordIcon = '<i class="fa fa-file-word-o" ></i>';
                var pptIcon = '<i class="fa fa-file-powerpoint-o" ></i>';
                var websiteIcon = '<i class="fa fa-globe" ></i>';
                var imageIcon = '<i class="fa fa-picture-o" ></i>';
                var phoneIcon = '<i class="fa fa-phone" ></i>';
                
                // temporary changes until font icons are updated to fontawesome6
                data = data.medium ? data.medium.trim() : null;
                if (data) {
                    data = data.toUpperCase();
                    if (data === 'VIDEO') {
                        $('td:eq(0)', row).html(videoIcon);
                    } else if (data === 'EXCEL' || data === 'XLS' || data === 'SPREADSHEET') {
                        $('td:eq(0)', row).html(xlsIcon);
                    } else if (data === 'HTML') {
                        $('td:eq(0)', row).html(htmlIcon);
                    } else if (data === 'EMAIL') {
                        $('td:eq(0)', row).html(emailIcon);
                    } else if (data === 'DOC') {
                        $('td:eq(0)', row).html(wordIcon);
                    } else if (data === 'PPT') {
                        $('td:eq(0)', row).html(pptIcon);
                    } else if (data === 'WEBSITE' || data === 'URL') {
                        $('td:eq(0)', row).html(websiteIcon);
                    } else if (data === 'PDF') {
                        $('td:eq(0)', row).html(pdfIcon);
                    } else if (data === 'IMAGE') {
                        $('td:eq(0)', row).html(imageIcon);
                    } else if (data === 'PHONE') { 
                        $('td:eq(0)', row).html(phoneIcon);
                    }

                } else {
                    $('td:eq(0)', row).html('<span>&nbsp;</span>');
                }
                           
            },
            //work only once for initial time
            initComplete: function (dataTableSettings, jsonResponse) {
                Backbone.$(dataTableSettings.nTableWrapper)
                  .find('.dataTables_info')
                  .removeClass('hidden');
                // ILFS - 113939 temporary changes for hiding sorting icons in headers.
            },
        });

      // In order to prevent "null" from being shown in IE/Edge, an
      // empty string will be set as the defaultContent for each column.
        utils.setDatatablesDefaultContent(dataTableOptions.columns, '');
        spinnerChannel.trigger('show', {
            viewScope : this,
            position  : 'fixed'
        });

        var table = this.ui.resourceCenterDataTableItems
        .DataTable(dataTableOptions)
        .on('xhr', function ($event, dataTableSettings, jsonResponse, xhr) {
            spinnerChannel.trigger('hide', _this);
                    
            //if request is aborted callback will reach here with statusText 'abort'
            //So do no proceed to do anything, otherwise it display blank Table
            if (xhr && xhr.statusText === 'abort') {
                return false;
            }

            // Make sure we're getting the info back that we should expect
            if (!jsonResponse || _.isUndefined(jsonResponse.recordsTotal) || !xhr) {
                _this.trigger('error', _this.errors.systemError, 'error');
                return;
            }

            // Handle any non-200 HTTP responses
            // after the readyState has been set to 4 (DONE)
            if (xhr.readyState === 4 && xhr.status !== 200) {
                _this.trigger('error', _this.errors.systemError, 'error');
                return;
            }

            if (jsonResponse.recordsTotal === 0) {
               
                if (_this.searchItem) {
                    _this.trigger('showServerMessage', _this.errors.noSearchTermFound, 'info');
                } else {
                    _this.trigger('noResults', _this.errors.noResult, 'info');
                }
                return;
            }

            _this.ui.resourceCenterDatatable.removeClass('hidden');
        })
        .on('draw', function ($event, dataTableSettings) {
            //There is no direct API to update info label, so updating it on DOM
            // Backbone.$(dataTableSettings.nTableWrapper).find('.dataTables_info span')
            //     .text(_this.producerName);
            Backbone.$('[data-toggle="popover"]').popover({
                trigger: 'manual',
                placement: 'auto',
                container: 'body',
            });

            Backbone.$('[data-toggle="popover"]').mouseleave(
                _this._hideInfoPopover
            );
            Backbone.$('[data-toggle="popover"]').mouseenter(
                _this._showInfoPopover
            );
            Backbone.$('[data-toggle="popover"]').click(_this._toggleInfoPopover);
            Backbone.$('[data-toggle="popover"]').blur(_this._hideInfoPopover);
            // let aTags = document.querySelectorAll('#resource-center-datatable tr td '+
            // 'a[id^="downloadId"]');
            // aTags.forEach(a => {
            //     Backbone.$(a).click(_this._handleCSPDownloadClicks);
            // });
        });
        
        utils.bindDataTableEvents({
            dtObject: table,
            viewScope: this,
            viewName: 'resource-center-item-v: ',
            spinnerChannel: spinnerChannel,
            debugModule: debugModule,
            addOrderListener: true,
        });

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));
    },

    /**
     * Error handling to show server message if results is empty or server error
     * @param  {string} message message to print
     */
    _showServerMessage: function _showServerMessage( message, type) {
        this.model.set(
          'alertMessage',
           errorHelper.createAlert(message, type)
        );
        this.render();
    },

    // /**
    //  * Display the download and tag options under 'Action' header and download the document.
    //   * @param data cell value from the table
    //   * @param type datatype of the value found
    //   * @param row row object for all the values in that row.
    //   * 
    // */
    // _setDownloadOption: function (data, type, row, meta) {
    
    //     var downloadIcon =
    //     `<a href="#" class="tooltip.bottom"  id="downloadId${meta.row}"`+
    //     `  title="Download Resource" >` +
    //       `<i class="fa fa-download"` +
    //       ` aria-hidden="true" ></i>` + 
    //       `</a>&nbsp;&nbsp`;
    //     //   +' <a href="#" class="tagId" onClick="return false;" ' +
    //     //   ' class="favorite " > ' +
    //     //   '<i class="fa fa-bookmark-o" aria-hidden="true" </i></a>';

    //     if (data) {
    //         return downloadIcon;
    //     } else {
    //         return '';
    //     }
    // },
  
    /**
     * Display the download and tag options under 'Action' header and download the document.
      * @param data cell value from the table
      * @param type datatype of the value found
      * @param row row object for all the values in that row.
      * @returns {HTMLAnchorElement} returns HTML anchor element
    */
    _setClickableTitle: function (data, type, row) {
        var link =
        '<a href="' +row.url +'"'+
        ' target="_blank"  >' + data + '</a>';
        return link;
    
    }
});

module.exports = ResourceCenterItemView;
