// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"alert "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.alertMessage : depth0)) != null ? stack1.divClass : stack1), depth0))
    + "\">\r\n    <div class=\"alert-icon\">\r\n        <i class=\"ace-icon fa "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.alertMessage : depth0)) != null ? stack1.icon : stack1), depth0))
    + "\"></i>\r\n    </div>\r\n    "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.alertMessage : depth0)) != null ? stack1.message : stack1), depth0)) != null ? stack1 : "")
    + "\r\n</div>";
},"useData":true});
