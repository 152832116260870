// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div>\r\n    <div class=\"well well-account-info\">\r\n        <div class=\"row\">\r\n            <div class=\"col-sm-12\">\r\n                <h3>Name:&nbsp;\r\n                    <span class=\"oa-banner-blue\" id=\"producerName\">\r\n\r\n                    </span>\r\n                </h3>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    \r\n    <div class=\"dataTables_wrapper form-inline no-footer\" id=\"inforce-sr-dt-wrapper\">\r\n        <div class=\"table-responsive\">\r\n            <table class=\"table table-striped table-bordered table-hover table-sortable\" id=\"inforce-sr-datatable\"\r\n                data-instruction-container=\"#inforce-sr-instruction\">\r\n            </table>\r\n        </div>\r\n\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","hash":{"id":"inforce-sr-instruction"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n    </div>\r\n\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.orgView : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n<div class=\"well\">\r\n\r\n    <h2 class=\"header\">Inforce Service\r\n        <span class=\"text-nowrap\">Requests\r\n            <button id=\"inforce-sr-help-text-icon\" class=\"fa-stack information-modal pointer\">\r\n                <i class=\"fa fa-square fa-stack-2x\"></i>\r\n                <i class=\"fa fa-info fa-inverse fa-stack-1x\"></i></button>\r\n        </span>\r\n    </h2>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
