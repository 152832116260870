// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        "
    + ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,((stack1 = (data && data.root)) && stack1.customers),((stack1 = blockParams[0][0]) != null ? stack1.customerId : stack1),{"name":"lookup","hash":{},"data":data,"blockParams":blockParams}),{"name":"with","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.fullName || (depth0 != null ? depth0.fullName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fullName","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return ", ";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.policyNumber || (depth0 != null ? depth0.policyNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"policyNumber","hash":{},"data":data}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    return "Recently Received\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"profile-info-row\" id=\"product\">\r\n                        <div class=\"profile-info-name\"><h5 class=\"gray-50\">Product:</h5></div>\r\n                        <div class=\"profile-info-value\"><h5>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.productName : stack1), depth0))
    + "</h5></div>\r\n                    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"well well-account-info\">\r\n    <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n            <h3>Name:&nbsp;\r\n                <span class=\"oa-banner-blue\" id=\"policy-owner-name\">\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.customerRoles)) && stack1.Owner),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                </span>\r\n            </h3>\r\n        </div>\r\n        <!-- /.col-sm-6 -->\r\n        <div class=\"col-sm-6\">\r\n            <h3>Policy #:&nbsp;<span id=\"policy-number\" class=\"oa-banner-blue\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.policyNumber : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams),"inverse":container.program(8, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "            </span>\r\n            </h3>\r\n        </div>\r\n        <!-- /.col-sm-6 -->\r\n    </div>\r\n    <!-- /.row -->\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n            <div class=\"profile-user-info\">\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.productName : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\r\n            </div>\r\n            <!-- /.profile-user-info -->\r\n        </div>\r\n        <!-- /.col-sm-6 -->\r\n    </div>\r\n    <!-- /.row -->\r\n</div>";
},"useData":true,"useBlockParams":true});
