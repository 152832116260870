/* global Backbone:false */

var debugModule          = require('../../../modules/debug/debugModule').init();
var HierarchyPathsModel  = require('../models/hierarchy-paths-m');
var HierarchyPathsView   = require('./hierarchy-paths-v');
var nameTemplate         = require('../templates/general-info-producer-name-t.hbs');
var template             = require('../templates/producer-info-t.hbs');
var userChannel          = Backbone.Radio.channel('user');
var utils                = require('../../../utils/utils');
var spinnerChannel       = Backbone.Radio.channel('spinner');

// Load handlebars helper method to avoid unit test failure
require('../../../utils/hb-helpers');

var ProducerPolicyListInfoView = Backbone.Marionette.LayoutView.extend({

    template : template,

    regions : {
        hierarchyRegion    : '#hierarchy-paths-region',
        producerNameRegion : '#general-info-producer-name-region'
    },

    onBeforeShow : function onBeforeShow () {
        var id               = this.model.get('id');
        var isHomeOffice     = userChannel.request('hasCapability', 'Home_Office');
        var canViewHierarchy = userChannel.request('hasCapability', 'Producer_Role_View');

        // If the canViewHierarchy value is truthy and isHomeOffice is falsey,
        // show the hierarchy child view.
        // Home Office users are sent an empty array for "paths" by the service. See discussion in
        // https://oneamerica.atlassian.net/browse/OOSO-3178.
        // It's best just to not make this call for a Home Office user.
        if (!isHomeOffice && canViewHierarchy && id) {
            this.model.set('paths', new HierarchyPathsModel({
                id : id
            }));

            this.listenTo(this.model.get('paths'), 'sync', this._showHierarchyPaths);
            this.listenTo(this.model.get('paths'), 'error', this._handlePathError);

            spinnerChannel.trigger('show', {
                viewScope    : this,
                regionName   : 'hierarchyRegion',
                size         : 'medium',
                position     : 'static'
            });

            this.model.get('paths').fetch();
        }
    },

    /**
     * Create and show the hierarchy path subview
     * @private
     */
    _showHierarchyPaths : function _showPaths () {

        var hierarchyModel = this.model.get('paths');
        var paths          = hierarchyModel.get('paths');

        // Just in case there are no paths, or the producer is the root
        if (paths && !hierarchyModel.producerIsRoot()) {
            this.hierarchyView = new HierarchyPathsView({
                model : hierarchyModel
            });

            this.showChildView('hierarchyRegion', this.hierarchyView);
        } else {
            // If producerIsRoot then just hide the spinner
            this.showChildView('hierarchyRegion', new Backbone.Marionette.ItemView({
                template:false
            }));
        }
    },

    /**
     * Create a new childview to show the name
     * @param name the name to display
     */
    showName : function _showName(name) {
        var nameView = new Backbone.Marionette.ItemView({
            model : new Backbone.Model({
                fullName : name,
                showIcon : false
            }),
            template : nameTemplate,
            onRender : function() {
                utils.unwrapView(this);
            }
        });
        this.showChildView('producerNameRegion', nameView);
    },

    /**
     * Handle an error when retrieving hierarchy path info. Should be caught by global handler,
     * but may want to do something specific here.
     * @private
     */
    _handlePathError : function _handlePathError (model, response) {

        // Remove spinner as its failed
        this.showChildView('hierarchyRegion', new Backbone.Marionette.ItemView({
            template:false
        }));
        var message = 'Unable to retrieve hierarchy path(s) for ' + model.get('id');
        debugModule.error(message);
    }

});

module.exports = ProducerPolicyListInfoView;