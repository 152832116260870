const Handlebars = require('hbsfy/runtime');

Handlebars.registerPartial(
    'attachments',
    require('./attachments.hbs')
);

Handlebars.registerPartial(
    'header',
    require('./header.hbs')
);

Handlebars.registerPartial(
    'identification-section',
    require('./identification-section.hbs')
);
