/* global Backbone:false, Marionette:false, _:false */
/**
 * UI Test View to render the WCM content
 * It use wcm-content-v which require 'wcmPath' and 'el' as options.
 *
 * wcmPath - a path to load the html content from connectServlet
 * el - element from template provided to place the HTML contwent
 *
 * Created by jbell on 1/25/16.
 */

var checkpoint      = require('../../../modules/checkpoint/checkpointModule');
var wcmTestTemplate = require('../templates/wcm-test-t.hbs');
var WcmView         = require('../../../modules/wcm-content/views/wcm-content-v');

var WcmTestView = Marionette.LayoutView.extend({

    template : wcmTestTemplate,

    regions  : {
        contentArea : '.wcm-content',
        errorArea   : '#errorView'
    },

    ui : {
        wcmForm : '#wcm-form',
        wcmPath : '#wcm-path'
    },

    events : {
        'submit @ui.wcmForm' : '_refresh'
    },

    initialize: function() {

        this.model = new Backbone.Model();

        if (this.options && this.options.stateObj && this.options.stateObj.path) {
            this.model.set('path', this.options.stateObj.path);
        }
    },

    onBeforeShow: function () {
        this._showPath();
    },

    _showPath : function _showPath() {
        var wcmView;

        if (this.model.get('path')) {
            wcmView = new WcmView({
                wcmPath : this.model.get('path')
            });

            this.showChildView('contentArea', wcmView);
        }
    },

    _refresh : function _refresh(event) {
        event.preventDefault();
        var path = this.ui.wcmPath[0].value;
        if (path) {
            this.model.set('path', path);
            this._showError('');
            this._showPath();
            checkpoint.writeCheckpoint( {path : path} );
        } else {
            this._showError('Path Required');
        }
    },

    _showError : function _showError(error) {
        var view = new Marionette.ItemView({
            className : 'error-red',
            tagName   : 'span',
            template  : _.template(error)
        });

        this.showChildView('errorArea', view);
    }
});

module.exports = WcmTestView;
