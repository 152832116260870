/* global Backbone:false, _:false */
/**
 * View used to display the explanation of benefits section
 *
 */

import config from '../../../config/config';

import PolicyExplanationBenefitsModel from '../models/policy-explanation-benefits-m';
import template              from '../partials/policy-explanation-benefits.hbs';
import utils from '../../../utils/utils';
// Load handlebars helper method to avoid unit test failure
require('../../../utils/hb-helpers');

const spinnerChannel = Backbone.Radio.channel('spinner');

const PolicyExplanationBenefitsView = Backbone.Marionette.LayoutView.extend({

    template : template,

    errors : {
        missingPolicyID : 'Policy ID is missing to fetch policy benefits documents',
        noDocuments     : 'No documents available.'
    },

    initialize() {

        if (!this.options.policyId) {
            throw new Error(this.errors.missingPolicyID);
        }

        this.model = new PolicyExplanationBenefitsModel({
            policyId :this.options.policyId
        });
    },

    onBeforeShow() {
        this.listenTo(this.model, 'sync', this.render);
        this.listenTo(this.model, 'sync', this._showExplanationBenefitsLinks);
        this.listenTo(this.model, 'error', this._handleServerError);
        this.model.fetch();

        spinnerChannel.trigger('show', {
            viewScope   : this,
            size        : 'small',
            position    : 'static'
        });
    },

    /**
     * Show link received from explanation benefits service
     *
     * @private
     */
    _showExplanationBenefitsLinks() {


        var explanationBenefits = this.model.get('explanationBenefits');
        // As API is retuning following result, if no records are available
        // [
        //    {
        //      links: []
        //    }
        // ]
        //
        // Ideally i should return empty array i.e. [].
        // To handle it, in below if block we are updating explanationBenefits to [].
        // So that first compare statement in line 6 will not be satisfied
        // and else block will be displayed i.e. No documents available.
        if(explanationBenefits.length === 1 && explanationBenefits[0].links.length ===0){
            explanationBenefits = [];
        }
        else{

            for(let i = 0; i<explanationBenefits.length; i++) {
                if(!_.isEmpty(explanationBenefits[i].links)) {
                    // Appending "/api/oso/secure/web/" to href value inside links array
                    explanationBenefits[i].links[0].href = utils.buildHrefUrlForDocument(
                        config.apiDocUrlRoot + explanationBenefits[i].links[0].href
                    );
                }
            }
        }
        this.model.set('policyExplanationBenefitsLinks', explanationBenefits);

        // Need to flag to display messages/link after getting data
        // till that time FE need to display spinner animation
        spinnerChannel.trigger('hide', this);
        this.render();
    },

    /**
     * Handle error occured while fetching iGo service
     *
     * @param {object} model model object
     * @param {object} response Service response
     *
     * @private
     */
    _handleServerError(model, response) {

        spinnerChannel.trigger('hide', this);

        if (response && response.status) {

            if (response.status === 404 || response.status === 403 || response.status === 500) {
                this.model.set('errorMessage', this.errors.noDocuments);
            }

            this.render();
        }
    }

});

module.exports = PolicyExplanationBenefitsView;