// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"form-inline no-footer hidden display-table\" id=\"transaction-history-dt-wrapper\">\r\n        <div class=\"table-responsive\">\r\n            <table class=\"table table-striped table-bordered table-hover table-sortable table-header-with-title\" id=\"transaction-history-list-datatable\"\r\n                   data-instruction-container=\"#"
    + container.escapeExpression(((helper = (helper = helpers.instructionTextId || (depth0 != null ? depth0.instructionTextId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"instructionTextId","hash":{},"data":data}) : helper)))
    + "\">\r\n            </table>\r\n        </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showNoteForVariableProducts : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <div id=\"pointOneOfNoteForVariableProducts\" class=\"margin-top-10 small datatables-bottom-legends hidden\">\r\n            1) For variable annuity products and variable life insurance products, the amount paid as premiums will be allocated into the funds as selected by the policy owner in the policy contract.\r\n        </div>\r\n        <div id=\"pointTwoOfNoteForVariableProducts\" class=\"margin-top-10 small datatables-bottom-legends hidden\">\r\n            2)  Registered contracts issued by American United Life Insurance Company<sup>&reg;</sup> (AUL) were distributed by OneAmerica Securities, Inc., Member FINRA, SIPC, a Registered Investment Advisor, One American Square., Indianapolis, IN 46282, which is a wholly owned subsidiary of AUL.\r\n        </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<h2 id =\"transactionHistoryTitle\" class=\"hidden\">Recent Transaction History</h2>\r\n\r\n<p class=\"hidden\">\r\n    <span class=\"oa-lake\">For the period of:</span>\r\n    <span id=\"from-to-date\"></span>\r\n</p>\r\n\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n";
},"usePartial":true,"useData":true});
