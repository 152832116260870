/* global Backbone:false, _:false */

var utils = require('../../../utils/utils');
import EditDelegateModel from '../models/edit-delegate-m';

const EditDelegateViewModel = Backbone.Model.extend({
    defaults: function () {
        return {
            name: '',
            emailAddress: '',
            accessLevel: '',
            errors: {}
        };
    },
    errors: {
        accessLevelRequired: 'Access Level is required.',
    },
    errorKeysToFormFieldMap: {
        emailAddressErrorText: 'emailAddress',
        accessLevelErrorText: 'accessLevel'
    },

    initialize: function (options) {
        if (!options.domainModel) {
            this.domainModel = new EditDelegateModel();
        } else {
            this.domainModel = options.domainModel;
        }
    },

    validate: function (callback) {
        var errors = {};
        this.set({ errors: {} });
        if (!this.get('accessLevel') || this.get('accessLevel') === '--please select--') {
            errors.accessLevelErrorText = this.errors.accessLevelRequired;
        }
        
        if (this.get('isEmailAddressNotRegistered') === true) {
            let emailAddressErrorText = utils.validateEmailAddress(this.get('emailAddress'));
            if (emailAddressErrorText && emailAddressErrorText.length > 0) {
                errors.emailAddressErrorText = emailAddressErrorText;
            }
        }
        this.set({
            emailAddressErrorText: errors.emailAddressErrorText,
            accessLevelErrorText: errors.accessLevelErrorText
        });

        if (!_.isEmpty(errors)) {
            utils.sendFormErrorsToAnalytics(errors, this.errorKeysToFormFieldMap,
                'Add Delegate');
            return errors;
        }
    }
});
module.exports = EditDelegateViewModel;