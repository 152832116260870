/* global Backbone:false */

import config from '../../../config/config';

const AddDelegateModel = Backbone.Model.extend({

    urlRoot() {
        return `${config.apiUrlRoot}producers/${this.get('producerId')}/delegates`;
    }

});

module.exports = AddDelegateModel;