/* global Backbone:false, _:false */

/**
 * This class serves as view-model for search-box form on Resource Center page.
 *
 */
var utils = require('../../../utils/utils');
 
var ResourceCenterFormModel = Backbone.Model.extend({

     /*
      * The default values for a new model. None of the properties are required.
      */
    defaults: {
        // search term input
        searchTerm          : '',
        searchTermErrorText : ''
    },

     /*
      * Errors values
      */
    errors: {
        searchItemFormat: 'Please limit special characters to (),-'
    },
 
    errorKeysToFormFieldMap : {
        searchTermErrorText : 'searchTerm'         
    },
 
    initialize: function (options) {
        this._sanitizeSearchTerm();

        this.listenTo(this, 'change:searchTerm', this._sanitizeSearchTerm);
    },
 
     /**
      * Validate the model for search-box input value. If invalid, it will return
      * an object containing a hash of error messages
      *
      * @returns {Object} if the model is incorrect, with properties for error messages.
      *                */
    validate: function() {
        var errors = {};
        var value = this.get('searchTerm');
        // alpha number and/or special characters including 
        //a parenthesis, comma, or dash:  ( )  , -  is VALID search item search
        var isValidSearchString = /^[ A-Za-z0-9(),-]*$/.test(value);
        if (value && !isValidSearchString) {
            errors.searchTermErrorText = this.errors.searchItemFormat;
        }
        // set the error messages
        this.set({
            searchTermErrorText : errors.searchTermErrorText
        });
        if (! _.isEmpty(errors)) {
            utils.sendFormErrorsToAnalytics(errors,
                this.errorKeysToFormFieldMap, 'Resource Center Search');
            return errors;
        }
    },
 
 
     /**
      * Sanitize the search term - for now, just trim leading and trailing whitespace.
      * This method gets called when something changes the searchTerm attribute via a 'set' call.
      *
      * @private
      */
    _sanitizeSearchTerm: function() {
        var searchTerm = this.get('searchTerm');
        if (searchTerm && _.isString(searchTerm)) {
            // updating directly does not cause the 'change' event to fire again
            this.attributes.searchTerm = searchTerm.trim();
        }
    }
});
 
module.exports = ResourceCenterFormModel;
