// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<!-- src/apps/navbar/navbar.hbs -->\r\n<div tabindex=\"-1\" class=\"navbar-container container\" id=\"navbar-container\">\r\n<button class=\"skipnav\">Skip to main content</button>\r\n"
    + ((stack1 = container.invokePartial(partials["navbar-mobile-toggle"],depth0,{"name":"navbar-mobile-toggle","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(partials["navbar-header"],depth0,{"name":"navbar-header","hash":{"appName":(depth0 != null ? depth0.appName : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(partials["navbar-dropdown"],depth0,{"name":"navbar-dropdown","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n</div>\r\n";
},"usePartial":true,"useData":true});
