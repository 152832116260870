// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <p>Enter a policy number or a client name.</p>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "has-error";
},"5":function(container,depth0,helpers,partials,data) {
    return "hide";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchTypeErrorText : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"pending-search-type-group\">\r\n                <div class=\"col-sm-offset-3 col-sm-9\">\r\n                    <div>\r\n                        <label>\r\n                            <input name=\"search-option\" type=\"radio\" class=\"ace\"\r\n                                "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.searchOption : depth0),"policySearch",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                                value=\"policySearch\">\r\n                            <span class=\"lbl\"> Policy Number / Client Name</span>\r\n                        </label>\r\n                    </div>\r\n                    <div>\r\n                        <label>\r\n                            <input name=\"search-option\" type=\"radio\" class=\"ace\"\r\n                                "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.searchOption : depth0),"producerName",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                                value=\"producerName\">\r\n                            <span class=\"lbl\"> Producer Name</span>\r\n                        </label>\r\n                    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasHOViewAsProducer : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    <div id=\"pending-search-type-error\"\r\n                        class=\"help-block "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTypeErrorText : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                        "
    + container.escapeExpression(((helper = (helper = helpers.searchTypeErrorText || (depth0 != null ? depth0.searchTypeErrorText : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"searchTypeErrorText","hash":{},"data":data}) : helper)))
    + "\r\n                    </div>\r\n                </div>\r\n            </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\" ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div>\r\n                        <label>\r\n                            <input name=\"search-option\" type=\"radio\" class=\"ace\"\r\n                                "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.searchOption : depth0),"producerNumber",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                                value=\"producerNumber\">\r\n                            <span class=\"lbl\"> View as Producer (Producer Number)</span>\r\n                        </label>\r\n                    </div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row search-wrapper\">\r\n    <div class=\"col-sm-12\">\r\n\r\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hasPolicySearchByProducer : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n        <form class=\"form-horizontal\" role=\"form\" id=\"pending-search-form\">\r\n\r\n            <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchTermErrorText : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"pending-search-term-group\">\r\n                <label class=\"col-sm-3 control-label no-padding-right\" for=\"pending-search-term-input\"> Search </label>\r\n                <div class=\"col-sm-9\">\r\n                    <div class=\"clearfix\"> \r\n                        <input type=\"text\" id=\"pending-search-term-input\" class=\"col-xs-10 col-sm-5\"\r\n                            value=\""
    + alias4(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\" />\r\n                        <button aria-label=\"information\" class=\"info-wrapper\" type=\"button\" data-toggle=\"popover\"\r\n                            data-content='Policy Number must be 9 or 10 digits. Name searches can be \"Name\" or \"Last Name, First Name.\"'>\r\n                            <i class=\"fa fa-info-circle oa-lake pointer no-margin\" id=\"search-input-restrictions\"></i>\r\n                        </button>\r\n                    </div>\r\n                    <div id=\"pending-search-term-error\"\r\n                        class=\"help-block "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTermErrorText : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                        "
    + alias4(((helper = (helper = helpers.searchTermErrorText || (depth0 != null ? depth0.searchTermErrorText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchTermErrorText","hash":{},"data":data}) : helper)))
    + "\r\n                    </div>\r\n                </div>\r\n            </div>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasPolicySearchByProducer : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n            <div class=\"clearfix form-actions\">\r\n                <div class=\"col-sm-offset-3 col-sm-9\">\r\n                    <button type=\"submit\" class=\"btn btn-info\" id=\"pending-search-button\"\r\n                        "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTerm : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                        <i class=\"ace-icon fa fa-search bigger-110\"></i> Search\r\n                    </button>\r\n                </div>\r\n            </div>\r\n\r\n        </form>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});
