// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"tick-overlay\"> </div>\r\n    <div "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.chartId : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "class=\"chart-outer chart lighter\" data-percent=\""
    + container.escapeExpression(((helper = (helper = helpers.outerValue || (depth0 != null ? depth0.outerValue : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"outerValue","hash":{},"data":data}) : helper)))
    + "\">\r\n"
    + ((stack1 = container.invokePartial(partials.chartLabels,depth0,{"name":"chartLabels","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "id=\""
    + container.escapeExpression(((helper = (helper = helpers.chartId || (depth0 != null ? depth0.chartId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"chartId","hash":{},"data":data}) : helper)))
    + "\" ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.chartId : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "class=\"chart-outer chart-double\" data-percent=\""
    + alias4(((helper = (helper = helpers.outerValue || (depth0 != null ? depth0.outerValue : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"outerValue","hash":{},"data":data}) : helper)))
    + "\">\r\n"
    + ((stack1 = container.invokePartial(partials.chartLabels,depth0,{"name":"chartLabels","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\r\n    <div "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.chartId : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "class=\"chart-inner chart-double-inner\" data-percent=\""
    + alias4(((helper = (helper = helpers.innerValue || (depth0 != null ? depth0.innerValue : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"innerValue","hash":{},"data":data}) : helper)))
    + "\">\r\n    </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "id=\""
    + container.escapeExpression(((helper = (helper = helpers.chartId || (depth0 != null ? depth0.chartId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"chartId","hash":{},"data":data}) : helper)))
    + "-graph-outer\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "id=\""
    + container.escapeExpression(((helper = (helper = helpers.chartId || (depth0 != null ? depth0.chartId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"chartId","hash":{},"data":data}) : helper)))
    + "-graph-inner\" ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.chartType : depth0),"single",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.chartType : depth0),"double",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
