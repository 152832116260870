// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " header-wrapper-toggle";
},"3":function(container,depth0,helpers,partials,data) {
    return "Life Lives Bonus\r\n        <span class=\"text-nowrap\">"
    + container.escapeExpression((helpers.dateTimeFormat || (depth0 && depth0.dateTimeFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.asOfDate : depth0),"YYYY",{"name":"dateTimeFormat","hash":{},"data":data}))
    + "\r\n            <button class=\"fa-stack information-modal pointer\" data-toggle=\"modal\" data-target=\"lifeLivesHelpModal\" title=\"Life Lives Bonus Help\">\r\n                 <i class=\"fa fa-square fa-stack-2x\"></i>\r\n                 <i class=\"fa fa-info fa-inverse fa-stack-1x\"></i>\r\n             </button>\r\n        </span>";
},"5":function(container,depth0,helpers,partials,data) {
    return "Annual Production\r\n        <span class=\"text-nowrap\">Requirement\r\n            <button class=\"fa-stack information-modal pointer\" data-toggle=\"modal\" data-target=\"aprModal\" title=\"Annual Production Requirements Help\">\r\n                <i class=\"fa fa-square fa-stack-2x\"></i>\r\n                <i class=\"fa fa-info fa-inverse fa-stack-1x\"></i>\r\n            </button>\r\n        </span>";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "Chairman's Trip\r\n        <span class=\"text-nowrap\">"
    + alias4(((helper = (helper = helpers.conferenceYear || (depth0 != null ? depth0.conferenceYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conferenceYear","hash":{},"data":data}) : helper)))
    + "\r\n            <button class=\"fa-stack information-modal pointer\" data-toggle=\"modal\" data-target=\"chairmansTripModal\" title=\"Chairman's Trip "
    + alias4(((helper = (helper = helpers.conferenceYear || (depth0 != null ? depth0.conferenceYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conferenceYear","hash":{},"data":data}) : helper)))
    + " Help\">\r\n                <i class=\"fa fa-square fa-stack-2x\"></i>\r\n                <i class=\"fa fa-info fa-inverse fa-stack-1x\"></i>\r\n            </button>\r\n        </span>";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "Leaders' Conference\r\n        <span class=\"text-nowrap\">"
    + alias4(((helper = (helper = helpers.conferenceYear || (depth0 != null ? depth0.conferenceYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conferenceYear","hash":{},"data":data}) : helper)))
    + "\r\n            <button class=\"fa-stack information-modal pointer\" title=\"Leaders' Conference "
    + alias4(((helper = (helper = helpers.conferenceYear || (depth0 != null ? depth0.conferenceYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conferenceYear","hash":{},"data":data}) : helper)))
    + " Help\" data-toggle=\"modal\" data-target=\"leadersConferenceModal\">\r\n                <i class=\"fa fa-square fa-stack-2x\"></i>\r\n                <i class=\"fa fa-info fa-inverse fa-stack-1x\"></i>\r\n            </button>\r\n        </span>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"header-wrapper"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.toggleChart : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n    <h4 class=\"oa-lake\">"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.section : depth0),"lifeLives",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.section : depth0),"annualProductionRequirements",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.section : depth0),"chairmansTrip",{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.section : depth0),"leadersConference",{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</h4>\r\n</div>\r\n";
},"useData":true});
