// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <ul class=\"list-inline\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.producers : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <li>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "            </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.producer : depth0)) != null ? stack1.fullName : stack1), depth0))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                    <a href=\""
    + alias1((helpers.buildHrefURL || (depth0 && depth0.buildHrefURL) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"#policy-manager/org/",{"name":"buildHrefURL","hash":{"producerOrgId":((stack1 = (depth0 != null ? depth0.producer : depth0)) != null ? stack1.id : stack1)},"data":data}))
    + "\" data-producerId=\""
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.producer : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"oa-js-nav\">"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.producer : depth0)) != null ? stack1.fullName : stack1), depth0))
    + " </a>\r\n                    <i class=\"ace-icon fa fa-arrow-right\"></i>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<h5>Hierarchy</h5>\r\n\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.paths : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"useData":true});
