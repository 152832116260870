/* global Marionette:false */

var template = require('../templates/performance-center-vintage-t.hbs');

//Performance Center class
var PerformanceCenterView  = 
    require('../../../modules/performance-center/views/performance-center-v');

var UiTestView = Marionette.LayoutView.extend({
    template    : template,
    regions     : {
        performanceCenterVintageRegion      : '.performance-center-vintage-region'
    },
    onBeforeShow: function () {
        
        this.showChildView('performanceCenterVintageRegion', new PerformanceCenterView({
            showInfoLinks: true
        }));

    }
});

module.exports = UiTestView;