// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.totalYearToDate : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.qualifyingYearToDate : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.qualifyingYearToDate : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"well-gray-footer\">\r\n        <a href=\"/haid/haidDetail.faces\" id=\"calYearCommission\" target=\"_blank\" rel=\"noopener noreferrer\">More Info</a>\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"well well-gray\" >\r\n    <h4>"
    + alias4(((helper = (helper = helpers.textOne || (depth0 != null ? depth0.textOne : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"textOne","hash":{},"data":data}) : helper)))
    + "<br>\r\n        <strong>"
    + alias4(((helper = (helper = helpers.textTwo || (depth0 != null ? depth0.textTwo : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"textTwo","hash":{},"data":data}) : helper)))
    + "</strong>\r\n    </h4>\r\n    <div class=\"well-gray-highlight lighter oa-lake year-to-date\" >\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.totalYearToDate : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showInfoLinks : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\r\n<!-- /.well -->";
},"useData":true});
