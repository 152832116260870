/* global require:false, module:false, Backbone:false */
/**
 * View for displaying the hierarchy paths of a producer, as well as handling the link clicks
 * on upline producers.
 */

var debugModule = require('../../../modules/debug/debugModule').init();
var template    = require('../templates/hierarchy-paths-t.hbs');

// Load handlebars helper method to avoid unit test failure
require('../../../utils/hb-helpers');

var HierarchyPathsView = Backbone.Marionette.ItemView.extend({

    className : 'hierarchy',

    events   : {
        'click a' : '_hierarchyChange'
    },

    template : template,

    initialize : function initialize(options) {
        // attach debugModule for testing
        this.debugModule = debugModule;
    },

    /**
     * Handle clicking on a hierarchy link.
     * @param $event the jQuery event object from the click
     * @private
     */
    _hierarchyChange : function _handleHierarchyClick($event) {
        var producerId = Backbone.$($event.currentTarget).data('producerid');

        if (!producerId) {
            $event.preventDefault();
            this.debugModule.error('User clicked on a hierarchy path link without a producerid');
        }
    }
});

module.exports = HierarchyPathsView;