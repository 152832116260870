// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <ul class=\"list-horizontal \">\r\n\r\n                    <li><a href=\"#\" id=\"end-vap-session-link\">"
    + container.escapeExpression(((helper = (helper = helpers.endLinkLabel || (depth0 != null ? depth0.endLinkLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"endLinkLabel","hash":{},"data":data}) : helper)))
    + "</a></li>\r\n\r\n                </ul>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"alert alert-success alert-view-producer\">\r\n    <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n            <p>"
    + alias4(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data}) : helper)))
    + "</p>\r\n            <h3><strong>"
    + alias4(((helper = (helper = helpers.producer || (depth0 != null ? depth0.producer : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"producer","hash":{},"data":data}) : helper)))
    + "</strong></h3>\r\n        </div>\r\n        <div class=\"col-sm-6 end-vap-session\">\r\n            <hr class=\"visible-xs\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showEnd : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n</div>";
},"useData":true});
