// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " text-error";
},"3":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"help-block"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.commentsErrorText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = helpers.commentsErrorText || (depth0 != null ? depth0.commentsErrorText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"commentsErrorText","hash":{},"data":data}) : helper)))
    + "</div>";
},"useData":true});
