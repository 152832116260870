/* global $, Marionette*/

var menuViewTemplate = require('../templates/sidebar-menu-t.hbs');
var config = require('../../../config/config');
var MenuItemView = require('./sidebar-menu-item-v');
var checkpoint = require('../../../modules/checkpoint/checkpointModule');
var utils = require('../../../utils/utils');
var SidebarMenuView = Marionette.CompositeView.extend({

    template            : menuViewTemplate,

    childView           : MenuItemView,

    childViewContainer  : '#__OA_SIDEBAR_MENU__',
    
    events: {
        'click a': 'selectMenuItem'
    },

    selectMenuItem: function (e) {
        var $clickTarget;
        var href;

        if (e && e.currentTarget) {
            $clickTarget = $(e.currentTarget);
        }


        // If we have an external link, just return. Don't handle anything.
        if (($clickTarget.attr('target') === '_blank') 
            || ($clickTarget.attr('data-disable-handler') === 'true')) {
            //To check Vintage external link and remove the targetuser query parameter.
            let newUrl= new URL($clickTarget.attr('href'));
            if(newUrl.toString().indexOf(config.vintageURL)>-1){
                //adding parameter renewSession
                newUrl.searchParams.delete('targetuser');
                newUrl.searchParams.set('renewSession', 'OLS');
                $clickTarget.attr('href', newUrl.toString());
                // remove cookie targetuser while redirecting to vintage
                let readData = checkpoint.readCheckpoint();
                utils.createCookie(readData.targetuser, -1);
                
            }
            return;
        }

        if (e && e.preventDefault) {
            e.preventDefault();
        }

        href = $clickTarget.attr('href');

        if (!$clickTarget.hasClass('dropdown-toggle')) {

            if (href) {
                this.trigger('nav', href);            
            }
        }
    },

    onChildviewNav: function (childView, href) {
        this.trigger('nav', href);
    }
    
});

module.exports = SidebarMenuView;
