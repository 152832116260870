/* global Backbone, _ */

/**
 * PageLookup
 *
 * The pageLookup model is related to the appStructure model. It uses the
 * pageId as a key to store the associated capability and view. This is
 * used to verify that a pageId exists and what capability a user must
 * have in order to view a particular page.
 *
 * @param {Object} options
 * @param {Object[]} appStructure - (array of objects) Metadata for the pages that make up the site.
 *                  See src/js/models/appStructure-m.js.
 *
 */

const PageLookupModel = Backbone.Model.extend({

    errors : {
        appStructure : 'pageLookup constructor requires an appStructure array'
    },

    initialize (options) {
        if (!options || !options.appStructure || !_.isArray(options.appStructure)) {
            throw new Error(this.errors.appStructure);
        } else {
            this.set('structure', this._buildPageLookup(options.appStructure));
        }
    },

    /**
     * isValidPage() checks to see if pageId is defined in the pageLookup object
     * @param pageId
     * @returns {boolean}
     */
    isValidPage (pageId) {
        let cleanedPageId = this._removeHashPrefix(pageId);
        const siteAreaPageId = this.getSiteAreaPageIdFrom(cleanedPageId);
        if (siteAreaPageId !== null) {
            cleanedPageId = siteAreaPageId;
        }

        // A normal pageId
        if (this.get('structure').hasOwnProperty(cleanedPageId)) {
            return true;
        }

        return false;
    },

    /**
     * userCanAccessPage() validates whether or not the user's capabilities allow them
     * to access a given pageId
     * @param {string} pageId
     * @param {array} capabilities
     * @returns {boolean}
     */
    userCanAccessPage (pageId, capabilities) {
        const _this = this;
        let canAccess = false;
        let hasActiveItemInSidebar = false;
        let cleanedPageId = this._removeHashPrefix(pageId);
        const siteAreaPageId = this.getSiteAreaPageIdFrom(cleanedPageId);
        if (siteAreaPageId !== null) {
            cleanedPageId = siteAreaPageId;
        }
        const page = this.get('structure')[cleanedPageId];

        if (page.capability) {

            //if page has forSidebar property then all should match with
            // existing user capabilities
            if (page.capability.forSidebar) {
                canAccess = this._hasCapabilitiesForSidebar(page, capabilities);
            } 
            
            // if pageId doesn't have 'forSidebar' property or not matching with 
            // any capabilities defined in 'forSidebar' property, but it has pageAccess 
            // property with user capabilities, then it should be pointed to
            // any one of the menu item using 'activeFor' property to set a active menu item and
            // user should have the capabilities which added to the capability.forSidebar property
            // of 'active menu item'
            if (!canAccess) {
                
                // get all pages that have an activeFor property matching the current pageId
                let pageItemsToSetActive = _.filter(this.get('structure'), 
                    function (pageItem) {
                        if(pageItem.activeFor) {
                            return _.contains(pageItem.activeFor, cleanedPageId);
                        }
                    });

                // Check whether containing pageItems has enough capabilities to set a flag
                if (pageItemsToSetActive && Array.isArray(pageItemsToSetActive)) {
                    pageItemsToSetActive.forEach( function (pageItem) {
                        if (!hasActiveItemInSidebar) {
                            hasActiveItemInSidebar = _this._hasCapabilitiesForSidebar(
                                pageItem, 
                                capabilities
                            );
                        }
                    });
                }
            }

            //if capabilities are not matching with forSidebar property then
            //look for pageAccess property to identify page access. Also there 
            //should be a mapping with activeItem for this page ID
            const capabilitiesForPageAccess = page.capability.forPageAccess;
            if (!canAccess && capabilitiesForPageAccess && hasActiveItemInSidebar) {
                canAccess = this._hasCapabilitiesForPageAccess(page, capabilities);
            }

            return canAccess;
        }
        return false;
    },

    /**
     * If the pageId is a wcm site area link, return the site area pageId so it can be
     * used to look up the page properties in the structure. If it is not a wcm site area
     * pageId, return null.
     * 
     * @param {string} pageId
     * @returns {string|null}
     * 
     * For example, here is a site area that is defined in wcmStructure:
     * 
     *       {
     *           displayText : '',
     *           capability : { forPageAccess: { 'all': true }},
     *           isSiteArea : true,
     *           link : 'c/news/articles/**'
     *       }
     *
     * A news article within this site area might have a pageId like this:
     *       'c/news/articles/incentives/chairmans-career'
     * This news article pageId does not exactly match any of the pageIds in the
     * pageLookup, so it is sent to this function to find the matching site area pageId:
     * 
     *  siteAreaPageId = this.getSiteAreaPageIdFrom('c/news/articles/incentives/chairmans-career')
     *  // siteAreaPageId is now 'c/news/articles/**'
     */
    getSiteAreaPageIdFrom (pageId) {
        let pageIdParts = pageId.split('/');
        //discard the part after the last slash
        pageIdParts = pageIdParts.slice(0, -1);

        // Cut off the pageId after the last slash over and over to see if it
        // ever matches a site area pageId
        while (pageIdParts.length > 0) {
            const choppedPageId = pageIdParts.join('/')+'/**';
            if (this.get('structure').hasOwnProperty(choppedPageId)) {
                // pageId found. But is it a site area?
                if (this.get('structure')[choppedPageId].isSiteArea) {
                    return choppedPageId;
                }
            }
            //discard the part after the last slash
            pageIdParts = pageIdParts.slice(0, -1);
        }

        // The pageId does not match any site areas
        return null;
    },

    /**
     * Removes the hash if a string is prefixed with one.
     * If not prefixed with a hash, the entire string is returned.
     * (e.g. '#mypageId' would be returned as 'mypageId').
     * @param {string} pageId
     * @returns {string}
     * @private
     */
    _removeHashPrefix (pageId) {
        return pageId.charAt(0) === '#' ? pageId.substring(1) : pageId;
    },

    /**
    * Convert the appStructure to a lookup with pageId (link) as the key.
    *
    * @param {Object[]} appStructure - Metadata for the pages that make up the site.
    * @returns {Object} Page data lookup object
    *
    * Only items representing actual pages are included in the lookup object.
    * Items used only for subitem headings are omitted.
    *
    * As an example this app structure...
    *     [
    *         {
    *             icon        : 'desktop',
    *             displayText : 'Contact Us',
    *             link        : 'contact-us',
    *             view        : ContactView,
    *             capability  : {
    *                   forSidebar : [{
    *                       all : true
    *                   }]
    *             }
    *         },
    *         {
    *             icon        : 'info-circle',
    *             displayText : 'FAQs & Resources',
    *             subItems: [
    *                 {
    *                     displayText : 'Billing and Payments',
    *                     link        : 'billing-payments',
    *                     view        : BillingView,
    *                     capability  : {
    *                           forPageAccess : {
    *                             'Home_Office': true,
    *                             'WCM_Retail_Manager': true,
    *                             'WCM_Retail_Producer': true,
    *                             'WCM_IB_Manager': true,
    *                             'WCM_IB_Producer': true,
    *                             'WCM_CS_Manager': true,
    *                             'WCM_CS_Producer': true,
    *                             'WCM_CS_Bank_Manager': true,
    *                             'WCM_CS_Bank_Producer': true
    *                           }
    *                     }
    *                 },
    *                 {
    *                     icon        : 'tachometer',
    *                     displayText : 'Tools',
    *                     link        : 'tools',
    *                     view        : ToolsView,
    *                     capability  : {
    *                           forPageAccess : {
    *                             'Home_Office': true,
    *                             'WCM_Retail_Manager': true,
    *                             'WCM_Retail_Producer': true,
    *                             'WCM_IB_Manager': true,
    *                             'WCM_IB_Producer': true,
    *                             'WCM_CS_Manager': true,
    *                             'WCM_CS_Producer': true,
    *                             'WCM_CS_Bank_Manager': true,
    *                             'WCM_CS_Bank_Producer': true
    *                           }
    *                     }
    *                 }
    *             ]
    *         }
    *     ]
    *
    * Will become this lookup object...
    *      {
    *         'contact-us': {
    *             icon        : 'desktop',
    *             displayText : 'Contact Us',
    *             view        : ContactView,
    *             capability  : {
    *                   forSidebar : [{
    *                       all : true
    *                   }]
    *             }
    *         },
    *         'billing-payments': {
    *             displayText : 'Billing and Payments',
    *             view        : BillingView
    *             capability  : {
    *                   forPageAccess : {
    *                          'Home_Office': true,
    *                           'WCM_Retail_Manager': true,
    *                           'WCM_Retail_Producer': true,
    *                           'WCM_IB_Manager': true,
    *                           'WCM_IB_Producer': true,
    *                           'WCM_CS_Manager': true,
    *                           'WCM_CS_Producer': true,
    *                           'WCM_CS_Bank_Manager': true,
    *                           'WCM_CS_Bank_Producer': true
    *
    *                   }
    *             }
    *         },
    *         'tools': {
    *             icon        : 'tachometer',
    *             displayText : 'Tools',
    *             view        : ToolsView,
    *             capability  : {
    *                   forPageAccess : {
    *                           'Home_Office': true,
    *                           'WCM_Retail_Manager': true,
    *                           'WCM_Retail_Producer': true,
    *                           'WCM_IB_Manager': true,
    *                           'WCM_IB_Producer': true,
    *                           'WCM_CS_Manager': true,
    *                           'WCM_CS_Producer': true,
    *                           'WCM_CS_Bank_Manager': true,
    *                           'WCM_CS_Bank_Producer': true
    *                   }
    *             }
    *         }
    *     }
     */
    _buildPageLookup (appStructure) {
        let lookupObj = {};

        // This recursive function adds items to lookupObj as a side effect. It's
        // not pretty, but trying to pass the lookupObj as a parameter and merging
        // the return values into it at each level of recursion is even uglier. -RKC
        const iterate = function (nodeObj) {

            // If nodeObj is an array, loop through the elements
            if (_.isArray(nodeObj)) {
                for (let i=0; i<nodeObj.length; i++) {
                    iterate(nodeObj[i]);
                }

            } else {

                // If there is a 'link' property, then add the node to the lookupObj
                if (nodeObj.hasOwnProperty('link')) {

                    // Required properties
                    lookupObj[nodeObj.link] = {
                        displayText : nodeObj.displayText,
                        view        : nodeObj.view,
                        capability  : nodeObj.capability
                    };

                    // Optional properties
                    if (nodeObj.activeFor) {
                        lookupObj[nodeObj.link].activeFor  = nodeObj.activeFor;
                    }
                    if (nodeObj.icon) {
                        lookupObj[nodeObj.link].icon = nodeObj.icon;
                    }
                    if (nodeObj.isSiteArea) {
                        lookupObj[nodeObj.link].isSiteArea = nodeObj.isSiteArea;
                    }
                }

                // If there is a 'subItems' array, iterate into it
                if (nodeObj.hasOwnProperty('subItems')) {
                    iterate(nodeObj.subItems);
                }
            }
        };

        iterate(appStructure);

        return lookupObj;
    },

    /**
     * Determine if the pageObj passed in has capabilities applied which match one of
     * the capabilities passed to the function. The `forPageAccess` capability is
     * evaluated and must match at least one of the capabilities applied to the pageObj.
     *
     * @param {object} pageObj - The page which is part of the "structure" model attribute
     * @param {Array} userCapabilities - array of capability strings to test against
     * @returns {boolean}
     * @private
     */
    _hasCapabilitiesForPageAccess (pageObj, userCapabilities) {
        let allowAccess = false;

        if (!(pageObj && pageObj.capability && pageObj.capability.forPageAccess)) {
            return false;
        }

        if (_.has(pageObj.capability.forPageAccess, 'all')) {
            allowAccess = true;
        } else {
            _.each(pageObj.capability.forPageAccess, function (flag, capability) {
                if (!allowAccess && (flag === _.contains(userCapabilities, capability))) {
                    allowAccess = true;
                }
            });
        }

        return allowAccess;
    },

    /**
     * Determine if the pageObj passed in has capabilities which match those passed in
     * to the function. The `forSidebar` (menu access) capability is evaluated with this
     * function and must match ALL capabilities applied to the pageObj.
     *
     * @param {object} pageObj - The page which is part of the "structure" model attribute
     * @param {Array} userCapabilities - array of capability strings to test against
     * @returns {boolean}
     * @private
     */
    _hasCapabilitiesForSidebar (pageObj, userCapabilities) {
        let allowAccess = false;

        if (!(pageObj && pageObj.capability && pageObj.capability.forSidebar &&
            Array.isArray(pageObj.capability.forSidebar))) {
            return false;
        }
        const capabilitySets = pageObj.capability.forSidebar;

        // Loop through the sets of capabilities, looking for a set that matches
        // the user's capabilities. 
        for (let i = 0; i < capabilitySets.length; i++) {

            if (_.has(capabilitySets[i], 'all')) {
                allowAccess = true;
            } else {
                // See if the user's capabilities agree with this capability set.
                // An example of a capability set that might be present in the forSidebar
                // array:
                //  {
                //    'Policy_View' : true,
                //    'Policy_Search_by_Producer' : false,
                //    'Home_Office' : false
                //  }
                // Based on this capability set, allowAccess will be set to true only if the user
                // has the 'Policy_View' capability and DOES NOT HAVE 'Policy_Search_by_Producer'
                // and 'Home_Office'.

                /* eslint-disable no-loop-func */
                allowAccess =
                    _.every(capabilitySets[i], function (capabilityValue, capabilityName) {
                        if (capabilityValue === true) { // capability must be present
                            return _.contains(userCapabilities, capabilityName);
                        } else { // capability must NOT be present
                            return !_.contains(userCapabilities, capabilityName);
                        }
                    }
                );
                /* eslint-enable no-loop-func */
            }

            // Break out of the loop if we found a match
            if (allowAccess) {
                break;
            }
        }

        return allowAccess;
    }
});

module.exports = PageLookupModel;
