/* global Backbone */

/**
* Config model to render the dial chart module
*  
* All root level config objects (options) are used to handle dial templates rendering.
* eg: dialType: 'single', checkMark: true etc...
* 
* Expected object and it values:
* ----------------
* dialType {string}      : single / double - default its single
* outerValue {number}    : - mandatory
* classLabelOne {string} : CSS class name style the label one, , without .(dot) - optional
* classLabelTwo {string} : CSS class name style the label two, without .(dot) - optional
* textLabelOne {string}  : Text to place it in label one - optional
* textLabelTwo {string}  : Text to place it in label two - optional
* checkMark {boolean}    : To display checkmark. while checkmark display 
*                          above textLabels will not shown. - optional

* chartOptions {object}  : Config used to render the dial chart - mandatory
*    
*     Mandatory options under chartOptions
*        maxValue {number} 
* 
* If chart type is `double` below fields are mandatory
*
* innerValue {number} - mandatory for chart type 'double'
* outerChartOptions {object} - expected maxValue options
* innerChartOptions {object} - expected maxValue options
*
* in config object. if those objects (outerChartOptions and innerChartOptions) are 
* available in config object, which will extended with default `chartOptions` and 
* set new model objects with the same key name.
*
*/
var DialChartModel = Backbone.Model.extend({
    defaults: {
        dialType: 'single',
        outerValue:0,
        classLabelOne: '',
        classLabelTwo: undefined,
        textLabelOne: '',
        textLabelTwo: undefined,
        checkMark: false,
        chartOptions: {
            animate: {
                enabled: true,
                duration: 2500
            },
            barColor: '#6693bc',
            circlePercentage: 75,
            easing: 'easeOutCirc',

            // fits flush with track/background
            lineCap: 'butt', 
            lineWidth: 20,
            rotate: 225,

            // speedometer lines
            scaleColor: '#777', 

            // speedometer lines
            scaleLength: 3, 
            size:190,

            // graph background
            trackColor: '#d9d9d9'
        }
    },
    
    /**
    * 
    */
    initialize: function (options) {

        var dialColorNegative = '#af1732';
        var dialColorComplete = '#006347';
        var dialColorInner    = '#add8f4';

        if (options) {

            if (options.chartType === 'single') {

                //zero might acceptable
                if (options.outerValue === undefined || options.outerValue === null
                        || typeof options.outerValue !== 'number' ) {
                    throw new Error('dialChartModel.initialize: expected outerValue '+
                        'as chartType is single. outerValue should be a number');
                } else if (options.chartOptions.maxValue === undefined 
                        || options.chartOptions.maxValue === null
                        || typeof options.chartOptions.maxValue !== 'number' ) {
                    throw new Error('dialChartModel.initialize: expected maxValue '+
                        'for chartOptions as chartType is single. maxValue should be a number.');
                }
            } else if (options.chartType === 'double') {
                
                //zero accepted
                if (options.outerValue === undefined || options.outerValue === null
                        || typeof options.outerValue !== 'number'
                        || options.innerValue === undefined || options.innerValue === null
                        || typeof options.innerValue !== 'number' ) {
                    throw new Error('dialChartModel.initialize: expected outerValue. '+
                        'and innerValue as chartType is double. '+
                        'Both should be number.');
                }
                else if (!options.outerChartOptions || !options.innerChartOptions) {
                    throw new Error('dialChartModel.initialize: expected outerChartOptions '+
                        'and innerChartOptions as chartType is double');
                } else if (options.outerChartOptions.maxValue === undefined 
                        || options.outerChartOptions.maxValue === null
                        || typeof options.outerChartOptions.maxValue !== 'number'
                        || options.innerChartOptions.maxValue === undefined
                        || options.innerChartOptions.maxValue === null
                        || typeof options.innerChartOptions.maxValue !== 'number') {
                    throw new Error('dialChartModel.initialize: expected maxValue '+
                        'for outerChartOptions and innerChartOptions as chartType is double. '+
                        'maxValue should be a number.');
                } 
            }

            //set barColor to complete if checkMark true
            if (this.get('checkMark')) {
                if (options.chartOptions) {
                    options.chartOptions.barColor = dialColorComplete;
                }

                if (options.outerChartOptions) {
                    options.outerChartOptions.barColor = dialColorComplete;
                }

                if (options.innerChartOptions) {
                    options.innerChartOptions.barColor = dialColorComplete;
                }
            }

            // set negativeColor for minus value 
            // convert them to absolute
            // set label color as red
            if (this.get('outerValue') < 0) {
                
                this.set('outerValue', Math.abs(this.get('outerValue')));

                this.set('classLabelOne', this.get('classLabelOne') +' oa-banner-red');

                if (options.chartOptions) {
                    options.chartOptions.barColor = dialColorNegative;
                }

                if (options.outerChartOptions) {
                    options.outerChartOptions.barColor = dialColorNegative;
                }
            }

            if (this.get('innerValue') < 0) {
                
                this.set('innerValue', Math.abs(this.get('innerValue')));

                this.set('classLabelTwo', this.get('classLabelTwo') +' oa-banner-red');
           
                if (options.innerChartOptions) {
                    options.innerChartOptions.barColor = dialColorNegative;
                }
            }

            //set green color for label if barColor is green
            if (options.chartOptions 
                    && options.chartOptions.barColor === dialColorComplete
                    || options.outerChartOptions 
                    && options.outerChartOptions.barColor === dialColorComplete) {
                this.set('classLabelOne', this.get('classLabelOne') +' oa-forest');
            } 
            if (options.innerChartOptions 
                    && options.innerChartOptions.barColor === dialColorComplete) {
                this.set('classLabelTwo', this.get('classLabelTwo') +' oa-forest');
            }

            //Finally extending default chartOptions with options parameter
            if(options.chartOptions) {
                options.chartOptions = Backbone.$.extend(true, {}, 
                    this.defaults.chartOptions, options.chartOptions);
                this.set('chartOptions', options.chartOptions);
            } 

            if (options.outerChartOptions) {
                options.outerChartOptions = Backbone.$.extend(true, {}, 
                    this.defaults.chartOptions, options.outerChartOptions);
                this.set('outerChartOptions', options.outerChartOptions);
            }

            if (options.innerChartOptions) {

                //set dial size to 135 for inner chart
                if (!options.innerChartOptions.size) {
                    options.innerChartOptions.size = 135;
                }

                if (!options.innerChartOptions.barColor) {
                    options.innerChartOptions.barColor = dialColorInner;
                }

                options.innerChartOptions = Backbone.$.extend(true, {},
                    this.defaults.chartOptions, options.innerChartOptions);
                this.set('innerChartOptions', options.innerChartOptions);
            }
        }
    }
});

module.exports = DialChartModel;