/* global Backbone: false, Marionette:false */

/**
 * LayoutView for the home page
 */

import errorHelper from '../../../utils/error-helper';
import homeTemplate from '../templates/home-t.hbs';
import { writeCheckpoint } from '../../../modules/checkpoint/checkpointModule';
import EnrollmentBannerView from '../../../modules/enrollmentBanner/enrollment-banner-v';
import FormsView from './ipipeline-forms-v';
import SalesConnectionLinksView from './sales-connection-links-v';
import WcmToutView from './wcm-tout-v';
import PerformanceCenterView
    from '../../../modules/performance-center/views/performance-center-v';
import PoliciesToutView from './policies-tout-v';
var config = require('../../../config/config');

// add global partials
import '../../../partials';

const userChannel = Backbone.Radio.channel('user');


// LayoutView to contain the components on the page
const HomeView = Marionette.LayoutView.extend({

    template : homeTemplate,
    
    ui : {
        myBusinessHeader             : '#my-business-header',
        myBusinessWell               : '#my-business-well',
        educationHeader              : '#education-header',
        educationWell                : '#education-well'
    },

    regions : {
        coeLinkRegion              : '#coe-link-region',
        formsRegion                : '#forms-region',
        newsRegion                 : '#news-link-region',
        oamsRegion                 : '#oams-link-region',
        openEnrollmentRegion       : '#open-enrollment-region',
        performanceCenterRegion    : '#performance-center-region',
        policiesToutRegion         : '#policies-tout-region',
        salesConnectionLinksRegion : '#sales-connection-links-region',
        dailyCommissionsToutRegion : '#daily-commissions-tout-region'
    },

    errors : {
        noDelegateTargetUsers : 'There are no active producers for your delegate access'
    },

    initialize () {
        this.appStructureChannel = Backbone.Radio.channel('appStructure');

        //remove all invalid sub page hashes 
        if (this.options && this.options.stateObj && this.options.stateObj.subpages) {
            this.options.stateObj.subpages = [];
            writeCheckpoint(this.options.stateObj, true);
        }

        this.model =  new Backbone.Model();

        // Determine HO capability
        this.model.set('hasHOCapability', userChannel.request('hasCapability', 'Home_Office'));

        // Claims
        const canViewClaimsLinks = userChannel.request('hasCapability', 'Claims_Resources_View');
        this.model.set('showClaimsTout', canViewClaimsLinks);
        
        // IPipeline_iGO capability check
        const canViewSalesConnectionLinks = userChannel.request('hasCapability', 'IPipeline_iGO');
        const canViewDisabledLinks        = userChannel.request('hasCapability',
            'IPipeline_iGO(disabled)'
        );

        if (canViewSalesConnectionLinks) {
            this.model.set('showSalesConnectionLinksRegion', true);
        }
        this.model.set('showSalesConnectionLinksRegionDisabled', canViewDisabledLinks); 

        // IPipeline_FormsPipe capability check
        const canViewForms    = userChannel.request('hasCapability', 'IPipeline_FormsPipe');
        const canViewDisabled = userChannel.request('hasCapability', 
            'IPipeline_FormsPipe(disabled)'
        );

        this.model.set('showFormsRegion', canViewForms);
        this.model.set('showFormsRegionDisabled', canViewDisabled);

        //Pinpoint_LMS capability check
        const canViewCOELink          = userChannel.request('hasCapability', 'Pinpoint_LMS');
        const canViewCOELinkDisabled  = userChannel.request('hasCapability', 
            'Pinpoint_LMS(disabled)'
        );

        this.model.set('showCOELinkRegion', canViewCOELink);
        this.model.set('showCOELinkRegionDisabled', canViewCOELinkDisabled);

        this.model.set('showNewsToutRegion', this._shouldShowNewsTout());

        //Flex Underwriting tools and resources Link will be displayed 
        //under Sales connection region only for authorized users 
        //having Pinpoint_LMS capability when feature flag is enabled         
        if (config.fflag.enableResourceLink && this.model.get('showSalesConnectionLinksRegion') 
                && canViewCOELink){
            this.model.set('showResourcesLink', true);
        } 

        //Commissions_Daily_View capability check
        const canViewDCMLink = userChannel.request('hasCapability', 'Commissions_Daily_View');
        const canViewDCMLinkDisabled  = userChannel.request('hasCapability', 
            'Commissions_Daily_View(disabled)'
        );
        //OneDailyComp_View Activity capability check
        const canViewODCompLink = userChannel.request('hasCapability', 'OneDailyComp_View');
        const canViewODCompLinkDisabled = userChannel.request('hasCapability',
            'OneDailyComp_View(disabled)');
        
        this.model.set('showDailyCommissionsRegion', (canViewDCMLink || canViewODCompLink));
        this.model.set('showDailyCommissionsRegionDisabled', 
            (canViewDCMLinkDisabled || canViewODCompLinkDisabled));

        this._setOAMSCapabilitiesInModel();

        this._setEducationDisplayAttributesInModel();

        this._alignTouts(); //touts display based on number of touts for the user
    },

    onBeforeShow () {

        const canViewProducerPerformance = userChannel.request('hasCapability', 
                'Producer_Performance_View');

        // whether user is delegate user
        const isDelegate = userChannel.request('hasCapability', 'OLS:DELEGATE');
        const isProducer = userChannel.request('hasCapability', 'OLS:PRODUCER');

        // Get the producers this user can be a delegate for (will be an empty array if none)
        const delegateTargetProducers = userChannel.request('getDelegateTargets');

        if (isDelegate && !isProducer && !delegateTargetProducers.length) {
            this.model.set('alertMessage', 
                errorHelper.createAlert(
                    this.errors.noDelegateTargetUsers, 'info'
                )
            );

            this.render();

            return false;
        }

        // Do not attempt to load the PC if the user shouldn't see it
        if (canViewProducerPerformance) {

            //option showInfoLinks is not mandatory, by default it is false
            this.showChildView('performanceCenterRegion', new PerformanceCenterView({
                showInfoLinks: false
            }));
        }

        const pageData = this.appStructureChannel.request(
            'getPageDataByKeyValue', 'defaultMyBusinessPage', true
        );
        
        if (pageData) {

            let policiestext='';
            let title = '';
            let toutView;

            title = pageData.displayText;

            //define text to display under title
            if (this.model.get('hasHOCapability')) {
                policiestext = 'Find policies and <br class="visible-lg">View as Producer';
            } else {
                policiestext = 'Find policies and pending lists';
                title = 'View ' + title;
            }

            // create and display the policies tout
            toutView = new PoliciesToutView({
                iconCss : 'ace-icon fa fa-pencil brown fa-3x',
                title   : title,
                text    : policiestext,
                href    : '#'+pageData.link
            });

            this.listenTo(toutView, 'showPolicy', this._showPolicy);
            this.listenTo(toutView, 'redirectToSearchPage', this._redirectToSearchPage);
            
            this.showChildView('policiesToutRegion', toutView);
            
            // show the myBusinessHeader and myBusinessWell
            this.ui.myBusinessHeader.removeClass('hidden');
            this.ui.myBusinessWell.removeClass('hidden');
        }

        // Show Sales Connection links
        this._displaySalesConnectionLinks();

        // Show IPipeline Forms links
        this._displayForms();

        //Show COE link
        this._displayCOETout();

        // Show News tout
        this._displayNewsTout();

        // Show the OAMS tout if allowed
        this._displayOAMSTout();

        this._displayOpenEnrollmentBanners();

        //Show Daily Commssions tout if allowed
        this._displayDailyCommissionsTout();
    },

    _alignTouts () {
        if(
            ( this.model.get('showSalesConnectionLinksRegion') || 
            this.model.get('showSalesConnectionLinksRegionDisabled') ) &&
            ( this.model.get('showFormsRegion') || 
                this.model.get('showFormsRegionDisabled') ) &&
            ( this.model.get('showDailyCommissionsRegion') ||
                this.model.get('showDailyCommissionsRegionDisabled') ) &&
            this.model.get('showClaimsTout')
        ){
            this.model.set('alignTout', false);
            if (this.model.get('showResourcesLink')) {
                this.model.set('alterAlignTout', true);
            }
        }else {
            this.model.set('alignTout', true);
        }
    },

    _displayDailyCommissionsTout () {
        const showDCMRegionDisabled = this.model.get('showDailyCommissionsRegionDisabled');
        const showDCMRegion = this.model.get('showDailyCommissionsRegion');
        if (config.fflag.dailyCommissionsTout && 
            (showDCMRegion || showDCMRegionDisabled)) {
            this.showChildView('dailyCommissionsToutRegion', new WcmToutView({
                wcmPath            : 'touts/homepage/my-business/daily-commissions-touts',
                showDisabledIcon   : showDCMRegionDisabled,
                buildHrefUrl       : true
            }));
        }
    },

    _displayOpenEnrollmentBanners () {
        this.showChildView('openEnrollmentRegion', new EnrollmentBannerView());
    },

    /**
     * Show Sales Connection links such as eApp/Illustration, Sales Connection Practice
     * and care solutions products links based up on user capability
     * 
     * Also, show Flex Underwriting tools and resources Link for users having
     * Pinpoint_LMS capability
     * 
     * @private
     */
    _displaySalesConnectionLinks () {
        let showCareSolutionsProductsLink;
        let isAdvancedHomeOfficeUser;
        let showFlexUnderwritingResourcesLink;

        if (this.model.get('showSalesConnectionLinksRegion')) {

            // Advanced home office user check
            isAdvancedHomeOfficeUser = ( 
                userChannel.request('hasCapability', 'OLS:HO:OVERRIDE:IGO.EAPP')
                || userChannel.request('hasCapability', 'OLS:HO:OVERRIDE:IGO.ILLUSTRATIONS')
                || userChannel.request('hasCapability', 'OLS:HO:OVERRIDE:IGO.ADMIN')
            );

            // Care Solution Products Link will be displayed only
            // for advanced home user
            if (this.model.get('hasHOCapability') && isAdvancedHomeOfficeUser) {
                showCareSolutionsProductsLink = true;
            }

            //Flex Underwriting tools and resources Link will be displayed only
            //for authorized users having Pinpoint_LMS capability.
            //The link will not be displayed if Sales connection region is disabled.           
            if (this.model.get('showResourcesLink')){
                showFlexUnderwritingResourcesLink = true;
                this.showChildView('salesConnectionLinksRegion', new SalesConnectionLinksView({
                    showCareSolutionsProductsLink : showCareSolutionsProductsLink,
                    showFlexUnderwritingResourcesLink : showFlexUnderwritingResourcesLink
                }));
            } else {
                this.showChildView('salesConnectionLinksRegion', new SalesConnectionLinksView({
                    showCareSolutionsProductsLink : showCareSolutionsProductsLink
                }));
            }
        } else if (this.model.get('showSalesConnectionLinksRegionDisabled')) {
            this.showChildView('salesConnectionLinksRegion', new SalesConnectionLinksView({
                showDisabled : true 
            }));
        }
    },

    /**
     * Determine which of the three views for the Forms tout the user is allowed to see.
     * This is determined by capability:
     *
     * @see {@link https://oneamerica.atlassian.net/browse/OOSO-3226|OOSO-3226}
     * @private
     */
    _displayForms () {

        if (this.model.get('showFormsRegion')) {
            this.showChildView('formsRegion', new FormsView());

        } else if (this.model.get('showFormsRegionDisabled')) {
            this.showChildView('formsRegion', new FormsView({ showDisabled : true }));
        }
    },

    /**
     * Show Center of Excellence tout from WCM. Manipulate the tout based on
     * whether or not the tout should be displayed in a disabled state.
     * 
     * @private
     */
    _displayCOETout () {
        const showCOELinkRegion         = this.model.get('showCOELinkRegion');
        const showCOELinkRegionDisabled = this.model.get('showCOELinkRegionDisabled');
      
        if (showCOELinkRegion || showCOELinkRegionDisabled) {
            this.showChildView('coeLinkRegion', new WcmToutView({
                wcmPath            : 'touts/homepage/education-and-practice-development/coe',
                showDisabledIcon   : showCOELinkRegionDisabled,
                buildHrefUrl       : true
            }));
        }
    },

    /**
     * Display the News tout from WCM.
     * @private
     */
    _displayNewsTout () {
        const showNewsToutRegion    = this.model.get('showNewsToutRegion');
        const wcmPath               = 'touts/homepage/education-and-practice-development/news-tout';

        if (showNewsToutRegion) {
            this.showChildView('newsRegion', new WcmToutView({
                wcmPath : wcmPath
            }));
        }
    },

    /**
     * Display the OneAmerica Marketing Store tout from WCM.
     * @private
     */
    _displayOAMSTout () {
        const showOAMSToutRegion         = this.model.get('showOAMSToutRegion');
        const showOAMSToutRegionDisabled = this.model.get('showOAMSToutRegionDisabled');
        const impersonatedWebId          = userChannel.request('getImpersonatedWebId');
        let wcmPath                    = 'touts/homepage/education-and-practice-development/oams';

        if (impersonatedWebId) {
            wcmPath = wcmPath+'-delegate';
        }

        if (showOAMSToutRegion || showOAMSToutRegionDisabled) {
            this.showChildView('oamsRegion', new WcmToutView({
                wcmPath : wcmPath,
                showDisabledIcon : showOAMSToutRegionDisabled
            }));
        }
    },

    /**
     * Query the capabilities related to the News link and set them in the model.
     * @returns boolean
     * @private
     */
    _shouldShowNewsTout () {
        let showTout = false;
        const allowedCapabilities = [
            'Home_Office',
            'WCM_Retail_Producer',
            'WCM_Retail_Manager',
            'WCM_IB_Producer',
            'WCM_IB_Manager',
            'WCM_CS_Producer',
            'WCM_CS_Manager'];

        // See if the user has at least one of these capabilities
        let i = 0;
        while (showTout === false && i < allowedCapabilities.length) {
            showTout = userChannel.request('hasCapability', allowedCapabilities[i]);
            i++;
        }

        return showTout;
    },

    /**
     * Query the capabilities related to the OneAmerica Marketing Store and set them in the model.
     * @private
     */
    _setOAMSCapabilitiesInModel () {
        this.model.set('showOAMSToutRegion', userChannel.request('hasCapability', 'OAMS_View'));
        this.model.set('showOAMSToutRegionDisabled', 
            userChannel.request('hasCapability', 'OAMS_View(disabled)'));
    },

    /**
     * Determine the number of touts which will be displayed in the
     * "Education & Practice Development" section.
     * @return {number}
     * @private
     */
    _getEducationToutCount () {
        let touts = 0;

        if (this.model.get('showCOELinkRegion') || this.model.get('showCOELinkRegionDisabled')) {
            touts++;
        }

        if (this.model.get('showNewsToutRegion')) {
            touts++;
        }

        if (this.model.get('showOAMSToutRegion') || this.model.get('showOAMSToutRegionDisabled')) {
            touts++;
        }

        return touts;
    },

    /**
     * Set attributes in the model which will be used to display the
     * "Education & Practice Development" section and related column widths.
     * @private
     */
    _setEducationDisplayAttributesInModel () {
        // Count of tout available to display. It can be used to define column(colClass) layout
        const totalEducationTouts = this._getEducationToutCount();

        // Display the education section if we have at least one tout
        this.model.set('showEducationSection', (totalEducationTouts > 0));
    },

    /**
     * Navigate to the policy detail page
     * @param policyId the ID of the policy to display
     * @private
     */
    _showPolicy(policyId) {
        this.trigger('nav', '#policy-detail?policyId=' + policyId);
    },

    /**
     * Navigate to url the received in param
     * @param {string} url Hash URL returned from Tout view for search page redirection
     */
    _redirectToSearchPage(url) {
        this.trigger('nav', url);
    }
    
});

module.exports = HomeView;
