/* global Backbone, _ */

/**
 * Model used to render a tout.
 *
 * This requires 'title', 'iconCss', and 'href' properties to be passed to the constructor.
 */
var requiredKeys = ['title', 'iconCss', 'href'];
var ToutModel = Backbone.Model.extend({

    defaults : {
        text    : ''
    },

    errors : {
        missingKeys : 'Tout model constructor requires an object with the following keys: ' +
            requiredKeys.join(', ')
    },

    /**
     *
     * @param options {object}
     */
    initialize : function(options) {

        // verify that required keys are passed
        _.each(requiredKeys, function(key) {
            if (!_.has(options, key)) {
                throw new Error(this.errors.missingKeys);
            }
        }, this);

        // extend defaults
        options = _.extend(this.defaults, options);
    }
});

module.exports = ToutModel;
