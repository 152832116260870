/* global Backbone, Marionette, _*/
/**
 * A skeleton module for encapsulating an analytics package.
 * This module should be initialized on app start and use Backbone.Radio command/comply.
 */

var debugModule = require('../debug/debugModule').init();

var _this;

var AnalyticsModule  = Marionette.Object.extend({

    init: function(options) {

        this.providers = [];

        //null checking for providers object
        if (options && options.providers && options.providers.length) {            
            this.providers = options.providers;
        } else {
            debugModule.error('Expected atleast one providers to capture analytics data');
        }

        //set webId for each providers
        _.each(this.providers,function (provider) {
            if (provider.setUser && options.webId) {
                provider.setUser(options.webId);
            }
        });

        //to get this scope accross all methods
        _this = this;

        // Let's make the interface with Backbone.Radio
        var channelName = options && options.channelName || 'analytics';
        this.analyticsChannel = Backbone.Radio.channel(channelName);
        
        /*
         * Register analytics channel callback for trackView and trackAction
         */
        this.listenTo(this.analyticsChannel, 'trackView', this.trackView);
        this.listenTo(this.analyticsChannel, 'trackAction', this.trackAction);
        this.listenTo(this.analyticsChannel, 'trackException', this.trackException);
        this.listenTo(this.analyticsChannel, 'setDataLayerValue', this.setDataLayerValue);
        this.listenTo(this.analyticsChannel, 'unsetDataLayerValue', this.unsetDataLayerValue);
        this.listenTo(this.analyticsChannel, 'setViewingAs', this.setViewingAs);

    },

    /**
     * Set the `gtmDataLayer` values for Google Tag Manager.
     * @param {Object} options
     */
    setDataLayerValue : function setDataLayerValue(options) {
        if (_this.providers) {
            _.each(_this.providers, function (provider) {
                if (provider.setDataLayerValue) {
                    provider.setDataLayerValue(options);
                }
            });
        }
    },

    /**
     * Unset the `gtmDataLayer` values for Google Tag Manager.
     * @param {Object} options
     */
    unsetDataLayerValue : function unsetDataLayerValue(options) {
        if (_this.providers) {
            _.each(_this.providers, function (provider) {
                if (provider.unsetDataLayerValue) {
                    provider.unsetDataLayerValue(options);
                }
            });
        }
    },

    /**
     * Track that a user viewed a page
     * @param {object} options - the args param is an object of the following format:
     *
     *  {
     *     uri   : '#home',
     *     title : 'home'
     *  }
     */
    trackView : function(options) {

        if (_this.providers) {
            _.each(_this.providers,function (provider) {
                if (provider.trackView) {
                    provider.trackView(options);
                }
            });
        }
        
    },

    /**
     * Track that an action occurred
     * @param {object} options - the args param is an object of the following format:
     * 
     * type 1
     *  {
     *     event: event {object}
     *  }
     * type 2
     * {
     *    // URI of the page on which the event happened (eg, #my-biz/pending)
     *    uri: 'string',
     *
     *    // Name of the action taken (eg, 'Filter')
     *    actionName: 'string',
     *
     *    // The type of the event, to be translated to the analytic provider's code
     *    // This is optional, and will default to a 'miscellaneous' value
     *    // (eg, 'formGet')
     *    eventType: 'string'
     * }
     */
    trackAction: function(options) {

        var eventDetails = {};
        var hashedAction;

        //parse event get details and pass it to each providers
        if (options && options.event && typeof options.event === 'object' ) {
            eventDetails = _this._getEventDetails(options.event);
        
        //or just pass eventType along with actionName, uri and title
        } else if (options && options.eventType) {
            hashedAction = '#' + options.actionName;
            eventDetails = { 
                eventType: options.eventType,
                uri: (options.uri) ? options.uri + hashedAction : hashedAction,
                title: (options.title) ? options.title : 'One Source Online'
            };
        } else {
            debugModule.warn('Haven\'t provided enough data to track action', options);
            return false;
        }
        
        if (_this.providers) {
            _.each(_this.providers,function (provider) {
                if (provider.trackAction) {
                    provider.trackAction(eventDetails);
                }
            });
        }
    },

    /**
     * Track all exceptions occured in applications
     * 
     * @param  {object} options Exception details throwed by browser
     * 
     */
    trackException: function (options) {
        if (_this.providers) {
            _.each(_this.providers,function (provider) {
                if (provider.trackException) {
                    provider.trackException(options);
                }
            });
        }
    },

    /**
     * Set webId of impersonated user to track their actions
     * webId will set with a variable 'impersonatedWebId', which will be shared 
     * with analyitics providers.
     * 
     * @param {string} webId Contained with response of 'user' service
     */
    setViewingAs : function setViewingAs (webId) {

        //set webId for each providers
        _.each(this.providers,function (provider) {
            if (provider.setViewingAs) {
                provider.setViewingAs(webId);
            }
        });
    },

    /**
    * Parse event object get back details to send webTrends
    * @event {object} - An `event` object
    *
    * @return {object} - which have uri, title, eventId and referenceUri
    */
    _getEventDetails: function(event) {

        //parsing required info from event object
        var currentTarget = event.currentTarget;
        var uri;
        var title;
        var eventType;
        var elementTrackingInfo;
        var actionName;

        //it can be A, BUTTON, INPUT etc...
        var tagName = currentTarget.tagName.toLowerCase();

        //for offsite and download
        var referenceUri = window.location.href; 

        //to understand whether its rightClick
        if (event.which === 3) {
            eventType = 'rightClick';
        }

        //if anchor find out final event type which match one eventIds
        if (tagName === 'a'  ) {

            //uri of anchor link
            uri = currentTarget.hash;

            title = Backbone.$(currentTarget).data('title') 
                    || currentTarget.innerText 
                    || currentTarget.textContent;

            title = (title) ? title.trim() : '';
            
            if (!eventType) {
                elementTrackingInfo = _this._getElementTrackingInfo(currentTarget);
                eventType = elementTrackingInfo.eventType;
                actionName = 'navigation link';
                if (elementTrackingInfo.title) {
                    title = elementTrackingInfo.title;
                }
            }
        } else if (tagName === 'input' || tagName === 'button') {
            actionName = 'Button click';
            elementTrackingInfo = _this._getElementTrackingInfo(currentTarget);
            eventType = elementTrackingInfo.eventType;
            title = elementTrackingInfo.title;
        } else if (tagName === 'form') {
            actionName = 'Form Submit';
            elementTrackingInfo = _this._getElementTrackingInfo(currentTarget);
            eventType = elementTrackingInfo.eventType;
            title = elementTrackingInfo.title;
        }

        return  {
            actionName   : actionName,
            eventType    : eventType,
            referenceURI : referenceUri,
            title        : title,
            uri          : uri
        };
    },

    /**
    * Get event type based on the target element attributes
    * @param currentTarget {object} - from event object
    * @param tagName {string} - name of tag from event object
    *
    * @return {object} An object which have `title` and `eventType`
    */
    _getElementTrackingInfo: function(currentTarget){
        
        //for checking if its a file
        var fileExtensionPattern = /\.(xls|xslx|doc|docx|pdf|txt|csv|zip|rar|gzip)/i;

        //get host of current element
        var hostPattern = new RegExp(currentTarget.host, 'i');

        //http://help.webtrends.com/en/jstag/tracking_selectors.html
        //get hostname and pathname of the current element
        var hostName    = currentTarget.hostname?( currentTarget.hostname.split(':')[0] ):'';
        var query       = currentTarget.search?
                            currentTarget.search.substring(currentTarget.search.indexOf('?')+
                            1,currentTarget.search.length):'';
        var pathName    = currentTarget.pathname?
                            ((currentTarget.pathname.indexOf('/')!==0)?'/'+
                            currentTarget.pathname:currentTarget.pathname):'/';

        var eventType;
        var title;
        var tagName = currentTarget.tagName.toLowerCase();

        var label;
        var formId;

        //its A tag
        if (tagName === 'a') {

            var href = currentTarget.href;

            //javascript event as inline
            if (/^javascript:/i.test(href)) {
                title = 'javascript link:' + href.substr(11);
                eventType = 'javascript';
            
            //mailto
            } else if (/^mailto:/i.test(href)) {
                title = 'mailto link:' + href.substr(7);
                eventType = 'mailto';
            
            //download '/file' stand for file content from WCM
            } else if (/files\//gi.test(href) 
                    || /oso\/documents\//gi.test(href) || fileExtensionPattern.test(href) ) {
                title = 'Download link:' + hostName + pathName + 
                        (query.length ? ('?' + query) : '');
                eventType = 'download';
            
            //external link?
            } else if (!hostPattern.test(window.location.host)) {
                title = 'Offsite link:' + hostName + pathName + (query.length ? ('?' + query) : '');
                eventType = 'external';
            
            //otherwise it will be just an 'a' tag
            } else {
                eventType = 'anchor';
            }

        } else if (tagName === 'input' || tagName === 'button') {

            label = currentTarget.value || currentTarget.textContent;
            
            // If its inside form identify method
            if (currentTarget.form && currentTarget.type === 'submit') {
                eventType = 'formGet';
                if (currentTarget.form.method === 'post' ) {
                    eventType = 'formPost';
                }

                formId = currentTarget.form.id ? ' - '+currentTarget.form.id : '';

                title = 'Form submit (element - '+tagName+'): '+ label + formId;
            } else {

                eventType = (tagName === 'input') ? 'formInput' : 'formButton';
                title = 'Button click (element - '+tagName+'): ' +label;
            }
        } else if (tagName === 'form') {
            formId = currentTarget.id ? currentTarget.id : '';

            eventType = 'formGet';
            if (currentTarget.method === 'post') {
                eventType = 'formPost';
            }

            title = 'Form submit (element - '+tagName+'): '+  formId;
        }

        return {
            eventType   : eventType,
            title       : title
        };

    }
});

// export a singleton instance of the module
module.exports = new AnalyticsModule();
