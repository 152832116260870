/* global Marionette, _:false*/
/**
 A model parser class which parses the data for Policy rating information
 */


var policyRatingInformation = Marionette.Object.extend({
    /**
     * Parse and sort through customers and ratingInformation
     * in the response to create an object which can be
     * used by the view for displaying the Rating Information
     * table data.
     *
     * Expected Output:
     *
     * ratingInformationData : [
     *       // primary insured is first
     *      {
     *          "customerId"   : 123,
     *          "underwritingClass"  : "Preferred",
     *          "tobaccoUse" : false,
     *          "firstName" : "John Primary",
     *          "lastName"  : "Insured"
     *          "fullName"  : "John Primary Insured",
     *          "coverageRatings" : [
     *              {
     *                  "coverageSequence": 1,
     *                  "coveragePlanName" : null,
     *                  "mortalityTable" : null,
     *                  "morbidityTable" : null,
     *                  "ratedAge"   : 99, 
     *              }
     *          ]
     *      },
     *      // jointInsureds are listed by alphabetical order
     *      {
     *          "customerId"   : 456,
     *          "underwritingClass"  : "Sub-standard",
     *          "tobaccoUse" : true,
     *          "firstName" : "Mary Secondary",
     *          "lastName"  : "Insured"
     *          "fullName"  : "Mary Secondary Insured"
     *          "coverageRatings" : [
     *              {
     *                  "coverageSequence": 1,
     *                  "coveragePlanName" : "Asset Care 4 - 24 Pay",
     *                  "mortalityTable" : "8/H",
     *                  "morbidityTable" : "6/F",
     *                  "ratedAge"   : 99, 
     *              }
     *          ]
     *      },{
     *          "customerId"   : 789,
     *          "underwritingClass"  : "Standard",
     *          "tobaccoUse" : false,
     *          "firstName" : "Xander Secondary",
     *          "lastName"  : "Insured"
     *          "fullName"  : "Xander Secondary Insured",
     *          "coverageRatings" : [
     *              {
     *                  "coverageSequence": 1,
     *                  "coveragePlanName" : null,
     *                  "mortalityTable" : null,
     *                  "morbidityTable" : null,
     *                  "ratedAge"   : 99, 
     *              }
     *          ]
     *      }
     *
     * ]
     *
     * @param {object} response - The data received from service
     */
    _setRatingInformationData : function(response) {
        // convert customerRoles arrays from numbers to strings
        var insureds = _.map(
                response.customerRoles.Insured,
                    function (insured) {
                        return insured.customerId;
                    }
                 );
        var jointInsureds = _.map(
                response.customerRoles['Joint Insured'], 
                    function (jointInsured) {
                        return jointInsured.customerId;
                    }
                );
        
        return _.chain(response.ratingInformation)
            .each(function(info) {
                // identify the primary insured(s) by adding an
                // 'insured' key with a value of 'primary'
                if (_.contains(insureds, info.customerId)) {
                    _.extend(info, {insured : 'primary'});
                }

                // identify the joint insured(s) by adding an
                // 'insured' key with a value of 'secondary'
                if (_.contains(jointInsureds, info.customerId)) {
                    _.extend(info, {insured : 'secondary'});
                }

                // add the name values of the insured
                _.extend(info,
                    _.pick(
                        response.customers[info.customerId], 
                        'fullName', 'lastName', 'firstName'
                    )
                );
            })

            // only include object where insured exists as a key
            .pick(function(info) {
                return _.has(info, 'insured');
            })

            // use the field 'insured' added above to sort by 
            // primary insured first, then by name
            .sortBy(function(info) {
                return [info.insured, info.lastName, info.firstName];
            })

            // Remove the insured key after the sort has been performed. It is no longer needed.
            .map(function(info) {
                return _.omit(info, 'insured');
            })
            .value();
    },

    /**
     * Set flags used to display rating information.
     * Rating information is displayed if the following
     * conditions are met (OOSO-2803):
     *
     *  1) underwritingRequired flag should be true
     *  2) There should be rating information data 
     *
     * @param {object} response - The data received from service
     */
    _setRatingInformationFlagsAndData : function(response) {
        var showRatingInfo                  = false;
        var isProductAssetCare              = false;
        var hasAssetCareTableRatingData     = false;
        var hasNonAssetCareTableRatingData  = false;
        var noTableRatingData               = false;
        var productTypeCodeWL               = 'WL';
        var carrierCodeSL                   = 'SL';
        var currentProductTypeCode;
        var currentCarrierCode;

        if (response.product && response.product.underwritingRequired) {
            showRatingInfo = true;
        }

        response.showRatingInfo = showRatingInfo;

        if (response.product && !_.isEmpty(response.ratingInformation)) {
            
            if (response.product.productTypeCode) {
                currentProductTypeCode = response.product.productTypeCode;
            }
            
            if (response.carrierCode) {
                currentCarrierCode = response.carrierCode;
            }

            // build the data structure for display
            response.ratingInformationData = this._setRatingInformationData(response);
            
            // determine if this is "Asset Care" (Care Solutions)
            if (currentProductTypeCode === productTypeCodeWL 
                    && currentCarrierCode === carrierCodeSL) {
                isProductAssetCare = true;
            }



            //whether it has rating data  - Asset care
            //it will return 'true' if any of object has 'coverageRatings' 
            //and .underwritingClass field which match the value require
            hasAssetCareTableRatingData = _.any(response.ratingInformationData, 
                function (data) {
                    return (_.has(data, 'coverageRatings') 
                        && data.underwritingClass === 'Sub-Standard'
                        && isProductAssetCare);
                }
            );

            //whether it has rating data - Non Asset care
            //it will return 'true' if any of object has 'coverageRatings' 
            //and underwritingClass field which match the value require
            hasNonAssetCareTableRatingData = _.any(response.ratingInformationData, 
                function (data) {
                    return (_.has(data, 'coverageRatings') 
                        && data.underwritingClass === 'Sub-Standard'
                        && !isProductAssetCare);
                }
            );

            if (!hasAssetCareTableRatingData && !hasNonAssetCareTableRatingData) {
                noTableRatingData = true;
            }

        }

        if (response.ratingInformationData) {
            response.ratingInformationData
                .hasAssetCareTableRatingData = hasAssetCareTableRatingData;
            response.ratingInformationData
                .hasNonAssetCareTableRatingData = hasNonAssetCareTableRatingData;
            response.ratingInformationData.isProductAssetCare = isProductAssetCare;
            response.ratingInformationData.noTableRatingData  = noTableRatingData;
        }
                
        return response;
    }
});

module.exports = policyRatingInformation;