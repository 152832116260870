// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <tr>\r\n                    <td>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.comments : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        <a role=\"button\" data-toggle=\"collapse\" href=\"#exchange-comments-"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" \r\n                                class=\"show-details-btn\" title=\"Show Details\"> \r\n                            <i class=\"ace-icon fa fa-angle-down\"></i> \r\n                            View Comments \r\n                        </a>\r\n                    </td>\r\n                    <td>"
    + alias4(((helper = (helper = helpers.otherCarrierName || (depth0 != null ? depth0.otherCarrierName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"otherCarrierName","hash":{},"data":data}) : helper)))
    + "</td>\r\n                    <td>"
    + alias4(((helper = (helper = helpers.otherCarrierPolicyNumber || (depth0 != null ? depth0.otherCarrierPolicyNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"otherCarrierPolicyNumber","hash":{},"data":data}) : helper)))
    + "</td>\r\n                    <td>"
    + alias4((helpers.dateFormat || (depth0 && depth0.dateFormat) || alias2).call(alias1,(depth0 != null ? depth0.dateReceived : depth0),{"name":"dateFormat","hash":{},"data":data}))
    + "</td>\r\n                    <td>"
    + alias4((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.amountReceived : depth0),{"name":"currencyFormat","hash":{},"data":data}))
    + "</td>\r\n                </tr>\r\n                <tr class=\"detail-row\" id=\"exchange-comments-"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\">\r\n                    <td colspan=\"5\"><div class=\"table-detail\">\r\n                            <div class=\"profile-user-info profile-user-info-striped\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.comments : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\r\n                        </div>\r\n                    </td>\r\n                </tr>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            "
    + container.escapeExpression((helpers.dateFormat || (depth0 && depth0.dateFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.comments : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.date : stack1),{"name":"dateFormat","hash":{},"data":data}))
    + "<br>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.comment : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                                        <div class=\"profile-info-row\">\r\n                                            <div class=\"profile-info-name\">"
    + alias3((helpers.dateTimeFormat || (depth0 && depth0.dateTimeFormat) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"MM/DD/YYYY, hh:mm A",{"name":"dateTimeFormat","hash":{},"data":data}))
    + "</div>\r\n                                            <div class=\"profile-info-value\">"
    + alias3(((helper = (helper = helpers.comment || (depth0 != null ? depth0.comment : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"comment","hash":{},"data":data}) : helper)))
    + "</div>\r\n                                        </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<h2 id=\"transfers\" class=\"header\">1035 Exchange / Transfer History</h2>\r\n<div class=\"table-responsive\" id=\"policy-1035-exchange-history-comments\">\r\n    <table class=\"table table-bordered table-hover\">\r\n        <thead>\r\n            <tr>\r\n                <th>Last Activity</th>\r\n                <th>Company Name</th>\r\n                <th>Company Policy Number</th>\r\n                <th>Date Received</th>\r\n                <th>Amount Received</th>\r\n            </tr>\r\n        </thead>\r\n        <tbody>\r\n\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.exchangeHistory : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tbody>\r\n    </table>\r\n</div>\r\n<!-- /.table-responsive -->\r\n"
    + ((stack1 = container.invokePartial(partials["table-responsive-instruction"],depth0,{"name":"table-responsive-instruction","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
