/* global Backbone:false */
/**
 * View used to display the Sales Connection links such as 
 * eApp/Illustration and Pratice Sales connection
 * 
 * send an HTTP POST request in a new window for both link.
 */

var SalesConnectionLinksModel = require('../models/sales-connection-links-m');
var template                  = require('../templates/sales-connection-links-t.hbs');
var config = require('../../../config/config');

var SalesConnectionLinksView = Backbone.Marionette.ItemView.extend({

    template : template,

    ui : {
        samlResponseInput      : '#ipipeline-igo-form input[name="SAMLResponse"]',
        eAppIllustrationLink   : '#eapp-illustration-link',
        ipipelineIgoForm       : '#ipipeline-igo-form',
        practiceCenterLink     : '#practice-center-link',
        relayStateURLInput     : '#ipipeline-igo-form input[name="RelayState"]',
        careSolutionsProdLink  : '#care-solutions-products-link',
        flexUnderwritingResourcesLink : '#flex-underwriting-resources-link'
    },

    events : {
        'click @ui.eAppIllustrationLink'  : '_fetchApplicationData',
        'click @ui.practiceCenterLink'    : '_fetchApplicationData',
        'click @ui.careSolutionsProdLink' : '_fetchApplicationData',
        'click @ui.flexUnderwritingResourcesLink' : '_fetchUWResourceApplicationData'
    },
    
    errors : {
        missingProducerRecord : '<strong>Unable to connect you to Sales Connection.</strong> ' +
            'Please contact the Sales Desk with reference code 1.',
        xmlGenerationError    : 'Unable to connect to Sales Connection at this time.'
    },

    initialize : function () {

        this.model = new SalesConnectionLinksModel();

        // Add a model property to handle disable style
        if (this.options.showDisabled) {
            this.model.set('showDisabled', true);
        }

        if (this.options.showCareSolutionsProductsLink) {
            this.model.set('showCareSolutionsProductsLink', true);
        }

        if (this.options.showFlexUnderwritingResourcesLink) {
            this.model.set('showFlexUnderwritingResourcesLink', true);
        }

        this.listenTo(this.model, 'sync', this._setupFormAndSubmit);
        this.listenTo(this.model, 'error', this._handleiGoSsoError);
        this.listenTo(this.model, 'change:iGoErrorMessage', this.render);
    },

    /**
     * Fetch application data which require enable SSO with sales connection links
     *
     * Response should contain 
     *     - target URL
     *     - relayStateURL
     *     - ApplicationDataXML
     *     
     */
    _fetchApplicationData : function _fetchApplicationData (event) {

        event.preventDefault();

        var currentLinkType = Backbone.$(event.currentTarget).data('link-type');
        var isDisabled      = this.model.get('showDisabled');

        // Allow only if isDisabled is false
        if (!isDisabled) {

            if (this.igoWindow) {
                this.igoWindow.close();
            }
            this.igoWindow = window.open('about:blank', 'igo');

            // update model URL to add /practice at end point
            if (currentLinkType === 'practice' || currentLinkType === 'caresolutions') {
                this.model.set('id', currentLinkType);
            
            // remove id property to set end point as 'igo'
            } else {
                this.model.unset('id'); 
            }

            this.model.unset('iGoErrorMessage');

            // Initially we were catching data in locally to re-use
            // But it require fresh request to enable SSO
            // Otherwise SSO will throw error while using previous data for
            // form submission 
            this.model.fetch();
        }
    },

    /**
     * Sets the action attribute of the form to the "targetUrl" returned from the service,
     * sets the values of hidden inputs, and then submits the form.
     * 
     * @private
     */
    _setupFormAndSubmit : function _setupFormAndSubmit () {

        //set data for form based on current link type
        var targetURL           = this.model.get('targetURL');
        var SAMLResponseData    = this.model.get('SAMLResponse');
        var relayStateURL       = this.model.get('RelayState');

        if (targetURL && SAMLResponseData && relayStateURL) {
            
            // set up the form
            this.ui.ipipelineIgoForm.attr('action', targetURL);
            this.ui.relayStateURLInput.val(relayStateURL);
            this.ui.samlResponseInput.val(SAMLResponseData);

            this.ui.ipipelineIgoForm.submit();
        }
    },

    /**
     * Handle error occured while fetching iGo service
     * 
     * @param {object} model model object
     * @param {object} response Service response
     * 
     * @private
     */
    _handleiGoSsoError : function _handleiGoSsoError (model, response) {

        // we need to close this window which is already opened
        // otherwise user will be navigated to blank page
        // There will be blink while closing window. 
        // but this is the better way to overcome popup blocker issue.
        if(this.igoWindow) {
            this.igoWindow.close();           
        }

        if (response && response.status) {

            if (response.status === 404 || response.status === 403) {
                this.model.set('iGoErrorMessage', this.errors.missingProducerRecord);

            } else if (response.status === 500) {
                this.model.set('iGoErrorMessage', this.errors.xmlGenerationError);
            }
        }
    },

    /**
    * Update root url to pinpoint api for Flex underwriting tools and resources link 
    * New prod url: https://oneamerica.pinpointglobal.com/Portal/resourcepages/underwriting/underwriting   
    * 
    * @param event
    * 
    */
    _fetchUWResourceApplicationData : function _fetchUWResourceApplicationData (event) {

        var currentLinkType = Backbone.$(event.currentTarget).data('link-type');

        if (currentLinkType === 'flexUnderwritingResources') {
    
            var model = new SalesConnectionLinksModel();
            model.urlRoot = config.pinpointURL + '?RelayState=' + config.pinpointResourceURL;
    
            this.ui.flexUnderwritingResourcesLink.attr('href', model.urlRoot);
        }
    }  
});

module.exports = SalesConnectionLinksView;