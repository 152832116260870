var Handlebars = require('hbsfy/runtime');

// TODO
// Should be able to write this file out using a gulp task
// So that it's created on build, and just reads the directory

Handlebars.registerPartial(
    'navbar-mobile-toggle',
    require('./navbar-mobile-toggle.hbs')
);

Handlebars.registerPartial(
    'navbar-header',
    require('./navbar-header.hbs')
);

Handlebars.registerPartial(
    'navbar-header-brand',
    require('./navbar-header-brand.hbs')
);

Handlebars.registerPartial(
    'navbar-dropdown',
    require('./navbar-dropdown.hbs')
);

Handlebars.registerPartial(
    'navbar-dropdown-usermenu',
    require('./navbar-dropdown-usermenu.hbs')
);

Handlebars.registerPartial(
    'navbar-dropdown-usermenu-user',
    require('./navbar-dropdown-usermenu-user.hbs')
);

Handlebars.registerPartial(
    'navbar-dropdown-usermenu-item',
    require('./navbar-dropdown-usermenu-item.hbs')
);

module.exports = Handlebars;
