/* global Backbone:false */
/**
 * Simple model used to retrieve data used for single sign-on to iPipeline forms.
 */
var config = require('../../../config/config');

var FormsModel = Backbone.Model.extend({

    urlRoot : config.apiUrlRoot + 'sso/ipipeline/formspipe'

});

module.exports = FormsModel;