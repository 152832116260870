/* global Backbone:false */
/**
 * Displays the confirmation message and navigation links after a successful form
 * submission.
 */

// Load partials
import '../partials';

// add handlebar helpers (required for unit tests to work)
import '../../../utils/hb-helpers';

import template from '../templates/requirement-submission-confirmation-t.hbs';

const RequirementSubmissionConfirmationPageView = Backbone.Marionette.ItemView.extend({

    errors   : {
        noModel : 'An existing RequirementSubmissionFormViewModel must be passed to the page!'
    },

    template : template,

    initialize (options) {

        if (!options.model) {
            throw new Error(this.errors.noModel);
        } else {
            this.model = options.model;
        }

        // Show the current date and time on the confirmation page
        this.model.set('submittedTimestamp', Date.now());
        
        this.render();
    }

});

module.exports = RequirementSubmissionConfirmationPageView;