// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            "
    + container.escapeExpression(((helper = (helper = helpers.coveragePlanName || (depth0 != null ? depth0.coveragePlanName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"coveragePlanName","hash":{},"data":data}) : helper)))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            "
    + container.escapeExpression(((helper = (helper = helpers.mortalityTable || (depth0 != null ? depth0.mortalityTable : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mortalityTable","hash":{},"data":data}) : helper)))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            "
    + container.escapeExpression(((helper = (helper = helpers.morbidityTable || (depth0 != null ? depth0.morbidityTable : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"morbidityTable","hash":{},"data":data}) : helper)))
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression((helpers.isoDurationToTimeUnit || (depth0 && depth0.isoDurationToTimeUnit) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ratedAge : depth0),"year",{"name":"isoDurationToTimeUnit","hash":{},"data":data}))
    + "\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<tr>\r\n"
    + ((stack1 = container.invokePartial(partials["rating-information-col-insured-rateclass"],depth0,{"name":"rating-information-col-insured-rateclass","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <td>\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.coveragePlanName : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </td>\r\n    <td>\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.mortalityTable : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </td>\r\n    <td>\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.morbidityTable : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </td>\r\n    <td>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ratedAge : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </td>\r\n</tr>";
},"usePartial":true,"useData":true});
