/* global Backbone: false, _:false */
/**
 * Implementation of wcm content model.
 *
 * WCM contents are loaded from connectServlet in html format. 
 * There might chance for some changes to templates in future
 */

 import config             from '../../../config/config';
 import localStorageMemory from '../../../modules/localStorageMemory/localStorageMemory';
 import utilsUI from '../../../utils/utils-ui';

 const WCMModel = Backbone.Model.extend({
 
     cache : {
         cacheStore : localStorageMemory,
         ttl        : (config && config.cache && config.cache.wcmTtl) ? config.cache.wcmTtl : 0.0
     },
 
     errors : {
         addParamsNoString : 'Parameter passed to _addParams is required and must be a string',
         getWcmRoleNoString : 'Parameter passed to _getWcmRole is required and must be a string'
     },
 
     defaults () {
         return {
             body: null,
 
             // connectServlet URL to fetch the HTML content.
             // eg: OnlineServices/Marketing/ELEVATE
             wcmPath: null
         };
     },
 
     // setting default option for 'dataType' as 'html'
     fetch (options){
         options = _.extend(options || {}, {
             dataType: 'html',
             cache      : true,
             cacheTTL   : this.cache.ttl,
             localCache : this.cache.cacheStore,
             global     : false
         });
         this.constructor.__super__.fetch.call(this, options);
     },
 
 
     /**
      * Parse the HTML response to set body content .
      *
      * @param {string} data - The HTML data returned from the wcm connectServlet
      * @returns {body: string, wcmPath: string}
      */
     parse (data) {
         let body    = '';
         const wcmPath = this.get('wcmPath');
 
         // set data returned as body content
         if (data) {
             body = data;
         }
 
         return {
             body    : body,
             wcmPath : wcmPath
         };
     },
 
     /**
      * Build the URL to fetch the content. Appends the
      * "wcmRole" query parameter which gets sent to WCM.
      * @returns {string}
      */
     url () {
         const wcmPathWithParams = this._addParams(this.get('wcmPath'));
 
         return [
             config.wcmBaseUrl,
             wcmPathWithParams
         ].join('');
     },
 
     /**
      * Adds the wcmRole parameter while keeping existing query strings.
      * @param uriString
      * @returns {string} URI with query params if they exist. If query params
      * do not exist, only the URI passed to the function is returned.
      * @private
      */
     _addParams (uriString) {
         let queryString;
         let params = {};
         let wcmRoleValue;
         let urlBase;
 
         if (typeof uriString !== 'string') {
             throw new Error(this.errors.addParamsNoString);
         }
 
         urlBase = uriString.indexOf('?') === -1 ? uriString : uriString.split('?')[0];
         wcmRoleValue = this._getWcmRole(this.get('wcmPath'));
 
         queryString = uriString.split('?')[1];
         params      = Backbone.$.deparam(queryString ? queryString : '');
 
         // Add wcmRole to params if it exists.
         if (wcmRoleValue) {
             params.wcmRole = wcmRoleValue;
         }
 
         if (_.isEmpty(params)) {
             return urlBase;
         } else {
             return urlBase + '?' + Backbone.$.param(params);
         }
     },
 
     /**
      * Returns a comma-delimited set of roles based on capabilities which
      * is ultimately passed to WCM as a query parameter (e.g. "wcmRole")
      * in order to determine the content to display to the user.
      *
      * Note that because of some constraints in WCM, the
      * actual role must be prefixed with some additional text.
      *
      * {@link https://oneamerica.atlassian.net/browse/OOSO-3662}
      * @param wcmPath string - The wcmPath for the content
      * @returns {string|null} Role (e.g. "/inddesign/TAX-Roles/Home_Office",
      * "/inddesign/TAX-Roles/Retail_Producer", "/TAX-Roles/IB_Manager", etc.)
      */
     _getWcmRole (wcmPath) {
         if (typeof wcmPath !== 'string') {
             throw new Error(this.errors.getWcmRoleNoString);
         }
 
         let wcmRole = '';
        
         // Strip off any querystring params, e.g. "?targetuser=123"
         wcmPath = wcmPath.split('?')[0];
 
         
         let taxDataArr = this._getWcmRolesOrGroups();
         if (taxDataArr !== null) {
             wcmRole = taxDataArr.toString();
         }
         if (wcmRole === '') {
             wcmRole = null;
         }
 
         return wcmRole;
     },

     /**
      * This functions returns the array for taxonomy role and
      * taxonomy groups assigned to the user.
      * @returns  {Array} string based array
      */
     _getWcmRolesOrGroups() {
         //calls _getTAXRolesWithGroups() with false flag argument 
         // to fetch WCM pages related data.
         let result =  utilsUI._getTAXRolesWithGroups(false);
         return result;
     }
 
    
 });
 
 module.exports = WCMModel;
 