/* global Marionette, _ */

require('../../../utils/hb-helpers');

//load all partials
require('../partials');

var dialChartTemplate   = require('../templates/dial-chart-t.hbs');
var DialChartModel      = require('../models/dial-chart-m');

var DialChartView = Marionette.ItemView.extend({

    template: dialChartTemplate,

    ui: {
        chartOuter: '.chart-outer',
        chartInner: '.chart-inner'
    },

    initialize: function (options) {

        if (!options || typeof options !== 'object' || _.isEmpty(options) ) {
            throw new Error('dialChartModule view.initialize: options is empty or undefined');
        }

        var dialChartModel = new DialChartModel(options);
        this.model = dialChartModel;
        
    },
    onRender: function () {

        if (this.model.get('chartType') === 'single') {
            this.ui.chartOuter.easyPieChart(this.model.get('chartOptions'));
        } else if (this.model.get('chartType') === 'double') {

            if (this.model.has('outerChartOptions')) {
                this.ui.chartOuter.easyPieChart(this.model.get('outerChartOptions'));
            }

            if (this.model.has('innerChartOptions')) {
                this.ui.chartInner.easyPieChart(this.model.get('innerChartOptions'));
            }
        }
    }

});

module.exports = DialChartView;
