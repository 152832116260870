// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <strong><span class=\"oa-forest\">\r\n            "
    + container.escapeExpression(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + " Goal Achieved!\r\n        </span></strong>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <strong>\r\n        <span class=\"oa-lake\"\r\n            id=\"chairmansTrip-required-remaining-amount\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.requiredRemainingQualAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n    </strong>\r\n    "
    + alias3(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + " needed to reach goal of\r\n    <strong>\r\n        <span class=\"oa-pumpkin\" id=\"chairmansTrip-required-amount\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.requiredQualAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n    </strong>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "oa-banner-red";
},"7":function(container,depth0,helpers,partials,data) {
    return "oa-lake";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <strong><span class=\"oa-forest\">Lives Goal Achieved!</span></strong>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <strong>\r\n        <span class=\"oa-lake\" id=\"chairmansTrip-required-remaining-lives\">"
    + alias4(((helper = (helper = helpers.requiredRemainingLives || (depth0 != null ? depth0.requiredRemainingLives : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"requiredRemainingLives","hash":{},"data":data}) : helper)))
    + "</span>\r\n    </strong> lives needed to reach goal of\r\n    <strong>\r\n        <span class=\"oa-pumpkin\" id=\"chairmansTrip-required-lives\">"
    + alias4(((helper = (helper = helpers.requiredLives || (depth0 != null ? depth0.requiredLives : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"requiredLives","hash":{},"data":data}) : helper)))
    + "</span>\r\n    </strong>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<li id=\"chairmansTrip-qualified-amount-goal-text\">\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.requiredRemainingQualAmountNumber : depth0),0,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n</li>\r\n<li>\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n    <strong>\r\n        <span\r\n            class=\"actual-amount-number "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.actualAmountNumber : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.actualQualAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n        </span>\r\n    </strong>\r\n    actual "
    + alias3(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + "\r\n</li>\r\n\r\n<li id=\"chairmansTrip-qualified-lives-goal-text\">\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.requiredRemainingLives : depth0),0,{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>\r\n<li>\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n    <strong>\r\n        <span class=\"actual-lives "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.actualLives : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias3(((helper = (helper = helpers.actualLives || (depth0 != null ? depth0.actualLives : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"actualLives","hash":{},"data":data}) : helper)))
    + "\r\n        </span>\r\n    </strong> actual lives\r\n</li>";
},"useData":true});
