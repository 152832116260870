/* global Backbone:false, Marionette:false */
/**
 * Layout view for Add Delegate page.
 */
import '../../../utils/hb-helpers';

import AddDelegateViewModel from '../viewModels/add-delegate-vm';
import AddDelegateModel from '../models/add-delegate-m';
import addDelegateTemplate from '../templates/add-delegate-t.hbs';
import errorHelper from '../../../utils/error-helper';
import utils from '../../../utils/utils';
import ProducerDetailsModel from '../../../models/producerDetails-m';

const focusNavBarChannel = Backbone.Radio.channel('focusNavBar');
const spinnerChannel = Backbone.Radio.channel('spinner');
const errorChannel = Backbone.Radio.channel('error');

const AddDelegateView = Marionette.LayoutView.extend({

    defaultName: { name },
    template: addDelegateTemplate,

    errors: {
        serverError: 'An error occurred while attempting to submit the form',
        producerIdRequired: 'No Producer Specified'
    },

    modelEvents: {
        'validated:invalid': 'onValidationFail'
    },

    ui: {
        submitButton: '#add-delegate-submit',
        cancelButton: '#add-delegate-cancel',
        firstName: '#firstname',
        lastName: '#lastname',
        email: '#email',
        accessLevel: 'select[name=accessLevel]',
        addDelegateConfirmModal: '#add-delegate-confirm-modal',
        ok: '#ok'
    },

    events: {
        'click @ui.submitButton': '_submitClick',
        'click @ui.cancelButton': '_cancel',
        'change @ui.accessLevel': '_accessLevelChange',
        'click @ui.ok': '_ok'
    },

    /**
     * Initialize the view
     * @param options
     */
    initialize(options) {
        let domainModel;
        this.utils = utils;

        // Create and set a new model if there isn't one passed in
        if (!options.model) {

            domainModel = new AddDelegateModel();
            this.model = new AddDelegateViewModel({
                domainModel: domainModel

            });
        } else if (options.model) {
            this.model = options.model;
        }

        if (!options || !options.stateObj || !options.stateObj.producerId) {
            errorChannel.trigger('showErrorPage', this.errors.producerIdRequired);
            this.destroy();
            return false;
        }

        this.model.domainModel.set('producerId', options.stateObj.producerId);
    },

    onBeforeShow() {
        spinnerChannel.trigger('show', {
            viewScope: this,
            position: 'fixed'
        });

        var producerId = this.model.domainModel.get('producerId');

        if (producerId && !this.producerDetailsModel) {
            this.producerDetailsModel = new ProducerDetailsModel({
                producerId: producerId
            });

            this.listenTo(this.producerDetailsModel, 'sync', this._setProducerDetails);

            this.producerDetailsModel.fetch();
        }
    },

    _setProducerDetails(response) {
        if (response && response.attributes) {
            this.model.set('producerName', response.attributes.fullName);
            spinnerChannel.trigger('hide', this);
            this.render();
        }
    },

    /**
     * When rendering, bind the popover handler to the popover ui elements.
     */

    onValidationFail(errors) {
        this.validationErrors = errors;
        this.render();
    },

    /**
       * Enables/disables the submit button. If being disabled, the
       * CSS class "disabled" is added.
       *
       * @param {boolean} doEnable Set to TRUE to enable the button
       * @private
       */
    _setSubmitButtonState(doEnable) {

        if (doEnable) {
            this.ui.submitButton.removeClass('disabled');
            this.ui.submitButton.removeAttr('disabled');
        } else {
            // add the 'disabled' css class to the submit button
            this.ui.submitButton.addClass('disabled');
            this.ui.submitButton.attr('disabled', true);
        }
    },

    /**
     * Display a message to the user if an error occurs
     * @param model
     * @param response
     * @private
     */

    _showAlertMessage(model, response) {

        if (response && (response.status === 404 || response.status === 400
            || response.status === 500)) {
            this.model.set(
                'alertMessage',
                errorHelper.createAlert(this.errors.serverError, 'warning')
            );
            this.render();
        }
    },

    _showSuccessMessage(model, response) {
        if (response) {
            spinnerChannel.trigger('hide', this);
            this._showConfirmationModal();
        }
    },

    /**
     * Handle the click event of the submit button for the form.
     * @param {Object} e The event
     * @private
     */

    _accessLevelChange: function (e) {
        let accessLevel = e.target.value;
        this.model.set('accessLevel', accessLevel);
    },

    _hasFullAccess: function (accessLevel) {
        return accessLevel === 'Full Access';
    },

    _showConfirmationModal: function () {
        this.ui.addDelegateConfirmModal.modal({
            show: true,
            backdrop: 'static'
        });
    },

    _hideConfirmationModal: function () {
        this.ui.addDelegateConfirmModal.modal('hide');
    },

    _redirectToDelegateMaintenancePage: function () {
        let producerId = this.model.domainModel.get('producerId');
        let url = '#producer-delegate-maintenance?producerId=' + producerId;
        this.trigger('nav', url);
    },

    _ok: function (event) {
        if (event) {
            event.preventDefault();
        }
        this._hideConfirmationModal();
        this._redirectToDelegateMaintenancePage();
    },

    _cancel: function () {
        this._redirectToDelegateMaintenancePage();
    },

    _submitClick(event) {
        let isValid;
        if (event) {
            event.preventDefault();
        }
        spinnerChannel.trigger('show', {
            viewScope: this,
            position: 'fixed'
        });

        this._updateModel();
        isValid = this.model.isValid();

        if (isValid) {
            this.listenTo(this.model.domainModel, 'error', this._showAlertMessage);
            this.listenTo(this.model.domainModel, 'sync', this._showSuccessMessage);
            this._setSubmitButtonState(false);
            let xRequestedWithHeader = {
                'X-Requested-With' : 'XMLHttpRequest'
            };
            this.model.domainModel.save(null, {
                dataType: 'json',
                data: JSON.stringify(this._getData()),
                type: 'POST',
                contentType: 'application/json',
                headers     : xRequestedWithHeader
            });
            focusNavBarChannel.trigger('focusNavBar');
        }
        this.render();
    },

    _updateModel() {
        this.model.set({
            firstName: this.ui.firstName.val(),
            lastName: this.ui.lastName.val(),
            emailAddress: this.ui.email.val()
        });
    },

    _getData() {
        return {
            firstName: this.model.get('firstName'),
            lastName: this.model.get('lastName'),
            emailAddress: this.model.get('emailAddress'),
            commissionAccess: this._hasFullAccess(this.model.get('accessLevel'))
        };
    },

    onDestroy: function () {
        Backbone.$('.modal-backdrop.fade.in').remove();
        Backbone.$('body').removeClass('modal-open');
    }
});

module.exports = AddDelegateView;